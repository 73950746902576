import {
  Grid, TextField, InputLabel, Typography, CustomReactSelect,
} from '@chaos/ui';
import {
  ArgType,
  isConfigurableTypeParams,
  isRandomTypeParams,
  isTypedArg,
  ScriptArg,
  ScriptResource,
} from '@chaos/types';
import { FC } from 'react';

const parameterTypeOptions = [
  { label: 'Deterministic', value: 'Value' },
  { label: 'Stochastic', value: 'RandomParam' },
  { label: 'Range', value: 'ConfigurableParam' },
];

type AdvancedParamConfigProps = {
  name: string,
  arg: ScriptArg, // actual value to be sent in API cann
  argType?: ArgType, // definition
  setSelectedInput: (value: React.SetStateAction<Partial<ScriptResource>>) => void,
  selectedInput: Partial<ScriptResource>,
};
const getIntialValue = (type: string, argType?: ArgType) => {
  if (!type) {
    return '';
  }

  if (type === 'ConfigurableParam') {
    return {
      minValue: 0,
      maxValue: 100,
      interval: 1,
    };
  }

  if (type === 'RandomParam') {
    return {
      minValue: 0,
      maxValue: 100,
    };
  }

  if (argType === 'number[]' || argType === 'string[]') {
    return [];
  }

  return '';
};

export const AdvancedParamConfig: FC<AdvancedParamConfigProps> = ({
  name, argType, arg, setSelectedInput, selectedInput,
}) => (
  <Grid container rowSpacing={2} columnSpacing={4}>
    <Typography fontSize="16px" fontWeight="600" sx={{ mt: 3, ml: 4 }}>{`${name}`}</Typography>
    <Grid item xs={12} key={`${name}-type`}>
      <InputLabel>Type</InputLabel>
      <CustomReactSelect
        name={name}
        options={parameterTypeOptions}
        value={parameterTypeOptions.find((option) => (option?.value === (isTypedArg(arg) ? arg.type : 'Value')))}
        onChange={(selected) => setSelectedInput({
          ...selectedInput,
          args: {
            ...selectedInput.args,
            [name]: {
              ...selectedInput.args![name],
              type: (selected?.value as ArgType),
              value: getIntialValue(selected?.value ?? 'Value', argType),
            },
          },
        })}
        isSearchable
      />
    </Grid>
    {arg?.type === 'ConfigurableParam' && (
    <>
      <Grid item xs={4} key={`${name}-scan-min`}>
        <InputLabel>Min</InputLabel>
        <TextField
          fullWidth
          type="number"
          value={isConfigurableTypeParams(arg.value) && arg.value.minValue}
          onChange={(e) => setSelectedInput({
            ...selectedInput,
            args: {
              ...selectedInput.args,
              [name]: {
                ...arg,
                value: typeof arg.value === 'object' ? {
                  ...arg.value,
                  minValue: Number(e.target.value),
                } : {
                  minValue: Number(e.target.value),
                  maxValue: 100,
                  interval: 1,
                },
              },
            },
          })}
          placeholder="Enter min value"
        />
      </Grid>
      <Grid item xs={4} key={`${name}-scan-max`}>
        <InputLabel>Max</InputLabel>
        <TextField
          fullWidth
          type="number"
          value={isConfigurableTypeParams(arg.value) && arg.value.maxValue}
          onChange={(e) => setSelectedInput({
            ...selectedInput,
            args: {
              ...selectedInput.args,
              [name]: {
                ...arg,
                value: typeof arg.value === 'object' ? {
                  ...arg.value,
                  maxValue: Number(e.target.value),
                } : {
                  minValue: 1,
                  maxValue: Number(e.target.value),
                  interval: 1,
                },
              },
            },
          })}
          placeholder="Enter max value"
        />
      </Grid>
      <Grid item xs={4} key={`${name}-scan-interval`}>
        <InputLabel>Interval</InputLabel>
        <TextField
          type="number"
          fullWidth
          value={isConfigurableTypeParams(arg.value) && arg.value.interval}
          onChange={(e) => setSelectedInput({
            ...selectedInput,
            args: {
              ...selectedInput.args,
              [name]: {
                ...arg,
                value: typeof arg.value === 'object' ? {
                  ...arg.value,
                  interval: Number(e.target.value),
                } : {
                  minValue: 1,
                  maxValue: 100,
                  interval: Number(e.target.value),
                },
              },
            },
          })}
          placeholder="Enter interval"
        />
      </Grid>
    </>
    )}
    {arg?.type === 'RandomParam' && (
    <>
      <Grid item xs={6} key={`${name}-rand-min`}>
        <InputLabel>Min</InputLabel>
        <TextField
          type="number"
          fullWidth
          value={isRandomTypeParams(arg.value) && arg.value.minValue}
          onChange={(e) => setSelectedInput({
            ...selectedInput,
            args: {
              ...selectedInput.args,
              [name]: {
                ...arg,
                value: typeof arg.value === 'object' ? {
                  ...arg.value,
                  minValue: Number(e.target.value),
                } : {
                  minValue: Number(e.target.value),
                  maxValue: 100,
                },
              },
            },
          })}
          placeholder="Enter min value"
        />
      </Grid>
      <Grid item xs={6} key={`${name}-rand-max`}>
        <InputLabel>Max</InputLabel>
        <TextField
          fullWidth
          type="number"
          value={isRandomTypeParams(arg.value) && arg.value.maxValue}
          onChange={(e) => setSelectedInput({
            ...selectedInput,
            args: {
              ...selectedInput.args,
              [name]: {
                ...arg,
                value: typeof arg.value === 'object' ? {
                  ...arg.value,
                  maxValue: Number(e.target.value),
                } : {
                  minValue: 1,
                  maxValue: Number(e.target.value),
                },
              },
            },
          })}
          placeholder="Enter max value"
        />
      </Grid>
    </>
    )}
    {!isTypedArg(arg) && (
      <Grid item xs={12} key={`${name}-value`}>
        <InputLabel>{`Value${argType ? ` (${argType})` : ''}`}</InputLabel>
        <TextField
          fullWidth
          value={Number(arg.value)}
          type="number"
          onChange={(e) => setSelectedInput({
            ...selectedInput,
            args: {
              ...selectedInput.args,
              [name]: {
                ...arg,
                value: Number(e.target.value),
              },
            },
          })}
          placeholder="Enter Parameter"
        />
      </Grid>
    )}
  </Grid>
);
