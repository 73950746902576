import { ProgressStatus } from 'src/store/progress-bar/reducer';

// iterationMultiplier defined as the gap between "run setup scripts" and "run runtime scripts",
// and the gap between "run runtime scripts" to "simulation finished". both needs to be equal.
const iterationMultiplier: Record<ProgressStatus, number> = {
  initial: 0,
  'initializing...': 0,
  'spinning chain': 0,
  'deploying contracts': 0,
  'contracts deployed': 0,
  'simulation started': 0,
  'calculate permutations': 0,
  'run config simulations': 0.5,
  'run setup scripts': 0.3,
  'run runtime scripts': 0.3,
  'simulation error': 0,
  'simulation cancelled': 0,
  'simulation finished': 0,
};

const statusesProgress: Record<ProgressStatus, number> = {
  initial: 0,
  'initializing...': 0.05,
  'spinning chain': 0.1,
  'deploying contracts': 0.2,
  'contracts deployed': 0.25,
  'simulation started': 0.3,
  'calculate permutations': 0.3,
  'run setup scripts': 0.4,
  'run config simulations': 0.5,
  'run runtime scripts': 0.7,
  'simulation error': 0.95,
  'simulation cancelled': 0.95,
  'simulation finished': 1,
};

export function ProgressByState(
  state: ProgressStatus | string,
  currentIteration?: number,
  totalIterations?: number,
): number {
  const iterationsPercentage = currentIteration && totalIterations
    ? (iterationMultiplier[state as ProgressStatus] ?? 0) * (currentIteration / totalIterations)
    : 0;

  return (statusesProgress[state as ProgressStatus] ?? 0) + iterationsPercentage;
}
