import { DataItem, HistogramChartRow } from '@chaos/ui/charts';
import { Paper } from '@chaos/ui';
import { HealthHistogramItem } from '../../types';

type Props = {
  walletsHealth: HealthHistogramItem[]
};
const HealthHistogram = ({ walletsHealth }: Props) => (
  <Paper
    variant="card"
    sx={{
      bgcolor: 'background.default',
      height: '100%',
      p: 3,
    }}
  >
    <HistogramChartRow
      title="Wallets Health"
      description="Expected health distribution of wallets that have supply positions for the selected assets"
      data={[(walletsHealth || [])
        .reduce((acc: DataItem[], { bucket: { start, end }, numberOfWallets }, index: number) => {
          const isLastItem = index === walletsHealth.length - 1;
          acc.push({
            x: isLastItem ? `>= ${start}` : `${start} - ${end}`,
            y: numberOfWallets,
          });
          return acc;
        }, [])]}
      legend={[
        'Number of wallets',
      ]}
    />
  </Paper>
);

export default HealthHistogram;
