import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Box, Button, Typography } from '@chaos/ui';
import { RouteParams, RoutePath } from 'src/config/routes';
import { SidebarPopup } from '../blockchain-simulation-form/SidebarPopup';

type BlockDetailsProps = {
  observers?: {
    title: string,
    data: { title: string, value: number, color: string }[]
  }[],
  simulationResultId: string,
  iterationNumber: number,
  startBlock?: number,
  onClose: () => void,
};

export const BlockDetails: FC<BlockDetailsProps> = ({
  observers, simulationResultId, iterationNumber, startBlock, onClose,
}) => {
  const navigate = useNavigate();

  return (
    <SidebarPopup
      isOpen={!!observers}
      title={`Iteration ${iterationNumber}`}
      subtitle={startBlock ? `Block #${iterationNumber + startBlock}` : undefined}
      onClose={onClose}
      footer={startBlock && (
        <Button
          color="primary"
          sx={{ ml: 'auto', mt: 'auto', py: 2 }}
          onClick={() => navigate(RoutePath.Simulations.BlockExplorer.BlockDetails
            .replace(RouteParams.SimulationResultId, simulationResultId)
            .replace(RouteParams.BlockNumber, (iterationNumber + startBlock).toString()))}
        >
          Explore Block
        </Button>
      )}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        {observers?.map((o) => (
          <Box key={uuidv4()} display="flex" flexWrap="wrap" gap={1} flexDirection="column">
            {o.title && <Typography>{o.title}</Typography>}
            {o.data.map((d) => (
              <Box key={uuidv4()} display="flex" gap={1} alignItems="center" width="100%">
                <Box bgcolor={d.color} width={8} height={8} borderRadius="50%" flexShrink={0} />
                <Typography variant="caption" overflow="hidden" textOverflow="ellipsis">
                  {`${d.title}:`}
                </Typography>
                <Typography alignSelf="start" ml="auto">{d.value}</Typography>
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </SidebarPopup>
  );
};
