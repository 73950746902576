import { FC } from 'react';
import { Team } from '@chaos/types';
import { Box } from '@chaos/ui';
import { IconButton } from '@chaos/ui/icon-button';
import { Typography } from '@chaos/ui/typography';
import { CustomIcon } from 'src/components/custom-icon';
import { HIDDEN_COLLAPSED_STYLES } from './styles';

export const NavFooter: FC<{ team?: Team }> = ({ team }) => (
  <Box
    className="navigation-footer"
    sx={{
      ...HIDDEN_COLLAPSED_STYLES,
      display: 'flex',
      flexDirection: 'column',
      rowGap: 3,
      mt: 3,
      pb: { xs: 2, lg: 3 },
    }}
  >
    <Box sx={{
      columnGap: 3,
      display: 'flex',
      justifyContent: 'center',
    }}
    >
      {team && (
      <IconButton size="small" sx={{ p: '0 !important' }} target="_blank" href="https://chaoslabs.xyz/">
        <CustomIcon icon="chaos" />
      </IconButton>
      )}
      <IconButton size="small" sx={{ p: '0 !important' }} target="_blank" href="https://twitter.com/chaos_labs">
        <CustomIcon icon="outline-twitter" />
      </IconButton>
      <IconButton size="small" sx={{ p: '0 !important' }} target="_blank" href="https://linkedin.com/company/chaos-labs-xyz">
        <CustomIcon icon="linkedin" />
      </IconButton>
      <IconButton size="small" sx={{ p: '0 !important' }} target="_blank" href="mailto:info@chaoslabs.xyz">
        <CustomIcon icon="outline-mail" />
      </IconButton>
    </Box>
    <Typography variant="body2" whiteSpace="nowrap" align="center">Chaos Labs Inc., 2022</Typography>
  </Box>
);
