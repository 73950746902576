import { createContext } from 'react';

import { Team } from '@chaos/types';

export interface TeamContextValue {
  loading: boolean;
  teams: Team[]
  team?: Team
  setTeam?: (team: string) => void
}

const defaultValue: TeamContextValue = {
  loading: true,
  teams: [],
};

export const TeamContext = createContext<TeamContextValue>(defaultValue);
