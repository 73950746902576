import { Variant } from '@mui/material/styles/createTypography';
import { Box } from '../box';
import { Paper } from '../paper';
import { Tooltip } from '../tooltip';
import { Typography } from '../typography';
import { CustomIcon } from '../custom-icon';
import { formatAmount } from '../utils/formatters';
import { ShimmerLoader } from '../shimmer-loader';
import { Trend } from '../trend';
import { formatCardValue } from './helper';
import { ProgressBar } from '../progress-bar';

export type ValueCardProps = {
  title: string
  titleColor?: string
  titleVariant?: Variant | 'inherit'
  value?: number | string | ValueCardProps[]
  valueMaximumFractionDigits?: number
  capAtValue?: number
  showFullValue?: boolean
  icon?: string
  isPercent?: boolean
  isDeltaPercent?: boolean
  currency?: string
  delta?: number
  deltaTrend?: number
  deltaColor?: number
  tooltip?: string
  bgColor?: string
  iconColor?: string
  iconOriginalColor?: boolean
  deltaTooltip?: string
  progress?: {
    maxValue: number;
    displayMaxValue?: boolean;
  }
  onClick?: () => void;
  showSign?: boolean;
};

const ValueCardInner = ({
  title,
  titleColor,
  titleVariant = 'caption',
  value,
  showFullValue,
  icon,
  isPercent,
  isDeltaPercent,
  currency,
  delta,
  deltaTrend,
  deltaColor,
  tooltip,
  iconColor,
  iconOriginalColor,
  deltaTooltip,
  progress,
  valueMaximumFractionDigits,
  capAtValue,
  onClick,
  showSign,
}: ValueCardProps) => (
  <>
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        {icon
          && (
          <Box display="inline-flex" sx={{ width: 24, height: 24, mr: 1 }}>
            {icon.length && (
            <CustomIcon
              icon={icon}
              sx={{
                color: 'almostWhite.main',
                '& path': iconOriginalColor ? undefined : { fill: iconColor || 'currentColor' },
              }}
            />
            )}
          </Box>
          )}
        <Typography variant={titleVariant} color={titleColor}>
          {title}
        </Typography>
      </Box>
      <Box display="flex" gap={[0.5, 1]}>
        {tooltip && (
        <Box>
          <Tooltip title={tooltip} arrow>
            <Box>
              <CustomIcon
                icon="info"
                sx={{
                  color: 'almostWhite.main',
                  '& path': { fill: 'currentColor' },
                  svg: { height: '24px', width: '24px' },
                }}
              />
            </Box>
          </Tooltip>
        </Box>
        )}
        {onClick && <CustomIcon icon="chevron-right" />}
      </Box>
    </Box>
    <Box minHeight={32}>
      {value === undefined && <ShimmerLoader sx={{ borderRadius: 1 }} />}
      {Array.isArray(value) && (
        <Box display="flex" gap={5}>
          {value.map((props) => <Box><ValueCardInner {...props} /></Box>)}
        </Box>
      )}
      {!Array.isArray(value) && value !== undefined && (
        <Box display="flex" gap={1} flexWrap="wrap">
          <Tooltip
            title={
              typeof value === 'number'
                ? `${showSign && value > 0 ? '+' : ''}${formatAmount(value || 0, {
                  isPercent, currency, notation: 'standard',
                })}`
                : ''
            }
            arrow
            placement="bottom"
          >
            <Typography variant="h3">
              {typeof value === 'number' ? `${showSign && value > 0 ? '+' : ''}${formatCardValue(value, isPercent, currency, showFullValue, valueMaximumFractionDigits, capAtValue)}` : value}
            </Typography>
          </Tooltip>
          {progress?.displayMaxValue && (
            <Tooltip
              title={formatAmount(progress.maxValue, { isPercent, currency, notation: 'standard' })}
              arrow
              placement="bottom"
            >
              <Box display="flex" alignItems="center" gap={1}>
                <Typography>of</Typography>
                <Typography variant="h3">
                  {formatCardValue(
                    progress.maxValue,
                    isPercent,
                    currency,
                    showFullValue,
                    valueMaximumFractionDigits,
                    capAtValue,
                  )}
                </Typography>
              </Box>
            </Tooltip>
          )}
          {delta !== undefined && deltaTrend !== undefined && (
            <Trend
              delta={delta}
              deltaTrend={deltaTrend}
              deltaColor={deltaColor}
              isPercent={isDeltaPercent || isPercent}
              currency={currency}
              tooltip={deltaTooltip}
              maximumFractionDigits={valueMaximumFractionDigits}
            />
          )}
        </Box>
      )}
    </Box>
    {progress && typeof value === 'number' && (
      <Box>
        <ProgressBar progress={Number(value) / progress.maxValue} textPosition="right" height={8} bgBlack />
      </Box>
    )}
  </>
);

const ValueCard = (props: ValueCardProps) => {
  const { bgColor, onClick } = props;

  return (
    <Paper
      variant="card"
      sx={{
        boxShadow: 'none',
        padding: 3,
        backgroundColor: bgColor ?? 'darkGrey.main',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        justifyContent: 'space-between',
        cursor: onClick ? 'pointer' : undefined,
      }}
      onClick={onClick}
    >
      <ValueCardInner {...props} />
    </Paper>
  );
};

export default ValueCard;
