import { ReactElement, useMemo } from 'react';
import { GlobalStyles } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@chaos/ui/theme';
import { AppColorSchemeContext } from '@chaos/ui/hooks/use-app-color-scheme';
import { LinearProgress } from '@chaos/ui/linear-progress';
import { AppColorSchemes } from '@chaos/ui/types';
import { SnackbarProvider } from 'notistack';
import { palette } from '@chaos/ui/theme/palette';
import { LocalizationProvider, AdapterDayjs } from '@chaos/ui/date-picker';
import { RenderRoutes } from './RenderRoutes';
import { useAppSelector } from './store';
import { ChaosSnackbarConfigurator } from './utils/chaos-snackbar-utils';

const App = (): ReactElement => {
  const isLoaded = useAppSelector((state) => state.authLoaded);
  const colorProviderValue = useMemo(() => ({
    colorScheme: 'dark' as AppColorSchemes,
  }), []);

  if (!isLoaded) {
    return <LinearProgress />;
  }

  return (
    <AppColorSchemeContext.Provider value={colorProviderValue}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider>
          <SnackbarProvider maxSnack={10}>
            <ChaosSnackbarConfigurator />
            <CssBaseline />
            <GlobalStyles styles={{
              body: {
                font: '16px/24px "Archivo", sans-serif;',
                fontWeight: 500,
                color: palette.white.main,
                backgroundColor: palette.black.main,
                width: '100%',
                height: '100%',
              },
            }}
            />
            <RenderRoutes />
          </SnackbarProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </AppColorSchemeContext.Provider>
  );
};

export default App;
