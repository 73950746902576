import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Autocomplete, Box, Typography, Button, Grid, TextField, InputLabel, CustomSwitch,
} from '@chaos/ui';
import { FilterBar } from '@chaos/ui/filter-bar';
import {
  CodeEntity,
  ObserverChartType,
  observerChartTypeOptions,
  ObserverDataType,
  observerDataTypeOptions,
  ScriptArg,
  ScriptResource,
  ScriptResourceGroupInput,
} from '@chaos/types';
import {
  DragDropContext, Droppable, Draggable, DropResult, DroppableProvided,
} from 'react-beautiful-dnd';
import { debounce } from 'src/utils/debounce';
import constant from 'src/assets/img/functions/constant.png';
import descending from 'src/assets/img/functions/descending.png';
import ascending from 'src/assets/img/functions/ascending.png';
import volatile from 'src/assets/img/functions/volatile.png';
import { useScriptResourceSearch } from 'src/hooks/useScriptResourceSearch';
import { CustomMultiReactSelect, CustomReactSelect, OptionType } from '@chaos/ui/custom-select';
import { Selector } from '../selector';
import { CustomIcon } from '../custom-icon';
import { SidebarPopup } from './SidebarPopup';
import { ConfirmationPromptDialog } from '../confirmation-prompt-dialog';
import { ShortCardItem } from '../short-card-item';
import { BlockchainSimulationConfigItem } from '../blockchain-simulation-configuration-item';
import { OracleConfig } from './OracleConfig';
import { AdvancedParamConfig } from './AdvancedParamConfig';
import { mapValueToArg, parseValueFromArg } from './helpers';
import { CustomCheckbox } from '../custom-checkbox';
import { SpellsConfig } from './SpellsConfig';

type CreateStepProps = {
  type: CodeEntity,
  onChange: (inputs: Partial<ScriptResource>[]) => void,
  onGroupsChange?: (groups: ScriptResourceGroupInput[]) => void,
  inputs: Partial<ScriptResource>[],
  groupsInputs?: ScriptResourceGroupInput[],
  selectable?: boolean
  itemsDraggable?: boolean;
};

const assertionTypeOptions = [
  { label: 'One Time', value: 'OneTime' },
  { label: 'Invariant', value: 'Invariant' },
];

const scenarioTypeOptions = [
  { label: 'Setup', value: 'setup' },
  { label: 'Runtime', value: 'runtime' },
];

const validateNewGroupForm = (input: ScriptResourceGroupInput) => {
  if (!input.groupName) return false;
  return true;
};

const validateInput = (input: Partial<ScriptResource>, type: CodeEntity) => {
  if (!input.name) return false;

  if ((type === 'Scenario' || type === 'Assertion') && !input.type) {
    return false;
  }

  return Object.entries(input.args || {}).every(([name, arg]) => {
    if (name === 'chainUrl') return true;

    if (!arg.value && arg.value !== 0) return false;

    if (arg.type === 'number[]') {
      return !(arg.value as string[]).find((n) => Number.isNaN(Number(n)));
    }

    if (arg.type === 'string[]') return !(arg.value as string[]).find((s) => !s);

    if (arg.type === 'number') return !Number.isNaN(Number(arg.value));

    return true;
  });
};

const reorderInputsItems = (
  inputs: Partial<ScriptResource>[],
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(inputs);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const CreateStep: React.FC<CreateStepProps> = ({
  type, onChange, onGroupsChange, inputs, groupsInputs, selectable, itemsDraggable,
}) => {
  const [isShowConfigurePopup, setIsShowConfigurePopup] = useState(false);
  const [isNewGroupSidebarOpened, setIsNewGroupSidebarOpened] = useState(false);
  const [removePopupItem, setRemovePopupItem] = useState<Partial<ScriptResource>>();
  const [selectedInput, setSelectedInput] = useState<Partial<ScriptResource>>({ args: {} });
  const [configuredInputs, setConfiguredInputs] = useState<Partial<ScriptResource>[]>(inputs);
  const [currentlyEditing, setCurrentlyEditing] = useState<Partial<ScriptResource>>();
  const [newGroupFormState, setNewGroupFormState] = useState<ScriptResourceGroupInput>({
    groupId: uuidv4(),
    groupName: '',
    observerIds: [],
  });
  const [createGroupWithSelected, setCreateGroupWithSelected] = useState<boolean>(true);
  const [scriptInputValue, setScriptInputValue] = useState('');
  const [scriptSearchValue, setScriptSearchValue] = useState('');
  const [selectedConfigItems, setSelectedConfigItems] = useState<string[]>([]);
  const [isAdvancedChecked, setIsAdvancedChecked] = useState(false);
  const {
    results: scriptOptions,
    isLoading: isSearchingScript,
  } = useScriptResourceSearch(type, scriptSearchValue);
  const debouncedSearch = useMemo(
    () => debounce((val: string) => setScriptSearchValue(val), 200),
    [],
  );

  useEffect(() => onChange(configuredInputs), [onChange, configuredInputs]);
  useEffect(() => setScriptInputValue(selectedInput?.name || ''), [selectedInput]);

  const closeConfigureHandler = useCallback(() => {
    setCurrentlyEditing(undefined);
    setSelectedInput({ args: {} });
    setIsShowConfigurePopup(false);
  }, []);

  const closeNewGroupSidebarHandler = useCallback(() => {
    setIsNewGroupSidebarOpened(false);
  }, []);

  const closeRemoveHandler = () => {
    setRemovePopupItem(undefined);
  };
  const showOracleConfiguration = selectedInput?.name === 'OSM price change';
  const showSpellsConfiguration = selectedInput?.name === 'Cast Spell';
  const selectedInputArgs = Object
    .entries(selectedInput.args || {})
    .filter((x) => x[0] !== 'chainUrl');
  const showAdvanced = (type === 'Agent' || type === 'Scenario')
    && selectedInputArgs.filter((x) => x[1].type === 'number').length > 0;

  const ScriptResourceSidebarInputs = useMemo(() => (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputLabel>Configuration Name</InputLabel>
          <Autocomplete
            inputValue={scriptInputValue}
            renderInput={(params) => (
              <FilterBar
                {...params}
                onChange={(s) => {
                  setScriptInputValue(s);
                  debouncedSearch(s);
                }}
                placeholder="Select Configuration"
                loading={isSearchingScript}
              />
            )}
            loading={isSearchingScript}
            options={scriptOptions}
            getOptionLabel={(option) => option.name}
            onChange={(_, option) => {
              if (option) {
                setSelectedInput({
                  ...selectedInput,
                  ...option,
                  refId: option.id,
                  args: Object.entries(option.args).reduce((res, [key, value]) => ({
                    ...res,
                    [key]: typeof value === 'string' ? { type: value } : value,
                  }), {}),
                });
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={type !== 'Observer' ? 8 : 12}>
          <InputLabel>{`${type} Name`}</InputLabel>
          <TextField
            fullWidth
            placeholder="Enter Name"
            value={selectedInput?.configName || ''}
            onChange={(e) => setSelectedInput({ ...selectedInput, configName: e.target.value })}
          />
        </Grid>
        {(type === 'Assertion' || type === 'Scenario') && (
        <Grid item xs={12} sm={4}>
          <InputLabel>Type</InputLabel>
          <CustomReactSelect
            name="type"
            placeholder="Type"
            options={type === 'Assertion' ? assertionTypeOptions : scenarioTypeOptions}
            value={(type === 'Assertion' ? assertionTypeOptions : scenarioTypeOptions)
              .find(({ value }) => value === selectedInput?.type)}
            onChange={(selected) => setSelectedInput({
              ...selectedInput,
              type: selected?.value.toString() as 'setup' | 'runtime' | undefined,
            })}
            isSearchable
          />
        </Grid>
        )}
      </Grid>
      {type === 'Observer' && (
      <Box display="flex" mt="auto" alignItems="flex-start">
        <CustomCheckbox
          checked={!!selectedInput?.isMetric}
          onChange={(e) => setSelectedInput({ ...selectedInput, isMetric: e.target.checked })}
        />
        <Typography ml={1.5}>
          Allow stasictical calculations
          for this observer after simulation
          run finished (for parameter configuration simulation)
        </Typography>
      </Box>
      )}
      {selectedInput?.refId
      && (showOracleConfiguration || showSpellsConfiguration || selectedInputArgs.length > 0) && (
      <>
        {showSpellsConfiguration && (
        <>
          <Box display="flex" flexDirection="row">
            <CustomIcon sx={{ width: '24px' }} icon="maker" />
            <Typography marginLeft="8px" variant="h5" sx={{ my: 4 }}>
              Maker Spells
            </Typography>
          </Box>
          <SpellsConfig selectedInput={selectedInput} setSelectedInput={setSelectedInput} />
        </>
        )}
        {showOracleConfiguration && (
          <>
            <Typography variant="h5" sx={{ my: 4 }}>Parameters</Typography>
            <OracleConfig selectedInput={selectedInput} setSelectedInput={setSelectedInput} />
          </>
        )}
        {!showOracleConfiguration && !showSpellsConfiguration && (
          <Box display="flex" flexDirection="column" paddingTop="10px">
            <Box display="flex" flexDirection="row" flexGrow={1} width="100%" sx={{ marginTop: 4 }}>
              <Typography fontSize="24px" fontWeight="600">
                Parameters
              </Typography>
              {showAdvanced && (
                <Box display="flex" alignItems="center" marginLeft="auto" marginRight="24px" gap={2}>
                  <CustomSwitch
                    checked={isAdvancedChecked}
                    onChange={(e) => setIsAdvancedChecked(e.target.checked)}
                  />
                  <Typography>Advanced</Typography>
                </Box>
              )}
            </Box>
            {showAdvanced && (
            <Typography variant="caption" sx={{ mb: 2 }}>
              Advanced parameters are also accepting random values or range of values
            </Typography>
            )}
            <Grid container spacing={3}>
              {selectedInputArgs
                .map(([name, value]) => {
                  const arg = (Object.entries(selectedInput?.args ?? [])
                    .find(([argName]) => argName === name) || [0, {}])[1] as ScriptArg;
                  const argType = typeof arg === 'object' ? arg.type : arg;
                  return isAdvancedChecked && argType === 'number' ? (
                    <Grid item xs={12} key={name}>
                      <AdvancedParamConfig
                        name={name}
                        arg={value}
                        argType={argType}
                        selectedInput={selectedInput}
                        setSelectedInput={setSelectedInput}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12} key={name}>
                      <InputLabel>{`${name}${argType ? ` (${argType})` : ''}`}</InputLabel>
                      <TextField
                        fullWidth
                        type={argType === 'number' ? 'number' : undefined}
                        value={parseValueFromArg(value)}
                        onChange={(e) => setSelectedInput({
                          ...selectedInput,
                          args: {
                            ...selectedInput.args,
                            [name]: mapValueToArg(
                              argType,
                              selectedInput.args![name],
                              e.target.value,
                            ),
                          },
                        })}
                        placeholder="Enter Parameter"
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
        )}
        {showOracleConfiguration && (
          <Box mb={6}>
            <Typography variant="h5" sx={{ my: 3 }}>
              Select Function
            </Typography>
            <Selector
              data={[
                { id: 'Constant', name: 'Constant', imgSrc: constant },
                { id: 'Descending', name: 'Descending', imgSrc: descending },
                { id: 'Ascending', name: 'Ascending', imgSrc: ascending },
                { id: 'Volatile', name: 'Volatile', imgSrc: volatile },
              ]}
              isLoading={false}
              // eslint-disable-next-line
              selectedValue={selectedInput?.args?.priceFunction?.value?.toString()}
              onItemClick={(item) => setSelectedInput({
                ...selectedInput,
                args: {
                  ...selectedInput.args,
                  priceFunction: {
                    type: 'string',
                    value: item.id,
                  },
                },
              })}
              columnSpacing={3}
              rowSpacing={3}
              gridItemSize={{ xs: 12, sm: 6 }}
              withImage
              imageSx={{ width: '100%', height: 40, mb: 2 }}
            />
          </Box>
        )}
      </>
      )}
    </>
  ), [
    selectedInput,
    setSelectedInput,
    showOracleConfiguration,
    isAdvancedChecked,
    selectedInputArgs,
    showAdvanced,
    showSpellsConfiguration,
    type,
    debouncedSearch,
    isSearchingScript,
    scriptInputValue,
    scriptOptions,
  ]);

  const ScriptResourceGroupSidebarInputs = useMemo(() => (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <InputLabel>Group Name</InputLabel>
        <TextField
          fullWidth
          placeholder="Group Name"
          value={newGroupFormState?.groupName || ''}
          onChange={(e) => setNewGroupFormState({
            ...newGroupFormState,
            groupName: e.target.value,
          })}
        />
      </Grid>
      <Grid item xs={12}>
        <InputLabel>Data Type</InputLabel>
        <CustomReactSelect
          placeholder="Select Type"
          options={observerDataTypeOptions}
          onChange={(selected) => {
            setNewGroupFormState({
              ...newGroupFormState,
              dataType: selected?.value as ObserverDataType,
            });
          }}
          isSearchable
        />
      </Grid>
      <Grid item xs={12}>
        <InputLabel>Chart Type</InputLabel>
        <CustomReactSelect
          placeholder="Select Type"
          options={observerChartTypeOptions}
          onChange={(selected) => {
            setNewGroupFormState({
              ...newGroupFormState,
              chartType: selected?.value as ObserverChartType,
            });
          }}
          isSearchable
        />
      </Grid>
      <Grid item xs={12} display="flex">
        <CustomCheckbox
          checked={createGroupWithSelected}
          onChange={(event) => setCreateGroupWithSelected(event.target.checked)}
        />
        <Typography ml={1.5}>Create a group with the selected observers</Typography>
      </Grid>
    </Grid>
  ), [newGroupFormState, createGroupWithSelected]);

  const handleSideBarFormSubmit = () => {
    if (isNewGroupSidebarOpened) {
      const newGroup = {
        ...newGroupFormState,
        observerIds:
        createGroupWithSelected ? selectedConfigItems : [],
      };
      onGroupsChange?.([...(groupsInputs || []), newGroup]);
      setNewGroupFormState({
        groupId: uuidv4(),
        groupName: '',
        observerIds: [],
      });
      closeNewGroupSidebarHandler();
      return;
    }

    if (selectedInput) {
      if (currentlyEditing) {
        setConfiguredInputs(configuredInputs.map((input) => (input === currentlyEditing
          ? selectedInput : input)));
      } else {
        const newInput: Partial<ScriptResource> = {
          ...selectedInput,
          displayOrder: configuredInputs.length + 1,
        };
        setConfiguredInputs([...configuredInputs, newInput]);
      }

      setCurrentlyEditing(undefined);
      setSelectedInput({ args: {} });
      closeConfigureHandler();
    }
  };

  const onDragEnd = (result: DropResult) => {
    // Dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedConfiguredInputs = reorderInputsItems(
      configuredInputs,
      result.source.index,

      result.destination.index,
    ).map((item, idx) => (
      {
        ...item,
        displayOrder: idx,
      }
    ));

    setConfiguredInputs(reorderedConfiguredInputs);
  };

  return (
    <Box>
      <Box sx={{
        padding: '36px 0 28px', display: 'flex', alignItems: 'center', width: '100%',
      }}
      >
        <Typography variant="h2">
          {`Configure ${type}s`}
        </Typography>
        <Box display="flex" alignItems="center" flex={1} justifyContent="flex-end">
          {onGroupsChange && groupsInputs && (
          <Box width={250}>
            <CustomMultiReactSelect
              name={type}
              placeholder="Groups"
              value={groupsInputs.reduce((acc, group) => {
                const isSelectedItemsInGroup = group
                  .observerIds
                  .some((id) => selectedConfigItems.includes(id));

                const newAcc = [...acc];

                if (isSelectedItemsInGroup) {
                  newAcc.push({
                    label: group.groupName,
                    value: group.groupId,
                  });
                }
                return newAcc;
              }, [] as OptionType[])}
              options={groupsInputs.map((group: ScriptResourceGroupInput) => ({
                label: group.groupName,
                value: group.groupId,
              }))}
              onChange={(_selected, actionMeta) => {
                if (!actionMeta.option) return;

                const { value: selectedGroupId } = actionMeta.option;
                const groupIdx = groupsInputs.findIndex((g) => g.groupId === selectedGroupId);
                const group = groupsInputs[groupIdx];

                if (!group) return;

                const { observerIds } = group;
                const isSelectGroup = actionMeta.action === 'select-option';
                const newGroups: ScriptResourceGroupInput[] = [...groupsInputs];
                const newGroup = { ...group };
                const newObserverIds = [...observerIds];

                // eslint-disable-next-line no-restricted-syntax
                for (const itemId of selectedConfigItems) {
                  const itemIdx = newObserverIds.findIndex((
                    groupItemId,
                  ) => groupItemId === itemId);
                  const isInGroup = itemIdx > -1;

                  if (isSelectGroup && !isInGroup) {
                    newObserverIds.push(itemId);
                  } else if (!isSelectGroup && isInGroup) {
                    newObserverIds.splice(itemIdx, 1);
                  }
                }

                newGroup.observerIds = newObserverIds;
                newGroups[groupIdx] = newGroup;

                onGroupsChange?.(newGroups);
              }}
              deleteItemBtnProps={selectedConfigItems.length ? undefined : {
                onClick: (option: OptionType) => {
                  const newGroups: ScriptResourceGroupInput[] = groupsInputs.filter(
                    (group) => group.groupId !== option.value,
                  );
                  onGroupsChange?.(newGroups);
                },
              }}
              btnProps={{
                text: '+ Create Group',
                onClick: () => setIsNewGroupSidebarOpened(true),
              }}
              isDisabled={configuredInputs.length < 2}
            />
          </Box>
          )}
          <Button
            onClick={() => setIsShowConfigurePopup(true)}
            color="primary"
            sx={{ p: 1.5, minWidth: 0, ml: 3 }}
          >
            <CustomIcon icon="plus-circle" />
          </Button>
        </Box>
      </Box>
      {!configuredInputs.length ? (
        <Typography
          margin="auto"
          mt={12.5}
          mb={10.5}
          variant="h2"
          color="light.main"
          textAlign="center"
        >
          {`Add ${type} Configuration`}
        </Typography>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided: DroppableProvided) => (
              <Box
                {...provided.droppableProps}
                ref={provided.innerRef}
                height="100%"
              >
                {configuredInputs.sort((
                  inputA,
                  inputB,
                ) => (inputA.displayOrder || 0) - (inputB.displayOrder || 0)).map((input, i) => (
                  <Draggable
                    key={input.refId}
                    draggableId={input.refId || ''}
                    index={i}
                    isDragDisabled={!itemsDraggable}
                  >
                    {(draggableProvided, snapshot) => {
                      // Restrict dragging to vertical axis
                      let transform = draggableProvided.draggableProps.style?.transform;

                      if (snapshot.isDragging && transform) {
                        transform = transform.replace(/\(.+,/, '(0,');
                      }

                      const style = {
                        ...draggableProvided.draggableProps.style,
                        transform,
                      };
                      return (
                        <Box
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          style={style}
                          mb={1}
                        >
                          <BlockchainSimulationConfigItem
                            id={input.refId}
                            name={input.name}
                            configName={input.configName}
                            onRemove={() => setRemovePopupItem(input)}
                            selectedConfigItems={selectable ? selectedConfigItems : undefined}
                            groupsExist={!!groupsInputs?.length}
                            assosiatedGroups={groupsInputs?.reduce((acc, group) => {
                              const newGroups = [...acc];
                              if (input.refId && group.observerIds.includes(input.refId)) {
                                newGroups.push(group);
                              }
                              return newGroups;
                            }, [] as ScriptResourceGroupInput[])}
                            setSelectedConfigItems={selectable ? setSelectedConfigItems : undefined}
                            onClick={() => {
                              setCurrentlyEditing(input);
                              setSelectedInput(input);
                              setIsShowConfigurePopup(true);
                            }}
                          />
                        </Box>
                      );
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      )}
      <ConfirmationPromptDialog
        confirmButtonText="Remove"
        cancelButtonText="Cancel"
        isShow={!!removePopupItem}
        title={`Remove ${type} Configuration`}
        onConfirm={() => {
          setConfiguredInputs(configuredInputs.filter((input) => input !== removePopupItem));
          closeRemoveHandler();
        }}
        onClose={closeRemoveHandler}
      >
        <Typography>Are you sure you want to remove this configuration?</Typography>
        <Box sx={{
          mt: 3,
          bgcolor: 'background.default',
          borderRadius: 4,
          py: 3,
        }}
        >
          <ShortCardItem title={removePopupItem?.name || ''} subtitle={removePopupItem?.configName || ''} />
        </Box>
      </ConfirmationPromptDialog>
      <SidebarPopup
        isOpen={isShowConfigurePopup || isNewGroupSidebarOpened}
        onClose={isNewGroupSidebarOpened ? closeNewGroupSidebarHandler : closeConfigureHandler}
        title={isNewGroupSidebarOpened ? 'Create Group' : `${type} Configuration`}
        footer={(
          <Box sx={{
            pt: 1,
            display: 'flex',
            justifyContent: 'end',
            bottom: 0,
          }}
          >
            <Button
              onClick={isNewGroupSidebarOpened
                ? closeNewGroupSidebarHandler : closeConfigureHandler}
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              disabled={isNewGroupSidebarOpened
                ? !validateNewGroupForm(newGroupFormState) : !validateInput(selectedInput, type)}
              onClick={handleSideBarFormSubmit}
              color="primary"
              sx={{ ml: 3 }}
            >
              Configure
            </Button>
          </Box>
)}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          {isNewGroupSidebarOpened ? ScriptResourceGroupSidebarInputs : ScriptResourceSidebarInputs}
        </Box>
      </SidebarPopup>
    </Box>
  );
};
