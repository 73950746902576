import { Header, RenderData } from '@chaos/types';
import {
  numberCell,
  currencyCell,
  assetCell,
  chainCell,
  progressCell,
  textCell,
} from '@chaos/ui/chaos-table';
import { Asset, Market } from '../aave/graphql-queries';

export const tableHeader: Header[] = [
  {
    renderType: 'TEXT',
    text: 'Market',
    width: '10%',
    isAutocomplete: true,
  },
  {
    renderType: 'TEXT',
    text: 'Asset',
    textHidden: true,
    isAutocomplete: true,
    width: '5%',
  },
  {
    renderType: 'TEXT',
    text: 'Total Borrow',
    width: '10%',
  },
  {
    renderType: 'TEXT',
    text: 'Borrow Cap',
    width: '10%',
  },
  {
    renderType: 'TEXT',
    text: 'Borrow Cap Usage',
    width: '15%',
    suffix: '%',
  },
  {
    renderType: 'TEXT',
    text: 'Total Supply',
    width: '10%',
  },
  {
    renderType: 'TEXT',
    text: 'Supply Cap',
    width: '10%',
  },
  {
    renderType: 'TEXT',
    text: 'Supply Cap Usage',
    width: '15%',
    suffix: '%',
  },
];

export const debtCeilintTableHeader: Header[] = [
  {
    renderType: 'TEXT',
    text: 'Total Debt',
    width: '10%',
  },
  {
    renderType: 'TEXT',
    text: 'Debt Ceiling',
    width: '10%',
  },
  {
    renderType: 'TEXT',
    text: 'Debt Usage',
    width: '15%',
    suffix: '%',
  },
];

const noCapCell = textCell('No Cap', Infinity);
const capCell = (cap: number | null, isCurrency: boolean): RenderData => {
  if (cap === 0) return noCapCell;
  return (isCurrency ? currencyCell : numberCell)(cap || 0);
};

export const formatResults = (
  data: Asset[],
  markets: Market[],
  isValuesInUSD: boolean,
  isChainMarket = false,
  showDebtCeiling = false,
): RenderData[][] => data.map((row) => {
  const {
    asset,
    market,
    price,
    marketId,
    totalBorrow: totalBorrowInUsd,
    totalSupply: totalSupplyInUsd,
    borrowCap: borrowCapInToken,
    supplyCap: supplyCapInToken,
    isolationModeInformation,
  } = row;

  const totalBorrowInToken = totalBorrowInUsd / price;
  const totalSupplyInToken = totalSupplyInUsd / price;
  const borrowCapInUsd = borrowCapInToken !== null ? borrowCapInToken * price : null;
  const supplyCapInUsd = supplyCapInToken !== null ? supplyCapInToken * price : null;

  const borrowCapUsage = borrowCapInUsd ? totalBorrowInUsd / borrowCapInUsd : null;
  const supplyCapUsage = supplyCapInUsd ? totalSupplyInUsd / supplyCapInUsd : null;

  const marketData = markets.find((m) => m.id === marketId);
  const marketCell = isChainMarket
    ? chainCell(market, market)
    : textCell(marketData?.name || market);

  const debtCeilingCells = isolationModeInformation?.isIsolated
    ? [
      currencyCell(isolationModeInformation.isolationModeTotalDebtUsd!),
      capCell(isolationModeInformation.debtCeilingUsd!, true),
      progressCell(
        isolationModeInformation.isolationModeTotalDebtUsd!
      / isolationModeInformation.debtCeilingUsd!,
      ),
    ]
    : [
      textCell('-', 0),
      textCell('-', 0),
      textCell('-', 0),
    ];

  if (isValuesInUSD) {
    return [
      marketCell,
      assetCell(asset),
      currencyCell(totalBorrowInUsd),
      capCell(borrowCapInUsd, isValuesInUSD),
      progressCell(borrowCapUsage),
      capCell(totalSupplyInUsd, isValuesInUSD),
      currencyCell(supplyCapInUsd),
      progressCell(supplyCapUsage),
      ...(showDebtCeiling ? debtCeilingCells : []),
    ];
  }

  return [
    marketCell,
    assetCell(asset),
    numberCell(totalBorrowInToken),
    capCell(borrowCapInToken, isValuesInUSD),
    progressCell(borrowCapUsage),
    numberCell(totalSupplyInToken),
    capCell(supplyCapInToken, isValuesInUSD),
    progressCell(supplyCapUsage),
    ...(showDebtCeiling ? debtCeilingCells : []),
  ];
});
