import { FC } from 'react';
import { Link, Typography } from '@chaos/ui';
import { RoutePath } from 'src/config/routes';
import { CustomIcon } from 'src/components/custom-icon';
import { NavLink as RouterLink } from 'react-router-dom';
import { HIDDEN_COLLAPSED_STYLES } from './styles';

export const SignInSection: FC = () => (
  <Link
    to={RoutePath.SignIn}
    component={RouterLink}
    color="inherit"
    underline="none"
    sx={{
      display: 'flex',
      alignItems: 'center',
      columnGap: 2,
      py: '20px',
      height: '64px',
    }}
  >
    <CustomIcon icon="user" />
    <Typography sx={HIDDEN_COLLAPSED_STYLES}>Sign In</Typography>
  </Link>
);
