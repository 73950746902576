import { Link } from 'react-router-dom';

import { Box } from '@chaos/ui/box';
import { Grid } from '@chaos/ui/grid';
import { Nullable, Team } from '@chaos/types';
import { RoutePath } from 'src/config/routes';
import { Typography } from '@chaos/ui/typography';
import { formatDateAndTime } from 'src/utils/formatters';
import { IconButton } from '@chaos/ui/icon-button';

import { CustomIcon } from '../custom-icon';
import { useRandomAvatar } from '../../hooks';

interface TeamRowProps {
  team: Team & {
    count: number
    admins: Nullable<string>[]
  }
}

export const TeamRow = ({ team }: TeamRowProps): JSX.Element => {
  const {
    id,
    thumbnail,
    name,
    count,
    admins,
    createdAt,
    protocolWebsite,
    protocolBlockchain,
  } = team;
  const { randomAvatar } = useRandomAvatar(id);

  return (
    <Box
      sx={{
        marginX: 3,
        paddingY: 4,
        ':not(:last-of-type)': {
          borderBottom: '1px solid black',
        },
      }}
    >
      <Grid
        container
        wrap="nowrap"
        columnSpacing={3}
        rowSpacing={2}
        sx={{
          flexDirection: { xs: 'column', lg: 'row' },
          alignItems: { xs: 'start', lg: 'center' },
        }}
      >
        <Grid
          item
          zeroMinWidth
          sx={{
            display: 'flex',
          }}
          xs={12}
          lg={3}
        >
          <Box
            component="img"
            src={thumbnail ? thumbnail.url : randomAvatar}
            alt="team-avatar"
            sx={{
              mr: 3,
              display: 'block',
              objectFit: 'contain',
              width: 48,
              height: 48,
            }}
          />
          <Grid
            item
            zeroMinWidth
          >
            <Typography noWrap>{name}</Typography>
            <Typography
              variant="caption"
              component="time"
              noWrap
              sx={{ display: 'block' }}
            >
              {formatDateAndTime(createdAt?.toDate())}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          zeroMinWidth
          xs={12}
          lg={1}
        >
          <Typography variant="caption">Members</Typography>
          <Typography sx={{ fontWeight: 'fontWeightLight' }} noWrap>
            {count}
          </Typography>
        </Grid>

        <Grid
          item
          zeroMinWidth
          xs={12}
          lg={3}
        >
          <Typography variant="caption">Admin</Typography>
          <Typography sx={{ fontWeight: 'fontWeightLight' }} noWrap>
            {admins?.[0] || '-'}
          </Typography>
        </Grid>

        <Grid
          item
          zeroMinWidth
          xs={12}
          lg={2}
        >
          <Typography variant="caption" noWrap>Protocol Blockchain</Typography>
          <Typography sx={{ fontWeight: 'fontWeightLight' }} noWrap>
            {protocolBlockchain || '-'}
          </Typography>
        </Grid>

        <Grid
          item
          zeroMinWidth
          xs={12}
          lg={2}
        >
          <Typography variant="caption" noWrap>Protocol Website</Typography>
          <Typography
            sx={{
              fontWeight: 'fontWeightLight',
              ...(protocolWebsite
                ? {
                  background:
                      'linear-gradient(154.16deg, #31D8BF -3.9%, #0965F1 126.56%)',
                  backgroundClip: 'text',
                  color: 'transparent',
                }
                : {}),
            }}
            noWrap
          >
            {protocolWebsite || '-'}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          lg={1}
          display="flex"
          justifyContent="flex-end"
          sx={{ pl: { xs: '8px !important', lg: 'inherit' } }}
        >
          <IconButton
            component={Link}
            to={RoutePath.Team.Edit.replace(':teamId', id)}
            size="large"
          >
            <CustomIcon icon="edit" />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};
