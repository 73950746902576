import {
  FC, useState, createContext, useMemo,
} from 'react';

export interface ClientContextValue {
  isChaosCloud: boolean
  setIsChaosCloud: (isChaosCloud: boolean) => void
}

const defaultValue: ClientContextValue = {
  isChaosCloud: false,
  setIsChaosCloud: () => {},
};

export const ClientContext = createContext<ClientContextValue>(defaultValue);

export const ClientProvider: FC = (props) => {
  const [isChaosCloud, setIsChaosCloud] = useState(false);
  const contextValue = useMemo(() => ({
    isChaosCloud,
    setIsChaosCloud,
  }), [isChaosCloud]);

  return (
    <ClientContext.Provider value={contextValue}>
      {props.children}
    </ClientContext.Provider>
  );
};
