import { Box, InputLabel, CustomReactSelect } from '@chaos/ui';
import { wrappedSymbolToIconSymbol } from '@chaos/ui/utils/icon-helper';
import { MarketType } from '../types';

type Props = {
  markets: MarketType[],
  market: MarketType,
  sweepAsset: string,
  marketsAssets: string[],
  onSelectMarket: (market: MarketType) => Promise<void>;
  onSelectSweepAsset: (asset: string) => Promise<void>;
};

const MarketAssetSelects = ({
  markets,
  market,
  sweepAsset,
  marketsAssets,
  onSelectMarket,
  onSelectSweepAsset,
}: Props) => {
  const marketsSelectOptions = markets.map((m) => ({
    value: m,
    label: m,
    cryptoIcon: m.toLowerCase(),
  }));

  const assetsOptions = marketsAssets.map((a) => ({
    value: a,
    label: a,
    cryptoIcon: wrappedSymbolToIconSymbol(a.toLowerCase()),
  })) || [];

  return (
    <Box display="flex" gap={3} maxWidth="720px">
      <Box flex="1">
        <CustomReactSelect
          name="market"
          label="Chain name"
          placeholder="Select chain"
          options={marketsSelectOptions}
          value={{
            value: market,
            label: market,
            cryptoIcon: market.toLowerCase(),
          }}
          onChange={(selected) => {
            if (!selected) return;
            const newSelectedMarket = selected.value as MarketType;
            void onSelectMarket(newSelectedMarket);
          }}
        />
      </Box>
      <Box flex="1">
        <InputLabel>Sweep asset</InputLabel>
        <CustomReactSelect
          name="asset"
          placeholder="Select Asset"
          options={assetsOptions}
          value={{
            value: sweepAsset,
            label: sweepAsset,
            cryptoIcon: wrappedSymbolToIconSymbol(sweepAsset.toLowerCase()),
          }}
          onChange={(selected) => {
            if (!selected) return;
            void onSelectSweepAsset(selected.value);
          }}
        />
      </Box>
    </Box>
  );
};

export default MarketAssetSelects;
