import {
  Box,
  CustomIcon,
  CustomReactSelect,
  IconButton,
  InputLabel,
  NumberInput,
  Tooltip,
} from '@chaos/ui';
import { formatCurrencyStringAmount, formatPercent, formatPercentDecimal } from '@chaos/ui/utils/formatters';
import {
  useEffect, useMemo, useState, Dispatch, SetStateAction,
} from 'react';
import { useAssetsData } from '../../AssetProtectionTool/useAssetsData';
import { AssetOptions } from '../types';

type Props = {
  asset: string
  liquidationThreshold: number
  index: number
  options: AssetOptions
  error?: string
  canAddRow: boolean
  setDefaultLts: Dispatch<SetStateAction<Record<string, number>>>
  setFieldValue(key: string, value: string | number): Promise<void>
  onAddRow(): void
  onRemoveRow(index: number): void
};

const useSetDefaultRowValues = ({
  runWhen,
  onSet,
}: {
  runWhen: boolean
  onSet: () => void
}) => {
  const [isDone, setDone] = useState(false);
  useEffect(() => {
    if (runWhen && !isDone) {
      setDone(true);
      onSet();
    }
  }, [runWhen, onSet, isDone]);
};

export const ParamExplorerFormRow = ({
  options,
  asset,
  liquidationThreshold,
  index,
  error,
  canAddRow,
  setDefaultLts,
  setFieldValue,
  onRemoveRow,
  onAddRow,
}: Props) => {
  const keys = useMemo(
    () => ({
      asset: `assetsValues.${index}.asset`,
      liquidationThreshold: `assetsValues.${index}.liquidationThreshold`,
    }),
    [index],
  );

  const { isLoadingAssetsData, assetsDataResponse } = useAssetsData(
    'Ethereum',
    asset,
    asset,
    true,
  );
  const assetData = assetsDataResponse?.pumpedAsset;
  const currentLiquidationThreshold = assetData?.reserveLiquidationThreshold;
  const hasLiquidationThreshold = currentLiquidationThreshold !== undefined;
  const tooltip = assetData ? (
    <div>
      <strong>Current Parameters</strong>
      {' '}
      <br />
      Total Supplied:
      {formatCurrencyStringAmount(assetData.totalLiquidity)}
      {' '}
      <br />
      Available Liquidity:
      {formatCurrencyStringAmount(assetData.availableLiquidity)}
      {' '}
      <br />
      Utilization Rate:
      {formatPercent(assetData.utilizationRate)}
      {' '}
      <br />
      Max LTV:
      {formatPercentDecimal(assetData.baseLTVasCollateral)}
      {' '}
      <br />
      Liquidation Threshold:
      {formatPercentDecimal(assetData.reserveLiquidationThreshold)}
      {' '}
      <br />
      Liquidation Penalty:
      {formatPercentDecimal(assetData.reserveLiquidationBonus)}
      {' '}
      <br />
      Reserve Factor:
      {formatPercentDecimal(assetData.reserveFactor)}
    </div>
  ) : '';

  useSetDefaultRowValues({
    runWhen: hasLiquidationThreshold,
    onSet: () => {
      setDefaultLts((prev) => {
        const lt = Number(currentLiquidationThreshold);

        if (!lt) return prev;

        const newLt = { [asset]: lt };

        if (!prev) return newLt;

        return { ...prev, ...newLt };
      });
      void setFieldValue(keys.liquidationThreshold, currentLiquidationThreshold || 0);
    },
  });

  return (
    <Box display="flex" gap={2}>
      <Box flex="1">
        <CustomReactSelect
          label={index === 0 ? 'Asset' : ''}
          name={keys.asset}
          placeholder="Select Asset"
          options={options}
          value={{
            value: asset,
            label: asset,
            cryptoIcon: asset.toLowerCase(),
          }}
          onChange={(selected) => {
            if (!selected) return;
            const newSelectedAsset = selected.value;
            void setFieldValue(keys.asset, newSelectedAsset);
          }}
        />
      </Box>
      <Box flex="1">
        {index === 0 ? <InputLabel>Liquidation Threshold</InputLabel> : null}
        <Tooltip arrow title={tooltip} placement="bottom">
          <Box>
            <NumberInput
              disabled={isLoadingAssetsData}
              name={keys.liquidationThreshold}
              value={liquidationThreshold}
              onChange={async (value: number) => {
                void setFieldValue(keys.liquidationThreshold, value);
              }}
              isPercent
              error={error}
            />
          </Box>
        </Tooltip>
      </Box>
      <Box alignSelf="flex-end">
        {index === 0 ? (
          <IconButton
            type="button"
            color="primary"
            disabled={!canAddRow}
            onClick={() => onAddRow()}
          >
            <CustomIcon icon="plus" />
          </IconButton>
        ) : (
          <IconButton
            color="secondary"
            type="button"
            onClick={() => onRemoveRow(index)}
          >
            <CustomIcon icon="trash-can" />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
