import { useState } from 'react';

import { Box } from '@chaos/ui/box';
import { Typography } from '@chaos/ui/typography';
import { Button } from '@chaos/ui/button';
import { Tooltip } from '@chaos/ui/tooltip';
import { JSONViewer } from 'src/components/json-viewer';
import { useTickerSvg } from 'src/hooks';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const saveDataAsJSONFile = (data: Record<string, unknown>) => {
  const blob = new Blob([JSON.stringify(data)], { type: 'text/json' });
  const link = document.createElement('a');

  link.download = 'configuration.json';
  link.href = window.URL.createObjectURL(blob);
  link.dataset.downloadurl = ['text/json', link.download, link.href].join(':');

  const evt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });

  link.dispatchEvent(evt);
  link.remove();
};

interface ConfigurationModalProps {
  jsonValue: Record<string, unknown>
  onClose?: () => void
}

export const ConfigurationModal = ({
  onClose,
  jsonValue,
}: ConfigurationModalProps): JSX.Element => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const showTooltip = () => setIsTooltipOpen(true);
  const closeTooltip = () => setIsTooltipOpen(false);

  const data = {
    // eslint-disable-next-line
    tokenPair: `${jsonValue.token1}/${jsonValue.token2}`,
    initMockValue: jsonValue.initMockValue,
    // frequencyPriceChange: jsonValue.frequencyPriceChange,
    priceDelta: jsonValue.priceDelta,
    priceFunction: jsonValue.priceFunction,
  };

  const onCopy = () => {
    navigator.clipboard
      .writeText(JSON.stringify(data))
      .then(showTooltip)
      .catch((e) => console.log('Clipboard error: ', e));
  };
  const onDownload = () => {
    saveDataAsJSONFile(data);
  };

  // @ts-expect-error unknown
  const img1 = useTickerSvg(jsonValue.token1);
  // @ts-expect-error unknown
  const img2 = useTickerSvg(jsonValue.token2);

  return (
    <>
      <Box component="header">
        <Box className="modal-header" sx={{ py: '36px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', mr: 3 }}>
            <Box
              component="img"
              src={img1}
              alt="token image"
              sx={{
                position: 'relative', zIndex: 1, width: 48, height: 48,
              }}
            />
            <Box
              component="img"
              src={img2}
              alt="token image"
              sx={{
                position: 'relative', ml: -2, width: 48, height: 48,
              }}
            />
          </Box>
          <Typography component="h2" variant="h2" textAlign="center" noWrap>
            {jsonValue.token1}
            {' '}
            /
            {jsonValue.token2}
          </Typography>
        </Box>
      </Box>

      <Box className="modal-body">
        <Box sx={{ pb: 5 }}>
          <JSONViewer json={data} showToolbar />
        </Box>
      </Box>

      <Box component="footer">
        <Box
          className="modal-footer"
          sx={{
            py: 5,
            '> :not(:last-of-type)': {
              mr: 3,
            },
          }}
        >
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <ClickAwayListener onClickAway={closeTooltip}>
            <Tooltip
              classes={{
                tooltip: 'light',
                arrow: 'light',
              }}
              arrow
              disableFocusListener
              disableHoverListener
              disableTouchListener
              open={isTooltipOpen}
              onClose={closeTooltip}
              title="Copied to clipboard"
              placement="top"
            >
              <Button color="secondary" onClick={onCopy}>
                Copy
              </Button>
            </Tooltip>
          </ClickAwayListener>

          <Button color="primary" onClick={onDownload}>
            Download
          </Button>
        </Box>
      </Box>
    </>
  );
};
