import { useEffect, useMemo, useState } from 'react';
import { EconnomicRecommendationParams } from '@chaos/types';
import { getSimulationParams } from 'src/services/engine';
import { useCurrentTeam } from './useCurrentTeam';

export const useEconnomicRecommendationParams = (id: string): {
  isLoading: boolean
  econnomicRecommendationParams?: EconnomicRecommendationParams
} => {
  const team = useCurrentTeam();
  const [isLoading, setIsLoading] = useState(true);
  const [params, setParams] = useState<EconnomicRecommendationParams>();

  useEffect(() => {
    if (team?.authKey) {
      setIsLoading(true);
      void getSimulationParams(team.authKey, id).then((p) => {
        setParams(p);
        setIsLoading(false);
      });
    }
  }, [team?.authKey, id]);

  const result = useMemo(
    () => ({
      isLoading, econnomicRecommendationParams: params,
    }),
    [isLoading, params],
  );

  return result;
};
