import { RootState } from '../store/types';

/*
 isCollectionLoading:
 instead of using "react-redux-firebase" isLoaded() which only stays true for this first query,
 but not when passing dynamic querys we use the following, trying to find the right query key on
 firestore ongoing requests.
 If undefined is returned it means we haven't used the best 'key' that matches the query
 to debug log the keys and try to find the right match
*/
export function isCollectionLoaded(
  collection: string,
  state: RootState,
): boolean | undefined {
  // eslint-disable-next-line
  const keys = Object.keys(state.firestore.status.requesting);
  const bestKey = keys.find((key) => key.startsWith(collection));
  // console.log(
  //   "loading:",
  //   state.firestore.status.requesting[bestKey],
  //   "loaded:",
  //   state.firestore.status.requested[bestKey],
  //   "ts:",
  //   state.firestore.status.timestamps[bestKey],
  // )
  // eslint-disable-next-line
  return !state.firestore.status.requesting[bestKey];
}
