import { Outlet, useLocation, useParams } from 'react-router-dom';
import { ErrorBoundaryComponent } from '@chaos/ui/error-boundary';
import { MainLayout } from 'src/components/layouts';
import { useDashboards, usePageTitle } from 'src/hooks';
import { PageTracker } from 'src/components/page-tracker';

export const OverviewPage = PageTracker((): JSX.Element => {
  usePageTitle('Dashboard');
  const { privateDashboards, dashboards: firebaseDashboards } = useDashboards();
  const { id } = useParams<{ id: string }>();
  const location = useLocation();

  const allDashboards = [
    ...privateDashboards,
    ...firebaseDashboards.map((dashboard) => ({
      id: dashboard.id,
      title: dashboard.name,
    })),
  ];
  const subRouteName = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

  const currentDashboardOption = allDashboards.find((dashboard) => dashboard.id === (
    id || subRouteName));

  return (
    <MainLayout
      headerProps={{
        pageTitle: currentDashboardOption?.title || 'Risk Tool',
        breadcrumbsLinks: [{
          title: 'Risk Tools',
          href: '/',
        }],
      }}
    >
      <ErrorBoundaryComponent>
        <Outlet />
      </ErrorBoundaryComponent>
    </MainLayout>
  );
});
