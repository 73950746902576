import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { MainLayout } from 'src/components/layouts';
import { RoutePath } from 'src/config/routes';
import { useAppSelector } from 'src/store';
import { usePageTitle } from 'src/hooks';
import { EmptyState } from 'src/components/empty-state';
import { PageTracker } from 'src/components/page-tracker';
import { useBlockchainAssertions } from 'src/hooks/useBlockchainAssertions';
import { ScriptResourceTable } from 'src/components/script-resource-table';
import { ScriptResource } from '@chaos/types';

export const AssertionWizardPage = PageTracker((): JSX.Element => {
  usePageTitle('Assertions');
  const navigate = useNavigate();
  const { isLoading, blockchainAssertions } = useBlockchainAssertions();
  const isAdmin = useAppSelector((state) => state.firebase.profile.role === 'admin');

  const isAssertionExists = blockchainAssertions.length > 0;

  return (
    <MainLayout
      height={0}
      headerProps={{
        pageTitle: 'Assertions',
        buttons: isAdmin ? [{
          children: 'Create Assertion',
          onClick: () => navigate(RoutePath.AssertionWizard.Create),
        }] : undefined,
      }}
    >
      {isLoading ? <CircularProgress /> : isAssertionExists ? (
        <ScriptResourceTable
          data={blockchainAssertions}
          rowHref={(assertion: ScriptResource) => RoutePath.AssertionWizard.Details.replace(':id', assertion.id)}
        />
      ) : <EmptyState type="assertions" />}
    </MainLayout>
  );
});
