import { Header, RenderData } from '@chaos/types';
import {
  ChaosTable, CustomReactSelect, labelsCell, textCell,
} from '@chaos/ui';
import { useState } from 'react';
import { ModelRunPermutation, ModelRunResult } from 'src/pages/param-recommendations-tool/data/types';
import { getPermutationDropdownOptions } from '../utils';

const headers: Header[] = [{
  renderType: 'TEXT',
  text: 'Name',
}, {
  renderType: 'TEXT',
  text: 'Message',
}, {
  renderType: 'TEXT',
  text: 'Status',
}];

export type Props = {
  modelRunResults: ModelRunResult;
};

const RulesTable = ({ modelRunResults }: Props) => {
  const { permutations, dropdownOptions } = getPermutationDropdownOptions(modelRunResults);

  const [
    selectedPermutation,
    setSelectedPermutation,
  ] = useState<ModelRunPermutation>(permutations[0]);

  const data: RenderData[][] = selectedPermutation.rules.map((rule) => [
    textCell(rule.ruleName),
    textCell(rule.message),
    labelsCell(rule.result ? 'OK' : 'ERROR', rule.result ? 'Pass' : 'Fail'),
  ]);

  return (
    <ChaosTable
      title="Rules"
      headers={headers}
      data={data}
      isFilterHidden
      isSettingsHidden
      headerSuffixComponent={(
        <CustomReactSelect
          options={dropdownOptions}
          value={dropdownOptions.find(
            (option) => option.value === selectedPermutation.permutation_id,
          )}
          onChange={(option) => {
            if (!option) return;
            const newPermutation = permutations.find(
              ({ permutation_id }) => option.value === permutation_id,
            );
            if (!newPermutation) return;

            setSelectedPermutation(newPermutation);
          }}
        />
      )}
    />
  );
};

export default RulesTable;
