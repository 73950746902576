export interface TeamState {
  teamId: string | null
}

const initialState: TeamState = {
  teamId: null,
};

interface Action {
  type: string
  payload?: string
}

// eslint-disable-next-line
export function teamReducer(state = initialState, action: Action): TeamState {
  switch (action.type) {
    case 'team/SET_TEAM_ID':
      return {
        teamId: action.payload || null,
      };
    default:
      return state;
  }
}

export const setTeamId = (teamId: string) => ({
  type: 'team/SET_TEAM_ID',
  payload: teamId,
});
