import {
  Box, Collapse, Grid, Menu,
} from '@chaos/ui';
import {
  FC, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { NavIcon } from 'src/components/nav-icon';
import { IconButton } from '@chaos/ui/icon-button';
import { CustomIcon } from 'src/components/custom-icon';
import { MultiNavItemProps } from 'src/components/app-nav/types';
import { HIDDEN_COLLAPSED_STYLES } from 'src/components/app-nav/styles';
import { MultiNavLinkList } from './multi-nav-link-list';
import { COLLAPSED_MENU_STYLES, getMultiNavTitleStyles } from './styles';

export const MultiNavItem: FC<MultiNavItemProps> = ({
  title,
  links,
  closeMenu,
  menuOpened,
  icon,
}) => {
  const location = useLocation();
  const isSomeLinkActive = useMemo(
    () => links.map((link) => link.to).includes(location.pathname),
    [links, location.pathname],
  );

  const [open, setOpen] = useState<boolean>(isSomeLinkActive);
  const navAnchorElRef = useRef<null | HTMLElement>(
    null,
  );

  const toggleOpen = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  useEffect(() => {
    if (!menuOpened) {
      setOpen(false);
    }
  }, [menuOpened]);

  const isLinkActiveInClose = useMemo(
    () => isSomeLinkActive && !open,
    [isSomeLinkActive, open],
  );

  return (
    <Box>
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        className="clickable"
        justifyContent="space-between"
        sx={{
          fontWeight: 'fontWeightBold',
          whiteSpace: 'nowrap',
        }}
      >
        <Box
          sx={getMultiNavTitleStyles(isLinkActiveInClose)}
          className={isLinkActiveInClose ? 'active' : ''}
          onClick={toggleOpen}
          ref={navAnchorElRef}
        >
          <NavIcon icon={icon} />
          <Box sx={HIDDEN_COLLAPSED_STYLES}>
            <Box
              component="span"
            >
              {title}
            </Box>
            <IconButton
              sx={{
                padding: 0,
                marginLeft: 2,
                svg: {
                  transition: 'transform 0.3s ease',
                  transform: `rotate(${open ? 180 : 0}deg)`,
                },
              }}
              size="small"
            >
              <CustomIcon icon="chevron-down-filled" />
            </IconButton>
          </Box>
        </Box>
      </Grid>
      <Collapse in={open && menuOpened} unmountOnExit>
        <MultiNavLinkList closeMenu={closeMenu} links={links} />
      </Collapse>
      <Menu
        anchorEl={navAnchorElRef.current}
        anchorOrigin={{
          vertical: 2,
          horizontal: 64,
        }}
        id="nav-links-menu"
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={open && !menuOpened}
        onClose={toggleOpen}
        sx={COLLAPSED_MENU_STYLES}
      >
        <MultiNavLinkList closeMenu={closeMenu} links={links} />
      </Menu>
    </Box>
  );
};
