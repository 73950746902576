import { useState, useMemo } from 'react';
import { Box } from '@chaos/ui/box';
import { RoutePath } from 'src/config/routes';
import { MainLayout } from 'src/components/layouts';
import { Tabs } from '@chaos/ui/tabs';
import { PageTracker } from 'src/components/page-tracker';
import { EmptyState } from 'src/components/empty-state';
import { Notification } from './notification';

interface AccessData {
  uid: string
  date: Date | string
  firstName: string
  lastName: string
  photoUrl: string | null
}

interface SimulationData {
  sid: string
  date: Date | string
  name: string
}

type NotificationsType = 'simulation' | 'access';

export interface NotificationsData {
  id: string
  type: NotificationsType
  access?: AccessData
  simulation?: SimulationData
}

export const NotificationsPage = PageTracker(() => {
  const [tabIndex, setTabIndex] = useState(0);
  const [notifications] = useState<NotificationsData[] | null>(null);
  const simulations = useMemo(() => notifications?.filter((n) => n.type === 'simulation'), [notifications]);
  const accesses = useMemo(() => notifications?.filter((n) => n.type === 'access'), [notifications]);

  const tabs = [
    { label: 'All', count: notifications?.length || 0 },
    { label: 'Simulations', count: simulations?.length || 0 },
    { label: 'Access', count: accesses?.length || 0 },
  ];

  const onTabChange = (_: React.SyntheticEvent, newValue: number) => setTabIndex(newValue);

  return (
    <MainLayout
      headerProps={{
        pageTitle: 'Notifications',
        breadcrumbsLinks: [{
          title: 'Profile',
          href: RoutePath.Profile.Home,
        }],
      }}
    >
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Box mb={5}>
          <Tabs
            tabs={tabs}
            value={tabIndex}
            onChange={onTabChange}
          />
        </Box>

        <Box display="flex" flexDirection="column" flexGrow={1} gap={3}>
          {tabIndex === 0 && (
            notifications && notifications.length > 0
              ? notifications.map((data) => <Notification key={data.id} data={data} />)
              : <EmptyState type="visual data" />
          )}

          {tabIndex === 1 && (
            simulations && simulations.length > 0
              ? simulations.map((data) => <Notification key={data.id} data={data} />)
              : <EmptyState type="visual data" />

          )}

          {tabIndex === 2 && (
            accesses && accesses.length > 0
              ? accesses.map((data) => <Notification key={data.id} data={data} />)
              : <EmptyState type="visual data" />
          )}
        </Box>
      </Box>
    </MainLayout>
  );
});
