import React, { ReactEventHandler, useCallback } from 'react';
import { SwipeableDrawer, Box, Typography } from '@chaos/ui';
import { IconButton } from '@chaos/ui/icon-button';
import { BlurBackground } from 'src/components/blur-background';
import { CustomIcon } from '../custom-icon';

interface BaseHeaderProps {
  title: string,
  subtitle?: string,
}
const BaseHeader:React.FC<BaseHeaderProps> = ({ title, subtitle }) => (
  <Box display="flex" flexDirection="column">
    {subtitle && <Typography variant="caption">{subtitle}</Typography>}
    <Typography variant="h2">{title}</Typography>
  </Box>
);

interface SidebarPopupProps {
  isOpen: boolean
  title?: string
  subtitle?: string
  header?: React.ReactNode
  footer?: React.ReactNode
  onClose: React.ReactEventHandler
}

export const SidebarPopup:React.FC<SidebarPopupProps> = ({
  isOpen,
  onClose,
  children,
  title,
  subtitle,
  header,
  footer,
}) => {
  const onOpenHandler = useCallback<ReactEventHandler>(() => {}, []);
  return (
    <SwipeableDrawer
      disableSwipeToOpen
      anchor="right"
      open={isOpen}
      onOpen={onOpenHandler}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '600px',
          filter: 'drop-shadow(0px 16px 32px rgba(0, 0, 0, 0.08))',
          background: '#30343B',
        },
      }}
    >
      <Box
        py={3}
        px={5}
        height="100%"
        display="flex"
        flexDirection="column"
        position="relative"
        zIndex={5}
        overflow="hidden"
      >
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
          {header || <BaseHeader title={title || ''} subtitle={subtitle} />}
          <IconButton onClick={onClose} size="large" sx={{ ml: 'auto' }}>
            <CustomIcon icon="close" />
          </IconButton>
        </Box>
        <Box flex={1} mb={footer ? 2 : 0} overflow="auto">
          {children}
        </Box>
        {footer}
      </Box>
      <BlurBackground sx={{ zIndex: 1 }} />
    </SwipeableDrawer>
  );
};
