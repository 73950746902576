import { FC } from 'react';
import {
  CircularProgress, GridSize, GridSpacing, SxProps,
} from '@mui/material';

import { Grid } from '@chaos/ui/grid';

import { SelectorGridItem } from './selector-grid-item';

export type GridResponsiveStyleValue<T> = T | {
  xs?: T
  sm?: T
  md?: T
  xl?: T
};

export type GridItemSize = GridResponsiveStyleValue<GridSize>;

export interface SelectorItem {
  id: string
  name: string
  icon?: string
  caption?: string
  imgSrc?: string
  description?: string
}

export type SelectorLayout = 'rows' | 'columns';
export type SelectorAlign = 'left' | 'center';

interface SelectorProps {
  selectedValue: string | string[] | undefined
  columnSpacing?: GridResponsiveStyleValue<GridSpacing>
  rowSpacing?: GridResponsiveStyleValue<GridSpacing>
  gridItemSize?: GridItemSize
  data: SelectorItem[]
  isLoading: boolean
  onItemClick: (item: SelectorItem) => void
  layout?: SelectorLayout
  align?: SelectorAlign
  withImage?: boolean
  imageSx?: SxProps,
}

export const Selector: FC<SelectorProps> = ({
  data,
  isLoading,
  selectedValue,
  onItemClick,
  gridItemSize,
  columnSpacing = 5,
  rowSpacing = 5,
  layout = 'columns',
  align = 'left',
  withImage,
  imageSx,
}) => {
  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Grid container columnSpacing={columnSpacing} rowSpacing={rowSpacing}>
      {data.map((item) => {
        let isDisabled = false;
        let isSelected;

        if (Array.isArray(selectedValue)) {
          isSelected = selectedValue.includes(item.id);
        } else {
          isSelected = selectedValue === item.id;
          isDisabled = selectedValue !== undefined && selectedValue !== item.id;
        }
        return (
          <SelectorGridItem
            key={item.id}
            item={item}
            align={align}
            layout={layout}
            withImage={withImage}
            isDisabled={isDisabled}
            isSelected={isSelected}
            onItemClick={onItemClick}
            gridItemSize={gridItemSize}
            imageSx={imageSx}
          />
        );
      })}
    </Grid>
  );
};
