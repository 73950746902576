import { Box, Button, Typography } from '@chaos/ui';
import React from 'react';
import { ModalWrapper } from '../modals';

interface ConfirmationPromptDialogProps {
  title: string
  isShow: boolean
  onClose: () => void
  onConfirm: () => void
  confirmButtonText?: string
  cancelButtonText?: string
}

export const ConfirmationPromptDialog:React.FC<ConfirmationPromptDialogProps> = ({
  isShow,
  title,
  children,
  onClose,
  onConfirm,
  cancelButtonText = 'No',
  confirmButtonText = 'Yes',
}) => (
  <ModalWrapper open={isShow} maxWidth={600} onClose={onClose}>
    <Box component="header">
      <Box className="modal-header" sx={{ py: '36px' }}>
        <Typography variant="h2" component="h2">{title}</Typography>
      </Box>
    </Box>
    <Box className="modal-body">
      {children}
    </Box>
    <Box component="footer">
      <Box className="modal-footer" sx={{ py: 5 }}>
        <Button onClick={onClose} color="secondary" sx={{ ml: 'auto', mr: '24px' }}>
          {cancelButtonText}
        </Button>
        <Button
          color="error"
          onClick={onConfirm}
        >
          {confirmButtonText}
        </Button>
      </Box>
    </Box>
  </ModalWrapper>
);
