import { FC, useRef } from 'react';
import { Field, FieldProps } from 'formik';

import { Box } from '@chaos/ui/box';
import { TextField } from '@chaos/ui/text-field';
import { InputLabel } from '@chaos/ui/input-label';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
} from '@chaos/ui/autocomplete';
import { validateEmail } from 'src/utils/validate';
import { Icon } from '@chaos/ui/icon';
import { Chip } from '@chaos/ui/chip';

interface EmailFieldProps {
  name: string
  label: string
  placeholder: string
}

export const EmailsField: FC<EmailFieldProps> = ({
  name,
  label,
  placeholder,
}): JSX.Element => {
  const chipsWrapRef = useRef<null | HTMLElement>(null);

  const scrollChipsWrap = () => {
    chipsWrapRef?.current?.scrollTo(0, chipsWrapRef?.current?.scrollHeight || 0);
  };

  return (
    <>
      {label && <InputLabel>{label}</InputLabel>}
      <Field name={name}>
        {({ field: { value }, form }: FieldProps) => (
          <Autocomplete
            multiple
            freeSolo
            id={name}
            options={[]}
            value={value as string[] | undefined}
            onChange={(e, newValue) => {
              e.preventDefault();
              form.setFieldValue(name, newValue);
              scrollChipsWrap();
            }}
            disableClearable
            renderInput={(params: AutocompleteRenderInputParams) => {
              // eslint-disable-next-line
              params.inputProps.onKeyDown = (event) => {
                switch (event.key) {
                  case ',':
                  case ' ': {
                    event.preventDefault();
                    event.stopPropagation();
                    if (event.currentTarget.value.length > 0) {
                      form.setFieldValue(name, [
                        ...value as string[],
                        event.currentTarget.value,
                      ]);
                    }
                    break;
                  }
                  default:
                    break;
                }
              };
              return (
                <TextField {...params} placeholder={placeholder} fullWidth />
              );
            }}
            renderTags={(tagValue, getTagProps) => (
              <Box
                ref={chipsWrapRef}
                sx={{
                  overflowY: 'auto',
                  mt: -1,
                  pt: 1,
                }}
              >
                {tagValue.map((option: unknown, index) => {
                  const isEmail = validateEmail(option as string);
                  return (
                    <Chip
                      color={isEmail ? 'success' : 'error'}
                      {...getTagProps({ index })}
                      icon={
                        isEmail ? <Icon name="Face" /> : <Icon name="Error" />
                      }
                      label={option as string}
                    />
                  );
                })}
              </Box>
            )}
            sx={{
              '& .MuiOutlinedInput-root': {
                alignItems: 'flex-start',
              },
            }}
          />
        )}
      </Field>
    </>
  );
};
