import {
  useState, memo, useCallback, useEffect, useMemo,
} from 'react';
import Snackbar from '@mui/material/Snackbar';
import { v4 as uuidv4 } from 'uuid';
import {
  Header, RangeHeader, RenderData, RenderType,
} from '@chaos/types';
import { CustomSlice } from '@chaos/ui/charts';
import { Tooltip } from '@chaos/ui/tooltip';
import { Link } from '@chaos/ui/link';
import { Button } from '@chaos/ui/button';
import { IconButton } from '@chaos/ui/icon-button';
import { Box } from '@chaos/ui/box';
import { Typography } from '@chaos/ui/typography';
import { useRandomAvatar } from 'src/hooks';
import { wrappedSymbolToIconSymbol } from 'src/utils/icon-helper';
import { VictoryPie } from 'victory';
import { Alert, CryptoIcon } from '@chaos/ui';
import { ProgressBar } from '@chaos/ui/progress-bar';
import { CustomIcon } from '../custom-icon';
import { TagChip } from '../tag-chip';
import { colorScaleWarm } from '../charts/utils';
import { CopyButton } from '../copy-button';

interface TableRowProps {
  rowIndex: number
  data: RenderData[]
  headers: Header[]
  fixedRowHeight?: number
  showRowChevron?: boolean
  getRowImageUrl?: (rowId: number) => string | Promise<string>
  setExtraData: (extraData: { data?: Record<string, string>[], title?: string }) => void
}

const TableRow = ({
  rowIndex,
  data,
  headers,
  fixedRowHeight,
  showRowChevron,
  getRowImageUrl,
  setExtraData,
}: TableRowProps) => {
  const [rowImageUrl, setRowImageUrl] = useState('');
  const [snackbarText, setSnackbarText] = useState('');
  const { randomAvatar } = useRandomAvatar(data.find((cell) => cell.showCryptoPunk)?.text);

  useEffect(() => {
    void (async () => {
      if (!getRowImageUrl) return;
      const imageUrl = await getRowImageUrl(rowIndex);
      setRowImageUrl(imageUrl);
    })();
  }, [rowIndex, getRowImageUrl]);

  const renderCell = useCallback((currData: RenderData, currHeader: Header) => {
    const { customIcon, cryptoIcon } = currData;
    const token1 = currData.token1 && wrappedSymbolToIconSymbol(currData.token1);
    const token2 = currData.token2 && wrappedSymbolToIconSymbol(currData.token2);

    const { renderType } = currData;

    if (customIcon || cryptoIcon || token1 || token2) {
      return (
        <Tooltip title={currData.tooltipText || currData.text}>
          <Box
            overflow="hidden"
            display="flex"
            alignItems="center"
            sx={{
              display: 'flex',
              alignItems: 'center',
              overflow: 'hidden',
              svg: { width: 24, height: 24, flex: '0 0 24px' },
            }}
          >
            {customIcon && <CustomIcon icon={customIcon} />}
            {cryptoIcon && <CryptoIcon icon={cryptoIcon} />}
            {token1 && <CryptoIcon icon={token1} />}
            {token2 && <CryptoIcon sx={{ ml: 1 }} icon={token2} />}
            {!currHeader.textHidden && (
              <Typography overflow="hidden" textOverflow="ellipsis" ml={1}>
                {currData.text}
              </Typography>
            )}
          </Box>
        </Tooltip>
      );
    }

    if (renderType === 'LINK') {
      const text = currData.text.toString().substring(currData.text.toString().lastIndexOf('/') + 1);

      return (
        <Box display="flex" overflow="hidden" textOverflow="ellipsis" gap={1}>
          <Link
            className="gradient-link"
            href={currData.href || currData.text.toString()}
            underline="none"
            target="_blank"
            onClick={(event: React.SyntheticEvent) => event.stopPropagation()}
            overflow="hidden"
          >
            <Tooltip title={currData.tooltipText || currData.text} arrow>
              <Typography overflow="hidden" textOverflow="ellipsis">{text}</Typography>
            </Tooltip>
          </Link>
          {currData.copyText
              && (
              <Box className="copy-btn" display="none">
                <CopyButton text={currData.copyText} onCopy={setSnackbarText} />
              </Box>
              )}
        </Box>
      );
    }

    if (renderType === 'LABELS') {
      let tagColor = 'light';

      if (currData.status === 'OK') {
        tagColor = 'success';
      } else if (currData.status === 'WARNING') {
        tagColor = 'uploaded';
      } else if (currData.status === 'ERROR') {
        tagColor = 'error';
      } else if (currData.status === 'GOOD') {
        tagColor = 'deployed';
      } else if (currData.status === 'PROGRESS') {
        tagColor = 'progress';
      }

      return currData?.text ? (
        <Tooltip title={currData.tooltipText ? currData.tooltipText : ''}>
          <Box display="flex" overflow="hidden" textOverflow="ellipsis">
            <TagChip
              sx={{
                bgcolor: `${tagColor}.opacity50`, px: 1.5, fz: 12, width: currData.width,
              }}
              label={currData.text}
            />
            {currData.extraData && currData.extraData.title
              ? (
                <TagChip
                  sx={{
                    bgcolor: `${tagColor}.opacity20`, ml: 1, px: 1.5, fz: 12,
                  }}
                  label={currData.extraData.title}
                />
              )
              : ''}
          </Box>
        </Tooltip>
      ) : null;
    }

    if (renderType === 'ICONS' && currData.icons) {
      const { icons } = currData;

      return (
        <Tooltip title={icons.join(', ')} arrow placement="left">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              svg: { width: 24, height: 24 },
            }}
          >
            {icons.slice(0, 3).map(
              (singleIcon, idx) => (
                <CustomIcon
                  key={`${singleIcon}-${rowIndex}-${idx.toString()}`}
                  // TODO sagi - check if this is only symbols
                  icon={wrappedSymbolToIconSymbol(singleIcon)}
                  sx={{ ml: !idx ? 0 : 0.5 }}
                />
              ),
            )}
            {icons.length > 3 && (
              <Typography>
                +
                {icons.length - 3}
              </Typography>
            )}
          </Box>
        </Tooltip>
      );
    }

    if (renderType === 'CHIP') {
      const { chipIcon, text } = currData;
      return (
        <Tooltip title={currData.tooltipText ? currData.tooltipText : ''}>
          <Box
            display="flex"
            alignItems="center"
            sx={{ background: currData.chipColor || 'rgba(255, 255, 255, 0.1)', height: chipIcon ? '24px' : 'auto' }}
            borderRadius={4}
            paddingRight={chipIcon ? '16px' : '8px'}
          >
            {chipIcon && (
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '24px',
                height: '24px',
                background: 'rgba(48, 212, 193, 0.25)',
                borderRadius: '120px',
              }}
              >
                <CustomIcon
                  sx={{ width: '14px' }}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  icon={typeof chipIcon === 'string' ? chipIcon : chipIcon(text)}
                />
              </Box>
            )}
            <Typography variant="body2" marginLeft="8px">{currData.text}</Typography>
          </Box>
        </Tooltip>
      );
    }

    if (renderType === 'PROGRESS' && currData.progressValue) {
      const { progressValue, text, width } = currData;

      return (
        <Box fontSize={14} width={width}>
          <ProgressBar
            text={text}
            barType="small"
            progress={progressValue}
          />
        </Box>
      );
    }

    if (renderType === 'CUSTOM' && currData.component) {
      return (
        <Box sx={{ width: '100%', height: '100%' }}>
          {currData.component}
        </Box>
      );
    }

    if (renderType === 'PIE_CHART' && currData?.value) {
      const { value } = currData;
      return (
        <Box display="flex" overflow="hidden" textOverflow="ellipsis" height={24} width="100%">
          <svg viewBox="0 0 24 24">
            <VictoryPie
              data={[{ x: '', y: 100 - Number(value), background: true }, { x: '', y: Number(value) }]}
              standalone={false}
              width={24}
              height={24}
              padding={0}
              innerRadius={8}
              labelRadius={8}
              colorScale={colorScaleWarm}
              style={{ labels: { display: 'none' } }}
              dataComponent={<CustomSlice />}
            />
          </svg>
          <Typography
            overflow="hidden"
            textOverflow="ellipsis"
            ml={1}
          >
            {value}
          </Typography>
        </Box>
      );
    }

    if (renderType === 'BUTTON' && currData.onClick) {
      return (
        <Box py={1.5} width="100%" height="100%">
          <Button
            onClick={(e) => {
              e.preventDefault();
              currData.onClick?.(e);
            }}
            color="primary"
            sx={{
              ml: 'auto',
              height: '100%',
              width: '100%',
              maxWidth: '144px',
              background: '#234855',
              border: '1px solid rgba(36, 179, 208, 1)',
            }}
            disabled={currData.disabled}
          >
            {currData.text}
          </Button>
        </Box>
      );
    }

    const text = currData.text.toString();
    let color: string | undefined;
    let backgroundColor: string | undefined;

    if (currHeader.renderType === 'DELTA' && currData.value && currData.value !== 0) {
      color = currData.value > 0 ? 'success.main' : 'error.main';
    }

    if (currData.status === 'OK') {
      backgroundColor = 'success.opacity50';
    } else if (currData.status === 'WARNING') {
      backgroundColor = 'uploaded.opacity50';
    } else if (currData.status === 'ERROR') {
      backgroundColor = 'error.opacity50';
    } else if (currData.status === 'GOOD') {
      backgroundColor = 'deployed.opacity50';
    } else if (currData.status === 'PROGRESS') {
      backgroundColor = 'progress.opacity50';
    }

    return (
      <>
        <Tooltip title={currData.tooltipText || (text.length > 5 ? text : '')} arrow>
          <Box
            display="flex"
            alignItems="center"
            overflow="hidden"
            color={currData.textColor}
            bgcolor={backgroundColor}
            px={backgroundColor && 1.5}
            borderRadius={2}
          >
            {currData.extraData && (typeof currData.extraData?.data === 'function'
              || !!currData.extraData.data?.length) && (
              <IconButton
                onClick={() => {
                  if (typeof currData.extraData!.data === 'function') {
                    void currData.extraData!.data().then((loadedData) => setExtraData({
                      ...currData.extraData, data: loadedData,
                    }));
                  } else {
                    setExtraData({ ...currData.extraData, data: currData.extraData!.data });
                  }
                }}
                sx={{ p: 0, mr: 1 }}
              >
                <CustomIcon icon={currData.extraData.icon || 'info'} />
              </IconButton>
            )}
            {currData.showCryptoPunk && randomAvatar && (
              <Box
                component="img"
                mr={1}
                src={randomAvatar}
                sx={{
                  objectFit: 'contain',
                  height: 32,
                  width: 32,
                }}
              />
            )}
            {currData.customIcon && (
              <CustomIcon
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                icon={currData.customIcon}
                sx={{ marginRight: 1, svg: { width: 24, height: 24 } }}
              />
            )}
            {currData.cryptoIcon && (
              <CryptoIcon
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                icon={currData.cryptoIcon}
                sx={{ marginRight: 1, img: { width: 24, height: 24 } }}
              />
            )}
            {!currHeader.textHidden && (
              <Typography
                overflow="hidden"
                textOverflow="ellipsis"
                color={color}
              >
                {text}
              </Typography>
            )}
          </Box>
        </Tooltip>
        {currData.isMarked && (
          <Tooltip title={currData.tooltipText ? currData.tooltipText : ''}>
            <Box ml={1} width={8} height={8} borderRadius={8} bgcolor="error.main" />
          </Tooltip>
        )}
        {currData.copyText && (
          <Box ml={1} className="copy-btn" display="none">
            <CopyButton text={currData.copyText} onCopy={setSnackbarText} />
          </Box>
        )}
      </>
    );
  }, [randomAvatar, setExtraData, rowIndex]);

  const getBgColor = (text: string, header: RangeHeader) => {
    const number = parseFloat(text.replaceAll(',', ''));
    const bgColors = [
      '#223764', '#1E3057', '#19294A', '#15233E', '#111C32', '#0D1426', '#090E19', '#04070C',
    ];
    const sliceSize = (header.ceiling - header.floor) / bgColors.length;
    const i = Math.min(Math.floor((number - header.floor) / sliceSize), bgColors.length - 1);
    return bgColors[Math.max(i, 0)];
  };

  const helperData: RenderType[] = useMemo(() => ['FILTER'], []);

  const rowsForRender = useMemo(() => data
    .filter((rowItem) => !helperData.includes(rowItem.renderType))
    .map((currData, i) => {
      const currHeader = headers[i];
      const bgColor = currHeader.renderType === 'RANGE'
        ? getBgColor(currData.text.toString(), currHeader) : undefined;

      return (
        <Box
          key={`table-cell-${uuidv4()}`}
          className={i === data.length - 1 ? 'row-last-cell' : undefined}
          display="flex"
          alignItems="center"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          borderRadius={2}
          bgcolor={bgColor}
          marginLeft={3}
          minWidth={0}
          flexBasis={currHeader.width || `${100 / headers.length}%`}
          flexGrow={1}
          flexShrink={1}
          sx={currData.copyText ? { ':hover .copy-btn': { display: 'block' } } : undefined}
        >
          {renderCell?.(currData, currHeader)}
        </Box>
      );
    }), [data, headers, helperData, renderCell]);

  return (
    <Box
      display="flex"
      bgcolor="#30343B"
      sx={{
        mb: '1px',
        ':hover': {
          background: '#3A3E45',
        },
        '&:last-of-type': {
          mb: 0,
        },
        '.row-last-cell': {
          paddingRight: 3,
        },
      }}
      height={fixedRowHeight}
    >
      {rowImageUrl && (
        <Box
          width={0}
          flexBasis={0}
          flexGrow={1}
          flexShrink={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            component="img"
            src={rowImageUrl}
            alt={rowImageUrl}
            sx={{
              objectFit: 'contain',
              height: 48,
              width: 48,
            }}
          />
        </Box>
      )}
      {rowsForRender}
      {showRowChevron && (
        <Box
          width={0}
          flexBasis={0}
          flexGrow={1}
          flexShrink={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CustomIcon icon="chevron-right" />
        </Box>
      )}
      <Snackbar
        open={!!snackbarText}
        autoHideDuration={2000}
        onClose={() => setSnackbarText('')}
      >
        <Alert onClose={() => setSnackbarText('')} severity="info">
          {snackbarText}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default memo(TableRow);
