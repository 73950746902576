import {
  ComputedCell, HeatMapSerie, ResponsiveHeatMap, HeatMapDatum, HeatMapSvgProps,
} from '@nivo/heatmap';
import { Axis } from '@chaos/types';
import { PropertyAccessor } from '@nivo/core';
import { Box } from '../box';

export type CellLabelInputType<CellMetadata extends object = object> =
    Omit<ComputedCell<HeatMapDatum & CellMetadata>, 'label' | 'color' | 'opacity' | 'borderColor' | 'labelTextColor'>;

export type HeatmapChartProps<CellMetadata extends object = object> = {
  data: HeatMapSerie<HeatMapDatum & CellMetadata, object>[]
  height?: number | string
  onClick?: (cell: ComputedCell<HeatMapDatum & CellMetadata>) => void
  xAxis?: Axis,
  yAxis?: Axis,
  cellLabel?: PropertyAccessor<CellLabelInputType<CellMetadata>, string>
} & Omit<HeatMapSvgProps<HeatMapDatum & CellMetadata, object>, 'width' | 'height'>;

const colorScheme = ['#2BCEBB', '#29C5B3', ' #27BCAB', '#25B3A3', '#23AA9B', '#22A193', '#20988A',
  '#1E9082', '#1C877A', '#1A7E72', '#18756A', '#166C62', '#14635A', '#135A51', '#115149', '#0F4841',
  '#0D3F39', '#0B3631'];

// Docs: https://nivo.rocks/heatmap/
export const HeatmapChart = <CellMetadata extends object = object>({
  data, yAxis, xAxis, onClick, height = 500, cellLabel, ...restProps
}: HeatmapChartProps<CellMetadata>) => (
  <Box
    height={height}
    width="100%"
  >
    <ResponsiveHeatMap<HeatMapDatum & CellMetadata, object>
      data={data}
      label={cellLabel}
      valueFormat={cellLabel ? undefined : '>-.2s'}
      margin={{
        top: 60, right: 90, bottom: 60, left: 90,
      }}
      borderWidth={0}
      labelTextColor="rgba(255, 255, 255, .5)"
      theme={{
        fontFamily: 'Archivo,sans-serif',
        labels: {
          text: {
            textAlign: 'center',
            fontSize: 16,
            lineHeight: 24,
            fontWeight: 500,
            cursor: 'pointer',
            fill: '#FFFFFF',
          },
        },
        axis: {
          ticks: {
            text: {
              fontSize: 16,
              fontWeight: 400,
              fill: '#9B9DA1',
            },
          },
          legend: {
            text: {
              fontSize: 16,
              lineHeight: 24,
              fontWeight: 500,
              fill: '#FFFFFF',
            },
          },
        },
        annotations: {
          outline: {
            outlineWidth: 0,
            stroke: 'white',
          },
          text: {
            outlineWidth: 0,
            fill: 'white',
          },
          link: {
            outlineWidth: 1,
            outlineColor: 'white',
            strokeWidth: 0,
          },
        },
      }}
      onClick={onClick}
      motionConfig="stiff"
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: yAxis?.label ?? '',
        legendPosition: 'middle',
        legendOffset: -72,
      }}
      axisTop={{
        tickSize: 5,
        tickPadding: 5,
        legend: xAxis?.label ?? '',
        legendPosition: 'middle',
        legendOffset: -50,
      }}
      colors={{
        type: 'quantize',
        colors: colorScheme,
      }}
      emptyColor="#555555"
      isInteractive
      inactiveOpacity={1}
      tooltip={() => null} // Remove tooltip
      {...restProps}
    />
  </Box>
  );
