import { useCallback, useState } from 'react';

import { Box } from '@chaos/ui/box';
import { Typography } from '@chaos/ui/typography';
import { Button } from '@chaos/ui/button';
import { MainLayout } from 'src/components/layouts';

import welcome from 'src/assets/img/welcome.svg';
import { useAppSelector } from 'src/store';
import { usePageTitle } from 'src/hooks';
import { PageTracker } from 'src/components/page-tracker';
import { Snackbar } from '@mui/material';
import { CustomIcon } from '../../components/custom-icon';
import { auth as authService } from '../../services/firebase';
import { ACTION_CODE_SETTINGS } from '../signin-page';
import { recordError } from '../../utils/exceptions';

export const WelcomePage = PageTracker((): JSX.Element => {
  usePageTitle('Welcome');
  const [isSendLink, setIsSendLink] = useState(false);
  const hideToastHandler = useCallback((_?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsSendLink(false);
  }, []);

  const [auth, profile] = useAppSelector((state) => [state.firebase.auth, state.firebase.profile]);

  const emailString = profile.email || auth.email || '';

  const resendButtonHandler = useCallback(() => {
    if (!emailString) return;
    try {
      authService
        .sendSignInLinkToEmail(emailString, ACTION_CODE_SETTINGS)
        .finally(() => {
          setIsSendLink(() => true);
        });
    } catch (error) {
      recordError(error as Error);
    }

    setIsSendLink(() => true);
  }, [emailString]);

  return (
    <MainLayout>
      <Box
        sx={{
          position: 'relative',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          marginTop: '62px',
          flexShrink: 0,
          flexDirection: {
            xs: 'column',
            lg: 'row-reverse',
          },
          justifyContent: 'start',
        }}
      >
        <Box>
          <img src={welcome} width="600" height="600" alt="welcome" />
        </Box>
        <Box sx={{ maxWidth: 372 }}>
          <Typography
            sx={{
              paddingBottom: 3,
            }}
            component="h1"
            variant="h2"
          >
            Welcome to Chaos Labs,
            <br />
            {' '}
            {profile.firstName}
            !
          </Typography>
          <Typography sx={{ paddingBottom: 3 }}>
            First, check
            <span>
              {' '}
              {emailString}
              {' '}
            </span>
            for a link to verify your email.
          </Typography>
          <Button
            size="large"
            color="secondary"
            type="submit"
            onClick={resendButtonHandler}
          >
            Resend
          </Button>
        </Box>
        <Box
          component="img"
          sx={{
            maxWidth: '100%',
          }}
          src={welcome}
          alt="welcome"
        />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={isSendLink}
          autoHideDuration={6000}
          onClose={hideToastHandler}
          sx={{
            bottom: {
              sx: '16px',
              lg: '40px',
            },
            left: {
              sx: '16px',
              lg: '288px',
            },
            right: {
              sx: '16px',
              lg: '40px',
            },
          }}
        >
          <Box
            sx={{
              p: 3,
              width: '100%',
              background: 'rgba(43, 209, 129, 0.5)',
              backdropFilter: 'blur(24px)',
              borderRadius: 3,
              display: 'flex',
            }}
          >
            <CustomIcon sx={{ mr: 2.2 }} icon="check-circle" />
            {`Email to ${emailString} sent`}
          </Box>
        </Snackbar>
      </Box>
    </MainLayout>
  );
});
