import { useParams } from 'react-router-dom';
import { MainLayout } from 'src/components/layouts';
import { PageTracker } from 'src/components/page-tracker';
import { useBlockchainSimulationResult } from 'src/hooks/useBlockchainSimulationResult';
import { RouteParams, RoutePath } from 'src/config/routes';
import { BlockExplorerTransactions } from 'src/components/block-explorer-transactions';

export const BlockExplorerBlockTransactionsPage = PageTracker(() => {
  const { simulationResultId, blockNumber } = useParams<{
    simulationResultId: string, blockNumber: string
  }>();
  const { simulation, result } = useBlockchainSimulationResult(simulationResultId!, true);

  const breadcrumbsLinks = [{
    title: 'Simulation Results',
    href: RoutePath.Simulations.Results,
  }, {
    title: simulation?.name || '',
    href: RoutePath.Simulations.ResultDetails
      .replace(RouteParams.SimulationResultId, simulationResultId!),
  }, {
    title: 'Block Explorer',
    href: RoutePath.Simulations.BlockExplorer.Home
      .replace(RouteParams.SimulationResultId, simulationResultId!),
  }];

  return (
    <MainLayout
      height={0}
      headerProps={{
        pageTitle: 'Transactions',
        breadcrumbsLinks,
        extraData: {
          text: 'For Block',
          link: {
            href: RoutePath.Simulations.BlockExplorer.BlockDetails
              .replace(RouteParams.SimulationResultId, simulationResultId!)
              .replace(RouteParams.BlockNumber, blockNumber!),
            text: blockNumber!,
          },
        },
      }}
    >
      <BlockExplorerTransactions
        simulationResultId={simulationResultId!}
        connectionKey={result?.meta?.connectionKey}
        block={Number(blockNumber)}
      />
    </MainLayout>
  );
});
