import {
  Box, Paper, NumberInput, Button, InputLabel,
} from '@chaos/ui';
import { useFormik } from 'formik';
import { Loader } from '@chaos/ui/loader';
import { CustomReactSelect } from '@chaos/ui/custom-select';
import { useEffect, useState } from 'react';
import { MarketType, FormValues, PriceManipulationResultType } from './types';
import { useAssetProtectionToolData } from './useAssetProtectionToolData';
import { MarketAssetSelects, AssetParametersAccordion } from './components';
import AssetProtectionHeatmap from './components/AssetProtectionResultHeatmap';
import { useAssetsData } from './useAssetsData';
import { factorOptions, calculatePriceManipulationCost } from './utils';
import { rangeErrorMsg } from '../utils';

const AssetProtectionTool = () => {
  const [exploitResult, setExploitResult] = useState<PriceManipulationResultType>();
  const { isLoading, data: { marketsAssetsMapping } } = useAssetProtectionToolData();

  const markets = Object.keys(marketsAssetsMapping || {}) as MarketType[];
  const initialMarket = markets[0];
  const initialValues: FormValues = {
    market: initialMarket,
    baseAsset: marketsAssetsMapping?.[initialMarket][0] || '',
    manipulatedAsset: marketsAssetsMapping?.[initialMarket][1] || '',
    scaledExchangeLiquidity: 1,
    scaledTokenMarketCap: 1,
    potentialSellPressure: 0.1,
    baseLtv: 0,
    pumpedLtv: 0,
  };

  const onSubmit = async (values: FormValues) => {
    const isV2 = values.market === 'Ethereum';
    const result = await calculatePriceManipulationCost(values, isV2);
    setExploitResult(result);
  };

  const validate = ({ baseLtv, pumpedLtv, potentialSellPressure }: FormValues) => {
    const errors: Partial<Record<keyof FormValues, string>> = {};
    const baseLtvError = rangeErrorMsg(baseLtv || 0, 0, 100);
    const pumpedLtvError = rangeErrorMsg(pumpedLtv || 0, 0, 100);
    const potentialSellPressureError = rangeErrorMsg(potentialSellPressure || 0, 0, 1);

    if (baseLtvError) errors.baseLtv = baseLtvError;
    if (pumpedLtvError) errors.pumpedLtv = pumpedLtvError;
    if (potentialSellPressureError) errors.potentialSellPressure = potentialSellPressureError;

    return errors;
  };

  const {
    handleSubmit,
    setFieldValue,
    isSubmitting,
    isValid,
    errors,
    values: {
      market,
      baseAsset,
      manipulatedAsset,
      scaledExchangeLiquidity,
      scaledTokenMarketCap,
      potentialSellPressure,
      baseLtv,
      pumpedLtv,
    },
  } = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
    validate,
  });

  const { isLoadingAssetsData, assetsDataResponse } = useAssetsData(market, baseAsset, manipulatedAsset, market === 'Ethereum');

  useEffect(() => {
    if (assetsDataResponse) {
      void setFieldValue('baseLtv', assetsDataResponse.baseAsset.baseLTVasCollateral);
      void setFieldValue('pumpedLtv', assetsDataResponse.pumpedAsset.baseLTVasCollateral);
    }
  }, [assetsDataResponse, setFieldValue]);

  if (isLoading || !marketsAssetsMapping) return <Loader />;

  return (
    <Paper variant="card">
      <form onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column" gap={3}>
          <MarketAssetSelects
            markets={markets}
            market={market}
            baseAsset={baseAsset}
            manipulatedAsset={manipulatedAsset}
            marketsAssetsMapping={marketsAssetsMapping}
            onSelectMarket={async (selectedMarket: MarketType) => {
              await setFieldValue('market', selectedMarket);
              await setFieldValue('baseAsset', marketsAssetsMapping[selectedMarket][0]);
              await setFieldValue('manipulatedAsset', marketsAssetsMapping[selectedMarket][0]);
            }}
            onSelectBaseAsset={async (selectedAsset: string) => {
              await setFieldValue('baseAsset', selectedAsset);
            }}
            onSelectManipulatedAsset={async (selectedAsset: string) => {
              await setFieldValue('manipulatedAsset', selectedAsset);
            }}
          />
          <AssetParametersAccordion
            isLoadingAssetsData={isLoadingAssetsData}
            assetsData={assetsDataResponse}
          />
          <Box display="flex" gap={3}>
            <Box flex="1">
              <InputLabel>Scaled Exchange Liquidity</InputLabel>
              <CustomReactSelect
                name="scaledExchangeLiquidity"
                placeholder="Select Scaled Exchange Liquidity"
                options={factorOptions}
                value={{
                  value: `${scaledExchangeLiquidity}`,
                  label: `${scaledExchangeLiquidity}x`,
                }}
                onChange={(selected) => {
                  if (!selected) return;
                  const newFactor = selected.value;
                  void setFieldValue('scaledExchangeLiquidity', Number(newFactor));
                }}
                isSearchable={false}
              />
            </Box>
            <Box flex="1">
              <InputLabel>Scaled Token Market Cap</InputLabel>
              <CustomReactSelect
                name="scaledTokenMarketCap"
                placeholder="Select Scaled Token Market Cap"
                options={factorOptions}
                value={{
                  value: `${scaledTokenMarketCap}`,
                  label: `${scaledTokenMarketCap}x`,
                }}
                onChange={(selected) => {
                  if (!selected) return;
                  const newFactor = selected.value;
                  void setFieldValue('scaledTokenMarketCap', Number(newFactor));
                }}
                isSearchable={false}
              />
            </Box>
          </Box>
          <Box display="flex" gap={3}>
            <Box flex="1">
              <NumberInput
                label="Potential Sell Pressure"
                name="potentialSellPressure"
                value={potentialSellPressure}
                onChange={async (value: number) => {
                  await setFieldValue('potentialSellPressure', value);
                }}
                error={errors.potentialSellPressure}
              />
            </Box>
            <Box flex="1">
              <NumberInput
                label="Collateral Asset LTV"
                name="baseLtv"
                value={baseLtv}
                onChange={async (value: number) => {
                  await setFieldValue('baseLtv', value);
                }}
                isPercent
                error={errors.baseLtv}
              />
            </Box>
            <Box flex="1">
              <NumberInput
                label="Borrowed Asset LTV"
                name="pumpedLtv"
                value={pumpedLtv}
                onChange={async (value: number) => {
                  await setFieldValue('pumpedLtv', value);
                }}
                isPercent
                error={errors.pumpedLtv}
              />
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Button type="submit" color="primary" disabled={isSubmitting || !isValid}>Calculate</Button>
          </Box>
        </Box>
      </form>
      {(exploitResult || isSubmitting) && (
        <AssetProtectionHeatmap
          exploitResult={exploitResult}
          isCalculating={isSubmitting}
        />
      )}
    </Paper>
  );
};

export default AssetProtectionTool;
