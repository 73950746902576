import { FC } from 'react';
import { Box } from '@chaos/ui/box';

interface TableFilterTabPanelProps {
  value: number
  index: number
}

export const TableFilterTabPanel: FC<TableFilterTabPanelProps> = ({
  children, value, index, ...other
}) => (
  <Box
    sx={{ width: '100%', height: '100%' }}
    role="tabpanel"
    hidden={value !== index}
    id={`filter-tabpanel-${index}`}
    aria-labelledby={`filter-tab-${index}`}
    {...other}
  >
    {value === index && children}
  </Box>
);
