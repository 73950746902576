import { Header, RenderData, ScriptResource } from '@chaos/types';
import { formatDateAndTime } from 'src/utils/formatters';
import { Box, Typography } from '@chaos/ui';
import { FC, memo } from 'react';
import { AvatarAbrType, CircleAvatar } from 'src/components/circle-avatar';
import { getRandomAvatar } from 'src/hooks/useRandomAvatar';
import { ChaosTable } from '../widgets';

const headers: Header[] = [
  {
    renderType: 'TEXT',
    text: 'Name',
    width: '10%',
  },
  {
    renderType: 'TEXT',
    text: 'Description',
    width: '40%',
  },
  {
    renderType: 'TEXT',
    text: 'Date',
    width: '10%',
  },
];

type Props = {
  data: ScriptResource[];
  rowHref: (item: ScriptResource) => string;
  isCircleAvatarHidden?: boolean;
  circleAvatarType?: AvatarAbrType;
};

const ScriptResourceName = memo((
  { name, type }: { name: string, type: AvatarAbrType | undefined },
) => (
  <Box height="100%" display="flex" alignItems="center" gap={2}>
    <CircleAvatar name={name} type={type} />
    <Typography
      overflow="hidden"
      textOverflow="ellipsis"
    >
      {name}
    </Typography>
  </Box>
));

export const ScriptResourceTable: FC<Props> = ({
  data, rowHref, isCircleAvatarHidden = false, circleAvatarType,
}) => {
  const handleOnClickRow = (rowIdx: number) => {
    const item = data[rowIdx];
    return rowHref(item);
  };

  const getRowImageUrl = async (rowIdx: number): Promise<string> => {
    const item = data[rowIdx];
    if (!item) return '';

    const randomAvatar = await getRandomAvatar(item.id);

    return item.thumbnail?.url || randomAvatar;
  };

  const tableData: RenderData[][] = data.map(({
    name,
    description,
    createdAt,
  }: ScriptResource) => [

    {
      renderType: isCircleAvatarHidden ? 'TEXT' : 'CUSTOM',
      component: isCircleAvatarHidden
        ? undefined
        : <ScriptResourceName name={name} type={circleAvatarType} />,
      text: name,
    },
    {
      renderType: 'TEXT',
      text: description || '-',
    },
    {
      renderType: 'TEXT',
      text: formatDateAndTime(createdAt.toDate()),
      value: createdAt.toDate().getTime(),
    },
  ]);
  return (
    <ChaosTable
      data={tableData}
      headers={headers}
      rowHref={handleOnClickRow}
      initialSortBy={2}
      isInitialSortDesc
      getRowImageUrl={isCircleAvatarHidden ? getRowImageUrl : undefined}
      showSearch
      showRowChevron
      pageSize={20}
      isFullHeight
    />
  );
};
