import { Box, Typography } from '@chaos/ui';
import { ComputedDatum, CustomLayerProps, Datum } from '@nivo/line';
import { LineChart } from 'src/components/charts/line-chart-v2';
import { formatAxis } from '@chaos/ui/utils/formatters';
import { area, curveMonotoneX } from 'd3-shape';
import { MetricStatistics } from '@chaos/types';
import { parseIntervalString } from '../utils';

const AreaLayer = ({
  series, xScale, yScale, innerHeight,
}: CustomLayerProps) => {
  const areaGenerator = area()
    .x((d) => Number(xScale(Number((d as unknown as ComputedDatum).data.x))))
    .y0((d) => Number(Math.min(
      innerHeight,
      Number(yScale(Number((d as unknown as ComputedDatum).data.y)
      - Number((d as unknown as ComputedDatum).data.intervalDiff))),
    )))
    .y1((d) => Number(yScale(Number((d as unknown as ComputedDatum).data.y)
    + Number((d as unknown as ComputedDatum).data.intervalDiff))))
    .curve(curveMonotoneX) as (data: ComputedDatum[]) => string;

  return (
    <path
      d={areaGenerator(series[0].data)}
      fill="rgb(31, 163, 214)"
      fillOpacity={0.3}
      stroke="#3daff7"
      strokeWidth={0}
    />
  );
};

type Props = {
  selectedMetricStatistics: MetricStatistics;
  confidenceIntervalOverTimeByPath: Record<string, string[]>;
};

const ConfidenceIntervalOverTime = ({
  selectedMetricStatistics,
  confidenceIntervalOverTimeByPath,
}: Props) => (
  <Box height="100%" display="flex" flexDirection="column">
    <Typography variant="h2" mb={2}>Confidence Interval Over Time</Typography>
    <Box flex={1} height="0">
      <LineChart
        data={[{
          id: 1,
          title: 'Median',
          data: (confidenceIntervalOverTimeByPath[
            selectedMetricStatistics.confidenceIntervalOverTimePath]
            .map((
              interval: string,
              idx: number,
            ) => {
              const {
                interval: intervalDiff,
                mean,
                min,
                max,
              } = parseIntervalString(interval);
              return ({
                x: idx,
                y: mean,
                intervalDiff,
                metadata: {
                  Min: formatAxis(min, 4),
                  Max: formatAxis(max, 4),
                },
              });
            }) as Datum[]),
        }]}
        height="100%"
        customAreaLayers={[AreaLayer]}
        margin={{
          top: 20, bottom: 50, right: 10, left: 84,
        }}
      />
    </Box>
  </Box>
);

export default ConfidenceIntervalOverTime;
