import { FC } from 'react';
import { AutocompleteRenderInputParams, SxProps } from '@mui/material';
import { InputAdornment, TextField } from '@chaos/ui';
import { CircularProgress } from '@chaos/ui/circular-progress';
import { CustomIcon } from 'src/components/custom-icon';

interface SearchBarProps {
  value?: string
  onChange: (value: string) => void
  placeholder?: string
  fullWidth?: boolean
  sx?: SxProps
  loading?: boolean
}

export const SearchBar: FC<SearchBarProps & Partial<AutocompleteRenderInputParams>> = ({
  value, onChange, placeholder, fullWidth = false, sx, loading, InputProps, ...props
}) => (
  <TextField
    {...props}
    type="search"
    value={value}
    fullWidth={fullWidth}
    onChange={(e) => onChange(e.target.value)}
    placeholder={placeholder || 'Search field'}
    InputProps={{
      ref: InputProps?.ref,
      startAdornment: (
        <InputAdornment position="start" disablePointerEvents>
          <CustomIcon icon="search" />
        </InputAdornment>
      ),
      endAdornment: loading ? <CircularProgress color="inherit" size={20} /> : null,
      sx: {
        borderRadius: '16px',
        border: '1px solid #282B33',
        '&.Mui-focused': { borderColor: '#24b3d0' },
      },
    }}
    sx={{
      input: { paddingLeft: 1 },
      '& .MuiOutlinedInput-root': { px: '16px !important' },
      '& svg path': { fill: '#9B9DA1' },
      ...sx,
    }}
  />
);
