import { FC } from 'react';
import {
  Box, Grid, Typography, Tooltip,
} from '@chaos/ui';
import { useRandomAvatar } from 'src/hooks';
import { GridSize } from '@mui/material';
import { CircleAvatar } from 'src/components/circle-avatar';

type GridResponsiveStyleValue<T> = T | { xs?: T, sm?: T, md?: T, xl?: T };
type GridItemSize = GridResponsiveStyleValue<GridSize>;

interface Props {
  avatar?: string
  title?: string
  subtitle? : string
  icon?: React.ReactNode
  gridItemSize?: GridItemSize
  tooltipText?: string
  isCircleAvatarHidden?: boolean
}

export const ShortCardItem:FC<Props> = ({
  avatar,
  title,
  subtitle,
  gridItemSize,
  icon,
  tooltipText,
  isCircleAvatarHidden,
}) => {
  const { randomAvatar } = useRandomAvatar(title);
  return (
    <Grid
      {...gridItemSize}
      item
      sx={{ flexGrow: 0, flexBasis: 'auto', px: 2 }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {!isCircleAvatarHidden ? <CircleAvatar name={title || subtitle || ''} colorFromName />
          : (
            <Box
              component="img"
              src={avatar || randomAvatar}
              alt="agent"
              sx={{ objectFit: 'contain', height: 48, width: 48 }}
            />
          )}
        <Box sx={{ ml: 2, minWidth: 0 }}>
          <Box sx={{ display: 'flex' }}>
            <Tooltip
              title={tooltipText || ''}
              arrow
              placement="right"
            >
              <Typography sx={{ mr: 1.3 }} noWrap>{title}</Typography>

            </Tooltip>
            {icon}
          </Box>
          <Typography variant="caption">{subtitle}</Typography>
        </Box>
      </Box>
    </Grid>
  );
};
