import { Header, RenderData } from '@chaos/types';
import {
  Box,
  Button,
  ChaosTable,
  CryptoIcon,
  CustomReactSelect,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from '@chaos/ui';
import { Loader } from '@chaos/ui/loader';
import dayjs from 'dayjs';
import { camelCase } from 'lodash';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ConfirmationPromptDialog } from 'src/components/confirmation-prompt-dialog';
import { MainLayout } from 'src/components/layouts';
import { useCurrentTeam } from 'src/hooks';
import { useEconnomicRecommendationParams } from 'src/hooks/useEconomicRecommendationParams';
import { publishChainRecommendation } from 'src/services/engine';

const headers: Header[] = [{
  renderType: 'TEXT',
  text: 'Asset',
}, {
  renderType: 'TEXT',
  text: 'Parameter',
}, {
  renderType: 'TEXT',
  text: 'Current',
}, {
  renderType: 'TEXT',
  text: 'Model Recommendation',
}, {
  renderType: 'TEXT',
  text: 'Override Value',
}];

const OverrideDropdown = ({ options, value, onChange }: {
  options: string[], value?: string, onChange: (value?: string) => void
}) => (
  <CustomReactSelect
    options={options.map((o) => ({ value: o, label: o }))}
    value={value ? { value, label: value } : undefined}
    onChange={(o) => onChange(o?.value)}
    menuPortalTarget={document.body}
  />
);

export const ParamRecommendationDetailsPage = () => {
  const { id } = useParams<{ id: string }>();
  const { econnomicRecommendationParams } = useEconnomicRecommendationParams(id!);
  const [selectedOverrides, setSelectedOverrides] = useState<
  Record<string, Record<string, number>>
  >({});
  const [isPublishing, setIsPublishing] = useState(false);
  const [showConfirmationPrompt, setShowConfirmationPrompt] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const data = useMemo(() => (econnomicRecommendationParams?.result || [])
    .flatMap<RenderData[]>((res) => res.recommendations.map<RenderData[]>((rec) => [{
    renderType: 'TEXT',
    text: res.asset_name,
    icon: res.asset_name.toLowerCase(),
  }, {
    renderType: 'TEXT',
    text: rec.name,
  }, {
    renderType: 'TEXT',
    text: rec.value.toString(),
    value: rec.value,
  }, {
    renderType: 'TEXT',
    text: rec.recommended.toString(),
    value: rec.recommended,
  }, {
    renderType: 'CUSTOM',
    text: '-',
    component: <OverrideDropdown
      options={Array.from(new Set(res.permutations
        .flatMap((perm) => perm.parameters
          .filter((param) => param.name === rec.name).map((param) => param.value.toString()))))}
      value={selectedOverrides?.[res.asset_name]?.[camelCase(rec.name)]?.toString()}
      onChange={(value) => setSelectedOverrides((se) => ({
        ...se,
        [res.asset_name]: {
          ...se[res.asset_name],
          [camelCase(rec.name)]: value !== undefined ? Number(value) : rec.value,
        },
      }))}
    />,
  }])), [econnomicRecommendationParams, selectedOverrides]);
  const team = useCurrentTeam();

  const onSubmit = (isPublish?: boolean) => {
    setShowConfirmationPrompt(false);
    if (team && econnomicRecommendationParams?.result[0]) {
      setIsPublishing(true);
      setErrorMessage('');
      void publishChainRecommendation(
        team.authKey!,
        econnomicRecommendationParams.metadata.protocol,
        id!,
        econnomicRecommendationParams.result.map((res) => ({
          id: res.result_id,
          publishWithRecommendation: !selectedOverrides[res.asset_name],
          overrideArguments: selectedOverrides[res.asset_name] && {
            args: selectedOverrides[res.asset_name],
          },
        })),
        isPublish ? 'Published' : 'Pending',
      ).then(() => setIsPublishing(false)).catch((e: Error) => {
        setErrorMessage(`Failed publishing recommendations - ${e.message}`);
        setIsPublishing(false);
      });
    }
  };

  return (
    <MainLayout headerProps={{
      pageTitle: econnomicRecommendationParams?.metadata.model,
      suffixComponent: (
        <Box display="flex" gap={2}>
          <Button color="primary" disabled={isPublishing} onClick={() => setShowConfirmationPrompt(true)}>Publish</Button>
          <Button color="secondary" disabled={isPublishing} onClick={() => onSubmit(false)}>Send to Under Review</Button>
        </Box>
      ),
    }}
    >
      {econnomicRecommendationParams ? (
        <Box display="flex" flexDirection="column" gap={3}>
          <Typography color="red.main">{errorMessage}</Typography>
          <Paper variant="card" sx={{ p: 3 }}>
            <Typography variant="h3">Details</Typography>
            <Grid container mt={2} spacing={3}>
              <Grid item xs={4}>
                <Typography variant="caption">Protocol</Typography>
                <Typography sx={{ display: 'flex', gap: 1 }}>
                  <CryptoIcon
                    icon={econnomicRecommendationParams.metadata.protocol.toLowerCase()}
                  />
                  {econnomicRecommendationParams.metadata.protocol}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="caption">Chain</Typography>
                <Typography sx={{ display: 'flex', gap: 1 }}>
                  <CryptoIcon icon={econnomicRecommendationParams.metadata.chain.toLowerCase()} />
                  {econnomicRecommendationParams.metadata.chain}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="caption">Assets</Typography>
                <Box>
                  {econnomicRecommendationParams.metadata.assets.map((a) => (
                    <Tooltip key={a} title={a}>
                      <CryptoIcon key={a} icon={a.toLowerCase()} />
                    </Tooltip>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="caption">Start Time</Typography>
                <Typography>{dayjs(econnomicRecommendationParams.metadata.start_time).format('DD MMM HH:mm')}</Typography>
              </Grid>
            </Grid>
          </Paper>
          <ChaosTable
            title="Recommendations"
            headers={headers}
            data={data}
          />
        </Box>
      ) : <Loader />}
      <ConfirmationPromptDialog
        title="Are you sure?"
        isShow={showConfirmationPrompt}
        onClose={() => setShowConfirmationPrompt(false)}
        onConfirm={() => onSubmit(true)}
      />
    </MainLayout>
  );
};
