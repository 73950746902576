import React, { Suspense, useState } from 'react';
import {
  BrowserRouter as Router, Routes, Route, Navigate,
} from 'react-router-dom';
import { ErrorBoundaryComponent } from '@chaos/ui/error-boundary';
import { Page404 } from '@chaos/ui';
import { LoadingOverlay } from './components/loading-overlay';
import { MagicLinkReceivePage } from './pages/magic-link-receive-page';
import { MagicLinkSentPage } from './pages/magic-link-sent-page';
import { AuthGuard, GuardType } from './components/auth-guard';
import {
  AuthCompanyLayout, PageLayout, SignInLayout, SignUpLayout,
} from './components/layouts';
import { OverviewPage } from './pages/overview-page';
import { AgentPlaygroundPage } from './pages/agent-playground';
import { SignInPage } from './pages/signin-page';
import { SignUpOrgPage } from './pages/signup-org-page';
import { SignUpPage } from './pages/signup-page';
import { WelcomePage } from './pages/welcome-page';
import { TeamSettingsPage } from './pages/team-settings-page';
import { ContractDeploymentsPage } from './pages/contract-deployments-page';
import { SimulationsPageV2 } from './pages/simulations-page-v2';
import { SimulationsResultsV2 } from './pages/simulations-results-v2';
import { ScenarioCataloguePage } from './pages/scenario-catalogue-page';
import { ProfilePage } from './pages/profile-page';
import { EditProfilePage } from './pages/edit-profile-page';
import { TeamEditPage } from './pages/team-edit-page';
import { TeamCreatePage } from './pages/team-create-page';
import { OracleConfigurationPage } from './pages/oracle-configuration-page';
import { ConfigureOracleCompanyPage } from './pages/configure-oracle-company-page';
import { AgentCreatePage } from './pages/agent-create-page';
import { ScenarioCreatePage } from './pages/scenario-create-page';
import { AssertionWizardPage } from './pages/assertion-wizard-page';
import { AssertionCreatePage } from './pages/assertion-create-page';
import { RouteParams, RoutePath } from './config/routes';
import { WaitingListPage } from './pages/waiting-list-page';
import { TeamProvider } from './context/team-provider';
import { AssetsProvider } from './context/assets-provider';
import { ClientProvider } from './context/client-provider';
import { NetworkProvider } from './context/network-provider';
import { SignUpCompanyPage } from './pages/signup-company-page';
import { Page403 } from './components/403/page-403';
import { PrivateRoute } from './components/private-route';
import { useCurrentTeam, useDashboards } from './hooks';
import { NotificationsPage } from './pages/notifications-page';
import { WaitingTeamPage } from './pages/waiting-team-page';
import { SimulationResultPage } from './pages/simulation-result-page';
import { SimulationExperimentResultsPage } from './pages/simulation-experiment-results-page';
import { NewSimulationPageV2 } from './pages/new-simulation-page-v2';
import { ObserverCreatePage } from './pages/observer-create-page';
import { ObserverConfigurationPage } from './pages/observer-configuarion-page';
import {
  AgentDetailsPage, AssertionDetailsPage, ObserverDetailsPage, ScenarioDetailsPage,
} from './pages/script-details-pages';
import { SimulationDetailsPageV2 } from './pages/simulation-details-page-v2';
import {
  AgentEditPage, AssertionEditPage, ObserverEditPage, ScenarioEditPage,
} from './pages/script-edit-pages';
import { BlockExplorerPage } from './pages/block-explorer-page';
import { BlockExplorerTransactionsPage } from './pages/block-explorer-transactions-page';
import { BlockExplorerBlocksPage } from './pages/block-explorer-blocks-page';
import { BlockExplorerAddressPage } from './pages/block-explorer-address-page';
import { MultiSimulationReport } from './pages/simulation-result-page/simulation-result-multi';
import { BlockExplorerBlockTransactionsPage } from './pages/block-explorer-block-transactions-page';
import { BlockExplorerTransactionDetailsPage } from './pages/block-explorer-transaction-details-page';
import { BlockExplorerBlockDetailsPage } from './pages/block-explorer-block-details-page';
import { AccountSettingsPage } from './pages/account-settings';
import { DashboardsPage } from './pages/dashboards-page';
import { Dashboard } from './components/dashboard';
import { ParamRecommendationsPage } from './pages/param-recommendations-page';
import { ParamRecommendationDetailsPage } from './pages/param-recommendation-details-page';
import { ParamRecommendationTriggerPage } from './pages/param-recommendation-trigger-page';
import { ParamRecommendationsToolRoutes } from './pages/param-recommendations-tool';

export interface AuthRouteParams {
  mode: 'signIn'
  lang: 'en'
  oobCode: string
}

const PrivateRoutes = (): React.ReactElement => {
  const team = useCurrentTeam();

  const { isLoading, dashboards, privateDashboards } = useDashboards();
  const [error, setError] = useState(false);

  return (
    <AuthGuard>
      <ClientProvider>
        <PageLayout>
          <ErrorBoundaryComponent>
            <Routes>
              <Route index element={<DashboardsPage />} />
              <Route path={RoutePath.Welcome} element={<WelcomePage />} />
              <Route path={`${RoutePath.Dashboard.replace(RouteParams.Id, '')}*`} element={<OverviewPage />}>
                {privateDashboards.map(({ link, id: dashboardId, component }) => (
                  <Route path={link} key={dashboardId} element={component} />
                ))}
                <Route
                  path={`${RouteParams.Id}`}
                  element={(
                    <Dashboard
                      setError={setError}
                      error={error}
                      isLoading={isLoading}
                      dashboards={dashboards}
                    />
                  )}
                />
              </Route>
              <Route
                path={RoutePath.ScenarioCatalogue.Edit}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('scenarios')}>
                    <ScenarioEditPage />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.ScenarioCatalogue.Create}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('scenarios')}>
                    <ScenarioCreatePage />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.ScenarioCatalogue.Details}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('scenarios')}>
                    <ScenarioDetailsPage />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.ScenarioCatalogue.Home}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('scenarios')}>
                    <ScenarioCataloguePage />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.AgentPlayground.Edit}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('agents')}>
                    <AgentEditPage />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.AgentPlayground.Create}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('agents')}>
                    <AgentCreatePage />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.AgentPlayground.Details}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('agents')}>
                    <AgentDetailsPage />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.AgentPlayground.Home}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('agents')}>
                    <AgentPlaygroundPage />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.Team.Settings}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('team_settings')}>
                    <TeamSettingsPage />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.Deployments}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('deployments')}>
                    <ContractDeploymentsPage />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.Simulations.BlockExplorer.Blocks}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('simulations')}>
                    <BlockExplorerBlocksPage />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.Simulations.BlockExplorer.Transactions}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('simulations')}>
                    <BlockExplorerTransactionsPage />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.Simulations.BlockExplorer.TransactionDetails}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('simulations')}>
                    <BlockExplorerTransactionDetailsPage />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.Simulations.BlockExplorer.BlockTransactions}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('simulations')}>
                    <BlockExplorerBlockTransactionsPage />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.Simulations.BlockExplorer.BlockDetails}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('simulations')}>
                    <BlockExplorerBlockDetailsPage />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.Simulations.BlockExplorer.Address}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('simulations')}>
                    <BlockExplorerAddressPage />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.Simulations.BlockExplorer.Home}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('simulations')}>
                    <BlockExplorerPage />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.Simulations.ResultDetails}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('simulations')}>
                    <SimulationResultPage />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.Simulations.ExperimentResults}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('simulations')}>
                    <SimulationExperimentResultsPage />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.Simulations.MultiSimulationReport}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('simulations')}>
                    <MultiSimulationReport />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.Simulations.Results}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('simulations')}>
                    <SimulationsResultsV2 />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.Simulations.New}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('simulations')}>
                    <NewSimulationPageV2 />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.Simulations.Clone}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('simulations')}>
                    <NewSimulationPageV2 />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.Simulations.Edit}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('simulations')}>
                    <NewSimulationPageV2 />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.Simulations.Details}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('simulations')}>
                    <SimulationDetailsPageV2 />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.Simulations.Home}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('simulations')}>
                    <SimulationsPageV2 />
                  </PrivateRoute>
                )}
              />
              <Route path={RoutePath.Profile.Home} element={<ProfilePage />} />
              <Route path={RoutePath.AccountSettings} element={<AccountSettingsPage />} />
              <Route
                path={RoutePath.Team.Edit}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('team_settings')}>
                    <TeamEditPage />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.Team.New}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('team_settings')}>
                    <TeamCreatePage />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.Profile.Edit}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('team_settings')}>
                    <EditProfilePage />
                  </PrivateRoute>
                )}
              />
              <Route path={RoutePath.Notifications} element={<NotificationsPage />} />
              <Route
                path={RoutePath.OracleConfiguration.Home}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('oracles')}>
                    <OracleConfigurationPage />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.AssertionWizard.Edit}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('assertions')}>
                    <AssertionEditPage />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.AssertionWizard.Create}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('assertions')}>
                    <AssertionCreatePage />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.AssertionWizard.Details}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('assertions')}>
                    <AssertionDetailsPage />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.AssertionWizard.Home}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('assertions')}>
                    <AssertionWizardPage />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.ObserverConfiguration.Edit}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('observers')}>
                    <ObserverEditPage />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.ObserverConfiguration.Create}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('observers')}>
                    <ObserverCreatePage />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.ObserverConfiguration.Details}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('observers')}>
                    <ObserverDetailsPage />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.ObserverConfiguration.Home}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('observers')}>
                    <ObserverConfigurationPage />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.ParamRecommendations.Home}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('recommendations')}>
                    <ParamRecommendationsPage />
                  </PrivateRoute>
                )}
              />
              <Route
                path={`${RoutePath.ParamRecommendationsTool.Home}/*`}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('recommendations')}>
                    <ParamRecommendationsToolRoutes />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.ParamRecommendations.Details}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('recommendations')}>
                    <ParamRecommendationDetailsPage />
                  </PrivateRoute>
                )}
              />
              <Route
                path={RoutePath.ParamRecommendations.Trigger}
                element={(
                  <PrivateRoute isAllowed={team?.permissions.includes('recommendations')}>
                    <ParamRecommendationTriggerPage />
                  </PrivateRoute>
                )}
              />
              <Route path="/403" element={<Page403 />} />
              <Route path="*" element={<Page404 />} />
            </Routes>
          </ErrorBoundaryComponent>
        </PageLayout>
      </ClientProvider>
    </AuthGuard>
  );
};

const subdomains = [
  'benqi',
  'aave',
  'maker',
  'ardana',
  'spectral',
  'chainlink',
  'sushi',
  'pangolin',
  'dydx',
  'jet',
  'moonwell',
];

const domain = window.location.host;
export const haveSubDomain = subdomains.some((subdomain) => domain.includes(subdomain));

export const RenderRoutes = (): React.ReactElement => (
  <Suspense fallback={<LoadingOverlay hideNav />}>
    <Router>
      <ErrorBoundaryComponent>
        <NetworkProvider>
          <TeamProvider>
            <AssetsProvider>
              <Routes>
                <Route
                  path="/:url*(/+)"
                  element={<Navigate to={window.location.pathname.replace(/\/+$/, window.location.search)} replace />}
                />
                <Route
                  path={RoutePath.SignIn}
                  element={haveSubDomain ? (
                    <AuthCompanyLayout>
                      <SignInPage />
                    </AuthCompanyLayout>
                  ) : (
                    <SignInLayout>
                      <SignInPage />
                    </SignInLayout>
                  )}
                />
                <Route path={RoutePath.MagicLink.Sent} element={<MagicLinkSentPage />} />
                <Route path={RoutePath.MagicLink.Receive} element={<MagicLinkReceivePage />} />
                <Route path={RoutePath.WaitingList} element={<WaitingListPage />} />
                <Route path={RoutePath.WaitingTeam} element={<WaitingTeamPage />} />
                <Route
                  path={RoutePath.Organization.SignUp}
                  element={(
                    <AuthGuard type={GuardType.SignUpOrg}>
                      <SignUpLayout>
                        <SignUpOrgPage />
                      </SignUpLayout>
                    </AuthGuard>
                  )}
                />
                <Route
                  path={RoutePath.CreateProfile}
                  element={(
                    <AuthGuard type={GuardType.SignUp}>
                      {haveSubDomain ? (
                        <AuthCompanyLayout>
                          <SignUpCompanyPage />
                        </AuthCompanyLayout>
                      ) : (
                        <SignUpLayout>
                          <SignUpPage />
                        </SignUpLayout>
                      )}
                    </AuthGuard>
                  )}
                />
                <Route
                  path={RoutePath.OracleConfiguration.Company}
                  element={(
                    <PageLayout>
                      <ConfigureOracleCompanyPage />
                    </PageLayout>
                  )}
                />
                <Route index path="/*" element={<PrivateRoutes />} />
              </Routes>
            </AssetsProvider>
          </TeamProvider>
        </NetworkProvider>
      </ErrorBoundaryComponent>
    </Router>
  </Suspense>
);
