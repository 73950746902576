import { useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'react-redux-firebase';

import { Badge } from '@chaos/ui/badge';
import { Typography } from '@chaos/ui/typography';
import { Box } from '@chaos/ui/box';
import { IconButton } from '@chaos/ui/icon-button';
import { useAppSelector } from 'src/store';
import { useFallbackUserAvatar } from 'src/hooks';
import { NotificationsData } from 'src/pages/notifications-page';

import { CustomIcon } from '../custom-icon';
import { NavMenu } from '../app-nav/nav-components';
import { ProfileMenuMobile } from './profile-menu-mobile';
import AppMenuMobileHeader from './app-menu-mobile-header';

export const AppMenuMobile = (): JSX.Element => {
  const defaultUserAvatar = useFallbackUserAvatar();
  const [isMenuShow, setIsMenuShow] = useState<boolean>(false);

  const toggleMenu = useCallback(() => {
    setIsMenuShow((state) => (!state));
  }, []);

  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [notifications] = useState <NotificationsData[]>([]);
  const profile = useAppSelector((state) => state.firebase.profile);
  const auth = useAppSelector((state) => state.firebase.auth);
  const isAuthed = !isEmpty(auth);
  const closeMenu = useCallback(() => {
    setIsMenuShow(false);
    setIsProfileMenuOpen(false);
  }, []);

  useEffect(() => {
    const html = document.querySelector('html');
    const body = document.querySelector('body');

    if (html && body) {
      html.style.overflow = isMenuShow ? 'hidden' : '';
      body.style.overflow = isMenuShow ? 'hidden' : '';
    }
    return () => {
      if (html && body) {
        html.style.overflow = '';
        body.style.overflow = '';
      }
    };
  }, [isMenuShow]);

  return (
    <Box
      sx={{
        px: 2,
        position: 'relative',
      }}
    >
      <AppMenuMobileHeader isMenuShow={isMenuShow} toggleMenuHandler={toggleMenu} />
      <Box
        sx={{
          display: isMenuShow ? 'flex' : 'none',
          flexDirection: 'column',
          px: 2,
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          width: '100%',
          backgroundColor: 'darkGrey.main',
          zIndex: 1000,
        }}
      >
        <AppMenuMobileHeader isMenuShow={isMenuShow} toggleMenuHandler={toggleMenu} />
        <NavMenu closeMenu={closeMenu} menuOpened />

        {isAuthed && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              py: 2,
              mt: 'auto',
              cursor: 'pointer',
              '& span:first-of-type': {
                mr: '24px',
              },
            }}
            id="profile-menu"
            aria-label="account of current user"
            aria-haspopup="true"
            onClick={() => setIsProfileMenuOpen(true)}
            color="inherit"
          >
            <Box sx={{ position: 'relative', display: 'flex' }}>
              <Box
                component="img"
                src={profile?.photo?.url || defaultUserAvatar}
                alt="user avatar"
                sx={{
                  borderRadius: '50%', mr: 2, width: 48, height: 48,
                }}
              />
              {notifications.length > 0 && (
              <Badge
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  borderRadius: '4px',
                  px: '3px',
                  bgcolor: '#DE4469',
                  fontSize: 10,
                  lineHeight: '16px',
                }}
              >
                {notifications.length}
              </Badge>
              )}
            </Box>
            <Typography
              sx={{
                flex: 1,
              }}
            >
              {`${profile.firstName} ${profile.lastName}`}
            </Typography>
            <IconButton size="large">
              <CustomIcon icon="chevron-right" />
            </IconButton>
          </Box>
        )}

        <ProfileMenuMobile
          open={isProfileMenuOpen}
          closeProfileMenu={() => setIsProfileMenuOpen(false)}
          closeNavMenu={closeMenu}
        />
      </Box>
    </Box>
  );
};
