import { Task } from './types';

/**
 * Check whether a given value is an array where
 * each member is of a specified type
 *
 * @param arr - array to check
 * @param check - type guard to use when evaluating each item
 * @public
 */
export function isTypedArray<T>(
  arr: unknown,
  check: (x: unknown) => x is T,
): arr is T[] {
  if (!Array.isArray(arr)) return false;
  if (arr.some((item) => !check(item))) return false;
  return true;
}

/**
 * Check whether a given value is an {@link @chaos/types#Task}
 * @param arg - value to check
 * @example
 * This is useful if you need to make sure that
 * you are dealing with a task if you don't
 * necessarily control the data source.
 * Here's an example of how to use this guard
 * ```ts
 * const task = { performAt: firebaseTimestamp, status: 'completed', worker: 'doJob', options: {} };
 * isTask(task); // true
 * ```
 */
export function isTask(arg: Task): arg is Task {
  return (
    arg.performAt !== undefined
    && arg.status !== undefined
    && typeof arg.worker === 'string'
    && arg.options !== undefined
  );
}
