import { FC, useMemo } from 'react';
import { Header } from '@chaos/types';
import { Box } from '@chaos/ui/box';
import { Typography } from '@chaos/ui/typography';
import { IconButton } from '@chaos/ui/icon-button';
import { CustomIcon } from 'src/components/custom-icon';
import type { Filter } from './table-filter';

interface TableAppliedFilterProps {
  filters: Record<number, Filter>
  headers: Header[]
  removeFilter: (index: number) => void
}

const rangeFilterValue = (
  value: string,
  header: Header,
) => `${header.prefix || ''}${value}${header.suffix || ''}`;

const formatNumber = (value: number) => new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: value >= 10 ? 2 : 4,
}).format(value);

const getFilterValue = (filter: Filter, header: Header) => {
  switch (filter.type) {
    case 'options':
      return filter.options.filter(({ isHidden }) => !isHidden).map(({ name }) => name).join(', ');
    case 'range':
      return `${rangeFilterValue(
        formatNumber(filter.range[0]),
        header,
      )}-${rangeFilterValue(
        formatNumber(filter.range[1]),
        header,
      )}`;
    case 'text':
      return filter.value;
    default:
      return undefined;
  }
};

export const TableAppliedFilters: FC<TableAppliedFilterProps> = ({
  filters, headers, removeFilter,
}) => {
  const filterValues = useMemo(() => Object
    .entries(filters)
    .filter(([, filter]) => filter.isApplied)
    .map(([index, filter]) => ({
      index,
      header: headers[Number(index)].text,
      value: getFilterValue(filter, headers[Number(index)]),
    })), [filters, headers]);

  return (
    <>
      {!!filterValues.length && <Box width="1px" height="24px" bgcolor="#FFFFFF" mx={2} sx={{ opacity: 0.1 }} />}
      {filterValues.map(({ index, header, value }) => (
        <Box
          key={index}
          display="flex"
          borderRadius={4}
          px={1.5}
          bgcolor="rgba(255, 255, 255, 0.1)"
          alignItems="center"
          mr={1}
          whiteSpace="nowrap"
        >
          <Box mr={1}>
            <Typography variant="body2">{header}</Typography>
          </Box>
          <Box
            bgcolor="#22252C"
            borderRadius={4}
            px={1}
            mr={1}
            height={24}
            minWidth={24}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="body2">{value || '-'}</Typography>
          </Box>
          <IconButton
            sx={{ svg: { width: 8, height: 8 }, p: 0 }}
            onClick={() => removeFilter(Number(index))}
          >
            <CustomIcon icon="close" />
          </IconButton>
        </Box>
      ))}
    </>
  );
};
