import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography } from '@chaos/ui';
import { MainLayout } from 'src/components/layouts';
import { PageTracker } from 'src/components/page-tracker';
import { useSimulationDetails } from 'src/hooks/useSimulationDetails';
import { Loader } from 'src/components/loader';
import { EmptyState } from 'src/components/empty-state';
import { SimulationConfigurationDetails } from 'src/components/simulation-configuration-details';
import { SimulationGeneralInfo } from 'src/components/simulation-general-info';
import { useCurrentTeam } from 'src/hooks';
import { runBlockchainSimulationFn } from 'src/components/dashboard/network';
import { chaosSnackbar } from 'src/utils/chaos-snackbar-utils';
import { RoutePath } from 'src/config/routes';
import { SimulationsResultsV2HistoryTable } from 'src/components/simulations-results-v2-history-table';
import { usePermission } from 'src/hooks/usePermissions';
import { useAppSelector } from 'src/store';

export const SimulationDetailsPageV2 = PageTracker((): JSX.Element => {
  const navigate = useNavigate();
  const team = useCurrentTeam();
  const triggerPermission = usePermission('trigger_simualation');
  const { simulationId } = useParams<{ simulationId: string }>();
  const [isRunningSimulation, setIsRunningSimulation] = useState<boolean>(!triggerPermission);
  const profile = useAppSelector((state) => state.firebase.auth);

  const {
    isLoading,
    simulation,
    agents,
    scenarios,
    assertions,
    simulationResults,
  } = useSimulationDetails(simulationId!);

  if (!simulation && isLoading) return <Loader />;
  if (!simulation) return <EmptyState type="simulations" />;

  const handleRunSimulation = () => {
    if (!triggerPermission) {
      return;
    }
    setIsRunningSimulation(true);
    void runBlockchainSimulationFn(simulationId, profile.uid, team?.authKey)
      .then(() => setIsRunningSimulation(false));
    chaosSnackbar.toast(
      'New simulation is now running. You can see it in the results tabs.',
      {
        icon: 'check-circle',
        onClick: () => navigate(RoutePath.Simulations.Results),
      },
    );
  };

  const optionsMenuItems = [
    {
      title: 'Edit',
      icon: 'pencil',
      onClick: () => {
        const editPath = RoutePath.Simulations.Edit.replace(':simId', simulationId!);
        navigate(editPath);
      },
    },
    {
      title: 'Clone',
      icon: 'fork',
      onClick: () => {
        const clonePath = RoutePath.Simulations.Clone.replace(':simId', simulationId!);
        navigate(clonePath);
      },
    },
  ];

  return (
    <MainLayout
      headerProps={{
        pageTitle: simulation?.name || '',
        menuItems: optionsMenuItems,
        breadcrumbsLinks: [{
          title: 'Simulations',
          href: RoutePath.Simulations.Home,
        }],
        buttons: [{
          children: 'Run Simulation',
          disabled: isRunningSimulation,
          onClick: handleRunSimulation,
        }],
      }}
    >
      <Box display="flex" flexDirection="column" gap={5}>
        <SimulationGeneralInfo simulation={simulation} />
        <SimulationConfigurationDetails
          simulation={simulation}
          agents={agents}
          assertions={assertions}
          scenarios={scenarios}
        />
      </Box>
      {simulationResults && simulationResults.length > 0 && (
        <Box marginTop={5}>
          <Typography variant="h2" marginBottom={2}>Run History</Typography>
          <SimulationsResultsV2HistoryTable simulationsResults={simulationResults} omitNameColumn />
        </Box>
      )}
    </MainLayout>
  );
});
