import { useEffect, useMemo, useState } from 'react';
import { Observable, Subscription } from 'rxjs';
import { collection, doc } from 'firebase/firestore';
import { docData } from 'rxfire/firestore';
import { COLLECTIONS } from '@chaos/utils';
import { BlockchainSimulation } from '@chaos/types';
import { firestore } from 'src/services/firebase';
import { useSimulationTeam } from './useSimulationTeam';

export const useBlockchainSimulation = (id?: string): {
  isLoading: boolean
  blockchainSimulation?: BlockchainSimulation
} => {
  const [simulation, setSimulation] = useState<BlockchainSimulation>();
  const [isLoading, setIsLoading] = useState(true);

  useSimulationTeam(simulation);

  useEffect(() => {
    setIsLoading(true);
    const subs: Subscription[] = [];

    if (id) {
      const simsCol = collection(firestore, COLLECTIONS.BLOCKCHAIN_SIMULATIONS);
      const simulationObs = docData(doc(simsCol, id), { idField: 'id' }) as Observable<BlockchainSimulation>;
      subs.push(simulationObs.subscribe((data) => {
        setIsLoading(false);
        setSimulation(data);
      }));
    }

    return () => subs.forEach((s) => s.unsubscribe());
  }, [id]);
  const result = useMemo(
    () => ({ isLoading, blockchainSimulation: simulation }),
    [isLoading, simulation],
  );

  return result;
};
