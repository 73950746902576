import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { MainLayout } from 'src/components/layouts';
import { RoutePath } from 'src/config/routes';
import { usePageTitle } from 'src/hooks';
import { EmptyState } from 'src/components/empty-state';
import { PageTracker } from 'src/components/page-tracker';
import { useBlockchainObservers } from 'src/hooks/useBlockchainObservers';
import { ScriptResourceTable } from 'src/components/script-resource-table';
import { ScriptResource } from '@chaos/types';

export const ObserverConfigurationPage = PageTracker((): JSX.Element => {
  usePageTitle('Observers');
  const navigate = useNavigate();
  const { blockchainObservers, isLoading } = useBlockchainObservers();
  const isObserversExists = blockchainObservers.length > 0;

  return (
    <MainLayout
      height={0}
      headerProps={{
        pageTitle: 'Observers',
        buttons: [{
          children: 'Create Observer',
          onClick: () => navigate(RoutePath.ObserverConfiguration.Create),
        }],
      }}
    >
      {isLoading && <CircularProgress />}
      {!isLoading && !isObserversExists && <EmptyState type="observers" />}

      {!isLoading && isObserversExists && (
        <ScriptResourceTable
          data={blockchainObservers}
          rowHref={(observer: ScriptResource) => RoutePath.ObserverConfiguration.Details.replace(':id', observer.id)}
        />
      )}
    </MainLayout>
  );
});
