import * as React from 'react';
import { Box } from '@chaos/ui/box';
import { Link } from '@chaos/ui/link';
import { Typography } from '@chaos/ui/typography';
import { useTheme, useMediaQuery } from '@chaos/ui/theme';

export const SignInFooter = (): React.ReactElement => {
  const theme = useTheme();
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Box
      sx={{
        display: 'flex',
        fontWeight: 400,
        color: 'light.main',
        mb: 2,
      }}
    >
      {mdAndUp ? (
        <Box
          sx={{
            display: 'flex',
            '> *:not(:last-child)': {
              mr: '48px',
            },
          }}
        >
          <Typography sx={{ marginBottom: 0 }} paragraph>
            &copy; 2022 Chaos Labs, Inc
          </Typography>

          <Link
            href="mailto:info@chaoslabs.xyz"
            color="inherit"
            underline="none"
            rel="noreferrer"
          >
            Contact
          </Link>

          {/* <Link
            to={RoutePath.Privacy}
            component={RouterLink}
            color="inherit"
            underline="none"
          >
            Privacy &amp; Terms
          </Link> */}
        </Box>
      ) : (
        <Box>
          <Box sx={{ display: 'flex', mb: 2 }}>
            <Box sx={{ mr: '48px' }}>
              <Link
                href="mailto:info@chaoslabs.xyz"
                color="inherit"
                underline="none"
                rel="noreferrer"
              >
                Contact
              </Link>
            </Box>
            {/* <Box>
              <Link
                to={RoutePath.Privacy}
                component={RouterLink}
                color="inherit"
                underline="none"
              >
                Privacy &amp; Terms
              </Link>
            </Box> */}
          </Box>

          <Typography sx={{ marginBottom: 0, textAlign: 'center' }} paragraph>
            &copy; 2022 Chaos Labs, Inc
          </Typography>
        </Box>
      )}
    </Box>
  );
};
