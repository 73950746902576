import { Link } from 'react-router-dom';
import { Typography } from '@chaos/ui/typography';
import { Button } from '@chaos/ui/button';
import { MainLayout } from 'src/components/layouts';
import { TeamRow } from 'src/components/team-row';
import { RoutePath } from 'src/config/routes';
import { useAllTeams, usePageTitle } from 'src/hooks';
import { CircularProgress } from '@mui/material';
import { PageTracker } from 'src/components/page-tracker';

export const TeamSettingsPage = PageTracker((): JSX.Element => {
  usePageTitle('Teams');
  const { allTeams, isLoading } = useAllTeams();
  return (
    <MainLayout
      topbar={(
        <>
          <Typography
            variant="h1"
            component="h1"
            sx={{ p: '44px 0', flexGrow: 1 }}
          >
            Teams
          </Typography>
          <Button
            color="primary"
            component={Link}
            to={RoutePath.Team.New}
          >
            New Team
          </Button>
        </>
      )}
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        allTeams.map((team) => <TeamRow key={team.id} team={team} />)
      )}
    </MainLayout>
  );
});
