import { PropsWithChildren, MouseEvent, useState } from 'react';
import { Box } from '../box';
import { Typography } from '../typography';
import { Menu } from '../menu';
import { MenuItem } from '../menu-item';
import { CustomIcon } from '../custom-icon';

export type MenuItemType = {
  title: string;
  icon?: string;
  onClick: () => void;
};

type ChaosMenuProps = {
  items: MenuItemType[]
};

export const ChaosMenu = ({ items, children }: PropsWithChildren<ChaosMenuProps>) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const isOpen = Boolean(anchorEl);
  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box onClick={handleOpen}>
        {children}
      </Box>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
      >
        {items.map((item) => (
          <MenuItem
            key={item.title}
            onClick={() => {
              item.onClick?.();
              handleClose();
            }}
          >
            {item.icon && (
            <CustomIcon
              sx={{
                svg: {
                  width: 18,
                  height: 18,
                  mr: 1,
                  path: {
                    fill: 'currentColor',
                  },
                },
              }}
              icon={item.icon}
            />
            )}
            <Typography component="span">{item.title}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
