import { Link } from 'react-router-dom';
import { Paper } from '@chaos/ui';
import { Box } from '@chaos/ui/box';
import { Typography } from '@chaos/ui/typography';
import { Button } from '@chaos/ui/button';
import { MainLayout } from 'src/components/layouts';
import { CircularProgress } from '@chaos/ui/circular-progress';
import { TeamRow } from 'src/components/team-row';
import { RoutePath } from 'src/config/routes';
import { useAppSelector } from 'src/store';
import { useAllTeams, usePageTitle, useFallbackUserAvatar } from 'src/hooks';
import { PageTracker } from 'src/components/page-tracker';
import { CustomIcon } from '@chaos/ui/custom-icon';
import { ConfirmationPromptDialog } from 'src/components/confirmation-prompt-dialog';
import { useFirebase } from 'react-redux-firebase';
import { useCallback, useState } from 'react';

export const ProfilePage = PageTracker((): JSX.Element => {
  usePageTitle('Profile');
  const defaultUserAvatar = useFallbackUserAvatar();
  const isAdmin = useAppSelector((state) => state.firebase.profile.role === 'admin');
  const firebase = useFirebase();
  const [isShowLogoutPrompt, setIsShowLogoutPrompt] = useState(false);

  const { isLoading: isTeamsLoading, allTeams } = useAllTeams();

  const profile = useAppSelector((state) => state.firebase.profile);

  const handleLogoutConfirm = useCallback(() => {
    setIsShowLogoutPrompt(false);
    void firebase.logout();
  }, [firebase]);

  const handleLogoutReject = useCallback(() => {
    setIsShowLogoutPrompt(false);
  }, []);

  return (
    <MainLayout
      topbar={(
        <Box
          sx={{
            py: '28px', display: 'flex', alignItems: 'center', flex: 1,
          }}
        >
          <Box flex={1} display="flex" gap={2}>
            <Box
              component="img"
              src={profile?.photo?.url || defaultUserAvatar}
              alt="user avatar"
              sx={{
                height: 32,
                objectFit: 'contain',
                borderRadius: '50%',
              }}
            />
            <Box>
              <Typography variant="h1" component="h1">
                {`${profile.firstName} ${profile.lastName}`}
              </Typography>
              <Typography fontWeight={400}>
                {profile.email}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" gap={3}>
            <Link
              data-test-id="notifications-link"
              to={RoutePath.Notifications}
              style={{
                color: 'inherit',
                textDecoration: 'inherit',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CustomIcon icon="bell" />
            </Link>
            <Link
              data-test-id="edit-profile-link"
              to={RoutePath.Profile.Edit}
              style={{
                color: 'inherit',
                textDecoration: 'inherit',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CustomIcon icon="settings" />
            </Link>
            <Button
              data-test-id="sign-out-btn"
              onClick={() => setIsShowLogoutPrompt(true)}
            >
              Sign Out
            </Button>
          </Box>
        </Box>
      )}
    >
      <Box sx={{ mb: 5 }}>
        <Box sx={{ display: 'flex', flex: 1, alignItems: 'center' }}>
          <Typography variant="h2" component="h2" sx={{ py: '36px', flex: 1 }}>
            Teams
          </Typography>
          {isAdmin && (
          <Link
            to={RoutePath.Team.New}
            style={{
              color: 'inherit',
              textDecoration: 'inherit',
            }}
          >
            <Button
              color="secondary"
              sx={{
                borderRadius: 1.5,
              }}
            >
              Create Team
            </Button>
          </Link>
          )}
        </Box>

        <Box>
          {isTeamsLoading ? (
            <CircularProgress />
          ) : (
            <Paper variant="card">
              {allTeams.map((team) => <TeamRow key={team.id} team={team} />)}
            </Paper>
          )}
        </Box>

        <ConfirmationPromptDialog
          confirmButtonText="Leave"
          cancelButtonText="Stay here"
          isShow={isShowLogoutPrompt}
          title="Leave page"
          onConfirm={handleLogoutConfirm}
          onClose={handleLogoutReject}
        >
          Are you sure?
        </ConfirmationPromptDialog>
      </Box>
    </MainLayout>
  );
});
