import { FC } from 'react';
import { SxProps } from '@mui/material';
import { Box } from '@chaos/ui/box';
import { ReactComponent as ChaosIcon } from '../../assets/img/icons/logo-icon.svg';
import { ReactComponent as AirplayIcon } from '../../assets/img/icons/nav/airplay.svg';
import { ReactComponent as AirplayGradientShadowIcon } from '../../assets/img/icons/airplay-gradient-shadow.svg';
import { ReactComponent as ViewListIcon } from '../../assets/img/icons/nav/view-list.svg';
import { ReactComponent as AlbumLibraryIcon } from '../../assets/img/icons/nav/album-library.svg';
import { ReactComponent as GiftIcon } from '../../assets/img/icons/nav/gift.svg';
import { ReactComponent as GridIcon } from '../../assets/img/icons/nav/grid.svg';
import { ReactComponent as CardEjectIcon } from '../../assets/img/icons/card-eject.svg';
import { ReactComponent as HistoryIcon } from '../../assets/img/icons/nav/history.svg';
import { ReactComponent as KeyboardIcon } from '../../assets/img/icons/nav/keyboard.svg';
import { ReactComponent as PaletteIcon } from '../../assets/img/icons/nav/palette.svg';
import { ReactComponent as PerspectiveIcon } from '../../assets/img/icons/nav/perspective.svg';
import { ReactComponent as VectorIcon } from '../../assets/img/icons/nav/vector.svg';
import { ReactComponent as BulletBlueIcon } from '../../assets/img/icons/bullet-blue.svg';
import { ReactComponent as GridFourIcon } from '../../assets/img/icons/grid-four.svg';
import { ReactComponent as GridTwoHorizontal } from '../../assets/img/icons/grid-two-h.svg';
import { ReactComponent as BubbleRaceIcon } from '../../assets/img/icons/bubble-race.svg';
import { ReactComponent as UsersIcon } from '../../assets/img/icons/users.svg';
import { ReactComponent as ChevronDownFilledIcon } from '../../assets/img/icons/chevron-down-filled.svg';
import { ReactComponent as FiledVideo } from '../../assets/img/icons/file-video.svg';
import { ReactComponent as PieGradientShadowIcon } from '../../assets/img/pie-gradient-shadow.svg';
import { ReactComponent as TriangleGradientShadowIcon } from '../../assets/img/triangle-gradient-shadow.svg';
import { ReactComponent as CheckSquareIcon } from '../../assets/img/icons/check-square.svg';
import { ReactComponent as MenuToggleIcon } from '../../assets/img/icons/menu-toggle.svg';
import { ReactComponent as SortNoneIcon } from '../../assets/img/icons/sort-non.svg';
import { ReactComponent as SortDownIcon } from '../../assets/img/icons/sort-down.svg';
import { ReactComponent as CalendarIcon } from '../../assets/img/icons/calendar.svg';
import { ReactComponent as BriefCaseIcon } from '../../assets/img/icons/briefcase.svg';
import { ReactComponent as CheckboxOffIcon } from '../../assets/img/icons/checkbox-off.svg';
import { ReactComponent as GithubAltIcon } from '../../assets/img/icons/github-alt.svg';
import { ReactComponent as GoogleIcon } from '../../assets/img/icons/google.svg';
import { ReactComponent as TwitterIcon } from '../../assets/img/icons/twitter.svg';
import { ReactComponent as OutlineTwitter } from '../../assets/img/icons/outline-twitter.svg';
import { ReactComponent as EyeIcon } from '../../assets/img/icons/eye.svg';
import { ReactComponent as FolderUpload } from '../../assets/img/icons/folder-upload.svg';
import { ReactComponent as EyeOffIcon } from '../../assets/img/icons/eye-off.svg';
import { ReactComponent as ChevronDownIcon } from '../../assets/img/icons/chevron-down.svg';
import { ReactComponent as RemoveIcon } from '../../assets/img/icons/remove.svg';
import { ReactComponent as CloseIcon } from '../../assets/img/icons/close.svg';
import { ReactComponent as SearchIcon } from '../../assets/img/icons/search.svg';
import { ReactComponent as WifiIcon } from '../../assets/img/icons/wi-fi.svg';
import { ReactComponent as UserIcon } from '../../assets/img/icons/user.svg';
import { ReactComponent as BellIcon } from '../../assets/img/icons/bell.svg';
import { ReactComponent as SettingsIcon } from '../../assets/img/icons/settings.svg';
import { ReactComponent as SignOutIcon } from '../../assets/img/icons/sign-out.svg';
import { ReactComponent as CloseCircleIcon } from '../../assets/img/icons/close-circle.svg';
import { ReactComponent as CheckCircleIcon } from '../../assets/img/icons/check-circle.svg';
import { ReactComponent as StackedAreaUpIcon } from '../../assets/img/icons/stacked-area-up.svg';
import { ReactComponent as ChevronRightIcon } from '../../assets/img/icons/chevron-right.svg';
import { ReactComponent as ChevronLeftIcon } from '../../assets/img/icons/chevron-left.svg';
import { ReactComponent as ListBoxesIcon } from '../../assets/img/icons/list-boxes.svg';
import { ReactComponent as ExclamationSquareIcon } from '../../assets/img/icons/exclamation-square.svg';
import { ReactComponent as ExclamationMarkIcon } from '../../assets/img/icons/exclamation-mark.svg';
import { ReactComponent as ArrowLeftIcon } from '../../assets/img/icons/arrow-left.svg';
import { ReactComponent as BreadcrumbsArrowIcon } from '../../assets/img/icons/breadcrumbs-arrow.svg';
import { ReactComponent as CloudDownloadIcon } from '../../assets/img/icons/cloud-download.svg';
import { ReactComponent as PrinterIcon } from '../../assets/img/icons/printer.svg';
import { ReactComponent as SettingsAdjustIcon } from '../../assets/img/icons/settings-adjust.svg';
import { ReactComponent as GridTwoVerticalIcon } from '../../assets/img/icons/grid-two-v.svg';
import { ReactComponent as CheckboxIndeterminateIcon } from '../../assets/img/icons/checkbox-indeterminate.svg';
import { ReactComponent as FullscreenIcon } from '../../assets/img/icons/fullscreen.svg';
import { ReactComponent as ExitFullscreenIcon } from '../../assets/img/icons/exit-fullscreen.svg';
import { ReactComponent as FileCheckInIcon } from '../../assets/img/icons/file-check-in.svg';
import { ReactComponent as CoinDollarIcon } from '../../assets/img/icons/coin-dollar.svg';
import { ReactComponent as StarIcon } from '../../assets/img/icons/star.svg';
import { ReactComponent as ImportIcon } from '../../assets/img/icons/import.svg';
import { ReactComponent as ScaleIcon } from '../../assets/img/icons/scale.svg';
import { ReactComponent as CoinsIcon } from '../../assets/img/icons/coins.svg';
import { ReactComponent as StarFilledIcon } from '../../assets/img/icons/star-filled.svg';
import { ReactComponent as EraserIcon } from '../../assets/img/icons/eraser.svg';
import { ReactComponent as TrashCanIcon } from '../../assets/img/icons/trash-can.svg';
import { ReactComponent as ImageUploadIcon } from '../../assets/img/icons/image-upload.svg';
import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg';
import { ReactComponent as ChevronRightSmallIcon } from '../../assets/img/icons/chevron-right-small.svg';
import { ReactComponent as ImageUploadBigIcon } from '../../assets/img/icons/image-upload-big.svg';
import { ReactComponent as OracleIcon } from '../../assets/img/icons/nav/oracle.svg';
import { ReactComponent as ChainlinkIcon } from '../../assets/img/icons/chainlink.svg';
import { ReactComponent as ChatDotsIcon } from '../../assets/img/icons/chat-dots.svg';
import { ReactComponent as ChatIcon } from '../../assets/img/icons/chat.svg';
import { ReactComponent as CloseSmallIcon } from '../../assets/img/icons/close-small.svg';
import { ReactComponent as FileCodeIcon } from '../../assets/img/icons/file-code.svg';
import { ReactComponent as FileListIcon } from '../../assets/img/icons/file-list.svg';
import { ReactComponent as ExternalLinkIcon } from '../../assets/img/icons/external-link.svg';
import { ReactComponent as LinkedinIcon } from '../../assets/img/icons/linkedin-icon.svg';
import { ReactComponent as CopyIcon } from '../../assets/img/icons/copy.svg';
import { ReactComponent as UsersWhiteIcon } from '../../assets/img/icons/users-white.svg';
import { ReactComponent as CheckIcon } from '../../assets/img/icons/check.svg';
import { ReactComponent as BurgerMenuIcon } from '../../assets/img/icons/burger-menu.svg';
import { ReactComponent as InfoIcon } from '../../assets/img/icons/info.svg';
import { ReactComponent as PlusIcon } from '../../assets/img/icons/plus.svg';
import { ReactComponent as PlusCircleIcon } from '../../assets/img/icons/plus-circle.svg';
import { ReactComponent as GlobeIcon } from '../../assets/img/icons/globe.svg';
import { ReactComponent as ClockIcon } from '../../assets/img/icons/clock.svg';
import { ReactComponent as LightBulbIcon } from '../../assets/img/icons/light-bulb.svg';
import { ReactComponent as TelegramIcon } from '../../assets/img/icons/telegram.svg';
import { ReactComponent as RedditIcon } from '../../assets/img/icons/reddit.svg';
import { ReactComponent as ListIcon } from '../../assets/img/icons/list.svg';
import { ReactComponent as ManageSearchIcon } from '../../assets/img/icons/manage-search.svg';
import { ReactComponent as RocketIcon } from '../../assets/img/icons/rocket.svg';
import { ReactComponent as IdeaIcon } from '../../assets/img/icons/idea.svg';
import { ReactComponent as FolderCheckIcon } from '../../assets/img/icons/folder-check.svg';
import { ReactComponent as PlanetIcon } from '../../assets/img/icons/planet.svg';
import { ReactComponent as CircularIcon } from '../../assets/img/icons/circular.svg';
import { ReactComponent as RoadSignIcon } from '../../assets/img/icons/road-sign.svg';
import { ReactComponent as MoreIcon } from '../../assets/img/icons/more.svg';
import { ReactComponent as ForkIcon } from '../../assets/img/icons/fork.svg';
import { ReactComponent as PencilIcon } from '../../assets/img/icons/pencil.svg';
import { ReactComponent as UserPlusIcon } from '../../assets/img/icons/user_plus.svg';
import { ReactComponent as EtherIcon } from '../../assets/img/icons/ether.svg';
import { ReactComponent as DepositIcon } from '../../assets/img/icons/deposit.svg';
import { ReactComponent as BorrowIcon } from '../../assets/img/icons/borrow.svg';
import { ReactComponent as RepayIcon } from '../../assets/img/icons/repay.svg';
import { ReactComponent as ReedemPayIcon } from '../../assets/img/icons/reedem-pay.svg';
import { ReactComponent as OutlineMail } from '../../assets/img/icons/outline-mail.svg';
import { ReactComponent as ExponentialGraphIcon } from '../../assets/img/icons/exponential-graph.svg';
import { ReactComponent as HeartIcon } from '../../assets/img/icons/heart-icon.svg';
import { ReactComponent as LockIcon } from '../../assets/img/icons/lock-icon.svg';
import { ReactComponent as CoinsStackIcon } from '../../assets/img/icons/coins-stack-icon.svg';
import { ReactComponent as LocationWarningIcon } from '../../assets/img/icons/location-warning-icon.svg';
import { ReactComponent as WalletIcon } from '../../assets/img/icons/wallet-icon.svg';
import { ReactComponent as BitcoinLogoIcon } from '../../assets/img/bitcoin-logo.svg';
import { ReactComponent as EthereumIcon } from '../../assets/img/icons/ethereum-logo.svg';
import { ReactComponent as SolIcon } from '../../assets/img/crypto/sol.svg';
import { ReactComponent as AaveIcon } from '../../assets/img/crypto/aave.svg';
import { ReactComponent as AvaxIcon } from '../../assets/img/crypto/avax.svg';
import { ReactComponent as CeloIcon } from '../../assets/img/crypto/celo.svg';
import { ReactComponent as CompoundIcon } from '../../assets/img/crypto/compound.svg';
import { ReactComponent as DaiIcon } from '../../assets/img/crypto/dai.svg';
import { ReactComponent as ShibIcon } from '../../assets/img/crypto/shib.svg';
import { ReactComponent as AxsIcon } from '../../assets/img/crypto/axs.svg';
import { ReactComponent as ApeIcon } from '../../assets/img/crypto/ape.svg';
import { ReactComponent as GmtIcon } from '../../assets/img/crypto/gmt.svg';
import { ReactComponent as KavaIcon } from '../../assets/img/crypto/kava.svg';
import { ReactComponent as EnsIcon } from '../../assets/img/crypto/ens.svg';
import { ReactComponent as ThetaIcon } from '../../assets/img/crypto/theta.svg';
import { ReactComponent as ChzIcon } from '../../assets/img/crypto/chz.svg';
import { ReactComponent as CroIcon } from '../../assets/img/crypto/cro.svg';
import { ReactComponent as VetIcon } from '../../assets/img/crypto/vet.svg';
import { ReactComponent as ChrIcon } from '../../assets/img/crypto/chr.svg';
import { ReactComponent as LinkIcon } from '../../assets/img/crypto/link.svg';
import { ReactComponent as LunaIcon } from '../../assets/img/crypto/luna.svg';
import { ReactComponent as MaticIcon } from '../../assets/img/crypto/matic.svg';
import { ReactComponent as MakerIcon } from '../../assets/img/crypto/maker.svg';
import { ReactComponent as MakerDaoIcon } from '../../assets/img/crypto/makerdao.svg';
import { ReactComponent as TheGraphIcon } from '../../assets/img/crypto/the-graph.svg';
import { ReactComponent as UniIcon } from '../../assets/img/crypto/uni.svg';
import { ReactComponent as UniswapIcon } from '../../assets/img/crypto/uniswap.svg';
import { ReactComponent as UsdtIcon } from '../../assets/img/crypto/usdt.svg';
import { ReactComponent as UsdcIcon } from '../../assets/img/crypto/usdc.svg';
import { ReactComponent as SusdIcon } from '../../assets/img/crypto/susd.svg';
import { ReactComponent as AlgorandIcon } from '../../assets/img/crypto/algorand.svg';
import { ReactComponent as WarningIcon } from '../../assets/img/warning.svg';
import { ReactComponent as QuestionMarkCircleIcon } from '../../assets/img/icons/question-mark-circle.svg';
import { ReactComponent as ZrxIcon } from '../../assets/img/crypto/zrx.svg';
import { ReactComponent as BatIcon } from '../../assets/img/crypto/bat.svg';
import { ReactComponent as DotIcon } from '../../assets/img/crypto/dot.svg';
import { ReactComponent as CrvIcon } from '../../assets/img/crypto/crv.svg';
import { ReactComponent as GhstIcon } from '../../assets/img/crypto/ghst.svg';
import { ReactComponent as JeurIcon } from '../../assets/img/crypto/jeur.svg';
import { ReactComponent as DpiIcon } from '../../assets/img/crypto/dpi.svg';
import { ReactComponent as BalIcon } from '../../assets/img/crypto/bal.svg';
import { ReactComponent as AgeurIcon } from '../../assets/img/crypto/ageur.svg';
import { ReactComponent as EursIcon } from '../../assets/img/crypto/eurs.svg';
import { ReactComponent as WoneIcon } from '../../assets/img/crypto/wone.svg';
import { ReactComponent as BnbIcon } from '../../assets/img/crypto/bnb.svg';
import { ReactComponent as MovrIcon, ReactComponent as MoonriverIcon } from '../../assets/img/crypto/movr.svg';
import { ReactComponent as KsmIcon } from '../../assets/img/crypto/ksm.svg';
import { ReactComponent as BUSDIcon } from '../../assets/img/crypto/busd.svg';
import { ReactComponent as FraxIcon } from '../../assets/img/crypto/frax.svg';
import { ReactComponent as MIMIcon } from '../../assets/img/crypto/mim.svg';
import { ReactComponent as SolarIcon } from '../../assets/img/crypto/solar.svg';
import { ReactComponent as RomeIcon } from '../../assets/img/crypto/rome.svg';
import { ReactComponent as HundredFinanceIcon } from '../../assets/img/crypto/hnd.svg';
import { ReactComponent as FantomIcon } from '../../assets/img/crypto/fantom.svg';
import { ReactComponent as HarmonyIcon } from '../../assets/img/crypto/harmony.svg';
import { ReactComponent as PinkIcon } from '../../assets/img/crypto/pink.svg';
import { ReactComponent as MovrBWIcon } from '../../assets/img/crypto/movr_bw.svg';
import { ReactComponent as PangolinLogoSvg } from '../../assets/img/pangolin-logo.svg';
import { ReactComponent as DogeIcon } from '../../assets/img/crypto/doge.svg';
import { ReactComponent as LtcIcon } from '../../assets/img/crypto/ltc.svg';
import { ReactComponent as BchIcon } from '../../assets/img/crypto/bch.svg';
import { ReactComponent as AtomSvg } from '../../assets/img/crypto/atom.svg';
import { ReactComponent as XlmIcon } from '../../assets/img/crypto/xlm.svg';
import { ReactComponent as XmrIcon } from '../../assets/img/crypto/xmr.svg';
import { ReactComponent as EtcIcon } from '../../assets/img/crypto/etc.svg';
import { ReactComponent as IcpIcon } from '../../assets/img/crypto/icp.svg';
import { ReactComponent as FilIcon } from '../../assets/img/crypto/fil.svg';
import { ReactComponent as EosIcon } from '../../assets/img/crypto/eos.svg';
import { ReactComponent as RuneIcon } from '../../assets/img/crypto/rune.svg';
import { ReactComponent as ZecIcon } from '../../assets/img/crypto/zec.svg';
import { ReactComponent as SnxIcon } from '../../assets/img/crypto/snx.svg';
import { ReactComponent as EnjIcon } from '../../assets/img/crypto/enj.svg';
import { ReactComponent as YfiIcon } from '../../assets/img/crypto/yfi.svg';
import { ReactComponent as StethIcon } from '../../assets/img/crypto/steth.svg';
import { ReactComponent as OneInchIcon } from '../../assets/img/crypto/1inch.svg';
import { ReactComponent as UmaIcon } from '../../assets/img/crypto/uma.svg';
import { ReactComponent as SavaxIcon } from '../../assets/img/crypto/savax.svg';
import { ReactComponent as PolygonIcon } from '../../assets/img/crypto/polygon.svg';
import { ReactComponent as WbtcIcon } from '../../assets/img/crypto/wbtc.svg';
import { ReactComponent as SushiIcon } from '../../assets/img/icons/sushi.svg';
import { ReactComponent as NearIcon } from '../../assets/img/icons/near.svg';
import { ReactComponent as ArbitrumIcon } from '../../assets/img/crypto/arbitrum.svg';
import { ReactComponent as SolanaIcon } from '../../assets/img/icons/solana.svg';
import { ReactComponent as AvalancheIcon } from '../../assets/img/icons/avalanche.svg';
import { ReactComponent as BitcoinIcon } from '../../assets/img/crypto/bitcoin.svg';
import { ReactComponent as MoonwellIcon } from '../../assets/img/crypto/moonwell.svg';
import { ReactComponent as QIIcon } from '../../assets/img/crypto/qi.svg';
import { ReactComponent as BenqiIcon } from '../../assets/img/crypto/benqi.svg';
import { ReactComponent as OptimismIcon } from '../../assets/img/crypto/op.svg';
import { ReactComponent as WETHIcon } from '../../assets/img/icons/weth_logo.svg';
import { ReactComponent as EthereumTokenIcon } from '../../assets/img/crypto/ethereum.svg';
import { ReactComponent as CardanoIcon } from '../../assets/img/icons/cardano.svg';
import { ReactComponent as TerraIcon } from '../../assets/img/crypto/terra.svg';
import { ReactComponent as DyDxIcon } from '../../assets/img/crypto/dydx.svg';
import { ReactComponent as DefiKingdomsIcon } from '../../assets/img/crypto/jewel.svg';
import { ReactComponent as DefaultIcon } from '../../assets/img/crypto/default.svg';
import { ReactComponent as PangolinIcon } from '../../assets/img/crypto/pangolin.svg';
import { ReactComponent as WavesIcon } from '../../assets/img/crypto/waves.svg';
import { ReactComponent as GusdIcon } from '../../assets/img/crypto/gusd.svg';
import { ReactComponent as SandboxIcon } from '../../assets/img/crypto/sandbox.svg';
import { ReactComponent as ManaIcon } from '../../assets/img/crypto/mana.svg';
import { ReactComponent as GalaIcon } from '../../assets/img/crypto/gala.svg';
import { ReactComponent as ZilIcon } from '../../assets/img/crypto/zil.svg';
import { ReactComponent as RsrIcon } from '../../assets/img/crypto/rsr.svg';
import { ReactComponent as LrcIcon } from '../../assets/img/crypto/lrc.svg';
import { ReactComponent as MaiIcon } from '../../assets/img/crypto/mimatic.svg';
import { ReactComponent as CalcIcon } from '../../assets/img/icons/calc.svg';
import { ReactComponent as ReloadIcon } from '../../assets/img/icons/reload.svg';
import { ReactComponent as RouteIcon } from '../../assets/img/icons/route.svg';

const getIcon = (iconName: string) => {
  switch (iconName) {
    case 'linkedin':
      return <LinkedinIcon />;
    case 'outline-mail':
      return <OutlineMail />;
    case 'sort-none':
      return <SortNoneIcon />;
    case 'sort-down':
      return <SortDownIcon />;
    case 'briefcase':
      return <BriefCaseIcon />;
    case 'chaos':
      return <ChaosIcon />;
    case 'file-video':
      return <FiledVideo />;
    case 'eraser':
      return <EraserIcon />;
    case 'folder-upload':
      return <FolderUpload />;
    case 'airplay':
      return <AirplayIcon />;
    case 'airplay-gradient-shadow':
      return <AirplayGradientShadowIcon />;
    case 'album-library':
      return <AlbumLibraryIcon />;
    case 'btc':
      return <BitcoinIcon />;
    case 'btc-logo':
      return <BitcoinLogoIcon />;
    case 'gift':
      return <GiftIcon />;
    case 'grid':
      return <GridIcon />;
    case 'history':
      return <HistoryIcon />;
    case 'keyboard':
      return <KeyboardIcon />;
    case 'palette':
      return <PaletteIcon />;
    case 'perspective':
      return <PerspectiveIcon />;
    case 'vector':
      return <VectorIcon />;
    case 'menu-toggle':
      return <MenuToggleIcon />;
    case 'check-square':
      return <CheckSquareIcon />;
    case 'checkbox-off':
      return <CheckboxOffIcon />;
    case 'github-alt':
      return <GithubAltIcon />;
    case 'google':
      return <GoogleIcon />;
    case 'twitter':
      return <TwitterIcon />;
    case 'outline-twitter':
      return <OutlineTwitter />;
    case 'eye':
      return <EyeIcon />;
    case 'eye-off':
      return <EyeOffIcon />;
    case 'bullet-blue':
      return <BulletBlueIcon />;
    case 'dydx':
      return <DyDxIcon />;
    case 'grid-four':
      return <GridFourIcon />;
    case 'grid-two-h':
      return <GridTwoHorizontal />;
    case 'chevron-down':
      return <ChevronDownIcon />;
    case 'chevron-down-filled':
      return <ChevronDownFilledIcon />;
    case 'remove':
      return <RemoveIcon />;
    case 'close':
      return <CloseIcon />;
    case 'search':
      return <SearchIcon />;
    case 'wi-fi':
      return <WifiIcon />;
    case 'polygon':
      return <PolygonIcon />;
    case 'mfam':
      return <MoonwellIcon />;
    case 'moonwell':
      return <MoonwellIcon />;
    case 'sushi':
      return <SushiIcon />;
    case 'wbtc':
    case 'wbtc.e':
      return <WbtcIcon />;
    case 'qibtc':
      return <BitcoinIcon />;
    case 'weth.e':
      return <WETHIcon />;
    case 'qieth':
      return <WETHIcon />;
    case 'weth':
      return <WETHIcon />;
    case 'benqi-logo':
      return <BenqiIcon />;
    case 'benqi':
      return <QIIcon />;
    case 'qiqi':
      return <QIIcon />;
    case 'qi':
      return <QIIcon />;
    case 'pink':
      return <PinkIcon />;
    case 'solar':
      return <SolarIcon />;
    case 'hnd':
      return <HundredFinanceIcon />;
    case 'mim':
      return <MIMIcon />;
    case 'pangolin':
      return <PangolinLogoSvg />;
    case 'png':
      return <PangolinIcon />;
    case 'rome':
      return <RomeIcon />;
    case 'frax':
      return <FraxIcon />;
    case 'ftm':
    case 'fantom':
      return <FantomIcon />;
    case 'harmony':
      return <HarmonyIcon />;
    case 'ksm':
      return <KsmIcon />;
    case 'wbnb':
      return <BnbIcon />;
    case 'bnb':
      return <BnbIcon />;
    case 'bnb.bsc':
      return <BnbIcon />;
    case 'busd':
      return <BUSDIcon />;
    case 'movr_bw':
      return <MovrBWIcon />;
    case 'wmovr':
      return <MovrIcon />;
    case 'movr':
      return <MovrIcon />;
    case 'near':
      return <NearIcon />;
    case 'arbitrum':
      return <ArbitrumIcon />;
    case 'moonriver':
      return <MoonriverIcon />;
    case 'solana':
      return <SolanaIcon />;
    case 'avalanche':
      return <AvalancheIcon />;
    case 'bell':
      return <BellIcon />;
    case 'settings':
      return <SettingsIcon />;
    case 'users':
      return <UsersIcon />;
    case 'sign-out':
      return <SignOutIcon />;
    case 'close-circle':
      return <CloseCircleIcon />;
    case 'check-circle':
      return <CheckCircleIcon />;
    case 'stacked-area-up':
      return <StackedAreaUpIcon />;
    case 'chevron-right':
      return <ChevronRightIcon />;
    case 'chevron-left':
      return <ChevronLeftIcon />;
    case 'list-boxes':
      return <ListBoxesIcon />;
    case 'exclamation-square':
      return <ExclamationSquareIcon />;
    case 'exclamation-mark':
      return <ExclamationMarkIcon />;
    case 'arrow-left':
      return <ArrowLeftIcon />;
    case 'breadcrumbs-arrow':
      return <BreadcrumbsArrowIcon />;
    case 'cloud-download':
      return <CloudDownloadIcon />;
    case 'printer':
      return <PrinterIcon />;
    case 'settings-adjust':
      return <SettingsAdjustIcon />;
    case 'grid-two-v':
      return <GridTwoVerticalIcon />;
    case 'checkbox-indeterminate':
      return <CheckboxIndeterminateIcon />;
    case 'fullscreen':
      return <FullscreenIcon />;
    case 'exit-fullscreen':
      return <ExitFullscreenIcon />;
    case 'coin-dollar':
      return <CoinDollarIcon />;
    case 'star':
      return <StarIcon />;
    case 'file-check-in':
      return <FileCheckInIcon />;
    case 'import':
      return <ImportIcon />;
    case 'user':
      return <UserIcon />;
    case 'scale':
      return <ScaleIcon />;
    case 'coins':
      return <CoinsIcon />;
    case 'veth':
      return <EthereumIcon />;
    case 'eth':
      return <EthereumIcon />;
    case 'ethereum':
      return <EthereumIcon />;
    case 'star-filled':
      return <StarFilledIcon />;
    case 'trash-can':
      return <TrashCanIcon />;
    case 'image-upload':
      return <ImageUploadIcon />;
    case 'edit':
      return <EditIcon />;
    case 'chevron-right-small':
      return <ChevronRightSmallIcon />;
    case 'image-upload-big':
      return <ImageUploadBigIcon />;
    case 'oracle':
      return <OracleIcon />;
    case 'chat-dots':
      return <ChatDotsIcon />;
    case 'chat':
      return <ChatIcon />;
    case 'chainlink':
      return <ChainlinkIcon />;
    case 'bitcoin':
      return <BitcoinIcon />;
    case 'ethereum-token':
      return <EthereumTokenIcon />;
    case 'close-small':
      return <CloseSmallIcon />;
    case 'bubble-race':
      return <BubbleRaceIcon />;
    case 'file-code':
      return <FileCodeIcon />;
    case 'file-list':
      return <FileListIcon />;
    case 'external-link':
      return <ExternalLinkIcon />;
    case 'copy':
      return <CopyIcon />;
    case 'users-white':
      return <UsersWhiteIcon />;
    case 'cardano':
      return <CardanoIcon />;
    case 'ada':
      return <CardanoIcon />;
    case 'check':
      return <CheckIcon />;
    case 'burger-menu':
      return <BurgerMenuIcon />;
    case 'sol':
      return <SolIcon />;
    case 'aave':
      return <AaveIcon />;
    case 'qiavax':
      return <AvaxIcon />;
    case 'avax':
      return <AvaxIcon />;
    case 'savax':
      return <SavaxIcon />;
    case 'celo':
      return <CeloIcon />;
    case 'compound':
      return <CompoundIcon />;
    case 'comp':
      return <CompoundIcon />;
    case 'qidai':
      return <DaiIcon />;
    case 'dai':
      return <DaiIcon />;
    case 'link':
      return <LinkIcon />;
    case 'luna':
      return <LunaIcon />;
    case 'lunc':
      return <LunaIcon />;
    case 'imatic':
    case 'wmatic':
    case 'matic':
      return <MaticIcon />;
    case 'maker':
      return <MakerIcon />;
    case 'mkr':
      return <MakerIcon />;
    case 'grt':
      return <TheGraphIcon />;
    case 'uniswap':
      return <UniswapIcon />;
    case 'uni':
      return <UniIcon />;
    case 'optimism':
    case 'op':
      return <OptimismIcon />;
    case 'qiusdt':
    case 'fusdt':
    case 'usdt':
      return <UsdtIcon />;
    case 'qiusdc':
      return <UsdcIcon />;
    case 'usd':
      return <UsdcIcon />;
    case 'usdc':
      return <UsdcIcon />;
    case 'susd':
      return <SusdIcon />;
    case 'algorand':
      return <AlgorandIcon />;
    case 'algo':
      return <AlgorandIcon />;
    case 'warning':
      return <WarningIcon />;
    case 'question-mark':
      return <QuestionMarkCircleIcon />;
    case 'zrx':
      return <ZrxIcon />;
    case 'bat':
      return <BatIcon />;
    case 'dot':
      return <DotIcon />;
    case 'crv':
      return <CrvIcon />;
    case 'info':
      return <InfoIcon />;
    case 'ust':
      return <TerraIcon />;
    case 'jewel':
      return <DefiKingdomsIcon />;
    case 'plus':
      return <PlusIcon />;
    case 'plus-circle':
      return <PlusCircleIcon />;
    case 'view-list':
      return <ViewListIcon />;
    case 'web':
      return <GlobeIcon />;
    case 'clock':
      return <ClockIcon />;
    case 'pie-gradient-shadow':
      return <PieGradientShadowIcon />;
    case 'triangle-gradient-shadow':
      return <TriangleGradientShadowIcon />;
    case 'light-bulb':
      return <LightBulbIcon />;
    case 'doge':
      return <DogeIcon />;
    case 'dogecoin':
      return <DogeIcon />;
    case 'ltc':
      return <LtcIcon />;
    case 'litecoin':
      return <LtcIcon />;
    case 'bch':
      return <BchIcon />;
    case 'bitcoin-cash':
      return <BchIcon />;
    case 'atom':
      return <AtomSvg />;
    case 'cosmos':
      return <AtomSvg />;
    case 'stellar':
      return <XlmIcon />;
    case 'xlm':
      return <XlmIcon />;
    case 'xmr':
      return <XmrIcon />;
    case 'monero':
      return <XmrIcon />;
    case 'etc':
      return <EtcIcon />;
    case 'ethereum-classic':
      return <EtcIcon />;
    case 'icp':
      return <IcpIcon />;
    case 'internet-computer':
      return <IcpIcon />;
    case 'fil':
      return <FilIcon />;
    case 'filecoin':
      return <FilIcon />;
    case 'eos':
      return <EosIcon />;
    case 'rune':
      return <RuneIcon />;
    case 'thorchain':
      return <RuneIcon />;
    case 'zec':
      return <ZecIcon />;
    case 'zcash':
      return <ZecIcon />;
    case 'snx':
      return <SnxIcon />;
    case 'synthetix':
      return <SnxIcon />;
    case 'enj':
      return <EnjIcon />;
    case 'enjin':
      return <EnjIcon />;
    case 'yfi':
      return <YfiIcon />;
    case 'yearn':
      return <YfiIcon />;
    case '1inch':
      return <OneInchIcon />;
    case 'uma':
      return <UmaIcon />;
    case 'shib':
      return <ShibIcon />;
    case 'axs':
      return <AxsIcon />;
    case 'ape':
      return <ApeIcon />;
    case 'gmt':
      return <GmtIcon />;
    case 'waves':
      return <WavesIcon />;
    case 'gusd':
      return <GusdIcon />;
    case 'steth':
      return <StethIcon />;
    case 'sand':
      return <SandboxIcon />;
    case 'mana':
      return <ManaIcon />;
    case 'gala':
      return <GalaIcon />;
    case 'zil':
      return <ZilIcon />;
    case 'rsr':
      return <RsrIcon />;
    case 'lrc':
      return <LrcIcon />;
    case 'telegram':
      return <TelegramIcon />;
    case 'reddit':
      return <RedditIcon />;
    case 'list':
      return <ListIcon />;
    case 'manage-search':
      return <ManageSearchIcon />;
    case 'calendar':
      return <CalendarIcon />;
    case 'kava':
      return <KavaIcon />;
    case 'ens':
      return <EnsIcon />;
    case 'theta':
      return <ThetaIcon />;
    case 'chz':
      return <ChzIcon />;
    case 'cro':
      return <CroIcon />;
    case 'vet':
      return <VetIcon />;
    case 'chr':
      return <ChrIcon />;
    case 'rocket':
      return <RocketIcon />;
    case 'idea':
      return <IdeaIcon />;
    case 'folder-check':
      return <FolderCheckIcon />;
    case 'planet':
      return <PlanetIcon />;
    case 'circular':
      return <CircularIcon />;
    case 'road-sign':
      return <RoadSignIcon />;
    case 'more':
      return <MoreIcon />;
    case 'fork':
      return <ForkIcon />;
    case 'user_plus':
      return <UserPlusIcon />;
    case 'pencil':
      return <PencilIcon />;
    case 'ether':
      return <EtherIcon />;
    case 'deposit-icon':
      return <DepositIcon />;
    case 'borrow-icon':
      return <BorrowIcon />;
    case 'repay-icon':
      return <RepayIcon />;
    case 'redeem-icon':
      return <ReedemPayIcon />;
    case 'exponential-graph':
      return <ExponentialGraphIcon />;
    case 'heart-icon':
      return <HeartIcon />;
    case 'lock-icon':
      return <LockIcon />;
    case 'coins-stack-icon':
      return <CoinsStackIcon />;
    case 'location-warning-icon':
      return <LocationWarningIcon />;
    case 'wallet-icon':
      return <WalletIcon />;
    case 'card-eject':
      return <CardEjectIcon />;
    case 'ghst':
      return <GhstIcon />;
    case 'jeur':
      return <JeurIcon />;
    case 'dpi':
      return <DpiIcon />;
    case 'bal':
      return <BalIcon />;
    case 'ageur':
      return <AgeurIcon />;
    case 'eurs':
      return <EursIcon />;
    case 'wone':
    case 'one':
      return <WoneIcon />;
    case 'mimatic':
    case 'mai':
      return <MaiIcon />;
    case 'makerdao':
      return <MakerDaoIcon />;
    case 'reload':
      return <ReloadIcon />;
    case 'calc':
      return <CalcIcon />;
    case 'route':
      return <RouteIcon />;

    default:
      return <DefaultIcon />;
  }
};

export interface CustomIconProps {
  icon: string
  className?: string
  sx?: SxProps
}

export const CustomIcon: FC<CustomIconProps> = ({
  icon,
  className,
  sx,
}): JSX.Element => (
  <Box className={`${icon}-icon ${className || ''}`} display="flex" alignItems="center" sx={sx}>
    {getIcon(icon)}
  </Box>
);
