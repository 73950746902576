import { AggregationName, BlockchainSimulation } from '@chaos/types';
import { formatAmount, getFractionDigits } from '@chaos/ui/utils/formatters';

export const getSimulationObserverNameById = (simulation: BlockchainSimulation, id: string) => (
  simulation.observers.find((obs) => obs.refId === id)?.name
);

export type ConfidenceInterval = {
  min: number;
  mean: number;
  max: number;
  interval: number;
};

export const parseIntervalString = (interval: string): ConfidenceInterval => {
  const splitted = interval.split('±').filter((el) => !!el);

  if (splitted.length !== 2) {
    return ({
      min: 0,
      mean: 0,
      max: 0,
      interval: 0,
    });
  }

  const [mean, intervalDiff] = splitted;

  return ({
    min: Number(mean) - Number(intervalDiff),
    mean: Number(mean),
    max: Number(mean) + Number(intervalDiff),
    interval: Number(intervalDiff),
  });
};

export const confidenceIntervalToString = (confidenceInterval: string): string => {
  const {
    mean: confidenceIntervalMean,
    interval,
  } = parseIntervalString(confidenceInterval);

  const fractionDigits = Math.max(2, getFractionDigits(confidenceIntervalMean));

  const intervalStr = interval ? ` ± ${formatAmount(interval, { notation: 'compact', maximumFractionDigits: fractionDigits })}` : '';

  return `${formatAmount(confidenceIntervalMean, { notation: 'compact', maximumFractionDigits: fractionDigits })}${intervalStr}`;
};

export const getDistributionAxisData = (values: number[]): {
  numberOfFractionDigits: number,
  data: { x: number, y: number }[] } => {
  const sortedValues = values.sort((a, b) => a - b);

  const numberOfIntervals = Math.round(
    1 + 3.322 * Math.log10(sortedValues.length || 1),
  ); // Sturge's Rule

  const bucketSize = (sortedValues[sortedValues.length - 1] - sortedValues[0]) / numberOfIntervals;

  const numberOfFractionDigits = new Intl.NumberFormat('en-US', {
    notation: 'compact',
  }).format(bucketSize).replace(/[^0-9.,]+/, '').split('.')[1]?.length || 0;

  const data = (new Array(numberOfIntervals)
    .fill(undefined)).map((_, idx: number) => {
    const value = sortedValues[0] + (idx * bucketSize);
    const nextValue = sortedValues[0] + ((idx + 1) * bucketSize);

    const numberOfSims = sortedValues.reduce((
      sum: number,
      currVal: number,
    ) => (sum + ((currVal >= value && currVal <= nextValue) ? 1 : 0)), 0);
    return { x: value, y: numberOfSims };
  });

  return { numberOfFractionDigits, data };
};

export const aggregateDataOptions: Array<{ label: string, value: AggregationName }> = [
  {
    value: 'last',
    label: 'Last',
  },
  {
    value: 'average',
    label: 'Avg',
  },
  {
    value: 'max',
    label: 'Max',
  },
  {
    value: 'min',
    label: 'Min',
  },
  {
    value: 'sum',
    label: 'Sum',
  },
];
