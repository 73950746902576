import { RoutePath } from 'src/config/routes';
import { NavLink } from './types';

export const NAV_LINKS: NavLink[] = [
  {
    to: '/',
    icon: 'lightning',
    label: 'Risk Tools',
  },
  {
    to: RoutePath.Simulations.Home,
    icon: 'airplay',
    label: 'Simulations',
    permission: 'simulations',
  },
  {
    to: RoutePath.Simulations.Results,
    icon: 'history',
    label: 'Results',
    permission: 'simulations',
  },
  {
    title: 'Catalogs',
    icon: 'palette',
    permission: 'simulations',
    links: [
      {
        to: RoutePath.AgentPlayground.Home,
        icon: 'users',
        label: 'Agents',
        permission: 'agents',
      },
      {
        to: RoutePath.ScenarioCatalogue.Home,
        icon: 'scenario',
        label: 'Scenarios',
        permission: 'scenarios',
      },
      {
        to: RoutePath.AssertionWizard.Home,
        icon: 'assertion',
        label: 'Assertions',
        permission: 'assertions',
      },
      {
        to: RoutePath.ObserverConfiguration.Home,
        icon: 'observer',
        label: 'Observers',
        permission: 'observers',
      },
      {
        to: RoutePath.Deployments,
        icon: 'perspective',
        label: 'Contracts',
        permission: 'deployments',
      },
    ],
  },
  {
    to: RoutePath.ParamRecommendations.Home,
    icon: 'search',
    label: 'Recommendations',
    permission: 'recommendations',
  },
];
