import {
  BlockchainSimulation,
  SimulationPermutationInfo,
  SimulationPermutationArgInfo,
  BlockchainSimulationCalculatedRandomArgData,
  ScriptArg,
  ScriptResource,
} from '@chaos/types';
import { FC } from 'react';
import SimulationFormSummary from 'src/components/blockchain-simulation-form/SimulationFormSummary';
import { RouteParams, RoutePath } from 'src/config/routes';

interface SimulationDetailsSummaryProps {
  simulation?: BlockchainSimulation,
  assertions?: ScriptResource[]
  agents?: ScriptResource[]
  scenarios?: ScriptResource[]
  simulationResultId?: string,
  premutation?: SimulationPermutationInfo,
  randomArgData?: BlockchainSimulationCalculatedRandomArgData[],
}

export const SimulationConfigurationDetails: FC<SimulationDetailsSummaryProps> = (
  {
    simulation,
    agents,
    assertions,
    scenarios,
    premutation,
    randomArgData,
  },
) => {
  const getScriptsArg = (instanceId: string, argName: string, argValue: 'string' | 'number' | ScriptArg)
  : { parameterName: string, parameterValue: string } => {
    if (premutation !== undefined) {
      const filteredArgs: SimulationPermutationArgInfo[] = premutation.args.filter(
        (arg: SimulationPermutationArgInfo) => arg.scriptId === instanceId
          && arg.argName === argName,
      ) ?? [];
      if (filteredArgs.length > 0) {
        return {
          parameterName: argName,
          parameterValue: filteredArgs[0].argValue.toString(),
        };
      }
    }

    if (randomArgData !== undefined) {
      const filteredArgs: BlockchainSimulationCalculatedRandomArgData[] = randomArgData
        .filter(
          (arg: BlockchainSimulationCalculatedRandomArgData) => arg.scriptId
            === instanceId
        && arg.argName === argName,
        ) ?? [];
      if (filteredArgs.length > 0) {
        return {
          parameterName: argName,
          parameterValue: filteredArgs[0].argValue.toString(),
        };
      }
    }

    return {
      parameterName: argName,
      parameterValue: typeof argValue === 'string' ? argValue : (argValue.value?.toString() || ''),
    };
  };

  const scenarioToRuntimeData = (
    scenario: Partial<ScriptResource>,
    type: string,
    scenarioConfig?: ScriptResource,
  ) => ({
    title: scenario.name || '',
    subtitle: scenarioConfig?.name || '',
    subtitleLink: scenarioConfig?.id
      ? RoutePath.ScenarioCatalogue.Details.replace(RouteParams.Id, scenarioConfig.id)
      : '',
    description: scenarioConfig?.description || '',
    params: [{
      parameterName: 'type',
      parameterValue: type,
    }],
    fullPath: scenario.fullPath!,
    args: Object.entries(scenario.args || {}).filter((arg) => arg[0] !== 'chainUrl').map(
      ([key, value]) => getScriptsArg(scenario.instanceId!, key, value),
    ),
  });

  const agentsData = simulation?.agents?.map((agent) => {
    const agentConfig = agents?.find((x) => x.id === agent.refId);
    return ({
      title: agent.name || '',
      subtitle: agentConfig?.name || '',
      subtitleLink: agentConfig?.id
        ? RoutePath.AgentPlayground.Details.replace(RouteParams.Id, agentConfig.id)
        : '',
      description: agentConfig?.description || '',
      fullPath: agentConfig?.fullPath || '',
      args: Object.entries(agent.args || {}).filter((arg) => arg[0] !== 'chainUrl').map(
        ([key, value]) => getScriptsArg(agent.instanceId!, key, value),
      ),
    });
  }) || [];

  const runtimeScenarioData = simulation?.scenarios?.runtime?.map((scenario) => {
    const scenarioConfig = scenarios?.find((x) => x.id === scenario.refId);
    return scenarioToRuntimeData(scenario, 'runtime', scenarioConfig);
  }) || [];

  const setupScenarioData = simulation?.scenarios?.setup?.map((scenario) => {
    const scenarioConfig = scenarios?.find((x) => x.id === scenario.refId);
    return scenarioToRuntimeData(scenario, 'setup', scenarioConfig);
  }) || [];

  const assertionsData = simulation?.assertions?.map((assertion) => {
    const assertionConfig = assertions?.find((x) => x.id === assertion.refId);
    return ({
      title: assertion.name || '',
      subtitle: assertionConfig?.name || '',
      subtitleLink: assertionConfig?.id
        ? RoutePath.AssertionWizard.Details.replace(RouteParams.Id, assertionConfig.id)
        : '',
      description: assertionConfig?.description || '',
      params: [{
        parameterName: 'type',
        parameterValue: assertion.type,
      }],
      fullPath: assertionConfig?.fullPath || '',
      args: Object.entries(assertion.args || {}).filter((arg) => arg[0] !== 'chainUrl').map(([key, value]) => ({
        parameterName: key,
        parameterValue: typeof value === 'string' ? value : (value.value?.toString() || ''),
      })),
    });
  }) || [];

  return (
    <SimulationFormSummary
      shortInfo
      defaultExpanded
      title="Configurations"
      cardDtaTitleStyle="inherit"
      observers={[]}
      agents={agentsData}
      scenarios={[...setupScenarioData, ...runtimeScenarioData]}
      assertions={assertionsData}
    />
  );
};
