import { memo } from 'react';
import {
  AggregationName,
  BlockchainSimulation,
  Header,
  MetricStatistics,
  ObservationAggregationResult,
  RenderData,
} from '@chaos/types';
import { Box } from '@chaos/ui';
import { formatAmount } from 'src/components/charts/utils';
import { ChaosTable } from 'src/components/widgets';
import { CanvasLineChart } from 'src/components/charts';
import {
  confidenceIntervalToString,
  getDistributionAxisData,
  getSimulationObserverNameById,
  parseIntervalString,
} from '../utils';

const tableHeaders: Header[] = [
  {
    renderType: 'TEXT',
    text: 'Metric',
    width: '20%',
  },
  {
    renderType: 'TEXT',
    text: 'Mean',
  },
  {
    renderType: 'TEXT',
    text: 'Confidence Interval (95%)',
  },
  {
    renderType: 'TEXT',
    text: 'P25',
  },
  {
    renderType: 'TEXT',
    text: 'P50',
  },
  {
    renderType: 'TEXT',
    text: 'P75',
  },
  {
    renderType: 'TEXT',
    text: 'Min',
  },
  {
    renderType: 'TEXT',
    text: 'Max',
  },
  {
    renderType: 'TEXT',
    text: 'Distribution',
    width: '20%',
  },
];

const DistributionChart = memo(({ observerName, repetitionValues }: {
  observerName: string,
  repetitionValues: number[],
}) => (
  <Box width="100%" height="100%" py={1.5}>
    <CanvasLineChart
      data={[{
        id: observerName,
        data: getDistributionAxisData(repetitionValues).data,
      }]}
      margin={{
        top: 3, right: 3, bottom: 3, left: 3,
      }}
      enableArea
      enablePoints
      pointColor="#1ea3d6"
      pointSize={5}
    />
  </Box>
));

const aggregationRow = (
  simulation: BlockchainSimulation,
  aggregationName: AggregationName,
  observationsMetaData: Record<string, ObservationAggregationResult>[],
) => ({ id, aggregationStatistics }: MetricStatistics): RenderData[] => {
  const {
    mean,
    confidence_interval: confidenceInterval,
    p25,
    p50,
    p75,
    min,
    max,
  } = aggregationStatistics[aggregationName];
  const repetitionValues = observationsMetaData.reduce<number[]>((res, m) => {
    const value = Object.values(m).find((v) => v.id === id)?.aggregations[aggregationName];
    return value ? [...res, value] : res;
  }, []);
  const { mean: confidenceIntervalMean } = parseIntervalString(confidenceInterval);

  return [{
    renderType: 'TEXT',
    text: getSimulationObserverNameById(simulation, id) || '',
  },
  {
    renderType: 'TEXT',
    text: formatAmount(mean, { notation: 'compact', maximumFractionDigits: 2 }),
    tooltipText: formatAmount(mean, { notation: 'standard' }),
    value: mean,
  },
  {
    renderType: 'TEXT',
    text: confidenceIntervalToString(confidenceInterval),
    value: confidenceIntervalMean,
  },
  {
    renderType: 'TEXT',
    text: formatAmount(p25, { notation: 'compact', maximumFractionDigits: 2 }),
    tooltipText: formatAmount(p25, { notation: 'standard' }),
    value: p25,
  },
  {
    renderType: 'TEXT',
    text: formatAmount(p50, { notation: 'compact', maximumFractionDigits: 2 }),
    tooltipText: formatAmount(p50, { notation: 'standard' }),
    value: p50,
  },
  {
    renderType: 'TEXT',
    text: formatAmount(p75, { notation: 'compact', maximumFractionDigits: 2 }),
    tooltipText: formatAmount(p75, { notation: 'standard' }),
    value: p75,
  },
  {
    renderType: 'TEXT',
    text: formatAmount(min, { notation: 'compact', maximumFractionDigits: 2 }),
    tooltipText: formatAmount(min, { notation: 'standard' }),
    value: min,
  },
  {
    renderType: 'TEXT',
    text: formatAmount(max, { notation: 'compact', maximumFractionDigits: 2 }),
    tooltipText: formatAmount(max, { notation: 'standard' }),
    value: max,
  },
  {
    renderType: 'CUSTOM',
    text: '',
    component: <DistributionChart observerName="observerName" repetitionValues={repetitionValues} />,
    value: 1321,
  }];
};

type Props = {
  aggregationMethod: AggregationName;
  simulation: BlockchainSimulation;
  metricStatistics: MetricStatistics[];
  observationsMetaData: Record<string, ObservationAggregationResult>[],
};

const AggregationTable = ({
  simulation,
  metricStatistics,
  aggregationMethod,
  observationsMetaData,
}: Props) => {
  const tableData: RenderData[][] = metricStatistics.map(
    aggregationRow(
      simulation,
      aggregationMethod,
      observationsMetaData,
    ),
  );
  return (
    <ChaosTable
      title="Expiriment View Aggregation"
      headers={tableHeaders}
      data={tableData}
      pageSize={20}
    />
  );
};

export default AggregationTable;
