import { FC } from 'react';
import { SxProps } from '@mui/material';
import { Box } from '@chaos/ui/box';
import { ReactComponent as ScenarioIcon } from '../../assets/img/icons/nav/bubbles.svg';
import { ReactComponent as ObserverIcon } from '../../assets/img/icons/nav/eye.svg';
import { ReactComponent as AssertionIcon } from '../../assets/img/icons/nav/file-check.svg';
import { ReactComponent as AirplayIcon } from '../../assets/img/icons/nav/airplay.svg';
import { ReactComponent as AirplayGradientShadowIcon } from '../../assets/img/icons/airplay-gradient-shadow.svg';
import { ReactComponent as ViewListIcon } from '../../assets/img/icons/nav/view-list.svg';
import { ReactComponent as AlbumLibraryIcon } from '../../assets/img/icons/nav/album-library.svg';
import { ReactComponent as GiftIcon } from '../../assets/img/icons/nav/gift.svg';
import { ReactComponent as GridIcon } from '../../assets/img/icons/nav/grid.svg';
import { ReactComponent as HistoryIcon } from '../../assets/img/icons/nav/history.svg';
import { ReactComponent as KeyboardIcon } from '../../assets/img/icons/nav/keyboard.svg';
import { ReactComponent as PaletteIcon } from '../../assets/img/icons/nav/palette.svg';
import { ReactComponent as PerspectiveIcon } from '../../assets/img/icons/nav/perspective.svg';
import { ReactComponent as VectorIcon } from '../../assets/img/icons/nav/vector.svg';
import { ReactComponent as OracleIcon } from '../../assets/img/icons/nav/oracle.svg';
import { ReactComponent as UsersIcon } from '../../assets/img/icons/users.svg';
import { ReactComponent as LightningIcon } from '../../assets/img/icons/lightning.svg';

const getIcon = (iconName: string) => {
  switch (iconName) {
    case 'airplay':
      return <AirplayIcon />;
    case 'airplay-gradient-shadow':
      return <AirplayGradientShadowIcon />;
    case 'album-library':
      return <AlbumLibraryIcon />;
    case 'users':
      return <UsersIcon />;
    case 'gift':
      return <GiftIcon />;
    case 'grid':
      return <GridIcon />;
    case 'history':
      return <HistoryIcon />;
    case 'keyboard':
      return <KeyboardIcon />;
    case 'palette':
      return <PaletteIcon />;
    case 'perspective':
      return <PerspectiveIcon />;
    case 'vector':
      return <VectorIcon />;
    case 'observer':
      return <ObserverIcon />;
    case 'assertion':
      return <AssertionIcon />;
    case 'scenario':
      return <ScenarioIcon />;
    case 'view-list':
      return <ViewListIcon />;
    case 'oracle':
      return <OracleIcon />;
    case 'lightning':
      return <LightningIcon />;

    default:
      return <AirplayIcon />;
  }
};

interface CustomIconProps {
  icon: string
  className?: string
  sx?: SxProps
}

export const NavIcon: FC<CustomIconProps> = ({
  icon,
  className,
  sx,
}): JSX.Element => (
  <Box className={`${icon}-icon ${className || ''}`} display="flex" alignItems="center" sx={sx}>
    {getIcon(icon)}
  </Box>
);
