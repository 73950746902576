import {
  FC,
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { saveAs } from 'file-saver';
import { CodeEntity, Nullable } from '@chaos/types';
import { useDropzone } from 'react-dropzone';
import { Loader } from 'src/components/loader';
import { Paper, Box, Typography } from '@chaos/ui';
import { IconButton } from '@chaos/ui/icon-button';
import { CustomIcon } from 'src/components/custom-icon';
import Editor, { useMonaco } from '@monaco-editor/react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import {
  getDefaultPreset,
  MONACO_OPTIONS,
  MONACO_THEME,
  SUPPORTED_FILE_TYPES,
} from './config';
import './styles.scss';
import { getIsFullScreenSupported } from './helpers';

interface CodeEditorProps {
  entity?: CodeEntity
  initialCode?: string
  onChange?: (code: string | undefined, args?: string[], isClass?: boolean) => void
}

export const CodeEditor: FC<CodeEditorProps> = ({ entity, initialCode, onChange }) => {
  const monaco = useMonaco();
  const [error, setError] = useState<Nullable<string>>(null);
  const [isSavingSupport, setIsSavingSupport] = useState<boolean>(true);
  const defaultPreset = useMemo(() => entity && getDefaultPreset(entity), [entity]);
  const [code, setCode] = useState<string>(initialCode ?? defaultPreset ?? '');
  const fullScreenHandle = useFullScreenHandle();
  const isFullScreenActive = useMemo(() => fullScreenHandle.active, [fullScreenHandle]);
  const isFullScreenSupported = useMemo(() => getIsFullScreenSupported(), []);
  const toggleFullScreen = useCallback(() => {
    if (!isFullScreenSupported) {
      setError('Fullscreen is not supported in your browser');
      return;
    }
    if (isFullScreenActive) {
      void fullScreenHandle.exit();
    } else {
      void fullScreenHandle.enter();
    }
  }, [isFullScreenSupported, fullScreenHandle, isFullScreenActive]);

  useEffect(() => {
    try {
      if (!new Blob()) {
        setIsSavingSupport(false);
      }
    } catch (e) {
      setIsSavingSupport(false);
    }
  }, []);

  useEffect(() => {
    if (monaco) {
      monaco.editor.defineTheme('custom-theme', MONACO_THEME);
    }
  }, [monaco]);

  useEffect(() => {
    const testMain = /export.*main\(([\s\S]*?)\)/.exec(code || '');
    const testMainVars = testMain?.[1].split(',').map((s) => s.trim()).filter((s) => s.length > 0);

    const testClass = /class\s([^\s]+)\s*?{[\s\S]*?constructor\(([\s\S]*?)\)\s*?{/.exec(code || '');
    const testClassName = testClass?.[1];
    const testClassVars = testClass?.[2].split(',').map((s) => s.trim()).filter((s) => s.length > 0);

    if (testClassName && testClassVars) {
      setError(null);
      if (onChange) {
        const isClass = true;
        onChange(code, testClassVars, isClass);
      }
    } else if (testMainVars) {
      setError(null);
      if (onChange) {
        const isClass = false;
        onChange(code, testMainVars, isClass);
      }
    } else {
      setError('Invalid code structure');
      if (onChange) {
        onChange(undefined);
      }
    }
  }, [code, onChange]);

  const dropFileHandler = useCallback((acceptedFiles: Blob[]) => {
    try {
      if (!acceptedFiles.length) {
        return;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        if (!e.target) {
          return;
        }
        const codeInFile = e.target.result;
        if (codeInFile instanceof ArrayBuffer) {
          if (!('TextDecoder' in window)) {
            setError('Sorry, this browser does not support file decoding...');
            return;
          }
          const enc = new TextDecoder('utf-8');
          const arr = new Uint8Array(codeInFile);
          setCode(enc.decode(arr));
        } else {
          setCode(e.target.result as string);
        }
      };
      // eslint-disable-next-line
      reader.readAsText(acceptedFiles[0])
    } catch (e) {
      console.error('Failed to read file');
      setError('Failed to read file');
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: SUPPORTED_FILE_TYPES,
    onDropAccepted: dropFileHandler,
  });

  const saveCode = useCallback(() => {
    if (!isSavingSupport) {
      setError('Saving files is not supported in your browser');
      return;
    }
    const blob = new Blob([code], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, `${entity?.toLowerCase() ?? 'script'}.js`);
  }, [entity, code, isSavingSupport]);

  if (!monaco) {
    return <Loader />;
  }

  return (
    <FullScreen handle={fullScreenHandle}>
      <Paper
        variant="card"
        sx={{
          borderRadius: isFullScreenActive ? 0 : 4,
        }}
      >
        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: 3,
          columnGap: 1,
          '& > div': {
            '&:hover': {
              cursor: 'pointer',
            },
          },
        }}
        >
          {error && <Typography color="#DE4469" display="flex" alignItems="center">{error}</Typography>}
          {onChange && (
            <IconButton {...getRootProps()} title="Upload">
              <input {...getInputProps()} />
              <CustomIcon icon="folder-upload" />
            </IconButton>
          )}
          <IconButton onClick={saveCode} title="Download"><CustomIcon icon="cloud-download" /></IconButton>
          {isFullScreenSupported && (
            <IconButton onClick={toggleFullScreen} title={isFullScreenActive ? 'Exit Fullscreen' : 'Fullscreen'}>
              <CustomIcon icon={isFullScreenActive ? 'exit-fullscreen' : 'fullscreen'} />
            </IconButton>
          )}
        </Box>
        <Box bgcolor="background.default" borderRadius={1} height={isFullScreenActive ? '90vh' : '40vh'} py={3}>
          <Editor
            value={code}
            theme="custom-theme"
            options={{
              ...MONACO_OPTIONS,
              readOnly: !onChange,
            }}
            language="javascript"
            onChange={(value) => setCode(value || '')}
          />
        </Box>
      </Paper>
    </FullScreen>
  );
};
