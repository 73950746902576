import {
  useState, MouseEvent, useContext, useMemo, useEffect, useCallback,
} from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { isEmpty, useFirebase } from 'react-redux-firebase';

import { Box } from '@chaos/ui/box';
import { Typography } from '@chaos/ui/typography';
import { MenuItem } from '@chaos/ui/menu-item';
import { Menu } from '@chaos/ui/menu';
import { Badge } from '@chaos/ui/badge';
import { Link } from '@chaos/ui/link';
import { Button } from '@chaos/ui/button';
import { useFallbackUserAvatar } from 'src/hooks';
import { CustomIcon } from 'src/components/custom-icon';
import { useAppDispatch, useAppSelector } from 'src/store';
import { RoutePath } from 'src/config/routes';
import { TeamContext } from 'src/context/team-context';
import { useTheme } from '@chaos/ui';
import { NetworkContext, networkOptions } from 'src/context/network-provider';
import { ClientContext } from 'src/context/client-provider';
import { setTeamId } from 'src/store/team/reducer';

import { NotificationsData } from 'src/pages/notifications-page';
import { ConfirmationPromptDialog } from '../confirmation-prompt-dialog';

const clientOptions = [
  {
    endpoint: '',
    name: 'Mainnet',
  },
  {
    endpoint: 'https://chaoslabs.xyz/cloud/jhg87jker0',
    name: 'Chaos Cloud',
  },
];

export enum ProfileOptionsAction {
  SingOut = 'SignOut',
}

export const profileOptions = [
  {
    name: 'Profile',
    icon: 'user',
    to: RoutePath.Profile.Home,
    adminOnly: true,
  },
  {
    name: 'Notifications',
    icon: 'bell',
    to: RoutePath.Notifications,
    adminOnly: true,
  },
  {
    name: 'Create Team',
    icon: 'users-white',
    to: RoutePath.Team.New,
    adminOnly: true,
  },
  {
    name: 'Sign Out',
    icon: 'sign-out',
    action: ProfileOptionsAction.SingOut,
  },
];

const teamsWithLockedNetwork = [
  'Maker',
  'Aave',
  'Benqi 🔺',
  'Moonwell',
  'Pangolin',
];

export const AppBar = (): JSX.Element => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const firebase = useFirebase();
  const defaultUserAvatar = useFallbackUserAvatar();
  const auth = useAppSelector((state) => state.firebase.auth);
  const isAdmin = useAppSelector((state) => state.firebase.profile.role === 'admin');
  const isAuthed = !isEmpty(auth);
  const profile = useAppSelector((state) => state.firebase.profile);
  const { teams, team, setTeam } = useContext(TeamContext);
  const { setIsChaosCloud } = useContext(ClientContext);

  const [isShowLogoutPrompt, setIsShowLogoutPrompt] = useState(false);
  const [networkAnchorEl, setNetworkAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const [clientAnchorEl, setClientAnchorEl] = useState<null | HTMLElement>(null);
  const [teamAnchorEl, setTeamAnchorEl] = useState<null | HTMLElement>(null);
  const [profileAnchorEl, setProfileAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const { networkID, setNetworkID } = useContext(NetworkContext);
  const [clientSelectedIndex, setClientSelectedIndex] = useState(0);
  const [notifications] = useState <NotificationsData[]>([]);

  useEffect(() => {
    if (team) {
      const index = networkOptions.findIndex(
        (network) => network.name === team?.protocolBlockchain,
      );
      setNetworkID(index === -1 ? 0 : index);
    }
  }, [team, setNetworkID]);

  const isNetworkMenuOpen = Boolean(networkAnchorEl);
  const isClientMenuOpen = Boolean(clientAnchorEl);
  const isTeamMenuOpen = Boolean(teamAnchorEl);
  const isProfileMenuOpen = Boolean(profileAnchorEl);

  const handleNetworkMenuOpen = (event: MouseEvent<HTMLElement>) => {
    if (isAuthed) {
      setNetworkAnchorEl(event.currentTarget);
    }
  };

  const handleClientMenuOpen = (event: MouseEvent<HTMLElement>) => {
    if (isAuthed) {
      setClientAnchorEl(event.currentTarget);
    }
  };

  const handleTeamMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setTeamAnchorEl(event.currentTarget);
  };

  const handleProfileMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setNetworkAnchorEl(null);
    setClientAnchorEl(null);
    setTeamAnchorEl(null);
    setProfileAnchorEl(null);
  };

  const handleNetworkMenuItemClick = (
    _: MouseEvent<HTMLElement>,
    index: number,
  ) => {
    setNetworkID(index);
    setNetworkAnchorEl(null);
  };

  const handleClientMenuItemClick = (
    _: MouseEvent<HTMLElement>,
    index: number,
  ) => {
    setIsChaosCloud(index === 1);
    setClientSelectedIndex(index);
    setClientAnchorEl(null);
  };

  const handleTeamMenuItemClick = (
    _: MouseEvent<HTMLElement>,
    index: number,
  ) => {
    dispatch(setTeamId(teams[index].id));
    if (setTeam) {
      setTeam(teams[index].id);
    }
    setTeamAnchorEl(null);
  };

  const handleLogoutConfirm = useCallback(() => {
    setIsShowLogoutPrompt(() => false);
    void firebase.logout();
  }, [firebase]);

  const handleLogoutReject = useCallback(() => {
    setIsShowLogoutPrompt(() => false);
  }, []);

  const isNetworkSelectDisabled = useMemo(
    () => team && teamsWithLockedNetwork.includes(team.name),
    [team],
  );

  const networkMenu = (
    <Menu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 30,
      }}
      id="network-menu"
      anchorEl={networkAnchorEl}
      open={isNetworkMenuOpen}
      onClose={handleMenuClose}
    >
      {networkOptions.map((option, index) => (
        <MenuItem
          key={option.name}
          selected={index === networkID}
          sx={{
            '.menu-icon': {
              mr: 1,
            },
          }}
          onClick={(event) => handleNetworkMenuItemClick(event, index)}
        >
          <CustomIcon
            sx={{ svg: { width: 24, height: 24, filter: 'grayscale(100%)' } }}
            icon={option.icon}
            className="menu-icon"
          />
          <Typography component="span">{option.name}</Typography>
        </MenuItem>
      ))}
    </Menu>
  );

  const clientMenu = (
    <Menu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 100,
      }}
      id="client-menu"
      anchorEl={clientAnchorEl}
      open={isClientMenuOpen}
      onClose={handleMenuClose}
    >
      {clientOptions.map((option, index) => (
        <MenuItem
          key={option.name}
          selected={index === clientSelectedIndex}
          onClick={(event) => handleClientMenuItemClick(event, index)}
          sx={{ flexDirection: 'column', alignItems: 'flex-start' }}
        >
          <Typography>{option.name}</Typography>
          {option.endpoint && (
            <Typography
              sx={{
                color: 'light.main',
              }}
            >
              {`Simulation URL: ${option.endpoint}`}
            </Typography>
          )}
        </MenuItem>
      ))}
    </Menu>
  );

  const teamMenu = (
    <Menu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 0,
      }}
      id="team-menu"
      anchorEl={teamAnchorEl}
      open={isTeamMenuOpen}
      onClose={handleMenuClose}
    >
      {teams.map((option, index) => (
        option.name && (
        <MenuItem
          key={option.id}
          selected={option.id === team?.id}
          onClick={(event) => handleTeamMenuItemClick(event, index)}
          sx={{ flexDirection: 'column', alignItems: 'flex-start' }}
        >
          <Typography>{option.name}</Typography>
        </MenuItem>
        )
      ))}
    </Menu>
  );

  const menuId = 'primary-search-account-menu';
  const profileMenu = (
    <Menu
      anchorEl={profileAnchorEl}
      anchorOrigin={{
        vertical: 60,
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isProfileMenuOpen}
      onClose={handleMenuClose}
    >
      <ConfirmationPromptDialog
        confirmButtonText="Leave"
        cancelButtonText="Stay here"
        isShow={isShowLogoutPrompt}
        title="Leave page"
        onConfirm={handleLogoutConfirm}
        onClose={handleLogoutReject}
      >
        Are you sure?
      </ConfirmationPromptDialog>
      {profileOptions.filter(({ adminOnly }) => isAdmin || !adminOnly).map((option) => (
        <Link
          key={option.name}
          component={RouterLink}
          to={option.to || ''}
          color="inherit"
          underline="none"
        >
          <MenuItem
            sx={{ '.menu-icon': { mr: 1 }, width: '100%' }}
            onClick={() => {
              handleMenuClose();
              if (option.action && option.action === ProfileOptionsAction.SingOut) {
                setIsShowLogoutPrompt(() => true);
              }
            }}
          >
            <CustomIcon icon={option.icon} className="menu-icon" />
            {option.name}
            {option.name === 'Notifications' && notifications.length > 0 && (
            <Badge
              sx={{
                ml: '8px',
                borderRadius: '4px',
                px: '3px',
                bgcolor: '#DE4469',
                fontSize: 10,
                lineHeight: '16px',
              }}
            >
              {notifications.length}
            </Badge>
            )}
          </MenuItem>
        </Link>
      ))}
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box
        component="header"
        sx={{
          pt: { xs: 0, md: 5 },
          backgroundColor: 'darkGrey.main',
          position: 'relative',
          zIndex: 1,
        }}
      >
        <Box
          className="toolbar"
          sx={{
            display: 'flex',
            pr: { xs: 2, lg: 5 },
            pl: { xs: 2, lg: 0 },
            '.toolbar': {
              '&__content': {
                display: 'flex',
                alignItems: 'center',
                flexGrow: 1,
                flexWrap: 'wrap',
                gap: 2,
              },
              '&__item': {
                display: 'flex',
                alignItems: 'center',
                '&.disabled': {
                  pointerEvents: 'none',
                  opacity: 0.3,
                },
              },
              '&__action-label': {
                color: 'light.main',
                display: 'none',
                [theme.breakpoints.up('md')]: {
                  display: 'block',
                },
              },
              '&__action-btn': {
                minWidth: 170,
                px: 1,
                py: 1,
                ml: 1,
                [theme.breakpoints.up('lg')]: {
                  px: 2,
                  py: 1,
                },
                '& span': {
                  pr: '4px',
                },
                '.network-chevron': {
                  transition: 'transform 0.2s',
                  transform: isNetworkMenuOpen
                    ? 'rotateZ(180deg)'
                    : 'rotateZ(0)',
                },
                '.client-chevron': {
                  transition: 'transform 0.2s',
                  transform: isClientMenuOpen
                    ? 'rotateZ(180deg)'
                    : 'rotateZ(0)',
                },
                '.team-chevron': {
                  transition: 'transform 0.2s',
                  transform: isTeamMenuOpen ? 'rotateZ(180deg)' : 'rotateZ(0)',
                },
              },
            },
            '.network-icon': {
              mr: 1,
            },
          }}
        >
          <Box className="toolbar__content">
            <Box
              className={`toolbar__item ${
                !isAuthed || isNetworkSelectDisabled ? 'disabled' : ''
              }`}
            >
              <Box sx={{ display: 'flex' }}>
                <CustomIcon icon="wi-fi" />
                <Typography className="toolbar__action-label">
                  Network:
                </Typography>
              </Box>
              <Button
                id="network-menu"
                className="toolbar__action-btn"
                onClick={handleNetworkMenuOpen}
              >
                <CustomIcon
                  sx={{ svg: { width: 24, height: 24, filter: 'grayscale(100%)' } }}
                  icon={networkOptions[networkID].icon}
                  className="network-icon"
                />
                <Typography component="span">
                  {networkOptions[networkID].name}
                </Typography>
                <CustomIcon icon="chevron-down" className="network-chevron" />
              </Button>
            </Box>

            <Box className={`toolbar__item ${!isAuthed ? 'disabled' : ''}`}>
              <Box sx={{ display: 'flex' }}>
                <CustomIcon icon="wi-fi" />
                <Typography className="toolbar__action-label">
                  Client:
                </Typography>
              </Box>
              <Button
                className="toolbar__action-btn"
                onClick={handleClientMenuOpen}
              >
                <Typography component="span">
                  {clientOptions[clientSelectedIndex].name}
                </Typography>
                <CustomIcon icon="chevron-down" className="client-chevron" />
              </Button>
            </Box>

            {isAuthed && (
              <Box className="toolbar__item">
                <Box sx={{ display: 'flex' }}>
                  <Typography className="toolbar__action-label">
                    Team:
                  </Typography>
                </Box>
                <Button
                  className="toolbar__action-btn"
                  onClick={handleTeamMenuOpen}
                >
                  <Typography component="span">
                    {team ? team.name : '-/-'}
                  </Typography>
                  <CustomIcon icon="chevron-down" className="team-chevron" />
                </Button>
              </Box>
            )}
          </Box>

          {isAuthed && (
            <Box
              sx={{
                display: 'none',
                [theme.breakpoints.up(1050)]: {
                  display: 'flex',
                },
                maxHeight: 48,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                id="profile-menu"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <Typography>{`${profile.firstName} ${profile.lastName}`}</Typography>
                <Box sx={{ position: 'relative', display: 'flex' }}>
                  <Box
                    component="img"
                    src={profile?.photo?.url || defaultUserAvatar}
                    alt="user avatar"
                    sx={{
                      borderRadius: '50%', ml: '24px', width: '48px', height: '48px',
                    }}
                  />
                  {notifications.length > 0 && (
                  <Badge
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      borderRadius: '4px',
                      px: '3px',
                      bgcolor: '#DE4469',
                      fontSize: 10,
                      lineHeight: '16px',
                    }}
                  >
                    {notifications.length}
                  </Badge>
                  )}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      {networkMenu}
      {clientMenu}
      {teamMenu}
      {profileMenu}
    </Box>
  );
};
