import {
  Box, ChaosTable, CustomMultiReactSelect, DateTimePicker, Grid,
} from '@chaos/ui';
import { Loader } from '@chaos/ui/loader';
import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { capitalizeFirstLetter } from '@chaos/ui/utils/formatters';
import { tableHeader, formatResults } from './block-mapping-table';
import { useBlockMappingTableData } from './useBlockMappingTableData';

export const BlockMappingTool = () => {
  const ALL_CHAINS = [
    'arbitrum',
    'ethereum',
    'avalanche',
    'polygon',
    'optimism',
  ];

  const initialDate = (dayjs()).subtract(1, 'day');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [chains, setChains] = useState(ALL_CHAINS);

  const [datePickerValue, setDatePickerValue] = useState(initialDate);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const closeDatePicker = () => setDatePickerOpen(false);
  const toggleDatePicker = () => setDatePickerOpen(!datePickerOpen);

  const { blockMappingTableData, isLoading } = useBlockMappingTableData(
    datePickerValue.unix().toString(),
    chains,
  );

  return (
    <Box height="100%" overflow="auto">
      <Grid spacing={[1, 2]} paddingBottom={3} container>
        <Grid xs={3} md={3} lg={3} item>
          <CustomMultiReactSelect
            controlShouldRenderValue
            disabled={isLoading}
            label="Chains"
            placeholder="Select Chains"
            options={ALL_CHAINS.map((chain) => ({
              value: capitalizeFirstLetter(chain),
              label: capitalizeFirstLetter(chain),
              icon: chain,
            }))}
            onChange={(e) => {
              setChains(e.map((a) => a?.value.toLowerCase()));
            }}
          />
        </Grid>
        <Grid xs={3} md={3} lg={3} item>
          <DateTimePicker
            label="Date and Time"
            value={datePickerValue}
            open={datePickerOpen}
            disabled={isLoading}
            onAccept={(newDate: Dayjs | null) => {
              if (newDate === null) return;
              setDatePickerValue(newDate);
              closeDatePicker();
            }}
            onClose={closeDatePicker}
            slotProps={{
              textField: {
                onClick: toggleDatePicker,
              },
              popper: {
                placement: 'bottom-end',
              },
            }}
            maxDate={dayjs()}
          />
        </Grid>
      </Grid>
      {(isLoading) ? (
        <Box
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Loader />
        </Box>
      ) : (
        <ChaosTable
          title="Block Heights"
          headers={tableHeader}
          data={formatResults(
            blockMappingTableData,
          )}
          pageSize={15}
          isFullHeight
        />
      )}
    </Box>
  );
};
