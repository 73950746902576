import { useCallback, useState } from 'react';
import { BlockchainSimulationResult } from '@chaos/types';
import { useBlockchainSimulationResults } from 'src/hooks';
import { SimulationsResultsV2HistoryTable } from 'src/components/simulations-results-v2-history-table';
import { CircularProgress } from '@mui/material';
import { EmptyState } from '../empty-state';

const PAGES_TO_LOAD = 5;
const PAGE_SIZE = 20;

type Props = {
  parentResultId?: string;
  omitNameColumn?: boolean;
  showCusomParameterConfigurationHeaders?: boolean;
  isFullHeight?: boolean;
  showLoading?: boolean;
  showEmptyState?: boolean;
  title?: string;
};
const PaginatedSimulationResults = ({
  parentResultId,
  omitNameColumn,
  showCusomParameterConfigurationHeaders,
  isFullHeight,
  showLoading,
  showEmptyState,
  title,
}: Props) => {
  const [last, setLast] = useState<BlockchainSimulationResult>();
  const [sortBy, setSortBy] = useState('created');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [nameFilter, setNameFilter] = useState<string>();
  const [createdAtFilter, setCreatedAtFilter] = useState<[number, number]>();
  const { isLoading, results: blockchainSimulationResults } = useBlockchainSimulationResults(
    nameFilter ? 'simulationSnapshot.lowercaseName' : sortBy,
    sortDirection,
    last,
    PAGES_TO_LOAD * PAGE_SIZE,
    nameFilter,
    createdAtFilter,
    parentResultId,
  );
  const onPageChange = useCallback((page: number) => {
    if (page % PAGES_TO_LOAD === 0 && page * PAGE_SIZE === blockchainSimulationResults.length) {
      setLast(blockchainSimulationResults[blockchainSimulationResults.length - 1]);
    }
  }, [blockchainSimulationResults]);
  const onSortChange = useCallback((by: string, dir: -1 | 1) => {
    setLast(undefined);
    setSortBy(by);
    setSortDirection(dir === -1 ? 'desc' : 'asc');
  }, []);
  const onFilter = useCallback((newNameFilter?: string, newCreatedAtFilter?: [number, number]) => {
    setLast(undefined);
    setNameFilter(newNameFilter);
    setCreatedAtFilter(newCreatedAtFilter);
  }, []);

  const isFiltered = nameFilter || createdAtFilter;
  const resultsEmpty = !blockchainSimulationResults.length;

  return (
    <>
      {resultsEmpty && !isLoading && !isFiltered && showEmptyState && <EmptyState type="simulation results" />}
      {resultsEmpty && isLoading && !isFiltered && showLoading && <CircularProgress />}
      {(!resultsEmpty || isFiltered) && (
        <SimulationsResultsV2HistoryTable
          simulationsResults={blockchainSimulationResults}
          showCusomParameterConfigurationHeaders={showCusomParameterConfigurationHeaders}
          omitNameColumn={omitNameColumn}
          isFullHeight={isFullHeight}
          onPageChange={onPageChange}
          onSortChange={onSortChange}
          onFilter={onFilter}
          isLoading={isLoading}
          title={title}
        />
      )}
    </>
  );
};

export default PaginatedSimulationResults;
