import { FC } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  Box, Link, Paper, Typography,
} from '@chaos/ui';
import { IconButton } from '@chaos/ui/icon-button';
import { SimulationBlock } from '@chaos/types';
import { RouteParams, RoutePath } from 'src/config/routes';
import { LineSeparator, OverviewItem } from './building-components';
import { formatAmount } from '../charts/utils';
import { CustomIcon } from '../custom-icon';

type BlockDetailsProps = {
  block: SimulationBlock,
};

export const BlockDetails: FC<BlockDetailsProps> = ({ block }) => {
  const navigate = useNavigate();
  const { simulationResultId } = useParams<{ simulationResultId: string }>();

  return (
    <Paper variant="card">
      <Box display="flex" flexDirection="column">
        <OverviewItem name="Block Height">
          <Typography overflow="hidden" textOverflow="ellipsis">
            {block.number}
          </Typography>
          <IconButton
            sx={{ p: 0 }}
            onClick={() => navigate(RoutePath.Simulations.BlockExplorer.BlockDetails
              .replace(RouteParams.SimulationResultId, simulationResultId!)
              .replace(RouteParams.BlockNumber, (block.number - 1).toString()))}
          >
            <CustomIcon icon="chevron-left" />
          </IconButton>
          <IconButton
            sx={{ p: 0 }}
            onClick={() => navigate(RoutePath.Simulations.BlockExplorer.BlockDetails
              .replace(RouteParams.SimulationResultId, simulationResultId!)
              .replace(RouteParams.BlockNumber, (block.number + 1).toString()))}
          >
            <CustomIcon icon="chevron-right" />
          </IconButton>
        </OverviewItem>
        <OverviewItem name="Timestamp">
          <Typography>{dayjs(block.timestamp * 1000).format('MM/DD/YYYY hh:mm')}</Typography>
        </OverviewItem>
        <OverviewItem name="Transactions">
          <Link
            component={RouterLink}
            to={RoutePath.Simulations.BlockExplorer.BlockTransactions
              .replace(RouteParams.SimulationResultId, simulationResultId!)
              .replace(RouteParams.BlockNumber, block.number.toString())}
            underline="hover"
          >
            <Typography className="gradient-link" fontWeight={400}>
              {block.transactionsCount}
              {' '}
              transactions
            </Typography>
          </Link>
          <Typography>in this block</Typography>
        </OverviewItem>
        <LineSeparator />
        <OverviewItem name="Gas Used">
          {`${formatAmount(block.gasUsed)} (${formatAmount((block.gasUsed / block.gasLimit) * 100)}%)`}
        </OverviewItem>
        <OverviewItem name="Gas Limit">
          {formatAmount(block.gasLimit)}
        </OverviewItem>
        <OverviewItem name="Base Fee Per Gas">
          {`${formatAmount(block.baseFeePerGas, { maximumFractionDigits: 20 })} Ether`}
        </OverviewItem>
      </Box>
    </Paper>
  );
};
