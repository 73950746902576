import React from 'react';
import { Box } from '@chaos/ui';
import { IconButton } from '@chaos/ui/icon-button';
import logo from '../../assets/img/logo.svg';
import { CustomIcon } from '../custom-icon';

interface AppMenuMobileHeaderProps {
  isMenuShow: boolean
  toggleMenuHandler: () => void
}

const AppMenuMobileHeader:React.FC<AppMenuMobileHeaderProps> = ({
  isMenuShow,
  toggleMenuHandler,
}) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      columnGap: '24px',
    }}
  >
    <Box
      sx={{
        padding: '20px 0',
        lineHeight: 0,
      }}
    >
      <img src={logo} width="138" height="24" alt="Chaos Labs" />
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <IconButton size="large">
        <CustomIcon icon="search" />
      </IconButton>
      <IconButton
        onClick={toggleMenuHandler}
        size="large"
      >
        <CustomIcon icon={isMenuShow ? 'close' : 'burger-menu'} />
      </IconButton>
    </Box>
  </Box>
);
export default AppMenuMobileHeader;
