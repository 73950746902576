import {
  AggregationMethods,
  ScriptResource,
  ObservationAggregations,
  SimulationPermutationInfo,
  SimulationPermutationObservationResult,
  SimulationStatistics,
  AggregationStatistics,
  AggregationName,
  ExprimentalStatistics,
} from '@chaos/types';
import { ScatterChartRowProps } from '@chaos/ui/charts';
import { ScatterChartData } from 'src/components/charts';
import { confidenceIntervalToString, parseIntervalString } from 'src/pages/simulation-experiment-results-page/utils';

export const aggregateDataOptions: Array<{ label: string, value: string }> = [
  {
    value: AggregationMethods.Last,
    label: 'Last',
  },
  {
    value: AggregationMethods.Average,
    label: 'Avg',
  },
  {
    value: AggregationMethods.Max,
    label: 'Max',
  },
  {
    value: AggregationMethods.Min,
    label: 'Min',
  },
  {
    value: AggregationMethods.Sum,
    label: 'Sum',
  },
];

type ArgValueData = {
  value: number,
  formattedValue?: string
  min?: number,
  max?: number,
  counter?: number,
  metadata: string
};

export function getValue(
  observationAggregations: ObservationAggregations | undefined,
  aggregationMethod: string,
): number {
  if (observationAggregations === undefined) {
    return 0;
  }

  switch (aggregationMethod) {
    case AggregationMethods.Average: {
      return observationAggregations.average;
    }
    case AggregationMethods.Last: {
      return observationAggregations.last;
    }
    case AggregationMethods.Sum: {
      return observationAggregations.sum;
    }
    case AggregationMethods.Max: {
      return observationAggregations.max;
    }
    case AggregationMethods.Min: {
      return observationAggregations.min;
    }
    default: {
      throw new Error(`Unsupprted aggregation method - ${aggregationMethod}`);
    }
  }
}
export function getStatisticsValue(
  aggregationStatistics: Record<AggregationName, AggregationStatistics>,
  aggregationMethod: string,
): string {
  switch (aggregationMethod) {
    case AggregationMethods.Average: {
      return aggregationStatistics.average.confidence_interval;
    }
    case AggregationMethods.Last: {
      return aggregationStatistics.last.confidence_interval;
    }
    case AggregationMethods.Sum: {
      return aggregationStatistics.sum.confidence_interval;
    }
    case AggregationMethods.Max: {
      return aggregationStatistics.max.confidence_interval;
    }
    case AggregationMethods.Min: {
      return aggregationStatistics.min.confidence_interval;
    }
    default: {
      throw new Error(`Unsupprted aggregation method - ${aggregationMethod}`);
    }
  }
}

const mapValuesToScatterChartData = (
  data: Record<number, ArgValueData>,
): ScatterChartData[] => Object.entries(data).map(([key, value]) => ({
  x: Number(key),
  y: (value.value / (value.counter || 1)),
  yFormattedValue: value.formattedValue,
  label: '',
  metadata: value.metadata,
  interval: (value.min !== undefined && value.max !== undefined)
    ? {
      min: value.min,
      max: value.max,
    } : undefined,
}));

const permutationValueToObservationMappingFn = (
  permutationsMap: Record<string, SimulationPermutationInfo>,
  observationInfo: SimulationPermutationObservationResult[],
  observation: ScriptResource,
  aggregationMethod: string,
  argName: string,
  experimentStatistics?: ExprimentalStatistics[],
): Record<number, ArgValueData> => {
  if (experimentStatistics) {
    const argValueStatisticData = experimentStatistics.reduce((
      acc,
      { metricStatistics, permutationInfo },
    ) => {
      if (!permutationInfo) return acc;

      const permutation = permutationsMap[permutationInfo.id];
      if (!permutation) return acc;

      const permutationArg = permutation.args.find((perArgs) => perArgs.argName === argName);
      if (!permutationArg) return acc;

      const observationStatistics = metricStatistics.find(
        (statistics) => statistics.id === observation.id,
      );
      if (!observationStatistics) return acc;

      const confidenceInterval = getStatisticsValue(
        observationStatistics.aggregationStatistics,
        aggregationMethod,
      );
      const {
        mean,
        min,
        max,
      } = parseIntervalString(confidenceInterval);

      return {
        ...acc,
        [permutationArg.argValue]: {
          value: mean,
          min,
          max,
          metadata: permutation.id,
          formattedValue: confidenceIntervalToString(confidenceInterval),
        },
      };
    }, {} as Record<number, ArgValueData>);

    return argValueStatisticData;
  }

  const argValueData = observationInfo.reduce((acc, entry) => {
    const permutation = permutationsMap[entry.permutationId];
    if (!permutation) return acc;

    const permutationArg = permutation.args.find((perArgs) => perArgs.argName === argName);
    if (!permutationArg) return acc;

    const value = getValue(entry?.aggregations, aggregationMethod);
    const { argValue } = permutationArg;
    const currentValue = acc[argValue];

    if (!currentValue) {
      return {
        ...acc,
        [argValue]: {
          counter: 1,
          value,
          metadata: permutation.resultIds[0],
        },
      };
    }

    return {
      ...acc,
      [argValue]: {
        counter: (currentValue.counter || 0) + 1,
        value: currentValue.value + value,
        metadata: currentValue.metadata,
      },
    };
  }, {} as Record<number, ArgValueData>);

  return argValueData;
};
export function parsePermutationInfo(
  permutationInfo: SimulationPermutationInfo[],
  observationInfo: SimulationPermutationObservationResult[],
  observation: ScriptResource,
  argName: string,
  aggregationMethod: string,
  simulationStatistics?: SimulationStatistics,
): ScatterChartRowProps {
  const permutationsMap = permutationInfo.reduce((acc, perm) => ({
    ...acc, [perm.id]: perm,
  }), {} as Record<string, SimulationPermutationInfo>);

  const relevantStatistics = simulationStatistics?.data;
  const relevantObservationInfo = observationInfo.filter((entry) => (entry.id
    ? entry.id === observation.id
    : entry.name === observation.name));

  const argValueData = permutationValueToObservationMappingFn(
    permutationsMap,
    relevantObservationInfo,
    observation,
    aggregationMethod,
    argName,
    relevantStatistics,
  );

  const data: ScatterChartData[] = mapValuesToScatterChartData(argValueData);
  const yAxisLabel = observation.valueLabel
   || '';

  return {
    title: observation.name,
    description: observation.description,
    data,
    xAxis: { label: argName },
    yAxis: { label: yAxisLabel },
    isBoxPlot: !!relevantStatistics,
  };
}
