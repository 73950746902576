import { useCallback, useEffect, useState } from 'react';

export const useKeyPress = (targetKeys: string[], enabled?: boolean) => {
  const [keyPressed, setKeyPressed] = useState(false);

  const downHandler = useCallback(({ key }: KeyboardEvent) => {
    if (targetKeys.includes(key)) setKeyPressed(true);
  }, [targetKeys]);

  const upHandler = useCallback(({ key }: KeyboardEvent) => {
    if (targetKeys.includes(key)) setKeyPressed(false);
  }, [targetKeys]);

  useEffect(() => {
    if (enabled) {
      window.addEventListener('keydown', downHandler);
      window.addEventListener('keyup', upHandler);

      return () => {
        window.removeEventListener('keydown', downHandler);
        window.removeEventListener('keyup', upHandler);
      };
    }

    return undefined;
  }, [enabled, downHandler, upHandler]);

  return keyPressed;
};
