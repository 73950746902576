import { Box, Button } from '@chaos/ui';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/config/routes';
import ModelRunsTable from './model-runs-table';

const ModelRunsPage = () => {
  const navigate = useNavigate();
  return (
    <Box display="flex" flexDirection="column" p={3}>
      <Button color="primary" sx={{ ml: 'auto', mb: 2 }} onClick={() => navigate(RoutePath.ParamRecommendationsTool.ModelTrigger)}>New Recommendation</Button>
      <ModelRunsTable />
    </Box>
  );
};

export default ModelRunsPage;
