import * as Yup from 'yup';
import { Dispatch, SetStateAction } from 'react';
import { useParams } from 'react-router-dom';
import {
  Formik, Form, FormikHelpers, FieldArray,
} from 'formik';
import { useFirestore } from 'react-redux-firebase';
import { Box } from '@chaos/ui/box';
import { Button } from '@chaos/ui/button';
import { Typography } from '@chaos/ui/typography';
import { FileValue } from '@chaos/types';
import { COLLECTIONS } from '@chaos/utils';
import { required } from 'src/utils/validators';
import { InputField, ReactSelectField } from 'src/components/fields';
import { TeamMemberData } from 'src/pages/team-edit-page';
import { useTheme } from '@chaos/ui';
import { TeamMember } from '../team-member';
import { FirebaseUploader } from '../firebase-uploader';
import { useRandomAvatar } from '../../hooks';

export interface TeamFormValues {
  id: string
  name: string
  thumbnail?: FileValue
  protocolBlockchain: string
  protocolWebsite?: string
  chaoslabsId?: string
  amazonId?: string
  telegram?: string
  discord?: string
  members: {
    email: string
    role: string
    firstName: string
    lastName: string
    uuid?: string
  }[]
}

const validationSchema = Yup.object().shape({
  name: required,
  protocolBlockchain: required,
  protocolWebsite: Yup.string().url(),
  chaoslabsId: Yup.string(),
  amazonId: Yup.string(),
  telegram: Yup.string(),
  discord: Yup.string(),
  members: Yup.array().of(
    Yup.object().shape({
      email: Yup.string().email().required(),
      role: required,
    }),
  ),
});

export const initialValues: TeamFormValues = {
  id: '',
  thumbnail: undefined,
  name: '',
  protocolBlockchain: '',
  protocolWebsite: '',
  chaoslabsId: '',
  amazonId: '',
  telegram: '',
  discord: '',
  members: [{
    email: '', role: '', firstName: '', lastName: '',
  }],
};

interface TeamFormProps {
  setOpenModal: Dispatch<SetStateAction<string | null>>
  openRemoveModal: (member: TeamMemberData) => void
  formValues?: TeamFormValues
  onSubmit: (
    values: TeamFormValues,
    formikHelpers: FormikHelpers<TeamFormValues>,
  ) => void | Promise<any>
}

export const TeamForm = ({
  onSubmit,
  formValues,
  setOpenModal,
  openRemoveModal,
}: TeamFormProps): JSX.Element => {
  const firestore = useFirestore();
  const { randomAvatar } = useRandomAvatar(formValues?.id);
  const theme = useTheme();
  const { teamId } = useParams<{ teamId: string }>();
  const handleOpenModal = (modalName: string) => setOpenModal(modalName);
  const fieldMaxWidth = 344;

  return (
    <Formik
      initialValues={formValues || initialValues}
      enableReinitialize
      validateOnBlur
      validateOnChange
      validateOnMount
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, isValid, setFieldValue }) => (
        <Form autoComplete="off">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                alignItems: 'center',
                gap: { xs: 3, md: 0 },
                mb: 5,
              }}
            >
              <Box sx={{
                display: 'flex',
                [theme.breakpoints.down('md')]: {
                  alignSelf: 'center',
                },
              }}
              >
                {formValues && (
                  <FirebaseUploader
                    onDeleteSuccess={() => {
                      setFieldValue('thumbnail', undefined);
                      void firestore
                        .collection(COLLECTIONS.TEAMS)
                        .doc(teamId)
                        .update({
                          thumbnail: firestore.FieldValue.delete(),
                        });
                    }}
                    onUploadSuccess={(file) => {
                      setFieldValue('thumbnail', file);
                      void firestore
                        .collection(COLLECTIONS.TEAMS)
                        .doc(teamId)
                        .update({
                          thumbnail: file,
                        });
                    }}
                    category="img-teams"
                    value={formValues?.thumbnail}
                    fallbackPreviewPath={randomAvatar}
                  />
                )}
              </Box>
              <Box
                sx={{
                  '> :not(:last-of-type)': { mb: 5 },
                  maxWidth: { xs: 'inherit', md: fieldMaxWidth },
                  width: '100%',
                }}
              >
                <Box>
                  <InputField
                    label="Team Name"
                    name="name"
                    placeholder="Enter team name"
                    value="Team 1"
                    fullWidth
                  />
                </Box>
                <Box>
                  <ReactSelectField
                    name="protocolBlockchain"
                    label="Protocol Blockchain"
                    placeholder="Select protocol blockchain"
                    options={[
                      { label: 'Ethereum', value: 'Ethereum' },
                      { label: 'Cardano', value: 'Cardano' },
                      { label: 'Avalanche', value: 'Avalanche' },
                      { label: 'Polygon', value: 'Polygon' },
                      { label: 'Terra', value: 'Terra' },
                      { label: 'NEAR', value: 'NEAR' },
                      { label: 'Moonriver', value: 'Moonriver' },
                    ]}
                  />
                </Box>
                <Box>
                  <InputField
                    label="Protocol Website (Optional)"
                    name="protocolWebsite"
                    placeholder="Enter protocol website"
                    fullWidth
                  />
                </Box>
              </Box>
            </Box>

            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              py: '36px',
              [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                alignItems: 'start',
              },
            }}
            >
              <Typography
                component="h2"
                variant="h2"
                sx={{
                  flex: 1,
                  [theme.breakpoints.down('sm')]: {
                    mb: 3,
                  },
                }}
              >
                Members
              </Typography>
              <Button
                color="secondary"
                onClick={() => handleOpenModal('invite')}
                sx={{
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    alignSelf: 'center',
                  },
                }}
              >
                Invite Members
              </Button>
            </Box>
            <Box sx={{ mb: 5 }}>
              <FieldArray
                name="members"
                render={() => (
                  // eslint-disable-next-line
                  <>
                    {formValues?.members.map((member, index) => (
                      <TeamMember
                        index={index}
                        member={member}
                        key={`${member.email} - ${index}`}
                        openRemoveModal={() => openRemoveModal(member)}
                      />
                    ))}
                  </>
                )}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                py: 5,
                backgroundColor: 'background.default',
              }}
            >
              <Button
                type="submit"
                color="primary"
                sx={{
                  ml: { xs: 0, md: 'auto' },
                }}
                disabled={isSubmitting || !isValid}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
