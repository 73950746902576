import { FC } from 'react';
import { IconButton } from '@chaos/ui/icon-button';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

type CopyButtonProps = { text: string, onCopy: (message: string) => void };

export const CopyButton: FC<CopyButtonProps> = ({ text, onCopy }) => (
  <IconButton
    sx={{ p: 0, fontSize: 12 }}
    onClick={(e) => {
      e.stopPropagation();
      void navigator.clipboard.writeText(text).catch((error) => {
        console.error(`Failed copying ${text} to clipboard:`, error);
      });
      onCopy(`Copied "${text}" to clipboard`);
    }}
  >
    <ContentCopyIcon fontSize="small" />
  </IconButton>
);
