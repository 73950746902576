import { useEffect, useMemo, useState } from 'react';
import {
  catchError, combineLatestWith, mergeMap, of, Subscription, take,
} from 'rxjs';
import {
  collection, doc, getDoc,
} from 'firebase/firestore';
import { docData } from 'rxfire/firestore';
import { COLLECTIONS } from '@chaos/utils';
import {
  BlockchainSimulation, BlockchainSimulationResult, ScriptResource, SimulationStatistics,
} from '@chaos/types';
import { firestore } from 'src/services/firebase';
import { useCurrentTeam } from './useCurrentTeam';
import { useSimulationTeam } from './useSimulationTeam';

export const useBlockchainSimulationResult = (id: string, isLean?: boolean): {
  isLoading: boolean,
  result?: BlockchainSimulationResult
  simulation?: BlockchainSimulation
  simulationStatistics?: SimulationStatistics
  assertions?: ScriptResource[]
  agents?: ScriptResource[]
  scenarios?: ScriptResource[]
  observers?: ScriptResource[]
} => {
  const team = useCurrentTeam();
  const [simResult, setSimResult] = useState<BlockchainSimulationResult>();
  const [simulation, setSimulation] = useState<BlockchainSimulation>();

  const [isLoadingStatistics, setIsLoadingStatistics] = useState<boolean>(true);
  const [statistics, setStatistics] = useState<SimulationStatistics | undefined>();

  const [assertions, setAssertions] = useState<ScriptResource[]>();
  const [agents, setAgents] = useState<ScriptResource[]>();
  const [scenarios, setScenarios] = useState<ScriptResource[]>();
  const [observers, setObservers] = useState<ScriptResource[]>();

  useSimulationTeam(simulation);

  useEffect(() => {
    setStatistics(undefined);
    const subs: Subscription[] = [];
    const simResultsCol = collection(firestore, COLLECTIONS.BLOCKCHAIN_SIMULATION_RESULTS);

    const resultObs = docData(doc(simResultsCol, id), { idField: 'id' });
    subs.push(resultObs.subscribe((r) => setSimResult(r as BlockchainSimulationResult)));

    const statisticsProcessingCollection = collection(
      firestore,
      COLLECTIONS.SIMULATION_STATISTICS_PROCESSING,
    );

    const statisticsObs = resultObs.pipe(
      take(1),
      mergeMap((r) => docData(doc(
        statisticsProcessingCollection,
        (r as BlockchainSimulationResult).dataProcessingResultId,
      ))),
    );

    subs.push(statisticsObs
      .pipe(catchError(() => of(undefined)))
      .subscribe((s) => {
        setIsLoadingStatistics(false);
        setStatistics(s as SimulationStatistics);
      }));

    const simObs = resultObs.pipe(
      take(1),
      mergeMap((r) => docData(doc(collection(
        firestore,
        COLLECTIONS.BLOCKCHAIN_SIMULATIONS,
      ), (r as BlockchainSimulationResult).simulationId), { idField: 'id' })),
    );
    subs.push(simObs.subscribe((s) => setSimulation(s as BlockchainSimulation)));

    if (!isLean) {
      const assertionsObs = simObs.pipe(
        mergeMap((s) => Promise.all((s as BlockchainSimulation).assertions.map(async (a) => {
          const d = await getDoc(doc(collection(
            firestore,
            COLLECTIONS.BLOCKCHAIN_SIMULATION_ASSERTIONS,
          ), a.refId));
          return {
            ...d.data(),
            instanceId: a.instanceId ?? '',
            id: a?.refId,
          };
        }))),
      );
      subs.push(assertionsObs.subscribe((a) => setAssertions(a as ScriptResource[])));

      const agentsObs = simObs.pipe(mergeMap((s) => Promise.all(
        (s as BlockchainSimulation).agents.map(async (a) => {
          const d = await getDoc(doc(collection(
            firestore,
            COLLECTIONS.BLOCKCHAIN_SIMULATION_AGENTS,
          ), a.refId));
          return {
            ...d.data(),
            instanceId: a.instanceId ?? '',
            id: a?.refId,
          };
        }),
      )));
      subs.push(agentsObs.subscribe((a) => setAgents(a as ScriptResource[])));

      const scenariosObs = simObs.pipe(mergeMap((s) => Promise.all([
        ...(s as BlockchainSimulation).scenarios.runtime,
        ...(s as BlockchainSimulation).scenarios.setup,
      ].map(async (a) => {
        const d = await getDoc(doc(collection(
          firestore,
          COLLECTIONS.BLOCKCHAIN_SIMULATION_SCENARIOS,
        ), a.refId));
        return {
          ...d.data(),
          instanceId: a.instanceId ?? '',
          id: a?.refId,
        };
      }))));
      subs.push(scenariosObs.subscribe((a) => setScenarios(a as ScriptResource[])));

      const observersObs = simObs.pipe(
        combineLatestWith(resultObs),
        mergeMap(([s]) => Promise.all((s as BlockchainSimulation).observers.map(async (o) => {
          const d = await getDoc(doc(collection(
            firestore,
            COLLECTIONS.BLOCKCHAIN_SIMULATION_OBSERVERS,
          ), o.refId));
          return {
            ...d.data(),
            displayOrder: o.displayOrder,
            instanceId: o.instanceId ?? '',
            id: o?.refId,
          };
        }))),
      );
      subs.push(observersObs.subscribe((a) => setObservers(a as ScriptResource[])));
    }

    return () => subs.forEach((sub) => sub.unsubscribe());
  }, [id, team?.id, isLean]);
  const result = useMemo(() => ({
    isLoading: !simResult || !simulation || isLoadingStatistics,
    result: simResult,
    simulation,
    simulationStatistics: statistics,
    assertions,
    scenarios,
    agents,
    observers,
  }), [
    agents,
    assertions,
    isLoadingStatistics,
    observers,
    simResult,
    scenarios,
    simulation,
    statistics,
  ]);

  return result;
};
