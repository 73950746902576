import { FC } from 'react';

import { Box } from '@chaos/ui/box';
import { Button } from '@chaos/ui/button';
import { Typography } from '@chaos/ui/typography';

interface ConfirmUploadModalProps {
  onClose?: () => void
  confirmContractUpdate: () => void
  contractNotFoundError?: boolean
}

export const ConfirmUploadModal: FC<ConfirmUploadModalProps> = (props) => {
  const { onClose, confirmContractUpdate, contractNotFoundError } = props;

  return (
    <>
      <Box component="header">
        <Box className="modal-header" sx={{ py: '36px' }}>
          <Typography variant="h2" component="h2">
            Attention!
          </Typography>
        </Box>
      </Box>
      <Typography component="p" className="modal-body">
        {contractNotFoundError
          ? 'Fetching deployed contract failed, please verify input address.'
          : `The name that you have chosen for the uploaded contract is the same as
        the one already in the system. If you click confirm, the contract will
        be overwritten with a new one.`}
      </Typography>
      <Box component="footer">
        <Box className="modal-footer" sx={{ py: 5 }}>
          <Button color="secondary" onClick={onClose}>
            Back
          </Button>
          {!contractNotFoundError && (
            <Button
              sx={{ ml: '24px' }}
              color="primary"
              onClick={confirmContractUpdate}
            >
              Confirm
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};
