import { useNavigate } from 'react-router-dom';
import { EditPageLayout } from 'src/components/layouts';
import { RoutePath } from 'src/config/routes';
import { useCurrentTeam } from 'src/hooks';
import { PageTracker } from 'src/components/page-tracker';
import { ScriptForm } from 'src/components/script-form';
import { createBlockchainScriptFn } from 'src/components/dashboard/network';
import { ConfirmationLeavingDialog, getDisablePromptPushState } from 'src/components/confirmation-leaving-dialog';
import { useBlockchainObservers } from 'src/hooks/useBlockchainObservers';

export const ObserverCreatePage = PageTracker((): JSX.Element => {
  const navigate = useNavigate();
  const team = useCurrentTeam();
  const { blockchainObservers } = useBlockchainObservers();

  return (
    <EditPageLayout
      rootRoute={RoutePath.ObserverConfiguration.Home}
      rootTitle="Observers"
      pageTitle="Create New Observer"
      metaTitle="Create Observer"
    >
      <ConfirmationLeavingDialog message="Changes you made may not be saved." />
      <ScriptForm
        type="Observer"
        allEntities={blockchainObservers}
        onSubmit={async (observer) => {
          await createBlockchainScriptFn(observer, 'Observer', team?.authKey);
          navigate(RoutePath.ObserverConfiguration.Home, { state: getDisablePromptPushState() });
        }}
      />
    </EditPageLayout>
  );
});
