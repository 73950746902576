import { FC, useState } from 'react';
import { Link as RouterLink, useNavigate, useNavigationType } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import { SxProps } from '@mui/material';
import { usePageTitle } from '../hooks/use-page-title';
import { IconButton } from '../icon-button';
import { CopyButton } from '../copy-button';
import { CustomIcon } from '../custom-icon';
import { Alert } from '../alert';
import { Box } from '../box';
import { Breadcrumbs } from '../breadcrumbs';
import { Button } from '../button';
import { Link } from '../link';
import { Typography } from '../typography';
import { Theme } from '../theme';
import { ChaosMenu, MenuItemType } from '../chaos-menu';
import { CryptoIcon } from '../crypto-icon';

export type Breadcrumb = { title: string | React.ReactNode, href: string, icon?: string };

export type PageHeaderProps = {
  pageTitle: string | React.ReactNode,
  pageSubtitle?: string | React.ReactNode,
  breadcrumbTitle?: string | React.ReactNode,
  breadCrumbTitleIcon?: string,
  breadcrumbTitleLink?: string,
  extraData?: {
    text: string,
    allowCopy?: boolean,
    link?: { text: string, href: string, isExternal?: boolean },
  },
  breadcrumbsLinks?: Breadcrumb[],
  icon?: string | React.ReactNode,
  buttons?: React.ComponentProps<typeof Button>[],
  menuItems?: MenuItemType[],
  containerStyle?: SxProps<Theme>,
  suffixComponent?: React.ReactNode,
  backLink?: string,
  showBackButton?: boolean,
};

export const PageHeader: FC<PageHeaderProps> = ({
  pageTitle,
  pageSubtitle,
  breadcrumbTitle,
  breadCrumbTitleIcon,
  breadcrumbTitleLink,
  breadcrumbsLinks,
  extraData,
  icon,
  buttons,
  menuItems,
  containerStyle,
  suffixComponent,
  backLink,
  showBackButton = true,
}) => {
  const type = useNavigationType();
  usePageTitle(typeof pageTitle === 'string' ? pageTitle : '');
  const navigate = useNavigate();
  const [snackbarText, setSnackbarText] = useState('');

  return (
    <Box marginY={5.5} width="100%" sx={containerStyle}>
      {!!breadcrumbsLinks?.length && (
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<CustomIcon icon="breadcrumbs-arrow" />}
        sx={{ mb: 2 }}
      >
        {breadcrumbsLinks.map(({ title, href, icon: breadCrumbIcon }) => (
          <Box display="flex" alignItems="center" key={breadCrumbIcon || title?.toString()}>
            {breadCrumbIcon && (
            <CryptoIcon
              icon={breadCrumbIcon.toLowerCase()}
              sx={{
                px: 1,
                img: { width: '24px', height: '24px' },
              }}
            />
            )}
            <Link
              component={RouterLink}
              key={title?.toString()}
              to={href}
              color="almostWhite.main"
              underline="hover"
            >
              {title}
            </Link>
          </Box>
        ))}
        <Box display="flex" alignItems="center" flexDirection="row">
          {breadCrumbTitleIcon && (
          <CryptoIcon
            icon={breadCrumbTitleIcon.toLowerCase()}
            sx={{ mx: 1 }}
          />
          )}
          {breadcrumbTitleLink ? (
            <Link
              key={breadcrumbTitleLink}
              component={RouterLink}
              to={breadcrumbTitleLink}
              color="almostWhite.main"
              underline="hover"
            >
              {breadcrumbTitle || pageTitle}
            </Link>
          )
            : <Typography color="white.main">{breadcrumbTitle || pageTitle}</Typography>}
        </Box>
      </Breadcrumbs>
      )}
      <Box display="flex" justifyContent="space-between">
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }} flex={1}>
          <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center" flexGrow={1} flexWrap="wrap" gap={1}>
              {showBackButton && (
                <IconButton
                  size="large"
                  onClick={() => {
                    if (backLink) {
                      navigate(backLink);
                      return;
                    }

                    if (breadcrumbsLinks && type === 'POP') {
                      navigate(breadcrumbsLinks[breadcrumbsLinks.length - 1].href);
                    } else {
                      navigate(-1);
                    }
                  }}
                  sx={{ margin: '-8px 0 -8px -8px' }}
                >
                  <CustomIcon icon="arrow-left" />
                </IconButton>
              )}
              {icon && (
                <Box
                  overflow="hidden"
                  display="flex"
                  alignItems="center"
                >
                  {typeof icon === 'string'
                    ? (
                      <CryptoIcon
                        icon={icon}
                        sx={{
                          mr: 2,
                          img: {
                            xs: { width: 48, height: 48 },
                            md: { width: 64, height: 64 },
                          },
                        }}
                      />
                    )
                    : <Box mr={2}>{icon}</Box>}
                </Box>
              )}
              {typeof pageTitle === 'string' ? (
                <Box display="flex" flexWrap="wrap" alignItems={typeof pageSubtitle === 'object' ? 'center' : 'baseline'} gap={1}>
                  <Typography variant="h1" component="h1" data-testid="page-title">{pageTitle}</Typography>
                  {typeof pageSubtitle === 'string' ? <Typography variant="caption">{pageSubtitle}</Typography> : pageSubtitle}
                </Box>
              ) : <Box flex={1}>{pageTitle}</Box>}
              {extraData && (
                <Box display="flex" alignSelf="end" sx={{ svg: { fontSize: '12px' } }} gap={0.5}>
                  <Typography variant="caption">{extraData.text}</Typography>
                  {extraData.link && (
                    <Link
                      href={extraData.link.href}
                      target={extraData.link.isExternal ? '_blank' : undefined}
                      color="inherit"
                      variant="caption"
                      underline="hover"
                    >
                      <Typography className="gradient-link" variant="caption">{extraData.link.text}</Typography>
                    </Link>
                  )}
                  {extraData.allowCopy && (
                    <CopyButton
                      text={extraData.text || extraData.link?.text || ''}
                      onCopy={setSnackbarText}
                    />
                  )}
                </Box>
              )}
              <Box ml="auto" display="flex" gap={1}>
                {buttons?.map((btnProps, i) => (
                  <Button key={i} color="primary" {...btnProps} />
                ))}
              </Box>
              {menuItems && (
                <ChaosMenu items={menuItems}>
                  <IconButton
                    sx={{ ml: 2 }}
                    size="medium"
                  >
                    <CustomIcon icon="more" />
                  </IconButton>
                </ChaosMenu>
              )}
            </Box>
          </Box>
        </Box>
        {suffixComponent && <Box ml="auto" display={{ xs: 'none', md: 'initial' }}>{suffixComponent}</Box>}
      </Box>
      {suffixComponent && <Box mt={[2, 3]} display={{ md: 'none' }}>{suffixComponent}</Box>}
      <Snackbar
        open={!!snackbarText}
        autoHideDuration={2000}
        onClose={() => setSnackbarText('')}
      >
        <Alert onClose={() => setSnackbarText('')} severity="info">
          {snackbarText}
        </Alert>
      </Snackbar>
    </Box>
  );
};
