import { useCallback, useState } from 'react';
import { useFirebase } from 'react-redux-firebase';
import { NavLink as RouterLink } from 'react-router-dom';
import { Link } from '@chaos/ui/link';
import { Typography } from '@chaos/ui/typography';
import { Box } from '@chaos/ui/box';
import { IconButton } from '@chaos/ui/icon-button';
import { MenuItem } from '@chaos/ui/menu-item';
import { MenuList } from '@chaos/ui/menu-list';
import { useAppSelector } from 'src/store';
import { CustomIcon } from '../custom-icon';
import { profileOptions, ProfileOptionsAction } from '../app-bar';
import { ConfirmationPromptDialog } from '../confirmation-prompt-dialog';

interface ProfileMenuMobileProps {
  open: boolean
  closeProfileMenu: () => void
  closeNavMenu: () => void
}

export const ProfileMenuMobile = ({
  open,
  closeProfileMenu,
  closeNavMenu,
}: ProfileMenuMobileProps): JSX.Element | null => {
  const firebase = useFirebase();
  const isAdmin = useAppSelector((state) => state.firebase.profile.role === 'admin');

  const [isShowLogoutPrompt, setIsShowLogoutPrompt] = useState(false);

  const handleLogoutConfirm = useCallback(() => {
    setIsShowLogoutPrompt(() => false);
    void firebase.logout();
  }, [firebase]);

  const handleLogoutReject = useCallback(() => {
    setIsShowLogoutPrompt(() => false);
  }, []);

  return open ? (
    <Box>
      <ConfirmationPromptDialog
        confirmButtonText="Leave"
        cancelButtonText="Stay here"
        isShow={isShowLogoutPrompt}
        title="Leave page"
        onConfirm={handleLogoutConfirm}
        onClose={handleLogoutReject}
      >
        Are you sure?
      </ConfirmationPromptDialog>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          bottom: 0,
          width: '100%',
          zIndex: 2000,
          backgroundColor: 'darkGrey.main',
          mt: '64px',
          p: '24px 0',
        }}
      >
        <Box
          onClick={closeProfileMenu}
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            mb: 3,
            px: 2,
          }}
        >
          <IconButton sx={{ ml: -1 }} size="large">
            <CustomIcon icon="chevron-left" />
          </IconButton>
          <Typography component="p" sx={{ ml: 1 }}>
            Back
          </Typography>
        </Box>

        <MenuList sx={{ py: 0 }}>
          {profileOptions.filter(({ adminOnly }) => isAdmin || !adminOnly).map(({
            icon, name, to, action,
          }) => (
            <MenuItem
              key={name}
              sx={{ px: 2, display: 'flex' }}
              onClick={() => {
                closeNavMenu();
                if (action && action === ProfileOptionsAction.SingOut) {
                  setIsShowLogoutPrompt(() => true);
                }
              }}
            >
              <CustomIcon icon={icon} className="menu-icon" />
              {to ? (
                <Link
                  component={RouterLink}
                  to={to}
                  color="inherit"
                  underline="none"
                  sx={{ pl: 1, flex: 1 }}
                >
                  {name}
                </Link>
              ) : (
                <Box sx={{ pl: 1 }}>{name}</Box>
              )}
            </MenuItem>
          ))}
        </MenuList>
      </Box>
    </Box>
  ) : null;
};
