import {
  FC, useState, useEffect, useCallback, useMemo,
} from 'react';
import { ClickAwayListener, Popper } from '@mui/material';
import { Box } from '@chaos/ui/box';
import { useTheme } from '@chaos/ui';
import { Button } from '@chaos/ui/button';
import {
  TableFilterAutocomplete,
  TableFilterOptions, TableFilterSlider, TableFilterTabPanel, TableFilterTabs, TableFilterText,
} from './table-filter-tabs';

interface BaseFilter {
  fieldName: string;
  fieldIndex: number;
  type: 'text' | 'range' | 'options';
  isApplied: boolean
  prefix?: string
  suffix?: string
}

export interface FilterText extends BaseFilter {
  type: 'text'
  value?: string
}

export interface FilterRange extends BaseFilter {
  type: 'range'
  range: [number, number]
  stepSize?: number
}

export interface FilterPicker extends BaseFilter {
  type: 'options'
  options: { name: string, icon?: string, isHidden: boolean, isFixed?: boolean }[]
  isAutocomplete?: boolean
}

export type Filter = FilterText | FilterRange | FilterPicker;

interface TableFilterInterface {
  isOpen: boolean;
  anchorEl?: HTMLElement | null;
  filters: Filter[]
  onChange: (filterValues: Record<number, Filter>) => void
  close: () => void
}

export const TableFilter: FC<TableFilterInterface> = ({
  isOpen, anchorEl, filters, onChange, close,
}) => {
  const [filterValues, setFilterValues] = useState<Record<number, Filter>>(filters
    .reduce((res, filter) => ({
      ...res,
      [filter.fieldIndex]: filter,
    }), {}));
  const [tabIndex, setTabIndex] = useState(0);
  const theme = useTheme();

  type Keys = 'Escape' | 'Enter';

  const keyActions: Record<Keys, () => void> = useMemo(() => ({
    Escape: () => {
      close();
    },
    Enter: () => {
      onChange(filterValues);
      close();
    },
  }), [close, filterValues, onChange]);

  const keyListener = useCallback((e: KeyboardEvent) => {
    const action = keyActions[e.key as Keys];

    if (action) {
      action();
    }
  }, [keyActions]);

  useEffect(() => {
    document.body.addEventListener('keyup', keyListener);
    return () => document.body.removeEventListener('keyup', keyListener);
  }, [isOpen, keyListener]);

  useEffect(() => setFilterValues((curFilterValues) => filters.reduce((res, filter) => {
    if (!filter.isApplied && curFilterValues[filter.fieldIndex]?.isApplied) {
      return {
        ...res,
        [filter.fieldIndex]: {
          ...curFilterValues[filter.fieldIndex],
          value: (filter as FilterText).value,
          range: (filter as FilterRange).range,
          options: (filter as FilterPicker).options,
          isApplied: false,
        },
      };
    }

    return {
      ...res,
      [filter.fieldIndex]: curFilterValues[filter.fieldIndex],
    };
  }, {})), [filters]);

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Popper
      open={isOpen}
      placement="bottom-start"
      anchorEl={anchorEl}
      style={{
        zIndex: theme.zIndex.modal + 1,
        borderRadius: 16,
        overflow: 'hidden',
        boxShadow: '0px 24px 32px rgba(0, 0, 0, 0.7)',
      }}
    >
      <ClickAwayListener onClickAway={close}>
        <Box display="flex" bgcolor="#17191E" borderRadius="inherit">
          <TableFilterTabs
            value={tabIndex}
            onChange={handleTabChange}
            tabs={!filterValues ? undefined : Object.values(filterValues).map((f) => ({
              label: f.fieldName,
              isApplied: f.isApplied,
            }))}
          />
          <Box bgcolor="background.default" p={3} width={432}>
            <TableFilterTabPanel value={tabIndex} index={tabIndex}>
              <Box
                gap={3}
                height="100%"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                {filters.map((filter, i) => {
                  if (tabIndex === i && filterValues[filter.fieldIndex] !== undefined) {
                    if (filter.type === 'range') {
                      return (
                        <TableFilterSlider
                          filter={filter}
                          key={filter.fieldName}
                          range={(filterValues[filter.fieldIndex] as FilterRange).range}
                          setFilterValues={setFilterValues}
                        />
                      );
                    }
                    if (filter.type === 'options') {
                      if (filter.isAutocomplete) {
                        return (
                          <TableFilterAutocomplete
                            filter={filter}
                            key={filter.fieldName}
                            options={(filterValues[filter.fieldIndex] as FilterPicker).options}
                            setFilterValues={setFilterValues}
                          />
                        );
                      }
                      return (
                        <TableFilterOptions
                          filter={filter}
                          key={filter.fieldName}
                          options={(filterValues[filter.fieldIndex] as FilterPicker).options}
                          setFilterValues={setFilterValues}
                        />
                      );
                    }
                    return (
                      <TableFilterText
                        filter={filterValues[i] as FilterText}
                        key={filter.fieldName}
                        setFilterValues={setFilterValues}
                      />
                    );
                  }
                  return undefined;
                })}

                <Box display="flex" gap={2}>
                  <Button
                    color="secondary"
                    fullWidth
                    onClick={() => setFilterValues(filters.reduce((res, filter) => ({
                      ...res,
                      [filter.fieldIndex]: {
                        ...filter,
                        isApplied: false,
                      },
                    }), {}))}
                  >
                    Clear All Filters
                  </Button>
                  <Button
                    color="primary"
                    fullWidth
                    onClick={() => {
                      onChange(filterValues);
                      close();
                    }}
                  >
                    Apply
                  </Button>
                </Box>
              </Box>
            </TableFilterTabPanel>
          </Box>
        </Box>
      </ClickAwayListener>
    </Popper>
  );
};
