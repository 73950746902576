import { TokenTickerEnum } from '@chaos/types';

import bitcoinSvg from 'src/assets/img/crypto/bitcoin.svg';
import wbtcSvg from 'src/assets/img/crypto/wbtc.svg';
import wethSvg from 'src/assets/img/crypto/weth.svg';
import ethSvg from 'src/assets/img/crypto/ethereum.svg';
import qiSvg from 'src/assets/img/crypto/qi.svg';
import solSvg from 'src/assets/img/crypto/sol.svg';
import aaveSvg from 'src/assets/img/crypto/aave.svg';
import avaxSvg from 'src/assets/img/crypto/avax.svg';
import celoSvg from 'src/assets/img/crypto/celo.svg';
import compoundSvg from 'src/assets/img/crypto/compound.svg';
import daiSvg from 'src/assets/img/crypto/dai.svg';
import linkSvg from 'src/assets/img/crypto/link.svg';
import lunaSvg from 'src/assets/img/crypto/luna.svg';
import maticSvg from 'src/assets/img/crypto/matic.svg';
import mkrSvg from 'src/assets/img/crypto/maker.svg';
import nearSvg from 'src/assets/img/crypto/near.svg';
import graphSvg from 'src/assets/img/crypto/the-graph.svg';
import uniSvg from 'src/assets/img/crypto/uni.svg';
import usdtSvg from 'src/assets/img/crypto/usdt.svg';
import usdpSvg from 'src/assets/img/crypto/usdp.svg';
import usdcSvg from 'src/assets/img/crypto/usdc.svg';
import tusdSvg from 'src/assets/img/crypto/tusd.svg';
import batSvg from 'src/assets/img/crypto/bat.svg';
import gusdSvg from 'src/assets/img/crypto/gusd.svg';
import kncSvg from 'src/assets/img/crypto/kbr.svg';
import lrcSvg from 'src/assets/img/crypto/lrc.svg';
import balSvg from 'src/assets/img/crypto/bal.svg';
import renSvg from 'src/assets/img/crypto/ren.svg';
import yfiSvg from 'src/assets/img/crypto/yfi.svg';
import manaSvg from 'src/assets/img/crypto/mana.svg';
import zrxSvg from 'src/assets/img/crypto/zrx.svg';
import algoRandSvg from 'src/assets/img/crypto/algorand.svg';
import bnbSvg from 'src/assets/img/crypto/bnb.svg';
import movrSvg from 'src/assets/img/crypto/movr.svg';
import ksmSvg from 'src/assets/img/crypto/ksm.svg';
import busdSvg from 'src/assets/img/crypto/busd.svg';
import fraxSvg from 'src/assets/img/crypto/frax.svg';
import mimSvg from 'src/assets/img/crypto/mim.svg';
import solarSvg from 'src/assets/img/crypto/solar.svg';
import romeSvg from 'src/assets/img/crypto/rome.svg';
import hundredSvg from 'src/assets/img/crypto/hnd.svg';
import fantomSvg from 'src/assets/img/crypto/ftm.svg';
import pinkSvg from 'src/assets/img/crypto/pink.svg';
import movr_bwSvg from 'src/assets/img/crypto/movr_bw.svg';
import sushiSvg from 'src/assets/img/crypto/sushi.svg';
import moonwellSvg from 'src/assets/img/crypto/moonwell.svg';
import crvSvg from 'src/assets/img/crypto/crv.svg';
import dotSvg from 'src/assets/img/crypto/dot.svg';
import snxSvg from 'src/assets/img/crypto/snx.svg';
import adaSvg from 'src/assets/img/crypto/ada.svg';
import algoSvg from 'src/assets/img/crypto/algo.svg';
import atomSvg from 'src/assets/img/crypto/atom.svg';
import bchSvg from 'src/assets/img/crypto/bch.svg';
import enjSvg from 'src/assets/img/crypto/enj.svg';
import eosSvg from 'src/assets/img/crypto/eos.svg';
import filSvg from 'src/assets/img/crypto/fil.svg';
import ltcSvg from 'src/assets/img/crypto/ltc.svg';
import umaSvg from 'src/assets/img/crypto/uma.svg';
import xmrSvg from 'src/assets/img/crypto/xmr.svg';
import oneInchSvg from 'src/assets/img/crypto/1inch.svg';
import zecSvg from 'src/assets/img/crypto/zec.svg';
import dogeSvg from 'src/assets/img/crypto/doge.svg';
import pngSvg from 'src/assets/img/crypto/pangolin.svg';
import ustSvg from 'src/assets/img/crypto/terra.svg';
import xlmSvg from 'src/assets/img/crypto/xlm.svg';
import etcSvg from 'src/assets/img/crypto/etc.svg';
import icpSvg from 'src/assets/img/crypto/icp.svg';
import runeSvg from 'src/assets/img/crypto/rune.svg';
import savaxSvg from 'src/assets/img/crypto/savax.svg';
import shibSvg from 'src/assets/img/crypto/shib.svg';
import axsSvg from 'src/assets/img/crypto/axs.svg';
import apeSvg from 'src/assets/img/crypto/ape.svg';
import gmtSvg from 'src/assets/img/crypto/gmt.svg';
import opSvg from 'src/assets/img/crypto/op.svg';
import kavaSvg from 'src/assets/img/crypto/kava.svg';
import ensSvg from 'src/assets/img/crypto/ens.svg';
import thetaSvg from 'src/assets/img/crypto/theta.svg';
import chzSvg from 'src/assets/img/crypto/chz.svg';
import croSvg from 'src/assets/img/crypto/cro.svg';
import vetSvg from 'src/assets/img/crypto/vet.svg';
import chrSvg from 'src/assets/img/crypto/chr.svg';
import wavesSvg from 'src/assets/img/crypto/waves.svg';
import sandboxSvg from 'src/assets/img/crypto/sandbox.svg';
import galaSvg from 'src/assets/img/crypto/gala.svg';
import zilSvg from 'src/assets/img/crypto/zil.svg';
import rsrSvg from 'src/assets/img/crypto/rsr.svg';

const TokenData = {
  AAVE: aaveSvg,
  ADA: adaSvg,
  ALGO: algoSvg,
  ATOM: atomSvg,
  AVAX: avaxSvg,
  BAL: balSvg,
  BAT: batSvg,
  BCH: bchSvg,
  BNB: bnbSvg,
  BUSD: busdSvg,
  BTC: bitcoinSvg,
  DOGE: dogeSvg,
  EOS: eosSvg,
  ENJ: enjSvg,
  QI: qiSvg,
  FRAX: fraxSvg,
  FIL: filSvg,
  FTM: fantomSvg,
  WBTC: wbtcSvg,
  ETH: ethSvg,
  LTC: ltcSvg,
  UMA: umaSvg,
  XMR: xmrSvg,
  MOVR: movrSvg,
  MOVR_BW: movr_bwSvg,
  WETH: wethSvg,
  SOL: solSvg,
  CELO: celoSvg,
  CRV: crvSvg,
  COMP: compoundSvg,
  DAI: daiSvg,
  DAIE: daiSvg,
  DOT: dotSvg,
  HND: hundredSvg,
  LINK: linkSvg,
  LINKE: linkSvg,
  LUNA: lunaSvg,
  LRC: lrcSvg,
  MATIC: maticSvg,
  MANA: manaSvg,
  MIM: mimSvg,
  MKR: mkrSvg,
  MFAM: moonwellSvg,
  ONEINCH: oneInchSvg,
  KNC: kncSvg,
  KSM: ksmSvg,
  NEAR: nearSvg,
  PINK: pinkSvg,
  GRT: graphSvg,
  UNI: uniSvg,
  USDT: usdtSvg,
  TUSD: tusdSvg,
  GUSD: gusdSvg,
  USDTE: usdtSvg,
  USDC: usdcSvg,
  USDP: usdpSvg,
  USDCE: usdcSvg,
  ROME: romeSvg,
  REN: renSvg,
  RENBTC: renSvg,
  SUSHI: sushiSvg,
  SOLAR: solarSvg,
  RAND: algoRandSvg,
  YFI: yfiSvg,
  ZRX: zrxSvg,
  ZEC: zecSvg,
  SNX: snxSvg,
  PNG: pngSvg,
  UST: ustSvg,
  XLM: xlmSvg,
  ETC: etcSvg,
  ICP: icpSvg,
  RUNE: runeSvg,
  SAVAX: savaxSvg,
  SHIB: shibSvg,
  AXS: axsSvg,
  APE: apeSvg,
  GMT: gmtSvg,
  OP: opSvg,
  KAVA: kavaSvg,
  ENS: ensSvg,
  THETA: thetaSvg,
  CHZ: chzSvg,
  CRO: croSvg,
  VET: vetSvg,
  CHR: chrSvg,
  WAVES: wavesSvg,
  SAND: sandboxSvg,
  GALA: galaSvg,
  ZIL: zilSvg,
  RSR: rsrSvg,
};

export const stringToTokenTicker = (ticker: string): string => {
  if (ticker === TokenTickerEnum.AAVE) {
    return TokenData.AAVE;
  } if (ticker === TokenTickerEnum.BAL) {
    return TokenData.BAL;
  } if (ticker === TokenTickerEnum.ZRX) {
    return TokenData.ZRX;
  } if (ticker === TokenTickerEnum.GUSD) {
    return TokenData.GUSD;
  } if (ticker === TokenTickerEnum.MANA) {
    return TokenData.MANA;
  } if (ticker === TokenTickerEnum.TUSD) {
    return TokenData.TUSD;
  } if (ticker === TokenTickerEnum.KNC) {
    return TokenData.KNC;
  } if (ticker === TokenTickerEnum.BAT) {
    return TokenData.BAT;
  } if (ticker === TokenTickerEnum.LRC) {
    return TokenData.LRC;
  } if (ticker === TokenTickerEnum.ETH) {
    return TokenData.ETH;
  } if (ticker === TokenTickerEnum.WETH) {
    return TokenData.WETH;
  } if (ticker === TokenTickerEnum.VETH) {
    return TokenData.ETH;
  } if (ticker === TokenTickerEnum.AVAX) {
    return TokenData.AVAX;
  } if (ticker === TokenTickerEnum.QI) {
    return TokenData.QI;
  } if (ticker === TokenTickerEnum.BTC) {
    return TokenData.BTC;
  } if (ticker === TokenTickerEnum.WBTC) {
    return TokenData.WBTC;
  } if (ticker === TokenTickerEnum.SOL) {
    return TokenData.SOL;
  } if (ticker === TokenTickerEnum.CELO) {
    return TokenData.CELO;
  } if (ticker === TokenTickerEnum.COMP) {
    return TokenData.COMP;
  } if (ticker === TokenTickerEnum.LINK) {
    return TokenData.LINK;
  } if (ticker === TokenTickerEnum.LINKE) {
    return TokenData.LINKE;
  } if (ticker === TokenTickerEnum.LUNA) {
    return TokenData.LUNA;
  } if (ticker === TokenTickerEnum.MATIC) {
    return TokenData.MATIC;
  } if (ticker === TokenTickerEnum.MKR) {
    return TokenData.MKR;
  } if (ticker === 'MFAM') {
    return TokenData.MFAM;
  } if (ticker === TokenTickerEnum.GRT) {
    return TokenData.GRT;
  } if (ticker === TokenTickerEnum.UNI) {
    return TokenData.UNI;
  } if (ticker === TokenTickerEnum.BUSD) {
    return TokenData.BUSD;
  } if (ticker === TokenTickerEnum.USD) {
    return TokenData.USDC;
  } if (ticker === TokenTickerEnum.USDC) {
    return TokenData.USDC;
  } if (ticker === TokenTickerEnum.USDCE) {
    return TokenData.USDCE;
  } if (ticker === TokenTickerEnum.USDT) {
    return TokenData.USDT;
  } if (ticker === TokenTickerEnum.USDP) {
    return TokenData.USDP;
  } if (ticker === TokenTickerEnum.USDTE) {
    return TokenData.USDTE;
  } if (ticker === TokenTickerEnum.DAI) {
    return TokenData.DAI;
  } if (ticker === TokenTickerEnum.DAIE) {
    return TokenData.DAIE;
  } if (ticker === TokenTickerEnum.NEAR) {
    return TokenData.NEAR;
  } if (ticker === TokenTickerEnum.REN) {
    return TokenData.REN;
  } if (ticker === TokenTickerEnum.RENBTC) {
    return TokenData.RENBTC;
  } if (ticker === TokenTickerEnum.YFI) {
    return TokenData.YFI;
  } if (ticker === TokenTickerEnum.RAND) {
    return TokenData.RAND;
  } if (ticker === TokenTickerEnum.BNB) {
    return TokenData.BNB;
  } if (ticker === TokenTickerEnum.BNBBSC) {
    return TokenData.BNB;
  } if (ticker === TokenTickerEnum.WBNB) {
    return TokenData.BNB;
  } if (ticker === TokenTickerEnum.MOVR) {
    return TokenData.MOVR;
  } if (ticker === TokenTickerEnum.WMOVR) {
    return TokenData.MOVR;
  } if (ticker === TokenTickerEnum.MOVR_BW) {
    return TokenData.MOVR_BW;
  } if (ticker === TokenTickerEnum.KSM) {
    return TokenData.KSM;
  } if (ticker === TokenTickerEnum.FRAX) {
    return TokenData.FRAX;
  } if (ticker === TokenTickerEnum.FTM) {
    return TokenData.FTM;
  } if (ticker === TokenTickerEnum.SOLAR) {
    return TokenData.SOLAR;
  } if (ticker === TokenTickerEnum.MIM) {
    return TokenData.MIM;
  } if (ticker === TokenTickerEnum.ROME) {
    return TokenData.ROME;
  } if (ticker === TokenTickerEnum.SUSHI) {
    return TokenData.SUSHI;
  } if (ticker === TokenTickerEnum.CRV) {
    return TokenData.CRV;
  } if (ticker === TokenTickerEnum.DOT) {
    return TokenData.DOT;
  } if (ticker === TokenTickerEnum.SNX) {
    return TokenData.SNX;
  } if (ticker === TokenTickerEnum.ONE_INCH) {
    return TokenData.ONEINCH;
  } if (ticker === TokenTickerEnum.ADA) {
    return TokenData.ADA;
  } if (ticker === TokenTickerEnum.ATOM) {
    return TokenData.ATOM;
  } if (ticker === TokenTickerEnum.BCH) {
    return TokenData.BCH;
  } if (ticker === TokenTickerEnum.ENJ) {
    return TokenData.ENJ;
  } if (ticker === TokenTickerEnum.EOS) {
    return TokenData.EOS;
  } if (ticker === TokenTickerEnum.FIL) {
    return TokenData.FIL;
  } if (ticker === TokenTickerEnum.LTC) {
    return TokenData.LTC;
  } if (ticker === TokenTickerEnum.XMR) {
    return TokenData.XMR;
  } if (ticker === TokenTickerEnum.UMA) {
    return TokenData.UMA;
  } if (ticker === TokenTickerEnum.ZEC) {
    return TokenData.ZEC;
  } if (ticker === TokenTickerEnum.ALGO) {
    return TokenData.ALGO;
  } if (ticker === TokenTickerEnum.DOGE) {
    return TokenData.DOGE;
  } if (ticker === TokenTickerEnum.PNG) {
    return TokenData.PNG;
  } if (ticker === TokenTickerEnum.UST) {
    return TokenData.UST;
  } if (ticker === TokenTickerEnum.XLM) {
    return TokenData.XLM;
  } if (ticker === TokenTickerEnum.ETC) {
    return TokenData.ETC;
  } if (ticker === TokenTickerEnum.ICP) {
    return TokenData.ICP;
  } if (ticker === TokenTickerEnum.RUNE) {
    return TokenData.RUNE;
  } if (ticker === TokenTickerEnum.SAVAX) {
    return TokenData.SAVAX;
  } if (ticker === TokenTickerEnum.SHIB) {
    return TokenData.SHIB;
  } if (ticker === TokenTickerEnum.AXS) {
    return TokenData.AXS;
  } if (ticker === TokenTickerEnum.APE) {
    return TokenData.APE;
  } if (ticker === TokenTickerEnum.GMT) {
    return TokenData.GMT;
  } if (ticker === TokenTickerEnum.OP) {
    return TokenData.OP;
  } if (ticker === TokenTickerEnum.KAVA) {
    return TokenData.KAVA;
  } if (ticker === TokenTickerEnum.ENS) {
    return TokenData.ENS;
  } if (ticker === TokenTickerEnum.THETA) {
    return TokenData.THETA;
  } if (ticker === TokenTickerEnum.CHZ) {
    return TokenData.CHZ;
  } if (ticker === TokenTickerEnum.CRO) {
    return TokenData.CRO;
  } if (ticker === TokenTickerEnum.VET) {
    return TokenData.VET;
  } if (ticker === TokenTickerEnum.CHR) {
    return TokenData.CHR;
  } if (ticker === TokenTickerEnum.WAVES) {
    return TokenData.WAVES;
  } if (ticker === TokenTickerEnum.SAND) {
    return TokenData.SAND;
  } if (ticker === TokenTickerEnum.GALA) {
    return TokenData.GALA;
  } if (ticker === TokenTickerEnum.ZIL) {
    return TokenData.ZIL;
  } if (ticker === TokenTickerEnum.RSR) {
    return TokenData.RSR;
  }
  return '';
};

export const wrappedSymbolToIconSymbol = (symbol: string) => {
  // metis tokens
  if (symbol.startsWith('m.')) {
    return symbol.substring(2).toLocaleLowerCase();
  }
  const cleanSymbol = symbol.indexOf('.') > -1
    ? symbol.substring(0, symbol.indexOf('.')).toLocaleLowerCase() : symbol.toLowerCase();

  // moonwell or avalance or benqi
  if (
    symbol.startsWith('1')
    || symbol.startsWith('m')
    || (symbol.startsWith('W') && symbol !== 'WAVES')
    || (symbol.startsWith('s') && symbol !== 'sAVAX' && symbol.toLowerCase() !== 'sushi')
  ) {
    return cleanSymbol.substring(1, symbol.length);
  }

  // benqi
  if (cleanSymbol.startsWith('qi') && cleanSymbol.length > 2) {
    if (cleanSymbol.endsWith('n')) {
      return cleanSymbol.substring(2, symbol.length - 1);
    }

    return cleanSymbol.substring(2, symbol.length);
  }

  return cleanSymbol;
};
