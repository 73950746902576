import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { PropsWithChildren } from 'react';
import { dataQueryURL } from './data-query-url';

export const DATA_QUERY_URL = dataQueryURL();
let client: ApolloClient<NormalizedCacheObject>;

export const getApolloClient = (clientName: string) => {
  if (!client) {
    client = new ApolloClient<NormalizedCacheObject>({
      uri: `${DATA_QUERY_URL}query/${clientName}`,
      cache: new InMemoryCache(),
    });
  }

  return client;
};

type Props = {
  protocol: string;
};
export const ChaosApolloProvider = ({ children, protocol }: PropsWithChildren<Props>) => {
  const apolloClient = new ApolloClient<NormalizedCacheObject>({
    uri: `${DATA_QUERY_URL}query/ccar-lending`,
    cache: new InMemoryCache(),
    headers: {
      protocol,
    },
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
