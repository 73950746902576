import { useNavigate } from 'react-router-dom';
import { StyledFirebaseAuth } from 'react-firebaseui';
import firebase from 'firebase/compat/app';
import { Paper } from '@chaos/ui/paper';
import { Typography } from '@chaos/ui/typography';
import { auth } from 'src/services/firebase';
import { RoutePath } from 'src/config/routes';
import { useTheme } from '@chaos/ui/theme';
import { usePageTitle } from 'src/hooks';
import { PageTracker } from 'src/components/page-tracker';
import { useState } from 'react';
import { Loader } from '../../components/loader';
import './firebase.global.css';

export const ACTION_CODE_SETTINGS = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  // @TODO(Harris): make this a variable and not hardcoded localhost.
  url: `${window.location.origin}${RoutePath.MagicLink.Receive}`,
  // This must be true.
  handleCodeInApp: true,
};

export const SignInPage = PageTracker((): JSX.Element => {
  usePageTitle('Sign In');
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const userConfig = {
    signInFlow: 'popup',
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: (user: firebase.auth.UserCredential) => {
        if (user.user?.email) {
          setLoading(true);
          void firebase.functions().httpsCallable('onLogin')();
          setTimeout(() => {
            navigate('/');
          }, 1000);
        }

        return false;
      },
    },
  };

  const theme = useTheme();

  return loading ? <Loader /> : (
    <Paper
      className="sign-in"
      variant="card"
      sx={{
        px: 2,
        [theme.breakpoints.up('sm')]: {
          p: 5,
        },
      }}
    >
      <Typography
        sx={{
          pb: 3,
          [theme.breakpoints.up('sm')]: {
            pb: 3,
          },
        }}
        component="h1"
        variant="h2"
      >
        Sign in to your account
      </Typography>
      <StyledFirebaseAuth uiConfig={userConfig} firebaseAuth={auth} />
    </Paper>
  );
});
