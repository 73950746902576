import { FC } from 'react';
import { Box } from '@chaos/ui/box';
import { Typography } from '@chaos/ui/typography';
import { Paper } from '@chaos/ui/paper';
import { Grid } from '@chaos/ui/grid';
import { CustomIcon } from 'src/components/custom-icon';
import { CollapsibleDescription } from 'src/components/charts';

type PairsRowProps = {
  pairs: any[],
  title?: string,
  description?: string,
};

export const PairsRow: FC<PairsRowProps> = ({ pairs, title, description }): JSX.Element => (
  <Grid spacing={5} container>
    {(title || description) && (
    <Grid item xs={12}>
      {title && <Typography variant="h2">{title}</Typography>}
      {description && <CollapsibleDescription text={description} />}
    </Grid>
    )}
    {pairs.map((pair: any, index: number) => (
      // eslint-disable-next-line
      <Grid item xs={6} key={`${pair.price} + ${index}`}>
        <Paper
          variant="card"
          sx={{
            height: '100%',
            minHeight: 120,
            padding: '24px 16px 24px 24px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'lightblue.main',
            }}
          >
            <CustomIcon
              // eslint-disable-next-line
              icon={pair.currency1.icon}
              sx={{ mr: 1, svg: { width: 24, height: 24 } }}
            />
            <Typography variant="h2" component="h2">
              {/* eslint-disable-next-line */}
              {pair.currency1.code}
            </Typography>
            <Typography variant="h2" component="h2" ml={1} mr={1}>
              /
            </Typography>
            <CustomIcon
              // eslint-disable-next-line
              icon={pair.currency2.icon}
              sx={{ mr: 1, svg: { width: 24, height: 24 } }}
            />
            <Typography variant="h2" component="h2">
              {/* eslint-disable-next-line */}
              {pair.currency2.code}
            </Typography>
          </Box>
          <Typography
            variant="h1"
            component="h2"
            sx={{
              mt: 1,
            }}
          >
            {/* eslint-disable-next-line */}
            {pair.price}
            <Typography sx={{ color: 'success.main', pl: 1 }} component="span">
              {/* eslint-disable-next-line */}
              {pair.delta}
            </Typography>
          </Typography>
        </Paper>
      </Grid>
    ))}
  </Grid>
);
