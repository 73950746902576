import { FC } from 'react';
import { Box } from '@chaos/ui/box';
import { Button } from '@chaos/ui/button';
import { Typography } from '@chaos/ui/typography';
import { TeamMemberData } from 'src/pages/team-edit-page';

interface ModalProps {
  onClose?: () => void
  onRemove: () => void
  memberData: TeamMemberData | null
}

export const RemoveMemberModal: FC<ModalProps> = (props) => {
  const { onClose, onRemove, memberData } = props;

  return (
    <>
      <Box component="header">
        <Box className="modal-header" sx={{ py: '36px' }}>
          <Typography variant="h2" component="h2">
            Remove Member
          </Typography>
        </Box>
      </Box>

      <Box className="modal-body">
        <Typography component="p">
          You are going to remove member
          <strong>
            {' '}
            {`${memberData?.firstName || ''} ${memberData?.lastName || ''}`}
          </strong>
          <br />
          <span>{`(${memberData?.email || ''})`}</span>
          . This member will lose access.
          Are you sure to do this?
        </Typography>
      </Box>
      <Box component="footer">
        <Box className="modal-footer" sx={{ py: 5 }}>
          <Button
            color="secondary"
            onClick={onClose}
            sx={{ mr: '24px' }}
          >
            Cancel
          </Button>
          <Button
            color="error"
            onClick={onRemove}
          >
            Remove
          </Button>
        </Box>
      </Box>
    </>
  );
};
