import { useEffect, useState } from 'react';
import { useFirebase } from 'react-redux-firebase';

import * as StorageTypes from '@firebase/storage-types';
import { Box } from '@chaos/ui/box';
import { Typography } from '@chaos/ui/typography';
import { Button } from '@chaos/ui/button';
import {
  ModalWrapper,
  UploadContractModal,
  FetchChainContractModal,
} from 'src/components/modals';
import { useContracts, useCurrentTeam, usePageTitle } from 'src/hooks';
import { Loader } from 'src/components/loader';
import { PageTracker } from 'src/components/page-tracker';
import { EmptyState } from 'src/components/empty-state';
import { MainLayout } from '../../components/layouts';
import { DeploymentRow } from '../../components/deployment-row';

export const ContractDeploymentsPage = PageTracker((): JSX.Element => {
  usePageTitle('Contracts');
  const team = useCurrentTeam();
  const firebase = useFirebase();

  const [contractsMetadata, setContractMetadata] = useState<
  Array<StorageTypes.FullMetadata>
  >([]);
  const [uploadContractModalOpen, setUploadContractModalOpen] = useState<boolean>(false);
  const [fetchFetchContractModalOpen, setFetchFetchContractModalOpen] = useState<boolean>(false);

  const { isLoading, contracts } = useContracts();

  useEffect(() => {
    async function fetchContracts() {
      const contractsRef = firebase
        .storage()
        .refFromURL('gs://chaos-labs-web-app.appspot.com/solidity-contracts');
      const data = await contractsRef.listAll();
      const { items } = data;
      const filesMetadataPromises = items.map((item) => item.getMetadata());

      Promise.all(filesMetadataPromises)
        .then((newContractsMetadata) => {
          if (contracts.length) {
            const filteredContractMetadata = newContractsMetadata.filter(
              (currData) => contracts.find((contract) => contract.resourceName === currData.name),
            );
            setContractMetadata(filteredContractMetadata);
          }
        })
        .catch((e) => console.log('Catch err', e));
    }

    if (!isLoading) {
      fetchContracts().catch(console.log);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebase, isLoading]);

  const openUploadContractModal = () => {
    setUploadContractModalOpen(true);
  };
  const openFetchFetchContractModal = () => {
    setFetchFetchContractModalOpen(true);
  };

  const closeUploadContractModal = () => setUploadContractModalOpen(false);
  const closeFetchFetchContractModal = () => setFetchFetchContractModalOpen(false);

  return (
    <MainLayout
      topbar={(
        <>
          <Typography
            variant="h1"
            component="h1"
            sx={{
              padding: '44px 40px 36px 0',
              flexGrow: 1,
            }}
          >
            Contract Deployments
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <Button
              color="primary"
              sx={{ ml: 3 }}
              onClick={openFetchFetchContractModal}
            >
              Fetch Chain Contract
            </Button>
            <Button
              color="primary"
              sx={{ ml: 3 }}
              onClick={openUploadContractModal}
            >
              Upload Contract
            </Button>
          </Box>
        </>
      )}
    >
      {isLoading && <Loader />}
      {!isLoading && (contracts.length > 0
        ? (
          <Box>
            {contracts.map((contract, i) => (
              <DeploymentRow
                key={i.toString()}
                contract={contract}
                contractMetadata={contractsMetadata.find(
                  (cm) => contract.contractName === cm.name,
                )}
              />
            ))}
          </Box>
        ) : <EmptyState type="contract deployments" />)}
      <ModalWrapper
        open={uploadContractModalOpen}
        onClose={closeUploadContractModal}
        maxWidth={694}
      >
        <UploadContractModal
          team={team!}
          contracts={contracts}
          onClose={closeUploadContractModal}
          contractsMetadata={contractsMetadata}
        />
      </ModalWrapper>
      <ModalWrapper
        open={fetchFetchContractModalOpen}
        onClose={closeFetchFetchContractModal}
        maxWidth={600}
      >
        <FetchChainContractModal
          team={team!}
          contracts={contracts}
          onClose={closeFetchFetchContractModal}
        />
      </ModalWrapper>
    </MainLayout>
  );
});
