import { StylesConfig } from 'react-select';
import { OptionType, StylesFuncProps } from './types';
import { palette } from '../theme/palette';

export const styles = ({
  error = false,
  variant,
  isMobile,
}: StylesFuncProps): StylesConfig<OptionType, boolean> => ({
  container: (css) => ({
    ...css,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '24px',
    color: palette.white.main,
  }),
  control: (css, p) => ({
    ...css,
    ...p.isFocused ? ({
      backgroundColor: palette.inputs.active,
    }) : ({
      backgroundColor: palette.inputs.main,
      ':hover': {
        backgroundColor: palette.inputs.hover,
      },
    }),
    ...((variant === 'title' || variant === 'h3') && ({
      backgroundColor: 'transparent',
      ':hover': {
        backgroundColor: 'transparent',
      },
    })),
    ...(error && ({ backgroundColor: palette.red.opacity10 })),
    border: 'none',
    flexWrap: 'nowrap',
    borderRadius: p.menuIsOpen ? '8px 8px 0 0' : 8,
    outline: 'none',
    cursor: 'pointer',
    boxShadow: 'none',
    opacity: p.isDisabled ? 0.3 : 1,
    transition: 'background-color 0.3s ease',
    '.chevron-down-icon': {
      transform: `rotate(${p.menuIsOpen ? '180deg' : '0'})`,
    },
  }),
  valueContainer: (css) => ({
    ...css,
    padding: '12px 16px',
    ...((variant === 'title' || variant === 'h3') && {
      padding: 0,
      overflow: 'visible',
    }),
  }),
  singleValue: (css) => ({
    ...css,
    margin: 0,
    whiteSpace: 'nowrap',
    overflow: 'visible',
    display: 'flex',
    alignItems: 'center',
    columnGap: 8,
    color: palette.white.main,
    '.rs-icon svg': {
      lineHeight: 0,
      ...isMobile ? {
        width: '20px',
        height: '20px',
      } : {
        width: '24px',
        height: '24px',
      },
    },
    ...(variant === 'title' && ({
      fontSize: 32,
      fontWeight: 600,
      lineHeight: '32px',
      ...(isMobile && ({
        fontSize: 24,
        lineHeight: '24px',
      })),
    })),
    ...(variant === 'h3' && ({
      fontSize: 24,
      fontWeight: 700,
      lineHeight: '32px',
      ...(isMobile && ({
        fontSize: 20,
        lineHeight: '24px',
      })),
    })),
  }),
  placeholder: (css) => ({
    ...css,
    color: palette.almostWhite.main,
    margin: 0,
  }),
  indicatorsContainer: (css) => ({
    ...css,
    paddingRight: 4,
    ...((variant === 'title' || variant === 'h3') && {
      paddingRight: 0,
      maxWidth: 30,
    }),
    '> div': {
      paddingRight: 0,
    },
  }),
  menu: (css) => ({
    ...css,
    zIndex: 1500,
    marginTop: 0,
    overflow: 'hidden',
    background: palette.darkGrey.main,
    boxShadow: '0px 16px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '0 0 8px 8px',
    ...(variant === 'title' && ({
      marginTop: 12,
      borderRadius: 8,
    })),
    width: 'max-content',
    minWidth: '100%',
  }),
  menuList: (css) => ({
    ...css,
    padding: '8px 0',
    cursor: 'default',
  }),
  option: (_css, p) => ({
    display: 'flex',
    alignItems: 'center',
    columnGap: 8,
    color: palette.white.main,
    padding: `12px ${(p.data.customIcon || p.data.cryptoIcon) ? '16px' : '24px'}`,
    transition: 'background-color 0.3s ease',
    ...(!p.data.bgColor && ({ backgroundColor: p.isSelected ? palette.grey.main : 'transparent' })),
    ...(p.data.bgColor && ({
      backgroundColor: p.data.bgColor && (
        p.isSelected ? palette[p.data.bgColor].opacity50 : palette[p.data.bgColor].opacity20
      ),
    })),
    ':hover': {
      backgroundColor: p.isDisabled
        ? 'transparent'
        : (p.data.bgColor && palette[p.data.bgColor].opacity50) || palette.grey.hover,
    },
    ':active': {
      backgroundColor: p.isDisabled
        ? 'transparent'
        : (p.data.bgColor && palette[p.data.bgColor].opacity50) || palette.grey.hover,
    },
    cursor: p.isDisabled ? 'default' : 'pointer',
    opacity: p.isDisabled ? 0.3 : 1,
    '.rs-icon svg': {
      lineHeight: 0,
      ...isMobile ? {
        width: '20px',
        height: '20px',
      } : {
        width: '24px',
        height: '24px',
      },
    },
    ...(variant === 'title' && ({
      fontSize: 16,
    })),
    fontSize: 14,
  }),
  input: (css) => ({
    ...css,
    color: palette.white.main,
    margin: 0,
    padding: 0,
  }),
  menuPortal: (css) => ({
    ...css,
    zIndex: 1500,
  }),
  multiValue: (css) => ({
    ...css,
    backgroundColor: palette.blue.opacity50,
    borderRadius: 4,
    padding: '0 4px',
  }),
  multiValueLabel: (css) => ({
    ...css,
    color: palette.white.main,
    padding: '0 4px',
  }),
  multiValueRemove: (css, state) => ({
    ...css,
    // @ts-expect-error test
    display: state.data.isFixed ? 'none' : css.display,
    borderRadius: 0,
    marginLeft: 2,
    '&:hover': {
      backgroundColor: palette.blue.hover,
      color: palette.white.main,
    },
  }),
});
