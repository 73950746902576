import {
  FC, useEffect, useMemo, useState,
} from 'react';
import { Box } from '@chaos/ui/box';
import { Button } from '@chaos/ui/button';
import { Typography } from '@chaos/ui/typography';
import { TextField } from '@chaos/ui/text-field';
import { InputLabel } from '@chaos/ui/input-label';
import { ChartDataSource, ChartType } from '@chaos/types';
import { useCurrentTeam } from 'src/hooks';
import { DataQueryMap } from 'src/services/engine/requests';
import { CustomReactSelect } from '@chaos/ui/custom-select';
import { JSONViewer } from '../../json-viewer';
import { widgetsPerSource } from './widgets-per-source';
import { SubmitButton } from './submit-button';

const widgetLabels: Record<ChartType, string> = {
  [ChartType.AreaChartRow]: 'Area Chart',
  [ChartType.VictoryLineChart]: 'Victory Line Chart',
  [ChartType.HistogramChartRow]: 'Histogram',
  [ChartType.PieChart]: 'Pie Chart',
  [ChartType.PieChartRow]: 'Pie Chart',
  [ChartType.PairsRow]: 'Pairs',
  [ChartType.OverviewWithIconChart]: 'Overview With Icon',
  [ChartType.OverviewCardRow]: 'Overview',
  [ChartType.AddressRow]: 'Address',
  [ChartType.BalanceRow]: 'Balance',
  [ChartType.Table]: 'Table',
  [ChartType.JSONWidget]: 'JSON',
  [ChartType.Table]: 'Data Table',
  [ChartType.DyDxPerpetualFundingRate]: 'DyDx Funding',
  [ChartType.ScatterChart]: 'Scatter Chart',
  [ChartType.TokenCards]: 'Token Cards',
  [ChartType.RiskScores]: 'Risk Scores',
  [ChartType.TokenTreeMaps]: 'Token Tree Maps',
  [ChartType.DyDxCalculator]: 'DyDx Calculator',
};

export type DataSourceOption = {
  type: ChartDataSource,
  label: string,
  value: string,
};

interface AddWidgetModalProps {
  onClose: () => void
}

export const AddWidgetModal: FC<AddWidgetModalProps> = ({
  onClose,
}) => {
  const team = useCurrentTeam();
  const [data, setData] = useState<{ onchain: string[], offchain: string[] }>();
  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [dataSource, setDataSource] = useState<DataSourceOption>();
  const [widgetType, setWidgetType] = useState<ChartType>();
  // TODO: return default query data from data-fetchers for each data source
  const [queryData, setQueryData] = useState({});

  useEffect(() => {
    void DataQueryMap().then((resp: Record<string, { onchain: string[], offchain: string[] }>) => {
      setData(resp[team?.name.toLowerCase() || ''] || resp.maker);
    });
  }, [team]);

  const getSourceName = (source: string) => `${team?.name.includes('Dogfood')
    ? 'maker' : team?.name.toLowerCase() || ''}.${source}`;
  const transformDataSources = ((sources: string[], type: ChartDataSource) => sources
    .filter((source) => !!widgetsPerSource[getSourceName(source)])
    .map((source) => ({
      type,
      value: getSourceName(source),
      label: source,
    })));
  const dataSources: DataSourceOption[] = data ? [
    ...transformDataSources(data.onchain, ChartDataSource.ONCHAIN),
    ...transformDataSources(data.offchain, ChartDataSource.OFFCHAIN),
  ] : [];

  const widgets = useMemo(() => (dataSource ? widgetsPerSource[dataSource.value].map((widget) => ({
    value: widget,
    label: widgetLabels[widget],
  })) : []), [dataSource]);

  useEffect(() => {
    setWidgetType(widgets[0]?.value);
  }, [widgets]);

  return (
    <>
      <Box component="header">
        <Box className="modal-header" sx={{ py: '36px' }}>
          <Typography variant="h2" component="h3">
            Add Widget
          </Typography>
        </Box>
      </Box>
      <Box className="modal-body">
        <Box flex={1}>
          <InputLabel>Widget Title</InputLabel>
          <TextField
            name="title"
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
          />
        </Box>
        <Box flex={1}>
          <InputLabel>Widget Description</InputLabel>
          <TextField
            name="description"
            placeholder="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
          />
        </Box>
        <Box display="flex" gap={3}>
          <Box flex={1}>
            <InputLabel>Data Source</InputLabel>
            <CustomReactSelect
              placeholder="Data Source"
              isSearchable={false}
              options={dataSources}
              onChange={(val) => {
                setDataSource(val as DataSourceOption);
              }}
              menuPortalTarget={document.body}
            />
          </Box>
          <Box flex={1}>
            <InputLabel>Type</InputLabel>
            <CustomReactSelect
              placeholder="Type"
              isSearchable={false}
              options={widgets}
              onChange={(val) => {
                setWidgetType(val?.value as ChartType);
              }}
              menuPortalTarget={document.body}
              value={widgets.find((widget) => widget.value === widgetType)}
            />
          </Box>
        </Box>
        <Box>
          <InputLabel>Query Params</InputLabel>
          <JSONViewer
            isEditing
            json={queryData}
            onEdit={(edit) => {
              setQueryData(edit.updated_src);
            }}
          />
        </Box>
      </Box>
      <Box component="footer">
        <Box className="modal-footer" sx={{ py: 5 }}>
          <Button
            color="secondary"
            sx={{ mr: '24px' }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <SubmitButton
            dataSource={dataSource}
            widgetType={widgetType}
            title={title}
            description={description}
            queryData={queryData}
            onClose={onClose}
          />
        </Box>
      </Box>
    </>
  );
};
