import {
  Box, Button, CustomMultiReactSelect, Grid, Typography,
} from '@chaos/ui';
import { Form, Formik } from 'formik';
import { useCurrentTeam } from 'src/hooks';
import { ReactSelectField } from 'src/components/fields';
import { toTitleCase } from '@chaos/ui/utils/formatters';
import { useMemo, useState } from 'react';
import { useMarketsByProtocol } from '../../data/hooks';
import { TriggerChainRecommendationRequest } from '../../data/types';
import { chainMarketProtocols, protocols } from '../../types';
import { getAssetsFromMarket } from './utils';
import { postChainRecommendation } from '../../data/requests';

const ModelTriggerForm = () => {
  const team = useCurrentTeam();
  const { isLoading: isLoadingMarkets, marketsByProtocol } = useMarketsByProtocol();
  const [triggerResponse, setTriggerResponse] = useState<string>();

  const protocolsOptions = protocols.map((p) => (
    { value: p, label: toTitleCase(p), cryptoIcon: p }
  ));

  const initialValues: TriggerChainRecommendationRequest = useMemo(() => {
    const initialProtocol = protocols[0];
    const initialMarket = marketsByProtocol?.[initialProtocol]?.[0];
    const assets = initialMarket ? getAssetsFromMarket(initialMarket) : [];

    return ({
      protocol: initialProtocol,
      marketId: initialMarket?.id || '',
      chainName: initialMarket?.chain || '',
      overrideAssetSymbols: assets,
    });
  }, [marketsByProtocol]);

  const onSubmit = async (values: TriggerChainRecommendationRequest) => {
    if (team?.authKey) {
      const response = await postChainRecommendation(team.authKey, values);
      setTriggerResponse(response);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isSubmitting, values, setFieldValue }) => {
        const market = marketsByProtocol?.[values.protocol]?.find((
          { id },
        ) => id === values.marketId);
        const assetsOptions = (market ? getAssetsFromMarket(market) : []).map((
          asset,
        ) => ({ value: asset, label: asset, cryptoIcon: asset })) || [];
        return (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <ReactSelectField
                  label="Protocol"
                  placeholder="Select Protocol"
                  name="protocol"
                  options={protocolsOptions}
                  onChange={(protocol) => {
                    const firstMarket = marketsByProtocol?.[protocol as string]?.[0];
                    if (firstMarket) {
                      setFieldValue('marketId', firstMarket.id);
                      setFieldValue('chainName', firstMarket.chain);
                      const assets = getAssetsFromMarket(firstMarket);
                      setFieldValue('overrideAssetSymbols', assets);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <ReactSelectField
                  label="Market"
                  placeholder="Select Market"
                  name="marketId"
                  isDisabled={isLoadingMarkets}
                  options={(marketsByProtocol?.[values.protocol] || []).map((m) => ({
                    value: m.id,
                    label: m.name,
                    cryptoIcon: chainMarketProtocols.includes(values.protocol)
                      ? m.id
                      : undefined,
                  }))}
                  onChange={(marketId) => {
                    const newMarket = marketsByProtocol?.[values.protocol]?.find((
                      { id },
                    ) => id === marketId);
                    if (newMarket) {
                      setFieldValue('chainName', newMarket.chain);
                      const assets = getAssetsFromMarket(newMarket);
                      setFieldValue('overrideAssetSymbols', assets);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <ReactSelectField
                  label="Chain"
                  placeholder="Select Chain"
                  name="chainName"
                  isDisabled={isLoadingMarkets}
                  options={market ? [
                    { value: market.chain, label: market.chain, cryptoIcon: market.chain },
                  ] : []}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomMultiReactSelect
                  label="Asset(s)"
                  placeholder="Select Asset(s)"
                  controlShouldRenderValue
                  options={assetsOptions}
                  disabled={isLoadingMarkets}
                  value={assetsOptions.filter((
                    opt,
                  ) => values.overrideAssetSymbols?.includes(opt.value))}
                  onChange={(assets) => {
                    setFieldValue('overrideAssetSymbols', assets.map((a) => a.value));
                  }}
                  allowSelectAll
                />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" gap={2}>
                  <Button color="primary" type="submit" disabled={isSubmitting}>Trigger</Button>
                  {triggerResponse && (
                    <Typography color={triggerResponse ? 'green.main' : 'red.main'}>
                        {triggerResponse ? `Success ${triggerResponse}` : 'Error'}
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ModelTriggerForm;
