import { FC, useEffect, useState } from 'react';
import { Slider } from '../../slider';
import { Box } from '../../box';
import { Grid } from '../../grid';
import { TextField } from '../../text-field';
import { IconButton } from '../../icon-button';
import { InputAdornment } from '../../input-adornment';
import { TokensFilter } from '../../tokens-filter';
import { formatAmount, formatDate } from '../../utils/formatters';
import { CustomIcon } from '../../custom-icon';
import { CustomMultiReactSelect } from '../../custom-select';
import type {
  Filter, FilterPicker, FilterRange, FilterText,
} from '../table-filter';
import { CryptoIcon } from '../../crypto-icon';

interface TableFilterProps {
  filter: Filter
  setFilterValues: React.Dispatch<React.SetStateAction<Record<number, Filter>>>
}

interface TableFilterSliderProps extends TableFilterProps {
  filter: FilterRange
  min?: number
  max?: number
  range: FilterRange['range']
}

interface TableFilterOptionsProps extends TableFilterProps {
  filter: FilterPicker
  options: FilterPicker['options']
}

interface TableFilterTextProps extends TableFilterProps {
  filter: FilterText
}

const SliderTextField: FC<TableFilterSliderProps & { isRight?: boolean }> = ({
  filter, range, setFilterValues, isRight,
}) => (
  <TextField
    sx={{
      input: { width: '100%' },
      flex: 1,
      ml: isRight ? 'auto' : undefined,
      '.MuiOutlinedInput-root.Mui-disabled': {
        opacity: 1,
      },
    }}
    value={filter.isDate
      ? formatDate(new Date(range[isRight ? 1 : 0]))
      : formatAmount(range[isRight ? 1 : 0], { notation: 'standard' })}
    InputProps={{
      startAdornment:
        filter.prefix && <InputAdornment position="start">{filter.prefix}</InputAdornment>,
      endAdornment:
        filter.suffix && <InputAdornment position="end">{filter.suffix}</InputAdornment>,
    }}
    onChange={(e) => {
      const value = (e.target.value && Number.isInteger(Number(e.target.value)))
        ? Number(e.target.value) : 0;
      setFilterValues((filterValues) => ({
        ...filterValues,
        [filter.fieldIndex]: {
          ...filter,
          type: 'range',
          range: isRight ? [range[0], value] : [value, range[1]],
          isApplied: filter.range[isRight ? 1 : 0] !== value,
        },
      }));
    }}
    disabled={filter.isDate}
  />
);

export const TableFilterSlider: FC<TableFilterSliderProps> = (
  {
    range, filter, min, max, setFilterValues,
  },
) => {
  const minRangeValue = min !== undefined ? min : filter.range[0];
  const maxRangeValue = max !== undefined ? max : filter.range[1];

  const isRangeValuesChanged = (
    newMinValue: number,
    newMaxValue: number,
  ): boolean => newMinValue !== minRangeValue || newMaxValue !== maxRangeValue;

  return (
    <Box>
      <Slider
        value={range}
        min={minRangeValue}
        max={maxRangeValue}
        step={filter.stepSize || (maxRangeValue - minRangeValue <= 10 ? 0.01 : 0.1)}
        onChange={(_, val) => {
          const isApplied = Array.isArray(val)
            ? isRangeValuesChanged(val[0], val[1])
            : isRangeValuesChanged(val, val);
          setFilterValues((filterValues) => ({
            ...filterValues,
            [filter.fieldIndex]: {
              ...filter,
              range: Array.isArray(val)
                ? [Math.min(val[0], val[1]), Math.max(val[0], val[1])] : [val, val],
              isApplied,
            },
          }));
        }}
      />
      <Box display="flex" gap={2}>
        <SliderTextField filter={filter} range={range} setFilterValues={setFilterValues} />
        <SliderTextField filter={filter} range={range} setFilterValues={setFilterValues} isRight />
      </Box>
    </Box>
  );
};

export const TableFilterOptions: FC<TableFilterOptionsProps> = (
  { filter, options, setFilterValues },
) => (
  <Grid item xs={12}>
    <TokensFilter
      tokens={options}
      onChange={(index, isVisible) => setFilterValues((filterValues) => {
        const newOptions = options.map(
          (currOption, i) => ({
            ...currOption,
            isHidden: index === i ? !isVisible : currOption.isHidden,
          }),
        );
        return {
          ...filterValues,
          [filter.fieldIndex]: {
            ...filter,
            options: newOptions,
            isApplied: newOptions.some((option) => !option.isHidden),
          },
        };
      })}
    />
  </Grid>
);

export const TableFilterAutocomplete: FC<TableFilterOptionsProps> = (
  { filter, options, setFilterValues },
) => {
  const [currentOptions, setCurrentOptions] = useState(options);
  useEffect(() => setCurrentOptions(options), [options]);

  return (
    <Grid item xs={12}>
      <Box minWidth={200} data-testid={`table-filter-${filter.fieldName.toLowerCase()}`}>
        <CustomMultiReactSelect
          options={options.filter((o) => !o.isFixed).map((o) => ({
            label: o.name, value: o.name, cryptoIcon: o.cryptoIcon, customIcon: o.customIcon,
          }))}
          onChange={(vals) => setFilterValues((filterValues) => {
            const newOptions = options.map((o) => ({
              ...o,
              isHidden: !vals.find((v) => v.value === o.name),
            }));
            setCurrentOptions(newOptions);
            return {
              ...filterValues,
              [filter.fieldIndex]: {
                ...filter,
                options: newOptions,
                isApplied: newOptions.some((o) => !o.isHidden),
              },
            };
          })}
          value={currentOptions.filter((o) => !o.isHidden).map((o) => ({
            label: o.name, value: o.name, isFixed: o.isFixed,
          }))}
          placeholder={filter.title || filter.fieldName}
          closeMenuOnSelect
          menuShouldScrollIntoView={false}
          menuPortalTarget={document.body}
        />
        <Box display="flex" flexDirection="row" flexWrap="wrap" gap={1} mt={2}>
          {currentOptions.filter((o) => !o.isHidden).map((o) => (
            <Box
              key={o.name}
              bgcolor="background.light"
              display="flex"
              flexDirection="row"
              gap={1}
              px={1}
              borderRadius={2}
            >
              {o.customIcon && (
                <CustomIcon
                  icon={o.customIcon}
                  sx={{ svg: { width: 16, height: 16 } }}
                />
              )}
              {o.cryptoIcon && (
                <CryptoIcon
                  icon={o.cryptoIcon}
                  sx={{ img: { width: 16, height: 16 } }}
                />
              )}
              {o.name}
              {!o.isFixed && (
                <IconButton
                  sx={{ p: 0 }}
                  onClick={() => setFilterValues((filterValues) => {
                    const newOptions = currentOptions.map(((co) => (o.name === co.name
                      ? { ...co, isHidden: true } : co)));
                    setCurrentOptions(newOptions);
                    return {
                      ...filterValues,
                      [filter.fieldIndex]: {
                        ...filter,
                        options: newOptions,
                        isApplied: newOptions.some((co) => !co.isHidden),
                      },
                    };
                  })}
                >
                  <CustomIcon icon="close" sx={{ svg: { width: 8, height: 8 } }} />
                </IconButton>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </Grid>
  );
};

export const TableFilterText: FC<TableFilterTextProps> = ({ filter, setFilterValues }) => (
  <Box>
    <TextField
      sx={{ width: '100%', input: { background: '#17191e' } }}
      value={filter.value || ''}
      placeholder={`Enter ${filter.title || filter.fieldName}`}
      onChange={(e) => setFilterValues((filterValues) => ({
        ...filterValues,
        [filter.fieldIndex]: {
          ...filter, type: 'text', value: e.target.value, isApplied: e.target.value.length > 0,
        },
      }))}
    />
  </Box>
);
