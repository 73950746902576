import { useNavigate } from 'react-router-dom';

import { Box } from '@chaos/ui/box';
import { Grid } from '@chaos/ui/grid';
import { Paper } from '@chaos/ui/paper';
import { Typography } from '@chaos/ui/typography';

import { RoutePath } from 'src/config/routes';
import { MainLayout } from 'src/components/layouts';
import { CustomIcon } from 'src/components/custom-icon';
import { usePageTitle } from 'src/hooks';
import { PageTracker } from 'src/components/page-tracker';

import { ReactComponent as MakerLogoSvg } from 'src/assets/img/maker-logo.svg';
import { ReactComponent as UniswapLogo } from 'src/assets/img/uniswap-logo.svg';

export const OracleConfigurationPage = PageTracker((): JSX.Element => {
  usePageTitle('Oracle Configuration');
  const navigate = useNavigate();
  return (
    <MainLayout
      topbar={(
        <Typography
          variant="h1"
          component="h1"
          sx={{
            py: '44px',
            flexGrow: 1,
          }}
        >
          Oracle Configuration
        </Typography>
      )}
    >
      <Grid columnSpacing={5} rowSpacing={5} container>
        <Grid item xs={12} md={12} lg={6} zeroMinWidth>
          <Paper
            variant="card"
            onClick={() => navigate(
              RoutePath.OracleConfiguration.Company.replace(':companyName', 'Chainlink'),
            )}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              cursor: 'pointer',
              background:
                'radial-gradient(50% 561.12% at 100% 50%, rgba(26, 43, 107, 0.4) 0%, rgba(55, 91, 210, 0.4) 100%), linear-gradient(180deg, #3B4046 0%, #2D3037 100%)',
            }}
          >
            <Box sx={{ pr: 2 }}>
              <Typography>Configure</Typography>
              <Typography variant="h1" component="h2">
                Chainlink Oracle
              </Typography>
            </Box>
            <Box
              sx={{
                svg: {
                  width: 104,
                  height: 104,
                },
              }}
            >
              <CustomIcon icon="chainlink" />
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={12} lg={6} zeroMinWidth>
          <Paper
            variant="card"
            onClick={() => navigate(
              RoutePath.OracleConfiguration.Company.replace(':companyName', 'Maker'),
            )}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              p: '24px',
              cursor: 'pointer',
              background:
                'radial-gradient(50% 561.12% at 100% 50%, rgba(43, 183, 168, 0.2) 17.42%, rgba(253, 193, 52, 0.1) 100%), linear-gradient(180deg, #3B4046 0%, #2D3037 100%)',
            }}
          >
            <Box sx={{ pr: 2 }}>
              <Typography>Configure</Typography>
              <Typography variant="h1" component="h2">
                Maker Oracle
              </Typography>
            </Box>
            <Box
              sx={{
                '& > svg': {
                  width: 104,
                  height: 104,
                },
              }}
            >
              <MakerLogoSvg />
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={12} lg={6} zeroMinWidth>
          <Paper
            variant="card"
            onClick={() => navigate(
              RoutePath.OracleConfiguration.Company.replace(':companyName', 'Uniswap'),
            )}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              cursor: 'pointer',
              background:
                'radial-gradient(50% 561.12% at 100% 50%, rgba(126, 53, 124, 0.2) 0%, rgba(38, 50, 123, 0.2) 100%), linear-gradient(180deg, #3B4046 0%, #2D3037 100%)',
            }}
          >
            <Box sx={{ pr: 2 }}>
              <Typography>Configure</Typography>
              <Typography variant="h1" component="h2">
                Uniswap v3 TWAP Oracle
              </Typography>
            </Box>
            <Box
              sx={{
                svg: {
                  width: 104,
                  height: 104,
                },
              }}
            >
              <UniswapLogo />
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={12} lg={6} zeroMinWidth>
          <Paper
            variant="card"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              cursor: 'pointer',
            }}
          >
            <Box sx={{ pr: 2 }}>
              <Typography variant="h2" component="h2" maxWidth={200}>
                Request Support for an Oracle
              </Typography>
            </Box>
            <Box
              sx={{
                svg: {
                  width: 104,
                  height: 104,
                },
              }}
            >
              <CustomIcon icon="chat-dots" />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </MainLayout>
  );
});
