import { useEffect, useCallback, useMemo } from 'react';

import { Box } from '@chaos/ui/box';
import { Typography } from '@chaos/ui/typography';
import { Button } from '@chaos/ui/button';
import { useCountDown } from '@chaos/ui/hooks/use-countdown';

import { useLocation } from 'react-router-dom';
import { usePageTitle } from 'src/hooks';
import { PageTracker } from 'src/components/page-tracker';
import { recordError } from '../../utils/exceptions';
import { auth } from '../../services/firebase';
import { millisToMinutesAndSeconds } from '../../utils/formatters';
import { ACTION_CODE_SETTINGS } from '../signin-page';

const COUNTDOWN_INTERVAL = 1000; // interval to change remaining time amount, defaults to 1000

export const MagicLinkSentPage = PageTracker((): JSX.Element => {
  usePageTitle('Magic Link');
  const location = useLocation();

  const email = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get('email');
  }, [location.search]);

  const [timeLeft, { start }] = useCountDown(
    COUNTDOWN_INTERVAL,
  );

  const isStillCountingDown = timeLeft > 0;

  useEffect(() => {
    start();
  }, [start]);

  const restart = useCallback(() => {
    start();
  }, [start]);

  const onMagicLinkResendClick = () => {
    if (isStillCountingDown || !email) return;
    try {
      void auth.sendSignInLinkToEmail(email, ACTION_CODE_SETTINGS);
      restart();
    } catch (error) {
      recordError(error as Error);
    }
  };

  return (
    <Box
      px={3}
      sx={{
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'fex',
          justifyContent: 'center',
          paddingTop: 7,
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '454px',
          }}
        >
          <Typography
            sx={{
              marginBottom: 2,
            }}
            component="h4"
            variant="h4"
          >
            Magic Link Sent
          </Typography>

          <Typography
            sx={{
              marginBottom: 2,
            }}
            variant="subtitle1"
          >
            You can now close this window.
          </Typography>

          <Box>
            <Button
              onClick={onMagicLinkResendClick}
              disabled={isStillCountingDown}
              size="large"
              fullWidth
              color="primary"
            >
              Haven't received one? Send again
              {' '}
              {isStillCountingDown
                ? `in
              ${millisToMinutesAndSeconds(timeLeft)} 🪄`
                : '🪄'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});
