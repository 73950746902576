import { GRADIENT, HIDDEN_COLLAPSED_STYLES, LINEAR_TEXT } from 'src/components/app-nav/styles';

export const COLLAPSED_MENU_STYLES = {
  '& .MuiPaper-root': {
    overflow: 'visible',
    boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.16)',
    background: '#17191E',
  },

  '& ul::before': {
    content: "''",
    position: 'absolute',
    width: 8,
    height: 8,
    top: 26,
    left: -4,
    background: '#17191E',
    transform: 'rotate(-45deg)',
  },
};

export const getMultiNavTitleStyles = (
  isLinkActiveInClose: boolean,
) => ({
  display: 'inline-flex',
  position: 'relative',
  columnGap: 2,
  py: 2.5,
  '&.active span, &:hover span': LINEAR_TEXT,
  '&:not(.active):not(:hover)': {
    '& svg > path': { fill: isLinkActiveInClose ? '' : 'currentColor' },
  },
  '&:hover svg > path': {
    fill: '',
  },
  '&:hover': {
    '&:after': {
      background: GRADIENT,
    },
  },
  '&:after': {
    position: 'absolute',
    content: '""',
    opacity: 0,
    width: 0,
    height: 4,
    borderRadius: 2,
    background: isLinkActiveInClose ? GRADIENT : '#FFF',
    left: 34,
    top: 30,
    transition: 'width 0.3s ease, opacity 0.3s ease',
    '._nav-collapsed &': {
      opacity: 1,
      width: 4,
    },
  },
} as const);

export const MULTI_NAV_ITEM_STYLES = {
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'normal',
  whiteSpace: 'nowrap',
  py: 1.5,
  '&.active, &:hover': LINEAR_TEXT,
  '&.disabled': {
    opacity: 0.3,
    pointerEvents: 'none',
  },
  '&:not(.active):not(:hover) svg > path': {
    fill: 'currentColor',
  },
  fontSize: 14,
  ...HIDDEN_COLLAPSED_STYLES,
} as const;
