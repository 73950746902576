import {
  Box, Link, Paper, Typography,
} from '@chaos/ui';
import { Link as RouterLink } from 'react-router-dom';
import { RoutePath, RouteParams } from 'src/config/routes';

type Props = {
  id: string;
  name: string;
  description?: string;
  image?: string;
};
export const DashboardItem = ({
  id, name, description, image,
}: Props) => (
  <Link
    key={id}
    component={RouterLink}
    to={RoutePath.Dashboard.replace(RouteParams.Id, id)}
    sx={{
      color: 'inherit',
      textDecoration: 'inherit',
      width: 592,
      maxWidth: '100%',
      display: 'flex',
      justifyContent: 'center',
      overflow: 'hidden',
      mb: 3,
    }}
  >
    <Paper
      variant="card"
      sx={{
        p: 0,
        display: 'flex',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <Box display="flex" borderRight="1px solid #22252C">
        <img
          width={120}
          height={120}
          // eslint-disable-next-line
          src={require(`../../assets/img/community/tools/${image || 'grid'}.png`)}
          alt="tool-icon"
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        flex="1"
        padding={2}
      >
        <Typography variant="h2">{name}</Typography>
        {description && <Typography fontWeight={400}>{description}</Typography>}
      </Box>
    </Paper>
  </Link>
);
