import { FC } from 'react';

import { Box } from '@chaos/ui/box';
import { useMediaQuery, Theme } from '@chaos/ui/theme';
import { AppBar } from 'src/components/app-bar';
import { AppNav } from 'src/components/app-nav';
import { Loader } from '../loader';
import { AppMenuMobile } from '../app-menu-mobile';

interface PageLayoutProps {
  hideNav?: boolean
}

export const LoadingOverlay: FC<PageLayoutProps> = ({ hideNav = false }) => {
  const mdAndUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'stretch',
        width: '100%',
        height: '100%',
      }}
    >
      {mdAndUp && (
        <Box component="aside">
            {!hideNav && <AppNav />}
        </Box>
      )}
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          minWidth: 0,
          position: 'relative',
        }}
      >
        { (!mdAndUp && !hideNav) && <AppMenuMobile /> }
        <Box
          sx={{
            flexShrink: 0,
          }}
        >
          { !hideNav && <AppBar />}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            minHeight: 0,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loader />
        </Box>
      </Box>
    </Box>
  );
};
