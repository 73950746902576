import { FC, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { SimulationEvent } from '@chaos/types';
import { Box, Link } from '@chaos/ui';
import { RouteParams, RoutePath } from 'src/config/routes';
import {
  ColorText,
  DataBlock,
  EventInfoItem,
  GradientText,
  DecHexConverter,
  TopicList,
} from './building-components';

export const Event: FC<SimulationEvent> = ({
  simulationResultId,
  logIndex,
  address,
  name,
  args,
  topics,
}) => {
  const indexedArgs = useMemo(() => args.filter((arg) => arg.indexed), [args]);

  return (
    <Box
      component="li"
      sx={{
        display: 'flex',
        mt: 3,
        alignItems: 'start',
        pb: 3,
        position: 'relative',
        ':last-of-type': {
          pb: 0,
        },
      }}
    >
      <Box
        sx={{
          width: 40,
          height: 40,
          background: '#FFFFFF',
          borderRadius: 5,
          textAlign: 'center',
          color: '#17191E',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexShrink: 0,
        }}
      >
        {logIndex}
      </Box>
      <Box display="flex" flex={1} width={0} flexDirection="column" rowGap={1}>
        <EventInfoItem title="Address">
          <Link
            component={RouterLink}
            to={RoutePath.Simulations.BlockExplorer.Address
              .replace(RouteParams.SimulationResultId, simulationResultId)
              .replace(RouteParams.Address, address.toLowerCase())}
            underline="hover"
          >
            <GradientText>{address}</GradientText>
          </Link>
        </EventInfoItem>
        <EventInfoItem title="Name">
          <Box flex={1} width={0}>
            {name}
            {indexedArgs.length > 0 && ' ('}
            {indexedArgs.map((arg, i) => (
              <Box key={i} display="inline-flex" gap={1} ml={i > 0 ? 1 : 0}>
                {`index_topic_${i + 1}`}
                <ColorText color="success.main">{arg.type}</ColorText>
                <ColorText color="error.main">{arg.name}</ColorText>
                <Box ml={-1}>{i !== indexedArgs.length - 1 ? ',' : ')'}</Box>
              </Box>
            ))}
          </Box>
        </EventInfoItem>
        <EventInfoItem title="Topics">
          <TopicList topics={topics} />
        </EventInfoItem>
        <EventInfoItem title="Data">
          <DataBlock>
            {args.map((arg, i) => <DecHexConverter key={i} hexValue={arg.value} />)}
          </DataBlock>
        </EventInfoItem>
      </Box>
    </Box>
  );
};
