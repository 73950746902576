import { GetPageData } from 'src/services/engine';
import { formatAmount } from '@chaos/ui/utils/formatters';
import { HeatMapDatum, HeatMapSerie } from '@nivo/heatmap';
import { PriceManipulationResultType, FormValues } from './types';

export const factorOptions = (new Array(5).fill(null)).map((_, idx) => {
  const factor = idx + 1;

  return {
    value: `${factor}`,
    label: `${factor}x`,
  };
});

export const calculatePriceManipulationCost = async (values: FormValues, isV2?: boolean) => {
  const {
    baseAsset,
    market,
    manipulatedAsset,
    scaledExchangeLiquidity,
    scaledTokenMarketCap,
    potentialSellPressure,
    baseLtv,
    pumpedLtv,
  } = values;

  const version = isV2 ? 2 : 3;
  const query = {
    version,
    protocol: 'aave',
    base_asset: baseAsset,
    market: `${market.toLowerCase()}v${version}`,
    pumped_asset: manipulatedAsset,
    liquidity_factor: scaledExchangeLiquidity || 1,
    marketcap_factor: scaledTokenMarketCap || 1,
    dump_factor: potentialSellPressure || 0.1,
    baseLtv: baseLtv ? baseLtv / 100 : undefined,
    pumpedLtv: pumpedLtv ? pumpedLtv / 100 : undefined,
  };
  const result = await GetPageData<PriceManipulationResultType>(
    'aave_price_manipulation_post_cost_of_attack',
    query,
  );

  return result;
};

export const parseExploitResultToHeatmapData = (
  exploitResult: PriceManipulationResultType,
  selectedParam: 'NetProfit' | 'CapitalEfficiencyScore',
): HeatMapSerie<HeatMapDatum, object>[] => exploitResult.reduce((acc, currItem) => {
  const currItemId = formatAmount(currItem.AaveCapital, { currency: 'USD', notation: 'compact' });
  const currItemData = {
    x: `${currItem.PricePumpFactor}x`,
    y: currItem[selectedParam],
  };
  const currentHeatmapRowIdx = acc.findIndex((item) => item.id === currItemId);
  if (currentHeatmapRowIdx < 0) {
    const newAcc: HeatMapSerie<HeatMapDatum, object>[] = [
      ...acc,
      {
        id: currItemId,
        data: [
          currItemData,
        ],
      },
    ];

    return newAcc;
  }

  const newAcc: HeatMapSerie<HeatMapDatum, object>[] = [...acc];
  const currItemRow = newAcc[currentHeatmapRowIdx];
  const newItemRow = {
    id: currItemId,
    data: [
      ...currItemRow.data,
      currItemData,
    ],
  };
  newAcc[currentHeatmapRowIdx] = newItemRow;

  return newAcc;
}, [] as HeatMapSerie<HeatMapDatum, object>[]);
