import { TypeOfShape } from 'yup/lib/object';
import { AnyObject } from 'yup/lib/types';
import { BaseSchema } from 'yup';
import { useCallback, useMemo, useState } from 'react';

type PartialRecord<K extends keyof any, T> = Partial<Record<K, T>>;

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

type Schema = BaseSchema<
Record<string, unknown>,
AnyObject,
TypeOfShape<Record<string, BaseSchema>>>;

type ReturnType<T> = [
  (values: PartialRecord<keyof T, string>) => boolean,
  boolean,
  PartialRecord<keyof T, string>];

type ValidationErrorInfo<T> = { path: keyof T | undefined, errors: string[]; };

type ValidationError<T> = {
  inner: ValidationErrorInfo<T>[];
};

export const useValidation = <T>(validationSchema: Schema, initialValue?: T): ReturnType<T> => {
  type PartialObj = PartialRecord<keyof T, string>;
  const [errors, setErrors] = useState<PartialObj>({});

  const isFormValid = useMemo(() => Object.keys(errors).length === 0, [errors]);

  const getValidationObj = useCallback((values: PartialObj | T) => {
    if (!initialValue) {
      return values;
    }

    const entries = Object.entries(values) as Entries<PartialObj>;

    return entries.reduce((acum, [key, value]) => {
      if (initialValue[key] !== values[key]) {
        return { ...acum, [key]: value };
      }
      return acum;
    }, {});
  }, [initialValue]);

  const validate = useCallback((values: PartialObj) => {
    const objToValidate = getValidationObj(values);
    if (Object.keys(objToValidate).length === 0 && Object.keys(values).length !== 0) {
      return true;
    }
    try {
      validationSchema.validateSync(objToValidate, { abortEarly: false, strict: false });
      setErrors({});
      return true;
    } catch (e) {
      const validationErrors = e as ValidationError<PartialObj>;
      const errorsObj = validationErrors.inner.reduce<PartialObj>((obj, currError) => {
        if (currError.path !== undefined) {
          return { ...obj, [currError.path]: currError.errors.shift() };
        }
        return obj;
      }, {});
      setErrors(errorsObj);
      return false;
    }
  }, [getValidationObj, validationSchema]);

  return [validate, isFormValid, errors];
};
