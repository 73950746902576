import {
  FC, useCallback, useRef, useState,
} from 'react';
import {
  FieldArray,
  FieldArrayRenderProps,
  Form,
  Formik,
  FormikHelpers,
} from 'formik';
import * as Yup from 'yup';
import { isLoaded, useFirebase, useFirestore } from 'react-redux-firebase';

import { Box } from '@chaos/ui/box';
import { Button } from '@chaos/ui/button';
import { Typography } from '@chaos/ui/typography';
import { required } from 'src/utils/validators';
import { useAppSelector } from 'src/store';
import { COLLECTIONS } from '@chaos/utils';
import { Team, TeamMembership } from '@chaos/types';
import { v4 as uuidv4 } from 'uuid';
import { InputField, ReactSelectField } from '../fields';
import { CustomIcon } from '../custom-icon';

const validationSchema = Yup.object().shape({
  inviteMembers: Yup.array().of(
    Yup.object().shape({
      email: Yup.string().email().required(),
      role: required,
    }),
  ),
});

const initialValues = {
  inviteMembers: [
    {
      uuid: uuidv4(),
      email: '',
      role: '',
    },
  ],
};

interface ModalProps {
  onClose?: () => void
  team: Team
  memberships: TeamMembership[]
}

interface InviteMembersForm {
  inviteMembers: { uuid: string, email: string; role: string }[]
}

export const InviteMembersModal: FC<ModalProps> = (props): JSX.Element => {
  const fieldArrayHelpersRef = useRef<FieldArrayRenderProps | null>(null);
  const { onClose, memberships, team } = props;

  const [errorMessage, setErrorMessage] = useState<string>('');
  const addMember = () => {
    fieldArrayHelpersRef.current?.push({ uuid: uuidv4(), email: '', role: '' });
  };

  const removeMember = (index: number) => {
    fieldArrayHelpersRef.current?.remove(index);
  };

  const firebase = useFirebase();
  const firestore = useFirestore();

  const users = useAppSelector((state) => state.firestore.data.users);
  const isLoading = !isLoaded(users);

  const onSubmit = useCallback(async (
    values: InviteMembersForm,
    formikHelpers: FormikHelpers<InviteMembersForm>,
  ) => {
    try {
      setErrorMessage('');
      const idEmailMap = Object.entries(users)
        .reduce<Record<string, string>>(
        (acc, [id, { email }]) => (email ? { ...acc, [email]: id } : acc),
        {},
      );
      const ids = new Set(memberships.map((membership) => membership?.id));
      const newTeamMembers = values.inviteMembers.map((member) => {
        const userId = idEmailMap[member.email];

        if (!userId || ids.has(userId)) {
          setErrorMessage((state) => (state || `User ${member.email} doesn’t exist.`));
          return Promise.reject();
        }
        return firestore
          .collection(COLLECTIONS.TEAM_MEMBERSHIP)
          .doc(`${team.id}_${userId}`)
          .set({
            org: team.org,
            teamId: team.id,
            isAdmin: member.role === 'admin',
            team: firestore.collection(COLLECTIONS.TEAMS).doc(team.id),
            member: firestore.collection(COLLECTIONS.USERS).doc(userId),
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          });
      });
      await Promise.all(newTeamMembers)
        .then(() => {
          formikHelpers.setSubmitting(false);
          onClose?.();
        });
    } catch (e) {
      console.log(e);
    }
  }, [onClose, users, memberships, firestore, firebase, team]);

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values, isSubmitting, submitForm, isValid,
      }) => (
        <>
          <Box component="header">
            <Box className="modal-header" sx={{ py: '36px' }}>
              <Typography variant="h2" component="h2">
                Invite Members
              </Typography>
            </Box>
          </Box>
          <Form className="modal-body" autoComplete="off">
            {errorMessage && (
            <Box sx={{
              background: 'rgba(222, 68, 105, 0.5)',
              borderRadius: '16px',
              padding: '20px 24px',
              color: '#fff',
              fontSize: '16px',
              lineHeight: '24px',
              display: 'flex',
              maxWidth: '100%',
              overflow: 'hidden',
            }}
            >
              <CustomIcon sx={{ mr: '20px' }} icon="warning" />
              <p>{errorMessage}</p>
            </Box>
            )}
            <FieldArray
              name="inviteMembers"
              render={(fieldArrayProps) => {
                fieldArrayHelpersRef.current = fieldArrayProps;
                return (
                  <>
                    {values.inviteMembers.map((member, i, members) => {
                      const isShowDeleteButton = members.length > 1;
                      return (
                        <Box key={member.uuid} sx={{ display: 'flex' }}>
                          <Box sx={{ display: 'flex', flex: '1', alignItems: 'start' }}>
                            <Box sx={{ width: 248, mr: '24px' }}>
                              <InputField
                                label="Member Email"
                                name={`inviteMembers[${i}].email`}
                                type="email"
                                placeholder="Enter email"
                                fullWidth
                              />
                            </Box>

                            <Box sx={(isShowDeleteButton ? { flex: '0 1 175px', mr: '24px' } : { flex: '1 1' })}>
                              <ReactSelectField
                                label="Role"
                                name={`inviteMembers[${i}].role`}
                                placeholder="Select role"
                                isSearchable={false}
                                options={[
                                  { value: 'user', label: 'User' },
                                  { value: 'admin', label: 'Admin' },
                                ]}
                              />
                            </Box>
                          </Box>

                          {isShowDeleteButton && (
                          <Button
                            color="error"
                            sx={{
                              minWidth: 48,
                              borderRadius: '16px',
                              p: '12px',
                              alignSelf: 'start',
                              top: '32px',
                            }}
                            onClick={() => removeMember(i)}
                          >
                            <CustomIcon icon="trash-can" />
                          </Button>
                          )}
                        </Box>
                      );
                    })}
                  </>
                );
              }}
            />
          </Form>
          <Box component="footer">
            <Box className="modal-footer" sx={{ py: 5 }}>
              <Button color="secondary" onClick={addMember}>
                +1 Member
              </Button>
              <Button
                color="secondary"
                onClick={onClose}
                sx={{ ml: 'auto', mr: '24px' }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={isSubmitting || isLoading || !isValid}
                color="primary"
                onClick={() => {
                  void submitForm();
                }}
              >
                Invite
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Formik>
  );
};
