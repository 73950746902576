import {
  FC, useEffect, useRef, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  BlockchainSimulation, Header, RenderData, SimulationType,
} from '@chaos/types';
import { Box, CustomIcon } from '@chaos/ui';
import { IconButton } from '@chaos/ui/icon-button';
import {
  ChaosTable, FilterRange, FilterText, TableAppliedFilters, TableFilter,
} from '@chaos/ui/chaos-table';
import { RouteParams, RoutePath } from 'src/config/routes';
import { useCurrentTeam } from 'src/hooks';
import { chaosSnackbar } from 'src/utils/chaos-snackbar-utils';
import { formatDateOrTimeToday, formatDateAndTime } from 'src/utils/formatters';
import { usePermission } from 'src/hooks/usePermissions';
import { useAppSelector } from 'src/store';
import { SortChangeHandler } from 'src/hooks/usePagination';
import { runBlockchainSimulationFn } from '../dashboard/network';

type Props = {
  simulations: BlockchainSimulation[]
  onPageChange?: (page: number) => void
  onSortChange?: SortChangeHandler
  onFilter?: (idFilter?: string, createdAtFilter?: [number, number], nameFilter?: string) => void
  isLoading?: boolean
};

const headers: Header[] = [
  {
    renderType: 'FILTER',
    text: 'ID',
  },
  {
    renderType: 'TEXT',
    text: 'Name',
    nonSortable: true,
    width: '30%',
  },
  {
    renderType: 'TEXT',
    text: 'Created At',
    width: '10%',
    field: 'createdAt',
  },
  {
    renderType: 'TEXT',
    text: 'Description',
    width: '30%',
    nonSortable: true,
  },
  {
    renderType: 'TEXT',
    text: 'Duration (blocks)',
    width: '10%',
    nonSortable: true,
  },
  {
    renderType: 'TEXT',
    text: 'Type',
    width: '10%',
    nonSortable: true,
  },
  {
    renderType: 'EMPTY',
    text: '',
    width: '7%',
    nonSortable: true,
  },
  {
    renderType: 'EMPTY',
    text: '',
    width: '3%',
    nonSortable: true,
  },
];

const StopSimulationBtn: FC<{ disabled: boolean }> = ({ disabled }) => {
  if (disabled) {
    return null;
  }
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    }}
    >
      <CustomIcon
        icon="close-circle"
        sx={{
          width: 24,
          height: 24,
          'svg path': { fill: '#FFF' },
        }}
      />
    </Box>
  );
};

const getAppliedFilters = (
  initialFilters: (FilterText | FilterRange)[],
  id?: string,
  date?: [number, number],
  name?: string,
) => [{
  ...initialFilters[0],
  isApplied: id !== undefined,
  value: id,
}, {
  ...initialFilters[1],
  isApplied: date !== undefined && (
    date[0] !== (initialFilters[1] as FilterRange).range[0]
    || date[1] !== (initialFilters[1] as FilterRange).range[1]
  ),
  range: date || (initialFilters[1] as FilterRange).range,
}, {
  ...initialFilters[2],
  isApplied: name !== undefined,
  value: name,
}];

const initialFilters = [{
  type: 'text',
  fieldName: 'ID',
  fieldIndex: 0,
  isApplied: false,
} as FilterText, {
  type: 'range',
  fieldName: 'Created At',
  fieldIndex: 1,
  isApplied: false,
  range: [
    new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getTime(),
    new Date().getTime(),
  ],
  isDate: true,
} as FilterRange, {
  type: 'text',
  fieldName: 'Name',
  fieldIndex: 2,
  isApplied: false,
} as FilterText];

const SimulationsV2Table = ({
  simulations, onPageChange, onSortChange, onFilter, isLoading,
}: Props) => {
  const triggerPermission = usePermission('trigger_simualation');
  const [isTriggered, setIsTriggered] = useState<boolean>(!triggerPermission);
  const filterButtonRef = useRef<null | HTMLButtonElement>(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [idFilter, setIdFilter] = useState<string>();
  const [createdAtFilter, setCreatedAtFilter] = useState<[number, number]>();
  const [nameFilter, setNameFilter] = useState<string>();
  const [resetPagination, setResetPagination] = useState<string>();
  const team = useCurrentTeam();
  const navigate = useNavigate();
  const profile = useAppSelector((state) => state.firebase.auth);
  useEffect(
    () => {
      setResetPagination(uuidv4());
      onFilter?.(idFilter, createdAtFilter, nameFilter);
    },
    [onFilter, idFilter, createdAtFilter, nameFilter],
  );

  const runSimulation = async (id: string) => {
    if (!triggerPermission) {
      return;
    }
    const simulation = simulations.find((s) => s.id === id);
    if (!simulation) return;

    setIsTriggered(true);
    const resultId = await runBlockchainSimulationFn(id, profile.uid, team?.authKey, () => {});
    setIsTriggered(false);

    if (!resultId) return;

    chaosSnackbar.toast(
      'New simulation is now running. You can see it in the results tabs.',
      {
        icon: 'check-circle',
        onClick: () => navigate(RoutePath.Simulations.Results),
      },
    );
  };

  const handleOnClickRow = (rowIdx: number) => {
    const simulation = simulations[rowIdx];
    if (!simulation) {
      return RoutePath.Simulations.Home;
    }
    const simulationDetailsPath = RoutePath.Simulations.Details.replace(
      RouteParams.SimulationId,
      simulation.id,
    );
    return simulationDetailsPath;
  };

  const tableData: RenderData[][] = simulations.map((simulation) => [
    {
      renderType: 'FILTER',
      text: simulation.id,
    },
    {
      renderType: 'TEXT',
      text: simulation.name || simulation.description || simulation.id,
      tooltipText: simulation.name || '',
    },
    {
      renderType: 'TEXT',
      text: formatDateOrTimeToday(simulation.createdAt?.toDate(), false, true),
      value: simulation.createdAt?.toMillis(),
      tooltipText: formatDateAndTime(simulation.createdAt?.toDate(), false),
    },
    {
      renderType: 'TEXT',
      text: simulation.description || '-',
    },
    {
      renderType: 'TEXT',
      text: simulation.iterations ? `${simulation.iterations}` : '-',
      value: simulation.iterations || 0,
    },
    {
      renderType: 'TEXT',
      text: simulation.simulationType === SimulationType.ParameterConfiguration ? 'Multi' : 'Single',
    },
    {
      renderType: 'BUTTON',
      text: 'Run',
      onClick: async (e) => {
        e.stopPropagation();
        await runSimulation(simulation.id);
      },
      disabled: isTriggered,
    },
    {
      renderType: 'CUSTOM',
      component: <StopSimulationBtn disabled />,
      text: '',
    },
  ]);

  return (
    <>
      <ChaosTable
        headers={headers}
        data={tableData}
        initialSortBy={2}
        isInitialSortDesc
        rowHref={handleOnClickRow}
        isFullHeight
        pageSize={20}
        onPageChange={onPageChange}
        onSortChange={onSortChange}
        isLoading={isLoading}
        isFilterHidden
        customFilter={(
          <Box display="flex">
            <TableAppliedFilters
              filters={getAppliedFilters(initialFilters, idFilter, createdAtFilter, nameFilter)}
              removeFilter={(i) => {
                if (i === 0) {
                  setIdFilter(undefined);
                } else if (i === 1) {
                  setCreatedAtFilter(undefined);
                } else if (i === 2) {
                  setNameFilter(undefined);
                }
              }}
              headers={[
                { renderType: 'TEXT', text: 'ID' },
                { renderType: 'TEXT', text: 'Created At' },
                { renderType: 'TEXT', text: 'Name' },
              ]}
            />
            <IconButton
              color="secondary"
              ref={filterButtonRef}
              onClick={() => setIsFilterOpen(!isFilterOpen)}
              aria-label="Add Filter"
              data-testid="toggle-filter"
            >
              <CustomIcon icon="filter" />
            </IconButton>
          </Box>
        )}
        resetPagination={resetPagination}
      />
      <TableFilter
        isOpen={isFilterOpen}
        anchorEl={filterButtonRef.current}
        filters={initialFilters}
        onChange={(filterValues) => {
          const currFilters = Object.values(filterValues);
          setIdFilter((currFilters[0] as FilterText).value);
          setCreatedAtFilter((currFilters[1] as FilterRange).range);
          setNameFilter((currFilters[2] as FilterText).value);
        }}
        close={() => setIsFilterOpen(false)}
      />
    </>
  );
};

export default SimulationsV2Table;
