import { useMemo } from 'react';
import { useDataFetchersPageData } from 'src/hooks/useDataFetchersQuery';
import { AssetsDataResponse, MarketType } from './types';

type ReturnType = {
  isLoadingAssetsData: boolean,
  assetsDataResponse?: AssetsDataResponse
};

export const useAssetsData = (
  market: MarketType,
  baseAsset: string,
  manipulatedAsset: string,
  isV2?: boolean,
): ReturnType => {
  const version = isV2 ? 2 : 3;
  const queryObj = useMemo(() => (
    {
      version: isV2 ? 2 : 3,
      protocol: 'aave',
      market: market ? `${market.toLowerCase()}v${version}` : '',
      base_asset: baseAsset,
      pumped_asset: manipulatedAsset,
    }
  ), [isV2, market, version, baseAsset, manipulatedAsset]);

  const {
    response: assetsDataResponse,
    isLoading: isLoadingAssetsData,
  } = useDataFetchersPageData<AssetsDataResponse>('aave_price_manipulation_get_pair_data', queryObj);

  const response = useMemo(() => ({
    isLoadingAssetsData,
    assetsDataResponse,
  }), [assetsDataResponse, isLoadingAssetsData]);

  return response;
};
