import { Theme } from '@nivo/core';

export const theme = (tickSize: 'small' | 'large' = 'large'): Theme => ({
  background: 'transparent',
  axis: {
    ticks: {
      line: {
        stroke: '#555555',
      },
      text: {
        fontSize: tickSize === 'large' ? '16px' : '12px',
        fill: '#ffffff80',
      },
    },
    legend: {
      text: {
        fontSize: tickSize === 'large' ? '16px' : '12px',
        fill: '#ffffff80',
      },
    },
  },
  grid: {
    line: {
      stroke: '#ffffff33',
      strokeDasharray: '4 4',
    },
  },
  crosshair: {
    line: {
      stroke: '#9B9DA1',
      strokeDasharray: '1',
    },
  },
  labels: {
    text: {
      cursor: 'pointer',
    },
  },
});
