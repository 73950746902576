import { useEffect, useMemo, useState } from 'react';
import {
  ApolloClient, InMemoryCache, NormalizedCacheObject, gql,
} from '@apollo/client';
import {
  Button, CustomMultiReactSelect, CustomReactSelect, Grid, Typography,
} from '@chaos/ui';
import { DATA_QUERY_URL } from 'src/components/dashboard/utils/graphql';
import { MainLayout } from 'src/components/layouts';
import { postChainRecommendation } from 'src/services/engine';
import { useCurrentTeam } from 'src/hooks';

type Option = { value: string, label: string, icon?: string };

export const ParamRecommendationTriggerPage = () => {
  const protocols = [
    { value: 'aave', label: 'AAVE v3', cryptoIcon: 'aave' },
    { value: 'benqi', label: 'Benqi', cryptoIcon: 'benqi' },
    { value: 'venus', label: 'Venus', cryptoIcon: 'venus' },
  ];
  const [chains, setChains] = useState<{ chain: string, assets: string[] }[]>([]);
  const [markets, setMarkets] = useState<string[]>([]);
  const [protocol, setProtocol] = useState<Option>(protocols[0]);
  const [chain, setChain] = useState<Option>();
  const [market, setMarket] = useState<Option>();
  const [assets, setAssets] = useState<Option[]>();
  const [model, setModel] = useState<Option>({ value: 'LT_LB', label: 'LT_LB' });
  const [isPublishing, setIsPublishing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const apolloClient = useMemo(() => new ApolloClient<NormalizedCacheObject>({
    uri: `${DATA_QUERY_URL}query/ccar-lending`,
    cache: new InMemoryCache(),
    headers: {
      protocol: protocol.value,
    },
  }), [protocol]);
  useEffect(() => {
    void apolloClient.query<{
      allMarkets: {
        id: string, chain: string, borrowableAssets: string[], collateralableAssets: string[],
      }[]
    }>({
      query: gql`query AllMarket {
        allMarkets {
          id
          name
          chain
          totalBorrowUsd
          totalCollateralUsd
          totalSupplyUsd
          borrowableAssets
          collateralableAssets
          numberOfWalletsAtRisk
          totalCollateralAtRiskUsd
        }
      }`,
    }).then(({ data }) => {
      setChains(data.allMarkets.reduce<{ chain: string, assets: string[] }[]>((acc, m) => {
        const item = acc.find((a) => a.chain === m.chain);
        const currAssets = Array.from(new Set([...m.borrowableAssets, ...m.collateralableAssets]));

        if (item) {
          item.assets.push(...currAssets);
        } else {
          acc.push({ chain: m.chain, assets: currAssets });
        }

        return acc;
      }, []));
      setMarkets(data.allMarkets.map((m) => m.id));
    });
  }, [apolloClient]);
  useEffect(() => {
    setAssets(undefined);
    setMarket(undefined);
    setChain(undefined);
  }, [protocol]);
  useEffect(() => {
    setAssets(undefined);
  }, [protocol, chain]);
  const team = useCurrentTeam();

  const onSubmit = () => {
    if (team && protocol && market && chain) {
      setIsPublishing(true);
      setErrorMessage('');
      void postChainRecommendation(
        team.authKey!,
        protocol.value,
        market.value,
        chain.value,
        assets?.map((a) => a.value),
      ).then(() => setIsPublishing(false)).catch((e: Error) => {
        setIsPublishing(false);
        setErrorMessage(`Failed triggering model - ${e.message}`);
      });
    }
  };

  return (
    <MainLayout headerProps={{
      pageTitle: 'Run Model',
      suffixComponent: (
        <Button
          color="primary"
          disabled={isPublishing || !team || !market || !chain || !assets?.length}
          onClick={() => onSubmit()}
        >
          Generate Recommendation
        </Button>
      ),
    }}
    >
      <Typography color="red.main">{errorMessage}</Typography>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <CustomReactSelect
            label="Protocol"
            placeholder="Select Protocol"
            options={protocols}
            value={protocol}
            onChange={(o) => o && setProtocol(o)}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomReactSelect
            label="Market"
            placeholder="Select Market"
            options={markets
              .map((m) => ({ value: m, label: m, cryptoIcon: m.toLowerCase() }))}
            value={market || null}
            onChange={(o) => o && setMarket(o)}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomReactSelect
            label="Chain"
            placeholder="Select Chain"
            options={chains
              .map((c) => ({ value: c.chain, label: c.chain, cryptoIcon: c.chain.toLowerCase() }))}
            value={chain || null}
            onChange={(o) => o && setChain(o)}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomMultiReactSelect
            label="Asset(s)"
            placeholder="Select Asset(s)"
            controlShouldRenderValue
            options={chains.find((c) => c.chain === chain?.value)?.assets
              .map((c) => ({ value: c, label: c, cryptoIcon: c.toLowerCase() })) || []}
            value={assets || null}
            onChange={(os) => os && setAssets([...os])}
            allowSelectAll
          />
        </Grid>
        <Grid item xs={6}>
          <CustomReactSelect
            label="Model"
            placeholder="Select Model"
            options={[{ value: 'LT_LB', label: 'LT_LB' }]}
            value={model}
            onChange={(o) => o && setModel(o)}
          />
        </Grid>
      </Grid>
    </MainLayout>
  );
};
