export const colorScale = [
  '#1FA3D6',
  '#53B606',
  '#C435AE',
  '#E87C18',
  '#DE4469',
  '#30D4C1',
  '#9A22D2',
  '#0E55BF',
];
export const colorScaleWarm = [
  '#FFE814',
  '#E87C18',
  '#DE4469',
  '#CA1D1D',
  '#E44DBA',
  '#B8169F',
  '#9F064F',
  '#FF7395',

];
export const colorScaleCold = [
  '#9A22D2',
  '#0E55BF',
  '#1FA3D6',
  '#30D4C1',
  '#74E8A2',
  '#1BC960',
  '#53B606',
  '#90DE2D',
];

export const getFractionDigits = (number: number) : number => {
  switch (true) {
    case (number < 0.1):
      return 5;
    case (number < 1 && !Number.isInteger(number)):
      return 3;
    case (number < 100 && !Number.isInteger(number)):
      return 2;
    case (number < 1000 && !Number.isInteger(number)):
      return 1;
    default:
      return 0;
  }
};

export const formatPercent = (
  value: number,
  {
    minimumFractionDigits = 2, maximumFractionDigits = 2,
  } = { minimumFractionDigits: 2, maximumFractionDigits: 2 },
): string => new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits,
  maximumFractionDigits,
}).format(value);

export const formatAmount = (amount: number, {
  isPercent,
  currency,
  maximumFractionDigits = isPercent ? getFractionDigits(amount * 100) : getFractionDigits(amount),
  minimumFractionDigits,
  notation = 'compact',
}: {
  isPercent?: boolean,
  currency?: string,
  maximumFractionDigits?: number,
  minimumFractionDigits?: number,
  notation?: 'compact' | 'standard' | 'scientific' | 'engineering' | undefined,
} = {}) => new Intl.NumberFormat('en-US', {
  style: isPercent ? 'percent' : currency && 'currency',
  notation,
  currency,
  maximumFractionDigits,
  minimumFractionDigits,
}).format(amount);

export const formatAddressCompact = (address: string) => `${address.substring(0, 6)}...${address.substring(address.length - 6)}`;
