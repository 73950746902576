import { FC } from 'react';
import { Field, FieldProps } from 'formik';
import { InputActionMeta, Props as ReactSelectProps } from 'react-select';

import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { CustomReactSelect, OptionType } from '@chaos/ui/custom-select';
import { InputLabel } from '@chaos/ui/input-label';

type IsMulti = false;

interface ReactSelectFieldProps extends ReactSelectProps<OptionType, IsMulti> {
  name: string
  label?: string
  placeholder?: string
  options: OptionType[]
  allowInput?: boolean
  onChange?: (value: OptionType | string | null) => void
}

export const ReactSelectField: FC<ReactSelectFieldProps> = ({
  name,
  label,
  placeholder,
  options,
  onChange,
  allowInput,
  ...props
}): JSX.Element => (
  <>
    {label && (
      <InputLabel sx={{ opacity: props.isDisabled ? 0.3 : 1 }}>
        {label}
      </InputLabel>
    )}
    <Field name={name}>
      {({ field: { value, onBlur }, meta, form }: FieldProps) => {
        let error: string | undefined;
        if (meta && meta.touched && meta.error) {
          error = meta.error.replace(name, label || 'Value');
        }
        const selectValue = value
          ? options.find((option) => option.value === value)
          : undefined;
        return (
          <FormControl fullWidth error={error !== undefined}>
            <CustomReactSelect
              value={selectValue}
              inputValue={allowInput && !selectValue ? value as string : undefined}
              menuShouldBlockScroll
              menuPlacement="auto"
              placeholder={placeholder}
              options={options}
              onBlur={onBlur}
              onInputChange={
                allowInput
                  ? (newValue: string, newMeta: InputActionMeta) => {
                    if (
                      newMeta.action === 'set-value'
                        || newMeta.action === 'input-blur'
                        || newMeta.action === 'menu-close'
                    ) {
                      return;
                    }
                    form.setFieldValue(name, newValue);
                  }
                  : undefined
              }
              onChange={(newValue) => {
                onChange?.(newValue?.value || null);
                form.setFieldValue(name, newValue?.value);
              }}
              {...props}
            />
            {error && <FormHelperText>{error}</FormHelperText>}
          </FormControl>
        );
      }}
    </Field>
  </>
);
