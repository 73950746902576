import { FC } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box } from '@chaos/ui/box';
import { Link } from '@chaos/ui/link';
import { NavIcon } from 'src/components/nav-icon';
import { NavItemProps } from 'src/components/app-nav/types';
import { HIDDEN_COLLAPSED_STYLES } from 'src/components/app-nav/styles';
import { NAV_ITEM_STYLES } from './styles';

export const NavItem: FC<NavItemProps> = ({
  to, children, icon, className,
}) => (
  <Link
    to={to}
    component={RouterLink}
    color="inherit"
    underline="none"
    className={className}
    sx={NAV_ITEM_STYLES}
  >
    <NavIcon icon={icon} />
    <Box
      component="span"
      sx={{
        paddingLeft: '16px',
        ...HIDDEN_COLLAPSED_STYLES,
      }}
    >
      {children}
    </Box>
  </Link>
);
