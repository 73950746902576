import { actionTypes } from 'redux-firestore';
import { actionTypes as firebaseActionTypes } from 'react-redux-firebase';

interface Action {
  type: string
  meta: any
  payload: any
}

export function reducer<T>(reducerCollection: string):
(state: Record<string, T> | undefined, action: Action) =>Record<string, T> {
  const baseReducer = (
    // eslint-disable-next-line
    state: Record<string, T> = {},
    action: Action,
  ): Record<string, T> => {
    switch (action.type) {
      case actionTypes.LISTENER_RESPONSE:
      case actionTypes.GET_SUCCESS: {
      // eslint-disable-next-line
      const { collection } = action.meta;
        if (collection === reducerCollection) {
        // eslint-disable-next-line
        const { data } = action.payload;
          if (!data) {
            return state;
          }
          // eslint-disable-next-line
        return {
            ...state,
            ...data,
          };
        }
        return state;
      }

      case actionTypes.DOCUMENT_ADDED:
      case actionTypes.DOCUMENT_MODIFIED: {
      // eslint-disable-next-line
      const { collection, doc } = action.meta;
        if (collection === reducerCollection && doc) {
        // eslint-disable-next-line
        const { data } = action.payload;
          if (!data) {
            return state;
          }
          return {
            ...state,
            // eslint-disable-next-line
          [doc]: {
              ...data,
              // eslint-disable-next-line
            id: doc,
            },
          };
        }
        return state;
      }

      case actionTypes.DOCUMENT_REMOVED: {
      // eslint-disable-next-line
      const { collection, doc } = action.meta;
        if (collection === reducerCollection && doc) {
          const newState = { ...state };
          const key: string = doc as string;
          delete newState[key];
          return newState;
        }
        return state;
      }

      case firebaseActionTypes.LOGOUT:
        return {};

      default:
        return state;
    }
  };

  return baseReducer;
}
