import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { MainLayout } from 'src/components/layouts';
import { RoutePath } from 'src/config/routes';
import { useAppSelector } from 'src/store';
import { usePageTitle } from 'src/hooks';
import { useBlockchainAgents } from 'src/hooks/useBlockchainAgents';
import { EmptyState } from 'src/components/empty-state';
import { PageTracker } from 'src/components/page-tracker';
import { ScriptResource } from '@chaos/types';
import { ScriptResourceTable } from 'src/components/script-resource-table';

export const AgentPlaygroundPage = PageTracker((): JSX.Element => {
  usePageTitle('Agents');
  const navigate = useNavigate();
  const { isLoading, blockchainAgents } = useBlockchainAgents();
  const isAdmin = useAppSelector((state) => state.firebase.profile.role === 'admin');
  const isAgentsExists = blockchainAgents.length > 0;

  return (
    <MainLayout
      height={0}
      headerProps={{
        pageTitle: 'Agents',
        buttons: isAdmin ? [{
          children: 'Create Agent',
          onClick: () => navigate(RoutePath.AgentPlayground.Create),
        }] : undefined,
      }}
    >
      {isLoading && <CircularProgress />}
      {!isLoading && (isAgentsExists ? (
        <ScriptResourceTable
          isCircleAvatarHidden
          data={blockchainAgents}
          rowHref={(agent: ScriptResource) => RoutePath.AgentPlayground.Details.replace(':id', agent.id)}
        />
      ) : <EmptyState type="agents" />)}
    </MainLayout>
  );
});
