import { ExtendedStorageInstance, useFirebase } from 'react-redux-firebase';
import { v4 as uuidv4 } from 'uuid';
import { FileValue } from '@chaos/types';
import { useCallback, useMemo } from 'react';

type TypeUseFirebaseUpload = (o: { category: string }) => {
  uploadFile: (file: File) => Promise<FileValue>
  deleteFile: (file: FileValue) => ReturnType<ExtendedStorageInstance['deleteFile']>
};

const useFirebaseStorage:TypeUseFirebaseUpload = ({
  category,
}) => {
  const firebase = useFirebase();

  const uploadFile = useCallback((file: File) => {
    const extension = file.name.split('.').pop();
    const fileName = `${uuidv4()}-${new Date().getTime()}.${extension as string}`;

    return firebase
      .uploadFile(category, file, category, {
        name: fileName,
      })
      .then((result) => {
        const data = result as unknown as { downloadURL: string, id: string, File: {
          fullPath: string } };
        return {
          url: data.downloadURL,
          documentId: data.id,
          fullPath: data.File.fullPath,
        };
      });
  }, [category, firebase]);

  const deleteFile = useCallback(
    (file: FileValue) => firebase.deleteFile(file.fullPath),
    [firebase],
  );

  return useMemo(() => ({
    uploadFile,
    deleteFile,
  }), [
    uploadFile,
    deleteFile,
  ]);
};

export default useFirebaseStorage;
