import { FC } from 'react';
import { Box, Typography } from '@chaos/ui';
import { useSimulationTransactionEvents } from 'src/hooks/useSimulationTransactionEvents';
import { Loader } from '../loader';
import { EventList } from './event-list';

type EventsTabProps = {
  simulationResultId: string,
  transactionHash: string,
  connectionKey: string,
};

export const EventsTab: FC<EventsTabProps> = ({
  simulationResultId, transactionHash, connectionKey,
}) => {
  const { simulationTransactionEvents, isLoading } = useSimulationTransactionEvents(
    simulationResultId,
    transactionHash,
    connectionKey,
  );

  return isLoading ? <Loader /> : (
    <Box>
      <Typography variant="h2" component="h2">
        Transaction Receipt Event Logs
      </Typography>
      <Box display="flex" flexDirection="column">
        <EventList events={simulationTransactionEvents} />
      </Box>
    </Box>
  );
};
