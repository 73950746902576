export function loadString(key: string): string | null {
  try {
    return localStorage.getItem(key);
  } catch {
    // not sure why this would fail... even reading the RN docs I'm unclear
    return null;
  }
}

export function saveString(key: string, value: string): boolean {
  try {
    localStorage.setItem(key, value);
    return true;
  } catch {
    return false;
  }
}

export function load(key: string): unknown | null {
  try {
    const almostThere = localStorage.getItem(key);
    return JSON.parse(almostThere || '{}');
  } catch {
    return null;
  }
}

export function save(key: string, value: unknown): boolean {
  try {
    localStorage.setItem(key, JSON.stringify(value));
    return true;
  } catch {
    return false;
  }
}

export function remove(key: string): void {
  try {
    localStorage.removeItem(key);
  } catch {
    //
  }
}

export function clear(): void {
  try {
    localStorage.clear();
  } catch {
    //
  }
}
