import { useState } from 'react';
import * as StorageTypes from '@firebase/storage-types';
import { Box, CustomSwitch } from '@chaos/ui';
import { Typography } from '@chaos/ui/typography';
import { Paper } from '@chaos/ui/paper';
import { Grid } from '@chaos/ui/grid';
import { formatDateAndTime } from 'src/utils/formatters';
import { Contract, Nullable } from '@chaos/types';
import { StatusChip } from '../status-chip';
import { JSONViewer } from '../json-viewer';

const formatContractName = (contractName: string): string => {
  const name = contractName.split('-')[0];

  return name.length > 16 ? `${name.slice(0, 16)}...` : name;
};

export const DeploymentRow = ({ contract }: {
  contractMetadata?: StorageTypes.FullMetadata
  contract: Contract & { deployedBy?: Nullable<string> }
}): JSX.Element => {
  const [isChecked, setIsChecked] = useState(true);

  const contractStatus = contract.status
    ? `${contract.status[0].toUpperCase()}${contract.status.slice(1)}`
    : 'Uploaded';

  return (
    <Paper
      variant="card"
      sx={{
        padding: '20px 24px 24px',
        mt: 1,
      }}
    >
      <Grid container wrap="nowrap" alignItems="center" columnSpacing={3}>
        <Grid item zeroMinWidth flex="1 0">
          <Typography variant="caption">Contract Name</Typography>
          <Typography>
            <b>{formatContractName(contract.contractName)}</b>
          </Typography>
        </Grid>
        <Grid item zeroMinWidth flex="1 0">
          <Typography variant="caption">Deploy Date</Typography>
          <Typography sx={{ fontWeight: 'fontWeightLight' }} noWrap title={formatDateAndTime(contract.createdAt?.toDate())}>
            {formatDateAndTime(contract.createdAt?.toDate())}
          </Typography>
        </Grid>
        <Grid item zeroMinWidth flex="1 0">
          <Typography variant="caption">Address</Typography>
          <Typography sx={{ fontWeight: 'fontWeightLight' }} noWrap title={contract.address}>
            {contract.address}
          </Typography>
        </Grid>
        <Grid item zeroMinWidth flexBasis={124}>
          <Typography variant="caption">Status</Typography>
          <Box>
            <StatusChip label={contractStatus} status={contract.status} />
          </Box>
        </Grid>
        <Grid item zeroMinWidth flex="1 0">
          <Typography variant="caption">Deployed by</Typography>
          <Typography sx={{ fontWeight: 'fontWeightLight' }} noWrap>
            {contract?.deployedBy || '-'}
          </Typography>
        </Grid>
      </Grid>

      <Box sx={{ marginTop: '20px' }}>
        <Box display="flex" alignItems="center" gap={2}>
          <Typography>Source Code</Typography>
          <CustomSwitch
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
          />
          <Typography>ABI Code</Typography>
        </Box>

        <Box sx={{ mt: 1 }}>
          {isChecked && (
          <JSONViewer
            maxHeight={244}
            json={contract.abi as unknown as Record<string, unknown>[]}
            bgColor="#22252C"
          />
          )}

          {!isChecked && (
          <Box p={2} borderRadius={2} bgcolor="#263238" height={280} overflow="auto">
            <Typography>Please deploy contract to see source code</Typography>
          </Box>
          )}
        </Box>
      </Box>
    </Paper>
  );
};
