import { useContext, useMemo } from 'react';
import { useFirestoreConnect, isLoaded, useFirestore } from 'react-redux-firebase';
import { TeamContext } from 'src/context/team-context';
import {
  Team, Nullable, Contract, DashboardPayload,
} from '@chaos/types';
import { COLLECTIONS } from '@chaos/utils';
import { useAppSelector } from 'src/store';
import { useCurrentTeam } from './useCurrentTeam';
import { isCollectionLoaded } from './utils';

export { useTickerSvg } from './useTickerSvg';
export { useRandomAvatar } from './useRandomAvatar';
export { useCurrentTeam } from './useCurrentTeam';
export { useTeams } from './useTeams';
export { useBlockchainSimulations } from './useBlockchainSimulations';
export { useBlockchainSimulationResult } from './useBlockchainSimulationResult';
export { useBlockchainSimulationResults } from './useBlockchainSimulationResults';
export { FetchStorageBlobs } from './fetchStorageBlobs';
export { useDashboards } from './useDashboards';
export { usePublicDashboardsBySubDomain } from './usePublicDashboardsBySubDomain';
export { usePageTitle } from './usePageTitle';
export { usePagination } from './usePagination';
export { useGridRowView } from './useGridRowView';
export { useFallbackUserAvatar } from './useFallbackUserAvatar';

export const useContracts = (): {
  isLoading: boolean
  contracts: Contract[]
} => {
  const firestore = useFirestore();
  const team = useCurrentTeam();
  useFirestoreConnect([
    {
      collection: COLLECTIONS.CONTRACTS,
      orderBy: ['createdAt', 'desc'],
      where: [
        ['team', '==', firestore.collection(COLLECTIONS.TEAMS).doc(team?.id)],
      ],
    },
  ]);

  const { contracts, isLoading } = useAppSelector((state) => ({
    contracts: state.firestore.ordered.contracts,
    isLoading: !isCollectionLoaded(COLLECTIONS.CONTRACTS, state),
  }));

  return { isLoading, contracts: contracts || [] };
};

export const useAllTeams = (): {
  isLoading: boolean
  allTeams: Array<Team & { count: number; admins: Nullable<string>[] }>
} => {
  const { teams } = useContext(TeamContext);
  const { email } = useAppSelector((state) => state.firebase.auth);

  useFirestoreConnect([
    {
      collection: COLLECTIONS.USERS,
    },
    {
      collection: COLLECTIONS.TEAM_MEMBERSHIP,
      where: ['teamId', 'in', teams.map((team) => team.id)],
    },
  ]);

  const allTeams = useAppSelector((state) => teams.map((team) => {
    const memberships = Object.values(state.teamMemberships).filter(
      (membership) => membership.team.id === team.id,
    );
    const admins = memberships.filter((membership) => membership.isAdmin);
    const adminEmails = admins.map(({ member }) => {
      const userId = member.id;
      const { users } = state.firestore.data;

      return users && users[userId]?.email;
    });

    return {
      ...team,
      count: memberships.length,
      admins: adminEmails,
      isAdmin: adminEmails.includes(email),
    };
  }));

  const isLoading = !isLoaded(allTeams);

  return { isLoading, allTeams };
};

export const useCommunityDashboard = (
  id?: string,
): {
  isLoading: boolean
  dashboards: DashboardPayload[]
} => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.COMMUNITY_DASHBOARDS,
      doc: id,
    },
  ]);

  const { dashboards, isLoading } = useAppSelector((state) => ({
    dashboards: state.firestore.ordered.community_dashboards,
    isLoading: !isCollectionLoaded(COLLECTIONS.COMMUNITY_DASHBOARDS, state),
  }));
  const memoedDashboards = useMemo(
    () => (dashboards ? [...dashboards].sort((a, b) => (a.name > b.name ? 1 : -1)) : []),
    [dashboards],
  );

  return {
    isLoading,
    dashboards: memoedDashboards,
  };
};
