import { Box, Paper } from '@chaos/ui';
import { ExplorerLtScanningResponse, HealthHistogramItem } from '../../types';
import HealthHistogram from './health-histogram';
import LtScanningChart from './lt-scanning-chart';

type Props = {
  healthHistogramData: HealthHistogramItem[];
  ltScanningData?: ExplorerLtScanningResponse;
  isLoadingScanningResult: boolean;
};
const ChartsSection = ({ healthHistogramData, ltScanningData, isLoadingScanningResult }: Props) => (
  <Paper sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }} variant="card">
    <Box flex={1} width={0} minWidth={500}>
      <LtScanningChart
        isLoading={isLoadingScanningResult}
        data={ltScanningData}
      />
    </Box>
    <Box flex={1} width={0} minWidth={500}>
      <HealthHistogram
        walletsHealth={healthHistogramData}
      />
    </Box>
  </Paper>
);

export default ChartsSection;
