import { useEffect, useMemo, useState } from 'react';
import { Subscription, throttleTime } from 'rxjs';
import {
  collection, doc, orderBy, query, where,
} from 'firebase/firestore';
import { collectionData } from 'rxfire/firestore';
import { ScriptResource } from '@chaos/types';
import { COLLECTIONS } from '@chaos/utils';
import { firestore } from 'src/services/firebase';
import { useCurrentTeam } from './useCurrentTeam';

export const useBlockchainObservers = (): {
  isLoading: boolean
  blockchainObservers: ScriptResource[]
} => {
  const team = useCurrentTeam();
  const [observers, setObservers] = useState<ScriptResource[]>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const subs: Subscription[] = [];
    const observersCol = collection(firestore, COLLECTIONS.BLOCKCHAIN_SIMULATION_OBSERVERS);
    const observersObs = collectionData(query(
      observersCol,
      where('team', '==', doc(collection(firestore, COLLECTIONS.TEAMS), team?.id)),
      orderBy('createdAt', 'desc'),
    ), { idField: 'id' }).pipe(throttleTime(5000));
    subs.push(observersObs.subscribe((data) => {
      setIsLoading(false);
      setObservers(data as ScriptResource[]);
    }));

    return () => subs.forEach((s) => s.unsubscribe());
  }, [team?.id]);
  const result = useMemo(
    () => ({ isLoading, blockchainObservers: observers || [] }),
    [isLoading, observers],
  );

  return result;
};
