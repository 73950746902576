export const PROFILE_SECTION_STYLES = {
  py: 2,
  display: 'flex',
  alignItems: 'center',
  columnGap: 2,
  height: '64px',
  cursor: 'pointer',
  position: 'relative',
  '&:after': {
    position: 'absolute',
    content: '""',
    opacity: 0,
    width: 0,
    height: 4,
    borderRadius: 2,
    background: '#FFF',
    left: 38,
    bottom: 28,
    transition: 'width 0.3s ease, opacity 0.3s ease',
    zIndex: 3,
    '._nav-collapsed &': {
      opacity: 1,
      width: 4,
    },
  },
  '& .profile-img': {
    border: '1px solid rgba(255, 255, 255, 0.3)',
    borderRadius: '50%',
    width: '32px',
    height: '32px',
    transition: 'all 0.3s ease',
    position: 'relative',
    '._nav-collapsed &': {
      width: '24px',
      height: '24px',
    },
  },
} as const;

export const PROFILE_MENU_STYLES = {
  '& .MuiPaper-root': {
    overflow: 'visible',
    boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.16)',
    background: '#17191E',
    '& .MuiList-root': {
      py: 0,
    },
  },

  '& ul::before': {
    content: "''",
    position: 'absolute',
    width: 8,
    height: 8,
    bottom: 40,
    left: -4,
    background: '#17191E',
    transform: 'rotate(-45deg)',
  },
} as const;
