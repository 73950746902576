import { useQuery } from '@apollo/client';
import {
  Box, Button, Paper, Alert,
} from '@chaos/ui';
import { useFormik } from 'formik';
import { Loader } from '@chaos/ui/loader';
import { Editor } from '@chaos/ui/editor';
import { useEffect, useState } from 'react';
import { RunPythonSimulation } from 'src/services/engine';
import { useCurrentTeam } from 'src/hooks';
import { communityRecommendationResultsURL } from '@chaos/utils';
import { MarketType, FormValues } from './types';
import { MarketAssetSelects } from './components';
import { GET_MARKET_ASSETS, AssetsBreakdownResponse } from '../graphql-queries';

const defaultRequest = {
  simulationID: 'tQXIrYYSRQXZTQAAb65q',
  type: 'AaveSimulation',
  params: {
    chainName: 'Avalanche',
    sweepAsset: 'WETH.e',
    blockNumber: 24453296,
    version: 'v3',
    nSims: 1,
    debug: false,
    healthUpperThreshold: 3,
    totalBorrowsThreshold: 100,
    seed: 892677,
    liquidatatorEnabled: true,
    historicalPricesEnabled: false,
    baseAsset: 'WMATIC',
    quoteAsset: 'USDC.e',
    maxDump: 8e6,
    liquidationThresholdMinRange: -0.3,
    liquidationThresholdMaxRange: 0.3,
    liquidationThresholdNSamples: 2,
    liquidationBonusMinRange: -0.2,
    liquidationBonusMaxRange: 0.2,
    liquidationBonusNSamples: 2,
    startTime: '2022-12-01T00:00:00.000Z',
    endTime: '2023-01-01T00:00:00.000Z',
    nBlocks: 100,
    volScalar: 1,
    oracleHeartbeat: 110,
    archVals: { omega: 0.0057, alpha: 0.2, beta: 0.78 },
    volatileAssets: ['btcb', 'wavax', 'wethe', 'linke', 'aavee'],
    healthLowerThreshold: 0.98,
    initialPositionFilteringParams: {
      minCollateralUsd: 1,
      minBorrowUsd: 100,
      maxHealth: 1.5,
      includeEMode: false,
      includeIsoMode: false,
    },
  },
};

type RequestType = typeof defaultRequest;

const PythonSimulation = () => {
  const team = useCurrentTeam();

  const {
    loading: isLoading,
    data: marketsAssets,
  } = useQuery<AssetsBreakdownResponse>(GET_MARKET_ASSETS);
  const [jsonData, setJsonData] = useState(
    JSON.stringify(defaultRequest, undefined, 2),
  );
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [resultUrl, setResultUrl] = useState('');

  const markets = Array
    .from(new Set(marketsAssets?.assetsBreakdown.map((m) => m.market) as MarketType[]));
  const initialMarket = markets?.[0];
  const initialSweepAsset = marketsAssets?.assetsBreakdown.find((a) => a.market === initialMarket);

  const initialValues: FormValues = {
    market: initialMarket || '',
    sweepAsset: initialSweepAsset?.asset || '',
  };

  // Reset data when changing teams
  useEffect(() => {
    setError(undefined);
    setResultUrl('');
  }, [team?.id]);

  const clear = () => {
    setError(undefined);
  };

  const onSubmit = () => {
    clear();
    setLoading(true);
    const authKey = team?.authKey;
    if (!authKey) {
      return;
    }
    RunPythonSimulation(JSON.parse(jsonData), authKey)
      .then(({ simulationResultID }) => {
        setLoading(false);
        const url = communityRecommendationResultsURL({
          client: 'aave',
          resultID: simulationResultID,
        });
        setResultUrl(url);
      })
      .catch((e) => {
        setLoading(false);
        if (e instanceof Error) {
          setError(e.message);
        }
      });
  };

  const onFormControlChange = (newParams: Partial<RequestType['params']>) => {
    const data = JSON.parse(jsonData) as RequestType;
    const updatedData: RequestType = {
      ...data,
      params: {
        ...data.params,
        ...newParams,
      },
    };
    setJsonData(JSON.stringify(updatedData, undefined, 2));
  };

  const {
    handleSubmit,
    setFieldValue,
    values: { market, sweepAsset },
  } = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
  });

  if (isLoading || !marketsAssets) return <Loader />;

  return (
    <Paper key={team?.id} variant="card">
      <form onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column" gap={3}>
          <MarketAssetSelects
            markets={markets}
            market={market}
            sweepAsset={sweepAsset}
            marketsAssets={marketsAssets
              ?.assetsBreakdown.filter((a) => a.market === market).map((a) => a.asset)}
            onSelectMarket={async (selectedMarket: MarketType) => {
              const newSweepAsset = marketsAssets
                ?.assetsBreakdown.find((a) => a.market === selectedMarket)?.asset;
              onFormControlChange({
                chainName: selectedMarket,
                sweepAsset: newSweepAsset,
              });
              await setFieldValue('market', selectedMarket);
              await setFieldValue('sweepAsset', newSweepAsset);
            }}
            onSelectSweepAsset={async (selectedAsset: string) => {
              onFormControlChange({
                sweepAsset: selectedAsset,
              });
              await setFieldValue('sweepAsset', selectedAsset);
            }}
          />
          <Box
            bgcolor="background.default"
            borderRadius={1}
            height="60vh"
            py={3}
          >
            <Editor
              value={jsonData}
              onChange={(value) => value && setJsonData(value)}
              language="json"
            />
          </Box>

          <Box display="flex" justifyContent="flex-end" gap={2}>
            {resultUrl && <Alert severity="success">{resultUrl}</Alert>}
            {error && <Alert severity="error">{error}</Alert>}
            <Button type="submit" color="primary" disabled={loading}>
              Run
            </Button>
          </Box>
        </Box>
      </form>
    </Paper>
  );
};

export default PythonSimulation;
