import { LINEAR_TEXT } from 'src/components/app-nav/styles';

export const NAV_ITEM_STYLES = {
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'fontWeightBold',
  whiteSpace: 'nowrap',
  '& svg': {
    flex: '0 0 24px',
  },
  '&:not(.active):not(:hover) svg > path': {
    fill: 'currentColor',
  },
  '&.active span, &:hover span': LINEAR_TEXT,
  '&.disabled': {
    opacity: 0.3,
    pointerEvents: 'none',
  },
} as const;
