import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'react-redux-firebase';
import { Box } from '@chaos/ui/box';
import { Paper } from '@chaos/ui/paper';
import { TextField } from '@chaos/ui/text-field';
import { Typography } from '@chaos/ui/typography';
import { Button } from '@chaos/ui/button';
import { auth } from 'src/services/firebase';
import { loadString } from 'src/utils/storage';
import { useAppSelector } from 'src/store';
import { usePageTitle } from 'src/hooks';
import { PageTracker } from 'src/components/page-tracker';
import { SignInLayout } from 'src/components/layouts';
import { Loader } from 'src/components/loader';
import { RoutePath } from 'src/config/routes';

export const MagicLinkReceivePage = PageTracker((): JSX.Element => {
  usePageTitle('Magic Link');
  const [email, setEmail] = useState(loadString('authEmail'));
  const [userEmail, setUserEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const authData = useAppSelector((state) => state.firebase.auth);
  const isExist = !isEmpty(authData);
  const isSignInWithEmail = auth.isSignInWithEmailLink(window.location.href);
  if (!email || !isSignInWithEmail) {
    navigate(RoutePath.Welcome);
  }

  useEffect(() => {
    const handleSignInWithEmail = async () => {
      if (email) {
        try {
          await auth.signInWithEmailLink(email, window.location.href);
          navigate('/');
        } catch (error) {
          setEmail(null);
          setErrorMessage((error as { message: string }).message);
        }
      }
    };

    void handleSignInWithEmail();
  }, [navigate, isExist, email]);

  return (
    <SignInLayout>
      {email ? <Loader /> : (
        <Paper variant="card" sx={{ p: { xs: 2, sm: 5 }, margin: { xs: 0, sm: 'auto' } }}>
          <Box>
            <Typography variant="h2" mb={3}>Looks like you're using a different browser, which email did you use to sign in?</Typography>
            <form onSubmit={(e) => e.preventDefault()} style={{ display: 'flex' }} autoComplete="off">
              <TextField
                autoFocus
                name="email"
                placeholder="Email"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                error={!!errorMessage}
                fullWidth
                sx={{ mr: 1 }}
              />
              <Button
                type="submit"
                color="secondary"
                disabled={!userEmail}
                onClick={() => setEmail(userEmail)}
              >
                Submit
              </Button>
            </form>
            <Typography variant="caption" color="error.main" mt={1}>{errorMessage}</Typography>
          </Box>
        </Paper>
      )}
    </SignInLayout>
  );
});
