import {
  Box,
  Button,
  Typography,
} from '@chaos/ui';
import { useFormik, FormikProvider, FieldArray } from 'formik';
import { wrappedSymbolToIconSymbol } from 'src/utils/icon-helper';
import { useState } from 'react';
import { MarketType } from '../../AssetProtectionTool/types';
import { rangeErrorMsg } from '../../utils';
import {
  AssetValues,
  FormValues,
  ExplorerParamChangeResponse,
  ExplorerLtScanningResponse,
  AssetOptions,
} from '../types';
import { getChangeResult, getScanningResult } from '../utils';
import { ParamExplorerFormRow } from './param-explorer-form-row';

type Props = {
  marketsAssetsMapping: Record<MarketType, string[]>
  setResult: (result: ExplorerParamChangeResponse) => void
  setIsLoading: (isLoading: boolean) => void
  setScanningResult: (result: ExplorerLtScanningResponse) => void
  setIsLoadingScanningResult: (isLoading: boolean) => void
};

const ParamExplorerForm = ({
  marketsAssetsMapping,
  setResult,
  setIsLoading,
  setIsLoadingScanningResult,
  setScanningResult,
}: Props) => {
  const [defaultLts, setDefaultLts] = useState<Record<string, number>>({});

  const initialAsset: AssetValues = {
    asset: marketsAssetsMapping.Ethereum[0] || '',
    liquidationThreshold: 0,
  };
  const initialValues: FormValues = {
    assetsValues: [initialAsset],
  };

  const validate = ({ assetsValues }: FormValues) => {
    const errors: Partial<Record<string, (string | undefined)[]>> = {};

    const assetsValuesErrors = assetsValues.map(({ liquidationThreshold }) => {
      const liquidationThresholdError = rangeErrorMsg(
        liquidationThreshold || 0,
        0,
        100,
      );
      if (liquidationThresholdError) return liquidationThresholdError;
      return undefined;
    });

    if (assetsValuesErrors.some((err) => !!err)) {
      errors.assetsValues = assetsValuesErrors;
    }

    return errors;
  };

  const onSubmit = async (values: FormValues) => {
    setIsLoading(true);
    const result = await getChangeResult(values, defaultLts);
    setIsLoading(false);
    if (!result) return;
    setResult(result);

    setIsLoadingScanningResult(true);
    const scanningResult = await getScanningResult(values);
    setIsLoadingScanningResult(false);
    if (!scanningResult) return;
    setScanningResult({ ...scanningResult, 0: result.liquidatedWallets });
  };

  const assetsOptions: AssetOptions = marketsAssetsMapping.Ethereum?.map((a) => ({
    value: a,
    label: a,
    cryptoIcon: wrappedSymbolToIconSymbol(a.toLowerCase()),
  })) || [];

  const formik = useFormik<FormValues>({
    initialValues,
    onSubmit,
    enableReinitialize: true,
    validate,
  });

  const {
    setFieldValue,
    isSubmitting,
    isValid,
    handleSubmit,
    errors,
    values: { assetsValues },
  } = formik;

  return (
    <Box display="flex" gap={2} maxWidth={1170}>
      <Box flexGrow={1}>
        <FormikProvider value={formik}>
          <FieldArray
            name="assetsValues"
            render={(arrayHelpers) => (
              <Box display="flex" flexDirection="column" gap={2} maxWidth={1170}>
                {assetsValues.map(({ asset, liquidationThreshold }, index) => (
                  <ParamExplorerFormRow
                    key={`${asset}-${index}`}
                    options={assetsOptions}
                    asset={asset}
                    liquidationThreshold={liquidationThreshold}
                    index={index}
                    error={(errors?.assetsValues as string[])?.[index]}
                    canAddRow={
                      assetsValues.length < marketsAssetsMapping.Ethereum.length
                    }
                    setDefaultLts={setDefaultLts}
                    setFieldValue={async (key, value) => {
                      await setFieldValue(key, value);
                    }}
                    onAddRow={() => arrayHelpers.push({
                      asset:
                          marketsAssetsMapping.Ethereum[assetsValues.length] || '',
                      liquidationThreshold: 0,
                    })}
                    onRemoveRow={(indexToRemove) => arrayHelpers.remove(indexToRemove)}
                  />
                ))}
              </Box>
            )}
          />
        </FormikProvider>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Button
          disabled={!isValid || isSubmitting}
          color="primary"
          onClick={() => handleSubmit()}
          type="submit"
          sx={{
            width: 248,
            height: 48,
            mt: 3,
          }}
        >
          <Typography component="span">Apply</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default ParamExplorerForm;
