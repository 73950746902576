import { FormikHelpers } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  isLoaded,
  useFirestore,
  useFirestoreConnect,
} from 'react-redux-firebase';
import { CircularProgress } from '@chaos/ui/circular-progress';
import { MainLayout } from 'src/components/layouts';
import { RoutePath } from 'src/config/routes';
import {
  InviteMembersModal,
  ModalWrapper,
  RemoveMemberModal,
} from 'src/components/modals';
import { useAppSelector } from 'src/store';
import { COLLECTIONS } from '@chaos/utils';
import { TeamForm, TeamFormValues } from 'src/components/team-form';
import { useTeams, usePageTitle } from 'src/hooks';
import { PageTracker } from 'src/components/page-tracker';

export interface TeamMemberData {
  email: string
  firstName: string
  lastName: string
}

export const TeamEditPage = PageTracker((): JSX.Element => {
  usePageTitle('Team Edit');
  const [openModal, setOpenModal] = useState<string | null>(null);
  const [memberData, setMemberData] = useState<TeamMemberData | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const closeModal = () => setOpenModal(null);

  // Hack to get around this bug - https://github.com/prescottprue/react-redux-firebase/issues/914
  useEffect(() => {
    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  const openRemoveModal = useCallback((member: TeamMemberData) => {
    setMemberData(member);
    setOpenModal('remove');
  }, [setMemberData, setOpenModal]);

  const firestore = useFirestore();
  const { teamId } = useParams<{ teamId: string }>();
  useFirestoreConnect([
    {
      collection: COLLECTIONS.USERS,
    },
    {
      collection: COLLECTIONS.TEAM_MEMBERSHIP,
      where: ['teamId', '==', teamId],
    },
  ]);

  const teams = useTeams();
  const currentTeam = teams?.find((t) => t.id === teamId);
  const team = useAppSelector((state) => {
    const memberships = state.firestore.ordered.team_membership || [];
    const teamMembers = memberships.map((membership) => {
      const { users } = state.firestore.data;
      const teamMember = users && users[membership.member.id];
      return {
        ...teamMember,
        isAdmin: membership.isAdmin,
        membershipId: membership.id,
      };
    });
    return {
      ...currentTeam,
      members: teamMembers,
      memberships,
      isLoading: isLoading || !isLoaded(memberships),
    };
  });

  const onSubmit = useCallback(async (
    values: TeamFormValues,
    formikHelpers: FormikHelpers<TeamFormValues>,
  ): Promise<void> => {
    try {
      const updateTeamData = firestore
        .collection(COLLECTIONS.TEAMS)
        .doc(teamId)
        .update({
          name: values.name,
          protocolBlockchain: values.protocolBlockchain,
          protocolWebsite: values.protocolWebsite,
        });

      const updateMembersData = team.memberships.map((membership, index) => firestore
        .collection(COLLECTIONS.TEAM_MEMBERSHIP)
        .doc(membership.id)
        .update({
          isAdmin: values.members[index].role === 'admin',
        }));

      await Promise.all([updateTeamData, ...updateMembersData]);
    } catch (e) {
      console.log(e);
    } finally {
      formikHelpers.setSubmitting(false);
    }
  }, [firestore, team, teamId]);

  const formValues: TeamFormValues = {
    id: team?.id || '',
    thumbnail: team.thumbnail,
    name: team?.name || '',
    protocolBlockchain: team.protocolBlockchain || '',
    protocolWebsite: team.protocolWebsite,
    members: team.members.map((member) => ({
      email: member.email || '',
      firstName: member.firstName,
      lastName: member.lastName,
      role: member.isAdmin ? 'admin' : 'user',
    })),
  };

  const onRemoveMember = () => {
    const member = team.members.find(
      (currMember) => currMember.email === memberData?.email,
    );
    if (member) {
      try {
        void firestore
          .collection(COLLECTIONS.TEAM_MEMBERSHIP)
          .doc(member.membershipId)
          .delete();
        closeModal();
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <MainLayout
      headerProps={{
        pageTitle: team.name || 'Edit Team',
        breadcrumbsLinks: [{
          title: 'Profile',
          href: RoutePath.Profile.Home,
        }],
      }}
    >
      {team.isLoading ? (
        <CircularProgress />
      ) : (
        <TeamForm
          onSubmit={onSubmit}
          formValues={formValues}
          setOpenModal={setOpenModal}
          openRemoveModal={openRemoveModal}
        />
      )}

      <ModalWrapper
        open={openModal === 'invite'}
        maxWidth={600}
        onClose={closeModal}
      >
        <InviteMembersModal
          memberships={team.memberships}
          team={currentTeam!}
          onClose={closeModal}
        />
      </ModalWrapper>
      <ModalWrapper
        open={Boolean(memberData && openModal === 'remove')}
        maxWidth={600}
        onClose={closeModal}
      >
        <RemoveMemberModal
          onClose={closeModal}
          onRemove={onRemoveMember}
          memberData={memberData}
        />
      </ModalWrapper>
    </MainLayout>
  );
});
