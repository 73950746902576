import { v4 as uuidv4 } from 'uuid';
import { Grid } from '@chaos/ui/grid';
import { Box } from '@chaos/ui/box';
import { Typography } from '@chaos/ui/typography';
import {
  AreaChartRow,
  CollapsibleDescription,
  HistogramChartRow,
  PieChartContainer,
  LineChart,
  ScatterChartRow,
} from 'src/components/charts';
import {
  ChaosTable, JsonWidget, TokenCard, TokenCardProps,
} from 'src/components/widgets';
import {
  LinkableCard,
  OverviewCardWithIcon,
  StaticCard,
} from 'src/components/overview-card';
import { PairsRow } from 'src/components/pairs-row';
import {
  DashboardPayload, AddressOverviewCardInterface, ChartData, ChartType,
} from '@chaos/types';
import { Loader } from 'src/components/loader';
import { DyDxCalculatorPage } from 'src/pages/dydx-calculator-page';
import { EmptyState } from 'src/components/empty-state';
import { DyDxPerpetualFundingRate } from './dydx/DyDxPerpetualFundingRate';
import { RiskScore, RiskScoreProps } from '../widgets/risk-score';
import { TokenTreeMaps, TokenTreeMapsProps } from '../charts/token-tree-maps';
// import { DividerButton } from '../divider-button';

const TitleSection = ({ title, description }: { title?: string, description?: string }) => (
  <Grid item xs={12}>
    <Box position="relative">
      {title && <Typography variant="h2">{title}</Typography>}
      {description && <CollapsibleDescription text={description} />}
    </Box>
  </Grid>
);

const mapDataToComponent = (
  data: ChartData,
  chartType: ChartType,
  idx: number,
): JSX.Element | null => {
  const {
    // eslint-disable-next-line
    chartTitle, chartSubtitle, chartDescription, chartColor, dataPayload, hideChartLegends, hideXAxisLabels, hidePoints,
  } = data;

  switch (chartType) {
    case ChartType.VictoryLineChart:
      return (
        <LineChart
          // eslint-disable-next-line
          title={dataPayload?.title || ''}
          // eslint-disable-next-line
          data={dataPayload?.data}
          description={chartDescription}
          // eslint-disable-next-line
          xAxis={dataPayload?.xAxis}
          // eslint-disable-next-line
          yAxis={dataPayload?.yAxis}
          hidePoints={hidePoints}
          // eslint-disable-next-line
          callbacks={dataPayload?.callbacks}
        />
      );
    case ChartType.AreaChartRow:
      return (
        <AreaChartRow
          key={`area-chart-${idx}`}
          title={chartTitle?.value || ''}
          // eslint-disable-next-line
          data={dataPayload}
          color={chartColor || undefined}
          description={chartDescription}
        />
      );
    case ChartType.RiskScores:
      return (
        <Grid container spacing={3}>
          {(chartTitle?.value || chartDescription) && (
            <TitleSection title={chartTitle?.value} description={chartDescription} />
          )}
          {(dataPayload as RiskScoreProps[]).map(({ title, subtitle, value }) => (
            <Grid key={title} item xs={4}>
              <RiskScore
                title={title}
                subtitle={subtitle}
                value={value}
              />
            </Grid>
          ))}
        </Grid>
      );
    case ChartType.TokenTreeMaps:
      return (
        <TokenTreeMaps
          title={chartTitle?.value}
          description={chartDescription}
          data={dataPayload as TokenTreeMapsProps['data']}
        />
      );
    case ChartType.HistogramChartRow:
      // eslint-disable-next-line
      if (!dataPayload || !Array.isArray(dataPayload.data)) {
        return null;
      }
      return (
        <HistogramChartRow
          // eslint-disable-next-line
          title={chartTitle?.value || dataPayload?.title || ''}
          key={`histogram-${idx}`}
          // eslint-disable-next-line
          data={dataPayload?.data}
          // eslint-disable-next-line
          legend={dataPayload?.legends ? dataPayload.legends : []}
          description={chartDescription}
          // eslint-disable-next-line
          xAxis={dataPayload?.xAxis}
          // eslint-disable-next-line
          yAxis={dataPayload?.yAxis}
          // eslint-disable-next-line
          showTokenFilter={dataPayload?.isTokenData}
          // eslint-disable-next-line
          hideXAxisLabels={hideXAxisLabels}
        />
      );
    case ChartType.JSONWidget: {
      if (!Array.isArray(dataPayload)) {
        return null;
      }
      return (
        <Grid key={`json-widget-${idx}`} spacing={3} container>
          {(chartTitle?.value || chartDescription) && (
            <TitleSection title={chartTitle?.value} description={chartDescription} />
          )}
          {dataPayload
            .filter((chart) => (
              // eslint-disable-next-line
              chart?.chartTitle?.value
                  // eslint-disable-next-line
                  || chart?.chartSubtitle?.value
                  // eslint-disable-next-line
                  || chart?.payload
            ))
            .map((chart, i) => (
              // eslint-disable-next-line
              <Grid item xs={12} xl={6} key={chart.chartTitle.value || `json-chart-${i}`}>
                <JsonWidget
                  // eslint-disable-next-line
                  title={chart?.chartTitle}
                  // eslint-disable-next-line
                  subTitle={chart?.chartSubtitle}
                  // eslint-disable-next-line
                  icon={chart?.chartTitle?.value?.toLowerCase()}
                  // eslint-disable-next-line
                  data={chart?.payload}
                />
              </Grid>
            ))}
        </Grid>
      );
    }
    case ChartType.OverviewWithIconChart: {
      if (!Array.isArray(dataPayload)) {
        return null;
      }
      return (
        <Grid container key={`overview-with-icon-${idx}`} spacing={3}>
          {(chartTitle?.value || chartDescription) && (
            <TitleSection title={chartTitle?.value} description={chartDescription} />
          )}
          {dataPayload.map((chart, i) => (
            // eslint-disable-next-line
            <Grid item xs={12} xl={6} key={chart.chartTitle.value || `overview-chart-${i}`}>
              <OverviewCardWithIcon
                index={i}
                // eslint-disable-next-line
                title={chart.chartTitle.value}
                // eslint-disable-next-line
                value={chart.chartSubtitle?.value}
                // eslint-disable-next-line
                count={chart.chartSubtitle?.value}
                // eslint-disable-next-line
                delta={`${chart.chartDelta?.key}: ${chart.chartDelta?.value}`}
                // eslint-disable-next-line
                subtitle={`${chart.chartSubtitle?.key || undefined}: ${
                // eslint-disable-next-line
                  chart.chartSubtitle?.value || undefined
                }`}
                // eslint-disable-next-line
                detail={`${chart.chartDetail?.key}: ${chart.chartDetail?.value}`}
                icon={`${
                // eslint-disable-next-line
                chart.chartTitle.value === undefined
                    ? null
                  // eslint-disable-next-line
                  : chart.chartTitle.value.toLowerCase()
                }`}
              />
            </Grid>
          ))}
        </Grid>
      );
    }
    case ChartType.AddressRow: {
      if (!Array.isArray(dataPayload)) {
        return null;
      }
      return (
        <Grid key={`address-row-${idx}`} spacing={3} container>
          {(chartTitle?.value || chartDescription) && (
            <TitleSection title={chartTitle?.value} description={chartDescription} />
          )}
          {dataPayload.map(
            (addy: AddressOverviewCardInterface, i) => (
              <Grid item xs={12} xl={6} key={addy.title || `linkable-card-${i}`}>
                <LinkableCard
                  index={i}
                  title={addy.title}
                  subtitle={addy.address}
                  link={`https://etherscan.io/address/${addy.address}`}
                />
              </Grid>
            ),
          )}
        </Grid>
      );
    }
    case ChartType.Table: {
      // eslint-disable-next-line
      if (!dataPayload || !dataPayload.data?.length) {
        return null;
      }
      // eslint-disable-next-line
      const initialSortBy = dataPayload.headers.find((
        { sortDirection }: { sortDirection: 'ASC' | 'DESC' },
      ) => sortDirection);

      return (
        <ChaosTable
          key={`chaos-table-${idx}`}
          // eslint-disable-next-line
          headers={dataPayload.headers}
          // eslint-disable-next-line
          data={dataPayload.data}
          // eslint-disable-next-line
          title={dataPayload.title || data.chartTitle?.value}
          // eslint-disable-next-line
          titleIcon={dataPayload.titleIcon}
          rowHeight={56}
          description={chartDescription}
          // eslint-disable-next-line
          initialSortBy={initialSortBy && dataPayload.headers.indexOf(initialSortBy)}
          // eslint-disable-next-line
          isInitialSortDesc={initialSortBy && initialSortBy.sortDirection === 'DESC'}
        />
      );
    }
    case ChartType.ScatterChart: {
      // eslint-disable-next-line
      if (!dataPayload || !dataPayload.data?.length) {
        return null;
      }
      return (
        <ScatterChartRow
          key={`scatter-chart-${idx}`}
          // eslint-disable-next-line
          data={dataPayload.data}
          // eslint-disable-next-line
          xAxis={dataPayload.xAxis}
          // eslint-disable-next-line
          yAxis={dataPayload.yAxis}
          // eslint-disable-next-line
          title={dataPayload.title}
          description={chartDescription}
        />
      );
    }
    case ChartType.DyDxPerpetualFundingRate: {
      // eslint-disable-next-line
      if (!dataPayload || !dataPayload.markets || !dataPayload.funding_rate_over_time) {
        return null;
      }
      return (
        <DyDxPerpetualFundingRate
          key={`dydx-${idx}`}
          // eslint-disable-next-line
          markets={dataPayload.markets}
          // eslint-disable-next-line
          fundingRateOverTime={dataPayload.funding_rate_over_time}
          // eslint-disable-next-line
          candles={dataPayload.candles}
        />
      );
    }
    case ChartType.DyDxCalculator: {
      return <DyDxCalculatorPage key={`dydx-calc-${idx}`} />;
    }
    case ChartType.TokenCards: {
      if (!dataPayload) {
        return null;
      }
      return (
        <Grid key={`token-cards-${idx}`} container spacing={{ xs: 2, lg: 4 }}>
          {(chartTitle || chartDescription) && (
            <TitleSection
              title={typeof chartTitle === 'string' ? chartTitle : chartTitle?.value}
              description={chartDescription}
            />
          )}
          {(dataPayload as TokenCardProps[]).map((payload) => (
            <Grid key={payload.title} item xs={12} lg={6} xl={4}>
              <TokenCard {...payload} />
            </Grid>
          ))}
        </Grid>
      );
    }
    case ChartType.PieChart: {
      if (!Array.isArray(dataPayload)) {
        return null;
      }
      return (
        <Grid key={`pie-chart-${idx}`} container spacing={4}>
          <PieChartContainer
            {...data}
            chartTitle={chartTitle}
            chartSubtitle={{ key: '', value: chartSubtitle?.value || '' }}
            columnsToFill={12}
            hideChartLegends={hideChartLegends}
          />
        </Grid>
      );
    }
    default:
      return null;
  }
};

const mapMultiDataToComponent = (
  data: Array<ChartData>,
  chartType: ChartType,
  idx: number,
  title?: string,
  description?: string,
): JSX.Element | null => {
  const currentData = data[0];
  if (!currentData) {
    return null;
  }
  // Multiple Charts in Single Row Handling
  if (currentData.chartType === ChartType.PieChartRow) {
    const [leftChart, rightChart] = data;
    return (
      <Grid key={`pie-chart-${idx}`} container spacing={3}>
        {leftChart ? (
          <PieChartContainer
            {...leftChart}
            chartSubtitle={{ key: '', value: leftChart.chartSubtitle?.value || '' }}
            chartTitle={leftChart.chartTitle}
            hideChartLegends={leftChart.hideChartLegends}
          />
        ) : null}
        {rightChart ? (
          <PieChartContainer
            {...rightChart}
            chartSubtitle={{ key: '', value: rightChart.chartSubtitle?.value || '' }}
            chartTitle={rightChart.chartTitle}
            hideChartLegends={rightChart.hideChartLegends}
          />
        ) : null}
      </Grid>
    );
  }

  if (chartType === ChartType.PairsRow) {
    return (
      <PairsRow
        key={`pairs-row-${idx}`}
        pairs={[]}
        title={title}
        description={description}
      />
    );
  }

  if (chartType === ChartType.OverviewCardRow) {
    return (
      <Grid key={`overview-card-${idx}`} spacing={3} container>
        {description && (
          <Grid item xs={12} position="relative">
            <CollapsibleDescription text={description} />
          </Grid>
        )}
        {data.map((chart, i) => (
          <Grid item xs={12} xl={6} key={chart.chartTitle?.key || `overview-card-item-${i}`}>
            <StaticCard
              index={chart.chartTitle?.value}
              title={chart.chartTitle?.value || ''}
              // eslint-disable-next-line
              subtitle={chart.dataPayload}
              detail={chart.chartDetail?.value || ''}
              delta=""
              link=""
            />
          </Grid>
        ))}
      </Grid>
    );
  }

  return null;
};

export const DashboardBuilder = (
  { content, description }: DashboardPayload,
  currentDashboardName: string | undefined,
  // @ts-expect-error commented out
  // eslint-disable-next-line
  openAddWidgetModal?: () => void,
): Array<JSX.Element | null> | JSX.Element => {
  if (currentDashboardName === undefined) {
    return <Loader />;
  }

  if (content.length === 0) {
    return <EmptyState type="visual data" />;
  }

  const chartList = content.map(
    (dashContent, i) => {
      const { data, shouldRender } = dashContent;

      if (shouldRender === false) {
        return null;
      }

      const res = [];
      const currentData = data[0];
      const { chartType, chartTitle, chartDescription } = currentData;

      if (
        chartType === ChartType.PieChartRow
        || chartType === ChartType.PairsRow
        || chartType === ChartType.OverviewCardRow
      ) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        res.push(mapMultiDataToComponent(data, chartType, i, chartTitle?.value, chartDescription));
      }

      res.push(mapDataToComponent(currentData, chartType, i));

      // if (openAddWidgetModal) {
      //   res.push(
      //     <DividerButton key={`divider-${i}`} text="Add Widget" onClick={openAddWidgetModal} />,
      //   );
      // }

      return res;
    },
  );
  const flattened = chartList.flat().filter((chart) => !!chart);
  return flattened.length > 1 ? (
    <Grid container spacing={{ xs: 2, lg: 4 }} flex={1}>
      {description && (
        <Grid item xs={12}>
          <Typography>{description}</Typography>
        </Grid>
      )}
      {flattened.map((item) => (
        <Grid key={uuidv4()} item xs={12}>
          {item}
        </Grid>
      ))}
    </Grid>
  ) : flattened;
};
