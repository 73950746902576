import { FC } from 'react';
import { SimulationEvent } from '@chaos/types';
import { Box, Typography } from '@chaos/ui';
import { Event } from './event';
import { LineSeparator } from './building-components';

export const EventList: FC<{ events: SimulationEvent[] }> = ({ events }) => (events.length ? (
  <Box component="ul">
    {events.map((event, index) => (
      <>
        <Event key={event.logIndex} {...event} />
        {index !== events.length - 1 && <LineSeparator />}
      </>
    ))}
  </Box>
) : <Typography>No events found.</Typography>);
