import { useEffect, useMemo, useState } from 'react';
import { Observable, Subscription, throttleTime } from 'rxjs';
import { get } from 'lodash';
import {
  collection, doc, limit, orderBy, query, startAfter, where,
} from 'firebase/firestore';
import { collectionData } from 'rxfire/firestore';
import { COLLECTIONS } from '@chaos/utils';
import { BlockchainSimulationResult } from '@chaos/types';
import { firestore } from 'src/services/firebase';
import { useCurrentTeam } from './useCurrentTeam';

export const useBlockchainSimulationResults = (
  sortBy = 'updated',
  sortDirection: 'asc' | 'desc' = 'desc',
  last?: BlockchainSimulationResult,
  limitNumber?: number,
  nameFilter?: string,
  createdAtFilter?: [number, number],
  parentResultId?: string,
): {
  isLoading: boolean
  results: BlockchainSimulationResult[]
} => {
  const team = useCurrentTeam();
  const [simulationResults, setSimulationResults] = useState<BlockchainSimulationResult[]>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const subs: Subscription[] = [];
    const simResultsCol = collection(firestore, COLLECTIONS.BLOCKCHAIN_SIMULATION_RESULTS);
    const simulationResultsObs = (collectionData(query(
      simResultsCol,
      where('team', '==', doc(collection(firestore, COLLECTIONS.TEAMS), team?.id)),
      where('parentResultId', '==', parentResultId || null),
      orderBy(sortBy, sortDirection),
      ...(last ? [startAfter(get(last, sortBy))] : []),
      ...(limitNumber ? [limit(limitNumber)] : []),
      ...(nameFilter ? [
        where('simulationSnapshot.lowercaseName', '>=', nameFilter.toLowerCase()),
        where('simulationSnapshot.lowercaseName', '<=', `${nameFilter.toLowerCase()}\uf8ff}`),
      ] : []),
      ...((createdAtFilter && !nameFilter) ? [
        where('created', '>', new Date(createdAtFilter[0])),
        where('created', '<', new Date(createdAtFilter[1])),
      ] : []),
    ), { idField: 'id' }) as Observable<BlockchainSimulationResult[]>).pipe(throttleTime(5000));
    subs.push(simulationResultsObs.subscribe((data) => {
      setIsLoading(false);
      setSimulationResults((r) => (last ? [...r || [], ...data] : data));
    }));

    return () => subs.forEach((s) => s.unsubscribe());
  }, [
    team?.id,
    sortBy,
    sortDirection,
    last,
    limitNumber,
    nameFilter,
    createdAtFilter,
    parentResultId,
  ]);
  const result = useMemo(
    () => ({ isLoading, results: simulationResults || [] }),
    [isLoading, simulationResults],
  );

  return result;
};
