import {
  DashboardPayload,
  ChartDataSource,
  ChartData,
  DashboardChartInterface,
} from '@chaos/types';
import { DataQueries } from '../services/engine/requests';

async function fillDataForChart(chart: ChartData): Promise<any> {
  if (typeof chart.chartId !== 'string') {
    console.log(
      'Error : Please provide chartId for data fetcher. Received: ',
      chart,
    );
    return chart;
  }
  switch (chart.chartDataSource) {
    case ChartDataSource.ONCHAIN: {
      // eslint-disable-next-line
      const dataPayload = await DataQueries(chart.chartId, chart.chartDataSource, chart.queryData);

      if (dataPayload === undefined) {
        throw new Error(`unsupported type ${chart.chartDataSource}`);
      }

      // eslint-disable-next-line
      const filledChart = { ...chart, dataPayload };
      return filledChart;
    }
    case ChartDataSource.OFFCHAIN: {
      // eslint-disable-next-line
      const dataPayload = await DataQueries(chart.chartId, chart.chartDataSource, chart.queryData);

      if (dataPayload === undefined) {
        throw new Error(`unsupported type ${chart.chartDataSource}`);
      }

      // eslint-disable-next-line
      const filledChart = { ...chart, dataPayload };
      return filledChart;
    }
    default:
      return chart;
  }
}

async function fillDataForSingleDashboard(
  dashboard: DashboardPayload,
): Promise<Array<DashboardChartInterface | undefined>> {
  return Promise.all(
    dashboard.content?.map(
      async (
        dashPayload: DashboardChartInterface,
      ): Promise<DashboardChartInterface | undefined> => {
        try {
          const { data, shouldRender } = dashPayload;
          const fetchedData = await Promise.all(
            data?.map((chart: ChartData) => fillDataForChart(chart)),
          );
          // eslint-disable-next-line
          return Object.assign({}, ...dashboard.content, {
            data: fetchedData,
            shouldRender,
          });
        } catch (e) {
          console.error(e);
          return undefined;
        }
      },
    ),
  );
}

export async function fillDashboardData(
  dashboard: DashboardPayload,
): Promise<(DashboardPayload & { content: DashboardChartInterface[] })> {
  const filledChart = await fillDataForSingleDashboard(dashboard);
  return {
    ...dashboard,
    content: filledChart.filter((chart) => !!chart) as DashboardChartInterface[],
  };
}
