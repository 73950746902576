import { FC } from 'react';
import MUIIcon from '@mui/material/Icon';
import type { IconProps as MUIIconProps } from '@mui/material/Icon';
import allIcons from '@mui/icons-material';
import '@mui/icons-material/ArrowDropUp';
import { snakeCase } from 'snake-case';

export type IconNames = keyof typeof allIcons;

interface IconProps extends MUIIconProps {
  name: IconNames
}

export const Icon: FC<IconProps> = (props) => <MUIIcon {...props}>{snakeCase(props.name)}</MUIIcon>;
