import { useEffect, useState } from 'react';
import { Subscription, throttleTime } from 'rxjs';
import {
  collection, doc, orderBy, query, where,
} from 'firebase/firestore';
import { collectionData } from 'rxfire/firestore';
import { COLLECTIONS } from '@chaos/utils';
import { SimulationTransaction } from '@chaos/types';
import { firestore } from 'src/services/firebase';
import { useCurrentTeam } from './useCurrentTeam';

export const useSimulationTransactions = (
  simulationResultId: string,
  connectionKey?: string,
  transactionHash?: string,
): { isLoading: boolean, simulationTransactions: SimulationTransaction[] } => {
  const team = useCurrentTeam();
  const [transactions, setTransactions] = useState<SimulationTransaction[]>();

  useEffect(() => {
    const subs: Subscription[] = [];

    if (connectionKey) {
      const transactionsCol = collection(firestore, COLLECTIONS.ETH_TRANSACTIONS);
      const transactionsObs = collectionData(query(
        transactionsCol,
        where('team', '==', doc(collection(firestore, COLLECTIONS.TEAMS), team?.id)),
        where('simulationResultId', '==', simulationResultId),
        where('connectionKey', '==', connectionKey),
        transactionHash ? where('hash', '==', transactionHash) : orderBy('blockNumber', 'desc'),
      ), { idField: 'id' }).pipe(throttleTime(5000));
      subs.push(transactionsObs.subscribe((data) => {
        setTransactions(data as SimulationTransaction[]);
      }));
    }

    return () => subs.forEach((s) => s.unsubscribe());
  }, [team?.id, simulationResultId, connectionKey, transactionHash]);

  return { isLoading: !transactions, simulationTransactions: transactions || [] };
};
