import {
  useState, memo, useCallback, useEffect, useMemo,
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import { v4 as uuidv4 } from 'uuid';
import { VictoryPie } from 'victory';
import {
  Header, RangeHeader, RenderData, RenderType,
} from '@chaos/types';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { Tooltip } from '../tooltip';
import { Link } from '../link';
import { Button } from '../button';
import { IconButton } from '../icon-button';
import { Box } from '../box';
import { Typography } from '../typography';
import { useRandomAvatar } from '../hooks/use-random-avatar';
import { wrappedSymbolToIconSymbol } from '../utils/icon-helper';
import { CustomSlice } from '../charts';
import { Alert } from '../alert';
import { CustomIcon } from '../custom-icon';
import { TagChip } from '../tag-chip';
import { colorScaleWarm } from '../utils/color-scale';
import { ProgressBar } from '../progress-bar';
import { CopyButton } from '../copy-button';
import { CryptoIcon } from '../crypto-icon';

type ExpandRow = {
  content: React.ReactNode
  expandedIdx: number | null
  setExpandedIdx: React.Dispatch<React.SetStateAction<number | null>>
};

interface TableRowProps {
  rowIndex: number
  data?: RenderData[]
  headers: Header[]
  fixedRowHeight?: number
  showRowChevron?: boolean
  getRowImageUrl?: (rowId: number) => string | Promise<string>
  setExtraData: (extraData: { data?: Record<string, string>[], title?: string }) => void
  isLast?: boolean
  href?: string,
  hrefTarget?: string,
  expandRow?: ExpandRow,
}

const TableRow = ({
  rowIndex,
  data = [],
  headers,
  fixedRowHeight,
  showRowChevron,
  getRowImageUrl,
  setExtraData,
  isLast,
  href,
  hrefTarget,
  expandRow,
}: TableRowProps) => {
  const [rowImageUrl, setRowImageUrl] = useState('');
  const [snackbarText, setSnackbarText] = useState('');
  const { randomAvatar } = useRandomAvatar(data.find((cell) => cell.showCryptoPunk)?.text);

  useEffect(() => {
    void (async () => {
      if (!getRowImageUrl) return;
      const imageUrl = await getRowImageUrl(rowIndex);
      setRowImageUrl(imageUrl);
    })();
  }, [rowIndex, getRowImageUrl]);

  const renderCell = useCallback((currData: RenderData, currHeader: Header) => {
    const { cryptoIcon, customIcon } = currData;
    const token1 = currData.token1 && wrappedSymbolToIconSymbol(currData.token1);
    const token2 = currData.token2 && wrappedSymbolToIconSymbol(currData.token2);

    const { renderType } = currData;

    if (currData.value === null) return <Box />;

    if (renderType === 'LINK') {
      const text = currData.text.toString().substring(currData.text.toString().lastIndexOf('/') + 1);

      return (
        <Box display="flex" overflow="hidden" textOverflow="ellipsis">
          <Tooltip title={currData.tooltipText || currData.text}>
            <Button
              LinkComponent={Link}
              variant="text"
              size="small"
              href={currData.href || currData.text.toString()}
              target="blank"
              onClick={(event: React.SyntheticEvent) => event.stopPropagation()}
              endIcon={<CustomIcon size="x-small" icon="external-link" />}
            >
              {text}
            </Button>
          </Tooltip>
          {currData.copyText && (
            <Box className="copy-btn" display="none" flex="1">
              <CopyButton text={currData.copyText ?? text} onCopy={setSnackbarText} />
            </Box>
          )}
        </Box>
      );
    }

    if (renderType === 'RECOMMENDATION') {
      return (
        <Tooltip title={currData.tooltipText ? currData.tooltipText : ''}>
          <Box display="flex" gap={1} alignItems="center">
            <Box display="flex" gap={1} alignItems="center">
              {customIcon && (
                <CustomIcon
                  sx={{ svg: { height: 15, width: 15 } }}
                  icon={customIcon}
                />
              )}
              {(cryptoIcon) && (
                <CryptoIcon
                  sx={{ img: { height: 15, width: 15 } }}
                  icon={cryptoIcon}
                />
              )}
              {(token1) && (
                <CryptoIcon
                  sx={{ img: { height: 15, width: 15 } }}
                  icon={token1}
                />
              )}
              <Typography>{currData.previousText}</Typography>
            </Box>
            <CustomIcon icon="arrow-right" sx={{ svg: { height: 15, width: 15 } }} />
            <Box display="flex" gap={1} alignItems="center" px={1} bgcolor="recommended.opacity50" borderRadius={1}>
              {customIcon && (
                <CustomIcon
                  sx={{ svg: { height: 15, width: 15 } }}
                  icon={customIcon}
                />
              )}
              {(cryptoIcon) && (
                <CryptoIcon
                  sx={{ img: { height: 15, width: 15 } }}
                  icon={cryptoIcon}
                />
              )}
              {(token1) && (
                <CryptoIcon
                  sx={{ img: { height: 15, width: 15 } }}
                  icon={token1}
                />
              )}
              <Typography>{currData.text}</Typography>
            </Box>
          </Box>
        </Tooltip>
      );
    }

    if (renderType === 'LABELS') {
      let tagColor = 'light';

      if (currData.status === 'OK') {
        tagColor = 'success';
      } else if (currData.status === 'WARNING') {
        tagColor = 'uploaded';
      } else if (currData.status === 'ERROR') {
        tagColor = 'error';
      } else if (currData.status === 'GOOD') {
        tagColor = 'deployed';
      } else if (currData.status === 'PROGRESS') {
        tagColor = 'progress';
      } else if (currData.status === 'RECOMMENDED') {
        tagColor = 'recommended';
      }

      return currData?.text ? (
        <Tooltip title={currData.tooltipText ? currData.tooltipText : ''}>
          <Box display="flex" overflow="hidden" textOverflow="ellipsis">
            <TagChip
              icon={(
                <>
                  {customIcon ? (
                    <CustomIcon
                      sx={{ height: 24, width: 24 }}
                      icon={customIcon}
                    />
                  ) : undefined}
                  {(cryptoIcon)
                    ? <CryptoIcon sx={{ height: 24, width: 24 }} icon={cryptoIcon} />
                    : undefined}
                  {(token1)
                    ? <CryptoIcon sx={{ height: 24, width: 24 }} icon={token1} />
                    : undefined}
                </>
)}
              sx={{ bgcolor: `${tagColor}.opacity50`, width: currData.width }}
              label={(
                <Box display="flex" gap={1} alignItems="center">
                  {currData.text}
                </Box>
              )}
            />
            {currData.extraData && currData.extraData.title
              ? (
                <TagChip
                  sx={{ bgcolor: `${tagColor}.opacity20`, ml: 1 }}
                  label={currData.extraData.title}
                />
              )
              : ''}
          </Box>
        </Tooltip>
      ) : null;
    }

    if (renderType === 'ICONS' && currData.icons) {
      const { icons } = currData;

      return (
        <Tooltip title={icons.join(', ')} arrow placement="left">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              img: { width: 24, height: 24 },
            }}
          >
            {icons.slice(0, 3).map(
              (singleIcon, idx) => (
                <CryptoIcon
                  key={`${singleIcon}-${rowIndex}-${idx.toString()}`}
                  // TODO sagi - check if this is only symbols
                  icon={wrappedSymbolToIconSymbol(singleIcon)}
                  sx={{ ml: !idx ? 0 : 0.5 }}
                />
              ),
            )}
            {icons.length > 3 && (
              <Typography>
                +
                {icons.length - 3}
              </Typography>
            )}
          </Box>
        </Tooltip>
      );
    }

    if (renderType === 'CHIP') {
      const { chipIcon, text } = currData;
      return (
        <>
          {' '}
          <Tooltip title={currData.tooltipText ? currData.tooltipText : ''}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ background: currData.chipColor || 'rgba(255, 255, 255, 0.1)', height: chipIcon ? '24px' : 'auto', color: currData.textColor }}
              borderRadius={1}
              paddingRight={chipIcon ? '16px' : '8px'}
            >
              {chipIcon && (
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '24px',
                height: '24px',
                background: 'rgba(48, 212, 193, 0.25)',
                borderRadius: '120px',
              }}
              >
                <CryptoIcon
                  sx={{ width: '14px' }}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  icon={typeof chipIcon === 'string' ? chipIcon : chipIcon(text)}
                />
              </Box>
              )}
              <Typography variant="body2" marginLeft="8px">{currData.text}</Typography>
              {currData.trend === 'up' && <CustomIcon icon="sort-up" sx={{ path: { fill: '#181C1F' } }} />}
              {currData.trend === 'down' && <CustomIcon icon="sort-down" sx={{ path: { fill: '#181C1F' } }} />}
            </Box>
          </Tooltip>
          {currData.isMarked && (
          <Tooltip title={currData.tooltipText ? currData.tooltipText : ''}>
            <Box ml={1} width={8} height={8} borderRadius={8} bgcolor="recommended.main" />
          </Tooltip>
          )}
        </>
      );
    }

    if (renderType === 'PROGRESS' && currData.progressValue !== undefined) {
      const { progressValue, width } = currData;

      return (
        <Box fontSize={14} width={width}>
          <ProgressBar
            textPosition="left"
            barType="small"
            progress={progressValue}
          />
        </Box>
      );
    }

    if (renderType === 'CUSTOM' && currData.component) {
      return (
        <Box sx={{
          width: '100%', height: '100%', display: 'flex', alignItems: 'center',
        }}
        >
          {currData.component}
        </Box>
      );
    }

    if (renderType === 'PIE_CHART' && currData?.value) {
      const { value } = currData;
      return (
        <Box display="flex" overflow="hidden" textOverflow="ellipsis" height={24} width="100%">
          <svg viewBox="0 0 24 24">
            <VictoryPie
              data={[{ x: '', y: 100 - Number(value), background: true }, { x: '', y: Number(value) }]}
              standalone={false}
              width={24}
              height={24}
              padding={0}
              innerRadius={8}
              labelRadius={8}
              colorScale={colorScaleWarm}
              style={{ labels: { display: 'none' } }}
              dataComponent={<CustomSlice />}
            />
          </svg>
          <Typography
            overflow="hidden"
            textOverflow="ellipsis"
            ml={1}
          >
            {value}
          </Typography>
        </Box>
      );
    }

    if (renderType === 'BUTTON' && currData.onClick) {
      return (
        <Box py={1.5} width="100%" height="100%">
          <Button
            onClick={(e) => {
              e.preventDefault();
              currData.onClick?.(e);
            }}
            color="primary"
            fullWidth
            sx={{
              ml: 'auto',
              maxWidth: 144,
            }}
            disabled={currData.disabled}
          >
            {currData.text}
          </Button>
        </Box>
      );
    }

    if (customIcon || cryptoIcon || token1 || token2) {
      return (
        <Tooltip title={currData.tooltipText || currData.text}>
          <Box
            overflow="hidden"
            display="flex"
            alignItems="center"
            sx={{
              display: 'flex',
              alignItems: 'center',
              overflow: 'hidden',
              '& svg, & img': { width: 24, height: 24, flex: '0 0 24px' },
            }}
          >
            {customIcon && <CustomIcon icon={customIcon} sx={{ color: 'almostWhite.main' }} />}
            {cryptoIcon && <CryptoIcon icon={cryptoIcon} />}
            {token1 && <CryptoIcon icon={token1} />}
            {token2 && (
              <>
                <Box sx={{ ml: 1 }}>/</Box>
                <CryptoIcon sx={{ ml: 1 }} icon={token2} />
              </>
            )}
            {!currHeader.textHidden && (
              <Typography overflow="hidden" textOverflow="ellipsis" ml={1}>
                {currData.text}
              </Typography>
            )}
          </Box>
        </Tooltip>
      );
    }

    const text = currData.text?.toString();
    let color: string | undefined;
    let backgroundColor: string | undefined;

    if (currHeader.renderType === 'DELTA' && currData.value && currData.value !== 0) {
      color = currData.value > 0 ? 'success.main' : 'error.main';
    }

    if (currData.status === 'OK') {
      backgroundColor = 'success.opacity50';
    } else if (currData.status === 'WARNING') {
      backgroundColor = 'uploaded.opacity50';
    } else if (currData.status === 'ERROR') {
      backgroundColor = 'error.opacity50';
    } else if (currData.status === 'GOOD') {
      backgroundColor = 'deployed.opacity50';
    } else if (currData.status === 'PROGRESS') {
      backgroundColor = 'progress.opacity50';
    } else if (currData.status === 'INFO') {
      backgroundColor = 'background.light';
    } else if (currData.status === 'RECOMMENDED') {
      backgroundColor = 'recommended.opacity50';
    }

    return (
      <>
        <Tooltip title={currData.tooltipText || ''} arrow>
          <Box
            display="flex"
            alignItems="center"
            overflow="hidden"
            color={currData.textColor}
            bgcolor={backgroundColor}
            px={backgroundColor && 1.5}
            borderRadius={1}
            width={currData.width}
            justifyContent="center"
          >
            {currData.extraData && (typeof currData.extraData?.data === 'function'
              || !!currData.extraData.data?.length) && (
              <IconButton
                onClick={() => {
                  if (typeof currData.extraData!.data === 'function') {
                    void currData.extraData!.data().then((loadedData) => setExtraData({
                      ...currData.extraData, data: loadedData,
                    }));
                  } else {
                    setExtraData({ ...currData.extraData, data: currData.extraData!.data });
                  }
                }}
                sx={{ p: 0, mr: 1 }}
              >
                <CustomIcon icon={currData.extraData.icon || 'info'} />
              </IconButton>
            )}
            {currData.showCryptoPunk && randomAvatar && (
              <Box
                component="img"
                mr={1}
                src={randomAvatar}
                sx={{
                  objectFit: 'contain',
                  height: 32,
                  width: 32,
                }}
              />
            )}
            {currData.customIcon && (
              <CustomIcon
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                icon={currData.customIcon}
                sx={{ marginRight: 1, svg: { width: 24, height: 24 } }}
              />
            )}
            {currData.cryptoIcon && (
              <CryptoIcon
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                icon={currData.cryptoIcon}
                sx={{ marginRight: 1, img: { width: 24, height: 24 } }}
              />
            )}
            {!currHeader.textHidden && (
              <Typography
                overflow="hidden"
                textOverflow="ellipsis"
                color={color}
              >
                {text}
              </Typography>
            )}
          </Box>
        </Tooltip>
        {currData.isMarked && (
          <Tooltip title={currData.tooltipText ? currData.tooltipText : ''}>
            <Box ml={1} width={8} height={8} borderRadius={8} bgcolor="recommended.main" />
          </Tooltip>
        )}
        {currData.copyText && (
          <Box ml={1} className="copy-btn" display="none">
            <CopyButton text={currData.copyText} onCopy={setSnackbarText} />
          </Box>
        )}
      </>
    );
  }, [randomAvatar, setExtraData, rowIndex]);

  const getBgColor = (text: string, header: RangeHeader) => {
    const number = parseFloat(text.replaceAll(',', ''));
    const bgColors = [
      '#223764', '#1E3057', '#19294A', '#15233E', '#111C32', '#0D1426', '#090E19', '#04070C',
    ];
    const sliceSize = (header.ceiling - header.floor) / bgColors.length;
    const i = Math.min(Math.floor((number - header.floor) / sliceSize), bgColors.length - 1);
    return bgColors[Math.max(i, 0)];
  };

  const helperData: RenderType[] = useMemo(() => ['FILTER'], []);

  /*
    NOTE: The headers and row arrays must have the same length for the table to render properly.
    If the headers are changed dynamically outside of the component it will update them
    before the rows due to the internal state management and cause the table to break.
    To safeguard against this issue I added a check to ensure
    that the headers and row arrays have the same length (data.length !== headers.length)
   */
  const cellsForRender = useMemo(() => (data.length !== headers.length ? [] : data)
    .filter((rowItem) => !helperData.includes(rowItem.renderType))
    .map((currData, i) => {
      const currHeader = headers[i];
      const bgColor = currHeader.renderType === 'RANGE'
        ? getBgColor(currData.text.toString(), currHeader) : undefined;

      return (
        <Box
          key={`table-cell-${uuidv4()}`}
          data-testid={`table-cell-${i}`}
          className={i === data.length - 1 ? 'row-last-cell' : undefined}
          display="flex"
          alignItems="center"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          bgcolor={bgColor}
          marginLeft={3}
          minWidth={0}
          flexBasis={currHeader.width || `${100 / headers.length}%`}
          flexGrow={1}
          flexShrink={1}
          sx={currData.copyText ? { ':hover .copy-btn': { display: 'block' } } : undefined}
        >
          {renderCell?.(currData, currHeader)}
        </Box>
      );
    }), [data, headers, helperData, renderCell]);

  let row = (
    <Box
      display="flex"
      bgcolor="black.main"
      borderBottom={expandRow ? undefined : '2px solid'}
      borderColor="darkGrey.main"
      sx={{
        mb: isLast ? 0 : '1px',
        ':hover': href ? {
          backgroundColor: 'black.hover',
        } : undefined,
        width: '100%',
      }}
      height={fixedRowHeight}
    >
      {rowImageUrl && (
      <Box
        width={0}
        flexBasis={0}
        flexGrow={1}
        flexShrink={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          component="img"
          src={rowImageUrl}
          alt={rowImageUrl}
          sx={{
            objectFit: 'contain',
            height: 48,
            width: 48,
          }}
        />
      </Box>
      )}
      {cellsForRender}
      {showRowChevron && (
      <Box
        width="50px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CustomIcon icon="chevron-right" />
      </Box>
      )}
      <Snackbar
        open={!!snackbarText}
        autoHideDuration={2000}
        onClose={() => setSnackbarText('')}
      >
        <Alert
          onClose={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setSnackbarText('');
          }}
          severity="info"
        >
          {snackbarText}
        </Alert>
      </Snackbar>
    </Box>
  );

  if (expandRow) {
    row = (
      <Box>
        <Accordion
          key={rowIndex}
          expanded={expandRow.expandedIdx === rowIndex}
          TransitionProps={{ mountOnEnter: true }}
          onChange={() => {
            if (expandRow.expandedIdx === rowIndex) expandRow.setExpandedIdx(null);
            else expandRow.setExpandedIdx(rowIndex);
          }}
        >
          <Box
            borderBottom="2px solid"
            borderColor="darkGrey.main"
            sx={{
              mb: isLast ? 0 : '1px',
              ':hover': href ? {
                backgroundColor: 'black.hover',
              } : undefined,
              width: '100%',
            }}
          >
            <AccordionSummary
              data-testid="filter-accordion-summary"
              sx={{ display: 'flex', alignItems: 'center', backgroundColor: 'black.main' }}
              expandIcon={<CustomIcon icon="chevron-down-small" />}
            >
              {row}
            </AccordionSummary>
          </Box>
          <AccordionDetails
            sx={{ display: 'flex', alignItems: 'center', backgroundColor: 'black.main' }}
          >
            {expandRow.content}
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  }

  return href ? (
    <Link
      component={RouterLink}
      data-test-id="table-row-link"
      data-testid={`table-row-link-${rowIndex}`}
      to={href}
      target={hrefTarget}
      color="inherit"
      underline="none"
      sx={{ cursor: 'pointer' }}
    >
      {row}
    </Link>
  ) : row;
};

export default memo(TableRow);
