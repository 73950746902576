import React from 'react';
import { SxProps } from '@mui/material';
import { Box } from '../box';
import { ReactComponent as ChaosIcon } from '../assets/img/icons/logo-icon.svg';
import { ReactComponent as AirplayIcon } from '../assets/img/icons/nav/airplay.svg';
import { ReactComponent as AirplayGradientShadowIcon } from '../assets/img/icons/airplay-gradient-shadow.svg';
import { ReactComponent as ViewListIcon } from '../assets/img/icons/nav/view-list.svg';
import { ReactComponent as AlbumLibraryIcon } from '../assets/img/icons/nav/album-library.svg';
import { ReactComponent as GiftIcon } from '../assets/img/icons/nav/gift.svg';
import { ReactComponent as GridIcon } from '../assets/img/icons/nav/grid.svg';
import { ReactComponent as CardEjectIcon } from '../assets/img/icons/card-eject.svg';
import { ReactComponent as CardLockIcon } from '../assets/img/icons/card-lock.svg';
import { ReactComponent as HistoryIcon } from '../assets/img/icons/nav/history.svg';
import { ReactComponent as HathorIcon } from '../assets/img/crypto/hathor-logo.svg';
import { ReactComponent as KeyboardIcon } from '../assets/img/icons/nav/keyboard.svg';
import { ReactComponent as PaletteIcon } from '../assets/img/icons/nav/palette.svg';
import { ReactComponent as PerspectiveIcon } from '../assets/img/icons/nav/perspective.svg';
import { ReactComponent as VectorIcon } from '../assets/img/icons/nav/vector.svg';
import { ReactComponent as BulletBlueIcon } from '../assets/img/icons/bullet-blue.svg';
import { ReactComponent as GridFourIcon } from '../assets/img/icons/grid-four.svg';
import { ReactComponent as GridTwoHorizontal } from '../assets/img/icons/grid-two-h.svg';
import { ReactComponent as BubbleRaceIcon } from '../assets/img/icons/bubble-race.svg';
import { ReactComponent as UsersIcon } from '../assets/img/icons/users.svg';
import { ReactComponent as ChevronDownFilledIcon } from '../assets/img/icons/chevron-down-filled.svg';
import { ReactComponent as FiledVideo } from '../assets/img/icons/file-video.svg';
import { ReactComponent as FilterIcon } from '../assets/img/icons/filter.svg';
import { ReactComponent as PlayIcon } from '../assets/img/icons/play.svg';
import { ReactComponent as PieGradientShadowIcon } from '../assets/img/pie-gradient-shadow.svg';
import { ReactComponent as TriangleGradientShadowIcon } from '../assets/img/triangle-gradient-shadow.svg';
import { ReactComponent as CheckSquareIcon } from '../assets/img/icons/check-square.svg';
import { ReactComponent as MenuToggleIcon } from '../assets/img/icons/menu-toggle.svg';
import { ReactComponent as PieSliceIcon } from '../assets/img/icons/pie-slice.svg';
import { ReactComponent as SortNoneIcon } from '../assets/img/icons/sort-non.svg';
import { ReactComponent as SortDownIcon } from '../assets/img/icons/sort-down.svg';
import { ReactComponent as SortUpIcon } from '../assets/img/icons/sort-up.svg';
import { ReactComponent as LabIcon } from '../assets/img/icons/lab.svg';
import { ReactComponent as CalendarIcon } from '../assets/img/icons/calendar.svg';
import { ReactComponent as BriefCaseIcon } from '../assets/img/icons/briefcase.svg';
import { ReactComponent as CheckboxOffIcon } from '../assets/img/icons/checkbox-off.svg';
import { ReactComponent as GithubIcon } from '../assets/img/icons/github.svg';
import { ReactComponent as GithubAltIcon } from '../assets/img/icons/github-alt.svg';
import { ReactComponent as GoogleIcon } from '../assets/img/icons/google.svg';
import { ReactComponent as TwitterIcon } from '../assets/img/icons/twitter.svg';
import { ReactComponent as TwitterFilledIcon } from '../assets/img/icons/twitter-filled.svg';
import { ReactComponent as OutlineTwitter } from '../assets/img/icons/outline-twitter.svg';
import { ReactComponent as EyeIcon } from '../assets/img/icons/eye.svg';
import { ReactComponent as FolderUpload } from '../assets/img/icons/folder-upload.svg';
import { ReactComponent as EyeOffIcon } from '../assets/img/icons/eye-off.svg';
import { ReactComponent as ChevronDownIcon } from '../assets/img/icons/chevron-down.svg';
import { ReactComponent as RemoveIcon } from '../assets/img/icons/remove.svg';
import { ReactComponent as CloseIcon } from '../assets/img/icons/close.svg';
import { ReactComponent as SearchIcon } from '../assets/img/icons/search.svg';
import { ReactComponent as WifiIcon } from '../assets/img/icons/wi-fi.svg';
import { ReactComponent as UserIcon } from '../assets/img/icons/user.svg';
import { ReactComponent as BellIcon } from '../assets/img/icons/bell.svg';
import { ReactComponent as SettingsIcon } from '../assets/img/icons/settings.svg';
import { ReactComponent as SignOutIcon } from '../assets/img/icons/sign-out.svg';
import { ReactComponent as CloseCircleIcon } from '../assets/img/icons/close-circle.svg';
import { ReactComponent as CheckCircleIcon } from '../assets/img/icons/check-circle.svg';
import { ReactComponent as StackedAreaUpIcon } from '../assets/img/icons/stacked-area-up.svg';
import { ReactComponent as ChevronRightIcon } from '../assets/img/icons/chevron-right.svg';
import { ReactComponent as ChevronLeftIcon } from '../assets/img/icons/chevron-left.svg';
import { ReactComponent as ListBoxesIcon } from '../assets/img/icons/list-boxes.svg';
import { ReactComponent as ExclamationSquareIcon } from '../assets/img/icons/exclamation-square.svg';
import { ReactComponent as ExclamationMarkIcon } from '../assets/img/icons/exclamation-mark.svg';
import { ReactComponent as ArrowLeftIcon } from '../assets/img/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '../assets/img/icons/arrow-right.svg';
import { ReactComponent as BreadcrumbsArrowIcon } from '../assets/img/icons/breadcrumbs-arrow.svg';
import { ReactComponent as CloudDownloadIcon } from '../assets/img/icons/cloud-download.svg';
import { ReactComponent as PrinterIcon } from '../assets/img/icons/printer.svg';
import { ReactComponent as SettingsAdjustIcon } from '../assets/img/icons/settings-adjust.svg';
import { ReactComponent as GridTwoVerticalIcon } from '../assets/img/icons/grid-two-v.svg';
import { ReactComponent as CheckboxIndeterminateIcon } from '../assets/img/icons/checkbox-indeterminate.svg';
import { ReactComponent as FullscreenIcon } from '../assets/img/icons/fullscreen.svg';
import { ReactComponent as ExitFullscreenIcon } from '../assets/img/icons/exit-fullscreen.svg';
import { ReactComponent as FileCheckInIcon } from '../assets/img/icons/file-check-in.svg';
import { ReactComponent as CoinDollarIcon } from '../assets/img/icons/coin-dollar.svg';
import { ReactComponent as StarIcon } from '../assets/img/icons/star.svg';
import { ReactComponent as ImportIcon } from '../assets/img/icons/import.svg';
import { ReactComponent as ScaleIcon } from '../assets/img/icons/scale.svg';
import { ReactComponent as CoinsIcon } from '../assets/img/icons/coins.svg';
import { ReactComponent as StarFilledIcon } from '../assets/img/icons/star-filled.svg';
import { ReactComponent as EraserIcon } from '../assets/img/icons/eraser.svg';
import { ReactComponent as TrashCanIcon } from '../assets/img/icons/trash-can.svg';
import { ReactComponent as TransactionIcon } from '../assets/img/icons/transaction.svg';
import { ReactComponent as ImageUploadIcon } from '../assets/img/icons/image-upload.svg';
import { ReactComponent as EditIcon } from '../assets/img/icons/edit.svg';
import { ReactComponent as ChevronRightSmallIcon } from '../assets/img/icons/chevron-right-small.svg';
import { ReactComponent as ChevronDownSmallIcon } from '../assets/img/icons/chevron-down-small.svg';
import { ReactComponent as ImageUploadBigIcon } from '../assets/img/icons/image-upload-big.svg';
import { ReactComponent as OracleIcon } from '../assets/img/icons/nav/oracle.svg';
import { ReactComponent as ChainlinkIcon } from '../assets/img/icons/chainlink.svg';
import { ReactComponent as ChatDotsIcon } from '../assets/img/icons/chat-dots.svg';
import { ReactComponent as ChatIcon } from '../assets/img/icons/chat.svg';
import { ReactComponent as CloseSmallIcon } from '../assets/img/icons/close-small.svg';
import { ReactComponent as FileCodeIcon } from '../assets/img/icons/file-code.svg';
import { ReactComponent as FileListIcon } from '../assets/img/icons/file-list.svg';
import { ReactComponent as ExternalLinkIcon } from '../assets/img/icons/external-link.svg';
import { ReactComponent as LinkedinIcon } from '../assets/img/icons/linkedin-icon.svg';
import { ReactComponent as CopyIcon } from '../assets/img/icons/copy.svg';
import { ReactComponent as UsersWhiteIcon } from '../assets/img/icons/users-white.svg';
import { ReactComponent as CheckIcon } from '../assets/img/icons/check.svg';
import { ReactComponent as BurgerMenuIcon } from '../assets/img/icons/burger-menu.svg';
import { ReactComponent as InfoIcon } from '../assets/img/icons/info.svg';
import { ReactComponent as PlusIcon } from '../assets/img/icons/plus.svg';
import { ReactComponent as MinusIcon } from '../assets/img/icons/minus.svg';
import { ReactComponent as PlusCircleIcon } from '../assets/img/icons/plus-circle.svg';
import { ReactComponent as GlobeIcon } from '../assets/img/icons/globe.svg';
import { ReactComponent as ClockIcon } from '../assets/img/icons/clock.svg';
import { ReactComponent as LightBulbIcon } from '../assets/img/icons/light-bulb.svg';
import { ReactComponent as TelegramIcon } from '../assets/img/icons/telegram.svg';
import { ReactComponent as TelegramColorIcon } from '../assets/img/icons/telegram-color.svg';
import { ReactComponent as RedditIcon } from '../assets/img/icons/reddit.svg';
import { ReactComponent as ListIcon } from '../assets/img/icons/list.svg';
import { ReactComponent as ManageSearchIcon } from '../assets/img/icons/manage-search.svg';
import { ReactComponent as RocketIcon } from '../assets/img/icons/rocket.svg';
import { ReactComponent as IdeaIcon } from '../assets/img/icons/idea.svg';
import { ReactComponent as FolderCheckIcon } from '../assets/img/icons/folder-check.svg';
import { ReactComponent as PlanetIcon } from '../assets/img/icons/planet.svg';
import { ReactComponent as CircularIcon } from '../assets/img/icons/circular.svg';
import { ReactComponent as RoadSignIcon } from '../assets/img/icons/road-sign.svg';
import { ReactComponent as MoreIcon } from '../assets/img/icons/more.svg';
import { ReactComponent as ForkIcon } from '../assets/img/icons/fork.svg';
import { ReactComponent as PencilIcon } from '../assets/img/icons/pencil.svg';
import { ReactComponent as UserPlusIcon } from '../assets/img/icons/user_plus.svg';
import { ReactComponent as EtherIcon } from '../assets/img/icons/ether.svg';
import { ReactComponent as DepositIcon } from '../assets/img/icons/deposit.svg';
import { ReactComponent as BorrowIcon } from '../assets/img/icons/borrow.svg';
import { ReactComponent as RepayIcon } from '../assets/img/icons/repay.svg';
import { ReactComponent as ReedemPayIcon } from '../assets/img/icons/reedem-pay.svg';
import { ReactComponent as OutlineMail } from '../assets/img/icons/outline-mail.svg';
import { ReactComponent as ExponentialGraphIcon } from '../assets/img/icons/exponential-graph.svg';
import { ReactComponent as HeartIcon } from '../assets/img/icons/heart-icon.svg';
import { ReactComponent as LockIcon } from '../assets/img/icons/lock-icon.svg';
import { ReactComponent as CoinsStackIcon } from '../assets/img/icons/coins-stack-icon.svg';
import { ReactComponent as LocationWarningIcon } from '../assets/img/icons/location-warning-icon.svg';
import { ReactComponent as WarningIcon } from '../assets/img/warning.svg';
import { ReactComponent as WalletIcon } from '../assets/img/icons/wallet-icon.svg';
import { ReactComponent as ChartMinimumIcon } from '../assets/img/icons/chart-minimum.svg';
import { ReactComponent as LineUpIcon } from '../assets/img/icons/line-up.svg';
import { ReactComponent as LineUpSmoothIcon } from '../assets/img/icons/line-up-smooth.svg';
import { ReactComponent as LineDownIcon } from '../assets/img/icons/line-down.svg';
import { ReactComponent as LineDownSmoothIcon } from '../assets/img/icons/line-down-smooth.svg';
import { ReactComponent as DollarInIcon } from '../assets/img/icons/dollar-in.svg';
import { ReactComponent as DollarOutIcon } from '../assets/img/icons/dollar-out.svg';
import { ReactComponent as PieChartIcon } from '../assets/img/icons/pie-chart.svg';
import { ReactComponent as WaterfallAvgIcon } from '../assets/img/icons/waterfall-average.svg';
import { ReactComponent as WaterfallUpIcon } from '../assets/img/icons/waterfall-up.svg';
import { ReactComponent as WaterfallDownIcon } from '../assets/img/icons/waterfall-down.svg';
import { ReactComponent as DollarIcon } from '../assets/img/icons/dollar.svg';
import { ReactComponent as CoinInsertIcon } from '../assets/img/icons/coin-insert.svg';
import { ReactComponent as BankIcon } from '../assets/img/icons/bank.svg';
import { ReactComponent as DefaultIcon } from '../assets/img/crypto/default.svg';
import { ReactComponent as QuestionMarkCircleIcon } from '../assets/img/icons/question-mark-circle.svg';
import { ReactComponent as ReloadIcon } from '../assets/img/icons/reload.svg';
import { ReactComponent as RouteIcon } from '../assets/img/icons/route.svg';
import { ReactComponent as CalcIcon } from '../assets/img/icons/calc.svg';
import { ReactComponent as TokenIcon } from '../assets/img/icons/token.svg';
import { ReactComponent as ExportIcon } from '../assets/img/icons/export.svg';

const getIcon = (iconName: string) => {
  const loweCaseIconName = iconName.toLowerCase();
  switch (loweCaseIconName) {
    case 'linkedin':
      return <LinkedinIcon />;
    case 'outline-mail':
      return <OutlineMail />;
    case 'sort-none':
      return <SortNoneIcon />;
    case 'sort-down':
      return <SortDownIcon />;
    case 'sort-up':
      return <SortUpIcon />;
    case 'lab':
      return <LabIcon />;
    case 'briefcase':
      return <BriefCaseIcon />;
    case 'chaos':
      return <ChaosIcon />;
    case 'file-video':
      return <FiledVideo />;
    case 'filter':
      return <FilterIcon />;
    case 'play':
      return <PlayIcon />;
    case 'eraser':
      return <EraserIcon />;
    case 'folder-upload':
      return <FolderUpload />;
    case 'airplay':
      return <AirplayIcon />;
    case 'airplay-gradient-shadow':
      return <AirplayGradientShadowIcon />;
    case 'album-library':
      return <AlbumLibraryIcon />;
    case 'gift':
      return <GiftIcon />;
    case 'grid':
      return <GridIcon />;
    case 'history':
      return <HistoryIcon />;
    case 'keyboard':
      return <KeyboardIcon />;
    case 'palette':
      return <PaletteIcon />;
    case 'perspective':
      return <PerspectiveIcon />;
    case 'vector':
      return <VectorIcon />;
    case 'menu-toggle':
      return <MenuToggleIcon />;
    case 'pie-slice':
      return <PieSliceIcon />;
    case 'check-square':
      return <CheckSquareIcon />;
    case 'checkbox-off':
      return <CheckboxOffIcon />;
    case 'github':
      return <GithubIcon />;
    case 'github-alt':
      return <GithubAltIcon />;
    case 'google':
      return <GoogleIcon />;
    case 'twitter':
      return <TwitterIcon />;
    case 'twitter-filled':
      return <TwitterFilledIcon />;
    case 'outline-twitter':
      return <OutlineTwitter />;
    case 'eye':
      return <EyeIcon />;
    case 'eye-off':
      return <EyeOffIcon />;
    case 'bullet-blue':
      return <BulletBlueIcon />;
    case 'grid-four':
      return <GridFourIcon />;
    case 'grid-two-h':
      return <GridTwoHorizontal />;
    case 'chevron-down':
      return <ChevronDownIcon />;
    case 'chevron-down-small':
      return <ChevronDownSmallIcon />;
    case 'chevron-down-filled':
      return <ChevronDownFilledIcon />;
    case 'chevron-left':
      return <ChevronLeftIcon />;
    case 'chevron-right':
      return <ChevronRightIcon />;
    case 'chevron-right-small':
      return <ChevronRightSmallIcon />;
    case 'remove':
      return <RemoveIcon />;
    case 'close':
      return <CloseIcon />;
    case 'search':
      return <SearchIcon />;
    case 'wi-fi':
      return <WifiIcon />;
    case 'bell':
      return <BellIcon />;
    case 'settings':
      return <SettingsIcon />;
    case 'users':
      return <UsersIcon />;
    case 'sign-out':
      return <SignOutIcon />;
    case 'close-circle':
      return <CloseCircleIcon />;
    case 'check-circle':
      return <CheckCircleIcon />;
    case 'stacked-area-up':
      return <StackedAreaUpIcon />;
    case 'list-boxes':
      return <ListBoxesIcon />;
    case 'exclamation-square':
      return <ExclamationSquareIcon />;
    case 'exclamation-mark':
      return <ExclamationMarkIcon />;
    case 'arrow-left':
      return <ArrowLeftIcon />;
    case 'arrow-right':
      return <ArrowRightIcon />;
    case 'breadcrumbs-arrow':
      return <BreadcrumbsArrowIcon />;
    case 'cloud-download':
      return <CloudDownloadIcon />;
    case 'printer':
      return <PrinterIcon />;
    case 'settings-adjust':
      return <SettingsAdjustIcon />;
    case 'grid-two-v':
      return <GridTwoVerticalIcon />;
    case 'checkbox-indeterminate':
      return <CheckboxIndeterminateIcon />;
    case 'fullscreen':
      return <FullscreenIcon />;
    case 'exit-fullscreen':
      return <ExitFullscreenIcon />;
    case 'coin-dollar':
      return <CoinDollarIcon />;
    case 'coin-insert':
      return <CoinInsertIcon />;
    case 'bank':
      return <BankIcon />;
    case 'star':
      return <StarIcon />;
    case 'file-check-in':
      return <FileCheckInIcon />;
    case 'import':
      return <ImportIcon />;
    case 'user':
      return <UserIcon />;
    case 'scale':
      return <ScaleIcon />;
    case 'coins':
      return <CoinsIcon />;
    case 'star-filled':
      return <StarFilledIcon />;
    case 'trash-can':
      return <TrashCanIcon />;
    case 'transaction':
      return <TransactionIcon />;
    case 'image-upload':
      return <ImageUploadIcon />;
    case 'info':
      return <InfoIcon />;
    case 'edit':
      return <EditIcon />;
    case 'image-upload-big':
      return <ImageUploadBigIcon />;
    case 'oracle':
      return <OracleIcon />;
    case 'chat-dots':
      return <ChatDotsIcon />;
    case 'chat':
      return <ChatIcon />;
    case 'chainlink':
      return <ChainlinkIcon />;
    case 'close-small':
      return <CloseSmallIcon />;
    case 'bubble-race':
      return <BubbleRaceIcon />;
    case 'file-code':
      return <FileCodeIcon />;
    case 'file-list':
      return <FileListIcon />;
    case 'external-link':
      return <ExternalLinkIcon />;
    case 'copy':
      return <CopyIcon />;
    case 'users-white':
      return <UsersWhiteIcon />;
    case 'check':
      return <CheckIcon />;
    case 'burger-menu':
      return <BurgerMenuIcon />;
    case 'plus':
      return <PlusIcon />;
    case 'minus':
      return <MinusIcon />;
    case 'plus-circle':
      return <PlusCircleIcon />;
    case 'view-list':
      return <ViewListIcon />;
    case 'web':
      return <GlobeIcon />;
    case 'clock':
      return <ClockIcon />;
    case 'pie-gradient-shadow':
      return <PieGradientShadowIcon />;
    case 'triangle-gradient-shadow':
      return <TriangleGradientShadowIcon />;
    case 'light-bulb':
      return <LightBulbIcon />;
    case 'telegram':
      return <TelegramIcon />;
    case 'telegram-color':
      return <TelegramColorIcon />;
    case 'reddit':
      return <RedditIcon />;
    case 'list':
      return <ListIcon />;
    case 'manage-search':
      return <ManageSearchIcon />;
    case 'calendar':
      return <CalendarIcon />;
    case 'rocket':
      return <RocketIcon />;
    case 'idea':
      return <IdeaIcon />;
    case 'folder-check':
      return <FolderCheckIcon />;
    case 'planet':
      return <PlanetIcon />;
    case 'circular':
      return <CircularIcon />;
    case 'road-sign':
      return <RoadSignIcon />;
    case 'more':
      return <MoreIcon />;
    case 'fork':
      return <ForkIcon />;
    case 'user_plus':
      return <UserPlusIcon />;
    case 'pencil':
      return <PencilIcon />;
    case 'ether':
      return <EtherIcon />;
    case 'deposit-icon':
      return <DepositIcon />;
    case 'borrow-icon':
      return <BorrowIcon />;
    case 'repay-icon':
      return <RepayIcon />;
    case 'redeem-icon':
      return <ReedemPayIcon />;
    case 'exponential-graph':
      return <ExponentialGraphIcon />;
    case 'heart-icon':
      return <HeartIcon />;
    case 'hathor':
      return <HathorIcon />;
    case 'lock-icon':
      return <LockIcon />;
    case 'coins-stack-icon':
      return <CoinsStackIcon />;
    case 'location-warning-icon':
      return <LocationWarningIcon />;
    case 'wallet-icon':
      return <WalletIcon />;
    case 'warning':
      return <WarningIcon />;
    case 'card-eject':
      return <CardEjectIcon />;
    case 'card-lock':
      return <CardLockIcon />;
    case 'chart-minimum':
      return <ChartMinimumIcon />;
    case 'line-up':
      return <LineUpIcon />;
    case 'line-up-smooth':
      return <LineUpSmoothIcon />;
    case 'line-down':
      return <LineDownIcon />;
    case 'line-down-smooth':
      return <LineDownSmoothIcon />;
    case 'dollar-in':
      return <DollarInIcon />;
    case 'dollar-out':
      return <DollarOutIcon />;
    case 'pie-chart':
      return <PieChartIcon />;
    case 'waterfall-average':
      return <WaterfallAvgIcon />;
    case 'waterfall-up':
      return <WaterfallUpIcon />;
    case 'waterfall-down':
      return <WaterfallDownIcon />;
    case 'dollar':
      return <DollarIcon />;
    case 'question-mark':
      return <QuestionMarkCircleIcon />;
    case 'reload':
      return <ReloadIcon />;
    case 'route':
      return <RouteIcon />;
    case 'calc':
      return <CalcIcon />;
    case 'token':
      return <TokenIcon />;
    case 'export':
      return <ExportIcon />;
    default:
      return <DefaultIcon />;
  }
};

enum IconSize {
  'x-small' = 16,
  'small' = 20,
  'default' = 24,
}

export interface CustomIconProps {
  icon: string
  className?: string
  sx?: SxProps
  size?: 'x-small' | 'small' | 'default'
}

export const CustomIcon = React.forwardRef<unknown, CustomIconProps>(
  ({
    icon, className, sx, size = 'default', ...props
  }, ref) => {
    const sizeValue = size && IconSize[size];
    return (
      <Box
        {...props}
        component="span"
        ref={ref}
        className={`${icon}-icon ${className || ''}`}
        sx={{
          display: 'inline-flex',
          verticalAlign: 'middle',
          alignItems: 'center',
          svg: {
            ...(sizeValue && { width: sizeValue, height: sizeValue }),
            display: 'block',
          },
          ...sx,
        }}
      >
        {getIcon(icon)}
      </Box>
    );
  },
);

CustomIcon.defaultProps = {
  className: undefined,
  sx: undefined,
  size: undefined,
};
