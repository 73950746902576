import { TokenTickerEnum } from '@chaos/types';
import bitcoinSvg from 'src/assets/img/crypto/bitcoin.svg';
import ethSvg from 'src/assets/img/crypto/ethereum.svg';
import solSvg from 'src/assets/img/crypto/sol.svg';
import aaveSvg from 'src/assets/img/crypto/aave.svg';
import avaxSvg from 'src/assets/img/crypto/avax.svg';
import celoSvg from 'src/assets/img/crypto/celo.svg';
import compoundSvg from 'src/assets/img/crypto/compound.svg';
import daiSvg from 'src/assets/img/crypto/dai.svg';
import linkSvg from 'src/assets/img/crypto/link.svg';
import lunaSvg from 'src/assets/img/crypto/luna.svg';
import maticSvg from 'src/assets/img/crypto/matic.svg';
import mkrSvg from 'src/assets/img/crypto/maker.svg';
import nearSvg from 'src/assets/img/crypto/near.svg';
import graphSvg from 'src/assets/img/crypto/the-graph.svg';
import uniSvg from 'src/assets/img/crypto/uni.svg';
import usdtSvg from 'src/assets/img/crypto/usdt.svg';
import usdcSvg from 'src/assets/img/crypto/usdc.svg';
import algoRandSvg from 'src/assets/img/crypto/algorand.svg';
import dotSvg from 'src/assets/img/crypto/dot.svg';
import snxSvg from 'src/assets/img/crypto/snx.svg';
import gbpSvg from 'src/assets/img/crypto/gbp.svg';
import xrpSvg from 'src/assets/img/crypto/xrp.svg';
import cadSvg from 'src/assets/img/crypto/cad.svg';
import eurSvg from 'src/assets/img/crypto/eur.svg';
import dogeSvg from 'src/assets/img/crypto/doge.svg';
import audSvg from 'src/assets/img/crypto/aud.svg';
import crvSvg from 'src/assets/img/crypto/crv.svg';
import zrxSvg from 'src/assets/img/crypto/zrx.svg';
import batSvg from 'src/assets/img/crypto/bat.svg';
import qiSvg from 'src/assets/img/crypto/qi.svg';
import mfamSvg from 'src/assets/img/crypto/moonwell.svg';

export const TokenData = {
  AAVE: aaveSvg,
  AVAX: avaxSvg,
  BAT: batSvg,
  BTC: bitcoinSvg,
  GBP: gbpSvg,
  CAD: cadSvg,
  CRV: crvSvg,
  AUD: audSvg,
  EUR: eurSvg,
  DOGE: dogeSvg,
  ETH: ethSvg,
  SOL: solSvg,
  SNX: snxSvg,
  CELO: celoSvg,
  COMP: compoundSvg,
  DAI: daiSvg,
  LINK: linkSvg,
  LUNA: lunaSvg,
  MATIC: maticSvg,
  MKR: mkrSvg,
  MFAM: mfamSvg,
  MOONWELL: mfamSvg,
  NEAR: nearSvg,
  GRT: graphSvg,
  QI: qiSvg,
  UNI: uniSvg,
  USDT: usdtSvg,
  USDTE: usdtSvg,
  USDC: usdcSvg,
  USD: usdcSvg,
  RAND: algoRandSvg,
  DOT: dotSvg,
  XRP: xrpSvg,
  ZRX: zrxSvg,
};

export const useTickerSvg = (ticker: TokenTickerEnum): string => {
  if (!(ticker in TokenData)) {
    throw new Error(`Requested unsupported token... ${ticker}`);
  }
  if (ticker === TokenTickerEnum.AAVE) {
    return TokenData.AAVE;
  } if (ticker === TokenTickerEnum.ETH) {
    return TokenData.ETH;
  } if (ticker === TokenTickerEnum.AVAX) {
    return TokenData.AVAX;
  } if (
    ticker === TokenTickerEnum.BTC
    || ticker === TokenTickerEnum.WBTC
  ) {
    return TokenData.BTC;
  } if (ticker === TokenTickerEnum.SOL) {
    return TokenData.SOL;
  } if (ticker === TokenTickerEnum.CELO) {
    return TokenData.CELO;
  } if (ticker === TokenTickerEnum.COMP) {
    return TokenData.COMP;
  } if (
    ticker === TokenTickerEnum.LINK
    || ticker === TokenTickerEnum.LINKE
  ) {
    return TokenData.LINK;
  } if (ticker === TokenTickerEnum.LUNA) {
    return TokenData.LUNA;
  } if (ticker === TokenTickerEnum.MATIC) {
    return TokenData.MATIC;
  } if (ticker === TokenTickerEnum.MKR) {
    return TokenData.MKR;
  }
  if (ticker === TokenTickerEnum.MFAM) {
    return TokenData.MFAM;
  }

  if (ticker === TokenTickerEnum.GRT) {
    return TokenData.GRT;
  } if (ticker === TokenTickerEnum.UNI) {
    return TokenData.UNI;
  } if (ticker === TokenTickerEnum.USDC) {
    return TokenData.USDC;
  } if (ticker === TokenTickerEnum.USDTE) {
    return TokenData.USDC;
  } if (ticker === TokenTickerEnum.USDT) {
    return TokenData.USDT;
  } if (ticker === TokenTickerEnum.DAI) {
    return TokenData.DAI;
  } if (ticker === TokenTickerEnum.NEAR) {
    return TokenData.NEAR;
  } if (ticker === TokenTickerEnum.RAND) {
    return TokenData.RAND;
  } if (ticker === TokenTickerEnum.DOT) {
    return TokenData.DOT;
  } if (ticker === TokenTickerEnum.SNX) {
    return TokenData.SNX;
  } if (ticker === TokenTickerEnum.GBP) {
    return TokenData.GBP;
  } if (ticker === TokenTickerEnum.CAD) {
    return TokenData.CAD;
  } if (ticker === TokenTickerEnum.CRV) {
    return TokenData.CRV;
  } if (ticker === TokenTickerEnum.DOGE) {
    return TokenData.DOGE;
  } if (ticker === TokenTickerEnum.AUD) {
    return TokenData.AUD;
  } if (ticker === TokenTickerEnum.EUR) {
    return TokenData.EUR;
  } if (ticker === TokenTickerEnum.XRP) {
    return TokenData.XRP;
  } if (ticker === TokenTickerEnum.ZRX) {
    return TokenData.ZRX;
  } if (ticker === TokenTickerEnum.BAT) {
    return TokenData.BAT;
  } if (ticker === TokenTickerEnum.USD) {
    return TokenData.USDC;
  }
  // if (ticker === TokenTickerEnum.QIAVAX) {
  //   return TokenData.AVAX;
  // } if (ticker === TokenTickerEnum.QIDAI) {
  //   return TokenData.DAI;
  // } if (ticker === TokenTickerEnum.QIETH) {
  //   return TokenData.ETH;
  // } if (ticker === TokenTickerEnum.QIUSDC) {
  //   return TokenData.USDC;
  // } if (ticker === TokenTickerEnum.QIQI) {
  //   return TokenData.QI;
  // } if (ticker === TokenTickerEnum.QI) {
  //   return TokenData.QI;
  // }
  return TokenData.BTC;
};
