import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Paper } from '@chaos/ui';
import { SimulationTransaction } from '@chaos/types';
import { Tabs } from '@chaos/ui/tabs';
import { useTabsControl } from 'src/hooks/useTabsControl';
import { useSimulationTransactionEvents } from 'src/hooks/useSimulationTransactionEvents';
import { OverviewTab } from './overview-tab';
import { EventsTab } from './events-tab';

type TransactionDetailsProps = {
  transaction: SimulationTransaction,
};

export const TransactionDetails: FC<TransactionDetailsProps> = ({ transaction }) => {
  const { simulationResultId } = useParams<{ simulationResultId: string }>();
  const tabs = [{ label: 'Overview' }, { label: 'Events' }];
  const { dataForRender, handleTabChange } = useTabsControl([0, 1], 0);
  const { simulationTransactionEvents } = useSimulationTransactionEvents(
    simulationResultId!,
    transaction.hash,
    transaction.connectionKey,
  );

  return (
    <Paper variant="card">
      {simulationTransactionEvents?.length > 0 && (
        <Box mb={3}>
          <Tabs tabs={tabs} value={dataForRender} onChange={handleTabChange} />
        </Box>
      )}
      {dataForRender === 0
        && <OverviewTab simulationResultId={simulationResultId!} transaction={transaction} />}
      {dataForRender === 1 && (
        <EventsTab
          simulationResultId={simulationResultId!}
          transactionHash={transaction.hash}
          connectionKey={transaction.connectionKey}
        />
      )}
    </Paper>
  );
};
