import { BlockchainSimulation, PermutationInfo } from '@chaos/types';
import { Accordion } from 'src/components/accordion';
import { Box, Typography, Grid } from '@chaos/ui';
import { SimulationResultInfoCard } from 'src/components/simulation-result-info-card';

type Props = {
  simulation: BlockchainSimulation;
  permutationInfo: PermutationInfo;
  repetitionsCount: number;
};

const Overview = ({ simulation, permutationInfo, repetitionsCount }: Props) => (
  <Accordion
    defaultExpanded
    header={(
      <Box sx={{
        display: 'flex', alignItems: 'center',
      }}
      >
        <Typography variant="h2">
          General
        </Typography>
      </Box>
    )}
  >
    <Box py={3}>
      <Grid container spacing={3}>
        {permutationInfo.args.map(((arg, idx) => (
          <Grid item xs={12} md={4} key={`${arg.argName}${idx.toString()}`}>
            <SimulationResultInfoCard
              title={arg.argName}
              value={arg.argValue.toString()}
            />
          </Grid>
        )))}
        <Grid item xs={12} md={4}>
          <SimulationResultInfoCard
            title="# of repetitions"
            value={repetitionsCount}
          />
        </Grid>
        <Grid item xs={12}>
          <SimulationResultInfoCard
            title="Description"
            value={simulation.description || '-'}
          />
        </Grid>
      </Grid>
    </Box>
  </Accordion>
);

export default Overview;
