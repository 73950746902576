import { Team, Permissions } from '@chaos/types';
import { currentPagePath } from '@chaos/utils';
import { NavLink, SingleLink } from './types';

export const teamHasNavLink = (navLink: SingleLink, team?: Team) => !navLink.permission
  || Boolean(team?.permissions?.includes(navLink.permission));

export const isMultiLink = (link: NavLink): boolean => 'title' in link;

export const filterLinksByPermission = (links: NavLink[], team?: Team): NavLink[] => links.filter(
  (link) => teamHasNavLink(link as SingleLink, team),
);

export const isLinkDisabled = (
  to: string,
  permission?: Permissions,
  isAuthed?: boolean,
  isAdmin?: boolean,
  team?: Team,
) => (
  !to.startsWith(currentPagePath()) && !isAuthed
) || (!isAdmin && permission && !team?.permissions.includes(permission));
