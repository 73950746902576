import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { usePageTitle } from 'src/hooks';
import { PageTracker } from 'src/components/page-tracker';
import { useBlockchainScenarios } from 'src/hooks/useBlockchainScenarios';
import { EmptyState } from 'src/components/empty-state';
import { RoutePath } from 'src/config/routes';
import { ScriptResourceTable } from 'src/components/script-resource-table';
import { ScriptResource } from '@chaos/types';
import { MainLayout } from 'src/components/layouts';

export const ScenarioCataloguePage = PageTracker((): JSX.Element => {
  usePageTitle('Scenario Catalog');
  const navigate = useNavigate();
  const { isLoading, blockchainScenarios } = useBlockchainScenarios();

  const isScenariosExist = blockchainScenarios.length > 0;

  return (
    <MainLayout
      height={0}
      headerProps={{
        pageTitle: 'Scenarios',
        buttons: [{
          children: 'Create Scenario',
          onClick: () => navigate(RoutePath.ScenarioCatalogue.Create),
        }],
      }}
    >
      {isLoading && (
      <CircularProgress />
      )}

      {!isLoading && !isScenariosExist && <EmptyState type="scenarios" />}
      {!isLoading && isScenariosExist && (
        <ScriptResourceTable
          circleAvatarType="long"
          data={blockchainScenarios}
          rowHref={(scenario: ScriptResource) => RoutePath.ScenarioCatalogue.Details.replace(':id', scenario.id)}
        />
      )}
    </MainLayout>
  );
});
