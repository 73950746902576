import { AggregationName, MetricStatistics, ObservationAggregationResult } from '@chaos/types';
import { formatAxis } from '@chaos/ui/utils/formatters';
import { HistogramChartRow } from 'src/components/charts';
import { getDistributionAxisData } from '../utils';

type Props = {
  aggregationMethod: AggregationName;
  selectedMetricStatistics: MetricStatistics;
  observationsMetaData: Record<string, ObservationAggregationResult>[];
};

const MetricDistribution = ({
  selectedMetricStatistics, aggregationMethod, observationsMetaData,
}: Props) => {
  const values = observationsMetaData.reduce<number[]>((res, m) => {
    const value = Object.values(m)
      .find((v) => v.id === selectedMetricStatistics.id)?.aggregations[aggregationMethod];
    return value ? [...res, value] : res;
  }, []);

  const { data: histogramData, numberOfFractionDigits } = getDistributionAxisData(values);

  return (
    <HistogramChartRow
      title="Observations Distribution"
      description="This chart visualizes the distribution of metric results"
      data={[histogramData]}
      legend={['number of repetitions']}
      labels={({ datum: { x, y } }) => `Repetitions - ${formatAxis(y)}\nValue - ${formatAxis(x)}`}
      tickMaximumFractionDigits={numberOfFractionDigits}
    />
  );
};

export default MetricDistribution;
