/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useContext, useState } from 'react';
import { Box } from '@chaos/ui/box';
import { IconButton } from '@chaos/ui/icon-button';
import { load, saveString } from 'src/utils/storage';
import { useAppSelector } from 'src/store';
import { isEmpty } from 'react-redux-firebase';
import { CustomIcon } from 'src/components/custom-icon';
import { TeamContext } from 'src/context/team-context';
import { NavMenu } from './nav-components';
import logo from '../../assets/img/logo.svg';
import {
  APP_NAV_CLOSE_BTN_STYLES, APP_NAV_STYLES,
} from './styles';
import { NavFooter } from './nav-footer';
import { TeamSwitcher } from './team-switcher';
import { ProfileSection } from './profile-section';
import { SignInSection } from './sign-in-section';

const SAVE_KEY = 'nav-menu';

export const AppNav = (): JSX.Element => {
  const [menuOpened, setMenuOpened] = useState<boolean>(Boolean(load(SAVE_KEY)));
  const auth = useAppSelector((state) => state.firebase.auth);
  const isAuthed = !isEmpty(auth);
  const { team } = useContext(TeamContext);
  const toggleMenu = () => {
    saveString(SAVE_KEY, (!menuOpened).toString());
    setMenuOpened((prev) => !prev);
  };

  return (
    <Box
      sx={{ position: 'relative', height: '100%' }}
      className={`${menuOpened ? '' : '_nav-collapsed'}`}
    >
      <Box sx={APP_NAV_STYLES}>
        <Box
          zIndex={2}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '168px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexShrink: 0,
              padding: { xs: '20px 0', md: '40px 0 40px' },
              transition: 'width 0.3s ease',
              overflow: team ? 'visible' : 'hidden',
              '._nav-collapsed &': {
                width: 24,
              },
            }}
          >
            {team ? <TeamSwitcher menuOpen={menuOpened} /> : <img src={logo} width="138" height="24" alt="Chaos Labs" /> }
          </Box>

          <Box flex={1}>
            <NavMenu menuOpened={menuOpened} />
          </Box>
          {isAuthed ? (
            <ProfileSection />
          ) : (
            <SignInSection />
          )}
          <NavFooter team={team} />
        </Box>
      </Box>
      <Box sx={APP_NAV_CLOSE_BTN_STYLES}>
        <IconButton onClick={toggleMenu} size="medium">
          <CustomIcon className="arrow" icon="menu-toggle" />
        </IconButton>
      </Box>
    </Box>
  );
};
