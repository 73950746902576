import { ArgType, isTypedArg, ScriptArg } from '@chaos/types';

export const parseValueFromArg = (arg: ScriptArg): string => {
  if (!arg.value || isTypedArg(arg)) return '';

  if (arg.type?.endsWith('[]')) {
    return (arg.value as (string | number)[]).join(',');
  }

  return arg.value.toString();
};

export const mapValueToArg = (argType: ArgType, arg: ScriptArg, value?: string): ScriptArg => {
  if (isTypedArg(arg)) {
    return {
      ...arg,
      type: argType,
      value: undefined,
    };
  }

  if (!value) {
    return {
      ...arg,
      value: undefined,
    };
  }

  if (argType === 'number[]' || argType === 'string[]') {
    return {
      ...arg,
      value: value.split(','),
    };
  }

  return {
    ...arg,
    value,
  };
};
