/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FC } from 'react';

import { Grid } from '@chaos/ui/grid';
import { Box } from '@chaos/ui/box';
import { Typography } from '@chaos/ui/typography';
import { ChartData } from '@chaos/types';
import { PieChart } from 'src/components/charts';

export const PieChartContainer: FC<ChartData> = ({
  chartTitle,
  chartSubtitle,
  dataPayload,
  columnsToFill,
  labelPrefix,
  labelSuffix,
  hideChartLegends,
}): JSX.Element => (
  <Grid
    item
    xs={12}
    xl={columnsToFill || 6}
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <>
      <span data-testid="chart-title"><Typography variant="h2">{chartTitle?.value}</Typography></span>
      <Typography
        variant="body1"
        sx={{ color: '#9B9DA1' }}
        data-testid="chart-subtitle"
      >
        {chartSubtitle?.value}
      </Typography>
      <Box sx={{ height: { xs: 400, sm: 368 } }} display="flex">
        {/* eslint-disable-next-line */}
        <PieChart data={dataPayload} labelPrefix={labelPrefix} labelSuffix={labelSuffix} hideChartLegends={hideChartLegends} />
      </Box>
    </>
  </Grid>
);
