import { Header, RenderData } from '@chaos/types';
import {
  ChaosTable, chainCell, iconsCell, textCell,
} from '@chaos/ui';
import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { RouteParams, RoutePath } from 'src/config/routes';
import { toTitleCase } from '@chaos/ui/utils/formatters';
import { useMarketsByProtocol, useModelRuns } from '../../data/hooks';
import { chainMarketProtocols } from '../../types';

const DATA_PAGE_SIZE = 30;
const TABLE_PAGE_SIZE = 15;

const headers: Header[] = [{
  renderType: 'TEXT',
  text: 'Protocol',
  nonSortable: true,
}, {
  renderType: 'TEXT',
  text: 'Market',
  nonSortable: true,
}, {
  renderType: 'TEXT',
  text: 'Assets',
  nonSortable: true,
}, {
  renderType: 'TEXT',
  text: 'Model',
  nonSortable: true,
}, {
  renderType: 'TEXT',
  text: 'Status',
  nonSortable: true,
}, {
  renderType: 'TEXT',
  text: 'Start Time',
  nonSortable: true,
}];

const ModelRunsTable = () => {
  const [lastModelId, setLastModelId] = useState<string>();
  const { isLoading, modelRuns } = useModelRuns(lastModelId, DATA_PAGE_SIZE);
  const { isLoading: isLoadingMarkets, marketsByProtocol } = useMarketsByProtocol();

  const data: RenderData[][] = useMemo(() => modelRuns.map((modelRun) => {
    const { protocol, marketId } = modelRun;

    const markets = protocol ? marketsByProtocol?.[protocol.toLowerCase()] : [];
    const market = markets?.find((m) => m.id === marketId);

    return [
      textCell(protocol ? toTitleCase(protocol) : ''),
      chainMarketProtocols.includes(protocol?.toLowerCase() || '') ? chainCell(market?.name, market?.name) : textCell(market?.name || ''),
      iconsCell(modelRun.assets || []),
      textCell(modelRun.modelName || ''),
      textCell(modelRun.status),
      textCell(dayjs(modelRun.created).format('DD MMM HH:mm')),
    ];
  }), [modelRuns, marketsByProtocol]);

  const rowHref = (index: number) => {
    const modelRun = modelRuns[index];

    if (!modelRun) return '';

    return RoutePath.ParamRecommendationsTool.ModelRunDetails
      .replace(RouteParams.Id, modelRun.modelRunId);
  };

  const onPageChange = (pageNumber: number) => {
    if ((pageNumber * TABLE_PAGE_SIZE) >= modelRuns.length) {
      setLastModelId(modelRuns[modelRuns.length - 1].modelRunId);
    }
  };

  return (
    <ChaosTable
      headers={headers}
      data={isLoadingMarkets ? [] : data}
      pageSize={TABLE_PAGE_SIZE}
      rowHref={rowHref}
      onPageChange={onPageChange}
      isLoading={isLoading || isLoadingMarkets}
    />
  );
};

export default ModelRunsTable;
