/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
import { FC, forwardRef, useCallback } from 'react';
import {
  SnackbarContent, SnackbarKey, SnackbarMessage, useSnackbar,
} from 'notistack';
import { Box, Paper } from '@chaos/ui';
import { IconButton } from '@mui/material';
import { CustomIcon } from '../custom-icon';

export type SnackbarActionButton = { text: string, onClick: () => void };

export type ChaosSnackbarProps = {
  message: SnackbarMessage,
  id: SnackbarKey,
  icon?: string,
  actionButton?: SnackbarActionButton,
  onClick?: () => void,
};

const ChaosSnackbar: FC<ChaosSnackbarProps> = forwardRef<HTMLDivElement, ChaosSnackbarProps>(({
  id, icon, message, actionButton, onClick,
}, ref) => {
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref} role="alert">
      <Box
        sx={{
          borderRadius: '16px',
          width: '422px',
          background: 'linear-gradient(154.16deg, #31D8BF -3.9%, #0965F1 126.56%)',
          cursor: onClick ? 'pointer' : 'initial',
        }}
        onClick={onClick ? () => {
          onClick();
          handleDismiss();
        } : undefined}
      >
        <Paper
          variant="card"
          sx={{
            margin: '1px',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '12px 24px',
            background: '#17191E',
          }}
        >
          <Box sx={{
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'flex',
            alignItems: 'flex-start',
            height: '100%',
          }}
          >
            {icon && (
            <CustomIcon
              icon={icon}
              sx={{ '& svg path': { fill: 'url(#default-gradient)' }, marginRight: '10px' }}
            />
            )}
            <Box sx={{
              height: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontWeight: 600,
            }}
            >
              {message}
            </Box>
          </Box>
          <IconButton onClick={(e) => {
            e.stopPropagation();
            handleDismiss();
          }}
          >
            <CustomIcon
              sx={{ width: '12px' }}
              icon="close"
            />
          </IconButton>
          {
            (actionButton && (
              <Box
                onClick={() => {
                  // eslint-disable-next-line
                  actionButton.onClick();
                  handleDismiss();
                }}
                className="gradient-link"
                sx={{ cursor: 'pointer', marginLeft: '56px', fontWeight: 700 }}
              >
                {/* eslint-disable-next-line */}
                {actionButton.text}
              </Box>
            ))
          }
        </Paper>
      </Box>
    </SnackbarContent>
  );
});

export default ChaosSnackbar;
