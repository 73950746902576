import { palette } from '../theme/palette';

export const theme = {
  backgroundColor: 'transparent',
  titleColor: '#ffffff',
  subtitleColor: '#ffffff',
  textColorShow: false,
  textColor: palette.almostWhite.main,
  markTextColor: 'rgba(255,255,255,0.75)',
  textStyle: {
    fontFamily: 'Archivo',
    fontSize: window.innerWidth > 960 ? 14 : 10,
    lineHeight: window.innerWidth > 960 ? 20 : 10,
  },
  legend: {
    textStyle: {
      color: palette.almostWhite.main,
    },
    left: 0,
    icon: 'circle',
    itemWidth: 8,
    itemHeight: 8,
    itemGap: 16,
    pageTextStyle: {
      color: '#fff',
    },
    pageIconColor: '#ffffff8f',
    pageIconInactiveColor: '#ffffff2f',
    width: '100%',
  },
  color: [
    '#FFA318',
    '#24B3D0',
    '#2DB574',
    '#DE4469',
    '#7146A0',
    '#0366C1',
    '#9a22d2',
    '#0e55bf',
    palette.orange.active,
    palette.blue.active,
    palette.green.active,
    palette.red.active,
    palette.violette.active,
  ],
  borderColor: '#cccccc',
  borderWidth: '0',
  visualMapColor: [
    '#516b91',
    '#59c4e6',
    '#a5e7f0',
  ],
  tooltip: {
    backgroundColor: palette.darkGrey.main,
    borderWidth: '0',
    textStyle: {
      color: '#fff',
    },
  },
  visualMap: {
    textStyle: {
      color: palette.almostWhite.main,
    },
  },
  timeAxis: {
    nameTextStyle: {
      color: palette.white.main,
    },
    axisLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
    axisLabel: {
      textStyle: {
        color: palette.almostWhite.main,
      },
    },
  },
  valueAxis: {
    nameTextStyle: {
      color: palette.white.main,
    },
    axisLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
    axisLabel: {
      show: true,
      margin: 16,
      textStyle: {
        color: palette.almostWhite.main,
        fontSize: 14,
        lineHeight: 24,
      },
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ['#ffffff33'],
        type: [4, 4],
      },
    },
  },
  grid: {
    containLabel: true,
    left: 0,
    top: 8,
    right: 0,
    bottom: 0,
  },
  categoryAxis: {
    nameTextStyle: {
      color: palette.white.main,
    },
    axisLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
    axisLabel: {
      show: true,
      margin: 16,
      textStyle: {
        color: palette.almostWhite.main,
        fontSize: 14,
        lineHeight: 24,
      },
    },
    splitLine: {
      show: false,
    },
  },
};
