import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Paper, Typography } from '@chaos/ui';
import { IconButton } from '@chaos/ui/icon-button';
import { MainLayout } from 'src/components/layouts';
import { PageTracker } from 'src/components/page-tracker';
import { RouteParams, RoutePath } from 'src/config/routes';
import { ChaosTable } from 'src/components/widgets';
import {
  FetchStorageBlobs,
  useBlockchainSimulationResult,
  useCurrentTeam,
  usePageTitle,
} from 'src/hooks';
import { blockchaiSimulationResultCounts } from 'src/utils/blockchain-simulation-result-counts';
import { nameFromPath, NumericObservers, ObserversView } from 'src/components/observers';
import { useFirebase } from 'react-redux-firebase';
import { SimulationError } from 'src/components/simulatiion-error';
import { SimulationResultGeneralInfo } from 'src/components/simulation-result-general-info';
import { SimulationConfigurationDetails } from 'src/components/simulation-configuration-details';
import { isSimulationOnFinishedStatus, isSimulationStateOnScriptExecutionStatus } from 'src/utils/simulation-helper';
import { simplifyForSearch as simplify } from 'src/utils/search';
import { SearchBar } from 'src/components/search-bar';
import { CustomIcon } from 'src/components/custom-icon';
import { LoadingObservers } from 'src/components/observers/loading-observers';
import { Filter, TableFilter } from 'src/components/widgets/table-filter';
import { TableAppliedFilters } from 'src/components/widgets/table-applied-filters';
import { usePermission } from 'src/hooks/usePermissions';
import { chaosSnackbar } from 'src/utils/chaos-snackbar-utils';
import { useAppSelector } from 'src/store';
import { cancelBlockchainSimulationFn } from 'src/components/dashboard/network';
import { usePythonSimulationResult } from 'src/hooks/usePythonSimulationResult';
import { assertionViewHeaders, blockchainSimulationTitle, ResultToassertionsToView } from './helpers';
import { FilterRange } from '../../components/widgets/table-filter';

export const SimulationResultPage = PageTracker((): JSX.Element => {
  const firebase = useFirebase();
  const { simulationResultId } = useParams<{ simulationResultId: string }>();
  const [observationsSearchValue, setObservationsSearchValue] = useState('');
  const navigate = useNavigate();

  const {
    result,
    simulation,
    agents,
    scenarios,
    assertions,
    observers,
  } = useBlockchainSimulationResult(simulationResultId!);
  const { result: pythonResult } = usePythonSimulationResult(result?.pythonSimId);

  const pageTitle = simulation?.name || '';
  const counts = blockchaiSimulationResultCounts(result);
  const isFinished = result?.state?.toLowerCase() === 'simulation finished';
  const simulationTitle = simulation?.name || blockchainSimulationTitle(result);
  usePageTitle(simulationTitle);

  const [isLoadingObservations, setIsLoadingObservations] = useState<boolean>();
  const [observations, setObservations] = useState<NumericObservers>();

  const isObserversValid = useMemo(
    () => !!observations && observers && observers.length > 0,
    [observations, observers],
  );

  const paths = useMemo(() => {
    const { observationsRef, observations: observationsPaths } = result?.result || {};
    return observationsRef?.filter((ref) => ref.format !== 'csv').map((ref) => ref.path) || observationsPaths;
  }, [result?.result]);

  useEffect(() => {
    if (!paths?.length) return;

    void (async () => {
      try {
        setIsLoadingObservations((isLoading) => isLoading === undefined);
        const res = await FetchStorageBlobs(firebase, paths);
        setObservations(res as NumericObservers);
        setIsLoadingObservations(false);
      } catch (error) {
        console.log(error);
        setIsLoadingObservations(false);
      }
    })();
  }, [paths, firebase]);

  let durationValue = '0 blocks';
  let durationHeaderName = 'Current state progress';
  if (result?.state === undefined || isSimulationOnFinishedStatus(result?.state)) {
    durationHeaderName = 'Duration';
    durationValue = `${(result?.meta?.endBlock || 0) - (result?.meta?.startBlock || 0)} blocks`;
  } else if (isSimulationStateOnScriptExecutionStatus(result?.state)) {
    const iterationsValue: string = simulation?.iterations.toString() ?? '0';
    durationValue = `${
      result?.meta?.currentIteration ?? '0'}/${iterationsValue} blocks`;
  }

  const searchedObservations = useMemo(() => {
    if (!observations) return {};
    if (!observationsSearchValue) return observations;

    const isMatch = (value: string) => simplify(value).includes(simplify(observationsSearchValue));

    const reducedObservations: NumericObservers = Object.keys(observations).reduce((
      filteredObservations: NumericObservers,
      path: string,
    ) => {
      const newObservations = { ...filteredObservations };
      if (isMatch(nameFromPath(path))) {
        newObservations[path] = observations[path];
      }
      return newObservations;
    }, {});

    return reducedObservations;
  }, [observations, observationsSearchValue]);
  const simulationUrl = RoutePath.Simulations.Details.replace(RouteParams.SimulationId, simulation?.id || '');
  const [currIterationsRange, setCurrIterationsRange] = useState<[number, number]>(
    [0, simulation?.iterations || 0],
  );
  const filterButtonRef = useRef<null | HTMLButtonElement>(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filters, setFilters] = useState<Filter[]>([{
    type: 'range',
    range: [0, simulation?.iterations || 0],
    fieldName: 'Iterations Range',
    fieldIndex: 0,
    isApplied: false,
    stepSize: 1,
  }]);
  const isFilterApplied = currIterationsRange[0] !== 0
    || currIterationsRange[1] !== simulation?.iterations;

  useEffect(() => {
    const range: [number, number] = [0, simulation?.iterations || 0];
    setFilters(([filter]) => [{ ...filter, range } as FilterRange]);
    setCurrIterationsRange(range);
  }, [simulation?.iterations]);

  const simulationDate = useMemo(() => {
    const dateToParse = result?.created.seconds;
    return dateToParse
      ? dayjs(dateToParse * 1000).format('D/M/YYYY [at] h:mm a')
      : undefined;
  }, [result?.created.seconds]);

  const runtimeMillis = 1000 * Math.max(
    0,
    (result?.updated.seconds ?? 0) - (result?.created.seconds ?? 0),
  );

  const team = useCurrentTeam();
  const profile = useAppSelector((state) => state.firebase.auth);
  const triggerPermission = usePermission('trigger_simualation');
  const [isCanceled, setIsCanceled] = useState<boolean>(!triggerPermission);

  const cancelSimulation = async () => {
    if (!triggerPermission) {
      return;
    }

    setIsCanceled(true);
    const resultId = await cancelBlockchainSimulationFn(
      simulationResultId,
      profile.uid,
      team?.authKey,
    );

    if (!resultId) return;

    chaosSnackbar.toast(
      'Simulation execution cancled',
      {
        icon: 'check-circle',
      },
    );
  };

  const optionsMenuItems = (
    isCanceled || (result?.state && isSimulationOnFinishedStatus(result?.state)))
    ? undefined
    : [
      {
        title: 'Cancel',
        icon: 'close',
        onClick: async () => {
          await cancelSimulation();
        },
      },
    ];

  const downloadResultFiles = () => {
    const fileObservations = result?.result.observationsRef?.filter((r) => !!r.publicUrl);
    [...(fileObservations?.map((o, i) => ({ blobUrl: o.publicUrl, name: `on_chain_${i + 1}_${simulationResultId || ''}.${o.format || 'json'}` })) || []),
      { blobUrl: pythonResult?.metadata.debug_blob_urls.liquidation_calls_blob_url, name: `python_liquidation_calls_${result?.pythonSimId || ''}.csv` },
      { blobUrl: pythonResult?.metadata.debug_blob_urls.trajectory_blob_url, name: `python_prices_trajectory_${result?.pythonSimId || ''}.json` }].forEach((file) => {
      if (file.blobUrl) {
        void fetch(file.blobUrl)
          .then((resp) => resp.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = file.name;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          });
      }
    });
  };

  return (
    <MainLayout
      headerProps={{
        pageTitle,
        pageSubtitle: simulationDate,
        breadcrumbsLinks: [{
          title: 'Simulation Results',
          href: RoutePath.Simulations.Results,
        }],
        breadcrumbTitle: pageTitle,
        breadcrumbTitleLink: simulationUrl,
        buttons: [{
          variant: 'outlined',
          children: 'Download Files',
          color: 'secondary',
          onClick: downloadResultFiles,
        }, {
          variant: 'outlined',
          children: 'Block Explorer',
          color: 'primary',
          onClick: () => navigate(RoutePath.Simulations.BlockExplorer.Home
            .replace(RouteParams.SimulationResultId, simulationResultId!)),
        }],
        menuItems: optionsMenuItems,
      }}
    >
      {result?.error && (
        <Box sx={{ pb: '40px' }}>
          <SimulationError errorText={result?.error} />
        </Box>
      )}
      <Box display="flex" flexDirection="column" gap={5}>
        <SimulationResultGeneralInfo
          id={simulationResultId!}
          state={result?.state}
          startBlock={result?.meta?.startBlock}
          currentIteration={result?.meta?.currentIteration}
          totalIteration={simulation?.iterations}
          passed={counts.success}
          failed={counts.failed}
          durationHeaderName={durationHeaderName}
          durationValue={durationValue}
          runtimeMillis={runtimeMillis}
          description={simulation?.description}
          rpcUrl={result?.meta?.externalRemotePoolURL}
          isParamConfigSimulation={false}
        />

        <SimulationConfigurationDetails
          simulation={simulation}
          agents={agents}
          assertions={assertions}
          scenarios={scenarios}
          premutation={result?.premutation}
          randomArgData={result?.meta?.calculatedRandomArgData}
        />
        {(isFinished && !!result?.result?.assertions?.length) && (
        <Box sx={{ mt: 4 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h2">Assertion Results</Typography>
          </Box>
          <ChaosTable
            headers={assertionViewHeaders()}
            data={ResultToassertionsToView(result)}
            isFilterHidden
            isSettingsHidden
          />
        </Box>
        )}
        {
          isLoadingObservations ? <LoadingObservers />
            : isObserversValid && (
              <Paper
                variant="card"
                sx={{
                  paddingBottom: '32px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                }}
              >
                <Box display="flex" flexDirection="row" flexGrow={1} width="100%">
                  <Typography variant="h2">
                    Observations
                  </Typography>
                  <SearchBar
                    value={observationsSearchValue}
                    onChange={setObservationsSearchValue}
                    placeholder="Search for observer"
                    sx={{ minWidth: 344, ml: 'auto' }}
                  />
                </Box>
                <Box display="flex" alignItems="center" mb={3}>
                  <IconButton
                    color="secondary"
                    ref={filterButtonRef}
                    onClick={() => setIsFilterOpen(!isFilterOpen)}
                    aria-label="Add Filter"
                    data-testid="toggle-filter"
                  >
                    <CustomIcon icon="filter" />
                  </IconButton>
                  <TableAppliedFilters
                    filters={filters.map((filter) => ({
                      ...filter, range: currIterationsRange, isApplied: isFilterApplied,
                    }))}
                    removeFilter={() => setCurrIterationsRange([0, simulation?.iterations || 0])}
                    headers={[{ renderType: 'TEXT', text: 'Iterations Range' }]}
                  />
                </Box>
                <ObserversView
                  observers={observers}
                  observationsRef={result?.result?.observationsRef}
                  data={searchedObservations}
                  observerGroupsMetadata={simulation?.observerGroupsMetadata}
                  startBlock={result?.meta?.observersStartBlock}
                  simulationResultId={simulationResultId!}
                  iterationsRange={currIterationsRange}
                  currentIteration={result?.meta?.currentIteration}
                />
                <TableFilter
                  isOpen={isFilterOpen}
                  anchorEl={filterButtonRef.current}
                  filters={filters}
                  onChange={(filterValues) => {
                    setCurrIterationsRange((filterValues[0] as FilterRange).range);
                  }}
                  close={() => setIsFilterOpen(false)}
                />
              </Paper>
            )
      }
      </Box>
    </MainLayout>
  );
});
