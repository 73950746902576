import { FC } from 'react';
import { Chip } from '@chaos/ui/chip';
import { ChipProps } from '@mui/material/Chip/Chip';

export const TagChip: FC<ChipProps> = (props) => (
  <Chip
    {...props}
    sx={{
      padding: 0,
      height: 24,
      borderRadius: 2,
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 'fontWeightBold',
      '& .MuiChip-label': {
        p: '2px 4px',
        display: 'inline-block',
        minWidth: 15,
      },
      ...(props.sx || {}),
    }}
  />
);
