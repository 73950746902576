import {
  useState, useRef, useCallback, useMemo, useEffect,
} from 'react';
import type { Nullable } from '@chaos/types';

interface Actions {
  start: () => void
  pause: () => void
  resume: () => void
  reset: () => void
}

export const useCountDown = (
  interval = 1000,
): [number, Actions] => {
  const [timeLeft, setTimeLeft] = useState<number>(0);
  const timer = useRef<{
    started?: Nullable<number>
    lastInterval: Nullable<number>
    timeLeft: number
    timeToCount: number
    requestId?: number
  }>({ lastInterval: 0, timeToCount: 0, timeLeft: 0 });

  const run = useCallback((ts: number) => {
    if (!timer.current.started) {
      timer.current.started = ts;
      timer.current.lastInterval = ts;
    }

    const localInterval = Math.min(interval, timer.current.timeLeft || Infinity);

    if (
      timer.current?.lastInterval
      && ts - (timer.current?.lastInterval || 0) >= localInterval
    ) {
      timer.current.lastInterval += localInterval;
      setTimeLeft((newTimeLeft) => {
        timer.current.timeLeft = newTimeLeft - localInterval;
        return timer.current.timeLeft;
      });
    }

    if (ts - timer.current.started < timer.current.timeToCount) {
      timer.current.requestId = window.requestAnimationFrame(run);
    } else {
      timer.current = {
        lastInterval: 0,
        timeToCount: 0,
        timeLeft: 0,
      };
      setTimeLeft(0);
    }
  }, [interval]);

  const start = useCallback(() => {
    if (timer.current.requestId) {
      window.cancelAnimationFrame(timer.current.requestId);
    }

    timer.current.started = null;
    timer.current.lastInterval = null;
    timer.current.timeToCount = 60 * 1000;
    timer.current.requestId = window.requestAnimationFrame(run);

    setTimeLeft(60 * 1000);
  }, [run]);

  const pause = useCallback(() => {
    if (timer.current.requestId) {
      window.cancelAnimationFrame(timer.current.requestId);
    }

    timer.current.started = null;
    timer.current.lastInterval = null;
    timer.current.timeToCount = timer.current.timeLeft;
  }, []);

  const resume = useCallback(() => {
    if (
      timer.current.timeLeft
      && !timer.current.started
      && timer.current.timeLeft > 0
    ) {
      if (timer.current.requestId) {
        window.cancelAnimationFrame(timer.current.requestId);
      }
      timer.current.requestId = window.requestAnimationFrame(run);
    }
  }, [run]);

  const reset = useCallback(() => {
    if (timer.current.timeLeft) {
      if (timer.current.requestId) {
        window.cancelAnimationFrame(timer.current.requestId);
      }

      timer.current = {
        lastInterval: 0,
        timeToCount: 0,
        timeLeft: 0,
      };
      setTimeLeft(0);
    }
  }, []);

  const actions = useMemo(() => ({
    start, pause, resume, reset,
  }), [start, pause, resume, reset]);

  useEffect(() => reset, [reset]);

  return [timeLeft, actions];
};
