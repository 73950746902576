import { BlockchainSimulationResult, Header, RenderData } from '@chaos/types';
import {
  memo, useEffect, useRef, useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  ChaosTable, FilterRange, FilterText, TableAppliedFilters, TableFilter,
} from '@chaos/ui/chaos-table';
import { IconButton } from '@chaos/ui/icon-button';
import { CustomIcon } from '@chaos/ui/custom-icon';
import { Box } from '@chaos/ui';
import { RoutePath } from 'src/config/routes';
import { SortChangeHandler } from 'src/hooks/usePagination';
import { blockchaiSimulationResultCounts } from 'src/utils/blockchain-simulation-result-counts';
import { formatDateOrTimeToday, formatDateAndTime } from 'src/utils/formatters';
import { ProgressByState } from 'src/utils/progress-helper';
import { isParamConfigHistoryResult, isSimulationOnFinishedStatus, isSimulationStateOnScriptExecutionStatus } from 'src/utils/simulation-helper';

type Props = {
  simulationsResults: BlockchainSimulationResult[];
  omitNameColumn?: boolean;
  showCusomParameterConfigurationHeaders?: boolean;
  isFullHeight?: boolean;
  onPageChange?: (page: number) => void;
  onSortChange?: SortChangeHandler;
  onFilter?: (nameFilter?: string, createdAtFilter?: [number, number]) => void;
  isLoading?: boolean;
  title?:string;
};

const getAppliedFilters = (
  initialFilters: (FilterText | FilterRange)[],
  name?: string,
  date?: [number, number],
) => [{
  ...initialFilters[0],
  isApplied: name !== undefined,
  value: name,
}, {
  ...initialFilters[1],
  isApplied: date !== undefined && (
    date[0] !== (initialFilters[1] as FilterRange).range[0]
    || date[1] !== (initialFilters[1] as FilterRange).range[1]
  ),
  range: date || (initialFilters[1] as FilterRange).range,
}];

const initialFilters = [{
  type: 'text',
  fieldName: 'Simulation Name',
  fieldIndex: 0,
  isApplied: false,
} as FilterText, {
  type: 'range',
  fieldName: 'Start Date',
  fieldIndex: 1,
  isApplied: false,
  range: [
    new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getTime(),
    new Date().getTime(),
  ],
  isDate: true,
} as FilterRange];

const SimulationsResultsV2HistoryTable = ({
  simulationsResults,
  omitNameColumn,
  showCusomParameterConfigurationHeaders,
  isFullHeight,
  onPageChange,
  onSortChange,
  onFilter,
  isLoading,
  title,
}: Props) => {
  const filterButtonRef = useRef<null | HTMLButtonElement>(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [nameFilter, setNameFilter] = useState<string>();
  const [createdAtFilter, setCreatedAtFilter] = useState<[number, number]>();
  const [resetPagination, setResetPagination] = useState<string>();
  useEffect(
    () => {
      setResetPagination(uuidv4());
      onFilter?.(nameFilter, createdAtFilter);
    },
    [onFilter, nameFilter, createdAtFilter],
  );

  const handleOnClickRow = (rowIdx: number) => {
    const simulationResult = simulationsResults[rowIdx];
    if (!simulationResult) {
      return RoutePath.Simulations.Results;
    }
    const isParamConfigSimulation = isParamConfigHistoryResult(
      simulationResult,
      simulationResult.simulationSnapshot?.simulationType,
    );
    return `${isParamConfigSimulation
      ? RoutePath.Simulations.Reports
      : RoutePath.Simulations.Results}/${simulationResult.id || ''}`;
  };

  const tableData: RenderData[][] = simulationsResults.map((result: BlockchainSimulationResult) => {
    const simulation = result.simulationSnapshot;
    const state = result?.state?.toLowerCase();
    const currentIteration = result?.meta?.currentIteration;
    const resultBlocksDiff = (result.meta?.endBlock || 0) - (result?.meta?.startBlock || 0);
    const isSimulationOnExecutionState = isSimulationStateOnScriptExecutionStatus(state);
    const isParamConfigSimulation = isParamConfigHistoryResult(result, simulation?.simulationType);
    const iterationsValue: number = (isParamConfigSimulation
      ? result?.meta?.iterations
      : simulation?.iterations) ?? 0;

    const progressState = ProgressByState(state, currentIteration, iterationsValue);

    const currentStateBlocksString = (() => {
      if (isParamConfigSimulation) return `${simulation?.iterations || '0'}`;

      if (isSimulationOnExecutionState) return `${currentIteration ?? '0'}/${iterationsValue}`;

      if (state === undefined || isSimulationOnFinishedStatus(state)) return `${resultBlocksDiff}`;

      return '0';
    })();

    const resultStateCell: RenderData = (() => {
      switch (state) {
        case 'simulation error':
          return (
            {
              renderType: 'LABELS',
              text: 'Error',
              status: 'ERROR',
            }
          );
        case 'simulation cancelled':
          return (
            {
              renderType: 'LABELS',
              text: 'Cancelled',
              status: 'ERROR',
            }
          );
        case 'simulation finished':
          return (
            {
              renderType: 'LABELS',
              text: 'Completed',
              status: 'OK',
            }
          );
        default:
          return (
            {
              renderType: 'PROGRESS',
              text: state ?? 'pending',
              progressValue: progressState || 0.01,
            }
          );
      }
    })();

    const counts = blockchaiSimulationResultCounts(result);
    const isPassed = counts.failed === 0 && state === 'simulation finished';
    const isSimulationInProgress = resultStateCell.renderType === 'PROGRESS';
    const resultColor = isPassed ? 'success.main' : 'error.main';
    const resultText = isPassed ? 'Passed' : 'Failed';

    const parameterConfigRowsData: RenderData[] = showCusomParameterConfigurationHeaders
      ? result?.premutation?.args?.map((arg) => ({
        renderType: 'TEXT',
        text: arg.argValue.toString(),
      })) ?? []
      : [];

    const rowsData: RenderData[] = [
      {
        renderType: 'TEXT',
        text: result.simulationSnapshot?.name || '-',
        tooltipText: result.simulationSnapshot?.name,
      },
      {
        renderType: 'TEXT',
        text: formatDateOrTimeToday(result.created.toDate(), false, true),
        value: result.created.toDate().getTime(),
        tooltipText: formatDateAndTime(result.created.toDate()),
      },
      {
        renderType: 'TEXT',
        text: currentStateBlocksString,
        value: isSimulationOnExecutionState ? currentIteration : resultBlocksDiff,
      },
      {
        renderType: 'TEXT',
        text: isParamConfigSimulation ? 'Multi' : 'Single',
      },
      {
        ...resultStateCell,
        width: '105px',
      },
      ...parameterConfigRowsData,
      {
        renderType: 'TEXT',
        text: isSimulationInProgress ? '-' : resultText,
        textColor: isSimulationInProgress ? undefined : resultColor,
        value: counts.success || 0,
      },
    ];
    if (omitNameColumn) rowsData.shift();

    return rowsData;
  });

  const parameterConfigHeaders: Header[] = showCusomParameterConfigurationHeaders
    ? simulationsResults[0]?.premutation?.args?.map((arg) => ({
      renderType: 'TEXT',
      text: arg.argName,
      nonSortable: true,
      width: '5%',
    })) ?? []
    : [];

  const headers: Header[] = [
    {
      renderType: 'TEXT',
      text: 'Simulation Name',
      nonSortable: true,
      width: '30%',
    },
    {
      renderType: 'TEXT',
      text: 'Start Date',
      width: '5%',
      field: 'created',
    },
    {
      renderType: 'TEXT',
      text: 'Duration (blocks)',
      width: '5%',
      nonSortable: true,
    },
    {
      renderType: 'TEXT',
      text: 'Type',
      width: '5%',
      nonSortable: true,
    },
    {
      renderType: 'TEXT',
      text: 'Status',
      width: '5%',
      nonSortable: true,
    },
    ...parameterConfigHeaders,
    {
      renderType: 'TEXT',
      text: 'Result',
      width: '4%',
      nonSortable: true,
    },
  ];
  if (omitNameColumn) headers.shift();

  return (
    <>
      <ChaosTable
        title={title}
        headers={headers}
        data={tableData}
        rowHeight={72}
        pageSize={20}
        isSettingsHidden
        initialSortBy={omitNameColumn ? 0 : 1}
        isInitialSortDesc
        isInitialSortEnable
        showRowChevron
        isFullHeight={isFullHeight}
        rowHref={handleOnClickRow}
        onPageChange={onPageChange}
        onSortChange={onSortChange}
        isLoading={isLoading}
        isFilterHidden
        customFilter={(
          <Box display="flex">
            <TableAppliedFilters
              filters={getAppliedFilters(initialFilters, nameFilter, createdAtFilter)}
              removeFilter={(i) => {
                if (i === 0) {
                  setNameFilter(undefined);
                } else if (i === 1) {
                  setCreatedAtFilter(undefined);
                }
              }}
              headers={[
                { renderType: 'TEXT', text: 'Simulation Name' },
                { renderType: 'TEXT', text: 'Start Date' },
              ]}
            />
            <IconButton
              color="secondary"
              ref={filterButtonRef}
              onClick={() => setIsFilterOpen(!isFilterOpen)}
              aria-label="Add Filter"
              data-testid="toggle-filter"
            >
              <CustomIcon icon="filter" />
            </IconButton>
          </Box>
        )}
        resetPagination={resetPagination}
      />
      <TableFilter
        isOpen={isFilterOpen}
        anchorEl={filterButtonRef.current}
        filters={initialFilters}
        onChange={(filterValues) => {
          const currFilters = Object.values(filterValues);
          setNameFilter((currFilters[0] as FilterText).value);
          setCreatedAtFilter((currFilters[1] as FilterRange).range);
        }}
        close={() => setIsFilterOpen(false)}
      />
    </>
  );
};

export default memo(SimulationsResultsV2HistoryTable);
