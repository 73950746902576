import { OptionType, getTextByValueType } from '@chaos/ui';
import { ModelRunResult } from '../../data/types';

export const getPermutationDropdownOptions = (
  assetModelResult: ModelRunResult,
) => {
  const { current, recommended, alternatives } = assetModelResult;

  const filteredAlternatives = alternatives.filter((p) => ![
    current.permutation_id,
    recommended?.permutation_id,
  ].includes(p.permutation_id));

  const permutations = [...(recommended ? [recommended] : []), current, ...filteredAlternatives];

  const permutationsDropdownOptions: OptionType[] = permutations.map((p) => {
    const isCurrent = p.permutation_id === current.permutation_id;
    const isRecommended = p.permutation_id === recommended?.permutation_id;

    return ({
      value: p.permutation_id,
      label: Object.values(p.values).map((v) => `${v.display_name} - ${getTextByValueType(v.value, v.value_type)}`).join(' '),

      ...(isCurrent ? {
        tag: 'Current',
        bgColor: 'blue',
      } : {}),

      ...(isRecommended ? {
        tag: 'Recommended',
        bgColor: 'orange',
      } : {}),
    });
  });

  return { permutations, dropdownOptions: permutationsDropdownOptions };
};
