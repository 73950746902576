import { FC, ReactNode, useState } from 'react';
import {
  Box, Tooltip, Typography, CustomSwitch,
} from '@chaos/ui';
import { IconButton } from '@chaos/ui/icon-button';
import { CustomIcon } from 'src/components/custom-icon';
import { HexOrDec, useHexDecConvert } from 'src/hooks/useHexDecConvert';

export const OverviewItem: FC<{
  name?: string,
  spacing?: number,
  flexDirection?: 'row' | 'column',
  children?: ReactNode
}> = ({
  name,
  spacing,
  flexDirection,
  children,
}) => (
  <Box sx={{
    display: 'flex',
    columnGap: 3,
    mb: 2,
    '&:last-of-type': {
      mb: 0,
    },
  }}
  >
    <Box flexBasis="200px" flexShrink={0}>
      {name && <Typography variant="caption">{name}</Typography>}
    </Box>
    <Box
      width={0}
      flexDirection={flexDirection}
      sx={{
        display: 'flex',
        columnGap: spacing || 1,
        rowGap: 2,
        alignItems: 'center',
        flexWrap: 'wrap',
        flex: 1,
      }}
    >
      {children}
    </Box>
  </Box>
);

export const LineSeparator: FC = () => (
  <Box sx={{
    background: 'linear-gradient(90deg, rgba(155, 157, 161, 0), rgba(155, 157, 161, 0.5), rgba(155, 157, 161, 0))',
    height: '1px',
    my: 1,
    mb: 3,
  }}
  />
);

export const GradientText: FC<{ children?: ReactNode }> = ({ children }) => (
  <Box
    overflow="hidden"
    textOverflow="ellipsis"
    sx={{
      background: 'linear-gradient(333.68deg, #0965F1 -25.37%, #31D8BF 103.14%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    }}
    component="span"
  >
    {children}
  </Box>
);

export const CopyText: FC<{ text: string, }> = (
  {
    text,
  },
) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const toggleTooltip = () => setIsTooltipOpen((prevValue) => !prevValue);

  const onCopy = () => {
    navigator.clipboard
      .writeText(text)
      .then(toggleTooltip)
      .catch((e) => console.error('Clipboard error: ', e));
  };
  return (
    <Tooltip
      classes={{
        tooltip: 'light',
        arrow: 'light',
      }}
      open={isTooltipOpen}
      onClose={toggleTooltip}
      title="Copied to the clipboard"
      placement="top"
      leaveDelay={300}
    >
      <IconButton
        onClick={onCopy}
        size="small"
        sx={{
          width: 24,
          height: 24,
        }}
      >
        <CustomIcon icon="copy" />
      </IconButton>
    </Tooltip>
  );
};

export const ColorBlock: FC<{ children?: ReactNode, color?: string }> = ({ color, children }) => (
  <Box sx={{
    px: 1.5,
    borderRadius: 2,
    backgroundColor: color || 'disabled.opacity50',
  }}
  >
    {children}
  </Box>
);

export const LineBreak: FC = () => (
  <Box sx={{
    width: 2,
    height: 16,
    background: '#FFF',
  }}
  />
);

export const DataBlock: FC<{
  children: ReactNode }> = ({
  children,
}) => (
  <Box
    display="flex"
    flexDirection="column"
    gap={2}
    sx={{
      p: 2,
      color: '#FFF',
      borderRadius: 2,
      background: '#17191E',
      width: 0,
      flex: 1,
    }}
  >
    {children}
  </Box>
);

export const DecHexConverter: FC<{ hexValue: string }> = ({ hexValue }) => {
  const [hexOrDec, setHexOrDec] = useState<HexOrDec>('hex');
  const { convertedNumber, convert } = useHexDecConvert(hexValue, 'hex');

  return (
    <Box display="flex" columnGap={2} width={1} flex={1}>
      <Typography mt={1} flex={1} overflow="hidden" textOverflow="ellipsis">{convertedNumber}</Typography>
      <Box display="flex" marginLeft="24px" gap={1.5} alignItems="center">
        <Typography fontSize={14}>Dec</Typography>
        <CustomSwitch
          checked={hexOrDec === 'hex'}
          onChange={(e) => {
            setHexOrDec(e.target.checked ? 'hex' : 'dec');
            convert(e.target.checked ? 'hex' : 'dec');
          }}
        />
        <Typography fontSize={14}>Hex</Typography>
      </Box>
    </Box>
  );
};

export const EventInfoItem: FC<{ title: string, children?: ReactNode }> = ({ title, children }) => (
  <Box display="flex" flex={1} alignItems="start" columnGap={5}>
    <Typography variant="caption" sx={{ flexBasis: '80px', textAlign: 'end' }}>{title}</Typography>
    {children}
  </Box>
);

export const Topic: FC<{ position: number, text: string }> = ({ position, text }) => {
  const { convertedNumber } = useHexDecConvert(text);
  return (
    <Box
      sx={{
        display: 'flex',
        columnGap: 1,
        alignItems: 'center',
        py: 1,
      }}
      component="li"
    >
      <Box sx={{
        backgroundColor: 'disabled.opacity50',
        px: 1,
        borderRadius: 2,
      }}
      >
        {position}
      </Box>
      <Typography overflow="hidden" textOverflow="ellipsis">{convertedNumber}</Typography>
    </Box>
  );
};

export const TopicList: FC<{ topics: string[] }> = ({ topics }) => (
  <Box component="ul" flex={1} width={0}>
    {topics.map((item, index) => <Topic key={item} position={index} text={item} />)}
  </Box>
);

export const ColorText: FC<{ color: string, children?: ReactNode }> = ({ color, children }) => (
  <Box
    component="span"
    sx={{
      color,
    }}
  >
    {children}
  </Box>
);
