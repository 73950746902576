import {
  FC, useEffect, useRef, useState,
} from 'react';
import {
  useFirebase,
  useFirestore,
  useFirestoreConnect,
} from 'react-redux-firebase';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { DropzoneRef } from 'react-dropzone';

import * as StorageTypes from '@firebase/storage-types';
import { Box } from '@chaos/ui/box';
import { Button } from '@chaos/ui/button';
import { Typography } from '@chaos/ui/typography';
import { required } from 'src/utils/validators';
import { useAppSelector } from 'src/store';
import { COLLECTIONS } from '@chaos/utils';
import { Contract, ContractStatus, Team } from '@chaos/types';
import { ContractUploadField } from '../fields/contract-upload';
import { InputField } from '../fields';
import { Compile } from '../../services/engine';
import { ConfirmUploadModal } from './confirm-upload-contract-modal';
import { ModalWrapper } from './modal-wrapper';

export interface ContractValue {
  file: File
  name: string
  size: number
  extension: string
  formattedSize: string
}

interface UploadContractFormValues {
  name: string
  contract: ContractValue | undefined
}

const initialValues: UploadContractFormValues = {
  name: '',
  contract: undefined,
};

const validationSchema = Yup.object().shape({
  name: required,
  contract: Yup.object().required(),
});

export type OpenFileSelectHandler = DropzoneRef['open'];

interface UploadContractModalProps {
  team: Team
  onClose?: () => void
  contracts: Contract[]
  contractsMetadata: StorageTypes.FullMetadata[]
}

export const UploadContractModal: FC<UploadContractModalProps> = ({
  team,
  onClose,
  contracts,
  contractsMetadata,
}) => {
  const openFileSelectRef = useRef<OpenFileSelectHandler | undefined>(undefined);
  const formikHelpersRef = useRef<
  FormikHelpers<UploadContractFormValues> | undefined
  >(undefined);

  const { uid } = useAppSelector((state) => state.firebase.auth);

  const firebase = useFirebase();
  const firestore = useFirestore();

  useFirestoreConnect([
    {
      collection: COLLECTIONS.CONTRACTS,
      where: [
        'team',
        '==',
        firestore.collection(COLLECTIONS.TEAMS).doc(team.id),
      ],
    },
  ]);
  const [contractUpdateConfirmed, setContractUpdateConfirmed] = useState(false);
  const [confirmUploadModalOpen, setConfirmUploadModalOpen] = useState(false);

  const confirmContractUpdate = () => {
    setContractUpdateConfirmed(true);
  };

  const openConfirmUploadModal = () => {
    setConfirmUploadModalOpen(true);
  };

  const closeConfirmUploadModal = () => {
    setConfirmUploadModalOpen(false);
    if (formikHelpersRef.current) {
      formikHelpersRef.current.setSubmitting(false);
    }
  };

  useEffect(() => {
    if (contractUpdateConfirmed && formikHelpersRef.current) {
      void formikHelpersRef.current.submitForm();
    }
  }, [contractUpdateConfirmed]);

  const onSubmit = (
    values: UploadContractFormValues,
    helpers: FormikHelpers<UploadContractFormValues>,
  ) => {
    const fileName = `${values.name}-${uid}-${team.id}.${values.contract?.extension || ''}`;
    const contractFile = contractsMetadata.find(
      (fileData) => fileData.name === fileName,
    );
    const contractId = contracts.find(
      (contract) => contract.contractName === contractFile?.name,
    )?.id;

    if (contractFile && contractId) {
      if (!contractUpdateConfirmed) {
        formikHelpersRef.current = helpers;
        openConfirmUploadModal();
      }
      setConfirmUploadModalOpen(false);
      firebase
        .storage()
        .ref('solidity-contracts')
        .child(fileName)
      // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
        .put(values.contract!.file)
        .then(({ metadata }) => {
          firestore
            .collection(COLLECTIONS.CONTRACTS)
            .doc(contractId)
            .update({
              size: metadata.size,
              updated: metadata.updated,
              updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .catch(console.log)
            .finally(() => {
              setContractUpdateConfirmed(false);
              helpers.setSubmitting(false);
              onClose?.();
            });
        })
        .catch(console.log);
    }

    const storageRef = firebase.storage().ref(`solidity-contracts/${fileName}`);
    if (values.contract) {
      storageRef
        .put(values.contract.file)
        .then(({ metadata }) => {
          firestore
            .collection(COLLECTIONS.CONTRACTS)
            .add({
              status: ContractStatus.uploaded,
              size: metadata.size,
              updated: metadata.updated,
              fullPath: metadata.fullPath,
              contractName: values.name,
              resourceName: metadata.name,
              hash: metadata.md5Hash,
              team: firestore.collection(COLLECTIONS.TEAMS).doc(team.id),
              createdBy: firestore.collection(COLLECTIONS.USERS).doc(uid),
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then((result) => {
              Compile(result.id, team.authKey ?? '')
                .then(() => {
                  console.log('compilation started');
                })
                .catch((e) => {
                  console.log(e);
                })
                .finally(() => {
                  onClose?.();
                  window.location.reload();
                });
            })
            .catch(console.log);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <Box component="header">
        <Box className="modal-header" sx={{ py: '36px' }}>
          <Typography variant="h2" component="h2">
            Upload Contract
          </Typography>
        </Box>
      </Box>

      <Formik
        validateOnMount
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          submitForm,
          setFieldValue,
          isSubmitting,
          resetForm,
          isValid,
        }) => (
          <Box sx={{ width: 694 }}>
            <Form className="modal-body" autoComplete="off">
              <Box sx={{ maxWidth: 280 }}>
                <InputField
                  name="name"
                  label="Contract Name"
                  placeholder="Enter contract name"
                />
              </Box>
              <ContractUploadField
                name="contract"
                setFieldValue={setFieldValue}
                openFileSelectRef={openFileSelectRef}
                resetForm={resetForm}
              />
            </Form>

            <Box component="footer">
              <Box className="modal-footer" sx={{ py: 5 }}>
                <Button
                  onClick={openFileSelectRef.current}
                  color="secondary"
                  disabled={!!values.contract}
                  sx={{ mr: 3 }}
                >
                  Select File
                </Button>
                <Button
                  color="secondary"
                  sx={{ mr: '24px', ml: 'auto' }}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting || !isValid}
                  color="primary"
                  onClick={() => {
                    void submitForm();
                  }}
                >
                  Upload
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Formik>

      <ModalWrapper
        open={confirmUploadModalOpen}
        onClose={closeConfirmUploadModal}
        maxWidth={600}
      >
        <ConfirmUploadModal
          onClose={closeConfirmUploadModal}
          confirmContractUpdate={confirmContractUpdate}
        />
      </ModalWrapper>
    </>
  );
};
