import { FC, ReactNode } from 'react';

import { Box } from '@chaos/ui/box';
import { useTheme } from '@chaos/ui/theme';
import { SignInFooter } from '../signin-footer';
import { AuthCompanyBg } from '../auth-company-bg';

interface SignInLayoutProps {
  children?: ReactNode
}

export const AuthCompanyLayout: FC<SignInLayoutProps> = ({
  children,
}): JSX.Element => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minHeight: '100%',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <AuthCompanyBg />
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            maxWidth: '560px',
            margin: '0 auto',
            width: '100%',
            px: 2,
            [theme.breakpoints.up('sm')]: {
              px: 5,
            },
          }}
        >
          {children}
        </Box>
      </Box>
      <Box
        sx={{
          flexShrink: 0,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <SignInFooter />
      </Box>
    </Box>
  );
};
