import {
  useState, useRef, FC,
} from 'react';
import { Axis } from '@chaos/types';
import { colors } from '@chaos/ui';
import { Box } from '@chaos/ui/box';
import { Grid } from '@chaos/ui/grid';
import { Typography } from '@chaos/ui/typography';
import { wrappedSymbolToIconSymbol } from 'src/utils/icon-helper';
import { CustomReactSelect } from '@chaos/ui/custom-select';
import { useElementSize } from '@chaos/ui/hooks/use-element-size';
import { Loader } from 'src/components/loader';
import {
  VictoryLine,
  VictoryChart,
  VictoryScatter,
  VictoryGroup,
  VictoryAxis,
  VictoryLabel,
} from 'victory';
import { DataQueries } from 'src/services/engine';
import dayjs from 'dayjs';
import { chartTooltip } from './chart-tooltip';
import { formatAmount } from './utils';

const victoryAxisStyle = {
  tickLabels: {
    fill: 'rgba(255, 255, 255, .5)',
    fontFamily: 'Archivo, sans-serif',
    lineHeight: '24px',
    fontSize: 16,
  },
  axis: {
    stroke: 'rgba(255, 255, 255, .2)',
    strokeWidth: 1,
    strokeDasharray: 4,
  },
  axisLabel: {
    fontSize: 20,
    fontFamily: 'Archivo, sans-serif',
    fill: 'rgba(255, 255, 255, .5)',
  },
  grid: {
    stroke: 'rgba(255, 255, 255, .2)',
    strokeWidth: 1,
    strokeDasharray: 4,
    strokeLinecap: 'round',
  },
};

export interface LineChartData {
  x: string | number,
  y: number,
  label: string
}

export interface LineChartProps {
  title: string,
  data: LineChartData[],
  description?: string,
  xAxis: Axis,
  xAxisAmountNotation?: 'compact' | 'standard' | 'scientific' | 'engineering' | undefined,
  yAxis: Axis,
  yAxisAmountNotation?: 'compact' | 'standard' | 'scientific' | 'engineering' | undefined,
  hidePoints?: boolean,
  callbacks?: {
    query: {
      chartId: string,
      type: 'onchain' | 'offchain';
      options: Array<{
        label: string,
        value: string,
        icon?: string,
      }>,
    };
  };
}

export const LineChart: FC<LineChartProps> = ({
  title,
  data,
  description,
  xAxis,
  xAxisAmountNotation,
  yAxis,
  yAxisAmountNotation,
  hidePoints,
  callbacks,
}) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const size = useElementSize(chartRef);
  const [isLoading, setIsLoading] = useState(false);
  const [stateData, setStateData] = useState({
    title,
    description,
    data,
    xAxis,
    yAxis,
    callbacks,
  });
  const [token, setToken] = useState(stateData?.callbacks?.query?.options[0]);
  const sortedYData = stateData
    && Array.isArray(stateData.data)
    && stateData.data.sort((a, b) => a.y - b.y).map(({ y }) => y);
  return stateData && stateData.data ? (
    <Grid item xs={12} position="relative">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
        <Box position="relative" flex="1">
          <Typography variant="h2">{title}</Typography>
          {description && (
          <Typography fontWeight={400} mt={1}>
            {description}
          </Typography>
          )}
        </Box>
        { token
          ? (
            <Box ml="auto">
              <CustomReactSelect
                value={token}
                options={stateData.callbacks?.query.options}
                onChange={(curr) => {
                  if (curr && stateData?.callbacks?.query.chartId) {
                    setToken({
                      ...curr,
                      icon: wrappedSymbolToIconSymbol(curr.value),
                    });
                    setIsLoading(true);
                    // eslint-disable-next-line
                    void DataQueries(
                      stateData.callbacks.query.chartId,
                      stateData.callbacks.query.type,
                      {
                        payload: curr.value,
                      },
                    ).then((res) => {
                      // eslint-disable-next-line
                    if (res && res.data) {
                        setIsLoading(false);
                        // eslint-disable-next-line
                      setStateData(res);
                      }
                    });
                  }
                }}
                isSearchable={false}
              />
            </Box>
          ) : null}
      </div>
      <Box ref={chartRef} sx={{ mt: { sm: 2, md: 4 } }}>
        {isLoading ? (
          <Loader />
        )
          : (
            <VictoryChart
              {...size}
              height={400}
              padding={{
                top: 16, bottom: 65, left: 85, right: 35,
              }}
            >
              <VictoryAxis
                style={victoryAxisStyle}
                dependentAxis
                axisLabelComponent={<VictoryLabel dy={-40} />}
                label={stateData.yAxis.label}
                tickFormat={(t: number) => formatAmount(
                  t,
                  { currency: stateData.yAxis?.currency, notation: yAxisAmountNotation },
                )}
              />
              <VictoryAxis
                style={victoryAxisStyle}
                fixLabelOverlap
                axisLabelComponent={<VictoryLabel dy={20} />}
                label={stateData.xAxis.label}
                tickFormat={(stateData.xAxis.type === 'datetime' || stateData.xAxis.type === 'date')
                  ? (t: number) => dayjs(t).format(`DD MMM YYYY ${stateData.xAxis.type === 'datetime' ? 'HH:MM:ss' : ''}`)
                  : (t: number) => formatAmount(
                    t,
                    { currency: stateData.xAxis?.currency, notation: xAxisAmountNotation },
                  )}
              />
              <VictoryGroup data={stateData.data} labelComponent={chartTooltip}>
                <VictoryLine style={{ data: { stroke: 'rgba(35, 174, 210, 0.5)' } }} />
                <VictoryScatter
                  size={hidePoints ? 1 : 4}
                  style={{ data: { fill: ({ active }) => (active ? '#30D4C1' : '#C1F2EC') }, labels: { display: 'none' } }}
                />
              </VictoryGroup>
              {sortedYData && stateData?.xAxis.highlight && (
              <VictoryLine
                data={[
                  { x: stateData?.xAxis?.highlight.value, y: sortedYData[0] },
                  { x: stateData?.xAxis?.highlight.value, y: sortedYData[sortedYData.length - 1] },
                ]}
                style={{ data: { stroke: 'rgba(222, 68, 105, 0.5)', strokeDasharray: 8 } }}
                labels={[stateData.xAxis.highlight.text]}
                labelComponent={(
                  <VictoryLabel
                    angle={-90}
                    dx={stateData.xAxis.highlight.text.length * 12}
                    dy={5}
                    textAnchor="end"
                    style={{
                      fill: 'white', fontFamily: 'Archivo',
                    }}
                    backgroundStyle={{ fill: colors.background.default }}
                  />
              )}
              />
              )}
            </VictoryChart>
          )}
      </Box>
    </Grid>
  ) : <Loader />;
};
