import React from 'react';
import {
  useSnackbar, WithSnackbarProps, SnackbarMessage,
} from 'notistack';
import { ChaosSnackbar } from 'src/components/chaos-snackbar';
import { ChaosSnackbarProps } from 'src/components/chaos-snackbar/chaos-snackbar';

let useSnackbarRef: WithSnackbarProps;
export const ChaosSnackbarConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

export const chaosSnackbar = {
  toast(msg: SnackbarMessage, options?: Omit<ChaosSnackbarProps, 'id' | 'message'>, duration?: number) {
    useSnackbarRef.enqueueSnackbar(
      msg,
      {
        autoHideDuration: duration || 4000,
        content: (
          key,
          message,
        ) => (
          <ChaosSnackbar
            id={key}
            message={message}
            {...options}
          />
        ),
      },
    );
  },
};
