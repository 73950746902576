import { FC, useMemo } from 'react';
import { Tooltip } from '@chaos/ui/tooltip';
import { Box, Typography } from '@chaos/ui';
import { CustomIcon } from 'src/components/custom-icon';

interface SimulationResultInfoCardProps {
  icon?: string
  title: string
  valueColor?: string
  value: string | number | JSX.Element
  titleType?: 'small'
  tooltip?: string
  theme?: 'gradient'
  onClick?: () => void
}

export const SimulationResultInfoCard: FC<SimulationResultInfoCardProps> = ({
  icon, title, value, valueColor, titleType, tooltip, theme, onClick,
}) => {
  const text = useMemo(() => (
    <Typography
      className={theme === 'gradient' ? 'gradient-link' : undefined}
      onClick={onClick}
      variant="h1"
      component="h2"
      width="100%"
      overflow="hidden"
      textOverflow="ellipsis"
      sx={{
        color: valueColor || '#fff',
        fontSize: 16,
        lineHeight: '24px',
        textAlign: { xs: 'right', md: 'left' },
        cursor: onClick && 'pointer',
      }}
    >
      {value}
    </Typography>
  ), [onClick, theme, value, valueColor]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'row', sm: 'column' },
        justifyContent: 'space-between',
        alignItems: { xs: 'center', sm: 'flex-start' },
        overflow: 'hidden',
      }}
    >
      <Box display="flex" alignItems="center" columnGap={1}>
        <Typography
          variant="caption"
          component="span"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          data-testid="title"
          fontSize={titleType === 'small' ? 14 : 16}
          sx={{
            minWidth: 'fit-content',
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          minWidth: 0,
          color: 'light.main',
          ml: '2px',
          svg: {
            path: {
              fill: theme !== 'gradient' ? 'currentColor' : '',
            },
          },
        }}
      >
        {icon && (<CustomIcon icon={icon} sx={{ '& svg path': { fill: '#9B9DA1' } }} />)}
        {tooltip ? (
          <Tooltip
            classes={{ tooltip: 'dark', arrow: 'dark' }}
            placement="bottom-end"
            title={tooltip}
            enterTouchDelay={0}
            leaveTouchDelay={10000}
          >
            {text}
          </Tooltip>
        ) : text}
      </Box>
    </Box>
  );
};
