import {
  AggregationName,
  BlockchainSimulation,
  BlockchainSimulationResult,
  ObservationAggregationResult,
  ScriptResource,
} from '@chaos/types';
import { Box, Typography } from '@chaos/ui';
import { useState } from 'react';
import { ScatterChart, ScatterChartData } from 'src/components/charts';
import { CustomReactSelect, OptionType } from '@chaos/ui/custom-select';
import { RoutePath } from 'src/config/routes';

type Props = {
  aggregationMethod: AggregationName;
  simulation: BlockchainSimulation;
  metricOptions: OptionType[];
  selectedXMetric: Partial<ScriptResource>;
  observationsMetaData: Record<string, ObservationAggregationResult>[];
  repetitionsResults: BlockchainSimulationResult[];
};

const MetricScatterPlot = ({
  simulation,
  metricOptions,
  selectedXMetric,
  aggregationMethod,
  observationsMetaData,
  repetitionsResults,
}: Props) => {
  const [selectedYMetric, setSelectedYMetric] = useState<Partial<ScriptResource>>(
    simulation.observers.find((
      obs,
    ) => obs.refId === metricOptions[0]?.value) || simulation.observers[0],
  );
  const scatterChartData = (() => {
    const metricXData = observationsMetaData.find((m) => Object
      .values(m).find((v) => v.id === selectedXMetric.refId));
    const metricYData = observationsMetaData.find((m) => Object
      .values(m).find((v) => v.id === selectedYMetric.refId));

    if (!metricXData || !metricYData) return [];

    return observationsMetaData.map((m, i) => {
      const x = Object.values(m).find((l) => l.id === selectedXMetric.refId)
        ?.aggregations[aggregationMethod] || 0;
      const y = Object.values(m).find((l) => l.id === selectedYMetric.refId)
        ?.aggregations[aggregationMethod] || 0;
      return {
        x,
        y,
        label: '',
        metadata: repetitionsResults[i].id,
      };
    });
  })();
  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Typography variant="h2">Metric ScatterPlot</Typography>
        <CustomReactSelect
          key={selectedXMetric.name}
          placeholder="Metric (y)"
          options={metricOptions}
          defaultValue={metricOptions[0]}
          onChange={(selected) => {
            const newSelectedMetric = simulation.observers.find((
              obs,
            ) => obs.refId === selected?.value);
            if (!newSelectedMetric) return;

            setSelectedYMetric(newSelectedMetric);
          }}
        />
      </Box>
      <Box flex={1} height="0">
        <ScatterChart
          data={scatterChartData}
          onClick={(datum: ScatterChartData) => datum.metadata && window.open(`${RoutePath.Simulations.Results}/${datum.metadata}`)}
          xAxis={{
            label: selectedXMetric.name,
          }}
          yAxis={{
            label: selectedYMetric.name,
          }}
        />
      </Box>
    </Box>
  );
};

export default MetricScatterPlot;
