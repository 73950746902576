import { FC } from 'react';
import ReactJson, { InteractionProps } from 'react-json-view';

import { Box } from '@chaos/ui/box';
import { IconButton } from '@chaos/ui/icon-button';
import { CustomIcon } from '../custom-icon';

const theme = {
  base00: '#17191E', // bg
  base01: '#383830',
  base02: 'rgba(255, 255, 255, 0.1)', // lines
  base03: '#75715e',
  base04: '#9b9da1', // items count
  base05: '#f8f8f2',
  base06: '#30D4C1',
  base07: '#30D4C1', // label
  base08: '#f92672',
  base09: '#ffa318', // value
  base0A: '#f4bf75',
  base0B: '#a6e22e',
  base0C: '#30D4C1',
  base0D: '#66d9ef',
  base0E: '#ae81ff',
  base0F: '#cc6633',
};

interface JSONViewerProps {
  json: Record<string, unknown> | Record<string, unknown>[]
  bgColor?: string
  padding?: number | string
  maxHeight?: number | string
  collapsed?: boolean
  showToolbar?: boolean
  isEditing?: boolean
  isError?: boolean
  startEdit?: () => void
  finishEdit?: () => void
  resetEdit?: () => void
  onEdit?: ((edit: InteractionProps) => any) | false
  style?: React.CSSProperties
}

export const JSONViewer: FC<JSONViewerProps> = ({
  json,
  bgColor,
  padding,
  onEdit,
  showToolbar,
  isEditing,
  startEdit,
  finishEdit,
  resetEdit,
  maxHeight,
  isError,
  ...props
}) => (
  <Box
    sx={{
      '.react-json-view': {
        p: padding || 2,
        borderRadius: showToolbar ? '0 0 8px 8px' : '8px',
        fontFamily: 'Armata, sans-serif !important',
        border: `2px solid ${isError ? '#de4469' : 'transparent'}`,
      },
      '.pretty-json-container': {
        maxHeight,
        overflow: 'auto',
      },
      '.object-size': {
        fontStyle: 'normal !important',
      },
      '.data-type-label': {
        fontSize: '16px !important',
      },
      '.object-key + span': {
        color: '#fff !important',
      },
      '.object-key-val > span > span > :last-child': {
        color: '#fff !important',
      },
      '.brace-row > *': {
        color: '#fff !important',
      },
      '.string-value': {
        wordBreak: 'break-word',
      },
    }}
  >
    {showToolbar && (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: '4px 8px',
          bgcolor: '#2E3034',
          borderRadius: '8px 8px 0 0',
        }}
      >
        {isEditing ? (
          <>
            <IconButton onClick={resetEdit} size="large">
              <CustomIcon icon="close" />
            </IconButton>
            <IconButton onClick={finishEdit} size="large">
              <CustomIcon icon="check" />
            </IconButton>
          </>
        ) : (
          <>
            <IconButton size="large">
              <CustomIcon icon="fullscreen" />
            </IconButton>
            {onEdit && (
              <IconButton onClick={startEdit} size="large">
                <CustomIcon icon="edit" />
              </IconButton>
            )}
          </>
        )}
      </Box>
    )}
    <ReactJson
      src={json}
      theme={{ ...theme, base00: bgColor || theme.base00 }}
      enableClipboard={false}
      collapseStringsAfterLength={35}
      onEdit={isEditing && onEdit}
      onAdd={isEditing && onEdit}
      onDelete={isEditing && onEdit}
      displayDataTypes={Boolean(showToolbar)}
      displayObjectSize={Boolean(showToolbar)}
      {...props}
    />
  </Box>
);
