import {
  Switch, SxProps, Theme, Tooltip,
} from '@mui/material';
import { ChangeEvent, FC, ReactNode } from 'react';
import { palette } from '../theme/palette';
import { Box } from '../box';
import { Typography } from '../typography';
import { CustomIcon } from '../custom-icon';
import { CryptoIcon } from '../crypto-icon';

interface CustomSwitchProps {
  checked?: boolean
  value?: string | boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  sx?: SxProps<Theme>
  trackColor?: string
  testId?: string
  checkedLabel?: string | ReactNode
  uncheckedLabel?: string | ReactNode
  checkedIcon?: string | ReactNode;
  uncheckedIcon?: string | ReactNode;
  checkedCryptoIcon?: string;
  uncheckedCryptoIcon?: string;
  checkedLabelTooltip?: string
  uncheckedLabelTooltip?: string
  labelsVariant?: 'h6' | 'h4',
  disabled?: boolean
}

export const CustomSwitch: FC<CustomSwitchProps> = ({
  checked,
  value,
  onChange,
  sx,
  trackColor,
  testId,
  checkedLabel,
  uncheckedLabel,
  checkedIcon,
  uncheckedIcon,
  checkedCryptoIcon,
  uncheckedCryptoIcon,
  labelsVariant = 'h6',
  checkedLabelTooltip = '',
  uncheckedLabelTooltip = '',
  disabled = false,
}) => (
  <Box sx={{
    display: 'inline-flex', alignItems: 'center', gap: 2, opacity: disabled ? 0.2 : 1,
  }}
  >
    {typeof uncheckedIcon === 'string' ? <CustomIcon icon={uncheckedIcon} /> : uncheckedIcon}
    {uncheckedCryptoIcon && <CryptoIcon icon={uncheckedCryptoIcon} />}
    {uncheckedLabel && (
      <Tooltip title={uncheckedLabelTooltip}>
        <Typography variant={labelsVariant}>
          {uncheckedLabel}
        </Typography>
      </Tooltip>
    )}
    <Switch
      data-testid={testId}
      checked={checked}
      value={value}
      onChange={onChange}
      disabled={disabled}
      sx={{
        padding: 0,
        borderRadius: 4,
        width: 40,
        height: 24,
        '.MuiSwitch-thumb': {
          background: checked ? palette.black.main : palette.blue.main,
          boxShadow: 'none',
          transition: 'background 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
        },
        '.MuiSwitch-track': {
          background: trackColor ?? palette.almostWhite.opacity20,
          opacity: 1,
          transition: 'background 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
        },

        '.MuiSwitch-switchBase': {
          padding: '2px',

          ':hover': {
            background: 'none',
          },

          '&.Mui-checked': {
            transform: 'translateX(16px)',
            ':hover': {
              background: 'none',
            },
          },

          '&.Mui-checked + .MuiSwitch-track': {
            background: trackColor ?? palette.blue.main,
            opacity: 1,
          },
        },
        '.Mui-checked': {
          '& + .MuiSwitch-track': {
            background: trackColor ?? palette.blue.main,
            opacity: 1,
          },
        },
        ...sx,
      }}
    />
    {typeof checkedIcon === 'string' ? <CustomIcon icon={checkedIcon} /> : checkedIcon}
    {checkedCryptoIcon && <CryptoIcon icon={checkedCryptoIcon} />}
    {checkedLabel && (
      <Tooltip title={checkedLabelTooltip}>
        <Typography variant={labelsVariant}>
          {checkedLabel}
        </Typography>
      </Tooltip>
    )}
  </Box>
);
