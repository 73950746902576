import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';

dayjs.extend(isToday);

const userLocale = navigator.languages && navigator.languages.length
  ? navigator.languages[0]
  : navigator.language;

export const millisToMinutesAndSeconds = (millis: number): string => {
  const hours = Math.floor(millis / 3600000);
  const minutes = Math.floor((millis % 3600000) / 60000);
  const seconds = +((millis % 60000) / 1000).toFixed(0);
  // ES6 interpolated literals/template literals
  // If seconds is less than 10 put a zero in front.
  return `${hours}:${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export function formatCurrency(value: number, currency = 'USD'): string {
  const formatAmount = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: value % 1 === 0 ? 0 : 2,
    maximumFractionDigits: value > 0.01 ? 2 : value > 0.00001 ? 5 : 8,
  });
  return formatAmount.format(value);
}

export const formatDate = (date?: Date): string => {
  if (!date) return '';
  const dayjsDate = dayjs(date);
  const today = dayjsDate.isToday() && 'Today';
  const yesterday = dayjsDate.isYesterday() && 'Yesterday';
  const actualDate = date.toLocaleString(userLocale, { year: 'numeric', month: '2-digit', day: '2-digit' });
  return `${today || yesterday || actualDate}`;
};

export const formatTimeInDate = (date?: Date | string, withSeconds = true): string => {
  if (!date) return '';
  return `${withSeconds
    ? date.toLocaleString(userLocale, {
      hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false, // timeZoneName: 'short',
    })
    : date.toLocaleString(userLocale, {
      hour: '2-digit', minute: '2-digit', hour12: false, // timeZoneName: 'short',
    })}`;
};

export const formatDateAndTime = (date?: Date, withSeconds = true, dateOnly?: boolean): string => {
  if (!date) return '';
  if (dateOnly) return formatDate(date);
  return `${formatDate(date)} at ${formatTimeInDate(date, withSeconds)}`;
};

export const formatDateOrTimeToday = (
  date?: Date,
  withSeconds = true,
  dateOnly?: boolean,
): string => {
  if (!date) return '';
  const dayjsDate = dayjs(date);

  const isTodayDate = dayjsDate.isToday();

  return isTodayDate
    ? formatTimeInDate(date, withSeconds)
    : formatDateAndTime(date, withSeconds, dateOnly);
};

export const formatCamelCaseToPascalCase = (value?: string) => {
  if (value) {
    const withSpacings = value.replace(/([a-z])([A-Z])/g, '$1 $2');
    return withSpacings.charAt(0).toUpperCase() + withSpacings.slice(1);
  }
  return value;
};
