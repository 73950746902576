import { Box, Grid, Typography } from '@chaos/ui';
import { ShimmerLoader } from '@chaos/ui/shimmer-loader';
import { formatCurrencyStringAmount, formatPercent, formatPercentDecimal } from '@chaos/ui/utils/formatters';
import { Accordion } from 'src/components/accordion';
import { AssetsDataResponse } from '../types';

type ParameterFieldProps = {
  title: string;
  value: string;
};

const ParameterField = ({ title, value }: ParameterFieldProps) => (
  <Box display="flex" flexDirection="column">
    <Typography variant="caption">
      {title}
    </Typography>
    <Typography>
      {value}
    </Typography>
  </Box>
);

type Props = {
  isLoadingAssetsData: boolean,
  assetsData?: AssetsDataResponse
};
const AssetParametersAccordion = ({ isLoadingAssetsData, assetsData }: Props) => {
  const { pumpedAsset } = (assetsData || {});
  return (
    <Accordion
      header={(
        <Typography fontWeight={700}>
          Borrow Asset ($
          {pumpedAsset?.symbol}
          ) Parameters:
        </Typography>
)}
      sx={{
        mt: 0,
        background: '#181C1F',
        borderRadius: 2,
      }}
      defaultExpanded
    >
      {isLoadingAssetsData || !pumpedAsset
        ? (
          <Box height={120} mt={3}>
            <ShimmerLoader sx={{ height: '100%' }} />
          </Box>
        )
        : (
          <Grid container spacing={3} mt={0}>
            <Grid item xs={6} md={3}>
              <ParameterField title="Total Supplied" value={formatCurrencyStringAmount(pumpedAsset.totalLiquidity)} />
            </Grid>
            <Grid item xs={6} md={3}>
              <ParameterField title="Available Liquidity" value={formatCurrencyStringAmount(pumpedAsset.availableLiquidity)} />
            </Grid>
            <Grid item xs={6} md={3}>
              <ParameterField title="Utilization Rate" value={formatPercent(pumpedAsset.utilizationRate)} />
            </Grid>
            <Grid item xs={6} md={3}>
              <ParameterField title="Max LTV" value={formatPercentDecimal(pumpedAsset.baseLTVasCollateral)} />
            </Grid>
            <Grid item xs={6} md={3}>
              <ParameterField title="Liquidation Threshold" value={formatPercentDecimal(pumpedAsset.reserveLiquidationThreshold)} />
            </Grid>
            <Grid item xs={6} md={3}>
              <ParameterField title="Liquidation Penelty" value={formatPercentDecimal(pumpedAsset.reserveLiquidationBonus)} />
            </Grid>
            <Grid item xs={6} md={3}>
              <ParameterField title="Reserve Factor" value={formatPercentDecimal(pumpedAsset.reserveFactor)} />
            </Grid>
          </Grid>
        )}
    </Accordion>
  );
};
export default AssetParametersAccordion;
