import { useEffect, useState } from 'react';
import { mergeMap, Subscription } from 'rxjs';
import {
  collection, doc, getDoc, query, where,
} from 'firebase/firestore';
import { collectionData, docData } from 'rxfire/firestore';
import { COLLECTIONS } from '@chaos/utils';
import { BlockchainSimulation, BlockchainSimulationResult, ScriptResource } from '@chaos/types';
import { firestore } from 'src/services/firebase';
import { useCurrentTeam } from './useCurrentTeam';
import { useSimulationTeam } from './useSimulationTeam';

export const useSimulationDetails = (simulationId: string): {
  isLoading: boolean,
  simulation?: BlockchainSimulation,
  assertions?: ScriptResource[],
  agents?: ScriptResource[],
  scenarios?: ScriptResource[],
  simulationResults?: BlockchainSimulationResult[]
} => {
  const team = useCurrentTeam();
  const [simulation, setSimulation] = useState<BlockchainSimulation>();
  const [simulationResults, setSimulationResults] = useState<BlockchainSimulationResult[]>();
  const [assertions, setAssertions] = useState<ScriptResource[]>();
  const [agents, setAgents] = useState<ScriptResource[]>();
  const [scenarios, setScenarios] = useState<ScriptResource[]>();

  useSimulationTeam(simulation);

  useEffect(() => {
    const subs: Subscription[] = [];
    const simObs = docData(doc(collection(firestore, COLLECTIONS.BLOCKCHAIN_SIMULATIONS), simulationId), { idField: 'id' });
    subs.push(simObs.subscribe((s) => setSimulation(s as BlockchainSimulation)));

    const simResultObs = collectionData(query(
      collection(firestore, COLLECTIONS.BLOCKCHAIN_SIMULATION_RESULTS),
      where('team', '==', doc(collection(firestore, COLLECTIONS.TEAMS), team?.id)),
      where('simulationId', '==', simulationId),
      where('parentResultId', '==', null),
    ), { idField: 'id' });
    subs.push(simResultObs.subscribe((s) => {
      setSimulationResults(s as BlockchainSimulationResult[]);
    }));

    const assertionsObs = simObs.pipe(
      mergeMap((s) => Promise.all((s as BlockchainSimulation).assertions.map(async (a) => {
        const d = await getDoc(doc(collection(
          firestore,
          COLLECTIONS.BLOCKCHAIN_SIMULATION_ASSERTIONS,
        ), a.refId));
        return {
          ...d.data(),
          instanceId: a.instanceId ?? '',
          id: a?.refId,
        };
      }))),
    );
    subs.push(assertionsObs.subscribe((a) => setAssertions(a as ScriptResource[])));

    const agentsObs = simObs.pipe(mergeMap((s) => Promise.all(
      (s as BlockchainSimulation).agents.map(async (a) => {
        const d = await getDoc(doc(collection(
          firestore,
          COLLECTIONS.BLOCKCHAIN_SIMULATION_AGENTS,
        ), a.refId));
        return {
          ...d.data(),
          instanceId: a.instanceId ?? '',
          id: a?.refId,
        };
      }),
    )));
    subs.push(agentsObs.subscribe((a) => setAgents(a as ScriptResource[])));

    const scenariosObs = simObs.pipe(mergeMap((s) => Promise.all([
      ...(s as BlockchainSimulation).scenarios.runtime,
      ...(s as BlockchainSimulation).scenarios.setup,
    ].map(async (a) => {
      const d = await getDoc(doc(collection(
        firestore,
        COLLECTIONS.BLOCKCHAIN_SIMULATION_SCENARIOS,
      ), a.refId));
      return {
        ...d.data(),
        instanceId: a.instanceId ?? '',
        id: a?.refId,
      };
    }))));
    subs.push(scenariosObs.subscribe((a) => setScenarios(a as ScriptResource[])));

    return () => subs.forEach((s) => s.unsubscribe());
  }, [team, simulationId]);

  return {
    isLoading: !simulation || !simulationResults,
    simulation,
    simulationResults,
    assertions,
    agents,
    scenarios,
  };
};
