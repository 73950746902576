const SimulationsHome = '/simulations';
const ProfileHome = '/profile';
const TeamHome = '/team';
const DashboardHome = '/dashboard';
const OrganizationHome = '/organizations';
const OracleConfigurationHome = '/oracle-configuration';
const AgentPlaygroundHome = '/agents';
const AssertionWizardHome = '/assertions';
const ObserverConfigurationHome = '/observers';
const ScenarioCatalogueHome = '/scenarios';
const ParamRecommendationsHome = '/models';
const ParamRecommendationsToolHome = '/param-recs-tool';

export const RouteParams = {
  Id: ':id',
  SimulationId: ':simulationId',
  DashboardId: ':dashboardId',
  SimulationResultId: ':simulationResultId',
  PermutationId: ':permutationId',
  Address: ':address',
  BlockNumber: ':blockNumber',
  TransactionHash: ':transactionHash',
};

export const RoutePath = {
  WaitingList: '/waiting-list',
  SignIn: '/sign-in',
  CreateProfile: '/create-profile',
  Welcome: '/welcome',
  WaitingTeam: '/waiting-team',
  ScenarioCatalogue: {
    Home: ScenarioCatalogueHome,
    Create: `${ScenarioCatalogueHome}/create`,
    Edit: `${ScenarioCatalogueHome}/:id/edit`,
    Details: `${ScenarioCatalogueHome}/:id`,
  },
  AccountSettings: '/account-settings',
  Deployments: '/deployments',
  Notifications: '/notifications',
  Contact: '/contact',
  Privacy: '/privacy',
  DyDxCalculator: '/dydx-calculator',
  Dashboard: `${DashboardHome}/${RouteParams.Id}`,
  MagicLink: {
    Sent: '/magic-link-sent',
    Receive: '/magic-link-receive',
  },
  Team: {
    Settings: `${TeamHome}/settings`,
    New: `${TeamHome}/new`,
    Edit: `${TeamHome}/:teamId/edit`,
  },
  Organization: {
    Home: OrganizationHome,
    SignUp: `${OrganizationHome}/sign-up`,
  },
  Simulations: {
    Home: SimulationsHome,
    Details: `${SimulationsHome}/${RouteParams.SimulationId}`,
    Results: `${SimulationsHome}-results`,
    Reports: `${SimulationsHome}-reports`,
    ResultDetails: `${SimulationsHome}-results/${RouteParams.SimulationResultId}`,
    ExperimentResults: `${SimulationsHome}-reports/${RouteParams.SimulationResultId}/experiment/${RouteParams.PermutationId}`,
    MultiSimulationReport: `${SimulationsHome}-reports/${RouteParams.SimulationResultId}`,
    History: `${SimulationsHome}-history`,
    New: `${SimulationsHome}/new`,
    Edit: `${SimulationsHome}/:simId/edit`,
    Clone: `${SimulationsHome}/:simId/clone`,
    BlockExplorer: {
      Home: `${SimulationsHome}/results/${RouteParams.SimulationResultId}/block-explorer`,
      Transactions: `${SimulationsHome}/results/${RouteParams.SimulationResultId}/block-explorer/txs`,
      Blocks: `${SimulationsHome}/results/${RouteParams.SimulationResultId}/block-explorer/blocks`,
      Address: `${SimulationsHome}/results/${RouteParams.SimulationResultId}/block-explorer/address/${RouteParams.Address}`,
      BlockTransactions: `${SimulationsHome}/results/${RouteParams.SimulationResultId}/block-explorer/block/${RouteParams.BlockNumber}/txs`,
      TransactionDetails: `${SimulationsHome}/results/${RouteParams.SimulationResultId}/block-explorer/tx/${RouteParams.TransactionHash}`,
      BlockDetails: `${SimulationsHome}/results/${RouteParams.SimulationResultId}/block-explorer/block/${RouteParams.BlockNumber}`,
    },
  },
  Profile: {
    Home: ProfileHome,
    Edit: `${ProfileHome}/edit`,
  },
  OracleConfiguration: {
    Home: OracleConfigurationHome,
    Company: `${OracleConfigurationHome}/:companyName`,
    Support: `${OracleConfigurationHome}/support`,
  },
  AgentPlayground: {
    Home: AgentPlaygroundHome,
    Create: `${AgentPlaygroundHome}/create`,
    Edit: `${AgentPlaygroundHome}/${RouteParams.Id}/edit`,
    Details: `${AgentPlaygroundHome}/${RouteParams.Id}`,
  },
  AssertionWizard: {
    Home: AssertionWizardHome,
    Create: `${AssertionWizardHome}/create`,
    Edit: `${AssertionWizardHome}/${RouteParams.Id}/edit`,
    Details: `${AssertionWizardHome}/${RouteParams.Id}`,
  },
  ObserverConfiguration: {
    Home: ObserverConfigurationHome,
    Create: `${ObserverConfigurationHome}/create`,
    Edit: `${ObserverConfigurationHome}/${RouteParams.Id}/edit`,
    Details: `${ObserverConfigurationHome}/${RouteParams.Id}`,
  },
  ParamRecommendations: {
    Home: ParamRecommendationsHome,
    Details: `${ParamRecommendationsHome}/${RouteParams.Id}`,
    Trigger: `${ParamRecommendationsHome}/trigger`,
  },
  ParamRecommendationsTool: {
    Home: ParamRecommendationsToolHome,
    ModelRuns: `${ParamRecommendationsToolHome}/models`,
    ModelTrigger: `${ParamRecommendationsToolHome}/models/trigger`,
    ModelRunDetails: `${ParamRecommendationsToolHome}/models/${RouteParams.Id}`,
  },
};
