import ReactECharts, { EChartsReactProps } from 'echarts-for-react';
import type { EChartsOption } from 'echarts';
import * as echarts from 'echarts';
import { createRef, useEffect, useMemo } from 'react';
import { Typography } from '../typography';
import { Box } from '../box';
import { theme } from './theme';
import { useKeyPress } from '../utils/use-key-press';

echarts.registerTheme('main', theme);
type Props = Omit<EChartsReactProps, 'option'> & {
  zoomable?: boolean;
  onClick?: (xValue: number) => void;
  emptyState?: boolean;
  disableWatermark?: boolean;
  option: EChartsOption;
};
const ECharts = ({
  zoomable, onClick, option, emptyState, disableWatermark, ...restProps
}: Props) => {
  const chartRef = createRef<ReactECharts>();
  const zoomKeyPressed = useKeyPress(['ctrl', 'Meta'], zoomable);
  const brushKeyPressed = useKeyPress(['Shift'], zoomable);

  const waterMarkOption = useMemo(() => {
    if (disableWatermark) return option;
    return {
      ...option,
      graphic: [
        {
          type: 'image',
          id: 'logo',
          left: 'center',
          top: 'center',
          style: {
            image: '/chaos-logo.svg',
            width: 184,
            height: 32,
          },
        },
      ],
    };
  }, [option, disableWatermark]);

  const zoomOption = useMemo(() => {
    const { dataZoom } = waterMarkOption;
    if (!zoomable || typeof dataZoom !== 'object' || Array.isArray(dataZoom)) return waterMarkOption;
    return { ...waterMarkOption, dataZoom: { ...dataZoom, zoomLock: !zoomKeyPressed } };
  }, [waterMarkOption, zoomKeyPressed, zoomable]);

  useEffect(() => {
    const echartInstance = chartRef?.current?.getEchartsInstance();

    if (echartInstance && zoomable) {
      echartInstance.dispatchAction({
        type: 'takeGlobalCursor',
        key: 'dataZoomSelect',
        dataZoomSelectActive: brushKeyPressed,
      });
    }
  }, [zoomable, brushKeyPressed, chartRef]);

  useEffect(() => {
    const echartInstance = chartRef?.current?.getEchartsInstance();
    const zr = echartInstance?.getZr();

    if (echartInstance && onClick) {
      zr?.on('click', (params: echarts.ElementEvent) => {
        const pointInPixel = [params.offsetX, params.offsetY];
        const pointInGrid = echartInstance.convertFromPixel('grid', pointInPixel);
        if (echartInstance.containPixel('grid', pointInPixel)) {
          onClick(pointInGrid[0]);
        }
      });
      zr?.on('mousemove', (params) => {
        const pointInPixel = [params.offsetX, params.offsetY];
        zr?.setCursorStyle(
          echartInstance.containPixel('grid', pointInPixel) ? 'pointer' : 'default',
        );
      });
    }

    return () => {
      if (echartInstance && onClick) {
        zr?.off?.('click');
      }
    };
  }, [chartRef, onClick]);

  return emptyState ? (
    <Box display="flex" alignItems="center" justifyContent="center" height="80%">
      <Typography
        variant="h2"
        color="light.main"
      >
        No Data to Show
      </Typography>
    </Box>
  ) : (
    <ReactECharts
      ref={chartRef}
      {...restProps}
      option={zoomOption}
      style={{ height: '100%', width: '100%' }}
      theme="main"
    />
  );
};

export default ECharts;
