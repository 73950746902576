import {
  FC, useState, useEffect, MutableRefObject,
} from 'react';
import { useDropzone, DropzoneOptions, ErrorCode } from 'react-dropzone';
import { FieldMetaProps } from 'formik';

import { FormHelperText } from '@chaos/ui/form-helper-text';
import { FormControl } from '@chaos/ui/form-control';
import { Box } from '@chaos/ui/box';
import { Button } from '@chaos/ui/button';
import { Typography } from '@chaos/ui/typography';
import { CustomIcon } from 'src/components/custom-icon';
import { formatBytes } from 'src/utils/formatters';
import type { ContractValue, OpenFileSelectHandler } from '../modals';

export interface ContractUploadProps {
  openFileSelectRef: MutableRefObject<OpenFileSelectHandler | undefined>
  name: string
  onChange?: (value?: ContractValue) => void
  value?: ContractValue
  meta?: FieldMetaProps<ContractValue>
  setFieldValue?: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void
  resetForm: () => void
}

const accept = ['.sol']; // files to accept
const maxSize = 1024000; // max file size (1 megabyte)

export const ContractUpload: FC<ContractUploadProps> = ({
  name,
  value,
  meta,
  onChange,
  setFieldValue,
  openFileSelectRef,
  resetForm,
}) => {
  const [fileContract, setFileContract] = useState<ContractValue | undefined>(
    undefined,
  );
  const [uploadErrors, setUploadErrors] = useState<string[]>([]);

  useEffect(() => {
    if (meta && meta.touched && meta.error) {
      setUploadErrors([meta.error]);
    }
  }, [meta]);

  const onDropRejected: DropzoneOptions['onDropRejected'] = (
    fileRejections,
  ) => {
    const errors: Set<string> = new Set();
    fileRejections.forEach((fileObj) => {
      fileObj.errors.forEach((error) => {
        if (error.code === ErrorCode.FileTooLarge) {
          errors.add(`File is larger than ${formatBytes(maxSize)}`);
        } else {
          errors.add(error.message);
        }
      });
    });
    setUploadErrors(Array.from(errors));
  };

  const onDropAccepted: DropzoneOptions['onDropAccepted'] = (acceptedFiles) => {
    const acceptedFile = acceptedFiles[0];
    const { name: rawName, size } = acceptedFile;
    const [fileName, extension] = rawName.split('.');
    setUploadErrors([]);

    const contractValue: ContractValue = {
      name: rawName,
      size,
      extension,
      file: acceptedFile,
      formattedSize: formatBytes(size),
    };
    setFieldValue?.('name', fileName);

    setFileContract(contractValue);
    if (onChange) {
      onChange(contractValue);
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    accept,
    maxSize,
    maxFiles: 1,
    onDropAccepted,
    onDropRejected,
  });

  const removeSelectedFile = () => {
    setFileContract(undefined);
    setFieldValue?.(name, undefined);
    resetForm();
  };

  // eslint-disable-next-line
  openFileSelectRef.current = open;

  return (
    <Box>
      {fileContract ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
            p: 2,
            backgroundColor: '#45494F',
            borderRadius: '16px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mr: 2,
              minWidth: 48,
              height: 48,
              borderRadius: '50%',
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            }}
          >
            <CustomIcon icon="file-code" />
          </Box>
          <Box sx={{ mr: 2 }}>
            <Typography component="h4">{fileContract.name}</Typography>
            <Typography variant="caption" component="span">
              {fileContract.formattedSize}
            </Typography>
          </Box>
          <Box sx={{ ml: 'auto' }}>
            <Button
              color="error"
              onClick={() => {
                removeSelectedFile();
                if (onChange) {
                  onChange(undefined);
                }
              }}
              sx={{
                minWidth: 48,
                borderRadius: '16px',
                p: '12px',
              }}
            >
              <CustomIcon icon="trash-can" />
            </Button>
          </Box>
        </Box>
      ) : (
        <FormControl error={uploadErrors.length > 0} sx={{ width: '100%' }}>
          <Box
            {...getRootProps()}
            sx={{
              backgroundColor: '#45494F',
              border: '1px dashed #9B9DA1',
              borderRadius: '16px',
              textAlign: 'center',
              color: '#9B9DA1',
              cursor: 'pointer',
            }}
          >
            <input {...getInputProps()} />
            <input
              style={{ display: 'none' }}
              // eslint-disable-next-line
              value={value as any}
              // eslint-disable-next-line
              onChange={onChange as any}
            />

            <Typography component="p" sx={{ my: '48px' }}>
              Drag & drop contract file here
            </Typography>
          </Box>
          {uploadErrors.map((error) => (
            <FormHelperText key={error}>{error}</FormHelperText>
          ))}
        </FormControl>
      )}
    </Box>
  );
};
