import { FC } from 'react';
import usePagination, { UsePaginationProps } from '@mui/material/usePagination';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import styled from '@emotion/styled';
import { Button } from '@chaos/ui/button';

const List = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  userSelect: 'none',
  button: {
    fontSize: 14,
    fontWeight: 400,
    borderRadius: 8,

    ':hover': {
      background: '#383b41',
    },
  },
});

export const Pagination: FC<UsePaginationProps> = ({
  count = 10, page, onChange, boundaryCount = 1,
}) => {
  const { items } = usePagination({
    count, page, onChange, siblingCount: 3, boundaryCount,
  });

  return (
    <nav>
      <List>
        {items.map(({
          page: pageNumber, type, selected, ...item
        }, index) => {
          let children = null;

          if (boundaryCount > 0 && (type === 'start-ellipsis' || type === 'end-ellipsis')) {
            children = '…';
          }

          if (type === 'page') {
            children = (
              <Button
                sx={{
                  background: selected
                    ? 'linear-gradient(154.16deg, #31D8BF -3.9%, #0965F1 126.56%)'
                    : undefined,
                  width: 32,
                  height: 32,
                  padding: 0,
                  minWidth: 32,
                  ':hover': {
                    background: selected
                      ? 'linear-gradient(154.16deg, #31D8BF -3.9%, #0965F1 126.56%) !important'
                      : '#383b41',
                  },
                }}
                {...item}
              >
                {pageNumber}
              </Button>
            );
          }

          if (type === 'previous' || type === 'next') {
            const isPrev = type === 'previous';
            children = (
              <Button
                sx={{ height: 32, padding: '0 8px' }}
                size="small"
                startIcon={isPrev && <ChevronLeftIcon />}
                endIcon={!isPrev && <ChevronRightIcon />}
                {...item}
              >
                {isPrev ? 'Prev' : 'Next'}
              </Button>
            );
          }

          return <li key={index}>{children}</li>;
        })}
      </List>
    </nav>
  );
};
