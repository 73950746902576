import { Box, InputLabel } from '@chaos/ui';
import { CustomReactSelect } from '@chaos/ui/custom-select';
import { wrappedSymbolToIconSymbol } from '@chaos/ui/utils/icon-helper';
import { MarketType } from '../types';

type Props = {
  markets: MarketType[],
  market: MarketType,
  baseAsset: string,
  manipulatedAsset: string,
  marketsAssetsMapping: Record<MarketType, string[]>,
  onSelectMarket: (market: MarketType) => Promise<void>;
  onSelectBaseAsset: (asset: string) => Promise<void>;
  onSelectManipulatedAsset: (asset: string) => Promise<void>;
};

const MarketAssetSelects = ({
  markets,
  market,
  baseAsset,
  manipulatedAsset,
  marketsAssetsMapping,
  onSelectMarket,
  onSelectBaseAsset,
  onSelectManipulatedAsset,
}: Props) => {
  const marketsSelectOptions = markets.map((m) => ({
    value: m,
    label: m,
    cryptoIcon: m.toLowerCase(),
  }));

  const assetsOptions = marketsAssetsMapping[market].map((a) => ({
    value: a,
    label: a,
    cryptoIcon: wrappedSymbolToIconSymbol(a.toLowerCase()),
  })) || [];

  return (
    <Box display="flex" gap={3}>
      <Box flex="1">
        <InputLabel>Market</InputLabel>
        <CustomReactSelect
          name="market"
          placeholder="Select Market"
          options={marketsSelectOptions}
          value={{
            value: market,
            label: market,
            cryptoIcon: market.toLowerCase(),
          }}
          onChange={(selected) => {
            if (!selected) return;
            const newSelectedMarket = selected.value as MarketType;
            void onSelectMarket(newSelectedMarket);
          }}
        />
      </Box>
      <Box flex="1">
        <InputLabel>Collateral Asset</InputLabel>
        <CustomReactSelect
          name="asset"
          placeholder="Select Asset"
          options={assetsOptions}
          value={{
            value: manipulatedAsset,
            label: manipulatedAsset,
            cryptoIcon: wrappedSymbolToIconSymbol(manipulatedAsset.toLowerCase()),
          }}
          onChange={(selected) => {
            if (!selected) return;
            void onSelectManipulatedAsset(selected.value);
          }}
        />
      </Box>
      <Box flex="1">
        <InputLabel>Borrow Asset</InputLabel>
        <CustomReactSelect
          name="baseAsset"
          placeholder="Select Base Asset"
          options={assetsOptions}
          value={{
            value: baseAsset,
            label: baseAsset,
            cryptoIcon: wrappedSymbolToIconSymbol(baseAsset.toLowerCase()),
          }}
          onChange={(selected) => {
            if (!selected) return;
            void onSelectBaseAsset(selected.value);
          }}
        />
      </Box>
    </Box>
  );
};

export default MarketAssetSelects;
