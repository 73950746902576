import {
  FC, MouseEvent, useCallback, useContext, useRef, useState,
} from 'react';
import {
  Box, Menu, MenuItem, Typography,
} from '@chaos/ui';
import { IconButton, MenuListProps } from '@mui/material';
import { useAppDispatch } from 'src/store';
import { setTeamId } from 'src/store/team/reducer';
import { TeamContext } from 'src/context/team-context';
import { CustomIcon } from 'src/components/custom-icon';
import { getTeamMenuStyles, TEAM_SWITCHER_STYLES } from './styles';

export const TeamSwitcher: FC<{ menuOpen: boolean }> = ({ menuOpen }) => {
  const [open, setOpen] = useState<boolean>(false);
  const { team, teams, setTeam } = useContext(TeamContext);
  const dispatch = useAppDispatch();
  const teamInfoElement = useRef<null | HTMLElement>(
    null,
  );
  const toggleOpen = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  if (!team) {
    return null;
  }

  const teamName = menuOpen ? team.name : team.name.slice(0, 1);

  const handleTeamMenuItemClick = (
    _: MouseEvent<HTMLElement>,
    index: number,
  ) => {
    dispatch(setTeamId(teams[index].id));
    if (setTeam) {
      setTeam(teams[index].id);
    }
    setOpen(false);
  };

  return (
    <>
      <Box className="team-switcher" sx={TEAM_SWITCHER_STYLES} ref={teamInfoElement} onClick={toggleOpen}>
        <Typography fontSize={24}>{teamName}</Typography>
        {menuOpen && (
        <IconButton
          sx={{
            padding: 0,
            svg: {
              transition: 'transform 0.3s ease',
              transform: `rotate(${open ? 180 : 0}deg)`,
            },
          }}
          size="small"
        >
          <CustomIcon icon="chevron-down" />
        </IconButton>
        )}

      </Box>
      <Menu
        anchorEl={teamInfoElement.current}
        anchorOrigin={{
          vertical: menuOpen ? 48 : -16,
          horizontal: menuOpen ? -14 : 64,
        }}
        id="teams-menu"
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={open}
        onClose={toggleOpen}
        MenuListProps={{ component: 'div' } as Partial<MenuListProps<'ul', { component: string }>>}
        sx={getTeamMenuStyles(menuOpen)}
      >
        <Box component="ul" sx={{ overflow: 'hidden', py: 1, borderRadius: '16px' }}>
          {teams.map((option, index) => (
            option.name && (
            <MenuItem
              key={option.id}
              selected={option.id === team?.id}
              onClick={(event) => handleTeamMenuItemClick(event, index)}
              sx={{
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Typography>{option.name}</Typography>
            </MenuItem>
            )
          ))}
        </Box>
      </Menu>
    </>
  );
};
