import { FC } from 'react';
import ReactSelect, { MultiValue } from 'react-select';
import { useMediaQuery, Theme } from '../theme';
import { InputLabel } from '../input-label';
import { FormHelperText } from '../form-helper-text';
import { FormControl } from '../form-control';
import {
  customReactSelectComponents,
  customMultiReactSelectComponents,
} from './config';
import {
  FormControlProps, CustomReactSelectProps, CustomMultiReactSelectProps, OptionType,
} from './types';
import { styles } from './styles';

const SelectFormControl: FC<FormControlProps> = ({
  label, helperText, error, disabled, fullWidth, sx, children,
}) => (
  <FormControl {...{
    error, disabled, fullWidth, sx,
  }}
  >
    {label && <InputLabel sx={{ opacity: disabled ? 0.3 : 1 }}>{label}</InputLabel>}
    {children}
    {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
  </FormControl>
);

export const CustomReactSelect: FC<CustomReactSelectProps> = (
  {
    error,
    variant,
    disabled,
    ...props
  },
) => {
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  return (
    <SelectFormControl {...props} disabled={disabled} error={error}>
      <ReactSelect
        styles={styles({ error, variant, isMobile })}
        isDisabled={disabled}
        components={customReactSelectComponents}
        {...props}
      />
    </SelectFormControl>
  );
};

const SELECT_ALL_VALUE = '*';
const selectAllOption = { label: 'Select All', value: SELECT_ALL_VALUE, customIcon: 'printer' };

export const CustomMultiReactSelect: FC<CustomMultiReactSelectProps> = ({
  controlShouldRenderValue,
  disabled,
  error,
  variant,
  btnProps,
  deleteItemBtnProps,
  allowSelectAll,
  onChange,
  options,
  value,
  ...props
}) => {
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const values = value as MultiValue<OptionType> | undefined;

  const isSelectAllSelected = !!allowSelectAll && values?.length === options?.length;
  const isOptionSelected = (option: OptionType) => (values || []).some(({
    value: val,
  }) => val === option.value) || isSelectAllSelected;

  return (
    <SelectFormControl {...props} disabled={disabled} error={error}>
      <ReactSelect
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        controlShouldRenderValue={controlShouldRenderValue || false}
        isClearable={false}
        isDisabled={disabled}
        backspaceRemovesValue={false}
        tabSelectsValue={false}
        styles={styles({ error, variant, isMobile })}
        components={customMultiReactSelectComponents({
          btnProps,
          deleteItemBtnProps,
        })}
        isOptionSelected={allowSelectAll ? isOptionSelected : undefined}
        options={allowSelectAll ? [selectAllOption, ...(options || [])] : options}
        value={isSelectAllSelected ? options as OptionType[] : values}
        onChange={(selected, actionMeta) => {
          const { action, option, removedValue } = actionMeta;

          if (action === 'select-option' && option?.value === selectAllOption.value) {
            return onChange?.(options as OptionType[] || [], actionMeta);
          }

          if (
            (action === 'deselect-option' && option?.value === selectAllOption.value)
            || (action === 'remove-value' && removedValue.value === selectAllOption.value)
          ) {
            return onChange?.([], actionMeta);
          }

          if (actionMeta.action === 'deselect-option' && isSelectAllSelected) {
            return onChange?.(
              (options as OptionType[] || []).filter((opt) => opt.value !== option?.value),
              actionMeta,
            );
          }

          return onChange?.(selected, actionMeta);
        }}
        {...props}
      />
    </SelectFormControl>
  );
};
