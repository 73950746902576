import { palette } from './palette';

export const buttonStyles = {
  primary: {
    background: palette.blue.main,
    '&:hover': {
      background: palette.blue.hover,
    },
    '&:not(:disabled):active': {
      background: palette.blue.active,
    },
    '&.Mui-disabled': {
      background: palette.blue.main,
      color: palette.white.main,
      opacity: 0.5,
    },
  },
  secondary: {
    background: palette.button.main,
    '&:hover': {
      background: palette.button.hover,
    },
    '&:not(:disabled):active': {
      background: palette.button.active,
    },
    '&.Mui-disabled': {
      background: palette.button.main,
      color: palette.white.main,
      opacity: 0.5,
    },
  },
  error: {
    background: palette.red.main,
    '&:hover': {
      background: palette.red.hover,
    },
    '&:not(:disabled):active': {
      background: palette.red.active,
    },
    '&.Mui-disabled': {
      background: palette.red.main,
      color: palette.white.main,
      opacity: 0.5,
    },
  },
  ghost: {
    background: 'transparent',
    '&:hover': {
      background: palette.button.hover,
    },
    '&:not(:disabled):active': {
      background: palette.button.active,
    },
    '&.Mui-disabled': {
      background: palette.button.main,
      color: palette.white.main,
      opacity: 0.5,
    },
  },
};
