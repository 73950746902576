import { useCallback, useState } from 'react';

export type HexOrDec = 'hex' | 'dec';

type ConvertFunc = (dec: string | number) => string;

export const useHexDecConvert = (number: string, initial: HexOrDec = 'hex') => {
  const [value, setValue] = useState<string>(number);
  const [currentNumSystem, setCurrentNumSystem] = useState<HexOrDec>(initial);
  const hexToDec = useCallback<ConvertFunc>(
    (hex: string | number) => BigInt(hex).toString(),
    [],
  );

  const decToHex = useCallback<ConvertFunc>((dec: string | number) => `0x${BigInt(dec).toString(16)}`, []);

  const convertFuncConfig: Record<HexOrDec, ConvertFunc> = {
    hex: decToHex,
    dec: hexToDec,
  };

  const convert = (to: HexOrDec = 'hex') => {
    setValue(convertFuncConfig[to](value));
    setCurrentNumSystem(to);
  };

  return {
    currentNumSystem,
    convertedNumber: value,
    convert,
  };
};
