import { FC } from 'react';
import { Chip, ChipProps } from '../chip';

export const TagChip: FC<ChipProps> = (props) => (
  <Chip
    {...props}
    sx={{
      padding: 0,
      height: 24,
      borderRadius: 1,
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 'fontWeightBold',
      '.MuiChip-icon': {
        marginLeft: 0,
      },
      '& .MuiChip-label': {
        p: '2px 8px',
        display: 'inline-block',
        minWidth: 15,
      },
      '.MuiChip-icon + .MuiChip-label': {
        paddingLeft: 0.5,
      },
      ...(props.sx || {}),
    }}
  />
);
