import { useContext, useEffect } from 'react';
import { BlockchainSimulation } from '@chaos/types';
import { TeamContext } from 'src/context/team-context';

export const useSimulationTeam = (simulation?: BlockchainSimulation) => {
  const { team, setTeam } = useContext(TeamContext);

  useEffect(() => {
    if (simulation && setTeam && simulation.team.id !== team?.id) {
      setTeam(simulation.team.id);
    }
  }, [setTeam, simulation, team]);
};
