import { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box } from '../box';
import { Grid } from '../grid';
import { Button } from '../button';
import { Typography } from '../typography';

interface KeyValueModalProps {
  onClose?: () => void
  json: (Record<string, string> & { title?: string, subtitle?: string })[]
  title?: string
}

export const KeyValueModal: FC<KeyValueModalProps> = ({ onClose, json, title }) => (
  <>
    <Box component="header">
      <Box className="modal-header" sx={{ py: '36px' }}>
        <Typography variant="h2">{title || 'JSON'}</Typography>
      </Box>
    </Box>
    <Box className="modal-body">
      <Box>
        {json.map((item) => (
          <Box mb={1}>
            {item.title && <Typography variant="h2" mb={3} mt={4}>{item.title}</Typography>}
            <Box key={uuidv4()} borderRadius={2} bgcolor="background.modal" p={2}>
              {item.subtitle && <Typography mb={3}>{item.subtitle}</Typography>}
              <Grid container rowSpacing={2}>
                {Object.entries(item).map(([key, value]) => key !== 'title' && key !== 'subtitle' && (
                  <Grid item xs={12} key={key}>
                    <Typography variant="caption">{key}</Typography>
                    <Typography>{value}</Typography>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        ))}
        {json.length === 0 && (
        <Typography
          sx={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: '24px',
            color: 'light.main',
            lineHeight: '40px',
          }}
        >
          No data to show
        </Typography>
        )}
      </Box>
    </Box>
    <Box component="footer">
      <Box className="modal-footer" sx={{ py: 5 }}>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
      </Box>
    </Box>
  </>
);
