import { FC, useCallback } from 'react';
import { FileValue } from '@chaos/types';
import { FileUpload, TypePropsOnDeleteFile, TypePropsOnDropFile } from 'src/components/file-upload';
import useFirebaseStorage from '../../hooks/useFirebaseStorage';

export interface FirebaseUploaderProps {
  category: string,
  value?: FileValue | null
  onChange?: (value?: FileValue) => void
  onUploadSuccess?: (fileData: FileValue) => void
  onDeleteSuccess?: () => void
  onUploadError?: (error: Error) => void
  onDeleteError?: (error: Error) => void
  fallbackPreviewPath?: string
  isInitialHoverDisabled?: boolean
  isDark?: boolean
  disabled?: boolean
}

export const FirebaseUploader: FC<FirebaseUploaderProps> = ({
  value,
  category,
  onChange,
  fallbackPreviewPath,
  onUploadSuccess,
  onDeleteSuccess,
  onUploadError,
  onDeleteError,
  isInitialHoverDisabled,
  isDark,
  disabled,
}) => {
  const { uploadFile, deleteFile } = useFirebaseStorage({ category });
  const onDrop:TypePropsOnDropFile = useCallback(([acceptedFile]) => uploadFile(acceptedFile)
    .then((file) => {
      if (onUploadSuccess) {
        onUploadSuccess(file);
      }
      return file;
    })
    .catch((error: Error) => {
      if (onUploadError) {
        onUploadError(error);
      }
      return Promise.reject(error);
    }), [uploadFile, onUploadSuccess, onUploadError]);

  const onDeleteFile: TypePropsOnDeleteFile = useCallback((file) => deleteFile(file)
    .then(() => {
      if (onDeleteSuccess) {
        onDeleteSuccess();
      }
    })
    .catch((error: Error) => {
      if (onDeleteError) {
        onDeleteError(error);
      }
      return Promise.reject(error);
    }), [onDeleteSuccess, onDeleteError, deleteFile]);

  return (
    <FileUpload
      onDeleteFile={onDeleteFile}
      onDropFile={onDrop}
      value={value}
      onChange={onChange}
      isDark={isDark}
      disabled={disabled}
      fallbackPreviewPath={fallbackPreviewPath}
      isInitialHoverDisabled={isInitialHoverDisabled}
    />
  );
};
