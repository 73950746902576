import { CommunityBaseURL } from '../constants/urls';

const isStaging = () => {
  const { hostname } = window.location;
  return hostname.includes('staging')
  || ['localhost', '127.0.0.1'].includes(hostname);
};

type RecResultURLInput = {
  client: string
  resultID: string
};

export const communityRecommendationResultsURL = (input: RecResultURLInput) => {
  let origin: string;
  if (isStaging()) {
    origin = CommunityBaseURL.STAGING;
  } else {
    origin = CommunityBaseURL.PROD;
  }
  return `${origin}/${input.client}/recommendations/${input.resultID}`;
};
