import {
  FC, memo, useMemo, useState,
} from 'react';
import { ScriptResource, ObservationRef, ObserverGroupMetadata } from '@chaos/types';
import { Grid } from '@chaos/ui';
import { ObserverWidget, ObserverWidgetProps, GroupChildObserver } from './components/observer-widget';
import { BlockDetails } from './block-details';
import { NumericObservers } from './types';
import { colorScale } from '../charts/utils';

export interface ObserversViewProps {
  data: NumericObservers;
  observers?: ScriptResource[];
  observationsRef?: ObservationRef[];
  observerGroupsMetadata?: ObserverGroupMetadata[];
  startBlock?: number;
  simulationResultId: string;
  iterationsRange: [number, number];
  currentIteration?: number;
}

export function nameFromPath(pathName: string): string {
  return pathName.substring(pathName.lastIndexOf('/') + 1);
}

export const ObserversView: FC<ObserversViewProps> = memo(({
  data,
  observers,
  observationsRef,
  observerGroupsMetadata,
  startBlock,
  simulationResultId,
  iterationsRange,
  currentIteration,
}) => {
  const [currentIterationNumber, setCurrentIterationNumber] = useState<number>();
  const observersWidgetsData: ObserverWidgetProps[] = useMemo(
    () => Object.keys(data).map((path) => {
      const observationRef = observationsRef?.find((ref) => ref.path === path);
      const name = observationRef?.name || nameFromPath(path);
      const observerConfig = observers?.find((observer) => observationRef?.id === observer.id
      || observationRef?.id === observer.refId);

      return {
        id: observerConfig?.id,
        instanceId: observerConfig?.instanceId,
        title: name,
        description: observerConfig?.description,
        observation: data[path].result,
        observerConfig,
        displayOrder: observerConfig?.displayOrder,
        startBlock,
        iterationsRange,
        currentIteration,
        onBlockClick: (iterationNumber: number) => setCurrentIterationNumber(iterationNumber),
      };
    }),
    [data, observationsRef, observers, startBlock, iterationsRange, currentIteration],
  );

  const observersGroups: ObserverWidgetProps[] = useMemo(
    () => (observerGroupsMetadata || []).reduce((
      acc: ObserverWidgetProps[],
      group: ObserverGroupMetadata,
    ) => {
      const newAcc: ObserverWidgetProps[] = [...acc];
      const observersProps = observersWidgetsData.filter(({ id, instanceId }) => (
        (id && group?.observerIds?.includes(id))
        || (instanceId && group?.observerInstanceIds?.includes(instanceId))
      ));

      const groupChildObservers: GroupChildObserver[] = observersProps.reduce((
        observersObjs: GroupChildObserver[],
        { id, instanceId, title }: ObserverWidgetProps,
      ) => {
        if (!id || !title) return observersObjs;
        const newChildObserver: GroupChildObserver = {
          id,
          title,
          secondaryAxis: !!(group.secondaryAxisObserverIds?.includes(id)
          || (instanceId && group.secondaryAxisInstanceIds?.includes(instanceId))),
        };
        return [...observersObjs, newChildObserver];
      }, []);

      if (observersProps.length) {
        const groupProps: ObserverWidgetProps = {
          title: group.groupName,
          observation: observersProps.map((obs) => (obs.observation as number[])),
          groupChildObservers,
          observerConfig: {
            dataType: group.dataType,
            chartType: group.chartType,
            valueType: group.valueType,
            valueLabel: group.valueLabel,
          },
          startBlock,
          iterationsRange,
          currentIteration,
          onBlockClick: (iterationNumber: number) => setCurrentIterationNumber(iterationNumber),
          legends: group.legends,
          displayOrder: group.displayOrder,
        };

        newAcc.push(groupProps);
      }

      return newAcc;
    }, [] as ObserverWidgetProps[]),
    [observerGroupsMetadata, observersWidgetsData, startBlock, iterationsRange, currentIteration],
  );

  const observersIdsInGroups = useMemo(() => (observerGroupsMetadata || [])
    .map(({ observerIds, observerInstanceIds }) => [...(observerIds || []),
      ...(observerInstanceIds || [])]).flat(), [observerGroupsMetadata]);

  const filteredObserversWidgetsData = useMemo(() => observersWidgetsData.filter(
    ({ id, instanceId }) => !((id && observersIdsInGroups.includes(id))
    || (instanceId && observersIdsInGroups.includes(instanceId))),
  ), [observersWidgetsData, observersIdsInGroups]);

  const renderData = useMemo(
    () => [...observersGroups, ...filteredObserversWidgetsData]
      .sort((obsA, obsB) => (obsA.displayOrder || 0) - (obsB.displayOrder || 0))
      .filter(({ observerConfig }) => observerConfig?.isVisible !== false),
    [filteredObserversWidgetsData, observersGroups],
  );

  const blockDetails = useMemo(() => {
    if (currentIterationNumber) {
      const groupedObserversDetails = observerGroupsMetadata?.map((g) => ({
        title: g.groupName,
        data: [...(g.observerIds || []), ...(g.observerInstanceIds || [])].map((id, i) => {
          const o = observersWidgetsData.find((d) => d.id === id || d.instanceId === id);

          return {
            title: o?.title || '',
            value: (o?.observation as number[])[currentIterationNumber],
            color: colorScale[i % colorScale.length],
          };
        }),
      })) || [];
      const singleObserversDetails = filteredObserversWidgetsData.map((o) => ({
        title: o.title || '',
        data: [{
          title: o.title || '',
          value: (o.observation as number[])[currentIterationNumber],
          color: colorScale[0],
        }],
      }));

      return [...groupedObserversDetails, ...singleObserversDetails];
    }

    return undefined;
  }, [
    currentIterationNumber,
    filteredObserversWidgetsData,
    observerGroupsMetadata,
    observersWidgetsData,
  ]);

  return (
    <Grid
      container
      spacing={2}
      flex={1}
    >
      {renderData.map((props: ObserverWidgetProps) => (
        <Grid
          item
          key={props.id || props.title}
          sm={12}
          md={(typeof props?.observation[0] === 'object' || renderData.length === 1) ? 12 : 6}
        >
          <ObserverWidget {...props} />
        </Grid>
      ))}
      {currentIterationNumber && (
        <BlockDetails
          iterationNumber={currentIterationNumber}
          observers={blockDetails}
          simulationResultId={simulationResultId}
          startBlock={startBlock}
          onClose={() => setCurrentIterationNumber(undefined)}
        />
      )}
    </Grid>
  );
});
