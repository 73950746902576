import {
  AssertionType,
  BlockchainSimulationAssertionResult,
  BlockchainSimulationResult,
  Header,
  RenderData,
} from '@chaos/types';
import { formatDateAndTime } from 'src/utils/formatters';

export const blockchainSimulationTitle = (result?: BlockchainSimulationResult): string => {
  if (!result) return '';
  return `Simulation from ${formatDateAndTime(result.created.toDate())}`;
};

export function assertionViewHeaders(): Array<Header> {
  return [
    { renderType: 'TEXT', text: 'Name' },
    { renderType: 'TEXT', text: 'Type' },
    { renderType: 'TEXT', text: 'Status' },
    { renderType: 'TEXT', text: 'Description' },
    { renderType: 'TEXT', text: 'Details' },
  ];
}

const assertionTypeConverter: Record<AssertionType, string> = {
  OneTime: 'One Time',
  Invariant: 'Invariant',
};

function assertionToView(result: BlockchainSimulationAssertionResult): RenderData[] {
  return [
    { renderType: 'TEXT', text: result?.name },
    { renderType: 'TEXT', text: result?.type ? assertionTypeConverter[result.type] : '' },
    result?.success
      ? {
        renderType: 'LABELS',
        text: 'Passed',
        extraData: {},
        status: 'OK',
        width: '82px',
      }
      : {
        renderType: 'LABELS',
        text: 'Failed',
        extraData: { title: `Failure on block ${result?.failureBlock ?? 0}` },
        status: 'ERROR',
        width: '82px',
      },
    { renderType: 'TEXT', text: result?.description ? result?.description : 'N/A' },
    { renderType: 'TEXT', text: result?.errorMessage ?? 'N/A' },
  ];
}

export function ResultToassertionsToView(result: BlockchainSimulationResult): Array<RenderData[]> {
  const results: Array<RenderData[]> = [];
  const assertionResults = result?.result.assertions;
  if (!assertionResults) {
    return results;
  }
  return assertionResults.map((assertionResult) => assertionToView(assertionResult));
}
