import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/analytics';
import 'firebase/compat/auth';
import 'firebase/compat/storage';

import { __DEV__ } from 'src/config/constants';
import enableEmulator from './enable-emulators';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

let firebaseApp;

if (!firebase.apps.length) {
  firebaseApp = firebase.initializeApp(firebaseConfig);

  // This enables the emulator.
  // make sure that you are running the emulator
  // by going to /packages/functions and running
  // `yarn serve`. If you don't want to use the emulator
  // and prefer to use `zaboca-dev` on firebase (staging)
  // simply comment change the REACT_APP_FIREBASE_USE_EMULATORS env
  // variable to false.
  if (__DEV__ && process.env.REACT_APP_FIREBASE_USE_EMULATORS === 'true') {
    enableEmulator();
  }

  void firebase.analytics.isSupported().then((isSupported) => {
    if (isSupported) firebase.analytics();
  });
}

export const auth = firebase.auth(firebaseApp);
export const functions = firebase.functions(firebaseApp);
export const firestore = firebase.firestore(firebaseApp);
export const storage = firebase.storage(firebaseApp);

export { firebase };
