import { CellStatusType, RenderData } from '@chaos/types';
import { TokenPairCell, TokenPairProps } from './custom-cells';
import {
  capitalizeFirstLetter, formatAddressCompact, formatCsvDate, formatDateAndTime, formatNumericValue,
} from '../utils/formatters';

export const addressCell = (address: string): RenderData => (
  {
    renderType: 'TEXT',
    text: formatAddressCompact(address),
    tooltipText: address,
    searchTerm: address,
    copyText: address,
    exportText: address,
  }
);

export const dateCell = (date?: Date): RenderData => ({
  renderType: 'TEXT',
  text: formatDateAndTime(date) || '',
  exportText: formatCsvDate(date) || '',
  value: date?.getTime(),
});

export const assetCell = (key: string, text?: string): RenderData => ({
  renderType: 'TEXT',
  text: text || key,
  tooltipText: key,
  token1: key,
});

export const currencyCell = (value: number | null, maximumFractionDigits = 2): RenderData => ({
  text: formatNumericValue(value || 0, {
    currency: 'USD',
    notation: 'compact',
    maximumFractionDigits,
  }),
  tooltipText: formatNumericValue(value || 0, {
    currency: 'USD',
    notation: 'standard',
    maximumFractionDigits,
  }),
  renderType: 'TEXT',
  value: Number(value || 0),
});

export const labelsCell = (status: CellStatusType, text = '', tooltipText = '', icon?: string): RenderData => ({
  renderType: 'LABELS',
  text,
  status,
  tooltipText,
  customIcon: icon,
});

type ValueType = 'currency' | 'percent' | 'decimal';

export const getTextByValueType = (value: number, valueType: ValueType, notation: 'compact' | 'standard' = 'standard') => formatNumericValue(value || 0, {
  currency: valueType === 'currency' ? 'USD' : undefined,
  isPercent: valueType === 'percent' ? true : undefined,
  notation,
  maximumFractionDigits: 2,
});

export const valueCell = (value: number, valueType: ValueType = 'decimal'): RenderData => ({
  renderType: 'TEXT',
  text: getTextByValueType(value, valueType, 'compact'),
  tooltipText: getTextByValueType(value, valueType, 'standard'),
  value,
});

export const valueLabelsCell = (status: CellStatusType, value: number, valueType: ValueType = 'decimal'): RenderData => ({
  renderType: 'LABELS',
  text: getTextByValueType(value, valueType, 'compact'),
  tooltipText: getTextByValueType(value, valueType, 'standard'),
  status,
  value,
});

export const iconsCell = (icons: string[], text = icons.join(', '), tooltipText = ''): RenderData => ({
  renderType: 'ICONS',
  text,
  tooltipText,
  icons,
});

export const chainCell = (key?: string, text = '', tooltipText = ''): RenderData => ({
  renderType: 'TEXT',
  text: capitalizeFirstLetter(text),
  tooltipText: capitalizeFirstLetter(tooltipText),
  token1: key,
  extraData: {
    symbol: key,
  },
});

export const numberCell = (
  value: number | null,
  tokenIcon?: string,
  maximumFractionDigits?: number,
): RenderData => ({
  text: formatNumericValue(value || 0, {
    notation: 'compact',
    maximumFractionDigits: maximumFractionDigits || 2,
  }),
  tooltipText: formatNumericValue(value || 0, {
    notation: 'standard',
    maximumFractionDigits: maximumFractionDigits || 2,
  }),
  renderType: 'TEXT',
  value: value || 0,
  token1: tokenIcon,
});

export const apyCell = (value: number | null, threshold?: number): RenderData => ({
  text: (threshold && value && (value * 100) < threshold)
    ? `<${threshold}%`
    : formatNumericValue(value || 0, { isPercent: true, notation: 'compact' }),
  renderType: 'TEXT',
  value: value ? Number(value * 100) : 0,
  tooltipText: (threshold && value && value < threshold)
    ? formatNumericValue(value || 0, { isPercent: true, notation: 'compact' })
    : undefined,
});

export const percentCell = (value: number | null, tooltipText?: string, renderType: 'TEXT' | 'CHIP' = 'TEXT'): RenderData => ({
  text: formatNumericValue(value || 0, { isPercent: true, notation: 'compact' }),
  renderType,
  value: value ? Number(value * 100) : 0,
  tooltipText,
});

export const progressCell = (
  value: number | null,
): RenderData => ({
  renderType: 'PROGRESS',
  progressValue: value || 0,
  width: 150,
  text: `${value || 0}`,
  value: (value || 0) * 100,
});

export const textCell = (text: string, value?: number, tokenIcon?: string)
: RenderData => ({
  text,
  tooltipText: text,
  renderType: 'TEXT',
  value,
  token1: tokenIcon,
});

export const markedCell = (isMarked: boolean, label: string, tooltip?: string): RenderData => ({
  renderType: 'TEXT',
  text: isMarked ? `With ${label}` : `Without ${label}`,
  isMarked,
  tooltipText: tooltip,
});

export const tokenPairCell = (props: TokenPairProps): RenderData => ({
  renderType: 'CUSTOM',
  text: `${props.token1}/${props.token2}`,
  component: <TokenPairCell {...props} />,
});

export const linkCell = (text: string, href: string, tooltip?: string): RenderData => ({
  renderType: 'LINK',
  text,
  href,
  tooltipText: tooltip || text,
  exportText: tooltip || text,
});

export const diffCell = (value: number, prevValue: number, valueType: ValueType = 'decimal'): RenderData => ({
  renderType: 'RECOMMENDATION',
  text: getTextByValueType(value, valueType, 'compact'),
  previousText: getTextByValueType(prevValue, valueType, 'compact'),
  tooltipText: getTextByValueType(value, valueType, 'standard'),
  value,
});
