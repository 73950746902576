import { ToolItemProps } from '@chaos/types';
import { ReactNode } from 'react';
import { AssetProtectionTool } from './aave/AssetProtectionTool';
import { BorrowSupplyCapProtocol, BorrowSupplyCapTool } from './BorrowSupplyCapTool';
import { ParamExplorer } from './aave/ParamExplorer';
import { PythonSimulation } from './aave/PythonRecommendation';
import { ChaosApolloProvider } from './utils/graphql';
import { BlockMappingTool } from './BlockMappingTool/BlockMappingTool';

export type PrivateDashboard = ToolItemProps & {
  component: ReactNode
};

const borrowSupplyCapItem = (protocol: BorrowSupplyCapProtocol, isChainMarket?: boolean) => ({
  id: 'borrow-supply-cap',
  icon: '',
  title: 'Borrow & Supply Caps',
  subtitle: 'Recommended values for borrow and supply caps',
  link: 'borrow-supply-cap',
  component: <BorrowSupplyCapTool protocol={protocol} isChainMarket={isChainMarket} />,
});

export const privateDashboardsByClient: Record<string, PrivateDashboard[]> = {
  aave: [
    {
      id: 'asset-protection',
      icon: '',
      title: 'Asset Protection Tool',
      subtitle: 'Analyze cost and profitability of price manipulation of low liquidity assets',
      link: 'asset-protection',
      component: <AssetProtectionTool />,
    },
    borrowSupplyCapItem('aave', true),
    {
      id: 'param-explorer',
      icon: '',
      title: 'Param Explorer',
      subtitle: 'Identify effects of risk parameter updates on accounts health and liquidations',
      link: 'param-explorer',
      component: <ParamExplorer />,
    },
    {
      id: 'param-recommendation-simulation',
      icon: '',
      title: 'Param Recommendation Simulation',
      link: 'param-recommendation-simulation',
      component: <ChaosApolloProvider protocol="aave"><PythonSimulation /></ChaosApolloProvider>,
    },
  ],
  venus: [
    borrowSupplyCapItem('venus'),
  ],
  benqi: [
    borrowSupplyCapItem('benqi', true),
  ],
  tashi: [
    borrowSupplyCapItem('tashi', true),
  ],
  seamless: [
    borrowSupplyCapItem('seamless', true),
  ],
  demo: [
    borrowSupplyCapItem('aave', true),
  ],
  dogfood2: [
    {
      id: 'param-recommendation-simulation',
      icon: '',
      title: 'Parameter Recommendation Simulation',
      link: 'param-recommendation-simulation',
      component: <ChaosApolloProvider protocol="aave"><PythonSimulation /></ChaosApolloProvider>,
    },
    {
      id: 'block-mapping',
      icon: '',
      title: 'Block Mapping Tool',
      subtitle: 'Get block height for a specific data and time across different blockchains',
      link: 'block-mapping',
      component: <BlockMappingTool />,
    },
  ],
};
