import React from 'react';
import { SxProps } from '@mui/material';
import { Box } from '../box';
import { getIconSrc } from './get-icon-src';
import DefaultImage from '../assets/img/crypto/default.svg';

enum IconSize {
  'x-small' = 16,
  'small' = 20,
  'default' = 24,
}

export interface CryptoIconProps {
  icon: string
  className?: string
  sx?: SxProps
  size?: 'x-small' | 'small' | 'default'
}

export const CryptoIcon = React.forwardRef<unknown, CryptoIconProps>(
  ({
    icon, className, sx, size = 'default', ...props
  }, ref) => {
    const sizeValue = size && IconSize[size];

    return (
      <Box
        {...props}
        component="span"
        ref={ref}
        className={`${icon}-icon ${className || ''}`}
        sx={{
          display: 'inline-flex',
          verticalAlign: 'middle',
          alignItems: 'center',
          img: {
            ...(sizeValue && { width: sizeValue, height: sizeValue }),
            display: 'block',
          },
          ...sx,
        }}
      >
        <img alt={`${icon}-icon`} src={getIconSrc(icon) || DefaultImage} />
      </Box>
    );
  },
);

CryptoIcon.defaultProps = {
  className: undefined,
  sx: undefined,
  size: undefined,
};
