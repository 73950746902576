import { FC, useMemo, useState } from 'react';
import {
  AggregationMethods,
  ScriptResource,
  SimulationPermutationInfo,
  SimulationPermutationObservationResult,
  SimulationStatistics,
} from '@chaos/types';
import { ScatterChartRow, ScatterChartRowProps } from 'src/components/charts';
import { RouteParams, RoutePath } from 'src/config/routes';
import { aggregateDataOptions, parsePermutationInfo } from 'src/hooks/parsePermutationInfo';
import {
  Box, Paper, Typography, CustomReactSelect,
} from '@chaos/ui';

export interface ParamConfigScatterChartRowProps {
  simulationResultId: string;
  permutationInfo: SimulationPermutationInfo[];
  observationsInfo: SimulationPermutationObservationResult[];
  observation: ScriptResource;
  simulationStatistics?: SimulationStatistics;
}

export const ParamConfigScatterChartRow: FC<ParamConfigScatterChartRowProps> = ({
  simulationResultId,
  permutationInfo,
  observationsInfo,
  observation,
  simulationStatistics,
}) => {
  const configurableParams = permutationInfo[0]?.args ?? [];
  const firstArgName = configurableParams[0].argName;
  const [scatterData, setScatterData] = useState<ScatterChartRowProps>();
  const [selectedArgName, setSelectedArgName] = useState<string>();
  const [selectedAggregation, setSelectedAggregation] = useState<string>(AggregationMethods.Last);

  const initialData: ScatterChartRowProps | undefined = useMemo(() => {
    if (!firstArgName) return undefined;
    const scatterChartData = parsePermutationInfo(
      permutationInfo,
      observationsInfo,
      observation,
      firstArgName,
      AggregationMethods.Last,
      simulationStatistics,
    );
    return scatterChartData;
  }, [firstArgName, observation, observationsInfo, permutationInfo, simulationStatistics]);

  return (
    <Box>
      <Paper
        color="secondary"
        sx={{
          padding: '24px 24px 32px',
          borderRadius: '1rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          bgcolor: 'background.default',
        }}
      >
        <Box display="flex" flexDirection="row" flexGrow={1} width="100%">
          <Box display="flex" flexDirection="column">
            <Typography fontSize="24px" fontWeight={600} lineHeight="32px">{observation.name}</Typography>
            <Typography fontSize="16px" fontWeight={400} lineHeight="24px">{observation.description}</Typography>
          </Box>
          <Box sx={{
            flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline',
          }}
          >
            {configurableParams.length > 1 && (
              <Box paddingRight="8px">
                <CustomReactSelect
                  name={`scatter-chart-select-${observation.name}`}
                  placeholder={configurableParams[0].argName}
                  options={configurableParams?.map((option) => (
                    { label: option.argName, value: option.argName }) ?? { })}
                  onChange={(val) => {
                    const data: ScatterChartRowProps = parsePermutationInfo(
                      permutationInfo,
                      observationsInfo,
                      observation,
                      val?.value ?? '',
                      selectedAggregation ?? AggregationMethods.Last,
                      simulationStatistics,
                    );
                    setScatterData(data);
                    setSelectedArgName(val?.value ?? '');
                  }}
                />
              </Box>
            )}
            <CustomReactSelect
              name={`scatter-chart-aggregation-${observation.name}`}
              placeholder={aggregateDataOptions[0].label}
              options={aggregateDataOptions}
              value={{ label: selectedAggregation, value: selectedAggregation }}
              onChange={(val) => {
                const data: ScatterChartRowProps = parsePermutationInfo(
                  permutationInfo,
                  observationsInfo,
                  observation,
                  selectedArgName ?? firstArgName,
                  val?.value ?? '',
                  simulationStatistics,
                );
                setScatterData(data);
                setSelectedAggregation(val?.value ?? '');
              }}
            />
          </Box>
        </Box>
        {(!!scatterData || !!initialData) && (
        <Box sx={{ height: 300, width: '100%' }}>
          <ScatterChartRow
            key={`scatter-chart-${observation.name}`}
            // only support drill-in for simulation with 1 param
            onClick={configurableParams.length === 1 ? (datum) => {
              if (!datum.metadata) return;

              if (simulationStatistics) {
                const { SimulationResultId, PermutationId } = RouteParams;
                const path = RoutePath.Simulations.ExperimentResults
                  .replace(SimulationResultId, simulationResultId)
                  .replace(PermutationId, datum.metadata);
                window.open(path);
                return;
              }
              window.open(`${RoutePath.Simulations.Results}/${datum.metadata}`);
            } : undefined}
            {...(scatterData ?? initialData!)}
            title={undefined}
            description={undefined}
          />
        </Box>
        )}
      </Paper>
    </Box>
  );
};
