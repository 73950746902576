import {
  AnyAction, applyMiddleware, combineReducers, compose, createStore,
} from 'redux';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import thunkMiddleware, { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { Team, TeamMembership } from '@chaos/types';
import { COLLECTIONS } from '@chaos/utils';
import { authLoadedReducer } from './auth/reducer';
import { RootState } from './types';
import { progressBarReducer } from './progress-bar/reducer';
import { reducer } from './dataReducer';
import { dashboardReducer } from './dashboard/reducer';
import { teamReducer } from './team/reducer';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const rootReducer = combineReducers<RootState>({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  authLoaded: authLoadedReducer,
  progressBar: progressBarReducer,
  teamMemberships: reducer<TeamMembership>(COLLECTIONS.TEAM_MEMBERSHIP),
  teams: reducer<Team>(COLLECTIONS.TEAMS),
  dashboard: dashboardReducer,
  // @ts-expect-error persist
  team: persistReducer(
    {
      key: 'team', // key for localStorage key, will be: "persist:team"
      storage,
      whitelist: ['teamId'],
    },
    teamReducer,
  ),
});

export type AppState = ReturnType<typeof rootReducer>;

export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
AppState,
unknown,
AnyAction
>;
export type AppAsyncThunk<ReturnType = void> = AppThunk<Promise<ReturnType>>;

export type AppDispatch = ThunkDispatch<AppState, undefined, AnyAction>;

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

const store = createStore(
  persistReducer(
    {
      key: 'root',
      debug: true,
      storage,
      whitelist: ['teamId'],
    },
    rootReducer,
  ),
  {},
  composeEnhancers(applyMiddleware(thunkMiddleware)),
);

export const persistor = persistStore(store, null, () => {
  // if you want to get restoredState
});

export default store;
