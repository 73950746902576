import { useEffect, useState } from 'react';
import { Subscription, throttleTime } from 'rxjs';
import {
  collection, doc, orderBy, query, where,
} from 'firebase/firestore';
import { collectionData } from 'rxfire/firestore';
import { COLLECTIONS } from '@chaos/utils';
import { SimulationEvent } from '@chaos/types';
import { firestore } from 'src/services/firebase';
import { useCurrentTeam } from './useCurrentTeam';

export const useSimulationTransactionEvents = (
  simulationResultId: string,
  transactionHash: string,
  connectionKey: string,
): { isLoading: boolean, simulationTransactionEvents: SimulationEvent[] } => {
  const team = useCurrentTeam();
  const [transactionEvents, setTransactionEvents] = useState<SimulationEvent[]>();

  useEffect(() => {
    const subs: Subscription[] = [];
    const eventsCol = collection(firestore, COLLECTIONS.ETH_EVENTS);
    const eventsObs = collectionData(query(
      eventsCol,
      where('team', '==', doc(collection(firestore, COLLECTIONS.TEAMS), team?.id)),
      where('simulationResultId', '==', simulationResultId),
      where('transactionHash', '==', transactionHash),
      where('connectionKey', '==', connectionKey),
      orderBy('logIndex', 'asc'),
    ), { idField: 'id' }).pipe(throttleTime(5000));
    subs.push(eventsObs.subscribe((data) => setTransactionEvents(data as SimulationEvent[])));

    return () => subs.forEach((s) => s.unsubscribe());
  }, [team?.id, simulationResultId, connectionKey, transactionHash]);

  return { isLoading: !transactionEvents, simulationTransactionEvents: transactionEvents || [] };
};
