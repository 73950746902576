import { useEffect, useState } from 'react';
import { Subscription, throttleTime } from 'rxjs';
import {
  collection, doc, orderBy, query, where,
} from 'firebase/firestore';
import { collectionData } from 'rxfire/firestore';
import { COLLECTIONS } from '@chaos/utils';
import { SimulationBlock } from '@chaos/types';
import { firestore } from 'src/services/firebase';
import { useCurrentTeam } from './useCurrentTeam';

export const useSimulationBlocks = (
  simulationResultId: string,
  connectionKey?: string,
  blockNumber?: number,
): {
  isLoading: boolean,
  simulationBlocks: SimulationBlock[],
} => {
  const team = useCurrentTeam();
  const [simulationBlocks, setSimulationBlocks] = useState<SimulationBlock[]>();

  useEffect(() => {
    const subs: Subscription[] = [];

    if (connectionKey) {
      const blocksCol = collection(firestore, COLLECTIONS.ETH_BLOCKS);
      const blocksObs = collectionData(query(
        blocksCol,
        where('team', '==', doc(collection(firestore, COLLECTIONS.TEAMS), team?.id)),
        where('simulationResultId', '==', simulationResultId),
        where('connectionKey', '==', connectionKey),
        blockNumber ? where('number', '==', blockNumber) : orderBy('number', 'desc'),
      ), { idField: 'id' }).pipe(throttleTime(5000));
      subs.push(blocksObs.subscribe((data) => setSimulationBlocks(data as SimulationBlock[])));
    }

    return () => subs.forEach((s) => s.unsubscribe());
  }, [team?.id, simulationResultId, connectionKey, blockNumber]);

  return { isLoading: !simulationBlocks, simulationBlocks: simulationBlocks || [] };
};
