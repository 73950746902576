import { toTitleCase } from '@chaos/ui/utils/formatters';
import { useMemo } from 'react';
import { useDataFetchersPageData } from 'src/hooks/useDataFetchersQuery';
import {
  AssetProtectionToolData, MarketType, MarketsAssetsResponse,
} from './types';

type ReturnType = {
  isLoading: boolean,
  data: AssetProtectionToolData
};

export const useAssetProtectionToolData = (): ReturnType => {
  const {
    response: marketsAssetsResponse,
    isLoading: isLoadingMarketsAssets,
  } = useDataFetchersPageData<MarketsAssetsResponse>('aave_price_manipulation_get_markets_and_assets');

  const marketsAssetsMapping: Record<MarketType, string[]> | undefined = useMemo(() => {
    if (!marketsAssetsResponse) return undefined;
    return Object.entries(marketsAssetsResponse).reduce((acc, [market, assets]) => {
      const marketName = toTitleCase(market.split('_')[1]);
      return ({
        ...acc,
        [marketName]: Object.values(assets)
          .map((asset) => asset.symbol.toUpperCase())
          .sort((a, b) => a.localeCompare(b)),
      });
    }, {} as Record<MarketType, string[]>);
  }, [marketsAssetsResponse]);

  return {
    isLoading: isLoadingMarketsAssets,
    data: {
      marketsAssetsMapping,
    },
  };
};
