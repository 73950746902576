import {
  Grid,
} from '@chaos/ui';
import { ValueCard } from '@chaos/ui/value-card';
import { formatAmount } from '@chaos/ui/utils/formatters';
import { Wallet } from '../types';

type Props = {
  wallets: Wallet[]
  defaultWallets: Wallet[]
};

const AggregationCards = ({ wallets, defaultWallets }: Props) => {
  const totalValueEligibleForLiquidation = wallets.reduce((
    total,
    wallet,
  ) => total + wallet.valueEligibleForLiquidation, 0);
  const defaultTotalValueEligibleForLiquidation = defaultWallets.reduce((
    total,
    wallet,
  ) => total + wallet.valueEligibleForLiquidation, 0);

  const valueDiff = totalValueEligibleForLiquidation - defaultTotalValueEligibleForLiquidation;
  const walletsNumberDiff = wallets.length - defaultWallets.length;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <ValueCard
          icon="exponential-graph"
          title="Eligible for Liquidations"
          value="Health < 1"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <ValueCard
          icon="deposit-icon"
          title="Total Value Eligible for Liquidation"
          value={totalValueEligibleForLiquidation}
          currency="USD"
          delta={valueDiff}
          deltaColor={valueDiff * -1}
          deltaTrend={Math.sign(valueDiff)}
          deltaTooltip={formatAmount(valueDiff, { notation: 'standard' })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <ValueCard
          icon="exponential-graph"
          title="Wallets"
          value={wallets.length}
          delta={walletsNumberDiff}
          deltaColor={walletsNumberDiff * -1}
          deltaTrend={Math.sign(walletsNumberDiff)}
          deltaTooltip={formatAmount(walletsNumberDiff, { notation: 'standard' })}
        />
      </Grid>
    </Grid>
  );
};

export default AggregationCards;
