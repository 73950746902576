import { FC, useRef } from 'react';
import {
  VictoryAxis, VictoryChart, VictoryLabel, VictoryScatter,
  VictoryBoxPlot,
} from 'victory';
import { Axis } from '@chaos/types';
import { Box } from '@chaos/ui/box';
import { useElementSize } from '@chaos/ui/hooks/use-element-size';
import { formatAxis } from '@chaos/ui/utils/formatters';
import { chartTooltip } from './chart-tooltip';
import { ChartData } from './chaos-chart';

const victoryAxisStyle = (dataClickable?: boolean) => ({
  tickLabels: {
    fill: 'rgba(255, 255, 255, .5)',
    fontFamily: 'Archivo, sans-serif',
    lineHeight: '24px',
    fontSize: 16,
  },
  axis: {
    stroke: 'rgba(255, 255, 255, .2)',
    strokeWidth: 1,
    strokeDasharray: 4,
  },
  grid: {
    stroke: 'rgba(255, 255, 255, .2)',
    strokeWidth: 1,
    strokeDasharray: 4,
    strokeLinecap: 'round',
  },
  axisLabel: {
    fontSize: 20,
    fontFamily: 'Archivo, sans-serif',
    fill: '#FFFFFF',
  },
  data: {
    fill: '#30D4C1',
    cursor: dataClickable ? 'pointer' : 'initial',
  },
});

export interface ScatterChartData {
  x: number,
  yFormattedValue?: string,
  y: number,
  label?: string,
  metadata?: string,
  interval?: {
    min: number,
    max: number,
  };
}

export interface ScatterChartProps {
  data: ScatterChartData[]
  xAxis: Axis
  yAxis: Axis
  isBoxPlot?: boolean;
  onClick?: (datum: ScatterChartData) => void
}

export const ScatterChart: FC<ScatterChartProps> = ({
  data, xAxis, yAxis, isBoxPlot, onClick,
}) => {
  const isMultiChart = Array.isArray(data?.[0]);
  const chartsData = (!data ? [] : (isMultiChart ? data : [data])) as ChartData[][];

  const chartRef = useRef<HTMLDivElement>(null);
  const size = useElementSize(chartRef);

  const getAxisValues = (axis: 'x' | 'y') => chartsData.reduce((values: number[], chartData) => {
    const chartYValues = chartData.map((item) => Number(item[axis]));
    const newValues = [...values].concat(chartYValues);
    return newValues;
  }, []);

  const yValues = getAxisValues('y');
  const maxYValue = Math.max(...yValues);

  const yLabelsLength = yValues.map(
    (y) => formatAxis(y).length,
  );

  const maxYSize = Math.max(...yLabelsLength);

  const axisStyle = victoryAxisStyle(!!onClick);

  return (
    <Box
      ref={chartRef}
      sx={{ width: '100%', height: '100%' }}
    >
      <VictoryChart
        padding={{
          top: (maxYValue < 0 ? 70 : 20),
          bottom: (maxYValue < 0 ? 30 : 110),
          left: (yAxis.label ? (maxYSize * 10) + 60 : (maxYSize * 10) + 27),
          right: 55,
        }}
        {...size}
      >
        <VictoryAxis
          style={axisStyle}
          axisLabelComponent={<VictoryLabel dy={-(maxYSize * 10)} />}
          label={yAxis.label}
          dependentAxis
          tickFormat={(t: number) => formatAxis(t, undefined, yAxis.currency)}
        />
        <VictoryAxis
          style={axisStyle}
          axisLabelComponent={<VictoryLabel dy={(maxYValue < 0 ? -15 : 30)} />}
          label={xAxis.label}
          fixLabelOverlap
          tickFormat={(t: number) => formatAxis(t, undefined, xAxis.currency)}
        />
        {isBoxPlot
          && (
          <VictoryBoxPlot
            boxWidth={0}
            whiskerWidth={10}
            data={data.map(({ x, interval }) => ({
              x,
              y: [interval?.min || 0, interval?.max || 0],
            }))}
            style={{
              min: { stroke: 'white' },
              max: { stroke: 'white' },
              q1: { fill: 'white' },
              q3: { fill: 'white' },
              median: { stroke: 'white', strokeWidth: 2 },
              minLabels: { fill: 'white' },
              maxLabels: { fill: 'white' },
            }}
          />
          )}
        <VictoryScatter
          size={8}
          labelComponent={chartTooltip}
          data={data.map((currData) => ({
            ...currData,
            label: [
              currData.label,
              `${xAxis.label || ''}: ${formatAxis(currData.x, undefined, xAxis.currency)}`,
              `${yAxis.label || 'Value'}: ${currData.yFormattedValue || formatAxis(currData.y, undefined, yAxis.currency)}`,
            ],
          }))}
          style={axisStyle}
          events={[{
            target: 'data',
            eventHandlers: {
              onClick: (_, childProps: { datum: ScatterChartData }) => {
                const datum = childProps?.datum;
                if (onClick !== undefined && datum) {
                  onClick(datum);
                }
              },
            },
          }]}
        />
      </VictoryChart>
    </Box>
  );
};
