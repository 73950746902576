export enum ProgressStatus {
  initial = 'initial',
  initializing = 'initializing...',
  spinningChain = 'spinning chain',
  deployingContracts = 'deploying contracts',
  contractsDeployed = 'contracts deployed',
  simulationStarted = 'simulation started',
  calculatePermutations = 'calculate permutations',
  runConfigSimulations = 'run config simulations',
  runSetupScripts = 'run setup scripts',
  runRuntimeScripts = 'run runtime scripts',
  simulationError = 'simulation error',
  simulationCancelled = 'simulation cancelled',
  simulationFinished = 'simulation finished',
}

export interface ProgressBarState {
  status: ProgressStatus
}

const initialState: ProgressBarState = {
  status: ProgressStatus.initial,
};

interface Action {
  type: string
  payload: ProgressStatus
}

const actionTypes = {
  SET_STATUS: 'progressBar/SET_STATUS',
};

export const setStatusAction = (
  status: ProgressStatus,
): { type: string; payload: string } => ({
  type: actionTypes.SET_STATUS,
  payload: status,
});

export function progressBarReducer(
  // eslint-disable-next-line
  state = initialState,
  action: Action,
): ProgressBarState {
  switch (action.type) {
    case actionTypes.SET_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    default:
      return state;
  }
}
