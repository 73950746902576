export const TEAM_SWITCHER_STYLES = {
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  columnGap: 3,
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  height: 32,
  borderColor: 'rgba(255, 255, 255, 0.3)',
  '._nav-collapsed &': {
    minWidth: 32,
    borderRadius: '16px',
    background: '#17191E',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    justifyContent: 'center',
    position: 'relative',
    left: -4,
    '& p': {
      fontSize: '14px',
      textAlign: 'center',
    },
  },
  '&:after': {
    position: 'absolute',
    content: '""',
    opacity: 0,
    width: 0,
    height: 4,
    borderRadius: 2,
    background: '#FFF',
    right: -14,
    top: 14,
    transition: 'width 0.3s ease, opacity 0.3s ease',
    zIndex: 3,
    '._nav-collapsed &': {
      opacity: 1,
      width: 4,
    },
  },
} as const;

export const getTeamMenuStyles = (open: boolean) => ({
  '& .MuiPaper-root': {
    overflow: 'visible',
    boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.16)',
    background: '#17191E',
    minWidth: open ? 200 : 100,
    '& .MuiList-root': {
      py: 0,
    },
  },

  '& ul::before': {
    content: "''",
    position: 'absolute',
    width: 8,
    height: 8,
    top: open ? -4 : 28,
    left: open ? 104 : -4,
    background: '#17191E',
    transform: 'rotate(-45deg)',
  },
} as const);
