import { ModalWrapper } from '@chaos/ui/modals';
import { ModelRunResult } from '../../../../data/types';
import PromoteForm from './promote-form';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  modelResults: ModelRunResult[];
};
const PromoteModal = ({ isOpen, setIsOpen, modelResults }: Props) => (
  <ModalWrapper
    title="Promote to Prod"
    open={isOpen}
    onClose={() => setIsOpen(false)}
    maxWidth={800}
  >
    <PromoteForm modelResults={modelResults} />
  </ModalWrapper>
);

export default PromoteModal;
