import { Box } from '@chaos/ui/box';
import { Paper } from '@chaos/ui/paper';
import { Typography } from '@chaos/ui/typography';
import { useTickerSvg } from 'src/hooks';

import { Tooltip } from '@chaos/ui/tooltip';
import { TokenTickerEnum } from '@chaos/types';

interface TokenPairCardProps {
  ticker1: TokenTickerEnum
  ticker2: TokenTickerEnum
  onTokenClick: () => void
  isSelected: boolean
}

export const TokenPairCard = ({
  onTokenClick,
  ticker1,
  ticker2,
  isSelected,
}: TokenPairCardProps): JSX.Element => {
  const img1 = useTickerSvg(ticker1);
  const img2 = useTickerSvg(ticker2);
  const title = `${ticker1} / ${ticker2}`;
  return (
    <Tooltip
      classes={{
        tooltip: 'light',
        arrow: 'light',
      }}
      arrow
      disableFocusListener
      disableHoverListener
      disableTouchListener
      open={isSelected}
      placement="top"
      title={`${title} selected`}
      sx={{ zIndex: 0 }}
    >
      <Box
        className={isSelected ? '_selected' : undefined}
        sx={{
          p: '1px',
          borderRadius: 4,
          cursor: 'pointer',
          ':hover, &._selected': {
            background:
              'radial-gradient(146.67% 222.17% at 75.81% 92.5%, rgba(17,123,232,0) 0%, #2CC8C7 100%)',
          },
        }}
      >
        <Paper
          variant="card"
          onClick={onTokenClick}
          sx={{
            p: 2,
            borderRadius: 'inherit',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
            <Box
              component="img"
              src={img1}
              alt="token image"
              sx={{ width: 32, height: 32 }}
            />
            <Box
              component="img"
              src={img2}
              alt="token image"
              sx={{ ml: 1, width: 32, height: 32 }}
            />
          </Box>
          <Typography component="p" textAlign="center" noWrap title={title}>
            {title}
          </Typography>
        </Paper>
      </Box>
    </Tooltip>
  );
};
