import { FC, useState } from 'react';
import { Box } from '@chaos/ui/box';
import { Typography } from '@chaos/ui/typography';
import { wrappedSymbolToIconSymbol } from 'src/utils/icon-helper';
import { CustomReactSelect } from '@chaos/ui/custom-select';
import { CollapsibleDescription, TreemapChart } from '.';

export type TokenTreeMapsProps = {
  title?: string;
  description?: string;
  data: Record<string, {
    name: string,
    value: number,
  }[]>
};

export const TokenTreeMaps: FC<TokenTreeMapsProps> = ({ title, description, data }) => {
  const options = Object.keys(data).map((val) => ({
    label: val,
    value: val,
    icon: wrappedSymbolToIconSymbol(val),
  }));
  const [token, setToken] = useState(options[0]);

  return (
    <Box>
      <Box display="flex" alignItems="center" mb={3}>
        {(title || description) && (
          <Box flex={1} position="relative">
            <Typography variant="h2">{title}</Typography>
            {description && <CollapsibleDescription text={description} />}
          </Box>
        )}
        <Box ml="auto">
          <CustomReactSelect
            value={token}
            options={options}
            onChange={(curr) => curr && setToken({
              ...curr,
              icon: wrappedSymbolToIconSymbol(curr.value),
            })}
            isSearchable={false}
          />
        </Box>
      </Box>
      <TreemapChart
        data={data[token.value]}
        onClick={(id) => {
          if (id !== 'Others') {
            window.open(`https://snowtrace.io/address/${id}`);
          }
        }}
      />
    </Box>
  );
};
