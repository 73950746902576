import { FC } from 'react';
import { Tab, Tabs } from '@mui/material';
import { Box } from '@chaos/ui/box';

interface TableFilterTabProps {
  label: string,
  isApplied: boolean,
}

interface TableFilterTabsProps {
  tabs?: TableFilterTabProps[]
  value: number
  onChange?: (e: React.SyntheticEvent, value: number) => void
}

export const TableFilterTabs: FC<TableFilterTabsProps> = ({
  tabs, value, onChange,
}) => (
  <Tabs
    value={value}
    aria-label="tabs"
    orientation="vertical"
    onChange={onChange}
    sx={{
      p: 2,
      '& .MuiTabs-flexContainer': {
        gap: 1,
      },
      '& .MuiTabs-indicator': {
        display: 'none',
      },
      button: {
        alignItems: 'flex-start',
        borderRadius: 4,
        textTransform: 'initial',
        color: 'white',
        p: '8px 16px',
        fontWeight: 500,
        textAlign: 'left',
        '&.Mui-selected': {
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          span: {
            background: 'linear-gradient(154.16deg, #31d8bf -3.9%, #0965f1 126.56%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          },
        },
        ':hover': {
          span: {
            background: 'linear-gradient(154.16deg, #31d8bf -3.9%, #0965f1 126.56%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          },
        },
        width: 200,
      },
      ...(onChange ? {} : { pointerEvents: 'none', userSelect: 'none' }),
    }}
  >
    {tabs?.length && tabs.map((tab) => (
      <Tab
        key={tab.label}
        label={(
          <Box display="flex" flexDirection="row" alignItems="center" width="100%">
            <Box flex="1" component="span">{tab.label}</Box>
            {tab.isApplied && (
              <Box
                flex="0 0 8px"
                ml="auto"
                height={8}
                width={8}
                borderRadius={4}
                sx={{ background: 'linear-gradient(154.16deg, #31D8BF -3.9%, #0965F1 126.56%)' }}
              />
            )}
          </Box>
        )}
      />
    ))}
  </Tabs>
);
