import { FC } from 'react';
import { Box } from '@chaos/ui/box';
import { Typography } from '@chaos/ui/typography';
import EmptyImg from 'src/assets/img/empty-page.png';
import { ReactComponent as EmptyAgentsIcon } from 'src/assets/img/icons/empty-agents.svg';
import { ReactComponent as EmptyScenariosIcon } from 'src/assets/img/icons/empty-scenarios.svg';
import { ReactComponent as EmptySimulationsIcon } from 'src/assets/img/icons/empty-simulations.svg';
import { ReactComponent as EmptyAssertionsIcon } from 'src/assets/img/icons/empty-assertions.svg';
import { ReactComponent as EmptyContractDeploymentsIcon } from 'src/assets/img/icons/empty-contract-deployments.svg';
import { ReactComponent as EmptyAssetsIcon } from 'src/assets/img/icons/empty-assets.svg';
import { ReactComponent as EmptyVisualIcon } from 'src/assets/img/icons/empty-visual.svg';
import { ReactComponent as EmptyResultsIcon } from 'src/assets/img/icons/empty-results.svg';

type EmptyStateType = 'agents'
| 'simulations'
| 'scenarios'
| 'assertions'
| 'contract deployments'
| 'assets'
| 'wallets'
| 'visual data'
| 'simulation results'
| 'observers';

const iconConfig: { [key in EmptyStateType]: JSX.Element } = {
  agents: <EmptyAgentsIcon />,
  scenarios: <EmptyScenariosIcon />,
  simulations: <EmptySimulationsIcon />,
  assertions: <EmptyAssertionsIcon />,
  observers: <EmptyScenariosIcon />,
  'contract deployments': <EmptyContractDeploymentsIcon />,
  assets: <EmptyAssetsIcon />,
  'visual data': <EmptyVisualIcon />,
  'simulation results': <EmptyResultsIcon />,
  wallets: <EmptyAssetsIcon />,
};

const wordCustomConfig: { [key in EmptyStateType]?: string } = {
  'visual data': '',
  'simulation results': 'to display',
  assets: 'to display',
  wallets: 'to display',
};

interface Props {
  type: EmptyStateType;
}

export const EmptyState: FC<Props> = ({ type }) => (

  <Box sx={{
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: { xs: 'flex-start', md: 'center' },
    mt: { xs: 2, md: 0 },
    alignItems: 'center',
    flexDirection: 'column',
    rowGap: 3,
  }}
  >
    <Box sx={{
      minWidth: { xs: 344, md: 448 },
      minHeight: { xs: 245, md: 320 },
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    >
      <Box
        component="img"
        src={EmptyImg}
        alt="empty image"
        sx={{
          maxWidth: { xs: 344, md: 'initial' },
          position: 'absolute',
          zIndex: -1,
        }}
      />
      {iconConfig[type]}
    </Box>
    <Typography>
      There are no
      &nbsp;
      {type}
      &nbsp;
      {wordCustomConfig[type] ?? 'created'}
      &nbsp;
      yet.
    </Typography>
  </Box>
);
