import dayjs from 'dayjs';
import { palette } from '@chaos/ui/theme/palette';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header, RenderData } from '@chaos/types';
import { Box, Button, ChaosTable } from '@chaos/ui';
import { useEconnomicRecommendationTriggers } from 'src/hooks/useEconnomicRecommendationTriggers';
import { RouteParams, RoutePath } from 'src/config/routes';

const DATA_PAGE_SIZE = 30;
const TABLE_PAGE_SIZE = 15;

const headers: Header[] = [{
  renderType: 'TEXT',
  text: 'Protocol',
  nonSortable: true,
}, {
  renderType: 'TEXT',
  text: 'Chain',
  nonSortable: true,
}, {
  renderType: 'TEXT',
  text: 'Assets',
  nonSortable: true,
}, {
  renderType: 'TEXT',
  text: 'Model',
  nonSortable: true,
}, {
  renderType: 'TEXT',
  text: 'Status',
  nonSortable: true,
}, {
  renderType: 'TEXT',
  text: 'Start Time',
  nonSortable: true,
}];

export const ParamRecommendationsPage = () => {
  const navigate = useNavigate();
  const [lastId, setLastId] = useState<string>();
  const {
    econnomicRecommendationTriggers,
    isLoading,
  } = useEconnomicRecommendationTriggers(lastId, DATA_PAGE_SIZE);
  const data: RenderData[][] = useMemo(() => econnomicRecommendationTriggers.map((t) => [{
    renderType: 'TEXT',
    text: t.protocol,
    icon: t.protocol.toLowerCase(),
  }, {
    renderType: 'TEXT',
    text: t.chain_name,
    icon: t.chain_name.toLowerCase(),
  }, {
    renderType: 'ICONS',
    text: '',
    icons: t.assets,
  }, {
    renderType: 'TEXT',
    text: t.models.join(', '),
  }, {
    renderType: 'CHIP',
    text: t.status === 'simulation finished' ? 'Ready' : 'In Progress',
    chipColor: t.status === 'simulation finished' ? palette.blue.opacity50 : palette.orange.opacity50,
  }, {
    renderType: 'TEXT',
    text: dayjs(t.created).format('DD MMM HH:mm'),
  }]), [econnomicRecommendationTriggers]);

  return (
    <Box display="flex" flexDirection="column" p={3}>
      <Button color="primary" sx={{ ml: 'auto', mb: 2 }} onClick={() => navigate(RoutePath.ParamRecommendations.Trigger)}>New Recommendation</Button>
      <ChaosTable
        headers={headers}
        pageSize={TABLE_PAGE_SIZE}
        data={data}
        rowHref={(i) => RoutePath.ParamRecommendations.Details
          .replace(RouteParams.Id, econnomicRecommendationTriggers[i].id)}
        onPageChange={(n) => {
          if ((n * TABLE_PAGE_SIZE) >= econnomicRecommendationTriggers.length) {
            setLastId(
              econnomicRecommendationTriggers[econnomicRecommendationTriggers.length - 1].id,
            );
          }
        }}
        isLoading={isLoading}
      />
    </Box>
  );
};
