import { FC } from 'react';
import { Box, Typography } from '@chaos/ui';
import { useAppSelector } from 'src/store';
import { useFallbackUserAvatar } from 'src/hooks';
import { RoutePath } from 'src/config/routes';
import {
  HIDDEN_COLLAPSED_STYLES,
} from 'src/components/app-nav/styles';
import { Link } from 'react-router-dom';
import { PROFILE_SECTION_STYLES } from './styles';

export const ProfileSection: FC = () => {
  const defaultUserAvatar = useFallbackUserAvatar();
  const profile = useAppSelector((state) => state.firebase.profile);

  return (
    <Link
      data-test-id="profile-link"
      to={RoutePath.Profile.Home}
      style={{
        color: 'inherit',
        textDecoration: 'inherit',
      }}
    >
      <Box
        className="logged-avatar-box"
        sx={PROFILE_SECTION_STYLES}
      >
        <Box
          component="img"
          alt="user avatar"
          src={profile?.photo?.url || defaultUserAvatar}
          className="profile-img"
        />
        <Typography sx={HIDDEN_COLLAPSED_STYLES}>{`${profile.firstName} ${profile.lastName}`}</Typography>
      </Box>
    </Link>
  );
};
