import {
  useMemo, useEffect, useState, useRef,
} from 'react';

const AVATARS_COUNT = 59;

const stringToIndex = (s: string, n: number) => s
  .split('')
  .reduce((res, c) => res + c.charCodeAt(0), 0) % n;

export const importRandomAvatar = async (avatarIndex: number) => new Promise<{ default: string }>(
  (resolve, reject) => {
    import(`../assets/img/agents/${avatarIndex}.png`)
      .then((data: { default: string }) => resolve(data))
      .catch((error) => reject(error));
  },
);

export const getRandomAvatar = async (id?: string) => {
  const randomIndex = Math.floor(Math.random() * AVATARS_COUNT);
  const avatarIndex = (id ? stringToIndex(id, AVATARS_COUNT) : randomIndex) + 1;
  const data = await importRandomAvatar(avatarIndex);
  return data.default;
};

export const useRandomAvatar = (id?: string): { randomAvatar: string } => {
  const isMounted = useRef(false);
  const [randomAvatar, setRandomAvatar] = useState<string>('');

  const randomIndex = useMemo(() => Math.floor(Math.random() * AVATARS_COUNT), []);

  const avatarIndex = useMemo(
    () => (id ? stringToIndex(id, AVATARS_COUNT) : randomIndex) + 1,
    [id, randomIndex],
  );

  useEffect(() => {
    void (async () => {
      try {
        const data = await importRandomAvatar(avatarIndex);
        setRandomAvatar((state) => (!isMounted.current ? data.default : state));
      } catch (error) {
        console.error(`Failed to load avatar with number ${avatarIndex}`, error);
      }
    })();

    return () => {
      isMounted.current = true;
    };
  }, [avatarIndex]);

  return { randomAvatar };
};
