import { FC } from 'react';
import { SxProps } from '@mui/material';
import { Box } from '@chaos/ui/box';
import { Grid } from '@chaos/ui/grid';
import { useRandomAvatar } from 'src/hooks';
import { Typography } from '@chaos/ui/typography';
import type {
  GridItemSize, SelectorAlign, SelectorItem, SelectorLayout,
} from './selector';
import { CustomIcon } from '../custom-icon';

interface SelectorGridItemProps {
  item: SelectorItem
  onItemClick: (item: SelectorItem) => void
  gridItemSize?: GridItemSize
  layout?: SelectorLayout
  align?: SelectorAlign
  withImage?: boolean
  isDisabled: number | boolean
  isSelected: boolean,
  imageSx?: SxProps,
}

export const SelectorGridItem: FC<SelectorGridItemProps> = ({
  item,
  gridItemSize,
  isDisabled,
  isSelected,
  onItemClick,
  align,
  layout,
  withImage,
  imageSx,
}) => {
  const { randomAvatar } = useRandomAvatar(item.id);
  return (
    <Grid item {...gridItemSize}>
      <Box
        className={`${isDisabled ? '_disabled' : ''} ${
          isSelected ? '_selected' : ''
        }`}
        onClick={() => {
          onItemClick(item);
        }}
        sx={{
          position: 'relative',
          borderRadius: 4,
          display: 'block',
          cursor: 'pointer',
          '&:not(._disabled):hover, &._selected': {
            border: '1px solid #2CC8C7',
            '.selectable-item-content': {
              padding: '15px',
            },
          },
        }}
      >
        <Box
          sx={{
            borderRadius: 4,
            padding: 2,
            backgroundColor: isSelected ? '#22252C' : '#45494F',
          }}
          className="selectable-item-content"
        >
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              alignItems: align,
              flexDirection: layout === 'columns' ? 'column' : 'row',
              mb: item.description !== undefined ? 2 : 0,
              '.grid-icon path': { fill: '#fff' },
            }}
          >
            {withImage && (
              <Box
                component="img"
                src={item.imgSrc || randomAvatar}
                sx={{
                  objectFit: 'contain',
                  height: 48,
                  width: 48,
                  mr: 2,
                  ...imageSx,
                }}
              />
            )}
            <Box flexDirection="column" overflow="hidden">
              <Box
                sx={{
                  minWidth: 0, flex: 1, flexDirection: 'row', display: 'flex',
                }}
                justifyContent={align}
              >
                {item.icon
            && <Box sx={{ marginRight: 1 }}><CustomIcon icon={item.icon} /></Box>}
                <Typography noWrap>{item.name}</Typography>
              </Box>
              <Box minWidth={0} display="flex">
                <Typography variant="caption" sx={{ fontWeight: 500 }} noWrap>
                  {item.caption}
                </Typography>
              </Box>

              {item.description !== undefined && (
              <Box minWidth={0} display="flex">
                <Typography
                  variant="caption"
                  component="p"
                  sx={{
                    fontWeight: 500,
                    maxWidth: '100%',
                  }}
                  noWrap
                >
                  {item.description}
                </Typography>
              </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};
