import {
  ChangeEventHandler, FC, MouseEvent, useCallback, useState,
} from 'react';
import { useDropzone, DropEvent, FileRejection } from 'react-dropzone';
import { FieldMetaProps } from 'formik';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

import { CircularProgress } from '@mui/material';
import { Box } from '@chaos/ui/box';
import { IconButton } from '@chaos/ui/icon-button';
import { CustomIcon } from 'src/components/custom-icon';
import { FileValue } from '@chaos/types';
import { formatBytes } from 'src/utils/formatters';

type TypeOnDropAccepted = (f: File[], e: DropEvent) => void;
type TypeOnDropRejected = (f: FileRejection[], e: DropEvent) => void;
export type TypePropsOnDropFile = (f: File[], e: DropEvent) => Promise<FileValue | void>;
export type TypePropsOnDeleteFile = (f: FileValue) => Promise<void>;

export interface ImgUploadProps {
  accept?: string
  disabled?: boolean
  maxSize?: number
  value?: FileValue | null
  onChange?: (value?: FileValue) => void
  meta?: FieldMetaProps<FileValue>
  onDropFile: TypePropsOnDropFile
  onDeleteFile?: TypePropsOnDeleteFile,
  fallbackPreviewPath?: string
  isInitialHoverDisabled?: boolean
  isDark?: boolean
}

export const FileUpload: FC<ImgUploadProps> = ({
  accept = 'image/png, image/jpeg',
  disabled = false,
  maxSize = 10485760,
  value,
  onChange,
  onDropFile,
  fallbackPreviewPath,
  onDeleteFile,
  isInitialHoverDisabled,
  isDark,
}) => {
  const [file, setFile] = useState<FileValue | undefined>(value || undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);

  const onDropRejected = useCallback<TypeOnDropRejected>((
    fileRejections,
  ) => {
    setErrors(() => {
      const state: string[] = [];
      fileRejections.forEach((fileObj) => {
        fileObj.errors.forEach((error) => {
          if (error.code === 'file-too-large') {
            state.push(`File is larger than ${formatBytes(maxSize)}`);
          } else {
            state.push(error.message);
          }
        });
      });
      return state;
    });
  }, [maxSize]);

  const onSuccess = useCallback((result: FileValue | void) => {
    setFile(() => result || undefined);
    if (onChange) {
      onChange(result || undefined);
    }
  }, [onChange]);

  const onReject = useCallback((error: { message: string }) => {
    setErrors(() => [error.message]);
  }, []);

  const onFinal = useCallback(() => setIsLoading(false), []);

  const dropFileHandler = useCallback<TypeOnDropAccepted>((acceptedFiles, event) => {
    setErrors(() => []);
    setIsLoading(() => true);

    onDropFile(acceptedFiles, event)
      .then(onSuccess)
      .catch(onReject)
      .finally(onFinal);
  }, [onDropFile, onSuccess, onFinal, onReject]);

  const deleteFileHandler = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (file && onDeleteFile) {
      setIsLoading(true);
      onDeleteFile(file)
        .then(onSuccess)
        .catch(onReject)
        .finally(onFinal);
    }
  }, [file, onDeleteFile, onSuccess, onFinal, onReject]);

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    disabled,
    maxSize,
    onDropAccepted: dropFileHandler,
    onDropRejected,
  });

  return (
    <Box
      {...getRootProps()}
      sx={{
        position: 'relative',
        mr: { xs: 0, md: 5 },
        maxWidth: 344,
        borderRadius: '50%',
        userSelect: 'none',
        '& .action-buttons': { opacity: 0, transition: '.3s ease' },
        '&:hover .action-buttons': { opacity: 1 },
        '& .img-upload::before': {
          display: isInitialHoverDisabled && !file ? 'none' : 'block',
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          content: '""',
          borderRadius: 'inherit',
          background: 'rgba(255,255,255,.1)',
          transition: '.3s ease',
          opacity: 0,
        },
        '&:hover .img-upload::before': {
          opacity: 0.75,
        },
      }}
    >
      <FormControl error={errors.length > 0} sx={{ borderRadius: '50%' }}>
        <input {...getInputProps()} />
        <input
          style={{ display: 'none' }}
          value={(value as unknown as string) || undefined}
          onChange={onChange as unknown as ChangeEventHandler<HTMLInputElement>}
        />
        <Box
          className="img-upload"
          sx={{
            position: 'relative',
            borderRadius: '50%',
            backgroundColor: isDark ? '#17191E' : '#9B9DA1',
            width: { xs: 200, sm: 280, md: 344 },
            height: { xs: 200, sm: 280, md: 344 },
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: disabled ? 'not-allowed' : 'pointer',
          }}
        >
          {isLoading && <CircularProgress />}
          <img
            src={(file && file.url) || fallbackPreviewPath}
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'contain',
              display: isLoading ? 'none' : 'block',
            }}
            alt="user avatar"
            onLoad={() => setIsLoading(false)}
            onError={() => {
              setIsLoading(false);
              setFile(undefined);
            }}
          />
        </Box>

        <Box
          className="action-buttons"
          sx={{
            display: 'flex',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            gap: 2,
          }}
        >
          {(!isInitialHoverDisabled || file) && (
          <Box
            sx={{
              backgroundColor: '#17191E',
              borderRadius: '12px',
            }}
          >
            <IconButton
              sx={{ borderRadius: '12px' }}
              disabled={isLoading}
              size="large"
            >
              <CustomIcon icon="image-upload" />
            </IconButton>
          </Box>
          )}

          {(file && file.url) && (
          <Box sx={{ backgroundColor: '#17191E', borderRadius: '12px' }}>
            <IconButton
              sx={{ borderRadius: '12px' }}
              disabled={isLoading}
              onClick={deleteFileHandler}
              size="large"
            >
              <CustomIcon icon="trash-can" />
            </IconButton>
          </Box>
          )}
        </Box>

        {errors.map((error) => (
          <FormHelperText key={`${error}`}>{error}</FormHelperText>
        ))}
      </FormControl>
    </Box>
  );
};
