import { FC } from 'react';

import { Box } from '@chaos/ui/box';
import { Grid } from '@chaos/ui/grid';
import { Paper } from '@chaos/ui/paper';
import { Tooltip } from '@chaos/ui/tooltip';
import { Typography } from '@chaos/ui/typography';

import constant from 'src/assets/img/functions/constant.png';
import descending from 'src/assets/img/functions/descending.png';
import ascending from 'src/assets/img/functions/ascending.png';
import volatile from 'src/assets/img/functions/volatile.png';

const mockFunctions = [
  {
    name: 'Constant',
    img: constant,
    value: 'constant',
    equation: null,
  },
  {
    name: 'Descending',
    img: descending,
    value: 'descending',
    equation: 'y = 200 - 0.3x',
  },
  {
    name: 'Ascending',
    img: ascending,
    value: 'ascending',
    equation: 'y = 20 + 0.3x',
  },
  {
    name: 'Volatile',
    img: volatile,
    value: 'volatile',
    equation: 'y = 50 + 0.1x * sin(0.2x)',
  },
];

interface SelectScenarioProps {
  functionValue: string | undefined
  onSelectFunction: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void
}

export const SelectFunction: FC<SelectScenarioProps> = ({
  functionValue,
  onSelectFunction,
}): JSX.Element => (
  <Grid container columnSpacing={5} rowSpacing={5}>
    {mockFunctions.map(({
      name, img, value, equation,
    }) => {
      const isDisabled = functionValue !== undefined && functionValue !== value;
      const isSelected = functionValue === value;
      return (
        <Grid item zeroMinWidth key={value} sm={12} md={12} xl={6}>
          <Tooltip
            classes={{
              tooltip: 'light',
              arrow: 'light',
              popper: 'hidden',
            }}
            arrow
            disableFocusListener
            disableHoverListener
            disableTouchListener
            open={isSelected}
            title={`${name} function selected`}
            placement="top"
          >
            <Box
              className={`${isDisabled ? '_disabled' : ''} ${
                isSelected ? '_selected' : ''
              }`}
              onClick={() => {
                if (isDisabled) {
                  return;
                }
                onSelectFunction(
                  'priceFunction',
                  functionValue === value ? undefined : value,
                );
              }}
              sx={{
                position: 'relative',
                borderRadius: 2,
                display: 'block',
                transition: 'opacity 0.3s ease',
                cursor: 'pointer',
                '&:not(._disabled):hover, &._selected': {
                  padding: '1px',
                  background:
                      'radial-gradient(146.67% 222.17% at 75.81% 92.5%, rgba(17,123,232,0) 0%, #2CC8C7 100%)',
                  '.selectable-item-content': {
                    padding: '23px',
                  },
                },
                '&._disabled': {
                  opacity: 0.3,
                  cursor: 'default',
                },
              }}
            >
              <Paper
                variant="card"
                className="selectable-item-content"
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box
                    component="img"
                    src={img}
                    alt="function image."
                    sx={{ height: 120, width: '100%' }}
                  />
                  <Box sx={{ mt: 3 }}>
                    <Typography noWrap sx={{ mb: 1 }}>
                      {name}
                    </Typography>
                    <Typography variant="caption">{equation ?? '-'}</Typography>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Tooltip>
        </Grid>
      );
    })}
  </Grid>
);
