import { FC } from 'react';
import { Box, Paper, Typography } from '@chaos/ui';
import { IconButton } from '@chaos/ui/icon-button';
import { ScriptResourceGroupInput } from '@chaos/types';
import { useRandomAvatar } from 'src/hooks';
import { CustomIcon } from 'src/components/custom-icon';
import { CustomCheckbox } from '../custom-checkbox';

interface BlockchainSimulationConfigurationItemProps {
  id?: string
  name?: string
  configName?: string
  instances?: number
  selectedConfigItems?: string[];
  assosiatedGroups?: ScriptResourceGroupInput[];
  groupsExist?: boolean;
  setSelectedConfigItems?: (itemsIds: string[]) => void;
  onClick?: () => void
  onRemove?: () => void
}
export const BlockchainSimulationConfigItem: FC<BlockchainSimulationConfigurationItemProps> = (
  {
    id,
    name,
    instances,
    configName,
    selectedConfigItems,
    assosiatedGroups,
    groupsExist,
    setSelectedConfigItems,
    onClick,
    onRemove,
  },
) => {
  const { randomAvatar } = useRandomAvatar(id);
  const tabBasis = instances !== undefined ? '30%' : '45%';
  const handleCheckboxChange = () => {
    const checked = id && selectedConfigItems?.includes(id);
    const itemIdIdx = selectedConfigItems?.findIndex((itemId) => itemId === id) || -1;
    if (itemIdIdx < 0 && !checked && id) {
      setSelectedConfigItems?.([...(selectedConfigItems || []), id]);
    } else {
      setSelectedConfigItems?.(selectedConfigItems?.filter((itemId) => itemId !== id) || []);
    }
  };

  return (
    <Paper
      variant="card"
      sx={{
        height: '80px',
      }}
    >
      <Box display="flex" columnGap="20px">
        {setSelectedConfigItems && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CustomCheckbox
              checked={id ? selectedConfigItems?.includes(id) : false}
              onChange={handleCheckboxChange}
            />
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            columnGap: '16px',
            alignItems: 'center',
            flexBasis: tabBasis,
          }}
        >
          <Box
            component="img"
            src={randomAvatar}
            alt={name || ''}
            sx={{
              height: 48,
              width: 48,
              borderRadius: '4px',
            }}
          />
          <Typography fontWeight="bold">{configName || '-'}</Typography>
        </Box>

        <Box flexBasis={tabBasis}>
          <Typography variant="caption">Configuration Name</Typography>
          <Typography
            variant="h4"
            sx={{ fontSize: { xs: '1rem', md: 'initial' } }}
          >
            {name || '-'}
          </Typography>
        </Box>
        {groupsExist && (
        <Box
          flexBasis={tabBasis}
        >
          <Typography variant="caption">Groups</Typography>
          <Typography
            variant="h4"
            sx={{ fontSize: { xs: '1rem', md: 'initial' } }}
          >
            {assosiatedGroups?.map((group) => group.groupName).join(', ') || '-'}
          </Typography>
        </Box>
        )}
        {instances !== undefined && (
        <Box
          flexBasis={tabBasis}
        >
          <Typography variant="caption">Instances</Typography>
          <Typography
            variant="h4"
            sx={{ fontSize: { xs: '1rem', md: 'initial' } }}
          >
            {instances}
            &nbsp;
          </Typography>
        </Box>
        )}
        <Box sx={{
          display: 'flex',
          flexBasis: '10%',
          justifyContent: 'end',
          columnGap: '16px',
          alignItems: 'center',
        }}
        >
          <IconButton onClick={onRemove}>
            <CustomIcon icon="trash-can" sx={{ '& path': { fill: '#DE4469' } }} />
          </IconButton>
          {onClick && (
          <IconButton onClick={onClick}>
            <CustomIcon icon="breadcrumbs-arrow" sx={{ '& path': { fill: 'white' } }} />
          </IconButton>
          )}
        </Box>
      </Box>
    </Paper>
  );
};
