import { FC } from 'react';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import { Box } from '@chaos/ui/box';
import { Paper } from '@chaos/ui/paper';
import { Button } from '@chaos/ui/button';
import { Typography } from '@chaos/ui/typography';
import { useFallbackUserAvatar } from 'src/hooks';
import { CustomIcon } from 'src/components/custom-icon';
import type { NotificationsData } from './notifications-page';

dayjs.extend(isToday);
dayjs.extend(isYesterday);

interface NotificationProps {
  data: NotificationsData
}

export const Notification: FC<NotificationProps> = ({ data }) => {
  const defaultUserAvatar = useFallbackUserAvatar();
  const { type, access, simulation } = data;
  const isAccess = type === 'access' && access;
  const isSimulation = type === 'simulation' && simulation;

  const formatDate = (date?: Date | string): string => {
    if (!date) return '';
    const dayjsDate = dayjs(date);
    const today = dayjsDate.isToday() && 'Today';
    const yesterday = dayjsDate.isYesterday() && 'Yesterday';
    return `${today || yesterday || dayjsDate.format('YYYY/MM/DD')} at ${dayjsDate.format('h:mm:ss a')}`;
  };

  return (
    <Paper
      variant="card"
      sx={{
        display: 'flex', alignItems: 'center', gap: 2, flexWrap: 'wrap',
      }}
    >
      <Box display="flex" alignItems="center" gap={2}>
        {isSimulation && (
        <CustomIcon icon="airplay-gradient-shadow" sx={{ mx: -1.5 }} />
        )}

        {isAccess && (
        <Box
          component="img"
          src={access.photoUrl || defaultUserAvatar}
          alt="User Avatar"
          borderRadius="50%"
          width={48}
          height={48}
        />
        )}

        <Box display="flex" flexDirection="column" gap={1}>
          <Typography>
            {isAccess && `${access.firstName} ${access.lastName} wants to join your community.`}
            {isSimulation && `${simulation.name} some important text.`}
          </Typography>

          <Typography variant="caption">
            {isAccess && formatDate(access.date)}
            {isSimulation && formatDate(simulation.date)}
          </Typography>
        </Box>
      </Box>

      {isAccess && (
      <Box
        display="flex"
        gap={3}
        sx={{
          width: { xs: '100%', md: 328 },
          ml: { xs: 0, md: 'auto' },
        }}
      >
        <Button
          fullWidth
          color="secondary"
          onClick={() => {}}
        >
          Decline
        </Button>
        <Button
          fullWidth
          color="primary"
          onClick={() => {}}
        >
          Accept
        </Button>
      </Box>
      )}
    </Paper>
  );
};
