import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BlockchainSimulation } from '@chaos/types';
import { CircularProgress } from '@chaos/ui/circular-progress';
import { EmptyState } from 'src/components/empty-state';
import { PageTracker } from 'src/components/page-tracker';
import { useAppSelector } from 'src/store';
import {
  useBlockchainSimulations, usePageTitle,
} from 'src/hooks';
import { RoutePath } from 'src/config/routes';
import { SimulationsV2Table } from 'src/components/simulations-table';
import { MainLayout } from '../../components/layouts';

const PAGES_TO_LOAD = 5;
const PAGE_SIZE = 20;

export const SimulationsPageV2 = PageTracker((): JSX.Element => {
  usePageTitle('Simulations');
  const [last, setLast] = useState<BlockchainSimulation>();
  const [sortBy, setSortBy] = useState<keyof BlockchainSimulation>('createdAt');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [idFilter, setIdFilter] = useState<string>();
  const [createdAtFilter, setCreatedAtFilter] = useState<[number, number]>();
  const [nameFilter, setNameFilter] = useState<string>();
  const { blockchainSimulations, isLoading } = useBlockchainSimulations(
    nameFilter ? 'lowercaseName' : sortBy,
    sortDirection,
    last,
    PAGES_TO_LOAD * PAGE_SIZE,
    idFilter,
    createdAtFilter,
    nameFilter,
  );
  const navigate = useNavigate();
  const isAdmin = useAppSelector((state) => state.firebase.profile.role === 'admin');
  const onPageChange = useCallback((page: number) => {
    if (page % PAGES_TO_LOAD === 0 && page * PAGE_SIZE === blockchainSimulations.length) {
      setLast(blockchainSimulations[blockchainSimulations.length - 1]);
    }
  }, [blockchainSimulations]);
  const onSortChange = useCallback((by: string, dir: -1 | 1) => {
    setLast(undefined);
    setSortBy(by as keyof BlockchainSimulation);
    setSortDirection(dir === -1 ? 'desc' : 'asc');
  }, []);
  const onFilter = useCallback(
    (newIdFilter?: string, newCreatedAtFilter?: [number, number], newNameFilter?: string) => {
      setLast(undefined);
      setIdFilter(newIdFilter);
      setCreatedAtFilter(newCreatedAtFilter);
      setNameFilter(newNameFilter);
    },
    [],
  );
  const isFiltered = idFilter !== undefined
    || createdAtFilter !== undefined || nameFilter !== undefined;

  return (
    <MainLayout
      headerProps={{
        pageTitle: 'Simulations',
        buttons: isAdmin ? [{
          children: 'Create Simulation',
          onClick: () => navigate(RoutePath.Simulations.New),
        }] : undefined,
      }}
      height={0}
    >
      {isLoading && !blockchainSimulations.length && !isFiltered && <CircularProgress />}
      {!isLoading && !blockchainSimulations.length && !isFiltered && <EmptyState type="simulations" />}
      {(!!blockchainSimulations.length || isFiltered) && (
        <SimulationsV2Table
          simulations={blockchainSimulations}
          onPageChange={onPageChange}
          onSortChange={onSortChange}
          isLoading={isLoading}
          onFilter={onFilter}
        />
      )}
    </MainLayout>
  );
});
