import { FC } from 'react';
import { Box } from '@chaos/ui';
import { SxProps } from '@mui/material';

const SHIMMER_STYLES = (colors: [string, string]) => ({
  display: 'inline-block',
  position: 'relative',
  overflow: 'hidden',
  height: '100%',
  width: '100%',
  borderRadius: '8px',
  backgroundColor: colors[0],

  '&::after': {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    transform: 'translateX(-100%)',
    background: `linear-gradient(90deg, ${colors[0]}, ${colors[1]}, ${colors[0]})`,
    animation: 'shimmer 1s infinite',
    content: '" "',
  },

  '@keyframes shimmer': {
    '100%': {
      transform: 'translateX(100%)',
    },
  },
} as const);

interface ShimmerLoaderProps {
  sx?: SxProps,
  colors?: [string, string]
}

export const ShimmerLoader: FC<ShimmerLoaderProps> = ({ sx, colors = ['#54595F', '#83888D'] }) => (
  <Box sx={{ ...SHIMMER_STYLES(colors), ...sx }} />
);
