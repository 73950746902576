import { Typography } from '@chaos/ui/typography';
import { Paper } from '@chaos/ui/paper';
import { Box } from '@chaos/ui/box';
import { JSONViewer } from '../json-viewer';
import { CustomIcon } from '../custom-icon';

export interface JsonWidgetInterface {
  title?: { key: string; value: string }
  subTitle?: { key: string; value: string }
  icon?: string
  data?: any
}

export const JsonWidget = ({
  icon, title, subTitle, data,
}: JsonWidgetInterface): JSX.Element => (
  <Paper variant="card">
    <Box display="flex" alignItems="center" gap={1} sx={{ svg: { height: 24, width: 24 } }}>
      {icon && <span data-testid="icon"><CustomIcon icon={icon} /></span>}
      <Typography variant="h2" component="h2" data-testid="title">
        {title === null
          ? null
          : `${title?.key || ''} ${title?.value || ''}`}
      </Typography>
    </Box>
    <Typography component="h3" data-testid="subtitle">
      {!subTitle?.value
        ? null
        : `${subTitle?.key}: ${subTitle?.value}`}
    </Typography>
    <Typography component="h3">
      {subTitle?.value === null ? null : subTitle?.value}
    </Typography>
    {data === null ? null : (
      <Box mt={3} data-testid="json">
        <JSONViewer
          // eslint-disable-next-line
          json={data}
          maxHeight={328}
          padding="8px 0 8px 8px"
        />
      </Box>
    )}
  </Paper>
);
