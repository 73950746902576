import { FC } from 'react';
import { VictoryPie, VictoryLabel } from 'victory';
import { Typography } from '@chaos/ui/typography';
import { Paper } from '@chaos/ui/paper';
import { Box } from '@chaos/ui/box';
import { CustomSlice } from '@chaos/ui/charts';

export interface RiskScoreProps {
  title: string
  subtitle: string
  value: number
}

export const RiskScore: FC<RiskScoreProps> = ({
  title, subtitle, value,
}) => (
  <Paper variant="card" sx={{ display: 'flex' }}>
    <Box height={120} width={120} flex="0 0 120px">
      <svg viewBox="0 0 120 120">
        <VictoryPie
          data={[{ x: '', y: 100 - value, background: true }, { x: '', y: value }]}
          standalone={false}
          width={120}
          height={120}
          padding={0}
          innerRadius={40}
          labelRadius={40}
          style={{ labels: { display: 'none' } }}
          dataComponent={<CustomSlice />}
        />
        <VictoryLabel
          textAnchor="middle"
          style={{ fontSize: 24, fill: 'white', fontFamily: 'Archivo, sans-serif' }}
          x={58}
          y={58}
          text={value}
        />
      </svg>
    </Box>
    <Box ml={3}>
      <Typography>{title}</Typography>
      <Typography variant="caption" sx={{ fontWeight: 'normal' }}>{subtitle}</Typography>
    </Box>
  </Paper>
);
