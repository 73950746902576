import { ResponsiveTreeMapHtml } from '@nivo/treemap';
import { Box } from '@chaos/ui/box';

const colorScheme = ['#0A4173', '#0E61AC', '#1171C8', '#1381E5', '#176962', '#1D837A', '#239D93', '#430517', '#650722', '#17191E', '#02101D'];

export type TreemapChartDataChild = {
  name: string;
  value: number;
};

type TreemapChartData<ChildType extends object = TreemapChartDataChild> = {
  name: string,
  children: ChildType[]
};

export interface TreemapChartProps<DataChildType extends object = TreemapChartDataChild> {
  data: DataChildType[]
  height?: number
  identity?: string
  isGrouped?: boolean
  orientLabel?: boolean
  labelFormat?: (value: number, name?: string) => string,
  tooltipFormat?: (value: number, name?: string) => string,
  onClick?: (id: string) => void
}

// Docs: https://nivo.rocks/treemap/html/
export const TreemapChart = <DataChildType extends object = TreemapChartDataChild>({
  data,
  identity,
  isGrouped,
  labelFormat,
  orientLabel = true,
  tooltipFormat,
  onClick,
  height = 500,
}: TreemapChartProps<DataChildType>) => (
  <Box
    height={height}
    sx={{
      '& div': { borderRadius: 2 },
      '& span': { whiteSpace: 'pre !important' },
      '> div > div > div > div > div': { cursor: onClick ? 'pointer' : undefined },
    }}
  >
    <ResponsiveTreeMapHtml<TreemapChartData<DataChildType>>
      data={{ name: '', children: data }}
      leavesOnly={!isGrouped}
      innerPadding={4}
      outerPadding={isGrouped ? 8 : undefined}
      value="value"
      enableParentLabel
      identity={identity || 'name'}
      colors={colorScheme}
      colorBy={(d) => d.id}
      nodeOpacity={1}
      borderWidth={0}
      label={(d) => (labelFormat ? labelFormat(d.value, d.id) : (`${d.id.length > 12
        ? `${d.id.substring(0, 6)}...${d.id.substring(d.id.length - 6)}`
        : d.id}\n${d.formattedValue}%`))}
      orientLabel={orientLabel}
      labelSkipSize={64}
      labelTextColor="#FFF"
      parentLabelTextColor="#FFF"
      theme={{
        fontFamily: 'Archivo',
        labels: {
          text: {
            textAlign: 'center', fontSize: 12, fontWeight: 500, cursor: 'pointer',
          },
        },
      }}
      onClick={(d) => onClick?.(d.id)}
      tooltip={({ node }) => (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '8px 12px',
            borderRadius: 8,
            color: '#FFF',
            background: '#17191E',
            fontSize: 12,
          }}
        >
          <strong>{node.id}</strong>
          <strong>

            {tooltipFormat ? tooltipFormat(node.value) : (labelFormat ? labelFormat(node.value) : `${node.value}%`)}
          </strong>
        </Box>
      )}
      motionConfig="stiff"
    />
  </Box>
  );
