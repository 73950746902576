import {
  useCallback, useState, FC, useEffect, useMemo, memo,
} from 'react';
import { ScriptResource, ScriptResourceGroupInput, BlockchainSimulation } from '@chaos/types';
import * as Yup from 'yup';
import { Box } from '@chaos/ui/box';
import { Button } from '@chaos/ui/button';
import { Tabs } from '@chaos/ui/tabs';
import { RouteParams, RoutePath } from 'src/config/routes';
import SimulationInfoStep, { InfoType } from './SimulationInfoStep';
import SimulationFormSummary from './SimulationFormSummary';
import { CreateStep } from './CreateStep';
import { useValidation } from '../../hooks/useValidation';

export const STEPS_TOTAL = 5;

enum STEPS {
  SIMULATION_INFO = 0,
  AGENTS = 1,
  SCENARIOS = 2,
  ASSERTION = 3,
  OBSERVERS = 4,
  SUMMARY = 5,
}

export type Inputs = {
  id?: string,
  info: InfoType,
  agents: Partial<ScriptResource>[],
  scenarios: Partial<ScriptResource>[],
  observers: Partial<ScriptResource>[],
  assertions: Partial<ScriptResource>[],
  observerGroupsMetadata: ScriptResourceGroupInput[],
  anvilFork?: boolean
};

const inputToCard = (
  baseUrl: string,
  placeholder: string,
) => ((input: Partial<ScriptResource>) => ({
  title: input.name || '',
  subtitle: input.configName || '',
  subtitleLink: `${input.refId ? baseUrl.replace(placeholder, input.refId) : ''}`,
  description: '',
  fullPath: input.fullPath || '',
  args: Object.entries(input.args || {}).map(([key, value]) => ({
    parameterName: key,
    parameterValue: value?.toString() || '',
  })).filter(({ parameterName }) => parameterName !== 'chainUrl'),
}));

const scriptToInput = (
  script: Partial<ScriptResource>,
  index: number,
): Partial<ScriptResource> => ({
  ...script,
  args: Object.entries(script.args || {}).reduce((res, [key, value]) => ({
    ...res,
    [key]: typeof value === 'string' ? { type: value } : value,
  }), {}),
  displayOrder: script.displayOrder || index,
});

interface FormProps {
  onSubmit: (inputs: Inputs) => Promise<void>,
  isSimulationClone?: boolean,
  currentSimulation?: BlockchainSimulation
}

const BlockchainSimulationFormComp: FC<FormProps> = ({
  onSubmit,
  isSimulationClone,
  currentSimulation,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [info, setInfo] = useState<Inputs['info']>({});
  const [agents, setAgents] = useState<Inputs['agents']>(currentSimulation?.agents || []);
  const [scenarios, setScenarios] = useState<Inputs['scenarios']>([
    ...currentSimulation?.scenarios.runtime || [], ...currentSimulation?.scenarios.setup || [],
  ]);
  const [observers, setObservers] = useState<Inputs['observers']>(currentSimulation?.observers || []);
  const [assertions, setAssertions] = useState<Inputs['assertions']>(currentSimulation?.assertions || []);
  const [observersGroups, setObserversGroups] = useState<Inputs['observerGroupsMetadata']>([]);

  const validationSchema = useMemo(() => Yup.object({
    description: Yup.string().required('This field is required'),
  }), []);
  const [validate, isFormValid, errors] = useValidation(validationSchema);

  const onInfoChange = useCallback((newInfo: Inputs['info']) => {
    setInfo((state) => {
      validate({ ...state, ...newInfo });
      return { ...state, ...newInfo };
    });
  }, [validate]);

  const onAgentsChange = useCallback((newAgents: Inputs['agents']) => setAgents(newAgents), []);
  const onScenariosChange = useCallback(
    (newScenarios: Inputs['scenarios']) => setScenarios(newScenarios),
    [],
  );
  const onObserversChange = useCallback(
    (newObservers: Inputs['observers']) => setObservers(newObservers),
    [],
  );
  const onAssertionsChange = useCallback(
    (newAssertions: Inputs['assertions']) => setAssertions(newAssertions),
    [],
  );
  const onObserversGroupsChange = useCallback(
    (newObserversGroups: Inputs['observerGroupsMetadata']) => setObserversGroups(newObserversGroups),
    [],
  );

  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const handleSubmit = async () => {
    setIsSubmit(true);
    await onSubmit({
      ...currentSimulation,
      id: isSimulationClone ? undefined : currentSimulation?.id,
      info,
      agents,
      scenarios,
      observers,
      assertions,
      observerGroupsMetadata: observersGroups,
    });
    setIsSubmit(false);
  };

  useEffect(() => {
    if (currentSimulation) {
      setInfo({
        name: currentSimulation.name + (isSimulationClone ? ' Clone' : ''),
        description: currentSimulation.description,
        duration: currentSimulation.iterations,
        initialBlock: currentSimulation.blockNumber,
        blockTimeout: currentSimulation.iterTimeout,
      });
      setAgents(currentSimulation.agents.map((
        agent,
        idx,
      ) => scriptToInput(agent, idx)));
      setScenarios([
        ...(currentSimulation.scenarios?.runtime.map<Partial<ScriptResource>>(
          (scenario, idx) => ({ type: 'runtime', ...scriptToInput(scenario, idx) }),
        ) || []),
        ...(currentSimulation.scenarios?.setup.map<Partial<ScriptResource>>(
          (scenario, idx) => ({ type: 'setup', ...scriptToInput(scenario, idx) }),
        ) || []),
      ]);
      setObservers(currentSimulation.observers.map(
        (observer, idx) => scriptToInput(observer, idx),
      ));
      setAssertions(currentSimulation.assertions.map(
        (assertion, idx) => scriptToInput(assertion, idx),
      ));
      const currentGroups: ScriptResourceGroupInput[] = currentSimulation
        .observerGroupsMetadata
        ?.map((group) => {
          const { observerIds, observerInstanceIds } = group;

          if (observerIds) return { ...group, observerIds };

          const groupObserversIds: string[] = currentSimulation.observers.filter((obs) => (obs.id
            || obs.refId)
          && obs.instanceId
          && (observerInstanceIds || [])
            .includes(obs.instanceId)).map((observer) => (observer.id || observer.refId) as string);

          return { ...group, observerIds: groupObserversIds };
        }) || [];
      setObserversGroups(currentGroups || []);
    }
  }, [currentSimulation, isSimulationClone]);

  return (
    <Box>
      <Box sx={{ mb: 5 }}>
        <Tabs
          value={tabIndex}
          tabs={[
            { label: 'Details' },
            { label: 'Agents' },
            { label: 'Scenarios' },
            { label: 'Assertions' },
            { label: 'Observers' },
            { label: 'Confirm' },
          ]}
          fullWidth
          onChange={(_, newValue) => {
            const isValid = validate(info);
            if (!isValid) {
              return;
            }
            setTabIndex(newValue);
          }}
        />
      </Box>
      {tabIndex !== STEPS.SIMULATION_INFO && (
        <SimulationFormSummary
          description={info?.description || ''}
          name={info?.name || ''}
          duration={info?.duration}
          agents={agents.map(
            inputToCard(RoutePath.AgentPlayground.Details, RouteParams.Id),
          )}
          assertions={assertions.map(
            inputToCard(RoutePath.AssertionWizard.Details, RouteParams.Id),
          )}
          observers={observers.map(
            inputToCard(RoutePath.ObserverConfiguration.Details, RouteParams.Id),
          )}
          scenarios={scenarios.map(
            inputToCard(RoutePath.ScenarioCatalogue.Details, RouteParams.Id),
          )}
          expanded={tabIndex === STEPS.SUMMARY}
        />
      )}
      {tabIndex === STEPS.SIMULATION_INFO && (
        <SimulationInfoStep errors={errors} info={info} onChange={onInfoChange} />
      )}
      {tabIndex === STEPS.AGENTS && (
        <CreateStep type="Agent" inputs={agents} onChange={onAgentsChange} />
      )}
      {tabIndex === STEPS.SCENARIOS && (
        <CreateStep type="Scenario" inputs={scenarios} onChange={onScenariosChange} />
      )}
      {tabIndex === STEPS.OBSERVERS && (
        <CreateStep
          type="Observer"
          inputs={observers}
          groupsInputs={observersGroups}
          onChange={onObserversChange}
          onGroupsChange={onObserversGroupsChange}
          selectable
          itemsDraggable
        />
      )}
      {tabIndex === STEPS.ASSERTION && (
        <CreateStep type="Assertion" inputs={assertions} onChange={onAssertionsChange} />
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          padding: '40px 0',
          backgroundColor: 'background.default',
          position: 'sticky',
          bottom: -40,
        }}
      >
        {tabIndex > 0 && (
          <Button color="secondary" onClick={() => setTabIndex((i) => i - 1)}>
            Back
          </Button>
        )}
        <Button
          onClick={() => {
            const isValid = validate(info);
            if (!isValid) {
              return;
            }
            if (tabIndex < STEPS_TOTAL) {
              setTabIndex(tabIndex + 1);
            } else {
              void handleSubmit();
            }
          }}
          disabled={!isFormValid || isSubmit}
          color="primary"
          sx={{ ml: 3 }}
        >
          {tabIndex < STEPS_TOTAL ? 'Next' : (currentSimulation?.id && !isSimulationClone) ? 'Update' : 'Create'}
        </Button>
      </Box>
    </Box>
  );
};

export const BlockchainSimulationForm = memo(BlockchainSimulationFormComp);
