import { useEffect, useState } from 'react';
import qs from 'querystring';
import { ResponseWithCount } from '@chaos/types';
import { DataQueries, GetPageData } from 'src/services/engine';

export const useDataFetchersQuery = <
ReturnType = any, QueryType = Record<string, unknown>,
>(endpoint: `${string}.${string}`, type: 'onchain' | 'offchain', query?: QueryType, isQueryRequired?: boolean) : {
    isLoading: boolean,
    response: ReturnType | undefined
  } => {
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState<ReturnType | undefined>(undefined);

  useEffect(() => {
    (void (async () => {
      if (!isQueryRequired || query) {
        setIsLoading(true);
        const fetchedData = await DataQueries<ReturnType, QueryType>(endpoint, type, query);
        setResponse(fetchedData);
        setIsLoading(false);
      }
    })());
  }, [endpoint, type, query, isQueryRequired]);

  return { isLoading, response };
};

export const usePaginatedDataFetchersQuery = <
ReturnType = any, QueryType extends { fromId?: string } = Record<string, unknown>,
>(endpoint: `${string}.${string}`, type: 'onchain' | 'offchain', query?: QueryType) : {
    isLoading: boolean,
    response: ReturnType[] | undefined,
  } => {
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState<ReturnType[]>();

  useEffect(() => {
    (void (async () => {
      setIsLoading(true);
      const fetchedData = await DataQueries<ResponseWithCount<ReturnType>, QueryType>(
        endpoint,
        type,
        query,
      );
      if (fetchedData) {
        setResponse((prevResponse) => (query?.fromId
          ? [...prevResponse || [], ...fetchedData.data] : fetchedData?.data));
      }

      setIsLoading(false);
    })());
  }, [endpoint, type, query]);

  return { isLoading, response };
};

export const useDataFetchersPageData = <ReturnType = any>(
  endpoint: string,
  queryObj?: qs.ParsedUrlQueryInput) : {
    isLoading: boolean,
    response: ReturnType | undefined,
  } => {
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState<ReturnType | undefined>(undefined);

  useEffect(() => {
    (void (async () => {
      if (typeof queryObj === 'object' && Object.values(queryObj).some((val) => val === '')) return;
      setIsLoading(true);
      const fetchedData = await GetPageData<ReturnType>(endpoint, queryObj);
      setResponse(fetchedData);
      setIsLoading(false);
    })());
  }, [endpoint, queryObj]);

  return { isLoading, response };
};
