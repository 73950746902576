import { useEffect, useState } from 'react';
import axios from 'axios';
import { firebase } from 'src/services/firebase';

export const useScriptCode = (path?: string) => {
  const storageRef = firebase.storage();
  const [code, setCode] = useState<string>();

  useEffect(() => {
    if (path) {
      const blobRef = storageRef.ref(path);
      void blobRef.getDownloadURL().then(async (url) => {
        const { data } = await axios.get<string>(url);
        setCode(data);
      });
    }
  }, [path, storageRef]);

  return code;
};
