import {
  AggregationName,
  BlockchainSimulation,
  BlockchainSimulationResult,
  Header,
  MetricStatistics,
  RenderData,
} from '@chaos/types';
import { formatAmount } from '@chaos/ui/utils/formatters';
import { EmptyState } from 'src/components/empty-state';
import { ChaosTable } from 'src/components/widgets';
import { RoutePath } from 'src/config/routes';
import { getSimulationObserverNameById } from '../utils';

type Props = {
  aggregationMethod: AggregationName;
  simulation: BlockchainSimulation;
  metricStatistics: MetricStatistics[];
  repetitionsResults: BlockchainSimulationResult[];
};

const ObservationBreakdownTable = ({
  simulation,
  metricStatistics,
  aggregationMethod,
  repetitionsResults,
}: Props) => {
  const tableHeaders: Header[] = [
    {
      renderType: 'TEXT',
      text: 'Repetition #',
    },
    ...metricStatistics.map((metric) => (
      {
        renderType: 'TEXT',
        text: getSimulationObserverNameById(simulation, metric.id) || '',
      } as Header
    )),
  ];

  const tableData: RenderData[][] = repetitionsResults.map(
    (blockchainSimulationResult: BlockchainSimulationResult, index) => {
      const aggregations = new Map(
        Object.entries(blockchainSimulationResult.result?.observationsMetaData ?? [])
          .map((x) => [x[1].id, x[1].aggregations]),
      );

      const rowData: RenderData[] = [
        {
          renderType: 'TEXT',
          text: (index + 1).toString(),
        },
        ...metricStatistics.map((metric) => {
          const cellValue = Number(aggregations.get(metric.id)?.[aggregationMethod]);
          return {
            renderType: 'TEXT',
            text: formatAmount(cellValue, { notation: 'compact', maximumFractionDigits: 2 }),
            tooltipText: formatAmount(cellValue, { notation: 'standard' }),
            value: cellValue,
          } as unknown as RenderData;
        })];
      return rowData;
    },
  );

  const rowHref = (rowId: number) => `${RoutePath.Simulations.Results}/${repetitionsResults[rowId].id}`;

  return (
    <>
      {!repetitionsResults.length && <EmptyState type="simulation results" />}
      <ChaosTable
        title="Repetitions"
        headers={tableHeaders}
        rowHref={rowHref}
        rowHrefTarget="_blank"
        data={tableData}
        pageSize={20}
      />
    </>
  );
};

export default ObservationBreakdownTable;
