import * as Yup from 'yup';
import { StringSchema } from 'yup';

export const string = Yup.string().trim().default('');

export const required = string.required();

export const email = Yup.string().trim().email().required()
  .default('');

// eslint-disable-next-line func-names
Yup.addMethod<StringSchema>(
  Yup.string,
  'uniqueName',
  function <T extends { name: string }>(this: StringSchema, existingValues: T[]) {
    return this.test(
      'is-unique-name',
      // eslint-disable-next-line no-template-curly-in-string
      'This ${path} is already taken',
      (value?: string) => existingValues.every((elem) => elem.name !== value?.trim()),
    );
  },
);

export const checkIsJSONValid = (
  valuesObj: any,
  valueTypesObj: any,
  setErrors: (v: any) => void,
): boolean => {
  const validationConfig: Record<string, any> = {
    number: Yup.number()
      // eslint-disable-next-line
      .typeError('${path} must be of type number')
      .strict(true),
    string: Yup.string()
      // eslint-disable-next-line
      .typeError('${path} must be of type string')
      .strict(true),
    'string[]': Yup.array()
      // eslint-disable-next-line
      .of(Yup.string().typeError('${path} must be of type string').strict(true))
      .strict(true),
    'number[]': Yup.array()
      // eslint-disable-next-line
      .of(Yup.number().typeError('${path} must be of type number').strict(true))
      .strict(true),
    boolean: Yup.bool()
      // eslint-disable-next-line
      .typeError('${path} must be of type boolean')
      .strict(true),
    'map<string,string>': Yup.object().test({
      name: 'is-strings-array',
      // eslint-disable-next-line
      message: '${path} values must be of type string',
      test: (value) => Object.values(value).every((v) => typeof v === 'string'),
    }),
    'map<string,number>': Yup.object().test({
      name: 'is-numbers-array',
      // eslint-disable-next-line
      message: '${path} values must be of type number',
      test: (value) => Object.values(value).every((v) => typeof v === 'number'),
    }),
  };

  if (!valueTypesObj) return true;

  // eslint-disable-next-line
  const valueKeys = Object.keys(valuesObj);
  // eslint-disable-next-line
  if (valueKeys.length !== Object.keys(valueTypesObj).length) {
    const unknownKeys = valueKeys.reduce((res, key) => {
      if (!(key in valueTypesObj)) res.push(key);
      return res;
    }, [] as string[]);

    const errorMsg = `Unknown key${
      unknownKeys.length > 1 ? 's' : ''
    }: ${unknownKeys.join(', ')}`;

    setErrors([errorMsg]);
    return false;
  }

  // eslint-disable-next-line
  const validationSchema = Object.keys(valuesObj).reduce((res, key) => {
    // eslint-disable-next-line
    const valueType = valueTypesObj[key];
    // eslint-disable-next-line
    if (validationConfig[valueType]) res[key] = validationConfig[valueType];
    return res;
  }, {} as Record<string, any>);

  try {
    Yup.object(validationSchema).validateSync(valuesObj, { abortEarly: false });

    setErrors([]);
    return true;
  } catch (e) {
    // @ts-expect-error unknown error
    setErrors(e?.errors);
    return false;
  }
};
