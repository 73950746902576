import { Box } from '@chaos/ui/box';
import { Typography } from '@chaos/ui/typography';

import { ReactComponent as LoaderSvg } from 'src/assets/img/loader-dashboard.svg';

export const Loader = (): JSX.Element => (
  <Box
    sx={{
      margin: 'auto',
      '@keyframes circleRotate': {
        '0%': {
          transform: 'rotate(0deg)',
        },
        '100%': {
          transform: 'rotate(360deg)',
        },
      },

      '.circle-rotate': {
        transformOrigin: 'center',
        animation: 'linear circleRotate 7s infinite',

        '&._reverse': {
          animationDirection: 'reverse',
        },
      },
      svg: {
        display: 'block',
        margin: '0 auto 24px',
      },
    }}
  >
    <LoaderSvg />

    <Typography
      sx={{
        textAlign: 'center',
        fontWeight: 600,
        fontSize: '24px',
        color: 'light.main',
        lineHeight: '40px',
        '@keyframes visibility': {
          '0%': {
            opacity: 1,
          },
          '65%': {
            opacity: 1,
          },
          '66%': {
            opacity: 0,
          },
          '100%': {
            opacity: 0,
          },
        },

        '@keyframes visibility2': {
          '0%': {
            opacity: 0,
          },
          '21%': {
            opacity: 0,
          },
          '22%': {
            opacity: 1,
          },
          '65%': {
            opacity: 1,
          },
          '66%': {
            opacity: 0,
          },
          '100%': {
            opacity: 0,
          },
        },

        '@keyframes visibility3': {
          '0%': {
            opacity: 0,
          },
          '43%': {
            opacity: 0,
          },
          '44%': {
            opacity: 1,
          },
          '65%': {
            opacity: 1,
          },
          '66%': {
            opacity: 0,
          },
          '100%': {
            opacity: 0,
          },
        },

        '.dot': {
          transition: 'opacity 1s',
        },

        '.dot1': {
          animation: 'visibility 3s linear infinite',
        },
        '.dot2': {
          animation: 'visibility2 3s linear infinite',
        },
        '.dot3': {
          animation: 'visibility3 3s linear infinite',
        },
      }}
    >
      Loading
      <span>
        <span className="dot dot1">.</span>
        <span className="dot dot2">.</span>
        <span className="dot dot3">.</span>
      </span>
    </Typography>
  </Box>
);
