import { useEffect, useState } from 'react';
import { Subscription } from 'rxjs';
import {
  collection, doc, query, where,
} from 'firebase/firestore';
import { collectionData } from 'rxfire/firestore';
import { SimulationContract } from '@chaos/types';
import { COLLECTIONS } from '@chaos/utils';
import { firestore } from 'src/services/firebase';
import { useCurrentTeam } from './useCurrentTeam';

export const useSimulationContract = (
  simulationResultId: string,
  address: string,
  connectionKey?: string,
): { isLoading: boolean, contract: SimulationContract | undefined } => {
  const team = useCurrentTeam();
  const [contract, setContract] = useState<SimulationContract>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const subs: Subscription[] = [];

    if (connectionKey) {
      const contrractsCol = collection(firestore, COLLECTIONS.SIMULATION_CONTRACTS);
      const contractsObs = collectionData(query(
        contrractsCol,
        where('team', '==', doc(collection(firestore, COLLECTIONS.TEAMS), team?.id)),
        where('simulationResultId', '==', simulationResultId),
        where('address', '==', address),
        where('connectionKey', '==', connectionKey),
      ), { idField: 'id' });
      subs.push(contractsObs.subscribe((data) => {
        setIsLoading(false);
        setContract(data[0] as SimulationContract);
      }));
    }

    return () => subs.forEach((s) => s.unsubscribe());
  }, [team?.id, simulationResultId, address, connectionKey]);

  return { isLoading, contract };
};
