import React, { useMemo, useState } from 'react';

interface TabsControl<T> {
  dataForRender: T,
  tabIndex: number;
  handleTabChange: (_: React.SyntheticEvent, newValue: number) => void;
}
export const useTabsControl = <T>(tabsData: T[], initialTab?: number): TabsControl<T> => {
  const [tabIndex, setTabIndex] = useState<number>(initialTab ?? 0);

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    if (newValue >= tabsData.length) {
      return;
    }
    setTabIndex(newValue);
  };

  const dataForRender = useMemo(() => tabsData[tabIndex], [tabIndex, tabsData]);

  return {
    dataForRender,
    tabIndex,
    handleTabChange,
  };
};
