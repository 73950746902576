import { FC, useState } from 'react';
import { Collapse } from '@chaos/ui/collapse';
import { Box } from '@chaos/ui/box';
import { IconButton } from '@chaos/ui/icon-button';
import { Icon } from '@chaos/ui/icon';
import { Tooltip } from '@chaos/ui/tooltip';
import { Typography } from '@chaos/ui/typography';

type CollapsibleDescriptionProps = {
  text: string
};

export const CollapsibleDescription: FC<CollapsibleDescriptionProps> = ({ text }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <>
      <Collapse in={!isCollapsed} unmountOnExit>
        <Typography sx={{ pr: 1, mt: 1 }}>{text}</Typography>
      </Collapse>
      <Box m={1} position="absolute" top={-8} right={0} zIndex={1}>
        <Tooltip title={`${text.substring(0, 40)}...`}>
          <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
            <Icon name={isCollapsed ? 'Info' : 'KeyboardArrowUp'} fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    </>
  );
};
