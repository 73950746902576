import { memo, useMemo } from 'react';
import { Header, RenderData } from '@chaos/types';
import { formatAddressCompact, formatAmount } from '@chaos/ui/utils/formatters';
import { Box } from '@chaos/ui';
import { ChaosTable } from '@chaos/ui/chaos-table';
import { Wallet } from './types';
import { deBankWalletLink } from './utils';

type Props = {
  wallets: Wallet[],
  defaultWallets: Wallet[],
};

const pageSize = 15;

const WalletsTable = ({ wallets, defaultWallets }: Props) => {
  const newWalletsEligibleForLiquidation = wallets.filter((wallet) => !defaultWallets.some(
    ({ address }) => address === wallet.address,
  ));

  const walletsTableHeaders: Header[] = useMemo(() => [
    {
      renderType: 'TEXT',
      text: 'Wallet',
      field: 'id',
      width: '12%',
    },
    {
      renderType: 'TEXT',
      text: 'Collateral',
      field: 'totalCollateralUSD',
      width: '12%',
    },
    {
      renderType: 'TEXT',
      text: 'Borrow',
      field: 'totalBorrowsUSD',
      width: '12%',
    },
    {
      renderType: 'TEXT',
      text: 'Health',
      field: 'healthFactor',
      width: '12%',
    },
    {
      renderType: 'TEXT',
      text: 'Assets',
      nonSortable: true,
      width: '12%',
    },
  ], []);

  const walletsTableRows: RenderData[][] = useMemo(() => newWalletsEligibleForLiquidation
    .map((wallet: Wallet) => [
      {
        renderType: 'LINK',
        text: formatAddressCompact(wallet.address),
        copyText: wallet.address,
        searchTerm: wallet.address,
        tooltipText: wallet.address,
        href: deBankWalletLink(wallet.address),
      },
      ...wallet.totalCollateralUsd ? [{
        renderType: 'TEXT',
        text: formatAmount(wallet.totalCollateralUsd, { currency: 'USD', maximumFractionDigits: 2 }),
        tooltipText: formatAmount(wallet.totalCollateralUsd, { currency: 'USD', notation: 'standard' }),
        value: Number(wallet.totalCollateralUsd) || 0,
      }] : [],
      {
        renderType: 'TEXT',
        text: wallet.totalBorrowsUsd ? formatAmount(wallet.totalBorrowsUsd, { currency: 'USD', maximumFractionDigits: 2 }) : '-',
        tooltipText: wallet.totalBorrowsUsd ? formatAmount(wallet.totalBorrowsUsd, { currency: 'USD', notation: 'standard' }) : undefined,
        value: Number(wallet.totalBorrowsUsd) || 0,
      },
      {
        renderType: 'TEXT',
        text: wallet.health !== Number.MAX_VALUE ? (wallet.health < 100 ? (wallet.health || 0).toFixed(2) : '100+') : '∞',
        value: (wallet.health || 0),
        status: wallet.health < 1.5 && (wallet.health < 1 ? 'ERROR' : 'WARNING'),
        width: '70px',
        isMarked: true,
      },
      {
        renderType: 'ICONS',
        text: wallet.positions.join(', '),
        icons: wallet.positions,
      },
    ] as RenderData[]), [newWalletsEligibleForLiquidation]);

  if (!wallets?.length) return <Box />;

  return (
    <ChaosTable
      headers={walletsTableHeaders}
      data={walletsTableRows}
      pageSize={pageSize}
      isInitialSortEnable
      initialSortBy={1}
      isInitialSortDesc
      showSearch
      title="New Wallets Eligible for Liquidation"
    />
  );
};

export default memo(WalletsTable);
