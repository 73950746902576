import { FC } from 'react';
import { AutocompleteRenderInputParams, SxProps } from '@mui/material';
import { InputAdornment } from '../input-adornment';
import { TextField } from '../text-field';
import { CircularProgress } from '../circular-progress';
import { CustomIcon } from '../custom-icon';

interface FilterBarProps {
  value?: string
  onChange: (value: string) => void
  placeholder?: string
  fullWidth?: boolean
  sx?: SxProps
  loading?: boolean
}

export const FilterBar: FC<FilterBarProps & Partial<AutocompleteRenderInputParams>> = ({
  value, onChange, placeholder, fullWidth = false, sx, loading, InputProps, ...props
}) => (
  <TextField
    {...props}
    type="search"
    value={value}
    fullWidth={fullWidth}
    onChange={(e) => onChange(e.target.value)}
    placeholder={placeholder || 'Search field'}
    InputProps={{
      ref: InputProps?.ref,
      startAdornment: (
        <InputAdornment position="start">
          <CustomIcon icon="search" />
        </InputAdornment>
      ),
      endAdornment: loading && <InputAdornment position="end"><CircularProgress color="inherit" size={20} /></InputAdornment>,
    }}
    sx={{ minWidth: 280 }}
  />
);
