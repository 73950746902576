import { useEffect, useMemo, useState } from 'react';
import { Observable, Subscription, throttleTime } from 'rxjs';
import {
  collection, doc, query, where,
} from 'firebase/firestore';
import { collectionData } from 'rxfire/firestore';
import { COLLECTIONS } from '@chaos/utils';
import { SimulationPermutationResult } from '@chaos/types';
import { firestore } from 'src/services/firebase';
import { useCurrentTeam } from './useCurrentTeam';

export const useSimulationPermutationResults = (parentResultId: string): {
  isLoading: boolean,
  permuationResults?: SimulationPermutationResult[],
} => {
  const team = useCurrentTeam();
  const [permuationResults, setPermutationResults] = useState<SimulationPermutationResult[]>();

  useEffect(() => {
    const subs: Subscription[] = [];
    const simPermutationResultsObs = (collectionData(query(
      collection(firestore, COLLECTIONS.SIMULATION_PERMUATATION_RESULTS),
      where('team', '==', doc(collection(firestore, COLLECTIONS.TEAMS), team?.id)),
      where('parentResultID', '==', parentResultId),
    ), { idField: 'id' }) as Observable<SimulationPermutationResult[]>).pipe(throttleTime(5000));
    subs.push(simPermutationResultsObs.subscribe((a) => setPermutationResults(a)));

    return () => subs.forEach((sub) => sub.unsubscribe());
  }, [parentResultId, team?.id]);
  const result = useMemo(
    () => ({ isLoading: !permuationResults, permuationResults }),
    [permuationResults],
  );

  return result;
};
