import { Box, Button, Typography } from '@chaos/ui';
import {
  FieldArray, Formik, Form,
} from 'formik';
import { useParams } from 'react-router-dom';
import { useCurrentTeam } from 'src/hooks';
import { publishModelRun } from 'src/pages/param-recommendations-tool/data/requests';
import { useState } from 'react';
import {
  ModelRunResult, PublishModelRunOverrides, PublishModelRunRequest, PublishModelRunResponse,
} from '../../../../data/types';
import { FormValues, PublishValue } from './types';
import PublishAssetRow, { CUSTOM_PERMUTATION_VALUE } from './publish-asset-row';

type Props = {
  modelResults: ModelRunResult[];
};

const PublishForm = ({ modelResults }: Props) => {
  const { id } = useParams() as { id: string };
  const team = useCurrentTeam();
  const [publishModelResult, setPublishModelResult] = useState<PublishModelRunResponse>();

  const publishValues: PublishValue[] = modelResults.map((result) => {
    const recommendedPermutation = result.recommended || result.current;
    const derivedValues = Object.entries(
      recommendedPermutation.derived_values,
    ).map(([key, param]) => ({
      paramKey: key,
      value: param.value,
    }));

    const customPermutation = Object.entries(
      recommendedPermutation.values,
    ).map(([key, param]) => ({
      paramKey: key,
      value: param.value,
    }));

    return ({
      assetResultId: result.id,
      recommendedPermutationId: recommendedPermutation.permutation_id,
      derivedValues,
      customPermutation,
    });
  });

  const onSubmit = async (values: FormValues) => {
    const publishModelOverrides = values.publishValues.reduce((
      acc: PublishModelRunOverrides,
      value,
    ) => {
      const derivedValues = (value.derivedValues || []).reduce((d: Record<string, number>, val) => (
        { ...d, [val.paramKey]: val.value }
      ), {});

      const customPermutation = (value.customPermutation || []).reduce((
        c: Record<string, number>,
        val,
      ) => (
        { ...c, [val.paramKey]: val.value }
      ), {});

      const isCustomPermutation = value.recommendedPermutationId === CUSTOM_PERMUTATION_VALUE;

      const recommendedPermutationId = isCustomPermutation
        ? undefined : value.recommendedPermutationId;

      const forcePermutation = (!value.customPermutation?.length || !isCustomPermutation)
        ? undefined : customPermutation;

      acc[value.assetResultId] = {
        recommendedPermutationId,
        derivedValues: !value.derivedValues?.length ? undefined : derivedValues,
        forcePermutation,
      };

      return acc;
    }, {});

    const publishModelRequest: PublishModelRunRequest = {
      modelRunId: id,
      overrides: publishModelOverrides,
    };
    if (team?.authKey) {
      const result = await publishModelRun(publishModelRequest, team.authKey);
      setPublishModelResult(result);
    }
  };

  return (
    <Box>
      <Formik
        initialValues={{ publishValues }}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <Box display="flex" flexDirection="column" gap={2} maxHeight={500}>
              <Box flex={1} display="flex" flexDirection="column" gap={3} overflow="auto" mb={1}>
                <FieldArray
                  name="publishValues"
                  render={() => values.publishValues.map((publishValue, index) => {
                    const assetResult = modelResults.find((
                      r,
                    ) => r.id === publishValue.assetResultId);
                    return (
                      <Box key={assetResult?.id}>
                        <PublishAssetRow
                          index={index}
                          assetResult={assetResult!}
                          values={publishValue}
                        />
                      </Box>
                    );
                  })}
                />
              </Box>
              <Box display="flex" gap={2}>
                <Button color="primary" type="submit" disabled={isSubmitting}>Submit</Button>
                {publishModelResult && (
                  <Typography color={publishModelResult?.[0]?.errorMessage ? 'red.main' : 'green.main'}>
                    {publishModelResult?.[0]?.errorMessage ?? 'Success'}
                  </Typography>
                )}
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default PublishForm;
