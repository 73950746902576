import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import dayjs from 'dayjs';
import { Box, Link, Typography } from '@chaos/ui';
import { SimulationTransaction } from '@chaos/types';
import { RouteParams, RoutePath } from 'src/config/routes';
import { CustomIcon } from 'src/components/custom-icon';
import { formatAmount, formatPercent } from '../charts/utils';
import {
  CopyText,
  DataBlock,
  LineBreak,
  ColorBlock,
  GradientText,
  OverviewItem,
  LineSeparator,
} from './building-components';

type OverviewTabProps = {
  simulationResultId: string,
  transaction: SimulationTransaction,
};

const mapMethodInputToArg = (type: string, name?: string) => `${type}${name ? ` ${name}` : ''}`;

export const OverviewTab: FC<OverviewTabProps> = ({ simulationResultId, transaction }) => {
  const methodName = `Function: ${transaction.methodName || transaction.methodId}${
    transaction.inputTypes ? `(${transaction.inputTypes
      .map((type, i) => mapMethodInputToArg(type, transaction.inputNames[i])).join(', ')})` : ''
  }`;

  return (
    <Box display="flex" flexDirection="column">
      <OverviewItem name="Transaction Hash">
        <Typography overflow="hidden" textOverflow="ellipsis">
          {transaction.hash}
        </Typography>
        <CopyText text={transaction.hash} />
      </OverviewItem>
      <OverviewItem name="Status">
        <ColorBlock color={transaction.status === 0 ? 'error.opacity50' : 'success.opacity50'}>
          {transaction.status === 0 ? 'Error' : 'Success'}
        </ColorBlock>
      </OverviewItem>
      <OverviewItem name="Block">
        <Typography>{transaction.blockNumber}</Typography>
      </OverviewItem>
      <OverviewItem name="Timestamp">
        <Typography>{dayjs(transaction.timestamp * 1000).format('MM/DD/YYYY hh:mm')}</Typography>
      </OverviewItem>
      <LineSeparator />
      <OverviewItem name="From">
        <Link
          component={RouterLink}
          to={RoutePath.Simulations.BlockExplorer.Address
            .replace(RouteParams.SimulationResultId, simulationResultId)
            .replace(RouteParams.Address, transaction.from.address.toLowerCase())}
          underline="hover"
        >
          <GradientText>{transaction.from.address}</GradientText>
        </Link>
        <CopyText text={transaction.from.address} />
      </OverviewItem>
      {transaction.to && (
      <OverviewItem name="Interacted With (To)">
        {transaction.to.name && <Typography>Contract</Typography>}
        <Link
          component={RouterLink}
          to={RoutePath.Simulations.BlockExplorer.Address
            .replace(RouteParams.SimulationResultId, simulationResultId)
            .replace(RouteParams.Address, transaction.to.address.toLowerCase())}
          underline="hover"
        >
          <GradientText>{transaction.to.address}</GradientText>
        </Link>
        {transaction.to?.name && (
        <>
          <Typography>{`(${transaction.to.name})`}</Typography>
          <CopyText text={transaction.to.address} />
        </>
        )}
      </OverviewItem>
      )}
      <LineSeparator />
      {transaction.tokenTransfers && transaction.tokenTransfers.length > 0 && (
      <>
        <OverviewItem name="Tokens Transferred" flexDirection="column">
          {transaction.tokenTransfers.map((transfer) => (
            <Box key={transfer.tokenAddress} display="flex" width="100%" gap={1}>
              <Typography>
                From
              </Typography>
              <Link
                component={RouterLink}
                to={RoutePath.Simulations.BlockExplorer.Address
                  .replace(RouteParams.SimulationResultId, simulationResultId)
                  .replace(RouteParams.Address, transfer.from.toLowerCase())}
                underline="hover"
              >
                <GradientText>{transfer.from}</GradientText>
              </Link>
              <Typography>
                To
              </Typography>
              <Link
                component={RouterLink}
                to={RoutePath.Simulations.BlockExplorer.Address
                  .replace(RouteParams.SimulationResultId, simulationResultId)
                  .replace(RouteParams.Address, transfer.to.toLowerCase())}
                underline="hover"
              >
                <GradientText>{transfer.to}</GradientText>
              </Link>
              <Typography>
                For
                {' '}
                {transfer.formattedAmount}
              </Typography>
              {transfer.token && (
                <CustomIcon
                  icon={transfer.token.toLowerCase()}
                  sx={{ '& svg': { width: 24, height: 24 } }}
                />
              )}
              <Typography>{transfer.token}</Typography>
            </Box>
          ))}
        </OverviewItem>
        <LineSeparator />
      </>
      )}
      <OverviewItem name="Value">
        <ColorBlock>
          {transaction.value}
          {' '}
          Ether
        </ColorBlock>
      </OverviewItem>
      <LineSeparator />
      <OverviewItem name="Gas Price">
        <Typography>
          {transaction.gasPrice}
          {' '}
          Ether
        </Typography>
      </OverviewItem>
      <OverviewItem name="Gas Limit & Usage by Txn">
        <Typography>{formatAmount(transaction.gasLimit)}</Typography>
        {transaction.gasUsed && (
        <>
          <LineBreak />
          <Typography>
            {`${formatAmount(transaction.gasUsed)} (${formatPercent(transaction.gasUsed / transaction.gasLimit)})`}
          </Typography>
        </>
        )}
      </OverviewItem>
      <OverviewItem name="Transaction Fee">
        <Typography>
          {transaction.gasUsed ? formatAmount(
            Number(transaction.gasPrice) * transaction.gasUsed,
            { maximumFractionDigits: 10 },
          ) : '0'}
          {' '}
          Ether
        </Typography>
      </OverviewItem>
      <LineSeparator />
      <OverviewItem name="Others" spacing={0.5}>
        <ColorBlock>
          Txn Type:
          {' '}
          {transaction.type}
        </ColorBlock>
        <ColorBlock>
          Position:
          {' '}
          {transaction.transactionIndex}
        </ColorBlock>
      </OverviewItem>
      <OverviewItem name="Input Data">
        <DataBlock>
          <Typography sx={{ mb: 3 }}>{methodName}</Typography>
          {transaction.inputValues?.map((value, i) => (
            <Typography key={i} overflow="hidden" textOverflow="ellipsis">
              {`[${i}]: ${value}`}
            </Typography>
          ))}
        </DataBlock>
      </OverviewItem>
    </Box>
  );
};
