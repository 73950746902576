import { useState, useCallback } from 'react';
import { Theme, useMediaQuery } from '@chaos/ui/theme';

export type GridOrRow = 'grid' | 'row';
export type ResponsiveGridOrRow = GridOrRow | {
  xs: GridOrRow
  sm: GridOrRow
  md: GridOrRow
  lg: GridOrRow
};

export const useGridRowView = (page: string, defaultView: ResponsiveGridOrRow = 'row'):[GridOrRow, (view: GridOrRow) => void] => {
  const isResponsiveMatches = typeof defaultView === 'object';
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));
  const isMedium = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));
  const isLarge = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'));

  const getDisplayDefaultView = useCallback(():GridOrRow => {
    if (isResponsiveMatches) {
      if (isLarge) {
        return defaultView.lg;
      }
      if (isMedium) {
        return defaultView.md;
      }
      if (isSmall) {
        return defaultView.sm;
      }

      return defaultView.xs;
    }
    return defaultView;
  }, [isSmall, isMedium, isLarge, isResponsiveMatches, defaultView]);

  const [currentView, setCurrentView] = useState<GridOrRow>(() => {
    const viewFromLS = localStorage.getItem(`${page}-lists-view`);
    if (viewFromLS) {
      return viewFromLS as GridOrRow;
    }
    return getDisplayDefaultView();
  });

  const setView = useCallback<(view: GridOrRow) => void>((view: GridOrRow) => {
    localStorage.setItem(`${page}-lists-view`, view);
    setCurrentView(view);
  }, [page]);

  return [currentView, setView];
};
