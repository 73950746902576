import { Navigate } from 'react-router-dom';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Box } from '@chaos/ui/box';
import { Paper } from '@chaos/ui/paper';
import { Typography } from '@chaos/ui/typography';
import { Button } from '@chaos/ui/button';
import { CircularProgress } from '@chaos/ui/circular-progress';
import { recordError } from 'src/utils/exceptions';
import { useAppSelector } from 'src/store';
import { required } from 'src/utils/validators';
import { InputField } from 'src/components/fields';
import { COLLECTIONS } from '@chaos/utils';
import { usePageTitle } from 'src/hooks';
import { PageTracker } from 'src/components/page-tracker';

interface IForm {
  firstName: string
  lastName: string
}

const validationSchema = Yup.object().shape({
  firstName: required,
  lastName: required,
});

export const SignUpPage = PageTracker((): JSX.Element => {
  usePageTitle('Sign Up');
  const profile = useAppSelector((state) => state.firebase.profile);
  const firebase = useFirebase();
  const firestore = useFirestore();
  const user = firebase.auth().currentUser;
  const [firstName, lastName] = user?.displayName?.split(' ') || [];
  const initialValues = validationSchema.cast({ firstName, lastName });

  if (profile.registered) {
    return <Navigate to="/" />;
  }

  const submit = async (
    values: IForm,
    setSubmitting: (submitting: boolean) => void,
  ) => {
    try {
      const payload = { ...values, registered: true };
      await firestore.update({ collection: COLLECTIONS.USERS, doc: user?.uid }, payload);
    } catch (error) {
      recordError(error as Error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Paper
      variant="card"
      sx={{
        padding: 5,
      }}
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validateOnBlur
        validateOnChange
        validateOnMount
        validationSchema={validationSchema}
        onSubmit={(values, actions) => submit(values, actions.setSubmitting)}
      >
        {({ isSubmitting, isValid }) => (
          <Form autoComplete="off">
            <Box>
              <Typography
                sx={{
                  paddingBottom: 2,
                }}
                component="h1"
                variant="h2"
              >
                Create your account
              </Typography>

              <Box sx={{ mt: 3 }}>
                <InputField
                  autoFocus
                  name="firstName"
                  label="First Name"
                  placeholder="Enter your first name"
                  fullWidth
                />
              </Box>

              <Box sx={{ mt: 3 }}>
                <InputField
                  name="lastName"
                  label="Last Name"
                  placeholder="Enter your last name"
                  fullWidth
                />
              </Box>

              <Box sx={{ mt: 3 }}>
                <Button
                  size="large"
                  color="primary"
                  type="submit"
                  disabled={!isValid || isSubmitting}
                  startIcon={isSubmitting && <CircularProgress size={20} />}
                  fullWidth
                >
                  Sign In
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Paper>
  );
});
