import React from 'react';
import { Box } from '@chaos/ui';
import { CustomIcon } from '../custom-icon';

interface ShortCardItemIconProps {
  onClick: () => void
}

export const ShortCardItemIcon: React.FC<ShortCardItemIconProps> = ({
  onClick,
}) => (

  <Box onClick={onClick} sx={{ cursor: 'pointer' }}>
    <CustomIcon icon="chevron-right" />
  </Box>
);
