import { FC, ReactNode } from 'react';

import { Box } from '@chaos/ui/box';
import { Typography } from '@chaos/ui/typography';
import { useTheme } from '@chaos/ui';
import { SignInFooter } from '../signin-footer';
import { CustomIcon } from '../custom-icon';
import { SigninBg } from '../signin-bg';

import logo from '../../assets/img/logo.svg';

interface SignUpLayoutProps {
  children?: ReactNode
}

export const SignUpLayout: FC<SignUpLayoutProps> = ({ children }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minHeight: '100%',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <SigninBg />
      <Box sx={{
        flexGrow: 1,
        padding: '120px 40px 0',
        width: '100%',
        maxWidth: 1280,
        margin: '0 auto',
        [theme.breakpoints.down('md')]: {
          pt: '40px',
        },
      }}
      >
        <Box sx={{
          display: 'flex',
          width: '230px',
          [theme.breakpoints.down('md')]: {
            m: '0 auto 40px',
            width: '138px',
          },
        }}
        >
          <img src={logo} style={{ width: '100%' }} alt="Chaos Labs" />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column-reverse',
            },
          }}
        >
          <Box
            sx={{
              maxWidth: 408,
              flexGrow: 1,
              [theme.breakpoints.down('md')]: {
                m: '0 auto',
                width: '100%',
              },
            }}
          >
            <Box
              component="ul"
              sx={{
                listStyle: 'none',
                '& svg': {
                  position: 'absolute',
                  top: -12,
                  left: -12,
                },
              }}
            >
              <Box
                component="li"
                sx={{
                  pl: 5,
                  mt: 5,
                  position: 'relative',
                }}
              >
                <CustomIcon icon="bullet-blue" />
                <Typography paragraph sx={{ marginBottom: 0 }}>
                  <b>Economic Security through Simulations</b>
                </Typography>
                <Typography paragraph sx={{ marginBottom: 0, mt: 1 }}>
                  {/* eslint-disable-next-line */}
                  Leverage the Chaos Labs agent and scenraio catalogue to bootstrap powerful simulations out of the box.
                </Typography>
              </Box>
              <Box
                component="li"
                sx={{
                  pl: 5,
                  mt: 5,
                  position: 'relative',
                }}
              >
                <CustomIcon icon="bullet-blue" />
                <Typography paragraph sx={{ marginBottom: 0 }}>
                  <b>Data Analytics and Risk Scoring</b>
                </Typography>
                <Typography paragraph sx={{ marginBottom: 0, mt: 1 }}>
                  {/* eslint-disable-next-line */}
                  Use the Chaos Labs data analytics tool suite to quickly create custom dashboards, analysis and drive deeper protocol insights.
                </Typography>
              </Box>
              <Box
                component="li"
                sx={{
                  pl: 5,
                  mt: 5,
                  position: 'relative',
                }}
              >
                <CustomIcon icon="bullet-blue" />
                <Typography paragraph sx={{ marginBottom: 0 }}>
                  <b>Interactive Governance Simulation Tooling</b>
                </Typography>
                <Typography paragraph sx={{ marginBottom: 0, mt: 1 }}>
                  {/* eslint-disable-next-line */}
                  Verify, Don't Trust. Attach a simulation test suite to every governance proposal so you can ship with confidence.
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              maxWidth: 480,
              flexGrow: 1,
              [theme.breakpoints.down('md')]: {
                m: '0 auto',
                width: '100%',
              },
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          flexShrink: 0,
          padding: 5,
          maxWidth: 1280,
          display: 'flex',
          justifyContent: 'flex-end',
          margin: '0 auto',
          width: '100%',
          [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
          },
        }}
      >
        <SignInFooter />
      </Box>
    </Box>
  );
};
