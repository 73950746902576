import React, { FC } from 'react';
import { Box } from '@chaos/ui/box';
import { Typography } from '@chaos/ui/typography';
import { Paper } from '@chaos/ui/paper';
import { CustomIcon } from '../custom-icon';

interface OverviewCardProps {
  icon: string
  title: string
  value: string | number | undefined
  valueColor?: string
  subValue?: React.ReactNode | string
}

export const OverviewCard: FC<OverviewCardProps> = (props) => {
  const {
    icon, title, value, valueColor, subValue,
  } = props;
  return (
    <Paper
      variant="card"
      sx={{
        display: 'flex',
        flexDirection: { xs: 'row', sm: 'column' },
        justifyContent: 'space-between',
        alignItems: { xs: 'center', sm: 'flex-start' },
        gap: 1,
        height: '100%',
        minHeight: 120,
        padding: { xs: '24px 16px', sm: '24px' },
      }}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        minWidth: 0,
        color: 'light.main',
        svg: {
          path: {
            fill: 'currentColor',
          },
        },
      }}
      >
        <CustomIcon icon={icon} />
        <Typography
          component="span"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          data-testid="title"
        >
          {title}
        </Typography>
      </Box>

      <Typography
        variant="h1"
        component="h2"
        width="100%"
        overflow="hidden"
        textOverflow="ellipsis"
        sx={{
          color: valueColor || '#fff',
          fontSize: { xs: 24, sm: 32 },
          lineHeight: { xs: '32px', sm: '40px' },
        }}
      >
        {value !== undefined ? value : null}
        {subValue || null}
      </Typography>
    </Paper>
  );
};
