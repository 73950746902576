import { useEffect, useMemo, useState } from 'react';
import {
  Observable, Subscription, throttleTime,
} from 'rxjs';
import {
  collection, doc, query, where,
} from 'firebase/firestore';
import { collectionData } from 'rxfire/firestore';
import { COLLECTIONS } from '@chaos/utils';
import { BlockchainSimulationResult, SimulationProgressState } from '@chaos/types';
import { firestore } from 'src/services/firebase';
import { useCurrentTeam } from './useCurrentTeam';

export const useBlockchainSimulationRepetitions = (
  parentResultId: string | null = null,
  permutationId: string | null = null,
): {
  isLoading: boolean
  results: BlockchainSimulationResult[]
} => {
  const team = useCurrentTeam();
  const [simulationResults, setSimulationResults] = useState<BlockchainSimulationResult[]>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const subs: Subscription[] = [];
    const simResultsCol = collection(firestore, COLLECTIONS.BLOCKCHAIN_SIMULATION_RESULTS);
    const simulationRepetitionsObs = (collectionData(query(
      simResultsCol,
      where('team', '==', doc(collection(firestore, COLLECTIONS.TEAMS), team?.id)),
      where('parentResultId', '==', parentResultId),
      where('permutation.id', '==', permutationId),
      where('state', '==', SimulationProgressState.SimulationFinished),
    ), { idField: 'id' }) as Observable<BlockchainSimulationResult[]>).pipe(throttleTime(50000));
    subs.push(simulationRepetitionsObs.subscribe((data) => {
      setIsLoading(false);
      setSimulationResults(data);
    }));
    return () => subs.forEach((s) => s.unsubscribe());
  }, [parentResultId, permutationId, team?.id]);
  const result = useMemo(
    () => ({ isLoading, results: simulationResults || [] }),
    [isLoading, simulationResults],
  );

  return result;
};
