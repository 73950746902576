import { useEffect, useMemo, useState } from 'react';
import { Market } from '../types';
import { getAllMarketsByProtocol } from '../markets';

type ReturnType = {
  isLoading: boolean
  marketsByProtocol?: Record<string, Market[]>
  markets?: Market[];
  market?: Market;
};

export const useMarketsByProtocol = (protocol?: string, marketId?: string): ReturnType => {
  const [marketsByProtocol, setMarketsByProtocol] = useState<Record<string, Market[]>>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    void (async () => {
      setIsLoading(true);
      const markets = await getAllMarketsByProtocol();
      setMarketsByProtocol(markets);
      setIsLoading(false);
    })();
  }, []);

  const result = useMemo(() => {
    const markets = protocol ? marketsByProtocol?.[protocol.toLowerCase()] : [];
    const market = markets?.find((m) => m.id === marketId);

    return {
      isLoading,
      marketsByProtocol,
      markets,
      market,
    };
  }, [isLoading, marketsByProtocol, protocol, marketId]);

  return result;
};
