import {
  FC, useEffect, useMemo, useState,
} from 'react';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import {
  Alert, Grid, Typography, Box,
} from '@chaos/ui';
import { Accordion } from 'src/components/accordion';
import { SimulationResultInfoCard } from 'src/components/simulation-result-info-card';
import { millisToMinutesAndSeconds } from 'src/utils/formatters';
import { ProgressBar } from '@chaos/ui/progress-bar';
import { ProgressByState } from '../../utils/progress-helper';
import { TagChip } from '../tag-chip';

interface SimulationResultGeneralInfoProps {
  id: string;
  state?: string;
  startBlock?: number,
  currentIteration?: number;
  totalIteration?: number;
  passed: number;
  failed: number;
  durationHeaderName: string;
  durationValue: string;
  runtimeMillis: number;
  rpcUrl?: string;
  description?: string;
  isParamConfigSimulation: boolean;
}

const checkValue = (value: string | undefined): string => ((value && value.length) ? value : '-');

export const SimulationResultGeneralInfo: FC<SimulationResultGeneralInfoProps> = ({
  state,
  startBlock,
  currentIteration,
  totalIteration,
  passed,
  failed,
  durationHeaderName,
  durationValue,
  runtimeMillis,
  rpcUrl,
  description,
  isParamConfigSimulation,
}) => {
  const [showRpcUrl, setShowRpcUrl] = useState(false);
  const [snackbarText, setSnackbarText] = useState('');

  useEffect(() => {
    if (rpcUrl) {
      void axios.get(rpcUrl).then(() => setShowRpcUrl(true));
    }
  }, [rpcUrl]);

  const simulationState = useMemo(() => (() => {
    switch (state) {
      case 'simulation error':
        return (
          <TagChip
            sx={{
              bgcolor: 'error.opacity50', px: 1.5, fz: 12, width: 105,
            }}
            label="Error"
          />
        );
      case 'simulation cancelled':
        return (
          <TagChip
            sx={{
              bgcolor: 'error.opacity50', px: 1.5, fz: 12, width: 105,
            }}
            label="Cancelled"
          />
        );
      case 'simulation finished':
        return (
          <TagChip
            sx={{
              bgcolor: 'success.opacity50', px: 1.5, fz: 12, width: 105,
            }}
            label="Completed"
          />
        );
      default:
        return (
          <Box fontSize={14}>
            <ProgressBar
              text={state || 'pending'}
              barType="small"
              progress={ProgressByState(state || 'initial', currentIteration, totalIteration) || 0.01}
              textPosition="bottom"
              barWidth="105px"
            />
          </Box>
        );
    }
  })(), [currentIteration, state, totalIteration]);

  return (
    <Accordion
      defaultExpanded
      header={(
        <Box sx={{
          display: 'flex', alignItems: 'center',
        }}
        >
          <Typography variant="h2">
            General
          </Typography>
        </Box>
      )}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} pl={0} key="State-InfoCard">
          <SimulationResultInfoCard title="State" value={<Box mt={0.5}>{simulationState}</Box>} />
        </Grid>
        {!isParamConfigSimulation
        && (
          <>
            <Grid item xs={12} md={4} key="Passed-InfoCard">
              <SimulationResultInfoCard title="Passed Assertions" value={passed} icon="check-circle" valueColor="success.main" />
            </Grid>
            <Grid item xs={12} md={4} key="Failed-InfoCard">
              <SimulationResultInfoCard title="Failed Assertions" value={failed} icon="close-circle" valueColor={failed > 0 ? 'error.main' : undefined} />
            </Grid>
          </>
        )}
        <Grid item xs={12} md={4} key="Duration-InfoCard">
          <SimulationResultInfoCard
            titleType={!isParamConfigSimulation ? 'small' : undefined}
            title={durationHeaderName}
            value={checkValue(durationValue)}
            icon="grid-four"
          />
        </Grid>
        <Grid item xs={12} md={4} key="Runtime-InfoCard">
          <SimulationResultInfoCard
            titleType={!isParamConfigSimulation ? 'small' : undefined}
            title="Runtime"
            value={millisToMinutesAndSeconds(runtimeMillis)}
            icon="clock"
          />
        </Grid>
        {startBlock && (
        <Grid item xs={12} md={4} key="StartBlock-InfoCard">
          <SimulationResultInfoCard titleType="small" title="Initial Block Height" value={startBlock} icon="fork" />
        </Grid>
        )}
        {rpcUrl && showRpcUrl && (
          <Grid item xs={12} md={4} key="RPC-InfoCard">
            <SimulationResultInfoCard
              titleType="small"
              title="RPC URL"
              value="Copy Link"
              icon="grid-four"
              theme="gradient"
              tooltip="This link is available for a limited time"
              onClick={() => {
                navigator.clipboard.writeText(rpcUrl).catch((e) => {
                  console.error(`Failed copying ${rpcUrl} to clipboard:`, e);
                });
                setSnackbarText('Copied RPC URL to clipboard');
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} key="Description-InfoCard">
          <SimulationResultInfoCard
            titleType="small"
            title="Description"
            value={checkValue(description)}
          />
        </Grid>
      </Grid>
      <Snackbar
        open={!!snackbarText}
        autoHideDuration={2000}
        onClose={() => setSnackbarText('')}
      >
        <Alert onClose={() => setSnackbarText('')} severity="info">
          {snackbarText}
        </Alert>
      </Snackbar>
    </Accordion>
  );
};
