import { useMemo, useState } from 'react';
import { Box, Typography, Grid } from '@chaos/ui';
import {
  AggregationName,
  BlockchainSimulation,
  BlockchainSimulationResult,
  MetricStatistics,
  ObservationAggregationResult,
  ScriptResource,
} from '@chaos/types';
import { CustomReactSelect } from '@chaos/ui/custom-select';
import MetricScatterPlot from './metric-scatter-plot';
import MetricDistribution from './metric-distribution';
import ConfidenceIntervalOverTime from './confidence-interval-over-time';

type Props = {
  aggregationMethod: AggregationName;
  simulation: BlockchainSimulation;
  metricStatistics: MetricStatistics[];
  confidenceIntervalOverTimeByPath: Record<string, string[]>;
  observationsMetaData: Record<string, ObservationAggregationResult>[];
  repetitionsResults: BlockchainSimulationResult[];
};

const ObservationsDistribution = ({
  metricStatistics,
  simulation,
  aggregationMethod,
  confidenceIntervalOverTimeByPath,
  observationsMetaData,
  repetitionsResults,
}: Props) => {
  const [selectedMetric, setSelectedMetric] = useState<Partial<ScriptResource>>(
    simulation.observers[0],
  );
  const metricOptions = simulation.observers.map((obs) => ({
    label: obs.name || '', value: obs.refId || '',
  }));
  const scatterOptions = metricOptions.filter((x) => x.label !== selectedMetric.name);
  const selectedMetricStatistics = useMemo(
    () => metricStatistics.find((metric) => metric.id === selectedMetric.refId),
    [metricStatistics, selectedMetric.refId],
  );

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h2">Observations Distribution</Typography>
        <CustomReactSelect
          placeholder="Metric (x)"
          defaultValue={metricOptions[0]}
          options={metricOptions}
          onChange={(selected) => {
            const newSelectedMetric = simulation.observers.find((
              obs,
            ) => obs.refId === selected?.value);
            if (!newSelectedMetric) return;
            setSelectedMetric(newSelectedMetric);
          }}
        />
      </Box>
      <Box bgcolor="#30343B" p={3} borderRadius={4}>
        <Grid container spacing={2}>
          {selectedMetricStatistics && (
            <>
              <Grid item xs={12} md={6}>
                <Box borderRadius={4} bgcolor="#22252C" p={3} height={496}>
                  <ConfidenceIntervalOverTime
                    selectedMetricStatistics={selectedMetricStatistics}
                    confidenceIntervalOverTimeByPath={confidenceIntervalOverTimeByPath}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box borderRadius={4} bgcolor="#22252C" p={3} height={496}>
                  <MetricDistribution
                    aggregationMethod={aggregationMethod}
                    selectedMetricStatistics={selectedMetricStatistics}
                    observationsMetaData={observationsMetaData}
                  />
                </Box>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Box borderRadius={4} bgcolor="#22252C" p={3} height={496}>
              <MetricScatterPlot
                aggregationMethod={aggregationMethod}
                simulation={simulation}
                metricOptions={scatterOptions}
                selectedXMetric={selectedMetric}
                observationsMetaData={observationsMetaData}
                repetitionsResults={repetitionsResults}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ObservationsDistribution;
