import React from 'react';
import { Box } from '@chaos/ui';
import { SxProps } from '@mui/material';

interface BlurBackgroundProps {
  sx?: SxProps
}

export const BlurBackground:React.FC<BlurBackgroundProps> = ({
  sx = {},
}) => (
  <Box sx={{
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    ...(sx || {}),
  }}
  >
    <Box
      sx={{
        transition: 'left 0.3s ease',
        position: 'absolute',
        width: '673px',
        height: '673px',
        left: '-141px',
        top: '-25px',
        background: 'radial-gradient(50% 50% at 50% 50%, rgba(15, 208, 196, 0.075) 0%, rgba(15, 208, 196, 0) 100%)',
      }}
    />
    <Box
      sx={{
        transition: 'left 0.3s ease',
        position: 'absolute',
        width: '673px',
        height: '673px',
        left: '-350px',
        bottom: '20px',
        background: 'radial-gradient(26.2% 31.49% at 62.26% 60.64%, rgba(246, 45, 93, 0.08) 0%, rgba(146, 61, 255, 0) 100%)',
      }}
    />
  </Box>
);
