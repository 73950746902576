import { MainLayout } from 'src/components/layouts';
import ModelTriggerForm from './model-trigger-form';

const ModelTriggerPage = () => (
  <MainLayout headerProps={{ pageTitle: 'Run LT_LB Model' }}>
    <ModelTriggerForm />
  </MainLayout>
);

export default ModelTriggerPage;
