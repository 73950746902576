import { FC } from 'react';
import { Link } from '@mui/material';

import { Box } from '@chaos/ui/box';
import { Paper } from '@chaos/ui/paper';
import { Typography } from '@chaos/ui/typography';
import { CustomIcon } from 'src/components/custom-icon';

interface NotionTipProps {
  isOpen: boolean
  onClose: () => void
}

export const NotionTip: FC<NotionTipProps> = ({ isOpen, onClose }) => (isOpen ? (
  <Paper
    variant="card"
    sx={{
      mb: 5,
      display: 'flex',
      gap: 1,
      alignItems: 'center',
    }}
  >
    <CustomIcon icon="light-bulb" sx={{ '& svg path': { fill: '#9B9DA1' } }} />

    <Box>
      <Typography component="span" color="light.main" mr={1}>Notion Tip:</Typography>
      <Typography component="span" mr={0.5}>
        For a deeper understanding of retail trading rewards, please read
      </Typography>
      <Link
        target="_blank"
        href="https://xenophonlabs.com/dydx_trade_rewards.pdf"
        color="inherit"
        variant="caption"
        underline="hover"
        className="gradient-link"
      >
        <Typography
          component="span"
          sx={{
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          Xenophone Labs’ research
        </Typography>
      </Link>
    </Box>

    <Box onClick={onClose} ml="auto">
      <CustomIcon icon="close-small" sx={{ '& svg path': { fill: '#9B9DA1' }, cursor: 'pointer' }} />
    </Box>
  </Paper>
) : <Box />);
