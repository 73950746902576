import {
  Box, CryptoIcon, NumberInput, OptionType, Tooltip,
} from '@chaos/ui';
import { ModelRunResult } from 'src/pages/param-recommendations-tool/data/types';
import { useMemo } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { ReactSelectField } from 'src/components/fields';
import { PublishValue } from './types';
import { getPermutationDropdownOptions } from '../../utils';

type Props = {
  index: number;
  assetResult: ModelRunResult;
  values: PublishValue;
};

export const CUSTOM_PERMUTATION_VALUE = 'custom';

const customPermutationItem: OptionType = {
  label: 'Custom',
  value: CUSTOM_PERMUTATION_VALUE,
  customIcon: 'edit',
};

const PublishAssetRow = ({
  index, assetResult, values,
}: Props) => {
  const { setFieldValue } = useFormikContext();
  const { current } = assetResult;
  const isSingleParam = Object.keys(current.values).length === 1;

  const formKeys = useMemo(() => ({
    recommendedPermutationId: `publishValues.${index}.recommendedPermutationId`,
    derivedValues: `publishValues.${index}.derivedValues`,
    customPermutation: `publishValues.${index}.customPermutation`,
  }), [index]);

  const { dropdownOptions, permutations } = getPermutationDropdownOptions(assetResult);

  const asset = assetResult.metadata.asset_name;

  const showCustomInputs = values.recommendedPermutationId === CUSTOM_PERMUTATION_VALUE;
  const customPermutationInputs = (
    <FieldArray
      name={formKeys.customPermutation}
      render={() => Object.entries(permutations[0]?.values || {}).map(([key, param], idx) => (
        <Box flex={1} key={key}>
          <NumberInput
            label={param.display_name}
            onChange={async (value) => {
              setFieldValue(`${formKeys.customPermutation}.${idx}.value`, value);
            }}
            value={values?.customPermutation?.[idx]?.value || 0}
          />
        </Box>
      ))}
    />
  );

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" gap={2} alignItems="center">
        <Tooltip title={asset} PopperProps={{ style: { zIndex: 9999 } }} placement="top">
          <CryptoIcon key={asset} icon={asset.toLowerCase()} sx={{ mt: 3 }} />
        </Tooltip>
        <Box flex={1}>
          <ReactSelectField
            label="Permutation"
            name={formKeys.recommendedPermutationId}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            options={[customPermutationItem, ...dropdownOptions]}
          />
        </Box>
        {values.derivedValues && (
          <FieldArray
            name={formKeys.derivedValues}
            render={() => (values.derivedValues || []).map((derived, idx) => (
              <Box flex={1} key={derived.paramKey}>
                <NumberInput
                  label={`Derived ${derived.paramKey}`}
                  onChange={async (value) => {
                    setFieldValue(`${formKeys.derivedValues}.${idx}.value`, value);
                  }}
                  value={derived.value}
                />
              </Box>
            ))}
          />
        )}
        {isSingleParam && showCustomInputs && customPermutationInputs}
      </Box>
      {!isSingleParam && showCustomInputs && (
        <Box ml={5} display="flex" gap={2}>
          {customPermutationInputs}
        </Box>
      )}
    </Box>
  );
};

export default PublishAssetRow;
