import { Header, RenderData } from '@chaos/types';
import {
  chainCell,
  dateCell,
  linkCell,
  textCell,
} from '@chaos/ui/chaos-table';
import { getBlockDetailsUrl } from '@chaos/ui/utils/chain-url-mapper';
import { capitalizeFirstLetter } from '@chaos/ui/utils/formatters';
import { BlockMappingTableRowData } from './useBlockMappingTableData';

export const tableHeader: Header[] = [
  {
    renderType: 'TEXT',
    text: 'Chain',
  },
  {
    renderType: 'TEXT',
    text: 'Block Height',
  },
  {
    renderType: 'TEXT',
    text: 'Timestamp',
  },
  {
    renderType: 'TEXT',
    text: 'Date & Time',
  },
];

export const formatResults = (
  data: BlockMappingTableRowData[],
): RenderData[][] => data.map((row) => [
  chainCell(row.chain, row.chain),
  linkCell(
    row.block.toString(),
    getBlockDetailsUrl(capitalizeFirstLetter(row.chain), row.block.toString()),
  ),
  textCell(row.timestamp.toString()),
  dateCell(new Date(row.timestamp * 1000)),
]);
