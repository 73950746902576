import { useEffect, useMemo, useState } from 'react';
import { Observable, Subscription } from 'rxjs';
import {
  collection, doc, limit, query, where,
} from 'firebase/firestore';
import { collectionData } from 'rxfire/firestore';
import { CodeEntity, ScriptResource } from '@chaos/types';
import { COLLECTIONS } from '@chaos/utils';
import { firestore } from 'src/services/firebase';
import { useCurrentTeam } from './useCurrentTeam';

const typeToCollection = (type: CodeEntity) => {
  switch (type) {
    case 'Agent': {
      return COLLECTIONS.BLOCKCHAIN_SIMULATION_AGENTS;
    }
    case 'Assertion': {
      return COLLECTIONS.BLOCKCHAIN_SIMULATION_ASSERTIONS;
    }
    case 'Observer': {
      return COLLECTIONS.BLOCKCHAIN_SIMULATION_OBSERVERS;
    }
    case 'Scenario': {
      return COLLECTIONS.BLOCKCHAIN_SIMULATION_SCENARIOS;
    }
    default: {
      return COLLECTIONS.BLOCKCHAIN_SIMULATION_AGENTS;
    }
  }
};

export const useScriptResourceSearch = (type: CodeEntity, text: string): {
  isLoading: boolean, results: ScriptResource[]
} => {
  const team = useCurrentTeam();
  const [results, setResults] = useState<ScriptResource[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const subs: Subscription[] = [];
    const obs = collectionData(query(
      collection(firestore, typeToCollection(type)),
      where('team', '==', doc(collection(firestore, COLLECTIONS.TEAMS), team?.id)),
      where('name', '>=', text),
      where('name', '<=', `${text}\uf8ff`),
      limit(10),
    ), { idField: 'id' }) as Observable<ScriptResource[]>;
    subs.push(obs.subscribe((data) => {
      setResults(data);
      setIsLoading(false);
    }));

    return () => subs.forEach((s) => s.unsubscribe());
  }, [team?.id, type, text]);
  const result = useMemo(() => ({ isLoading, results }), [isLoading, results]);

  return result;
};
