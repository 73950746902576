import { FormEvent, useCallback, useState } from 'react';
import { Box } from '@chaos/ui/box';
import { Button } from '@chaos/ui/button';
import { TextField } from '@chaos/ui/text-field';
import { MainLayout } from 'src/components/layouts';
import { InputLabel } from '@chaos/ui/input-label';
import { usePageTitle, useFallbackUserAvatar } from 'src/hooks';
import { PageTracker } from 'src/components/page-tracker';
import { FirebaseUploader } from 'src/components/firebase-uploader';
import { useAppSelector } from 'src/store';
import { FileValue } from '@chaos/types';
import { useFirestore } from 'react-redux-firebase';
import { COLLECTIONS } from '@chaos/utils';
import { RoutePath } from 'src/config/routes';
import { chaosSnackbar } from 'src/utils/chaos-snackbar-utils';

const showSuccessToast = () => {
  chaosSnackbar.toast(
    'Profile updated',
    {
      icon: 'check-circle',
    },
  );
};

export const EditProfilePage = PageTracker((): JSX.Element => {
  usePageTitle('Edit Profile');
  const { auth, profile } = useAppSelector((state) => state.firebase);
  const firestore = useFirestore();

  const [firstName, setFirstName] = useState<string>(profile.firstName);
  const [lastName, setLastName] = useState<string>(profile.lastName);

  const handleSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();

    void firestore
      .collection(COLLECTIONS.USERS)
      .doc(auth.uid)
      .update({
        firstName,
        lastName,
      })
      .then(showSuccessToast);
  }, [firestore, auth, firstName, lastName]);

  const onUploadSuccess = useCallback((file:FileValue) => {
    void firestore
      .collection(COLLECTIONS.USERS)
      .doc(auth.uid)
      .update({
        photo: file,
      });
  }, [firestore, auth]);
  const onDeleteSuccess = useCallback(() => {
    void firestore
      .collection(COLLECTIONS.USERS)
      .doc(auth.uid)
      .update({
        photo: firestore.FieldValue.delete(),
      });
  }, [firestore, auth]);

  const fieldMaxWidth = 344;

  const defaultUserAvatar = useFallbackUserAvatar();

  return (
    <MainLayout
      headerProps={{
        pageTitle: 'Edit Profile',
        breadcrumbsLinks: [{
          title: 'Profile',
          href: RoutePath.Profile.Home,
        }],
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{
          display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: { xs: 3, md: 0 }, alignItems: 'center',
        }}
        >
          <FirebaseUploader
            onDeleteSuccess={onDeleteSuccess}
            onUploadSuccess={onUploadSuccess}
            category="img-avatars"
            value={profile && profile.photo}
            fallbackPreviewPath={defaultUserAvatar}
          />

          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: 3, md: 5 },
            width: '100%',
          }}
          >
            <Box sx={{
              maxWidth: { xs: 'inherit', md: fieldMaxWidth },
              width: '100%',
            }}
            >
              <InputLabel>First Name</InputLabel>
              <TextField
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                fullWidth
                placeholder="Enter first name"
              />
            </Box>

            <Box sx={{
              maxWidth: { xs: 'inherit', md: fieldMaxWidth },
              width: '100%',
            }}
            >
              <InputLabel>Last Name</InputLabel>
              <TextField
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                fullWidth
                placeholder="Enter last name"
              />
            </Box>
          </Box>
        </Box>

        <Button
          color="primary"
          type="submit"
          sx={{ mt: { xs: 5, md: 'auto' }, ml: { xs: 0, md: 'auto' }, mb: 5 }}
        >
          Save
        </Button>
      </Box>
    </MainLayout>
  );
});
