import { useCurrentTeam } from 'src/hooks';
import { useEffect, useMemo, useState } from 'react';
import { ModelRunResults } from '../types';
import { getModelRunResults } from '../requests';

type ReturnType = {
  isLoading: boolean
  modelRunResults?: ModelRunResults
};

export const useModelRunResults = (modelRunId: string): ReturnType => {
  const team = useCurrentTeam();
  const [modelRunResults, setModelRunResults] = useState<ModelRunResults>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    void (async () => {
      if (team?.authKey) {
        setIsLoading(true);

        const modelRun = await getModelRunResults(modelRunId, team.authKey);
        setModelRunResults(modelRun);

        setIsLoading(false);
      }
    })();
  }, [team?.authKey, modelRunId]);

  const result = useMemo(() => ({ isLoading, modelRunResults }), [isLoading, modelRunResults]);

  return result;
};
