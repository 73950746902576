import { FC, useEffect, useState } from 'react';
import {
  AggregationMethods,
  ScriptResource,
  SimulationPermutationInfo,
  SimulationPermutationObservationResult,
  SimulationStatistics,
} from '@chaos/types';
import {
  CollapsibleDescription, ScatterChart, ScatterChartRowProps,
} from 'src/components/charts';
import { RoutePath } from 'src/config/routes';
import { parsePermutationInfo } from 'src/hooks/parsePermutationInfo';
import {
  Box, Grid, Paper, Typography, CustomReactSelect,
} from '@chaos/ui';

export interface ParamConfigScatterChartGroupProps {
  permutationInfo: SimulationPermutationInfo[];
  observationsInfo: SimulationPermutationObservationResult[];
  observations: ScriptResource[];
  groupName: string;
  groupDescription?: string;
  simulationStatistics?: SimulationStatistics;
}

export const ParamConfigScatterChartGroup: FC<ParamConfigScatterChartGroupProps> = ({
  permutationInfo,
  observationsInfo,
  observations,
  groupName,
  groupDescription,
  simulationStatistics,
}) => {
  const [scatterData, setScatterData] = useState<ScatterChartRowProps[]>();
  const [selectedArgName, setSelectedArgName] = useState<string>();
  const [selectedAggregation, setSelectedAggregation] = useState<string>(AggregationMethods.Last);
  const configurableParams = permutationInfo[0].args;

  const aggregateDataOptions: Array<{ label: string, value: string }> = [
    {
      value: AggregationMethods.Last,
      label: 'Last',
    },
    {
      value: AggregationMethods.Average,
      label: 'Avg',
    },
    {
      value: AggregationMethods.Max,
      label: 'Max',
    },
    {
      value: AggregationMethods.Min,
      label: 'Min',
    },
    {
      value: AggregationMethods.Sum,
      label: 'Sum',
    },
  ];

  useEffect(() => {
    const initialData: ScatterChartRowProps[] = [];
    if (permutationInfo.length > 0) {
      observations.forEach((observation, i) => {
        initialData[i] = parsePermutationInfo(
          permutationInfo,
          observationsInfo,
          observation,
          permutationInfo[0].args[0].argName,
          AggregationMethods.Last,
          simulationStatistics,
        );
      });
      setScatterData(initialData);
    }
  }, [observations, observationsInfo, permutationInfo, simulationStatistics]);

  return (
    <Box>
      <Paper
        variant="card"
        sx={{
          paddingBottom: '32px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
        }}
      >
        <Box display="flex" flexDirection="row" flexGrow={1} width="100%" paddingBottom="8px">
          <Box display="flex" flexDirection="column">
            <Typography variant="h2" fontWeight="bold">
              {groupName}
            </Typography>
            {groupDescription && <CollapsibleDescription text={groupDescription} />}
          </Box>
          <Box sx={{
            flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline',
          }}
          >
            {configurableParams && (
              <>
                {configurableParams.length > 1 && (
                <Box paddingRight="8px">
                  <CustomReactSelect
                    name={`scatter-chart-select-${groupName}`}
                    placeholder={configurableParams[0].argName}
                    options={configurableParams?.map((option) => (
                      { label: option.argName, value: option.argName }) ?? { })}
                    onChange={(val) => {
                      const data: ScatterChartRowProps[] = [];
                      observations.forEach((observation, i) => {
                        data[i] = parsePermutationInfo(
                          permutationInfo,
                          observationsInfo,
                          observation,
                          val?.value ?? '',
                          selectedAggregation ?? AggregationMethods.Last,
                          simulationStatistics,
                        );
                      });
                      setSelectedArgName(val?.value ?? '');
                      setScatterData(data);
                    }}
                  />
                </Box>
                )}
                <CustomReactSelect
                  name={`scatter-chart-aggregation-${groupName}`}
                  placeholder={aggregateDataOptions[0].label}
                  options={aggregateDataOptions}
                  value={{ label: selectedAggregation, value: selectedAggregation }}
                  onChange={(val) => {
                    const data: ScatterChartRowProps[] = [];
                    observations.forEach((observation, i) => {
                      data[i] = parsePermutationInfo(
                        permutationInfo,
                        observationsInfo,
                        observation,
                        selectedArgName ?? configurableParams[0].argName,
                        val?.value ?? '',
                        simulationStatistics,
                      );
                    });
                    setSelectedAggregation(val?.value ?? '');
                    setScatterData(data);
                  }}
                />
              </>
            )}
          </Box>
        </Box>
        {(scatterData && scatterData.length > 0) && (
        <Grid container spacing={2}>
          {scatterData?.map((chartData) => (
            <Grid item xs={6} key={`scatter-item-${chartData.title ?? chartData.xAxis.label ?? ''}`}>
              <Box bgcolor="background.default" borderRadius={3} flex={1}>
                <Box paddingLeft="24px" paddingTop="24px">
                  <Typography fontSize="24px" fontWeight={600} lineHeight="32px">{chartData?.title}</Typography>
                  <Typography fontSize="16px" fontWeight={400} lineHeight="24px">{chartData?.description}</Typography>
                </Box>
                <Box paddingLeft="12px" sx={{ height: 300, width: '100%' }}>
                  <ScatterChart
                    key={`scatter-chart-${chartData?.title ?? ''}`}
                    onClick={configurableParams.length === 1 ? (datum) => {
                      if (datum?.metadata) {
                        window.open(`${RoutePath.Simulations.Results}/${datum.metadata}`);
                      }
                    } : undefined}
                    {...(chartData)}
                  />
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
        )}
      </Paper>
    </Box>
  );
};
