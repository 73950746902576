import { FC, ReactNode } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { usePageTitle } from 'src/hooks';
import { Box } from '@chaos/ui/box';
import { Link } from '@chaos/ui/link';
import { PageHeaderProps } from '@chaos/ui/page-header';
import { Typography } from '@chaos/ui/typography';
import { IconButton } from '@chaos/ui/icon-button';
import { Breadcrumbs } from '@chaos/ui/breadcrumbs';
import { MainLayout } from './main-layout';
import { CustomIcon } from '../custom-icon';

interface EditPageLayoutProps {
  rootRoute: string
  rootTitle: string
  pageTitle: string
  metaTitle: string
  titleIcon?: string
  pageSubTitle?: string
  actions?: ReactNode[]
  headerProps?: Partial<PageHeaderProps>
}

export const EditPageLayout: FC<EditPageLayoutProps> = ({
  children,
  rootTitle,
  rootRoute,
  pageTitle,
  metaTitle,
  titleIcon,
  actions,
  pageSubTitle,
  headerProps,
}) => {
  usePageTitle(metaTitle);
  const navigate = useNavigate();
  return (
    <MainLayout
      headerProps={{
        pageTitle,
        breadcrumbsLinks: [{
          title: rootTitle,
          href: rootRoute,
        }],
        icon: titleIcon,
        ...(headerProps || {}),
      }}
      topbar={(
        <Box sx={{ mt: 5, width: '100%' }}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<CustomIcon icon="breadcrumbs-arrow" />}
            sx={{ mb: 2 }}
          >
            <Link
              to={rootRoute}
              component={RouterLink}
              color="inherit"
              variant="caption"
              underline="hover"
            >
              {rootTitle}
            </Link>
            <Typography variant="caption">{pageTitle}</Typography>
          </Breadcrumbs>

          <Box mb={3} display="flex" alignItems="center">
            <Box sx={{ display: 'flex', flexDirection: 'column' }} flex={1}>
              <Box display="flex" alignItems="center">
                <IconButton
                  size="large"
                  onClick={() => navigate(rootRoute)}
                  sx={{
                    margin: '-8px 8px -8px -8px',
                  }}
                >
                  <CustomIcon icon="arrow-left" />
                </IconButton>

                {titleIcon && <CustomIcon icon={titleIcon} sx={{ mr: 1 }} />}
                <Typography variant="h1" component="h1">{pageTitle}</Typography>
                <Box sx={{
                  display: 'flex',
                  columnGap: '24px',
                  alignItems: 'center',
                  ml: 'auto',
                }}
                >
                  {actions}
                </Box>
              </Box>
              {pageSubTitle && <Typography ml={6}>{pageSubTitle}</Typography>}
            </Box>
          </Box>
        </Box>
      )}
    >
      {children}
    </MainLayout>
  );
};
