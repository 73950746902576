import { useState } from 'react';
import {
  Box, ChaosTable, CustomIcon, CustomSwitch, Typography,
} from '@chaos/ui';
import { Loader } from '@chaos/ui/loader';
import { useQuery } from '@apollo/client';
import { tableHeader, formatResults, debtCeilintTableHeader } from './borrow-supply-cap-table';
import {
  GET_MARKETS, GET_MARKET_ASSETS, AssetsBreakdownResponse, MarketsResponse,
} from '../aave/graphql-queries';

type Props = {
  isChainMarket?: boolean;
};

export const BorrowSupplyCapTool = ({ isChainMarket }: Props) => {
  const [isValuesInUSD, setIsValuesInUSD] = useState(false);
  const { loading, data } = useQuery<AssetsBreakdownResponse>(GET_MARKET_ASSETS);
  const { loading: isLoadingMarkets, data: marketsData } = useQuery<MarketsResponse>(GET_MARKETS);

  const showDebtCeiling: boolean | undefined = data?.assetsBreakdown
    .some((asset) => asset.isolationModeInformation);

  const udsTokenToggle = (
    <Box display="flex" alignItems="center" gap={2}>
      <Typography>Token</Typography>
      <CustomSwitch
        checked={isValuesInUSD}
        onChange={(e) => setIsValuesInUSD(e.target.checked)}
      />
      <CustomIcon icon="dollar" />
    </Box>
  );

  return (
    <Box height="100%" overflow="auto">
      {(loading || isLoadingMarkets) ? (
        <Box
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Loader />
        </Box>
      ) : (
        data && marketsData && (
          <ChaosTable
            title="Borrow & Supply Caps"
            headers={[...tableHeader, ...(showDebtCeiling ? debtCeilintTableHeader : [])]}
            data={formatResults(
              data.assetsBreakdown,
              marketsData.allMarkets,
              isValuesInUSD,
              isChainMarket,
              showDebtCeiling,
            )}
            pageSize={10}
            isFullHeight
            showSearch
            isInitialSortEnable
            isInitialSortDesc
            serchbarPlaceholder="Search"
            initialSortBy={4}
            headerSuffixComponent={udsTokenToggle}
          />
        )
      )}
    </Box>
  );
};
