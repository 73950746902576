import {
  FC, useEffect, useRef, useState,
} from 'react';
import { Typography } from '@chaos/ui/typography';
import { Box } from '@chaos/ui/box';
import { Axis } from '@chaos/types';
import { IconButton } from '@chaos/ui/icon-button';
import { Icon } from '@chaos/ui/icon';
import { HistogramChart, HistogramChartProps } from 'src/components/charts';
import { ChartFilter } from './chart-filter';
import type { DataItem } from '.';

type HistogramChartRowProps = Partial<Omit<HistogramChartProps, 'legend'>> & {
  data?: DataItem[][]
  title: string
  legend: Array<string>
  description?: string
  xAxis?: Axis
  yAxis?: Axis
  showTokenFilter?: boolean
  hideXAxisLabels?: boolean
};

export const HistogramChartRow: FC<HistogramChartRowProps> = ({
  title, data, legend, description, xAxis, yAxis, showTokenFilter, hideXAxisLabels, ...restProps
}) => {
  const filterRef = useRef<HTMLButtonElement>(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filteredLegend, setFilteredLegend] = useState(legend.map(() => true));

  useEffect(() => setFilteredLegend(legend.map(() => true)), [legend]);

  return (
    <>
      <Box mb={2} display="flex">
        <Box position="relative" flex="1">
          <Typography variant="h2">{title}</Typography>
          {description && (
          <Typography fontWeight={400} mt={1}>
            {description}
          </Typography>
          )}
        </Box>
        {showTokenFilter && (
          <IconButton
            ref={filterRef}
            onClick={() => setIsFilterOpen(true)}
            sx={{
              ml: 'auto', flex: 0, p: 0, height: 40, width: 40, flexBasis: 40,
            }}
          >
            <Icon name="FilterAlt" />
          </IconButton>
        )}
      </Box>
      <Box sx={{ height: 376 }}>
        <HistogramChart
          data={data}
          legend={legend.map((name, i) => ({ name, isVisible: filteredLegend[i] }))}
          xAxis={xAxis}
          yAxis={yAxis}
          hideXAxisLabels={hideXAxisLabels}
          {...restProps}
        />
      </Box>
      <ChartFilter
        isOpen={isFilterOpen}
        anchorEl={filterRef.current}
        legend={legend}
        setFilteredLegend={setFilteredLegend}
        onClose={() => setIsFilterOpen(false)}
      />
    </>
  );
};
