import { useMemo } from 'react';
import {
  useFirestoreConnect,
  useFirestore,
  ReduxFirestoreQuerySetting,
} from 'react-redux-firebase';
import { COLLECTIONS } from '@chaos/utils';
import { DashboardPayload } from '@chaos/types';
import { useAppSelector } from 'src/store';
import { PrivateDashboard, privateDashboardsByClient } from 'src/components/dashboard/privateDashboardsByClient';
import { useCurrentTeam } from './useCurrentTeam';
import { isCollectionLoaded } from './utils';

export const useDashboards = (): {
  isLoading: boolean
  dashboards: DashboardPayload[]
  teamId: string | undefined
  privateDashboards: PrivateDashboard[]
} => {
  const firestore = useFirestore();
  const team = useCurrentTeam();
  const teamKey = team?.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() || '';
  const privateDashboards = privateDashboardsByClient[teamKey] || [];
  const query = useMemo<ReduxFirestoreQuerySetting>(() => ({
    collection: COLLECTIONS.DASHBOARDS,
    where: [
      ['team', '==', firestore.collection(COLLECTIONS.TEAMS).doc(team?.id)],
    ],
  }), [firestore, team]);
  useFirestoreConnect(query);

  const { dashboards, isLoading } = useAppSelector((state) => ({
    dashboards: state.firestore.ordered.dashboards,
    isLoading: !isCollectionLoaded(COLLECTIONS.DASHBOARDS, state),
  }));

  const memoedDashboards = useMemo(
    () => (dashboards ? [...dashboards].sort((a, b) => (a.name > b.name ? 1 : -1)) : []),
    [dashboards],
  );

  return {
    isLoading: isLoading ?? false,
    dashboards: memoedDashboards,
    teamId: team?.id,
    privateDashboards,
  };
};
