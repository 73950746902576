import {
  Box, Grid, Paper, Typography,
} from '@chaos/ui';
import { ShimmerLoader } from 'src/components/shimmer-loader';

export const LoadingObserver = () => (
  <Box
    flex={1}
    bgcolor="background.default"
    sx={{
      padding: '24px 24px 32px',
      borderRadius: '1rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
    }}
  >
    <ShimmerLoader sx={{
      mt: '32px',
      mb: '52px',
      height: '24px',
      width: '330px',
    }}
    />
    <ShimmerLoader sx={{
      height: '300px',
    }}
    />
  </Box>
);

export const LoadingObservers = () => (
  <Paper
    variant="card"
    sx={{
      padding: '24px 24px 24px 32px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
    }}
  >
    <Typography variant="h2" pb={2}>
      Observations
    </Typography>
    <Grid container spacing={2}>
      {Array.from(Array(4)).map((_, index) => (
        <Grid
          item
            // eslint-disable-next-line react/no-array-index-key
          key={index}
          xs={6}
        >
          <LoadingObserver />
        </Grid>
      ))}
    </Grid>
  </Paper>
);
