import {
  FC, ReactNode, useEffect, useState,
} from 'react';
import { Navigate } from 'react-router-dom';
import { isEmpty, isLoaded, useFirebase } from 'react-redux-firebase';
import { has } from 'ramda';
import { RoutePath } from 'src/config/routes';
import { useAppSelector } from 'src/store';
import { useCurrentTeam } from 'src/hooks';
import { haveSubDomain } from 'src/RenderRoutes';
import { LoadingOverlay } from '../loading-overlay';

export enum GuardType {
  Default,
  SignUp,
  SignUpOrg,
}

interface AuthGuardProps {
  children?: ReactNode
  type?: GuardType
}

export const AuthGuard: FC<AuthGuardProps> = ({
  children,
  type = GuardType.Default,
}): JSX.Element => {
  const [isUserAuthorized, setIsUserAuthorized] = useState<boolean>();
  const auth = useAppSelector((state) => state.firebase.auth);
  const isExist = !isEmpty(auth);
  const profile = useAppSelector((state) => state.firebase.profile);
  const team = useCurrentTeam();
  const firebase = useFirebase();

  const isProfileCreated = profile.firstName || profile.lastName;
  const isUserRegistered = profile.registered;
  const user = firebase.auth().currentUser;
  const isUserHasTeams = auth && Boolean(team);

  /**
   * Check a user's custom claims
   * and imediately kick them out
   */
  useEffect(() => {
    const hasAuthorizedClaim = has('authorized');

    if (user) {
      void user.getIdTokenResult(true).then((result) => {
        if (hasAuthorizedClaim(result?.claims)) {
          setIsUserAuthorized(Boolean(result?.claims?.authorized));
        } else {
          setIsUserAuthorized(false);
        }
      });
    } else {
      setIsUserAuthorized(false);
    }
  }, [user]);

  if (isUserAuthorized === undefined || !isLoaded(profile)) {
    return <LoadingOverlay hideNav />;
  }

  if (!isExist) {
    return <Navigate to={RoutePath.SignIn} />;
  }

  // if they are not authorized (whitelisted)
  // send them here.
  if (!isUserAuthorized) {
    return <Navigate to={RoutePath.WaitingList} />;
  }

  // User is signed in but doesn't have profile
  if (type !== GuardType.SignUp && !isUserRegistered) {
    return <Navigate to={RoutePath.CreateProfile} />;
  }

  if (type === GuardType.Default && isProfileCreated && !isUserHasTeams) {
    return <Navigate to={RoutePath.Organization.SignUp} />;
  }

  if (haveSubDomain && isProfileCreated && !isUserHasTeams) {
    return <Navigate to={RoutePath.WaitingTeam} />;
  }

  // eslint-disable-next-line
  return <>{children}</>;
};
