import {
  Box, Paper, Grid, Typography, CryptoIcon, Tooltip,
} from '@chaos/ui';
import { toTitleCase } from '@chaos/ui/utils/formatters';
import { chainMarketProtocols } from 'src/pages/param-recommendations-tool/types';
import dayjs from 'dayjs';
import { useMarketsByProtocol } from 'src/pages/param-recommendations-tool/data/hooks';
import { ShimmerLoader } from '@chaos/ui/shimmer-loader';
import { ModelRunResult } from '../../../data/types';

export type DetailItemProps = {
  title: string;
  value?: string | number;
  children?: JSX.Element;
};

const DetailItem = ({ title, value, children }: DetailItemProps) => (
  <Grid item xs={4}>
    <Box display="flex" flexDirection="column">
      <Typography variant="caption">{title}</Typography>
      {value && <Typography>{value}</Typography>}
      {children}
    </Box>
  </Grid>
);

type DetailsCardProps = {
  startDate: string;
  results: ModelRunResult[];
};

const DetailsCard = ({
  startDate,
  results,
}: DetailsCardProps) => {
  const firstResultMetadata = results[0].metadata;
  const { protocol, market_id: marketId, block_number: blockNumber } = firstResultMetadata || {};

  const { isLoading: isLoadingMarkets, market } = useMarketsByProtocol(protocol, marketId);

  return (
    <Paper variant="card">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h3">Details</Typography>
        </Grid>
        <DetailItem title="Protocol">
          <Box display="flex" gap={1}>
            <CryptoIcon icon={protocol} />
            <Typography>{toTitleCase(protocol)}</Typography>
          </Box>
        </DetailItem>
        <DetailItem title="Market">
          {!isLoadingMarkets ? market && (
            <Box display="flex" gap={1}>
              {chainMarketProtocols.includes(protocol.toLowerCase())
             && <CryptoIcon icon={market.id} />}
              <Typography>{market.name}</Typography>
            </Box>
          ) : <ShimmerLoader sx={{ height: 20, width: 100 }} />}
        </DetailItem>
        <DetailItem title="Assets">
          <Box>
            {results.map(({ metadata: { asset_name: asset } }) => (
              <Tooltip key={asset} title={asset}>
                <CryptoIcon key={asset} icon={asset.toLowerCase()} />
              </Tooltip>
            ))}
          </Box>
        </DetailItem>
        <DetailItem title="Start Time" value={dayjs(startDate).format('DD MMM HH:mm')} />
        <DetailItem title="Block Number" value={blockNumber} />
      </Grid>
    </Paper>
  );
};

export default DetailsCard;
