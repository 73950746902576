export const getIsFullScreenSupported = () => {
  const el = document.body;
  return typeof el.requestFullscreen !== 'undefined'
    // @ts-expect-error not existing
    || typeof el.mozRequestFullScreen !== 'undefined'
    // @ts-expect-error not existing
    || typeof el.webkitRequestFullscreen !== 'undefined'
    // @ts-expect-error not existing
    || typeof el.msRequestFullscreen !== 'undefined'
    || typeof document.exitFullscreen !== 'undefined'
    // @ts-expect-error not existing
    || typeof document.mozCancelFullScreen !== 'undefined'
    // @ts-expect-error not existing
    || typeof document.webkitExitFullscreen !== 'undefined';
};
