import {
  Box, Typography, ECharts, echartsTooltipFormatter,
} from '@chaos/ui';
import type { EChartsOption, SeriesOption } from 'echarts';
import {
  FC, useState, useMemo, memo,
} from 'react';
import { formatAxis } from '@chaos/ui/utils/formatters';
import { Tabs } from '@chaos/ui/tabs';
import { ScriptResource, ChartLegends } from '@chaos/types';
import { useTabsControl } from 'src/hooks/useTabsControl';
import { ChaosChartType, chaosChartTypes } from 'src/components/charts';
import { ObservationChartDataMapper, ObservationMultiChartsDataMapper } from '../observation-chart-data-mapper';
import { ObserverWidgetChartValue } from '../types';

export type GroupChildObserver = {
  id: string, title: string, secondaryAxis?: boolean
};
export interface ObserverWidgetProps {
  id?: string;
  instanceId?: string;
  observation: number[] | number[][];
  title?: string;
  groupChildObservers?: GroupChildObserver[];
  observerConfig?: Partial<ScriptResource>;
  description?: string;
  startBlock?: number;
  displayOrder?: number;
  iterationsRange: [number, number];
  onBlockClick: (iterationNumber: number) => void;
  legends?: ChartLegends;
  currentIteration?: number;
}

export const ObserverWidgetComponent: FC<ObserverWidgetProps> = ({
  observation,
  title,
  observerConfig,
  groupChildObservers,
  description,
  startBlock,
  iterationsRange,
  onBlockClick,
  legends,
}: ObserverWidgetProps) => {
  const {
    dataType,
    chartType: defaultChartType,
  } = (observerConfig || {});

  const showAggregation = dataType === 'event';

  const [isAggregationChecked, setIsAggregationChecked] = useState(false);

  const defaultChartIndex = chaosChartTypes.indexOf(defaultChartType || 'AREA');
  const {
    dataForRender: chartType,
  } = useTabsControl<ChaosChartType>(
    [...chaosChartTypes],
    defaultChartIndex,
  );

  const isMultipleCharts = typeof observation[0] === 'object';

  const chartData = useMemo(() => (isMultipleCharts ? ObservationMultiChartsDataMapper(
    chartType,
    observation as number[][],
    isAggregationChecked,
    iterationsRange,
  ) : ObservationChartDataMapper(
    chartType,
    observation as number[],
    isAggregationChecked,
    iterationsRange,
  )), [chartType, isAggregationChecked, isMultipleCharts, observation, iterationsRange]);

  const chartValues: ObserverWidgetChartValue[][] = useMemo(
    () => (
      isMultipleCharts
        ? chartData.values
        : [chartData.values]
    ) as ObserverWidgetChartValue[][],
    [chartData.values, isMultipleCharts],
  );

  const echartsSeries: EChartsOption['series'] = useMemo(() => chartValues.map(
    (values, index) => {
      const childObserver = groupChildObservers?.[index];
      const isSecondaryAxis = childObserver?.secondaryAxis;
      const isArea = chartType === 'AREA';
      return ({
        data: values.map((value) => value.y),
        type: 'line',
        symbol: 'none',
        yAxisIndex: isSecondaryAxis ? 1 : 0,
        name: childObserver?.title || title || '',
        areaStyle: isArea ? {
          origin: 'auto',
          opacity: 0.2,
        } : undefined,
      }) as SeriesOption;
    },
  ), [chartType, chartValues, groupChildObservers, title]);

  const [minIteration, maxIteration] = iterationsRange;
  const totalIterations = maxIteration - minIteration;
  const xAxisValues: number[] = (new Array(totalIterations + 1).fill(minIteration)).map((
    _,
    idx,
  ) => minIteration + idx);

  const formatAxisValue = (value: number) => {
    const isCurrency = observerConfig?.valueType === 'currency';
    const isPercent = observerConfig?.valueType === 'percent';

    return formatAxis(value, 4, isCurrency ? (observerConfig?.currencyCode || 'USD') : undefined, isPercent, 0);
  };

  const option: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      formatter: echartsTooltipFormatter({
        headerFormatter: (x: number | string) => `Iteration ${x.toString()}${
          startBlock ? ` (Block #${startBlock + Number(x)})` : ''
        }`,
      }),
      padding: 0,
      renderMode: 'auto',
      verticalAlign: 'middle',
    },
    toolbox: {
      itemSize: 12,
      top: 0,
      feature: {
        brush: {
          show: false,
        },
        dataView: {
          show: false,
        },
        dataZoom: {
          yAxisIndex: 'none',
        },
        magicType: {
          show: false,
        },
        restore: {},
        saveAsImage: {
          show: false,
        },
      },
    },
    legend: {
      type: 'scroll',
      data: echartsSeries.map((serie) => `${serie.name || ''}`),
    },
    xAxis: {
      type: 'category',
      axisLabel: {
        interval: 'auto',
      },
      data: xAxisValues,
    },
    grid: {
      top: 45,
      left: legends?.left ? 30 : 10,
      right: legends?.right ? 30 : 0,
    },
    dataZoom: {
      type: 'inside',
      zoomLock: true,
    },
    brush: {
      geoIndex: 'all',
    },
    yAxis: [
      {
        type: 'value',
        name: legends?.left,
        nameLocation: 'middle',
        nameGap: 50,
        nameTextStyle: {
          color: '#fff',
        },
        axisLabel: {
          formatter: formatAxisValue,
        },
      },
      {
        type: 'value',
        name: legends?.right,
        nameLocation: 'middle',
        nameGap: 50,
        nameTextStyle: {
          color: '#fff',
        },
        axisLabel: {
          formatter: formatAxisValue,
        },
      },
    ],
    series: echartsSeries,
  };

  return (
    <Box
      height="100%"
      bgcolor="background.default"
      sx={{
        padding: '24px 24px 32px',
        borderRadius: '1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
      }}
    >
      <Box display="flex" flexDirection="row" flexGrow={1} width="100%" justifyContent="space-between">
        <Box display="flex" flexDirection="column">
          <Typography fontSize="24px" fontWeight={600} lineHeight="32px">{title}</Typography>
          <Typography fontSize="16px" fontWeight={400} lineHeight="24px">{description}</Typography>
        </Box>
        {showAggregation && (
          <Box alignItems="center">
            <Tabs
              tabs={[{
                label: 'Per-Block',
              }, {
                label: 'Aggregated',
              }]}
              value={isAggregationChecked ? 1 : 0}
              onChange={(_, index) => setIsAggregationChecked(index === 1)}
            />
          </Box>
        )}
      </Box>
      <Box width="100%" height={400} py={2}>
        <ECharts
          option={option}
          onClick={onBlockClick}
          zoomable
        />
      </Box>
    </Box>
  );
};

export const ObserverWidget = memo(ObserverWidgetComponent);
