import { useParams } from 'react-router-dom';
import { MainLayout } from 'src/components/layouts';
import { PageTracker } from 'src/components/page-tracker';
import { useBlockchainSimulationResult } from 'src/hooks/useBlockchainSimulationResult';
import { useSimulationTransactions } from 'src/hooks/useSimulationTransactions';
import { RouteParams, RoutePath } from 'src/config/routes';
import { TransactionDetails } from 'src/components/transaction-details';
import { Loader } from 'src/components/loader';

export const BlockExplorerTransactionDetailsPage = PageTracker(() => {
  const {
    simulationResultId, transactionHash,
  } = useParams<{ simulationResultId: string, transactionHash: string }>();
  const { result, simulation } = useBlockchainSimulationResult(simulationResultId!, true);
  const { isLoading, simulationTransactions } = useSimulationTransactions(
    simulationResultId!,
    result?.meta?.connectionKey,
    transactionHash,
  );
  const transaction = simulationTransactions?.[0];

  const breadcrumbsLinks = [{
    title: 'Simulation Results',
    href: RoutePath.Simulations.Results,
  }, {
    title: simulation?.name || '',
    href: RoutePath.Simulations.ResultDetails
      .replace(RouteParams.SimulationResultId, simulationResultId!),
  }, {
    title: 'Block Explorer',
    href: RoutePath.Simulations.BlockExplorer.Home
      .replace(RouteParams.SimulationResultId, simulationResultId!),
  }];

  return (
    <MainLayout headerProps={{ pageTitle: 'Transaction Details', breadcrumbsLinks }}>
      {(isLoading || !transaction) ? <Loader /> : <TransactionDetails transaction={transaction} />}
    </MainLayout>
  );
});
