export enum COLLECTIONS {
  USERS = 'users',
  ROLES = 'roles',
  TEAMS = 'teams',
  TASKS = 'tasks',
  ORGS = 'orgs',
  ORG_MEMBERSHIP = 'org_membership',
  TEAM_MEMBERSHIP = 'team_membership',
  ORGS_ROLES = 'orgs_roles',
  CONTRACTS = 'contracts',
  BLOCKCHAIN_SIMULATIONS = 'simulations_v2',
  BLOCKCHAIN_SIMULATION_RESULTS = 'simulation_results_v2',
  PYTHON_SIMULATION_RESULTS = 'simulation_results',
  SIMULATION_STATISTICS_PROCESSING = 'simulation_statistics_processing',
  BLOCKCHAIN_SIMULATION_ASSERTIONS = 'assertions_v2',
  BLOCKCHAIN_SIMULATION_AGENTS = 'agents_v2',
  BLOCKCHAIN_SIMULATION_SCENARIOS = 'scenarios_v2',
  BLOCKCHAIN_SIMULATION_OBSERVERS = 'observers',
  DASHBOARDS = 'dashboards',
  COMMUNITY_DASHBOARDS = 'community_dashboards',
  SCRIPTS_CONFIG = 'scripts_config',
  SCRIPTS_DEFINITIONS = 'scripts_definitions',
  ASSETS = 'assets',
  ETH_BLOCKS = 'eth_blocks',
  ETH_TRANSACTIONS = 'eth_transactions',
  ETH_EVENTS = 'eth_events',
  SIMULATION_CONTRACTS = 'simulation_contracts',
  SIMULATION_PERMUATATION_RESULTS = 'simulation_permutation_results',
  ECONOMIC_RECOMMENDATION_TRIGGERS = 'economic_recommendation_triggers',
}
