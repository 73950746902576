import { Header, RenderData } from '@chaos/types';
import { ChaosTable, textCell, valueCell } from '@chaos/ui';
import { ModelRunPermutation } from 'src/pages/param-recommendations-tool/data/types';

const headers: Header[] = [{
  renderType: 'TEXT',
  text: 'Parameter',
}, {
  renderType: 'TEXT',
  text: 'Current',
}, {
  renderType: 'TEXT',
  text: 'Recommended',
}];

export type Props = {
  current: ModelRunPermutation;
  recommended?: ModelRunPermutation
};

const RecommendationsTable = ({ current, recommended }: Props) => {
  const data: RenderData[][] = Object.entries(current.values).map(([paramKey, param]) => {
    const recommendedParam = recommended?.values?.[paramKey] || param;

    return [
      textCell(param.display_name),
      valueCell(param.value, param.value_type),
      valueCell(recommendedParam.value, recommendedParam.value_type),
    ];
  });
  return (
    <ChaosTable
      title="Recommendations"
      headers={headers}
      data={data}
      isFilterHidden
      isSettingsHidden
    />
  );
};

export default RecommendationsTable;
