import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Box } from '@chaos/ui/box';
import { Paper } from '@chaos/ui/paper';
import { Typography } from '@chaos/ui/typography';
import { Button } from '@chaos/ui/button';
import { CircularProgress } from '@chaos/ui/circular-progress';
import { recordError } from 'src/utils/exceptions';
import { RoutePath } from 'src/config/routes';
import { required, string } from 'src/utils/validators';
import { EmailsField, InputField, SelectField } from 'src/components/fields';
import { COLLECTIONS } from '@chaos/utils';
import { useCurrentTeam, usePageTitle } from 'src/hooks';
import { PageTracker } from 'src/components/page-tracker';

interface IForm {
  name: string
  invitationEmails?: string[]
  protocolBlockchain: string
  protocolWebsite?: string
}

const validationSchema = Yup.object().shape({
  name: required,
  protocolBlockchain: required,
  // eslint-disable-next-line
  invitationEmails: Yup.array(),
  protocolWebsite: string,
});

const initialValues = validationSchema.cast({});

export const SignUpOrgPage = PageTracker((): JSX.Element => {
  usePageTitle('Sign Up Organization');
  const firebase = useFirebase();
  const firestore = useFirestore();
  const team = useCurrentTeam();
  const uid = firebase.auth().currentUser?.uid;

  const protocols = ['Polygon 1', 'Polygon 2', 'Polygon 3'].map((value) => ({
    value,
    label: value,
  }));

  const navigate = useNavigate();

  useEffect(() => {
    if (team) {
      navigate(RoutePath.Welcome);
    }
  }, [navigate, team]);

  const submit = async (values: IForm, setSubmitting: (submitting: boolean) => void) => {
    try {
      setSubmitting(true);
      await firestore.collection(COLLECTIONS.ORGS).add({
        ...values,
        createdBy: firestore.collection(COLLECTIONS.USERS).doc(uid),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    } catch (error) {
      recordError(error as Error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Paper
      variant="card"
      sx={{
        padding: 5,
      }}
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validateOnBlur
        validateOnChange
        validateOnMount
        validationSchema={validationSchema}
        onSubmit={(values, actions) => submit(values, actions.setSubmitting)}
      >
        {({ isSubmitting, dirty }) => (
          <Form autoComplete="off">
            <Box>
              <Typography
                sx={{
                  paddingBottom: 2,
                }}
                component="h1"
                variant="h2"
              >
                Create your organization
              </Typography>

              <Box sx={{ mt: 3 }}>
                <InputField
                  autoFocus
                  name="name"
                  label="Organization Name"
                  placeholder="Enter your organization name"
                  fullWidth
                />
              </Box>

              <Box sx={{ mt: 3 }}>
                <EmailsField
                  name="invitationEmails"
                  placeholder="Enter your organization members emails"
                  label="Organization Members (Optional)"
                />
              </Box>
              <Box sx={{ mt: 3 }}>
                <SelectField
                  name="protocolBlockchain"
                  placeholder="Select protocol blockchain"
                  label="Protocol Blockchain"
                  options={protocols}
                />
              </Box>

              <Box sx={{ mt: 3 }}>
                <InputField
                  name="protocolWebsite"
                  label="Protocol Website (Optional)"
                  placeholder="Enter protocol website"
                  fullWidth
                />
              </Box>

              <Box sx={{ mt: 3 }}>
                <Button
                  size="large"
                  color="primary"
                  type="submit"
                  disabled={!dirty || isSubmitting}
                  startIcon={isSubmitting && <CircularProgress size={20} />}
                  fullWidth
                >
                  Create Team Account
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Paper>
  );
});
