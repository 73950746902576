import {
  FC, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import type { Header, RowData } from '@chaos/types';
import { Box } from '../box';
import { Button } from '../button';
import { wrappedSymbolToIconSymbol } from '../utils/icon-helper';
import {
  TableFilter, Filter, FilterPicker, FilterRange, FilterText,
} from './table-filter';
import { TableAppliedFilters } from './table-applied-filters';
import { CustomIcon } from '../custom-icon';

const getFilters = (
  data: RowData[],
  headers: Header[],
  currFilters: Record<number, Filter>,
) => {
  const filters: Filter[] = headers
    .filter((header, i) => header.renderType !== 'EMPTY' && data.find(({ renderData: row }) => row[i].text && row[i].text.length > 0))
    .map((header, i) => ({
      fieldName: header.text.toString(),
      fieldIndex: i,
      type: 'text',
      isApplied: currFilters[i]?.isApplied || false,
      suffix: header.suffix,
      prefix: header.prefix,
      isAutocomplete: header.isAutocomplete,
      isDate: header.isDate,
    }));

  data.forEach(({ renderData: row }) => {
    row.forEach(({
      renderType,
      value,
      extraData,
      text,
      token1,
      icons,
      isValueExcluded,
      filterByValue,
      isMarked,
    }, i) => {
      if (i >= filters.length || isValueExcluded) {
        return;
      }

      const filteredValue = filterByValue === undefined ? value : filterByValue;
      const useRange: boolean = filterByValue === undefined ? (value !== undefined && value !== null) : typeof filterByValue === 'number';

      if (extraData?.symbol) {
        const { symbol } = extraData;
        const options = (filters[i] as FilterPicker).options || [];
        const isOptionExist = options.find(({ name }) => name === symbol);
        const newOption = {
          name: symbol,
          cryptoIcon: wrappedSymbolToIconSymbol(symbol),
          isHidden: true,
        };
        const newOptions = isOptionExist ? options : [...options, newOption];

        filters[i] = {
          ...filters[i],
          type: 'options',
          options: newOptions,
        };
      } else if (['CUSTOM', 'TEXT', 'LABELS', 'RECOMMENDATION', 'PIE_CHART', 'PROGRESS'].includes(renderType) && useRange && typeof filteredValue === 'number') {
        const range = (filters[i] as FilterRange).range || [Infinity, 0];
        filters[i] = {
          ...filters[i],
          type: 'range',
          range: [
            filteredValue < range[0] ? Math.floor(filteredValue) : range[0],
            filteredValue > range[1] ? Math.ceil(filteredValue) : range[1],
          ],
        };
      } else if (renderType === 'ICONS') {
        const options = (filters[i] as FilterPicker).options || [];
        filters[i] = {
          ...filters[i],
          type: 'options',
          options: [
            ...options,
            ...([
              ...(extraData?.data || []) as { symbol: string }[],
              ...(icons || []).map((s) => ({ symbol: s })),
            ]).filter(({ symbol }) => !options.find(({ name }) => name === symbol))
              .map(({ symbol }) => ({
                name: symbol,
                cryptoIcon: wrappedSymbolToIconSymbol(symbol),
                isHidden: true,
              })),
          ],
        };
      } else if (['CHIP', 'LABELS'].includes(renderType) || (filters[i] as FilterPicker).isAutocomplete) {
        const options = (filters[i] as FilterPicker).options || [];
        filters[i] = {
          ...filters[i],
          type: 'options',
          options: text && !options.find(({ name }) => name === text) ? [
            ...options,
            {
              name: text,
              cryptoIcon: token1 && wrappedSymbolToIconSymbol(token1),
              isHidden: true,
            },
          ] : options,
        };
      } else if (isMarked !== undefined) {
        const options = (filters[i] as FilterPicker).options || [];
        const isOptionExist = options.some(({ name }) => name === text);
        const newOption = {
          name: text,
          isHidden: true,
        };

        const newOptions = isOptionExist ? options : [...options, newOption];
        filters[i] = {
          ...filters[i],
          type: 'options',
          options: newOptions,
        };
      }
    });
  });

  return filters.filter(
    (filter, i) => filter.type !== 'range' || filter.range[0] !== filter.range[1]
      || !data.find(({ renderData: row }) => row[i].filterByValue || row[i].text),
  );
};

const filterData = (data: RowData[], filterValues: Record<number, Filter>) => data.filter(
  ({ renderData: row }) => row.every(({
    text, value, extraData, icons, isValueExcluded, searchTerm,
  }, i) => {
    if (!filterValues[i] || !filterValues[i].isApplied) {
      return true;
    }

    if (isValueExcluded) return false;

    if (filterValues[i].type === 'text') {
      const searchValue = (searchTerm || text).toString().toLowerCase();
      return searchValue.includes((filterValues[i] as FilterText).value?.toLowerCase() || '');
    }

    if (filterValues[i].type === 'range' && value !== undefined && value !== null) {
      const { range } = filterValues[i] as FilterRange;
      return value >= range[0] && value <= range[1];
    }

    if (filterValues[i].type === 'options') {
      const symbols = Array.from(new Set([
        ...(extraData?.data as { symbol: string }[])
          ?.map(({ symbol }) => symbol) || [], ...(icons || []),
      ]));
      return symbols?.length
        ? (filterValues[i] as FilterPicker).options
          .filter(({ isHidden }) => !isHidden)
          .every(({ name }) => symbols.includes(name))
        : (filterValues[i] as FilterPicker).options.find(
          ({ isHidden, name }) => !isHidden && name === text,
        );
    }

    return false;
  }),
);

interface TableFilterButtonInterface {
  headers: Header[],
  data: RowData[],
  isLoading?: boolean
  onChange: (rows: RowData[]) => void
  setTablePage: (page: number) => void
  showAppliedFilters?: boolean
}

export const TableFilterButton: FC<TableFilterButtonInterface> = ({
  headers, data, isLoading, onChange, setTablePage, showAppliedFilters = true,
}) => {
  const filterButtonRef = useRef<null | HTMLButtonElement>(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filters, setFilters] = useState<Record<number, Filter>>({});
  const onFilter = useCallback((filterValues: Record<number, Filter>) => {
    setFilters(filterValues);
    onChange(filterData(data, filterValues));
  }, [data, onChange]);

  useEffect(() => {
    onChange(filterData(data, filters));
  }, [data, onChange, filters]);

  const tableFilters = useMemo(
    () => getFilters(data, headers, filters),
    // TODO: https://chaoslabs.atlassian.net/browse/DEV-189
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, filters],
  );

  return (
    <Box display="flex" alignItems="center">
      {showAppliedFilters && (
      <TableAppliedFilters
        filters={filters}
        headers={headers}
        removeFilter={(index) => {
          setTablePage(1);
          onFilter({
            ...filters,
            [index]: {
              ...filters[index],
              isApplied: false,
            },
          });
        }}
      />
      )}
      <Button
        color="secondary"
        ref={filterButtonRef}
        disabled={isLoading}
        onClick={(e) => {
          if (e.detail === 0) {
            return;
          }
          setIsFilterOpen(!isFilterOpen);
        }}
        aria-label="Add Filter"
        data-testid="toggle-filter"
        startIcon={
          <CustomIcon icon="filter" />
        }
        endIcon={(
          <Box width={0}>
            { tableFilters.some((f) => f.isApplied) && (
            <Box sx={{
              width: 8, height: 8, borderRadius: 4, backgroundColor: 'turqouise.main',
            }}
            />
            )}
          </Box>
        )}
      >
        Filter
      </Button>
      <TableFilter
        isOpen={isFilterOpen}
        anchorEl={filterButtonRef.current}
        filters={tableFilters}
        onChange={(filterValues) => {
          setTablePage(1);
          onFilter(filterValues);
        }}
        close={() => setIsFilterOpen(false)}
      />
    </Box>
  );
};
