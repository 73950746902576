import { palette } from '@chaos/ui/theme/palette';

export const GRADIENT = 'linear-gradient(333.68deg, #0965F1 -25.37%, #31D8BF 103.14%)';

export const LINEAR_TEXT = {
  background: GRADIENT,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
};

export const HIDDEN_COLLAPSED_STYLES = {
  transition: 'width 0.3s ease, opacity 0.3s ease',
  '._nav-collapsed &': {
    opacity: 0,
    width: 0,
    overflow: 'hidden',
  },
} as const;

export const APP_NAV_STYLES = {
  position: 'relative',
  height: '100%',
  display: 'flex',
  background: palette.darkGrey.main,
  flexDirection: 'column',
  overflowY: 'auto',
  overflowX: 'hidden',
  padding: { xs: '0 16px', md: '0 40px' },
  width: '248px',
  transition: 'width 0.3s ease',
  '._nav-collapsed &': {
    width: 104,
  },
} as const;

export const APP_NAV_CLOSE_BTN_STYLES = {
  borderRadius: 20,
  position: 'absolute',
  bottom: 40,
  right: -20,
  display: 'flex',
  flexShrink: 0,
  boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.16)',
  transform: 'translate3d(0,0,0)',
  zIndex: 4,
  background: palette.darkGrey.main,
  '& .arrow': {
    transformOrigin: '50% 50%',
    transition: 'transform 0.3s ease',
    '._nav-collapsed &': {
      transform: 'rotate(180deg)',
    },
  },
} as const;
