/* eslint-disable no-underscore-dangle */
import {
  FC, useRef, useMemo, memo,
} from 'react';
import {
  VictoryLegend,
  VictoryChart,
  VictoryAxis,
  VictoryGroup,
  VictoryBar,
  VictoryLabel,
} from 'victory';
import { Box } from '@chaos/ui/box';
import { Axis } from '@chaos/types';
import { useElementSize } from '@chaos/ui/hooks/use-element-size';
import { colorScale, formatAmount } from './utils';
import { chartTooltip } from './chart-tooltip';
import type { DataItem } from '.';

interface Legend {
  name: string
  isVisible: boolean
}

export interface HistogramDataItem extends DataItem {
  metaData?: {
    url?: string
  }
}
export interface HistogramChartProps {
  data?: HistogramDataItem[][]
  legend: Legend[]
  xAxis?: Axis
  yAxis?: Axis
  hideXAxisLabels?: boolean
  labels?: ({ datum }: {
    datum: {
      y: number,
      x: number,
      _stack: number,
      xName: string }
  }) => string;
  tickMaximumFractionDigits?: number;
}

const axisStyle = {
  tickLabels: {
    fill: 'rgba(255, 255, 255, .5)',
    fontFamily: 'Archivo, sans-serif',
    lineHeight: '24px',
    fontSize: 16,
  },
  axis: {
    stroke: 'rgba(255, 255, 255, .2)',
    strokeWidth: 1,
    strokeDasharray: 4,
  },
  axisLabel: {
    fill: 'rgba(255, 255, 255, 0.5)',
    fontSize: 16,
    fontFamily: 'Archivo, sans-serif',
  },
  data: {
    fill: '#30D4C1',
  },
};

const HistogramChartComponent: FC<HistogramChartProps> = ({
  data, legend, xAxis, yAxis, hideXAxisLabels, labels, tickMaximumFractionDigits,
}): JSX.Element => {
  const chartRef = useRef<HTMLDivElement>(null);
  const size = useElementSize(chartRef);

  const maxYSize = useMemo(() => data?.reduce((max, bar, i) => {
    if (legend[i].isVisible) {
      return Math.max(bar.reduce((currMax, { y }) => {
        const currSize = formatAmount(Number(y), { currency: yAxis?.currency }).length;
        return currSize > currMax ? currSize : currMax;
      }, 0), max);
    }

    return max;
  }, 0) || 0, [data, legend, yAxis]);

  return (
    <Box
      ref={chartRef}
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        position: 'relative',
      }}
    >
      <VictoryChart
        {...size}
        domainPadding={{ x: 50, y: 30 }}
        padding={{
          top: 50, right: 24, bottom: 50, left: 35 + maxYSize * 8,
        }}
      >
        <VictoryLegend
          orientation="horizontal"
          colorScale={colorScale}
          data={legend.map(({ name, isVisible }) => ({
            name,
            labels: {
              fill: isVisible ? 'rgba(255, 255, 255, .5)' : 'rgba(255, 255, 255, .2)',
              fontFamily: 'Archivo, sans-serif',
            },
          }))}
          key="First Axis"
        />
        <VictoryAxis
          dependentAxis
          style={{
            ...axisStyle,
            grid: {
              stroke: 'rgba(255, 255, 255, .2)',
              strokeWidth: 1,
              strokeDasharray: 4,
            },
          }}
          key="Second Axis"
          tickFormat={(tick) => formatAmount(Number(tick), { currency: yAxis?.currency, notation: 'compact' })}
          animate={{ duration: 1000 }}
          label={yAxis?.label}
          axisLabelComponent={<VictoryLabel dy={-45} />}
        />
        <VictoryAxis
          style={axisStyle}
          key="Third Axis"
          fixLabelOverlap
          tickValues={data
            ?.flat()
            .map((d) => d.x)
            .sort((a, b) => (((hideXAxisLabels || (a || '') > (b || '')) ? 1 : -1)))}
          tickFormat={(x: number) => (hideXAxisLabels ? ''
            : (Number.isFinite(Number(x)) ? formatAmount(
              Number(x),
              { maximumFractionDigits: tickMaximumFractionDigits || 1, notation: 'compact' },
            ) : x))}
          label={xAxis?.label}
          axisLabelComponent={<VictoryLabel dy={-5} />}
        />
        <VictoryGroup offset={(data?.length || 0) < 5 ? 18 : undefined} colorScale={colorScale}>
          {data?.map((bar, i) => {
            const isClickable = bar.some((b) => b.metaData?.url);
            return (
              <VictoryBar
                animate={{ duration: 1000 }}
                style={{
                  data: {
                    cursor: isClickable ? 'pointer' : undefined,
                  },
                }}
                labels={
                  labels || (({ datum }: { datum: { y: number, _stack: number, xName: string } }) => (legend.length > 1 ? `${datum.xName}
                    ${legend[datum._stack - 1]?.name} - ${
                    formatAmount(datum.y, { currency: yAxis?.currency, notation: 'standard' })
                  }` : formatAmount(datum.y, { currency: yAxis?.currency, notation: 'standard' })))
                }
                labelComponent={chartTooltip}
                data={legend[i].isVisible ? bar : bar.map((item) => ({ ...item, y: 0 }))}
                barWidth={10}
                cornerRadius={5}
                key={i}
                events={[
                  {
                    target: 'data',
                    eventHandlers: {
                      onClick: () => ({
                        target: 'data',
                        mutation: (props) => {
                          // eslint-disable-next-line
                          const { url } = (props?.datum?.metaData || {});
                          if (!url) return;
                          window.open(url as string);
                        },
                      }),
                    },
                  },
                ]}
              />
            );
          })}
        </VictoryGroup>
      </VictoryChart>
    </Box>
  );
};

export const HistogramChart = memo(HistogramChartComponent);
