import { gql } from '@apollo/client';

export const GET_MARKET_ASSETS = gql`
  fragment AssetFragment on Asset {
    asset
    market
    borrowCap
    supplyCap
    totalBorrow
    totalSupply
    price
    marketId
    isolationModeInformation {
      isIsolated
      debtCeilingUsd
      isolationModeTotalDebtUsd
    }
  }

  query MarketAssetsBreakdown {
    assetsBreakdown {
      ...AssetFragment
    }
  }
`;

export const GET_MARKETS = gql`
  fragment MarketData on MarketData {
    id
    name
  }

  query AllMarkets {
    allMarkets {
      ...MarketData
    }
  }
`;

export const RISK_EXPLORER_LIQUIDATIONS = gql`
  query RiskExplorerLiquidatedWallets(
    $query: [RiskExplorerQuery!]!
    $becameEligibleOnly: Boolean
  ) {
    riskExplorerLiquidatedWallets(
      query: $query
      becameEligibleOnly: $becameEligibleOnly
    ) {
      address
      totalLiquidityUsd
      totalCollateralUsd
      totalBorrowsUsd
      minRiskStatus
      chainHealth {
        chain
        healthFactor
      }
      potentialBadDebtUsd
      positions
      health
      becameAtRisk
      valueEligibleForLiquidation
    }
  }
`;
