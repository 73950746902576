import { CSSProperties } from 'react';

import { Box } from '@chaos/ui/box';
import { useMediaQuery, Theme } from '@chaos/ui/theme';

import ellipsesBg from 'src/assets/img/ellipses.svg';
import companyBgPattern from 'src/assets/img/company-bg-pattern.svg';
import companyBgPattern1 from 'src/assets/img/company-bg-pattern-1.svg';
import jetBgPattern from 'src/assets/img/jet-bg-pattern.svg';
import jetBgPattern1 from 'src/assets/img/jet-bg-pattern1.svg';
import { ReactComponent as ChaosLabsLogoSvg } from 'src/assets/img/chaoslabs-logo.svg';
import { ReactComponent as BenqiLogoSvg } from 'src/assets/img/benqi-logo.svg';
import { ReactComponent as AaveLogoSvg } from 'src/assets/img/crypto/aave.svg';
import { ReactComponent as MakerLogoSvg } from 'src/assets/img/maker-logo.svg';
import { ReactComponent as ChainlinkLogoSvg } from 'src/assets/img/chainlink-logo.svg';
import { ReactComponent as SpectralLogoSvg } from 'src/assets/img/spectral-logo.svg';
import { ReactComponent as ArdanaLogoSvg } from 'src/assets/img/ardana-logo.svg';
import { ReactComponent as PangolinLogoSvg } from 'src/assets/img/pangolin-logo.svg';
import { ReactComponent as DydxLogoSvg } from 'src/assets/img/dydx-logo.svg';
import { ReactComponent as JetProtocolLogoSvg } from 'src/assets/img/jetprotocol-logo.svg';
import { ReactComponent as MoonwellLogoSvg } from 'src/assets/img/moonwell-logo.svg';
// import sushiLogoBg from "src/assets/img/sushi-logo.svg"

const bgCircleLeft = {
  position: 'absolute',
  borderRadius: '50%',
  transform: 'rotate(24.74deg)',
  zIndex: -1,
  width: 766,
  height: 766,
  background:
    'radial-gradient(50% 50% at 50% 50%, rgba(15,208,196,0.15) 0%, rgba(15,208,196,0) 100%)',
  top: 57,
  right: '50%',
  marginRight: '193px',
} as const;

const bgCircleTop = {
  position: 'absolute',
  borderRadius: '50%',
  transform: 'rotate(24.74deg)',
  zIndex: -1,
  width: 744,
  height: 744,
  background:
    'radial-gradient(50% 50% at 50% 50%, rgba(15,127,208,0.15) 0%, rgba(15,127,208,0) 100%)',
  top: -545,
  left: '50%',
  marginLeft: '-190px',
} as const;

const bgCircleRight = {
  position: 'absolute',
  borderRadius: '50%',
  transform: 'rotate(24.74deg)',
  zIndex: -1,
  width: 818,
  height: 818,
  background:
    'radial-gradient(50% 50% at 50% 50%, rgba(208,15,119,0.2) 0%, rgba(208,15,119,0) 100%)',
  bottom: -574,
  left: '50%',
  marginLeft: '61px',
} as const;

const ellipses = {
  position: 'absolute',
  zIndex: -1,
  width: 938,
  height: 900,
  backgroundImage: `url(${ellipsesBg})`,
} as const;

const bgPattern = {
  position: 'absolute',
  zIndex: -1,
  width: '590px',
  height: '100%',
  top: 0,
  left: 0,
  backgroundImage: `url(${companyBgPattern1})`,
  backgroundRepeat: 'no-repeat',
  opacity: 0.3,
  '@media (min-width: 900px)': {
    opacity: 1,
    backgroundImage: `url(${companyBgPattern})`,
  },
} as const;

const jetprotocolBgPattern = {
  position: 'absolute',
  zIndex: -1,
  width: '590px',
  height: '100%',
  top: 0,
  left: 0,
  backgroundImage: `url(${jetBgPattern1})`,
  backgroundRepeat: 'no-repeat',
  opacity: 0.3,
  '@media (min-width: 900px)': {
    opacity: 1,
    backgroundImage: `url(${jetBgPattern})`,
  },
} as const;

const chaosLabs = {
  position: 'absolute',
  zIndex: -1,
  marginLeft: '264px',
  width: 460,
  height: 470,
  left: '50%',
  top: '50%',
  transform: 'translateY(-50%)',
} as const;

const chaosLabsMobile = {
  position: 'absolute',
  zIndex: -1,
  width: 'calc(160px + 5vmin)',
  height: 'calc(160px + 5vmin)',
  left: '50%',
  transform: 'translateX(-50%)',
} as const;

const maker = {
  position: 'absolute',
  zIndex: -1,
  marginRight: '386px',
  width: 168,
  height: 160,
  right: '50%',
  top: '50%',
  transform: 'translateY(-50%)',
} as const;

const makerMobile = {
  position: 'absolute',
  zIndex: -1,
  width: 'calc(62px + 10vmin)',
  height: 'calc(59px + 10vmin)',
  left: '50%',
  bottom: 110,
  transform: 'translateX(-50%)',
} as const;

const chainlink = {
  position: 'absolute',
  zIndex: -1,
  marginRight: '406px',
  width: 160,
  height: 202,
  right: '50%',
  top: '50%',
  transform: 'translateY(-50%)',
} as const;

const chainlinkMobile = {
  position: 'absolute',
  zIndex: -1,
  width: 'calc(68px + 10vmin)',
  height: 'calc(86px + 10vmin)',
  left: '50%',
  bottom: 120,
  transform: 'translateX(-50%)',
} as const;

const spectral = {
  position: 'absolute',
  zIndex: -1,
  marginRight: '406px',
  width: 155,
  height: 224,
  right: '50%',
  top: '50%',
  transform: 'translateY(-50%)',
} as const;

const spectralMobile = {
  position: 'absolute',
  zIndex: -1,
  width: 'calc(61px + 10vmin)',
  height: 'calc(88px + 10vmin)',
  left: '50%',
  bottom: 120,
  transform: 'translateX(-50%)',
} as const;

const ardana = {
  position: 'absolute',
  zIndex: -1,
  marginRight: '390px',
  width: 184,
  height: 206,
  right: '50%',
  top: '50%',
  transform: 'translateY(-50%)',
} as const;

const ardanaMobile = {
  position: 'absolute',
  zIndex: -1,
  width: 'calc(100px + 5vmin)',
  height: 'calc(100px + 5vmin)',
  left: '50%',
  bottom: 120,
  transform: 'translateX(-50%)',
} as const;

const benqi = {
  position: 'absolute',
  zIndex: -1,
  marginRight: '406px',
  width: 160,
  height: 200,
  right: '50%',
  top: '50%',
  transform: 'translateY(-50%)',
} as const;

const benqiMobile = {
  position: 'absolute',
  zIndex: -1,
  width: 'calc(66px + 10vmin)',
  height: 'calc(83px + 10vmin)',
  left: '50%',
  bottom: 140,
  transform: 'translateX(-50%)',
} as const;

const dydx = {
  position: 'absolute',
  zIndex: -1,
  marginRight: '370px',
  width: 227,
  height: 71,
  right: '50%',
  top: '50%',
  transform: 'translateY(-50%)',
} as const;

const dydxMobile = {
  position: 'absolute',
  zIndex: -1,
  width: 'calc(99px + 10vmin)',
  height: 'calc(31px + 10vmin)',
  left: '50%',
  bottom: 140,
  transform: 'translateX(-50%)',
} as const;

const pangolin = {
  position: 'absolute',
  zIndex: -1,
  marginRight: '380px',
  width: 240,
  height: 191,
  right: '50%',
  bottom: 120,
  top: '50%',
  transform: 'translateY(-50%)',
} as const;

const pangolinMobile = {
  position: 'absolute',
  zIndex: -1,
  width: 'calc(88px + 10vmin)',
  height: 'calc(69px + 10vmin)',
  left: '50%',
  bottom: 100,
  transform: 'translateX(-50%)',
} as const;

const jet = {
  position: 'absolute',
  zIndex: -1,
  marginRight: '370px',
  width: 240,
  height: 191,
  right: '50%',
  bottom: 120,
  top: '50%',
  transform: 'translateY(-50%)',
} as const;

const jetMobile = {
  position: 'absolute',
  zIndex: -1,
  width: 'calc(114px + 5vmin)',
  height: 'calc(96px + 5vmin)',
  left: '50%',
  bottom: 100,
  transform: 'translateX(-50%)',
} as const;

const moonwell = {
  position: 'absolute',
  zIndex: -1,
  marginRight: '340px',
  width: 328,
  height: 91,
  right: '50%',
  bottom: 120,
  top: '50%',
  transform: 'translateY(-50%)',
} as const;

const moonwellMobile = {
  position: 'absolute',
  zIndex: -1,
  width: 'calc(150px + 8vmin)',
  height: 'calc(91px + 7vmin)',
  left: '50%',
  bottom: 110,
  transform: 'translateX(-50%)',
} as const;

const aave = {
  position: 'absolute',
  zIndex: -1,
  marginRight: '340px',
  width: 328,
  height: 91,
  right: '50%',
  bottom: 120,
  top: '50%',
  transform: 'translateY(-50%)',
} as const;

const aaveMobile = {
  position: 'absolute',
  zIndex: -1,
  width: 'calc(150px + 8vmin)',
  height: 'calc(91px + 7vmin)',
  left: '50%',
  bottom: 110,
  transform: 'translateX(-50%)',
} as const;

// const sushi = {
//   position: "absolute",
//   zIndex: 1,
//   marginRight: "406px",
//   width: 164,
//   height: 208,
//   right: "50%",
//   top: "50%",
//   transform: "translateY(-50%)",
//   backgroundImage: `url(${sushiLogoBg})`,
// } as const

// const sushiMobile = {
//   position: "absolute",
//   zIndex: -1,
//   width: 180,
//   height: 180,
//   left: "50%",
// transform: "translateX(-50%)",
//   backgroundImage: `url(${sushiLogoBgMobile})`,
// } as const

type CompanyType =
  | 'aave'
  | 'ardana'
  | 'benqi'
  | 'chainlink'
  | 'dydx'
  | 'jet'
  | 'maker'
  | 'moonwell'
  | 'pangolin'
  | 'spectral'
  | 'sushi';

const companyLogos: Partial<
Record<CompanyType, React.FunctionComponent<React.SVGProps<SVGSVGElement>>>
> = {
  maker: MakerLogoSvg,
  benqi: BenqiLogoSvg,
  chainlink: ChainlinkLogoSvg,
  spectral: SpectralLogoSvg,
  aave: AaveLogoSvg,
  ardana: ArdanaLogoSvg,
  pangolin: PangolinLogoSvg,
  dydx: DydxLogoSvg,
  jet: JetProtocolLogoSvg,
  moonwell: MoonwellLogoSvg,
};

const logoStyles: Partial<Record<CompanyType, CSSProperties>> = {
  benqi,
  maker,
  chainlink,
  spectral,
  aave,
  ardana,
  pangolin,
  dydx,
  jet,
  moonwell,
};

const logoStylesMobile: Partial<Record<CompanyType, CSSProperties>> = {
  benqi: benqiMobile,
  maker: makerMobile,
  aave: aaveMobile,
  chainlink: chainlinkMobile,
  spectral: spectralMobile,
  ardana: ardanaMobile,
  pangolin: pangolinMobile,
  dydx: dydxMobile,
  jet: jetMobile,
  moonwell: moonwellMobile,
};

const companyBgPatterns: Partial<Record<CompanyType, CSSProperties>> = {
  benqi: ellipses,
  jet: jetprotocolBgPattern,
};

export const AuthCompanyBg = (): JSX.Element => {
  const companyName = window.location.host.split('.')[0] as CompanyType;
  const mdAndUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));
  const CompanyLogo = companyName && companyLogos[companyName];
  const companyPattern = companyName && companyBgPatterns[companyName];

  return (
    <>
      <Box component="i" sx={bgCircleLeft} />
      <Box component="i" sx={bgCircleTop} />
      <Box component="i" sx={bgCircleRight} />

      <Box component="i" sx={companyPattern || bgPattern} />
      <ChaosLabsLogoSvg style={mdAndUp ? chaosLabs : chaosLabsMobile} />
      {CompanyLogo && (
        <CompanyLogo
          style={
            mdAndUp ? logoStyles[companyName] : logoStylesMobile[companyName]
          }
        />
      )}
    </>
  );
};
