import React from 'react';
import type {} from '@mui/x-date-pickers-pro/themeAugmentation';
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
  useTheme as useThemeMUI,
} from '@mui/material/styles';
import { createBreakpoints } from '@mui/system';
import { Theme, useMediaQuery } from '@mui/material';
import { useAppColorScheme } from '../hooks/use-app-color-scheme';
import { colors } from './colors';
import { buttonStyles } from './buttons';
import { inputStyles } from './inputs';
import { palette } from './palette';

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    card: true,
    widget: true,
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColoOverrides {
    error: true
  }
}

interface ThemeProviderProps {
  children?: React.ReactNode
}

export const ThemeProvider = ({
  children,
}: ThemeProviderProps): JSX.Element => {
  const { colorScheme } = useAppColorScheme();
  const breakpoints = createBreakpoints({});
  const mobileBreakpoint = breakpoints.down('sm');

  const theme = createTheme({
    palette: {
      mode: colorScheme,
      ...colors,
      ...palette,
    },
    typography: {
      fontFamily: ['Archivo', 'sans-serif'].join(','),
      fontWeightLight: 400,
      fontWeightRegular: 500,
      fontWeightBold: 600,
      h1: {
        fontSize: 32,
        lineHeight: '40px',
        fontWeight: 600,
        [mobileBreakpoint]: {
          fontSize: 24,
          lineHeight: '32px',
          fontWeight: 600,
        },
      },
      h2: {
        fontSize: 24,
        lineHeight: '32px',
        fontWeight: 600,
      },
      h3: {
        fontSize: 24,
        lineHeight: '32px',
        fontWeight: 700,
      },
      h4: {
        fontSize: 16,
        lineHeight: '24px',
        fontWeight: 700,
      },
      h5: {
        fontSize: 14,
        lineHeight: '24px',
        fontWeight: 600,
      },
      h6: {
        fontSize: 14,
        lineHeight: '24px',
        fontWeight: 400,
      },
      caption: {
        display: 'inline-block',
        verticalAlign: 'top',
        fontSize: 14,
        lineHeight: '24px',
        fontWeight: 400,
        color: colors.light.main,
      },
      body1: {
        fontSize: 14,
        lineHeight: '24px',
        fontWeight: 400,
      },
    },
    components: {
      MuiAccordion: {
        styleOverrides: {
          root: () => ({
            backgroundColor: palette.grey.main,
            transition: 'all 0.1s ease',
            margin: '0 !important',
            fontSize: 14,
            lineHeight: '24px',
            border: 'none',
            '&:before': {
              display: 'none',
            },
            '&:hover': {
              background: palette.grey.hover,
            },
          }),
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: () => ({
            border: 'none',
            minHeight: '48px !important',
            display: 'flex',
            flexDirection: 'row-reverse',
          }),
          content: () => ({
            margin: '0 !important',
          }),
          expandIconWrapper: () => ({
            transform: 'rotate(-90deg)',
            marginLeft: -8,
            marginRight: 8,
            '&.Mui-expanded': {
              transform: 'rotate(0)',
            },
          }),
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: () => ({
            backgroundColor: palette.darkGrey.main,
            padding: 16,
          }),
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: () => ({
            '& path': {
              fill: 'currentColor',
            },
          }),
        },
      },
      MuiPaper: {
        variants: [
          {
            props: { variant: 'card' },
            style: {
              padding: 24,
              background: palette.darkGrey.main,
              borderRadius: 8,
              [mobileBreakpoint]: {
                padding: 12,
              },
            },
          },
          {
            props: { variant: 'widget' },
            style: {
              padding: 24,
              background: palette.black.main,
              color: palette.white.main,
              borderRadius: 8,
              [mobileBreakpoint]: {
                padding: 12,
              },
            },
          },
        ],
        defaultProps: {
          elevation: 0,
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            border: '1px solid #fff',
          },
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            backgroundColor: 'rgba(255,255,255,0.12)',
          },
          bar: {
            borderRadius: 8,
            backgroundColor: '#fff',
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            color: palette.white.main,
            whiteSpace: 'nowrap',
          },
        },
      },
      MuiButton: {
        defaultProps: {
          color: 'inherit',
          variant: 'contained',
          disableElevation: true,
          size: 'medium',
        },
        variants: [
          {
            props: { color: 'primary' },
            style: buttonStyles.primary,
          },
          {
            props: { color: 'secondary' },
            style: buttonStyles.secondary,
          },
          {
            props: { color: 'error' },
            style: buttonStyles.error,
          },
          {
            props: { variant: 'text', color: 'primary' },
            style: buttonStyles.ghost,
          },
        ],
        styleOverrides: {
          root: {
            fontSize: '14px',
            lineHeight: '24px',
            fontWeight: 600,
            borderRadius: 8,
            border: 'none',
            color: palette.white.main,
            minWidth: 0,
            textTransform: 'none',
          },
          contained: buttonStyles.ghost,
          outlined: buttonStyles.secondary,
          sizeSmall: {
            padding: '4px 8px',
            borderRadius: 4,
          },
          sizeMedium: {
            padding: '12px 24px',
          },
          // Styleguide doens't have this size, making it match medium size
          sizeLarge: {
            padding: '12px 24px',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: ({ ownerState }) => {
            let chipColors = {};
            switch (ownerState.color) {
              case 'primary':
              case 'info':
                chipColors = {
                  backgroundColor: palette.blue.main,
                  color: palette.white.main,
                };
                break;
              case 'warning':
                chipColors = {
                  backgroundColor: palette.orange.main,
                };
                break;
              case 'error':
                chipColors = {
                  backgroundColor: palette.red.main,
                };
                break;
              default:
                break;
            }

            return {
              ...chipColors,
            };
          },
          sizeSmall: () => ({
            borderRadius: 4,
            fontSize: 12,
            lineHeight: '16px',
            height: '20px',
          }),
          labelSmall: () => ({
            padding: '2px 4px',
          }),
          icon: () => ({
            marginRight: -2,
            marginLeft: 8,
          }),
          clickable: () => ({
            ':hover': {
              backgroundColor: palette.blue.main,
            },
          }),
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            width: 600,
            maxWidth: '100%',
            background: palette.black.main,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            padding: 12,
            borderRadius: 8,
            color: 'currentcolor',
            ...(ownerState.color === 'primary' && buttonStyles.primary),
            ...(ownerState.color === 'secondary' && buttonStyles.secondary),
            ...(ownerState.color === 'error' && buttonStyles.error),
            ...(ownerState.color === 'default' && buttonStyles.ghost),
          }),
          sizeSmall: {
            padding: 4,
            borderRadius: 4,
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            transition: 'color .3s ease',
            color: 'currentColor',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize: 14,
          },
        },
        defaultProps: {},
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: '12px',
            lineHeight: '16px',
            color: colors.light.main,
            marginBottom: 8,
            '&.Mui-focused': {
              color: 'currentColor',
            },
            '&.Mui-error': {
              color: palette.red.main,
            },
            '&.Mui-disabled': inputStyles.disabled,
          },
        },
        defaultProps: {
          shrink: true,
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontSize: '12px',
            lineHeight: '16px',
            margin: '8px 0 0',
            color: palette.almostWhite.main,
            '&.Mui-disabled': inputStyles.disabled,
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            display: 'flex',
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: () => ({
            fontWeight: 400,
            marginLeft: 0,
            '&:hover .MuiCheckbox-root': {
              color: colors.light.main,
            },
          }),
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            '&&': {
              transform: 'none',
              position: 'relative',
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            color: 'currentcolor',
            '&.Mui-checked': {
              color: 'currentcolor',
            },
            ...ownerState.size === 'small' && {
              padding: 0,
            },
          }),
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            padding: 0,
            color: '#fff',
            width: 24,
            transition: 'color 0.3s ease',
            '&:hover': {
              color: colors.light.main,
            },
            '&.Mui-checked': {
              color: '#fff',
            },
            '&.Mui-checked:hover': {
              color: colors.light.main,
            },
            '&.Mui-disabled': {
              opacity: 0.1,
              cursor: 'default',
            },
            '& svg': {
              '& path': {
                fill: 'currentColor',
              },
            },
            '& ~ .MuiFormControlLabel-label': {
              paddingLeft: 16,
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            WebkitTextFillColor: palette.white.main,
            backgroundColor: palette.inputs.main,
            color: palette.almostWhite.main,
            borderRadius: 8,
            padding: 0,
            transition:
              'border-color 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease',
            '&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline':
              {
                borderColor: palette.inputs.hover,
              },
            '&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error)': {
              backgroundColor: palette.inputs.hover,
            },
            '&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-input:-webkit-autofill':
              {
                WebkitTextFillColor: palette.almostWhite.main,
              },
            '&.Mui-focused': {
              backgroundColor: palette.inputs.active,
              borderColor: palette.inputs.active,
              color: palette.white.main,
            },
            '&.Mui-error': {
              backgroundColor: palette.red.opacity10,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderWidth: 0,
            },
            '&.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
              backgroundColor: palette.inputs.active,
            },
            '&.MuiInputBase-adornedStart': {
              paddingLeft: 16,
            },
            '&.MuiInputBase-adornedEnd': {
              paddingRight: 16,
            },
            '& .MuiInputAdornment-root': {
              marginRight: 8,
            },
            '&.Mui-disabled': inputStyles.disabled,
            '& fieldset': {
              display: 'none',
            },
          },
          input({ ownerState }) {
            return {
              height: 'auto',
              lineHeight: '24px',
              fontWeight: 400,
              paddingTop: 12,
              paddingBottom: 12,
              paddingLeft: 16,
              paddingRight: 16,
              ...(ownerState?.startAdornment && ({ paddingLeft: 0 })),
              ...(ownerState?.endAdornment && ({ paddingRight: 0 })),
              '&:-webkit-autofill': {
                WebkitBoxShadow: `0 0 0 24px ${colors.background.default} inset`,
                WebkitTextFillColor: palette.almostWhite.main,
              },

              '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                WebkitAppearance: 'none',
                margin: 0,
              },

              '&[type=number]': {
                MozAppearance: 'textfield,',
              },
            };
          },
          notchedOutline: {
            borderColor: palette.inputs.main,
            hover: {
              borderColor: palette.inputs.hover,
            },
            '&:disabled': {
              borderWidth: 0,
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
              padding: 4,
              width: '100%',
            },
            '&:disabled': {
              opacity: 0.3,
            },
          },
          paper: {
            background: palette.lightGrey.main,
            boxShadow: '0px 16px 32px rgba(0, 0, 0, 0.08)',
            borderRadius: 8,
          },
          listbox: {
            '& .MuiAutocomplete-option': {
              padding: '12px 24px',
            },
          },
          inputRoot: {
            padding: '8px 12px',
          },
          tag: {
            margin: 4,
            maxWidth: 'calc(100% - 8px)',
            '& .MuiChip-label': {
              padding: '0 6px 0 12px',
            },
            '& .MuiChip-deleteIcon': {
              margin: '0 4px 0 0',
              width: 24,
              height: 24,
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            width: 24,
            height: 24,
            position: 'absolute',
            top: 12,
            right: 16,
            pointerEvents: 'none',
          },
          select: {
            paddingRight: '56px !important',
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          root: () => ({
            width: 'calc(100% - 24px)',
            marginLeft: 12,
            '.MuiSlider-rail': { backgroundColor: palette.almostWhite.main, height: '2px' },
            '.MuiSlider-track': { backgroundColor: palette.blue.main, height: '4px', border: 'none' },
            '.MuiSlider-thumb': {
              background: palette.blue.main,
              height: 24,
              width: 24,
              '&:after': {
                content: '""',
                width: 8,
                height: 8,
                borderRadius: 8,
                background: 'white',
              },
            },
          }),
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            background: palette.lightGrey.main,
            boxShadow: '0px 16px 32px rgba(0, 0, 0, 0.08)',
            borderRadius: 8,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            padding: '12px 24px',
            fontWeight: 400,
            transition: 'background 0.3s ease',
            '&.Mui-selected': {
              background: 'rgba(255,255,255,0.1) !important',
            },
            '&:not(.Mui-selected):not(.Mui-disabled):hover': {
              background: 'rgba(255,255,255,0.05)',
            },
          },
        },
      },
      MuiBreadcrumbs: {
        styleOverrides: {
          separator: {
            margin: 0,
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: () => ({
            alignItems: 'flex-start',
          }),
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: () => ({
            padding: 4,
            borderRadius: 8,
            backgroundColor: palette.inputs.main,
            gap: 4,
            overflow: 'auto',
            maxWidth: '100%',
            '& .MuiToggleButton-root': {
              border: 'none !important',
              margin: '0 !important',
              borderRadius: '6px !important',
            },
          }),
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: () => ({
            border: 'none',
            textTransform: 'none',
            color: palette.almostWhite.main,
            padding: '8px 16px',
            fontSize: 14,
            lineHeight: '24px',
            fontWeight: 600,
            background: palette.button.main,
            '&:hover': {
              background: palette.button.hover,
              color: palette.white.main,
            },
            '&.Mui-selected, &.Mui-selected:hover': {
              color: palette.white.main,
              background: palette.blue.main,
            },
          }),
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            background: palette.lightGrey.main,
            '&.light': {
              background: palette.white.main,
              color: palette.lightGrey.main,
            },
            '&.dark': {
              padding: 16,
              background: palette.black.main,
              maxWidth: 800,
            },
            whiteSpace: 'pre-line',
          },
          popper: {
            zIndex: 0,
            '&.hidden': {
              zIndex: 1000,
            },
          },
          arrow: {
            color: palette.lightGrey.main,
            '&.light': {
              color: palette.white.main,
            },
            '&.dark': {
              color: palette.black.main,
            },
          },
        },
        defaultProps: {
          arrow: true,
        },
      },
      MuiPickersPopper: {
        styleOverrides: {
          paper: {
            borderRadius: 8,
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: palette.blue.main,
            },
          },
          today: {
            backgroundColor: palette.aqua.opacity50,
            border: 'none !important',
          },
        },
      },
      MuiDateCalendar: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            backgroundColor: palette.grey.main,
          },
        },
      },
    },
  });

  return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>;
};

export const useTheme = (): Theme => useThemeMUI();

export { useMediaQuery } from '@mui/material';

export const useBreakpointDown = (breakpoint: 'sm' | 'md' | 'lg') => useMediaQuery<Theme>((theme) => theme.breakpoints.down(breakpoint));

export type { Theme };
