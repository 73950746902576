import { Box } from '@chaos/ui/box';

import shapesLeft from '../../assets/img/signin-shapes-left.svg';
import shapesRight from '../../assets/img/signin-shapes-right.svg';

const bgCircleLeft = {
  position: 'absolute',
  borderRadius: '50%',
  transform: 'rotate(24.74deg)',
  zIndex: -1,
  width: 766,
  height: 766,
  background: 'radial-gradient(50% 50% at 50% 50%, rgba(15,208,196,0.15) 0%, rgba(15,208,196,0) 100%)',
  top: 57,
  right: '50%',
  marginRight: '193px',
} as const;

const bgCircleTop = {
  position: 'absolute',
  borderRadius: '50%',
  transform: 'rotate(24.74deg)',
  zIndex: -1,
  width: 744,
  height: 744,
  background: 'radial-gradient(50% 50% at 50% 50%, rgba(15,127,208,0.15) 0%, rgba(15,127,208,0) 100%)',
  top: -545,
  left: '50%',
  marginLeft: '-190px',
} as const;

const bgCircleRight = {
  position: 'absolute',
  borderRadius: '50%',
  transform: 'rotate(24.74deg)',
  zIndex: -1,
  width: 818,
  height: 818,
  background: 'radial-gradient(50% 50% at 50% 50%, rgba(208,15,119,0.2) 0%, rgba(208,15,119,0) 100%)',
  bottom: -574,
  left: '50%',
  marginLeft: '61px',
} as const;

const bgShapesLeft = {
  position: 'absolute',
  zIndex: -1,
  top: 0,
  right: '50%',
  marginRight: '497px',
  width: 763,
  height: 421,
  backgroundImage: `url(${shapesLeft})`,
} as const;

const bgShapesRight = {
  position: 'absolute',
  zIndex: -1,
  bottom: 0,
  left: '50%',
  marginLeft: '434px',
  width: 980,
  height: 642,
  backgroundImage: `url(${shapesRight})`,
} as const;

export const SigninBg = (): JSX.Element => (
  <>
    <Box component="i" sx={bgCircleLeft} />
    <Box component="i" sx={bgCircleTop} />
    <Box component="i" sx={bgCircleRight} />
    <Box component="i" sx={bgShapesLeft} />
    <Box component="i" sx={bgShapesRight} />
  </>
);
