import { useEffect, useMemo, useState } from 'react';
import { ModelRunResult } from '../../data/types';

type ReturnType = {
  assetsOptions: string[];
  selectedAsset?: string;
  setSelectedAsset: (asset: string) => void;
  selectedAssetResult?: ModelRunResult;
};

export const useSelectAssetResult = (results: ModelRunResult[]) => {
  const [assetsOptions, setAssetsOptions] = useState<string[]>([]);
  const [selectedAsset, setSelectedAsset] = useState<string>();

  useEffect(() => {
    const assets = results.map((r) => r.metadata.asset_name);
    setAssetsOptions(assets);
    setSelectedAsset(assets[0]);
  }, [results]);

  const result: ReturnType = useMemo(() => ({
    assetsOptions,
    selectedAsset,
    setSelectedAsset,
    selectedAssetResult: results.find((r) => r.metadata.asset_name === selectedAsset),
  }), [assetsOptions, selectedAsset, setSelectedAsset, results]);

  return result;
};
