import { useEffect, useState } from 'react';
import axios from 'axios';
import { dataQueryURL } from '../utils/data-query-url';

export type BlockMappingTableRowData = {
  block: number,
  timestamp: number,
  chain: string,
};

export const useBlockMappingTableData = (date: string, chains: string[]): {
  blockMappingTableData: BlockMappingTableRowData[], isLoading: boolean,
} => {
  const [blockMappingTableData, setBlockMappingTableData] = useState<
  BlockMappingTableRowData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);

    const fetchTableData = async () => {
      if (chains?.length) {
        const url = `${dataQueryURL()}data/block_mapper`;
        const res = await axios.post<BlockMappingTableRowData[]>(
          url,
          {
            date,
            chains,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );
        const tableRows = res.data;
        setBlockMappingTableData(tableRows);
        setIsLoading(false);
      }
    };

    fetchTableData().catch(() => {
      setIsLoading(false);
      throw new Error('Error fetching block mapping table data');
    });
  }, [date, chains]);

  return { blockMappingTableData, isLoading };
};
