import {
  AggregationName,
  ScriptResource,
  Header,
  RenderData,
  SimulationPermutationInfo,
  SimulationPermutationObservationResult,
  SimulationStatistics,
} from '@chaos/types';
import { FC, useState } from 'react';
import { RouteParams, RoutePath } from 'src/config/routes';
import { aggregateDataOptions, confidenceIntervalToString, parseIntervalString } from 'src/pages/simulation-experiment-results-page/utils';
import { CustomReactSelect } from '@chaos/ui';
import { ChaosTable } from '../widgets';
import { formatAmount } from './utils';

export interface ParamConfigPermutationTableProps {
  simulationResultId: string;
  permutationInfo: SimulationPermutationInfo[];
  observationsPermutationInfo: SimulationPermutationObservationResult[];
  observers: ScriptResource[];
  simulationStatistics?: SimulationStatistics;
}

export const ParamConfigPermutationTable: FC<ParamConfigPermutationTableProps> = ({
  permutationInfo, observationsPermutationInfo, observers, simulationStatistics, simulationResultId,
}) => {
  const [
    selectedAggregation,
    setSelectedAggregation,
  ] = useState<AggregationName>('last');

  const isSimulationStatisticsExist = simulationStatistics
  && !simulationStatistics.errorMessage
  && simulationStatistics?.data.length;

  const orderedPermutationArgsNames: string[] = permutationInfo[0].args
    .map((arg) => arg.argName)
    .sort((a, b) => a.localeCompare(b));

  const orderedObservations: ScriptResource[] = observationsPermutationInfo.reduce((
    acc: ScriptResource[],
    currentInfo: SimulationPermutationObservationResult,
  ) => {
    const observer = observers.find((obs) => obs.id === currentInfo.id);

    if (!observer) return acc;

    const isObserverExist = acc.some((info) => observer.id === info.id);
    if (isObserverExist) return acc;

    return [...acc, observer];
  }, []);

  const headers: Header[] = [
    {
      renderType: 'TEXT',
      text: '#',
      width: '10%',
    },
    ...orderedPermutationArgsNames.map((argName) => ({
      renderType: 'TEXT',
      text: argName,
      width: '10%',
    }) as Header),
    ...orderedObservations
      .map((value: ScriptResource) => ({
        renderType: 'TEXT',
        text: value.dataType === 'event' ? `${value.name} (sum)` : value.name,
        width: '10%',
      }) as Header),
  ];

  const data: RenderData[][] = permutationInfo.map((perInfo, idx) => {
    const permutationData: RenderData[] = orderedPermutationArgsNames.map((name) => {
      const { argValue } = perInfo.args.find((info) => info.argName === name) || {};
      return {
        renderType: 'CHIP',
        text: argValue?.toString() || '-',
        value: argValue,
      };
    });

    const observsPermInfo: SimulationPermutationObservationResult[] = observationsPermutationInfo
      .filter((info) => info.permutationId === perInfo.id);

    const observationData: RenderData[] = orderedObservations
      .map(({ id, dataType }: ScriptResource) => {
        const observPermInfo = observsPermInfo.find((info) => info.id === id);

        if (!observPermInfo) {
          return {
            renderType: 'TEXT',
            text: '-',
          };
        }

        const { aggregations, permutationId } = observPermInfo;

        const observerStatistics = simulationStatistics?.data?.find((
          datum,
        ) => datum.permutationInfo?.id === permutationId)?.metricStatistics.find((
          statistics,
        ) => statistics.id === id);

        if (observerStatistics && isSimulationStatisticsExist) {
          const {
            confidence_interval: confidenceInterval,
          } = observerStatistics.aggregationStatistics[selectedAggregation];

          const {
            mean: confidenceIntervalMean,
          } = parseIntervalString(confidenceInterval);

          return {
            renderType: 'TEXT',
            text: confidenceIntervalToString(confidenceInterval),
            value: confidenceIntervalMean,
          };
        }

        const { sum } = aggregations;
        const value = dataType === 'event' ? sum : aggregations[selectedAggregation];

        const formatAmountOptions = Math.abs(value) > 1 ? undefined : { maximumFractionDigits: 4 };
        const text = formatAmount(value, formatAmountOptions);

        return {
          renderType: 'TEXT',
          text,
          value,
        };
      });

    return [
      {
        renderType: 'TEXT',
        text: `${idx + 1}`,
        value: idx,
      },
      ...permutationData,
      ...observationData,
    ];
  });

  const handleOnClickRow = (rowIdx: number) => {
    const result = permutationInfo[rowIdx];

    if (simulationStatistics && isSimulationStatisticsExist) {
      const { SimulationResultId, PermutationId } = RouteParams;
      return RoutePath.Simulations.ExperimentResults
        .replace(SimulationResultId, simulationResultId)
        .replace(PermutationId, result.id);
    }
    return `${RoutePath.Simulations.Results}/${result.resultIds[0]}`;
  };

  return (
    <ChaosTable
      title="Experiments"
      headers={headers}
      data={data}
      rowHeight={72}
      pageSize={20}
      rowHref={handleOnClickRow}
      rowHrefTarget="_blank"
      headerSuffixComponent={(
        <CustomReactSelect
          defaultValue={aggregateDataOptions[0]}
          options={aggregateDataOptions}
          onChange={(val) => {
            if (val?.value) {
              setSelectedAggregation(val.value as AggregationName);
            }
          }}
        />
      )}
    />
  );
};
