import { Box, Link, Typography } from '@chaos/ui';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useRandomAvatar } from '../../hooks';

interface Props {
  title?: string
  subtitle?: string
  subtitleLink?: string
}

export const SidebarPopupCustomHeader: React.FC<Props> = ({ title, subtitle, subtitleLink }) => {
  const { randomAvatar } = useRandomAvatar(title);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box
        component="img"
        src={randomAvatar}
        alt="agent"
        sx={{ objectFit: 'contain', height: 48, width: 48 }}
      />
      <Box sx={{ ml: 2, minWidth: 0, fontFamily: 'Archivo' }}>
        <Typography noWrap fontSize="24px">
          {title}
        </Typography>
        {subtitleLink ? (
          <Link
            key={title}
            to={subtitleLink}
            component={RouterLink}
            color="inherit"
            variant="caption"
            underline="hover"
          >
            {subtitle}
          </Link>
        ) : <Typography fontSize={16} variant="caption">{subtitle}</Typography>}
      </Box>
    </Box>
  );
};
