import { FC, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { SignInLayout } from 'src/components/layouts';
import { Paper } from '@chaos/ui/paper';
import { Typography } from '@chaos/ui/typography';
import { useAppSelector } from 'src/store';
import { useCurrentTeam } from 'src/hooks';

export const WaitingTeamPage: FC = () => {
  const team = useCurrentTeam();
  const profile = useAppSelector((state) => state.firebase.profile);

  useEffect(() => {
    if (!team) {
      // Team request logic
    }
  }, [team]);

  if (profile.isEmpty || team) return <Navigate to="/" />;

  return (
    <SignInLayout>
      <Paper variant="card" sx={{ p: { xs: 2, sm: 5 }, margin: { xs: 0, sm: 'auto' } }}>
        <Typography variant="h2" mb={3}>You have no any active team yet</Typography>
        <Typography>Please wait for admin approval</Typography>
      </Paper>
    </SignInLayout>
  );
};
