import { FC, useState, ChangeEvent } from 'react';
import { Field, FieldProps } from 'formik';
import { StandardTextFieldProps } from '@mui/material/TextField';

import { Box } from '@chaos/ui/box';
import { TextField } from '@chaos/ui/text-field';
import { InputLabel } from '@chaos/ui/input-label';

import { CustomIcon } from '../custom-icon';
import { CustomPopover } from '../custom-popover';

interface InputFieldProps extends StandardTextFieldProps {
  name: string
  normalize?: (value: string, prevValue: string) => string | undefined | null
  ingoreMeta?: boolean
  definition?: string
  labelIcon?: string
  customOnChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

export const InputField: FC<InputFieldProps> = ({
  name,
  normalize,
  ingoreMeta,
  definition,
  labelIcon,
  customOnChange,
  ...props
}): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  return (
    <>
      {props.label && (
      <Box display="flex" alignItems="flex-start" gap={1}>
        {labelIcon && <CustomIcon icon={labelIcon} sx={{ width: 24, height: 24 }} />}

        <InputLabel disabled={props.disabled}>{props.label}</InputLabel>

        {definition && (
        <Box
          onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
          onMouseLeave={() => setAnchorEl(null)}
          sx={{ cursor: 'pointer' }}
        >
          <CustomIcon icon="exclamation-square" />
        </Box>
        )}
      </Box>
      )}
      <Field name={name}>
        {({ field: { onChange, value, onBlur }, meta, form }: FieldProps) => {
          let error: string | undefined;
          if (meta && meta.touched && meta.error) {
            error = meta.error.replace(name, props.label?.toString() || 'Value');
          }
          return (
            <TextField
              fullWidth
              {...props}
              label={undefined}
              onBlur={onBlur}
              name={name}
              error={error !== undefined}
              helperText={error}
              value={value as string ?? ''}
              onChange={(event) => {
                if (normalize) {
                  form.setFieldValue(name, normalize(event.target.value, value as string));
                }
                if (customOnChange) customOnChange(event);
                onChange(event);
              }}
            />
          );
        }}
      </Field>

      <CustomPopover anchorEl={anchorEl} definition={definition} />
    </>
  );
};
