import { useContext } from 'react';
import { TeamContext } from 'src/context/team-context';
import { useAppSelector } from 'src/store';
import { Permissions } from '@chaos/types';

export const usePermission = (permission : Permissions | string): boolean => {
  const { team } = useContext(TeamContext);
  const isAdmin = useAppSelector((state) => state.firebase.profile.role === 'admin');
  const isAllowed = team?.permissions.includes(permission as Permissions) ?? false;
  return isAdmin || isAllowed;
};
