import { ComponentType, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { trackPage } from '../../google-analytics';

export const PageTracker = <T extends Record<string, any>>(
  WrappedComponent: ComponentType<T>,
  options: Record<string, unknown> = {},
): ((props: T) => JSX.Element) => {
  const HOC = (props: T) => {
    const location = useLocation();

    useEffect(() => {
      trackPage(location.pathname, options);
    }, [location.pathname]);

    return <WrappedComponent {...props} />;
  };

  return HOC;
};
