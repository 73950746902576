import { FC, useState } from 'react';
import { Popper, ClickAwayListener } from '@mui/material';
import { Box, Button, useTheme } from '@chaos/ui';
import { wrappedSymbolToIconSymbol } from 'src/utils/icon-helper';
import { TokensFilter } from '../tokens-filter';

type Props = {
  isOpen: boolean,
  anchorEl: HTMLButtonElement | null,
  legend: string[],
  setFilteredLegend: (filteredLegend: boolean[]) => void,
  onClose: () => void,
};

export const ChartFilter: FC<Props> = ({
  isOpen, anchorEl, legend, onClose, setFilteredLegend,
}) => {
  const theme = useTheme();
  const [tempLegend, setTempLegend] = useState(legend.map(() => true));

  return (
    <Popper
      open={isOpen}
      placement="bottom-start"
      anchorEl={anchorEl}
      style={{
        zIndex: theme.zIndex.modal + 1,
        borderRadius: 16,
        overflow: 'hidden',
        boxShadow: '0px 24px 32px rgba(0, 0, 0, 0.7)',
      }}
    >
      <ClickAwayListener onClickAway={onClose}>
        <Box bgcolor="background.default" width={400} p={4}>
          <TokensFilter
            onChange={(i, isVisible) => {
              tempLegend[i] = isVisible;
              setTempLegend([...tempLegend]);
            }}
            tokens={tempLegend.map((value, i) => ({
              name: legend[i],
              isHidden: !value,
              icon: legend[i] && wrappedSymbolToIconSymbol(legend[i]),
            }))}
          />
          <Button
            color="primary"
            sx={{ mt: 4 }}
            fullWidth
            onClick={() => {
              setFilteredLegend([...tempLegend]);
              onClose();
            }}
          >
            Apply
          </Button>
        </Box>
      </ClickAwayListener>
    </Popper>
  );
};
