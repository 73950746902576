import firebase from 'firebase';

/**
 * Reads an array of IDs from a collection concurrently
 * This helper method allows you to read
 * several firebase IDs from firestore concurrently.
 * By passing it a collection reference and an
 * array of IDs, it will return an array of document data
 * for said collection and IDs.
 *
 * Here's an example of how to use it:
 * ```ts
 * const usersRef = db.collection(COLLECTIONS.USERS)
 * const userIds = ["e3g1g0q3", "30ag30akg3aeeg"]
 * // [{userName: 'hello'}, {username: 'world}]
 * const usersDocuments = await readFirebaseIds(usersRef, userIds)
 *
 * ```
 *
 * @param collection The firestore collection reference
 * @param ids The array of IDs to concurrently read
 * @returns an array of document data
 */
export async function readFirestoreIds<T>(
  collection: firebase.firestore.CollectionReference,
  ids: string[],
): Promise<T[]> {
  const reads = ids.map((id) => collection.doc(id).get());
  const result = await Promise.all(reads);
  return result.map((v) => ({
    uid: v.id,
    ...v.data(),
  })) as (T & { uid: string })[];
}
