import { actionTypes } from 'react-redux-firebase';

interface Action {
  type: string;
}

// eslint-disable-next-line
export function authLoadedReducer(state = false, action: Action): boolean {
  switch (action.type) {
    case actionTypes.SET_PROFILE:
    case actionTypes.AUTH_EMPTY_CHANGE:
      return true;
    default:
      return state;
  }
}
