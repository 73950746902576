import {
  FC, useState, createContext, useMemo,
} from 'react';

export interface NetworkContextValue {
  networkID: number
  setNetworkID: (networkID: number) => void
}

const defaultValue: NetworkContextValue = {
  networkID: 0,
  setNetworkID: () => {},
};

export const NetworkContext = createContext<NetworkContextValue>(defaultValue);

export const NetworkProvider: FC = (props) => {
  const [networkID, setNetworkID] = useState<number>(0);
  const contextValue = useMemo(() => ({
    networkID,
    setNetworkID,
  }), [networkID]);

  return (
    <NetworkContext.Provider value={contextValue}>
      {props.children}
    </NetworkContext.Provider>
  );
};

export const networkOptions = [
  {
    icon: 'ethereum',
    name: 'Ethereum',
  },
  {
    icon: 'avalanche',
    name: 'Avalanche',
  },
  {
    icon: 'polygon',
    name: 'Polygon',
  },
  {
    icon: 'near',
    name: 'NEAR',
  },
  {
    icon: 'arbitrum',
    name: 'Arbitrum',
  },
  {
    icon: 'solana',
    name: 'Solana',
  },
  {
    icon: 'cardano',
    name: 'Cardano',
  },
  {
    icon: 'movr_bw',
    name: 'Moonriver',
  },
];

export function networkNameToIndex(name: string): number {
  const index = networkOptions.findIndex((network) => network.name === name);
  return index >= 0 ? index : 0;
}
