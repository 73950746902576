import React, { FC, useCallback, useState } from 'react';
import {
  Box, Collapse, Grid, Paper, Typography,
} from '@chaos/ui';
import { IconButton } from '@chaos/ui/icon-button';
import { useScriptCode } from 'src/hooks/useScriptCode';
import { formatAmount } from '../charts/utils';
import { ShortCardItem } from '../short-card-item';
import { SidebarPopup } from './SidebarPopup';
import { SidebarPopupCustomHeader } from './SidebarPopupCustomHeader';
import { CustomIcon } from '../custom-icon';
import { ShortCardItemIcon } from './ShortCardItemIcon';
import { ParametersItemType } from '../parameters-popover-datagrid';
import { CodeEditor } from '../code-editor';

export interface ShortCardDataType {
  title: string,
  subtitle: string,
  subtitleLink?: string,
  description: string,
  args: ParametersItemType[],
  fullPath: string,
}

interface SummarySectionProps {
  title: string,
  items: ShortCardDataType[],
  onClick: (item: ShortCardDataType) => void,
  cardDtaTitleStyle?: 'inherit',
  subTitle?: JSX.Element,
  isCircleAvatarHidden?: boolean,
}

interface SimulationFormSummaryProps {
  title?: string
  name?: string,
  shortInfo?: boolean,
  description?: string,
  duration?: number,
  agents: ShortCardDataType[],
  assertions: ShortCardDataType[],
  scenarios: ShortCardDataType[],
  observers: ShortCardDataType[],
  expanded?: boolean,
  defaultExpanded?: boolean
  cardDtaTitleStyle?: 'inherit',
}

const SummarySection: FC<SummarySectionProps> = ({
  title,
  items,
  onClick,
  cardDtaTitleStyle,
  subTitle,
  isCircleAvatarHidden,
}) => (
  <Grid item xs={12} zeroMinWidth>
    <Box display="flex" columnGap={2} mb={1}>
      <Typography variant={cardDtaTitleStyle || 'caption'}>{title}</Typography>
      {subTitle && subTitle}
    </Box>
    <Grid container columnSpacing={3} rowSpacing={1}>
      {items.map((item, i) => (
        <ShortCardItem
          key={`${item.title}-${i}`}
          gridItemSize={{ xs: 12, md: 3 }}
          tooltipText={item.description}
          isCircleAvatarHidden={isCircleAvatarHidden}
          icon={(
            <ShortCardItemIcon
              onClick={() => onClick(item)}
            />
            )}
          title={item.title}
          subtitle={item.subtitle}
        />
      ))}
    </Grid>
  </Grid>
);

const SimulationFormSummary: FC<SimulationFormSummaryProps> = ({
  name,
  duration,
  agents,
  assertions,
  description,
  scenarios,
  observers,
  expanded,
  shortInfo,
  title,
  cardDtaTitleStyle,
  defaultExpanded,
}) => {
  const [popupInfo, setPopupInfo] = useState<ShortCardDataType>();
  const closeHandler: React.ReactEventHandler = useCallback(() => setPopupInfo(undefined), []);
  const openPopup = useCallback((info: ShortCardDataType) => setPopupInfo(info), []);
  const [isExpanded, setIsExpanded] = React.useState<boolean>(Boolean(defaultExpanded));
  const toggleExpand = useCallback(() => setIsExpanded((prev) => !prev), []);
  const code = useScriptCode(popupInfo?.fullPath);

  return (
    <Paper variant="card">
      <Grid
        container
        wrap="nowrap"
        onClick={toggleExpand}
        className="clickable"
        columnSpacing={3}
        sx={{
          alignItems: 'start',
          mb: expanded || isExpanded ? 3 : 0,
          transition: '.3s',
        }}
      >
        {title && <Typography variant="h2" alignSelf="center" ml={3}>{title}</Typography>}
        {!shortInfo && (
        <Grid
          item
          zeroMinWidth
          sx={{
            flexGrow: 1,
            flexBasis: 0,
          }}
        >
          <Grid columnSpacing={3} rowSpacing={3} container>
            <Grid item xs={6} md={6} xl={6} zeroMinWidth>
              <Typography variant="caption">Name</Typography>
              <Typography sx={{ fontWeight: 'fontWeightBold' }}>{name}</Typography>
            </Grid>
            {duration && (
            <Grid item xs={6} md={6} xl={6} zeroMinWidth>
              <Typography variant="caption">Duration</Typography>
              <Typography sx={{ fontWeight: 'fontWeightBold' }} noWrap>
                {`${formatAmount(duration)} blocks`}
              </Typography>
            </Grid>
            )}
            <Grid item xs={12} zeroMinWidth>
              <Typography variant="caption">Description</Typography>
              <Typography sx={{ fontWeight: 'fontWeightBold' }} textOverflow="ellipsis" overflow="hidden">{description}</Typography>
            </Grid>
          </Grid>
        </Grid>
        )}
        {!expanded && Boolean(
          agents.length || assertions.length || scenarios.length || observers.length,
        ) && (
          <Grid
            item
            sx={{
              flexBasis: 40,
              paddingLeft: '0 !important',
              marginLeft: 'auto',
              mr: -1,
            }}
          >
            <IconButton
              sx={{
                svg: {
                  transition: 'transform 0.3s ease',
                  transform: `rotate(${isExpanded ? 180 : 0}deg)`,
                },
              }}
              size="large"
            >
              <CustomIcon icon="chevron-down" />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Collapse in={expanded || isExpanded} unmountOnExit>
        <Grid columnSpacing={3} rowSpacing={3} container>
          {agents.length > 0 && <SummarySection isCircleAvatarHidden cardDtaTitleStyle={cardDtaTitleStyle} title="Agents" items={agents} onClick={openPopup} />}
          {assertions.length > 0 && <SummarySection cardDtaTitleStyle={cardDtaTitleStyle} title="Assertions" items={assertions} onClick={openPopup} />}
          {scenarios.length > 0 && <SummarySection cardDtaTitleStyle={cardDtaTitleStyle} title="Scenarios" items={scenarios} onClick={openPopup} />}
          {observers.length > 0 && <SummarySection cardDtaTitleStyle={cardDtaTitleStyle} title="Observers" items={observers} onClick={openPopup} />}
        </Grid>
      </Collapse>
      <SidebarPopup
        isOpen={!!popupInfo}
        onClose={closeHandler}
        header={(
          <SidebarPopupCustomHeader
            {...popupInfo}
          />
        )}
      >
        <Box display="flex" gap={2} flexDirection="column">
          <Typography>{popupInfo?.description}</Typography>
          {!!popupInfo?.args.length && (
            <Box sx={{
              mt: 3, bgcolor: 'background.default', borderRadius: 4, px: 3, py: 3,
            }}
            >
              <Grid container spacing={2}>
                {popupInfo?.args.map((arg) => (
                  <Grid item xs={12} md={6} key={arg.parameterName}>
                    <Typography variant="caption">{arg.parameterName}</Typography>
                    <Typography
                      fontFamily="Armata, sans-serif !important"
                      marginTop="4px"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      {arg.parameterValue}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
          {code && <CodeEditor initialCode={code} />}
        </Box>
      </SidebarPopup>
    </Paper>
  );
};

export default SimulationFormSummary;
