import { FC } from 'react';
import Editor from '@monaco-editor/react';
import { SimulationContract } from '@chaos/types';
import { Box, Paper, Typography } from '@chaos/ui';
import { JSONViewer } from 'src/components/json-viewer';
import { MONACO_OPTIONS } from 'src/components/code-editor/config';

const PaperSX = {
  padding: '20px 24px 24px',
  mt: 1,
};

type BlockExplorerAddressContractCodeProps = {
  contract: SimulationContract,
};

export const BlockExplorerAddressContractCode: FC<BlockExplorerAddressContractCodeProps> = ({
  contract,
}) => {
  const EditorOptions = {
    ...MONACO_OPTIONS,
    fontSize: 14,
    readOnly: true,
  };

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      {contract.sourceCode && (
        <Paper variant="card" sx={PaperSX}>
          <Box mb={3}>
            <Typography variant="h2">Contract Source Code</Typography>
          </Box>
          <Box bgcolor="background.default" borderRadius={1} height="40vh" py={3}>
            <Editor
              value={contract.sourceCode}
              theme="custom-theme"
              options={EditorOptions}
              language="sol"
            />
          </Box>
        </Paper>
      )}
      <Paper variant="card" sx={PaperSX}>
        <Box mb={3}>
          <Typography variant="h2">Contract ABI</Typography>
        </Box>
        <Box sx={{ mt: 1 }} height="42vh">
          <JSONViewer
            maxHeight="38vh"
            json={contract.abi}
            bgColor="background.default"
          />
        </Box>
      </Paper>
      <Paper
        variant="card"
        sx={PaperSX}
      >
        <Box mb={3}>
          <Typography variant="h2">Contract Creation Code</Typography>
        </Box>
        <Box bgcolor="background.default" borderRadius={1} height="40vh" py={3}>
          <Editor
            value={contract.bytecode}
            theme="custom-theme"
            options={EditorOptions}
            language="bytecode"
          />
        </Box>
      </Paper>
    </Box>
  );
};
