import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RouteParams, RoutePath } from 'src/config/routes';
import { MainLayout } from 'src/components/layouts';
import { PageTracker } from 'src/components/page-tracker';
import { BlockchainSimulationForm, Inputs } from 'src/components/blockchain-simulation-form';
import { createBlockchainSimulationFn } from 'src/components/dashboard/network';
import { useCurrentTeam } from 'src/hooks';
import { useBlockchainSimulation } from 'src/hooks/useBlockchainSimulation';
import { recordError } from 'src/utils/exceptions';
import { useAppSelector } from 'src/store';
import { ConfirmationLeavingDialog, getDisablePromptPushState } from 'src/components/confirmation-leaving-dialog';
import { Loader } from 'src/components/loader';
import { ObserverGroupMetadata } from '@chaos/types';

export const NewSimulationPageV2 = PageTracker((): JSX.Element => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const profile = useAppSelector((state) => state.firebase.auth);
  const team = useCurrentTeam();
  const isEditPage = pathname.includes('edit');
  const isClonePage = pathname.includes('clone');
  const { simId } = useParams<{ simId?: string }>();
  const { blockchainSimulation, isLoading } = useBlockchainSimulation(simId);

  const onSubmit = useCallback(async (simulationDetails: Inputs) => {
    try {
      const {
        id, info, agents, assertions, scenarios, observers, observerGroupsMetadata, anvilFork,
      } = simulationDetails;
      const DEFAULT_ITERATIONS = 10;
      const DEFAULT_ITERATION_TIMEOUT = 2000;

      const observerGroups: ObserverGroupMetadata[] = observerGroupsMetadata.map((group) => {
        const observerInstanceIds = observers.filter(({
          id: observerId,
          refId,
          instanceId,
        }) => instanceId && group.observerIds.includes(observerId || refId || '')).map((observer) => observer.instanceId as string);

        return { ...group, observerInstanceIds };
      });

      await createBlockchainSimulationFn({
        ...simulationDetails,
        id,
        ownerId: profile.uid,
        name: info.name || '',
        lowercaseName: info.name?.toLowerCase() || '',
        description: info.description,
        blockNumber: info.initialBlock,
        iterations: info.duration ?? DEFAULT_ITERATIONS,
        iterTimeout: info.blockTimeout ?? DEFAULT_ITERATION_TIMEOUT,
        anvilFork: anvilFork ?? true,
        agents,
        assertions,
        scenarios: {
          setup: scenarios.filter((scenario) => scenario.type === 'setup'),
          runtime: scenarios.filter((scenario) => scenario.type === 'runtime'),
        },
        observers,
        observerGroupsMetadata: observerGroups,
        createdAt: undefined,
      }, team?.authKey, () => {});

      navigate(RoutePath.Simulations.Home, { state: getDisablePromptPushState() });
    } catch (error) {
      recordError(error as Error);
    }
  }, [navigate, profile?.uid, team?.authKey]);

  const pageTitle = !isEditPage ? 'New Simulation' : 'Edit Simulation';
  const breadcrumbsLinks = useMemo(() => {
    const baseBreadcrumb = [{
      title: 'Simulations',
      href: RoutePath.Simulations.Home,
    }];

    if (blockchainSimulation) {
      baseBreadcrumb.push({
        title: blockchainSimulation.name,
        href: RoutePath.Simulations.Details.replace(
          RouteParams.SimulationId,
          blockchainSimulation.id,
        ),
      });
    }

    return baseBreadcrumb;
  }, [blockchainSimulation]);

  return (
    <MainLayout
      headerProps={{
        pageTitle,
        breadcrumbsLinks,
      }}
    >
      <ConfirmationLeavingDialog message="Changes you made may not be saved." />
      {(simId && isLoading) ? <Loader /> : (
        <BlockchainSimulationForm
          onSubmit={onSubmit}
          isSimulationClone={isClonePage}
          currentSimulation={blockchainSimulation}
        />
      )}
    </MainLayout>
  );
});
