import {
  useEffect, useRef, useState, useMemo,
} from 'react';
import { Box } from '@chaos/ui/box';
import { Chip } from '@chaos/ui/chip';
import { Paper } from '@chaos/ui/paper';
import { Typography } from '@chaos/ui/typography';
import { wrappedSymbolToIconSymbol } from 'src/utils/icon-helper';
import { CustomIcon } from '../custom-icon';

const hexToRGB = (hex: string, alpha?: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  return `rgb(${r}, ${g}, ${b})`;
};

export interface TokenCardProps {
  title: string;
  value: string;
  delta?: string;
  deltaTrend?: 'up' | 'down';
  subtitle?: string;
  token1?: string;
  token2?: string;
  tags?: string[];
  layout?: 'row' | 'card';
}

const baseGradientA = '#3B4046';
const baseGradientB = '#2D3037';

export const TokenCard = ({
  title, value, delta, deltaTrend, subtitle, token1, token2, tags, layout = 'card',
}: TokenCardProps): JSX.Element => {
  const token1Symbol = token1 && wrappedSymbolToIconSymbol(token1);
  const token2Symbol = token2 && wrappedSymbolToIconSymbol(token2);
  const svgRefA = useRef<HTMLDivElement>();
  const svgRefB = useRef<HTMLDivElement>();
  const [gradient, setGradient] = useState('');
  const deltaColor = deltaTrend === 'down' ? 'error.main' : 'success.main';
  const TokenIcon = ({ symbol }: { symbol: string }) => useMemo(() => (
    <CustomIcon
      sx={{
        svg: {
          width: '100%',
          height: layout === 'card' ? 90 : 60,
          maxWidth: layout === 'card' ? 90 : 60,
        },
      }}
      icon={symbol}
    />
  ), [symbol]);

  useEffect(() => {
    const colorA = svgRefA.current?.innerHTML.match(/#(?:[0-9a-fA-F]{3}){1,2}/)
      ?.find((color) => color !== 'white' && color.toLowerCase() !== '#ffffff' && color !== 'none') || '#46a2da';
    const colorB = svgRefB.current?.innerHTML.match(/#(?:[0-9a-fA-F]{3}){1,2}/)
      ?.find((color) => color !== 'white' && color.toLowerCase() !== '#ffffff' && color !== 'none') || '#EBECF0';

    setGradient(
      `radial-gradient(100% 561.12% at 100% 50%, ${
        hexToRGB(colorB, 0.4)
      } 0%, ${
        hexToRGB(colorA, 0.6)
      } 100%), linear-gradient(180deg, ${baseGradientA} 0%, ${baseGradientB} 100%)`,
    );
  }, []);

  return (
    <Paper
      variant="card"
      sx={{
        display: 'flex',
        gap: '24px',
        alignItems: 'center',
        justifyContent: 'center',
        background: gradient,
      }}
    >
      <Box ref={svgRefA}>
        {token1Symbol && <TokenIcon symbol={token1Symbol} />}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems={token1Symbol && !token2Symbol ? 'start' : 'center'}
        flex={1}
      >
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          justifyContent={token1Symbol && !token2Symbol ? 'start' : 'center'}
        >
          <Typography>{title}</Typography>
          {layout === 'row' && tags && (
            <Box display="flex" gap={1} ml="auto">
              {tags.map((tag) => (
                <Chip key={tag} size="small" label={tag} />
              ))}
            </Box>
          )}
        </Box>
        <Typography variant="h1">{value}</Typography>
        {delta && (
          <Box
            bgcolor="rgba(60, 60, 60, .6)"
            px={1}
            py={0.25}
            borderRadius={2}
            my={0.5}
            boxShadow="0 0 1rem 0 rgba(0, 0, 0, .2)"
          >
            <Typography
              color={deltaTrend ? deltaColor : undefined}
              display="flex"
              alignItems="center"
              whiteSpace="nowrap"
              variant="body2"
            >
              {delta}
            </Typography>
          </Box>
        )}
        {subtitle && <Typography>{subtitle}</Typography>}
        {layout === 'card' && tags && (
          <Box display="flex" gap={1} mt={1}>
            {tags.map((tag) => (
              <Chip key={tag} size="small" label={tag} />
            ))}
          </Box>
        )}
      </Box>
      {token2Symbol && (
        <Box ref={svgRefB}>
          <TokenIcon symbol={token2Symbol} />
        </Box>
      )}
    </Paper>
  );
};
