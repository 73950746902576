import { FC } from 'react';
import { Field, FieldProps } from 'formik';
import type { ContractValue } from '../modals';
import { ContractUpload, ContractUploadProps } from '../contract-upload';

export const ContractUploadField: FC<ContractUploadProps> = ({
  name,
  ...props
}): JSX.Element => (
  <Field name={name}>
    {({ field: { value }, meta, form }: FieldProps) => (
      <ContractUpload
        {...props}
        onChange={(data) => {
          form.setFieldValue(name, data);
        }}
        value={value as ContractValue | undefined}
        name={name}
        meta={meta}
      />
    )}
  </Field>
);
