import { FC } from 'react';
import { Typography } from '@chaos/ui/typography';
import { ChaosChart, CollapsibleDescription } from 'src/components/charts';
import { Box } from '@chaos/ui/box';

interface AreaChartRowProps {
  data?: any
  title: string
  color?: string
  description?: string
}

export const AreaChartRow: FC<AreaChartRowProps> = ({
  title, data, color, description,
}): JSX.Element => (
  <Box position="relative">
    <Typography variant="h2">
      {title}
    </Typography>
    {description && <CollapsibleDescription text={description} />}
    <Box sx={{ mt: { sm: 2, md: 4 }, height: 300 }}>
      {/* eslint-disable-next-line */}
      <ChaosChart data={data} color={color} startY={0} />
    </Box>
  </Box>
);
