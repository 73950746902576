import {
  isEmpty,
  useFirestore,
  useFirestoreConnect,
} from 'react-redux-firebase';
import {
  MultiNavLink, NavLink, NavMenuProps, SingleLink,
} from 'src/components/app-nav/types';
import { useCallback } from 'react';
import { Box } from '@chaos/ui/box';
import { Permissions } from '@chaos/types';
import { useAppSelector } from 'src/store';
import { COLLECTIONS } from '@chaos/utils';
import { useCurrentTeam } from 'src/hooks';
import { NAV_LINKS } from 'src/components/app-nav/config';
import { MultiNavItem } from 'src/components/app-nav/multi-nav-components';
import { filterLinksByPermission, isLinkDisabled, isMultiLink } from 'src/components/app-nav/helpers';

import { NavItem } from './nav-item';

export const NavMenu = ({ closeMenu, menuOpened }: NavMenuProps): JSX.Element => {
  const auth = useAppSelector((state) => state.firebase.auth);
  const isAdmin = useAppSelector((state) => state.firebase.profile.role === 'admin');
  const isAuthed = !isEmpty(auth);
  const team = useCurrentTeam();

  const firestore = useFirestore();
  useFirestoreConnect(
    team && [
      {
        collection: COLLECTIONS.DASHBOARDS,
        where: [
          ['team', '==', firestore.collection(COLLECTIONS.TEAMS).doc(team.id)],
        ],
      },
    ],
  );

  const isDisabled = useCallback(
    (
      to: string,
      permission?: Permissions,
    ) => isLinkDisabled(to, permission, isAuthed, isAdmin, team),
    [isAdmin, isAuthed, team],
  );

  const renderLink = useCallback((link: NavLink): JSX.Element => {
    if (isMultiLink(link)) {
      const { links, title, icon } = link as MultiNavLink;
      return (
        <Box component="li" key={title}>
          <MultiNavItem
            title={title}
            closeMenu={closeMenu}
            menuOpened={menuOpened}
            links={filterLinksByPermission(links, team) as SingleLink[]}
            icon={icon}
          />
        </Box>
      );
    }
    const {
      to, icon, permission, label,
    } = link as SingleLink;

    return (
      <Box component="li" key={to} onClick={closeMenu} className="single-link">
        <NavItem
          icon={icon}
          to={to}
          className={isDisabled(to, permission) ? 'disabled' : ''}
        >
          {label}
        </NavItem>
      </Box>
    );
  }, [closeMenu, isDisabled, menuOpened, team]);

  return (
    <Box
      component="ul"
      sx={{
        listStyle: 'none',
        pr: 1,
        py: { xs: 3, md: 0 },
        '& .single-link': {
          py: 2.5,
        },
      }}
    >
      {filterLinksByPermission(NAV_LINKS, team).map((link) => renderLink(link))}
    </Box>
  );
};
