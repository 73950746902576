import {
  FC, useEffect, useState, useRef,
} from 'react';
import { CandlestickData, createChart, IChartApi } from 'lightweight-charts';
import type { ResponsiveStyleValue } from '@mui/system';
import { Box } from '@chaos/ui/box';
import { Typography } from '@chaos/ui/typography';
import { Paper } from '@chaos/ui/paper';
import { useLightweightChartResize } from 'src/hooks/useLightweightChartResize';

const HoverDataItem: FC<{ title: string, value: number, color: string }> = ({
  title,
  value,
  color,
}) => (
  <Box display="flex" flexDirection="row">
    <Typography variant="body2">{title}</Typography>
    <Typography variant="body2" color={color} px={1}>{value}</Typography>
  </Box>
);

type LightweightCandlesChartProps = {
  data: CandlestickData[],
  height: ResponsiveStyleValue<number | string>,
};

export const LightweightCandlesChart: FC<LightweightCandlesChartProps> = ({ data, height }) => {
  const chartContainer = useRef<HTMLDivElement>(null);
  const chart = useRef<IChartApi | null>(null);
  const [hoverData, setHoverData] = useState<CandlestickData>();

  useEffect(() => {
    if (chartContainer.current) {
      const rect = chartContainer.current?.getBoundingClientRect();
      chart.current = createChart(chartContainer.current, {
        width: rect.width || 500,
        height: rect.height || 500,
        layout: {
          backgroundColor: '#000',
          textColor: '#fff',
        },
      });
      chart.current.subscribeCrosshairMove((param) => {
        const value = param.seriesPrices.values().next();

        if (value.done) {
          setHoverData(undefined);
        } else {
          setHoverData(value.value as unknown as CandlestickData);
        }
      });
      const candlesSeries = chart.current.addCandlestickSeries();
      candlesSeries.setData(data);
      chart.current.timeScale().fitContent();
    }

    return () => {
      if (chart.current) {
        chart.current.remove();
      }
    };
  }, [data]);

  useLightweightChartResize(chartContainer, chart);
  const hoverDataColor = (hoverData && hoverData.open > hoverData.close) ? 'error.main' : 'success.main';

  return (
    <Box position="relative" height={height}>
      {hoverData && (
        <Paper
          variant="card"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute',
            zIndex: 10,
            p: 1,
            m: 1,
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <HoverDataItem title="Open" value={hoverData.open} color={hoverDataColor} />
          <HoverDataItem title="High" value={hoverData.high} color={hoverDataColor} />
          <HoverDataItem title="Low" value={hoverData.low} color={hoverDataColor} />
          <HoverDataItem title="Close" value={hoverData.close} color={hoverDataColor} />
        </Paper>
      )}
      <Box height={height} ref={chartContainer} />
    </Box>
  );
};
export default LightweightCandlesChart;
