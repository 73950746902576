import { Box, Link } from '@chaos/ui';
import { FC, useCallback } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { Permissions } from '@chaos/types';
import { isEmpty } from 'react-redux-firebase';
import { useAppSelector } from 'src/store';
import { auth } from 'src/services/firebase';
import { useCurrentTeam } from 'src/hooks';
import { NavIcon } from 'src/components/nav-icon';
import { isLinkDisabled } from 'src/components/app-nav/helpers';
import { MultiNavListProps, NavItemProps } from 'src/components/app-nav/types';
import { MULTI_NAV_ITEM_STYLES } from './styles';

const MultiNavItemElem: FC<NavItemProps> = ({
  to, children, className, icon,
}) => (
  <Link
    to={to}
    component={RouterLink}
    color="inherit"
    underline="none"
    className={className}
    sx={MULTI_NAV_ITEM_STYLES}
  >
    <NavIcon icon={icon} />
    <Box
      component="span"
      pl={2}
    />
    {children}
  </Link>
);

export const MultiNavLinkList: FC<MultiNavListProps> = ({ closeMenu, links }) => {
  const isAdmin = useAppSelector((state) => state.firebase.profile.role === 'admin');
  const isAuthed = !isEmpty(auth);
  const team = useCurrentTeam();

  const isDisabled = useCallback(
    (
      to: string,
      permission?: Permissions,
    ) => isLinkDisabled(to, permission, isAuthed, isAdmin, team),
    [isAdmin, isAuthed, team],
  );

  return (
    <Box
      component="ul"
      sx={{
        mx: 3,
        listStyle: 'none',
      }}
    >
      {links.map(({
        // eslint-disable-next-line @typescript-eslint/no-shadow
        to, label, permission, icon,
      }) => (
        <Box component="li" onClick={closeMenu} key={to}>
          <MultiNavItemElem
            to={to}
            className={isDisabled(to, permission) ? 'disabled' : ''}
            icon={icon}
          >
            {label}
          </MultiNavItemElem>
        </Box>
      ))}
    </Box>
  );
};
