import { usePageTitle } from 'src/hooks';
import { PageTracker } from 'src/components/page-tracker';
import { MainLayout } from 'src/components/layouts';
import { PaginatedSimulationResults } from 'src/components/paginated-simulation-results';

export const SimulationsResultsV2 = PageTracker((): JSX.Element => {
  usePageTitle('Simulation Results');

  return (
    <MainLayout
      headerProps={{
        pageTitle: 'Simulation Results',
      }}
      height={0}
    >
      <PaginatedSimulationResults isFullHeight showLoading showEmptyState />
    </MainLayout>
  );
});
