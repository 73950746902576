import { FC } from 'react';
import { Typography } from '@chaos/ui/typography';
import { Box } from '@chaos/ui/box';
import { Button } from '@chaos/ui/button';
import { CustomIcon } from 'src/components/custom-icon';
import { useRandomAvatar } from 'src/hooks';
import { InputField, ReactSelectField } from '../fields';

const roleOptions = [
  { value: 'user', label: 'User' },
  { value: 'admin', label: 'Admin' },
];

interface TeamMemberProps {
  index?: number
  isNew?: boolean
  openRemoveModal: () => void
  member: { email: string; role: string; firstName: string; lastName: string }
  isDeleteMemberButtonDisabled?: boolean
}

export const TeamMember: FC<TeamMemberProps> = (props) => {
  const {
    isNew,
    openRemoveModal,
    member,
    index,
    isDeleteMemberButtonDisabled,
  } = props;
  const { randomAvatar } = useRandomAvatar(member.email);
  const fieldMaxWidth = 344;
  return (
    <Box
      sx={{
        ':not(:last-of-type)': {
          mb: { xs: 3, md: 5 },
          pb: { xs: 3, md: 0 },
          borderBottom: { xs: '1px solid rgb(255 255 255 / 10%)', md: 0 },
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: 3,
        }}
      >
        {isNew ? (
          <Box sx={{ maxWidth: { xs: 'inherit', md: fieldMaxWidth }, flexGrow: 1 }}>
            <InputField
              name={`members[${index ?? ''}].email`}
              label="Member Email"
              placeholder="Enter email"
              fullWidth
            />
          </Box>
        ) : (
          <Box sx={{ display: 'flex', maxWidth: fieldMaxWidth, flexGrow: 1 }}>
            <Box
              component="img"
              src={randomAvatar}
              alt="user-avatar"
              sx={{
                display: 'block',
                objectFit: 'contain',
                width: 48,
                height: 48,
                mt: -1,
              }}
            />
            <Box sx={{ pl: 2, flexGrow: 1, width: { xs: '100%', md: 280 } }}>
              <Typography component="p" noWrap>{`${member.firstName} ${member.lastName}`}</Typography>
              <Typography component="p" sx={{ color: 'light.main' }} noWrap>
                {member.email}
              </Typography>
            </Box>
          </Box>
        )}

        <Box display="flex" gap={3} flexGrow={1}>
          <Box sx={{ maxWidth: { xs: 'inherit', md: fieldMaxWidth }, flexGrow: 1 }}>
            <ReactSelectField
              label={isNew ? 'Role' : ''}
              name={`members[${index ?? ''}].role`}
              isSearchable={false}
              options={roleOptions}
              placeholder="Select user role"
            />
          </Box>

          <Button
            color="error"
            disabled={isDeleteMemberButtonDisabled}
            sx={{
              minWidth: 48,
              borderRadius: '16px',
              p: '12px',
              alignSelf: 'flex-end',
            }}
            onClick={openRemoveModal}
          >
            <CustomIcon icon="trash-can" />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
