import {
  Box, Paper, ECharts, echartsTooltipFormatter, Typography,
} from '@chaos/ui';
import { ShimmerLoader } from '@chaos/ui/shimmer-loader';
import { formatAxis } from '@chaos/ui/utils/formatters';
import { EChartsOption } from 'echarts';
import { ExplorerLtScanningResponse } from '../../types';

type Props = {
  isLoading: boolean;
  data: ExplorerLtScanningResponse | undefined;
};

const LtScanningChart = ({
  isLoading,
  data,
}: Props) => {
  const sortedResultEntries = Object.entries(data || {}).sort((
    [a],
    [b],
  ) => Number(a) - Number(b));

  const totalLiquidatedAmountSerieData = sortedResultEntries.map((
    [diff, wallets],
  ) => {
    const totalLiquidatedAmount = wallets.reduce((
      total,
      wallet,
    ) => total + wallet.valueEligibleForLiquidation, 0);
    return [diff, totalLiquidatedAmount];
  });

  const option: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      formatter: echartsTooltipFormatter({
        headerFormatter: (value: number | string) => `${value}%`,
        currency: 'USD',
      }),
      padding: 0,
      renderMode: 'auto',
      verticalAlign: 'middle',
    },
    legend: {
      type: 'scroll',
    },
    xAxis: {
      type: 'value',
      splitLine: {
        show: false,
      },
      axisLabel: {
        formatter: (value: number) => `${value}%`,
      },
    },
    grid: {
      top: 45,
      right: 10,
      left: 10,
    },
    yAxis: {
      type: 'value',
      nameLocation: 'middle',
      nameGap: 50,
      nameTextStyle: {
        color: '#fff',
      },
      axisLabel: {
        formatter: (value: number) => formatAxis(value, 3, 'USD'),
      },
      min: 'dataMin',
      max: 'dataMax',
    },
    series: {
      name: 'Total Value Eligible for Liquidation',
      data: totalLiquidatedAmountSerieData,
      type: 'line',
    },
  };

  return (
    <Paper
      variant="card"
      sx={{
        bgcolor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        p: 3,
      }}
    >
      <Box mb={2}>
        <Typography variant="h2">Collateral At Risk</Typography>
        <Typography fontWeight={400} mt={1}>
          Value to be liquidated (in USD)
          if Liquidation Threshold changes up or down compared to target LT.
        </Typography>
      </Box>
      <Box height={376}>
        {isLoading
          ? <ShimmerLoader sx={{ height: '100%' }} />
          : (
            <ECharts option={option} />
          )}
      </Box>
    </Paper>
  );
};

export default LtScanningChart;
