import { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import ChainlinkDataFetcher from '@chaos-labs/chainlink-price-feeds';
import { Proxy } from '@chaos-labs/chainlink-price-feeds/build/types';
import { TokenTickerEnum } from '@chaos/types';
import { Typography } from '@chaos/ui/typography';
import { Box } from '@chaos/ui/box';
import { Grid } from '@chaos/ui/grid';
import { Tooltip } from '@chaos/ui/tooltip';
import { Button } from '@chaos/ui/button';
import { EditPageLayout } from 'src/components/layouts';
import { Tabs } from '@chaos/ui/tabs';
import { TokenPairCard } from 'src/components/token-pair-card';
import { RoutePath } from 'src/config/routes';
import { SelectFunction } from 'src/components/select-function';
import { PageTracker } from 'src/components/page-tracker';
import { InputField, ReactSelectField } from 'src/components/fields';
import { required } from 'src/utils/validators';
import { ConfigurationModal, ModalWrapper } from 'src/components/modals';

export const tokenOptions = [
  { value: TokenTickerEnum.BTC, label: 'Bitcoin', icon: 'bitcoin' },
  { value: TokenTickerEnum.ETH, label: 'Ethereum', icon: 'ethereum-token' },
  { value: TokenTickerEnum.CELO, label: TokenTickerEnum.CELO, icon: 'celo' },
  {
    value: TokenTickerEnum.COMP,
    label: TokenTickerEnum.COMP,
    icon: 'compound',
  },
  { value: TokenTickerEnum.DAI, label: TokenTickerEnum.DAI, icon: 'dai' },
  { value: TokenTickerEnum.GRT, label: TokenTickerEnum.GRT, icon: 'the-graph' },
  { value: TokenTickerEnum.LINK, label: TokenTickerEnum.LINK, icon: 'link' },
  { value: TokenTickerEnum.LUNA, label: TokenTickerEnum.LUNA, icon: 'luna' },
  { value: TokenTickerEnum.MATIC, label: TokenTickerEnum.MATIC, icon: 'matic' },
  { value: TokenTickerEnum.MKR, label: TokenTickerEnum.MKR, icon: 'maker' },
  { value: TokenTickerEnum.NEAR, label: TokenTickerEnum.NEAR, icon: 'near' },
  {
    value: TokenTickerEnum.RAND,
    label: TokenTickerEnum.RAND,
    icon: 'algorand',
  },
  { value: TokenTickerEnum.SOL, label: TokenTickerEnum.SOL, icon: 'sol' },
  { value: TokenTickerEnum.UNI, label: TokenTickerEnum.UNI, icon: 'uni' },
  { value: TokenTickerEnum.USDC, label: TokenTickerEnum.USDC, icon: 'usdc' },
  { value: TokenTickerEnum.USDT, label: TokenTickerEnum.USDT, icon: 'usdt' },
  { value: TokenTickerEnum.AAVE, label: TokenTickerEnum.AAVE, icon: 'aave' },
  { value: TokenTickerEnum.ZRX, label: TokenTickerEnum.ZRX, icon: 'zrx' },
  { value: TokenTickerEnum.BAT, label: TokenTickerEnum.BAT, icon: 'bat' },
  { value: TokenTickerEnum.DOT, label: TokenTickerEnum.DOT, icon: 'dot' },
  { value: TokenTickerEnum.CRV, label: TokenTickerEnum.CRV, icon: 'crv' },
];

export interface ConfigureFormValues {
  token1?: string
  token2?: string
  initMockValue?: number
  frequencyPriceChange?: number
  priceDelta?: number
  priceFunction?: string
}

const initialValues: ConfigureFormValues = {
  token1: undefined,
  token2: undefined,
  initMockValue: undefined,
  // frequencyPriceChange: undefined,
  priceDelta: undefined,
  priceFunction: undefined,
};

const validationSchemas = [
  yup.object().shape({
    token1: required,
    token2: required,
    initMockValue: yup.number().required(),
    frequencyPriceChange: yup.number().optional(),
    priceDelta: yup.number().required(),
  }),
  yup.object().shape({
    priceFunction: required,
  }),
];

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const ConfigureOracleCompanyPage = PageTracker((): JSX.Element => {
  const [selectedPairIndex, setSelectedPairIndex] = useState<number | null>(
    null,
  );

  const { companyName } = useParams<{ companyName: string }>();
  const capitalizedCompanyName = capitalizeFirstLetter(companyName!);
  const [, setPriceFeed] = useState<Proxy[] | undefined>(
    undefined,
  );

  useEffect(() => {
    async function fetchChainlinkPriceFeeds() {
      const fetchedPriceFeeds = await ChainlinkDataFetcher.getProxiesForNetwork(
        ChainlinkDataFetcher.CHAINLINK_DOCS_CONSTANTS.PAYLOAD_KEYS.ETHEREUM,
        ChainlinkDataFetcher.SUPPORTED_NETWORKS.ETHEREUM_MAINNET,
      );
      setPriceFeed(fetchedPriceFeeds);
    }
    fetchChainlinkPriceFeeds()
      .then((res) => {
        console.log(res);
      })
      .catch((e) => {
        // eslint-disable-next-line
          console.log(`Failed to fetch Chainlink pricefeeds: ${e} `);
      });
  }, []);

  const [tabIndex, setTabIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const submitFuncRef = useRef<
  FormikHelpers<ConfigureFormValues>['submitForm'] | null
  >(null);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const onSubmit = async (
    _values: ConfigureFormValues,
    helpers: FormikHelpers<ConfigureFormValues>,
  ) => {
    try {
      await Promise.resolve();
    } catch (e) {
      console.log(e);
    } finally {
      helpers.setSubmitting(false);
    }
  };

  return (
    <EditPageLayout
      rootRoute={RoutePath.OracleConfiguration.Home}
      rootTitle="Oracle Configuration"
      pageTitle={`Configure ${capitalizedCompanyName || ''} Oracle`}
      metaTitle="Configure Oracle"
      titleIcon={companyName!.toLowerCase()}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchemas[tabIndex]}
        validateOnBlur
        validateOnChange
        validateOnMount
      >
        {({
          values, setFieldValue, submitForm, isValid, isSubmitting,
        }) => {
          submitFuncRef.current = submitForm;
          return (
            <>
              <Box>
                <Box sx={{ mb: 5 }}>
                  <Tabs
                    value={tabIndex}
                    tabs={[{ label: 'Step 1' }, { label: 'Step 2' }]}
                    fullWidth
                  />
                </Box>

                {tabIndex === 0 ? (
                  <>
                    <Typography
                      component="h2"
                      variant="h2"
                      sx={{ py: '36px' }}
                    >
                      Top Token Pairs
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 5,
                      }}
                    >
                      <Grid container rowSpacing={5} columnSpacing={5}>
                        {[
                          [TokenTickerEnum.ETH, TokenTickerEnum.USDC],
                          [TokenTickerEnum.BTC, TokenTickerEnum.USDC],
                          [TokenTickerEnum.LINK, TokenTickerEnum.USDC],
                          [TokenTickerEnum.USDT, TokenTickerEnum.USDC],
                          [TokenTickerEnum.DAI, TokenTickerEnum.USDC],
                          [TokenTickerEnum.USDC, TokenTickerEnum.ETH],
                          [TokenTickerEnum.AAVE, TokenTickerEnum.ETH],
                          [TokenTickerEnum.AAVE, TokenTickerEnum.USDC],
                          [TokenTickerEnum.BTC, TokenTickerEnum.ETH],
                          [TokenTickerEnum.CELO, TokenTickerEnum.USDC],
                          [TokenTickerEnum.UNI, TokenTickerEnum.USDC],
                          [TokenTickerEnum.ZRX, TokenTickerEnum.ETH],
                          [TokenTickerEnum.BAT, TokenTickerEnum.ETH],
                          [TokenTickerEnum.DOT, TokenTickerEnum.USDC],
                          [TokenTickerEnum.COMP, TokenTickerEnum.USDT],
                          [TokenTickerEnum.SOL, TokenTickerEnum.USDT],
                          [TokenTickerEnum.GRT, TokenTickerEnum.ETH],
                          [TokenTickerEnum.LUNA, TokenTickerEnum.ETH],
                          [TokenTickerEnum.MKR, TokenTickerEnum.USDC],
                          [TokenTickerEnum.CRV, TokenTickerEnum.USDT],
                          [TokenTickerEnum.NEAR, TokenTickerEnum.USDC],
                          [TokenTickerEnum.MATIC, TokenTickerEnum.USDC],
                        ].map((pair, i) => (
                          <Grid
                            // eslint-disable-next-line
                            key={i}
                            item
                            zeroMinWidth
                            sm={4}
                            md={3}
                            xl={2}
                          >
                            <TokenPairCard
                              isSelected={selectedPairIndex === i}
                              ticker1={pair[0]}
                              ticker2={pair[1]}
                              onTokenClick={() => {
                                setTimeout(() => {
                                  setFieldValue('token1', pair[0]);
                                  setFieldValue('token2', pair[1]);
                                });
                                setSelectedPairIndex(i);
                              }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <Typography component="h2" variant="h2" sx={{ py: '36px' }}>
                      Select Token Pair
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 5,
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        flex: 1,
                      }}
                    >
                      <Box sx={{ flex: 1, maxWidth: 344 }}>
                        <ReactSelectField
                          isSearchable={false}
                          label="Token 1"
                          options={tokenOptions}
                          name="token1"
                          placeholder="Select token"
                        />
                      </Box>
                      <Box sx={{ flex: 1, maxWidth: 344 }}>
                        <ReactSelectField
                          isSearchable={false}
                          label="Token 2"
                          options={tokenOptions}
                          name="token2"
                          placeholder="Select token"
                        />
                      </Box>
                    </Box>
                    <Typography
                      component="h2"
                      variant="h2"
                      sx={{ py: '36px' }}
                    >
                      Select Initial Mock Value
                    </Typography>
                    <Box sx={{ maxWidth: 344 }}>
                      <InputField
                        autoComplete="off"
                        type="number"
                        name="initMockValue"
                        placeholder="Enter value"
                        label="Value"
                      />
                    </Box>
                    {/* <Typography component="h2" variant="h2" sx={{ py: "36px" }}>
                      Select Frequency of Price Change
                    </Typography>
                    <Box sx={{ maxWidth: 344 }}>
                      <InputField
                        autoComplete="off"
                        type="number"
                        name="frequencyPriceChange"
                        placeholder="Enter value"
                        label="Value"
                      />
                    </Box> */}
                    <Typography
                      component="h2"
                      variant="h2"
                      sx={{ py: '36px' }}
                    >
                      Select Delta in Price Change
                    </Typography>
                    <Box sx={{ maxWidth: 344 }}>
                      <InputField
                        autoComplete="off"
                        type="number"
                        name="priceDelta"
                        placeholder="Enter value"
                        label="Value"
                      />
                    </Box>
                  </>
                ) : (
                  <>
                    <Typography
                      component="h2"
                      variant="h2"
                      sx={{ flex: 1, py: '36px' }}
                    >
                      Select Function
                    </Typography>
                    <SelectFunction
                      functionValue={values.priceFunction}
                      onSelectFunction={setFieldValue}
                    />
                  </>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '40px 40px 40px 0',
                    backgroundColor: 'background.default',
                  }}
                >
                  {tabIndex > 0 && (
                  <Button
                    color="secondary"
                    onClick={() => setTabIndex(0)}
                  >
                    Back
                  </Button>
                  )}
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 3,
                      ml: 'auto',
                    }}
                  >
                    {tabIndex === 1 && (
                    <Button
                      color="secondary"
                      onClick={openModal}
                      disabled={!values.priceFunction}
                    >
                      Export JSON
                    </Button>
                    )}
                    {tabIndex === 1 && !values.priceFunction ? (
                      <Tooltip
                        title="Please Select Math Function"
                        arrow
                        placement="left"
                      >
                        <span>
                          <Button
                            color="primary"
                            disabled
                            sx={{
                              ml: 'auto',
                            }}
                          >
                            Deploy
                          </Button>
                        </span>
                      </Tooltip>
                    ) : (
                      <Button
                        color="primary"
                        disabled={isSubmitting || !isValid}
                        sx={{
                          ml: 'auto',
                        }}
                        onClick={() => {
                          if (tabIndex === 0) {
                            setTabIndex(1);
                          }
                          void submitFuncRef.current?.();
                        }}
                      >
                        {tabIndex === 0 ? 'Next' : 'Deploy'}
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
              <ModalWrapper
                open={isModalOpen}
                onClose={closeModal}
                maxWidth={694}
              >
                {/* @ts-expect-error record */}
                <ConfigurationModal jsonValue={values} onClose={closeModal} />
              </ModalWrapper>
            </>
          );
        }}
      </Formik>
    </EditPageLayout>
  );
});
