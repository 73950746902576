import { useState } from 'react';
import { Box } from '@chaos/ui';
import { Loader } from '@chaos/ui/loader';
import { useAssetProtectionToolData } from '../AssetProtectionTool/useAssetProtectionToolData';
import WalletsTable from './wallets-table';
import { ExplorerParamChangeResponse, ExplorerLtScanningResponse } from './types';
import { ChartsSection, ParamExplorerForm, AggregationCards } from './sections';

const ParamExplorer = () => {
  const { isLoading, data: { marketsAssetsMapping } } = useAssetProtectionToolData();
  const [isLoadingResult, setIsLoadingResult] = useState<boolean>(false);
  const [isLoadingScanningResult, setIsLoadingScanningResult] = useState<boolean>(false);
  const [result, setResult] = useState<ExplorerParamChangeResponse>();
  const [scanningResult, setScanningResult] = useState<ExplorerLtScanningResponse>();

  if (isLoading || !marketsAssetsMapping) return <Loader />;

  return (
    <Box display="flex" flexDirection="column" gap={5}>
      <ParamExplorerForm
        marketsAssetsMapping={marketsAssetsMapping}
        setResult={setResult}
        setIsLoading={setIsLoadingResult}
        setIsLoadingScanningResult={setIsLoadingScanningResult}
        setScanningResult={setScanningResult}
      />
      {
        isLoadingResult
          ? <Loader />
          : result && (
            <>
              <AggregationCards
                wallets={result.liquidatedWallets}
                defaultWallets={result.defaultLiquidatedWallets}
              />
              <ChartsSection
                healthHistogramData={result.healthHistogram}
                isLoadingScanningResult={isLoadingScanningResult}
                ltScanningData={scanningResult}
              />
              <WalletsTable
                wallets={result.liquidatedWallets}
                defaultWallets={result.defaultLiquidatedWallets}
              />
            </>
          )
      }
    </Box>
  );
};

export default ParamExplorer;
