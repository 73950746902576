import { createElement, FC } from 'react';
import { CustomLayerProps, Point, SliceTooltipProps } from '@nivo/line';
import { useTooltip } from '@nivo/tooltip';

type ClickLayerProps = CustomLayerProps & {
  slices: SliceTooltipProps['slice'][],
  setCurrentSlice: (slice: SliceTooltipProps['slice'] | null) => void,
  onClick?: (slice: Point[]) => void,
};

export const ClickLayer: FC<ClickLayerProps> = ({
  slices, setCurrentSlice, sliceTooltip, enableSlices, onClick,
}) => {
  const { showTooltipFromEvent, hideTooltip } = useTooltip();

  return (
    <>
      {slices.map((slice) => (
        <rect
          key={slice.x0}
          x={slice.x0}
          y={slice.y0}
          cursor={onClick && 'pointer'}
          width={slice.width}
          height={slice.height}
          fillOpacity={0}
          onMouseEnter={(event) => {
            if (sliceTooltip) {
              showTooltipFromEvent(
                createElement(sliceTooltip, {
                  slice,
                  axis: enableSlices || 'x',
                }),
                event,
                'right',
              );
            }
            setCurrentSlice(slice);
          }}
          onClick={() => {
            if (onClick) {
              onClick(slice.points);
            }
          }}
          onMouseMove={(event) => {
            if (sliceTooltip) {
              showTooltipFromEvent(
                createElement(sliceTooltip, {
                  slice,
                  axis: enableSlices || 'x',
                }),
                event,
                'right',
              );
            }
          }}
          onMouseLeave={() => {
            hideTooltip();
            setCurrentSlice(null);
          }}
        />
      ))}
    </>
  );
};
