import { useEffect, useState } from 'react';
import { Subscription, throttleTime } from 'rxjs';
import {
  collection, doc, orderBy, query, where,
} from 'firebase/firestore';
import { collectionData } from 'rxfire/firestore';
import { COLLECTIONS } from '@chaos/utils';
import { SimulationEvent } from '@chaos/types';
import { firestore } from 'src/services/firebase';
import { useCurrentTeam } from './useCurrentTeam';

export const useSimulationContractEvents = (simulationResultId: string, contractAddress: string): {
  isLoading: boolean,
  simulationContractEvents: SimulationEvent[],
} => {
  const team = useCurrentTeam();
  const [contractEvents, setContractEvents] = useState<SimulationEvent[]>();

  useEffect(() => {
    const subs: Subscription[] = [];
    const eventsCol = collection(firestore, COLLECTIONS.ETH_EVENTS);
    const eventsObs = collectionData(query(
      eventsCol,
      where('team', '==', doc(collection(firestore, COLLECTIONS.TEAMS), team?.id)),
      where('simulationResultId', '==', simulationResultId),
      where('address', '==', contractAddress),
      orderBy('logIndex', 'asc'),
    ), { idField: 'id' }).pipe(throttleTime(5000));
    subs.push(eventsObs.subscribe((data) => setContractEvents(data as SimulationEvent[])));

    return () => subs.forEach((s) => s.unsubscribe());
  }, [team?.id, simulationResultId, contractAddress]);

  return { isLoading: !contractEvents, simulationContractEvents: contractEvents || [] };
};
