import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from 'src/store';

type PrivateRouteProps = {
  isAllowed?: boolean,
  redirectPath?: string,
  children: JSX.Element,
};

export const PrivateRoute: FC<PrivateRouteProps> = ({
  isAllowed,
  redirectPath = '/403',
  children,
}) => {
  const isAdmin = useAppSelector((state) => state.firebase.profile.role === 'admin');

  if (!isAdmin && !isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};
