import { Box, Typography } from '@chaos/ui';
import { FC } from 'react';
import { CustomIcon } from 'src/components/custom-icon';

export const SimulationError: FC<{ errorText?: string }> = ({ errorText }) => (
  <Box sx={{
    width: '100%',
    borderRadius: '1rem',
    background: '#80354B',
    padding: '20px 27px',
    lineHeight: '24px',
    display: 'flex',
    columnGap: '1rem',
  }}
  >
    <CustomIcon icon="warning" />
    <Typography>
      {errorText || 'An error occurred during the simulation. You need to restart the simulation.'}
    </Typography>
  </Box>
);
