export const downloadCsv = (headers: string[], data: string[][], title?: string) => {
  const csvContent = `data:text/csv;charset=utf-8,${headers.join(',')}\n${
    data.map((row) => row.join(',')).join('\n')
  }`;
  const link = document.createElement('a');
  link.setAttribute('href', encodeURI(csvContent.replaceAll('#', '')));
  link.setAttribute(
    'download',
    `${title?.toLowerCase().replaceAll(' ', '_') || 'table_data'}.csv`,
  );
  link.click();
};
