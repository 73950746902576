import { useParams } from 'react-router-dom';
import { MainLayout } from 'src/components/layouts';
import { PageTracker } from 'src/components/page-tracker';
import { useBlockchainSimulationResult } from 'src/hooks/useBlockchainSimulationResult';
import { RouteParams, RoutePath } from 'src/config/routes';
import { BlockDetails } from 'src/components/transaction-details';
import { Loader } from 'src/components/loader';
import { useSimulationBlocks } from 'src/hooks/useSimulationBlocks';
import { Error } from 'src/components/error';

export const BlockExplorerBlockDetailsPage = PageTracker(() => {
  const {
    simulationResultId, blockNumber,
  } = useParams<{ simulationResultId: string, blockNumber: string }>();
  const { result, simulation } = useBlockchainSimulationResult(simulationResultId!, true);
  const { isLoading, simulationBlocks } = useSimulationBlocks(
    simulationResultId!,
    result?.meta?.connectionKey,
    Number(blockNumber),
  );
  const block = simulationBlocks?.[0];

  const breadcrumbsLinks = [{
    title: 'Simulation Results',
    href: RoutePath.Simulations.Results,
  }, {
    title: simulation?.name || '',
    href: RoutePath.Simulations.ResultDetails
      .replace(RouteParams.SimulationResultId, simulationResultId!),
  }, {
    title: 'Block Explorer',
    href: RoutePath.Simulations.BlockExplorer.Home
      .replace(RouteParams.SimulationResultId, simulationResultId!),
  }];

  return (
    <MainLayout headerProps={{
      pageTitle: 'Block',
      breadcrumbsLinks,
      extraData: block && { text: `#${block.number.toString()}` },
    }}
    >
      {isLoading && <Loader />}
      {!isLoading && block && <BlockDetails block={block} />}
      {!isLoading && !block && <Error errorTitle="404" errorMessage="Block not found or is still being indexed" />}
    </MainLayout>
  );
});
