import { memo, useState } from 'react';
import { DatumValue, Margin } from '@nivo/core';
import { ResponsiveLine, CustomLayer, Point } from '@nivo/line';
import { ChartLegends } from '@chaos/types';
import { Box, Typography } from '@chaos/ui';
import { formatAxis } from '@chaos/ui/utils/formatters';
import { colorScale } from 'src/components/charts/utils';
import { theme } from '../observers/components/charts/chart-theme';
import { ClickLayer } from '../observers/components/charts/click-layer';
import { ChartTooltip, LineChartSerie } from './chart-tooltip-v2';

type LineChartProps = {
  data: LineChartSerie[],
  tooltipHeader?: (xValue: DatumValue) => string,
  enableArea?: boolean,
  onClick?: (iterationNumber: number) => void,
  legends?: ChartLegends,
  labels?: string[],
  height?: number | string,
  xAxisType?: 'date' | 'linear',
  xAxisTickValues?: number,
  tickSize?: 'small' | 'large',
  axisHidden?: boolean,
  customAreaLayers?: CustomLayer[],
  margin?: Partial<Margin>
};

const LineChartComponent = ({
  data,
  tooltipHeader,
  onClick,
  legends,
  labels,
  height,
  xAxisType = 'linear',
  xAxisTickValues,
  tickSize = 'large',
  axisHidden,
  customAreaLayers,
  margin,
  ...restProps
}: LineChartProps) => {
  const [hiddenData, setHiddenData] = useState(labels?.map(() => false));
  const leftAxisData = data.filter((_, idx) => !hiddenData?.[idx]);
  const rightAxisData = data.filter((d, idx) => d.isSecondaryAxis && !hiddenData?.[idx]);
  const marginLarge = {
    top: 16, right: legends?.right ? 96 : 80, bottom: 48, left: legends?.left ? 96 : 80,
  };
  const marginSmall = {
    top: 8, right: legends?.right ? 64 : 60, bottom: 36, left: legends?.left ? 64 : 60,
  };
  const marginZero = {
    top: 2, right: 0, bottom: 2, left: 0,
  };

  return (
    <>
      {(leftAxisData.length || rightAxisData.length) ? (
        <Box position="relative" height={height} width="100%">
          {rightAxisData.length > 0 && (
            <Box position="absolute" top="0" height="100%" width="100%">
              <ResponsiveLine
                data={rightAxisData}
                curve="linear"
                margin={margin || (tickSize === 'large' ? marginLarge : marginSmall)}
                yScale={{
                  type: 'linear',
                  min: 'auto',
                  max: 'auto',
                }}
                yFormat={(value) => formatAxis(value, 4)}
                axisLeft={null}
                axisBottom={null}
                axisRight={{
                  tickSize: 0,
                  tickPadding: tickSize === 'large' ? 24 : 16,
                  tickRotation: 0,
                  tickValues: 6,
                  format: formatAxis,
                  legend: legends?.right,
                  legendPosition: 'middle',
                  legendOffset: 80,
                }}
                enableGridX={false}
                enableGridY={false}
                theme={theme(tickSize)}
                colors={(d: LineChartSerie) => (!d.isSecondaryAxis
                  ? 'transparent' : colorScale[data.indexOf(d) % colorScale.length])}
                enablePoints={false}
                {...restProps}
              />
            </Box>
          )}
          <ResponsiveLine
            data={leftAxisData}
            curve="linear"
            margin={margin || (axisHidden ? marginZero : tickSize === 'large' ? marginLarge : marginSmall)}
            enableSlices="x"
            yScale={{
              type: 'linear',
              min: 'auto',
              max: 'auto',
            }}
            yFormat={(value) => formatAxis(value, 4)}
            xScale={xAxisType === 'date' ? { type: 'time', format: '%Y-%m-%d', precision: 'day' } : {
              type: 'linear',
              min: 'auto',
              max: 'auto',
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: tickSize === 'large' ? 24 : 16,
              tickRotation: 0,
              tickValues: 6,
              format: formatAxis,
              legend: legends?.left,
              legendPosition: 'middle',
              legendOffset: -80,
            }}
            axisBottom={{
              tickSize: 0,
              tickPadding: tickSize === 'large' ? 24 : 16,
              tickRotation: 0,
              format: xAxisType === 'date' ? '%Y-%m-%d' : formatAxis,
              tickValues: xAxisTickValues,
              legend: legends?.bottom,
              legendOffset: 64,
              legendPosition: 'middle',
            }}
            gridYValues={axisHidden ? 0 : 6}
            enableGridX={false}
            enableGridY={!axisHidden}
            theme={theme(tickSize)}
            enablePoints={false}
            colors={(d: LineChartSerie) => (d.isSecondaryAxis
              ? 'transparent' : colorScale[data.indexOf(d) % colorScale.length])}
            {...restProps}
            sliceTooltip={(props) => (
              <ChartTooltip
                tooltipProps={props}
                data={data}
                headerLabel={tooltipHeader}
                hiddenData={hiddenData}
              />
            )}
            onClick={onClick && ((points) => {
              onClick((points as unknown as Point[])[0].data.x as number);
            })}
            enableCrosshair
            layers={[
              'grid',
              'markers',
              'axes',
              'areas',
              ...(customAreaLayers ?? []),
              'crosshair',
              'lines',
              'points',
              ClickLayer as CustomLayer,
              'mesh',
              'legends',
            ]}
          />
        </Box>
      ) : (
        <Box height={height} width="100%" display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h2" fontSize={20} color="textSecondary">No data</Typography>
        </Box>
      )}
      {labels && (
        <Box
          display="grid"
          gridTemplateColumns="repeat(3, 33.333%)"
          mt={tickSize === 'large' ? 3 : 2}
          overflow="auto"
          bgcolor="background.default"
          width="100%"
          maxHeight={160}
          px={3}
          py={0.5}
          borderRadius={4}
        >
          {labels.map((label, idx) => (
            <Box
              key={label}
              className="legend-item"
              display="flex"
              alignItems="center"
              sx={{
                cursor: 'pointer',
              }}
              onClick={() => {
                setHiddenData((currHiddenData) => {
                  if (currHiddenData) {
                    const newHiddenData = [...currHiddenData];
                    newHiddenData[idx] = !newHiddenData[idx];
                    return newHiddenData;
                  }

                  return undefined;
                });
              }}
              p={0.5}
            >
              <Box
                bgcolor={colorScale[idx % colorScale.length]}
                width={8}
                height={8}
                borderRadius={8}
                flexShrink={0}
              />
              <Typography
                variant={!hiddenData?.[idx] ? undefined : 'caption'}
                whiteSpace="nowrap"
                fontWeight={500}
                fontSize={16}
                ml={1}
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {label}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export const LineChart = memo(LineChartComponent);
