import React, {
  ChangeEvent, useCallback, useEffect, useState,
} from 'react';
import {
  Box, TextField, InputLabel, Typography, CustomReactSelect, CustomSwitch,
} from '@chaos/ui';
import { useCurrentTeam } from 'src/hooks';
import { getLatestBlock } from 'src/services/engine';
import { CustomIcon } from '../custom-icon';

export type InfoType = {
  name?: string;
  description?: string;
  duration?: number;
  initialBlock?: number;
  repetitions?: number;
  blockTimeout?: number;
};

type SimulationInfoStepProps = {
  errors?: Partial<Record<keyof InfoType, string>>,
  info: InfoType,
  onChange: (inputs: InfoType) => void,
};

const simulationDurationOptions: Array<{ label: string, value: string }> = [
  {
    value: '250',
    label: '250 Blocks',
  },
  {
    value: '1000',
    label: '1,000 Blocks',
  },
  {
    value: '2500',
    label: '2,500 Blocks',
  },
  {
    value: '5000',
    label: '5,000 Blocks',
  },
  {
    value: '10000',
    label: '10,000 Blocks',
  },
  {
    value: '-1',
    label: 'Custom',
  },
];

const SimulationInfoStep: React.FC<SimulationInfoStepProps> = ({ info, onChange, errors }) => {
  const showAdvancedSection = !!(info.initialBlock
    || info.repetitions
    || info.blockTimeout);
  const [isAdvanced, setIsAdvanced] = useState(showAdvancedSection);

  useEffect(() => {
    if (showAdvancedSection) setIsAdvanced(true);
  }, [showAdvancedSection]);

  const [showCustomDuration, setShowCustomDuration] = useState(false);
  const [latestBlock, setLatestBlock] = useState<number>();
  const team = useCurrentTeam();
  const selectedOption = info.duration ? (
    simulationDurationOptions.find(({ value }) => value === info.duration?.toString())
    || simulationDurationOptions[simulationDurationOptions.length - 1]
  ) : undefined;

  useEffect(() => {
    if (!showCustomDuration && selectedOption?.value === '-1') {
      setShowCustomDuration(true);
    }
  }, [selectedOption, showCustomDuration]);
  const onChangeHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => onChange({
    [event.target.name]: event.target.value,
  }), [onChange]);

  useEffect(() => {
    async function getBlock() {
      if (team?.authKey) {
        const { authKey } = team;
        const latestFetchedBlock = await getLatestBlock(authKey, 'ethereum');
        setLatestBlock(Number(latestFetchedBlock));
      }
    }
    void getBlock();
  }, [team]);

  return (
    <>
      <Box width="100%" display="flex" justifyContent="flex-end">
        <Box
          display="flex"
          alignItems="center"
          marginLeft="auto"
          marginRight="24px"
          gap={2}
        >
          <CustomSwitch
            testId="advanced-section-toggle"
            trackColor="#17191E"
            checked={isAdvanced}
            onChange={(e) => setIsAdvanced(e.target.checked)}
          />
          <Typography>Advanced</Typography>
        </Box>
      </Box>
      <Box sx={{ maxWidth: 344 }}>
        <InputLabel>Simulation Name</InputLabel>
        <TextField
          error={Boolean(errors?.name)}
          helperText={errors?.name}
          fullWidth
          name="name"
          placeholder="Enter simulation name"
          value={info.name}
          autoComplete="off"
          onChange={onChangeHandler}
        />
      </Box>
      <Box mt={4}>
        <InputLabel>Description</InputLabel>
        <TextField
          error={Boolean(errors?.description)}
          helperText={errors?.description}
          fullWidth
          name="description"
          placeholder="Enter description here"
          multiline
          minRows={3}
          value={info.description}
          onChange={onChangeHandler}
        />
      </Box>
      <Box display="flex" mt={4} gap={5}>
        <Box width={344}>
          <InputLabel>Simulation Duration</InputLabel>
          <CustomReactSelect
            options={simulationDurationOptions}
            placeholder="Select simulation duration"
            value={selectedOption}
            onChange={(val) => {
              setShowCustomDuration(false);
              if (val) {
                if (val.value !== '-1') {
                  onChange({ duration: Number(val.value) });
                } else {
                  onChange({ duration: undefined });
                  setShowCustomDuration(true);
                }
              }
            }}
          />
        </Box>
        {showCustomDuration && (
          <Box>
            <InputLabel>Duration (Blocks)</InputLabel>
            <TextField
              placeholder="Enter duration here"
              value={info.duration}
              onChange={(e) => {
                if (!e.target.value) {
                  onChange({ duration: undefined });
                } else if (!Number.isNaN(Number(e.target.value))) {
                  onChange({ duration: Number(e.target.value) });
                }
              }}
              helperText={(
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <CustomIcon icon="info" sx={{ mr: 1, 'svg path': { fill: '#9B9DA1' } }} />
                  Maximum simulation duration is 10,000 blocks
                </Box>
              )}
            />
          </Box>
        )}
      </Box>
      {isAdvanced && (
      <Box mt={5} display="flex" gap={5} flexWrap="wrap">
        <Box width={344}>
          <InputLabel>
            Initial Block Height
          </InputLabel>
          <TextField
            sx={{ width: '100%' }}
            data-testid="block-height-input"
            placeholder="Value"
            value={info.initialBlock}
            onChange={(e) => {
              if (!e.target.value) {
                onChange({ initialBlock: undefined });
              } else if (!Number.isNaN(Number(e.target.value))) {
                onChange({ initialBlock: Number(e.target.value) });
              }
            }}
          />
          {latestBlock && (
          <InputLabel sx={{ mt: 1 }}>
            Last block is
            {' '}
            {latestBlock}
          </InputLabel>
          )}
        </Box>
        <Box width={344}>
          <InputLabel>
            Repetitions
          </InputLabel>
          <TextField
            sx={{ width: '100%' }}
            placeholder="Value"
            value={info.repetitions}
            onChange={(e) => {
              if (!e.target.value) {
                onChange({ repetitions: undefined });
              } else if (!Number.isNaN(Number(e.target.value))) {
                onChange({ repetitions: Number(e.target.value) });
              }
            }}
          />
        </Box>
        <Box width={344}>
          <InputLabel>
            Block Timeout (seconds)
          </InputLabel>
          <TextField
            sx={{ width: '100%' }}
            placeholder="Timeout"
            value={info.blockTimeout}
            onChange={(e) => {
              if (!e.target.value) {
                onChange({ blockTimeout: undefined });
              } else if (!Number.isNaN(Number(e.target.value))) {
                onChange({ blockTimeout: Number(e.target.value) });
              }
            }}
          />
        </Box>
      </Box>
      )}
    </>
  );
};

export default SimulationInfoStep;
