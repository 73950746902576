import { Navigate, useNavigate } from 'react-router-dom';
import { Box } from '@chaos/ui/box';
import { Typography } from '@chaos/ui/typography';
import { MainLayout } from 'src/components/layouts';
import { Button } from '@chaos/ui/button';
import welcome from 'src/assets/img/welcome.svg';
import { useAppSelector } from 'src/store';
import { FirebaseReducer, useFirebase } from 'react-redux-firebase';
import { usePageTitle } from 'src/hooks';
import { RoutePath } from 'src/config/routes';
import { PageTracker } from 'src/components/page-tracker';

export const WaitingListPage = PageTracker((): JSX.Element => {
  usePageTitle('Waitlist');
  const auth: FirebaseReducer.AuthState = useAppSelector((state) => state.firebase.auth);
  const navigate = useNavigate();
  const firebase = useFirebase();
  const user = firebase.auth().currentUser;

  if (!user) {
    return <Navigate to={RoutePath.SignIn} />;
  }

  return (
    <MainLayout>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '62px',
          flexShrink: 0,
        }}
      >
        <Box>
          <img src={welcome} width="600" height="600" alt="welcome" />
        </Box>
        <Box sx={{ maxWidth: 372 }}>
          <Typography
            sx={{
              paddingBottom: 2,
            }}
            component="h1"
            variant="h2"
          >
            {`You are currently on the wait-list, ${auth.email || ''}!`}
          </Typography>
        </Box>

        <Button
          size="large"
          color="secondary"
          type="submit"
          onClick={() => {
            void firebase.logout();
            navigate('/');
          }}
        >
          Log Out
        </Button>
      </Box>
    </MainLayout>
  );
});
