import React, { MouseEvent, useCallback, useState } from 'react';

import { Box } from '@chaos/ui';
import { SxProps, Theme } from '@mui/material';
import { CustomIcon, CustomIconProps } from '../custom-icon';
import { CustomPopover } from '../custom-popover';

interface HintWithIconProps {
  showHintTrigger?: 'hover' | 'click'
  iconProps: CustomIconProps,
  sx?: SxProps<Theme>
}

export const HintWithIcon:React.FC<HintWithIconProps> = ({
  children,
  showHintTrigger = 'hover',
  iconProps,
  sx,
}) => {
  const propsTrigger: Record<string, (event: MouseEvent<HTMLDivElement>) => void> = {};

  const [element, setElement] = useState<null | HTMLElement>(null);
  const handleOpen = useCallback((event: MouseEvent<HTMLElement>) => {
    setElement(() => event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setElement(null);
  }, []);

  switch (showHintTrigger) {
    case 'click':
      propsTrigger.onClick = handleOpen;
      break;
    case 'hover':
      propsTrigger.onMouseEnter = handleOpen;
      propsTrigger.onMouseLeave = handleClose;
      break;
    default:
  }

  return (
    <>
      <Box sx={sx} {...(propsTrigger)}>
        <CustomIcon {...iconProps} />
      </Box>
      <CustomPopover anchorEl={element} definition={children} />
    </>
  );
};
