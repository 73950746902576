import { FC, ReactNode } from 'react';
import { Box } from '@chaos/ui/box';
import { PageHeader, PageHeaderProps } from '@chaos/ui/page-header';

interface PageLayoutProps {
  children?: ReactNode;
  topbar?: ReactNode;
  headerProps?: PageHeaderProps;
  height?: string | number;
  noPadding?: boolean;
}

export const MainLayout: FC<PageLayoutProps> = ({
  children, topbar, headerProps, height, noPadding,
}) => (
  <Box
    display="flex"
    flexDirection="column"
    flexGrow={1}
    maxWidth="100%"
    pb={noPadding ? undefined : 5}
    overflow="auto"
  >
    {(topbar || headerProps) && (
      <Box
        sx={{
          flexShrink: 0,
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          position: 'relative',
          zIndex: 1,
          pr: noPadding ? undefined : { xs: 2, lg: 5 },
          pl: noPadding ? undefined : { xs: 2, lg: 0 },
        }}
      >
        {headerProps ? <PageHeader {...headerProps} /> : topbar}
      </Box>
    )}
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      height={height === undefined ? 'auto' : height}
      pr={noPadding ? undefined : { xs: 2, lg: 5 }}
      pl={noPadding ? undefined : { xs: 2, lg: 0 }}
      width="100%"
    >
      {children}
    </Box>
  </Box>
);
