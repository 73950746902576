import { FC, useEffect, useState } from 'react';
import {
  AggregationMethods,
  AggregationName,
  Axis,
  ScriptResource,
  SimulationPermutationInfo,
  SimulationPermutationObservationResult,
  SimulationStatistics,
} from '@chaos/types';
import { confidenceIntervalToString } from 'src/pages/simulation-experiment-results-page/utils';
import { ComputedCell, HeatMapDatum, HeatMapSerie } from '@nivo/heatmap';
import { RouteParams, RoutePath } from 'src/config/routes';
import {
  Box, Paper, Typography, CustomReactSelect,
} from '@chaos/ui';
import { aggregateDataOptions, getValue } from 'src/hooks/parsePermutationInfo';
import { CellLabelInputType, HeatmapChart } from '@chaos/ui/charts';

export interface ParamConfigHeatmapProps {
  simulationResultId: string,
  permutationInfo: SimulationPermutationInfo[],
  observationsInfo: SimulationPermutationObservationResult[],
  observations: ScriptResource[],
  simulationStatistics?: SimulationStatistics
}

type ParamConfigHeatmapCellMetadata = { resultId: string, permutationId: string };
type ParamConfigHeatmapData = HeatMapSerie<HeatMapDatum & ParamConfigHeatmapCellMetadata, object>;

export const ParamConfigHeatmap: FC<ParamConfigHeatmapProps> = ({
  permutationInfo, observationsInfo, observations, simulationStatistics, simulationResultId,
}) => {
  const isSimulationStatisticsExist = simulationStatistics
  && !simulationStatistics.errorMessage
  && simulationStatistics?.data.length;

  const [heatmapData, setHeatmapData] = useState<ParamConfigHeatmapData[]>();
  const [heatmapXAxis, setHeatmapXAxis] = useState<Axis>();
  const [heatmapYAxis, setHeatmapYAxis] = useState<Axis>();
  const [observation, setObservation] = useState<ScriptResource>(observations[0]);
  const [
    selectedAggregation,
    setSelectedAggregation,
  ] = useState<string>(AggregationMethods.Sum);

  useEffect(() => {
    if (permutationInfo && permutationInfo.length > 0) {
      const arg1 = permutationInfo[0].args[0]?.argName;
      const arg2 = permutationInfo[0].args[1]?.argName;
      const args = new Map<number, Map<number, { y:number } & ParamConfigHeatmapCellMetadata>>();
      // eslint-disable-next-line no-restricted-syntax
      for (const permutation of permutationInfo) {
        const val1 = permutation.args.find((x) => x.argName === arg1)?.argValue;
        const val2 = permutation.args.find((x) => x.argName === arg2)?.argValue;
        if (!val1 || !val2) {
          break;
        }
        const agg = observationsInfo?.find(
          (observationInfo) => observationInfo.id === observation.id
          && observationInfo.permutationId === permutation.id,
        )?.aggregations;

        const aggValue = getValue(agg, selectedAggregation);

        const aggregationName = selectedAggregation.toLowerCase() as AggregationName;
        const statisticsAggValue = simulationStatistics?.data?.find((
          statistics,
        ) => statistics.permutationInfo?.id === permutation.id)
          ?.metricStatistics.find((observ) => observ.id === observation.id)
          ?.aggregationStatistics?.[aggregationName].mean;

        if (val1 && !(args.has(val1))) {
          args.set(val1, new Map<number, { y:number } & ParamConfigHeatmapCellMetadata>());
        }
        if (val2) {
          args.get(val1)?.set(val2, {
            y: isSimulationStatisticsExist ? statisticsAggValue! : (aggValue || 0),
            resultId: permutation.resultIds[0],
            permutationId: permutation.id,
          });
        }
      }
      const data = Array.from(args.keys()).sort().reverse().map(
        (key) => ({
          id: key.toString(),
          data: Array.from(args.get(key)?.entries() ?? [])
            .sort((a, b) => a[0] - b[0]).map(
              ([k, item]) => ({
                x: k.toString(),
                ...item,
              }),
            ),
        }),
      );
      setHeatmapData(data);
      setHeatmapYAxis({ label: arg1 });
      setHeatmapXAxis({ label: arg2 });
    }
  }, [
    permutationInfo,
    observationsInfo,
    observation,
    selectedAggregation,
    simulationStatistics,
    isSimulationStatisticsExist,
  ]);

  const formatCellLable = (cell: CellLabelInputType<ParamConfigHeatmapCellMetadata>) => {
    // Get selected observer stastistics by permutation
    const permObservationStats = simulationStatistics?.data.find((
      statistics,
    ) => statistics.permutationInfo?.id === cell.data.permutationId)
      ?.metricStatistics.find((observ) => observ.id === observation.id);

    if (!permObservationStats || !isSimulationStatisticsExist) return `${cell.value || ''}`;

    const aggregationName = selectedAggregation.toLowerCase() as AggregationName;

    const confidenceInterval = permObservationStats
      .aggregationStatistics[aggregationName]?.confidence_interval;

    return confidenceIntervalToString(confidenceInterval);
  };

  const onClickCell = (cell: ComputedCell<HeatMapDatum & ParamConfigHeatmapCellMetadata>) => {
    const permSimulationResultId = cell.data.resultId;

    /*
      If there are valid statistics
      - open experiment results page for the corresponding permutation
    */
    if (simulationStatistics && cell.data.permutationId && isSimulationStatisticsExist) {
      const { SimulationResultId, PermutationId } = RouteParams;
      window.open(
        RoutePath.Simulations.ExperimentResults
          .replace(SimulationResultId, simulationResultId)
          .replace(PermutationId, cell.data.permutationId),
      );
      return;
    }

    window.open(
      `${RoutePath.Simulations.Results}/${permSimulationResultId}`,
    );
  };

  return (
    (heatmapData && heatmapData.length > 0) ? (
      <Box>
        <Paper
          variant="card"
          sx={{
            paddingBottom: '32px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
          }}
        >
          <Box display="flex" flexDirection="row" flexGrow={1} width="100%">
            <Typography variant="h2" fontWeight="bold">
              Dual Parameter Analysis
            </Typography>
            <Box sx={{
              flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline',
            }}
            >
              <Box paddingRight="8px">
                <CustomReactSelect
                  name="scatter-chart-observer-"
                  placeholder={observation.name}
                  options={observations?.map((observeration) => (
                    { label: observeration.name, value: observeration.id }) ?? { })}
                  onChange={(val) => {
                    if (val?.value) {
                      setObservation(observations?.filter((observ) => observ.id === val?.value)[0]);
                    }
                  }}
                />
              </Box>
              <CustomReactSelect
                name="scatter-chart-aggregation"
                placeholder={selectedAggregation}
                options={aggregateDataOptions}
                onChange={(val) => {
                  if (val?.value) {
                    setSelectedAggregation(val?.value);
                  }
                }}
              />
            </Box>
          </Box>
          <Box
            width="100%"
            bgcolor="background.default"
            borderRadius={3}
            padding={2}
            marginTop={1}
          >
            <HeatmapChart<ParamConfigHeatmapCellMetadata>
              data={heatmapData}
              xAxis={heatmapXAxis}
              yAxis={heatmapYAxis}
              cellLabel={!isSimulationStatisticsExist ? undefined : formatCellLable}
              onClick={onClickCell}
            />
          </Box>
        </Paper>
      </Box>
    ) : null
  );
};
