import * as React from 'react';
import SelectUnstyled from '@mui/base/SelectUnstyled';
import OptionUnstyled from '@mui/base/OptionUnstyled';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import { styled } from '@mui/system';
import { Chip } from '../chip';
import { Box } from '../box';
import { ChipOptionType, Props } from './types';
import { CustomIcon } from '../custom-icon';
import { useTheme } from '../theme';
import { CryptoIcon } from '../crypto-icon';

type ChipWrapperProps = {
  ownerState: {
    value: ChipOptionType
  }
  children: React.ReactNode
};

const ChipWrapper = React.forwardRef(
  (props: ChipWrapperProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const { ownerState, children, ...other } = props;
    const value = ownerState?.value || {};
    const color = value.color || 'default';
    let icon;
    if (value.icon) {
      icon = <CryptoIcon size="x-small" icon={value.icon} />;
    }

    return (
      <Box
        {...other}
        ref={ref}
        sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      >
        <Chip
          clickable
          icon={icon}
          sx={{
            fontSize: 12,
            lineHeight: '16px',
            height: 24,
            '.MuiChip-label': {
              paddingLeft: 1,
              paddingRight: 1,
            },
          }}
          label={children}
          color={color}
        />
        <CustomIcon icon="chevron-down" />
      </Box>
    );
  },
);

const ListBox = React.forwardRef((props, ref) => (
  <Box
    ref={ref}
    component="ul"
    sx={{
      listStyle: 'none',
      backgroundColor: 'black.main',
      boxShadow: '0px 16px 32px rgba(0, 0, 0, 0.08)',
      borderRadius: 1,
      padding: '8px 0',
      margin: '8px 0',
      li: {
        padding: 1,
        paddingLeft: '32px',
        width: '100%',
      },
    }}
    {...props}
  />
));

type OptionProps = {
  active: boolean
  value: ChipOptionType
  children: React.ReactNode
};

const Option = ({ active, ...props }: OptionProps) => {
  const theme = useTheme();
  const colorKey = props?.value?.color || 'info';
  const icon = props?.value?.icon;
  const color = theme.palette[colorKey];

  return (
    <Box
      {...props}
      sx={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        fontSize: 12,
        lineHeight: '16px',
        '&:hover': {
          backgroundColor: 'black.hover',
        },
        '&:active': {
          backgroundColor: 'black.active',
        },
      }}
    >
      {icon ? (
        <CryptoIcon
          size="x-small"
          icon={icon}
          sx={{
            position: 'absolute',
            left: 8,
          }}
        />
      ) : (
        <Box
          sx={{
            position: 'absolute',
            pointerEvents: 'none',
            left: 12,
            background: active ? color.main : '',
            width: 8,
            height: 8,
            borderRadius: 4,
          }}
        />
      )}
      <OptionUnstyled {...props} />
    </Box>
  );
};

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1000;
`;

export const ChipSelect = React.forwardRef(
  (
    { value, options, onChange }: Props,
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) => (
    <SelectUnstyled
      value={value}
      ref={ref}
      onChange={onChange}
      components={{
        Root: ChipWrapper,
        Listbox: ListBox,
        Popper: StyledPopper,
      }}
    >
      {options.map((option) => (
        <Option
          key={option.value}
          active={option.value === value.value}
          value={option}
        >
          {option.label}
        </Option>
      ))}
    </SelectUnstyled>
  ),
);
