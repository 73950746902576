import axios from 'axios';

interface EtherscanABIResponse {
  status: string
  message: string
  result: string
}

const ETHERSCAN = {
  API_KEY: 'WEVCJPWAKA2WJ61NNN95DB8J3YAR7S5WEQ',
  // TODO -example maker contract 0xd8a04F5412223F513DC55F839574430f5EC15531
};

export async function EthABI(address: string): Promise<Array<any>> {
  const url = `https://api.etherscan.io/api?module=contract&action=getabi&address=${address}&apikey=${ETHERSCAN.API_KEY}`;
  const res = await axios.get<EtherscanABIResponse>(url);
  if (res.status !== 200 || res.data?.status === '0') {
    throw Error(`${res.data?.message} :: ${res.data?.result}`);
  }
  // eslint-disable-next-line
  return JSON.parse(res.data.result);
}
