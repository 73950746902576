import { VictoryTooltip } from 'victory';
import { colors } from '../theme';

export const chartTooltip = (
  <VictoryTooltip
    constrainToVisibleArea
    flyoutStyle={{ fill: colors.background.tooltip }}
    flyoutPadding={{
      top: 8, bottom: 8, right: 8, left: 8,
    }}
    style={{
      fontSize: 14,
      fontFamily: 'Archivo, sans-serif',
      fill: colors.chartText,
    }}
  />
);
