export const colorScale = [
  '#1FA3D6',
  '#53B606',
  '#C435AE',
  '#E87C18',
  '#DE4469',
  '#30D4C1',
  '#9A22D2',
  '#0E55BF',
];
export const colorScaleWarm = [
  '#FFE814',
  '#E87C18',
  '#DE4469',
  '#CA1D1D',
  '#E44DBA',
  '#B8169F',
  '#9F064F',
  '#FF7395',
];
export const colorScaleCold = [
  '#0750BF80',
  '#0750BF',
  '#0366C1',
  '#1B869C',
  '#21A4BF',
  '#23BAA3',
  '#228757',
  '#1DA564',
  '#1DA564AA',
  '#1DA564AA',
  '#1DA564CC',
];
