import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import {
  useCallback, useMemo, useRef, useState,
} from 'react';
import {
  Formik,
  Form,
  FormikHelpers,
  FieldArray,
  FieldArrayRenderProps,
} from 'formik';
import {
  useFirebase,
  useFirestore,
  useFirestoreConnect,
} from 'react-redux-firebase';
import { Buffer } from 'buffer';
import { v4 as uuidv4 } from 'uuid';
import { COLLECTIONS } from '@chaos/utils';

import { Typography } from '@chaos/ui/typography';
import { Box } from '@chaos/ui/box';
import { Button } from '@chaos/ui/button';
import { IconButton } from '@chaos/ui/icon-button';
import { InputAdornment } from '@chaos/ui/input-adornment';
import { MainLayout } from 'src/components/layouts';
import { CustomIcon } from 'src/components/custom-icon';
import { TeamMember } from 'src/components/team-member';
import { Tabs } from '@chaos/ui/tabs';
import { ModalWrapper, RemoveMemberModal } from 'src/components/modals';
import { RoutePath } from 'src/config/routes';
import { InputField, ReactSelectField } from 'src/components/fields';
import { required } from 'src/utils/validators';
import { useCurrentTeam, usePageTitle, useRandomAvatar } from 'src/hooks';
import { useAppSelector } from 'src/store';
import { TeamFormValues } from 'src/components/team-form';
import { PageTracker } from 'src/components/page-tracker';
import { FileValue } from '@chaos/types';
import { ConfirmationLeavingDialog, getDisablePromptPushState } from 'src/components/confirmation-leaving-dialog';
import { TeamMemberData } from '../team-edit-page';
import { FileUpload, TypePropsOnDeleteFile, TypePropsOnDropFile } from '../../components/file-upload';
import useFirebaseStorage from '../../hooks/useFirebaseStorage';

const validationSchemas = [
  Yup.object().shape({
    name: required,
    protocolBlockchain: required,
    protocolWebsite: Yup.string().url(),
    chaoslabsId: Yup.string(),
    amazonId: Yup.string(),
    telegram: Yup.string(),
    discord: Yup.string(),
  }),
  Yup.object().shape({
    members: Yup.array()
      .min(1)
      .of(
        Yup.object()
          .shape({
            email: Yup.string().email().required(),
            role: required,
          })
          .test('admins test', 'min one admin', function () {
            // eslint-disable-next-line
            const { parent } = this;
            // eslint-disable-next-line
            const filteredArray = parent.filter(
              (member: { role: string }) => member.role === 'admin',
            );
            // eslint-disable-next-line
            return filteredArray.length >= 1;
          }),
      ),
  }),
];

export const TeamCreatePage = PageTracker((): JSX.Element => {
  usePageTitle('Create Team');
  const auth = useAppSelector((state) => state.firebase.auth);
  const team = useCurrentTeam();
  const firebase = useFirebase();
  const firestore = useFirestore();
  const firebaseStorage = useFirebaseStorage({ category: 'img-teams' });
  const fieldArrayPropsRef = useRef<FieldArrayRenderProps | null>(null);
  const navigate = useNavigate();
  const [thumbnailFile, setThumbnailFile] = useState<undefined | File>(undefined);
  const [isChaosLabsIdVisible, setIsChaosLabsIdVisible] = useState(false);
  const [isAmazonIdVisible, setIsAmazonIdVisible] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [memberData, setMemberData] = useState<TeamMemberData | null>(null);
  const [memberIndex, setMemberIndex] = useState<number | undefined>(undefined);

  const [openModal, setOpenModal] = useState<string | null>(null);
  const { randomAvatar } = useRandomAvatar();

  useFirestoreConnect([
    {
      collection: COLLECTIONS.USERS,
    },
  ]);

  const users = useAppSelector((state) => {
    const { users: dbUsers } = state.firestore.data;

    return (
      dbUsers
      && Object.entries(dbUsers).map(([id, user]) => ({ id, email: user.email }))
    );
  });

  const toggleChaosLabsIdVisibility = () => setIsChaosLabsIdVisible(!isChaosLabsIdVisible);
  const toggleAmazonIdVisibility = () => setIsAmazonIdVisible(!isAmazonIdVisible);

  const closeModal = () => setOpenModal(null);

  const openRemoveModal = (member: TeamMemberData, index?: number) => {
    setMemberData(member);
    setOpenModal('remove');

    if (index !== undefined) {
      setMemberIndex(index);
    }
  };

  const removeTeamMember = () => {
    if (fieldArrayPropsRef.current && memberIndex !== undefined) {
      fieldArrayPropsRef.current.remove(memberIndex);
    }
    closeModal();
  };

  const generateAuthKey = (): string => Buffer.from(uuidv4()).toString('base64');

  const onSubmit = async (
    values: TeamFormValues,
    helpers: FormikHelpers<TeamFormValues>,
  ) => {
    if (tabIndex === 0) {
      setTabIndex(1);
      helpers.setSubmitting(false);
    } else {
      try {
        let thumbnail:FileValue | undefined;
        if (thumbnailFile) {
          thumbnail = await firebaseStorage.uploadFile(thumbnailFile);
        }
        const newTeam = await firestore.collection(COLLECTIONS.TEAMS).add({
          name: values.name,
          protocolBlockchain: values.protocolBlockchain,
          protocolWebsite: values.protocolWebsite,
          org: firestore.collection(COLLECTIONS.ORGS).doc(team?.org.id),
          authKey: generateAuthKey(),
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          permissions: [],
          ...thumbnail ? { thumbnail } : {},
        });

        const inviteMembers = values.members.map((member) => {
          const userId = users.find((user) => user.email === member.email)?.id;

          if (!userId) {
            return Promise.resolve();
          }

          return firestore
            .collection(COLLECTIONS.TEAM_MEMBERSHIP)
            .doc(`${newTeam.id}_${userId}`)
            .set({
              teamId: newTeam.id,
              isAdmin: member.role === 'admin',
              member: firestore.collection(COLLECTIONS.USERS).doc(userId),
              team: firestore.collection(COLLECTIONS.TEAMS).doc(newTeam.id),
              org: firestore.collection(COLLECTIONS.ORGS).doc(team?.org.id),
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            });
        });

        await Promise.all(inviteMembers);

        navigate(RoutePath.Team.Settings, { state: getDisablePromptPushState() });
      } catch (e) {
        console.log('Team create error: ', e);
        helpers.setSubmitting(false);
      }
    }
  };

  const initialValues: TeamFormValues = useMemo(
    () => ({
      id: '',
      thumbnail: undefined,
      name: '',
      protocolBlockchain: '',
      protocolWebsite: '',
      chaoslabsId: '',
      amazonId: '',
      telegram: '',
      discord: '',
      members: [
        {
          email: auth.email || '',
          role: 'admin',
          firstName: '',
          lastName: '',
          uuid: uuidv4(),
        },
      ],
    }),
    [auth],
  );

  const onDrop:TypePropsOnDropFile = useCallback(([acceptedFile]) => {
    setThumbnailFile(() => acceptedFile);
    const file: FileValue = {
      url: URL.createObjectURL(acceptedFile), // data.downloadURL,
      documentId: '',
      fullPath: '',
    };
    return Promise.resolve(file);
  }, []);

  const onDeleteFile: TypePropsOnDeleteFile = useCallback(
    async () => setThumbnailFile(undefined),
    [],
  );

  return (
    <MainLayout
      headerProps={{
        pageTitle: 'Create New Team',
        breadcrumbsLinks: [{
          title: 'Profile',
          href: RoutePath.Profile.Home,
        }],
      }}
    >
      <ConfirmationLeavingDialog message="Changes you made may not be saved." />
      <Formik
        initialValues={initialValues}
        validateOnBlur
        validateOnMount
        validationSchema={validationSchemas[tabIndex]}
        onSubmit={onSubmit}
      >
        {({
          values, isSubmitting, setErrors, isValid,
        }) => {
          const isDeleteMemberButtonDisabled = values.members.length < 2;
          const fieldMaxWidth = 344;
          return (
            <Form autoComplete="off">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box sx={{ mb: 5 }}>
                  <Tabs
                    value={tabIndex}
                    tabs={[{ label: 'Step 1' }, { label: 'Step 2' }]}
                    fullWidth
                  />
                </Box>

                <Box sx={{ display: tabIndex === 0 ? 'block' : 'none' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', md: 'row' },
                      alignItems: 'center',
                      gap: { xs: 3, md: 0 },
                      mb: 5,
                    }}
                  >
                    <FileUpload
                      onDeleteFile={onDeleteFile}
                      onDropFile={onDrop}
                      fallbackPreviewPath={randomAvatar}
                    />
                    <Box
                      sx={{
                        '> :not(:last-of-type)': { mb: 5 },

                        maxWidth: { xs: 'inherit', md: fieldMaxWidth },
                        width: '100%',
                      }}
                    >
                      <Box>
                        <InputField
                          label="Team Name"
                          name="name"
                          placeholder="Enter team name"
                          fullWidth
                        />
                      </Box>
                      <Box>
                        <ReactSelectField
                          name="protocolBlockchain"
                          label="Protocol Blockchain"
                          placeholder="Select protocol blockchain"
                          options={[
                            { label: 'Ethereum', value: 'Ethereum' },
                            { label: 'Terra', value: 'Terra' },
                            { label: 'Moonriver', value: 'Moonriver' },
                            { label: 'Avalanche', value: 'Avalanche' },
                            { label: 'Polygon', value: 'Polygon' },
                            { label: 'NEAR', value: 'NEAR' },
                            { label: 'Cardano', value: 'Cardano' },
                          ]}
                        />
                      </Box>
                      <Box>
                        <InputField
                          label="Protocol Website (Optional)"
                          name="protocolWebsite"
                          placeholder="Enter protocol website"
                          fullWidth
                        />
                      </Box>
                    </Box>
                  </Box>

                  <Typography component="h2" variant="h2" sx={{ py: '36px' }}>
                    IDs
                  </Typography>

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', md: 'row' },
                      gap: { xs: 3, md: 5 },
                      mb: 5,
                    }}
                  >
                    <Box sx={{
                      maxWidth: { xs: 'inherit', md: fieldMaxWidth },
                      width: '100%',
                    }}
                    >
                      <InputField
                        label="Chaos Labs ID"
                        name="chaoslabsId"
                        type={isChaosLabsIdVisible ? 'text' : 'password'}
                        placeholder="Enter ChaosLabs id"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle ChaosLabs id visibility"
                                edge="end"
                                onClick={toggleChaosLabsIdVisibility}
                                size="large"
                              >
                                <CustomIcon
                                  icon={
                                              isChaosLabsIdVisible ? 'eye-off' : 'eye'
                                            }
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>

                    <Box sx={{
                      maxWidth: { xs: 'inherit', md: fieldMaxWidth },
                      width: '100%',
                    }}
                    >
                      <InputField
                        label="Amazon ID"
                        name="amazonId"
                        type={isAmazonIdVisible ? 'text' : 'password'}
                        placeholder="Enter Amazon id"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle Amazon id visibility"
                                edge="end"
                                onClick={toggleAmazonIdVisibility}
                                size="large"
                              >
                                <CustomIcon
                                  icon={isAmazonIdVisible ? 'eye-off' : 'eye'}
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Box>

                  <Typography variant="h2" component="h2" sx={{ py: '36px' }}>
                    Social Media
                  </Typography>

                  <Box sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: { xs: 3, md: 5 },
                  }}
                  >
                    <Box sx={{
                      maxWidth: { xs: 'inherit', md: fieldMaxWidth },
                      width: '100%',
                    }}
                    >
                      <InputField
                        label="Telegram"
                        name="telegram"
                        placeholder="Enter telegram link"
                        fullWidth
                      />
                    </Box>

                    <Box sx={{
                      maxWidth: { xs: 'inherit', md: fieldMaxWidth },
                      width: '100%',
                    }}
                    >
                      <InputField
                        name="discord"
                        label="Discord"
                        placeholder="Enter discord link"
                        fullWidth
                      />
                    </Box>
                  </Box>
                </Box>

                <Box sx={{ display: tabIndex === 0 ? 'none' : 'block' }}>
                  <Typography component="h2" variant="h2" sx={{ py: '36px' }}>
                    Invite Members
                  </Typography>

                  <FieldArray
                    name="members"
                    render={(fieldArrayProps) => {
                      fieldArrayPropsRef.current = fieldArrayProps;
                      return (
                        <>
                          {values.members.map((member, index) => (
                            <TeamMember
                              isNew
                              index={index}
                              member={member}
                              key={member.uuid}
                              isDeleteMemberButtonDisabled={
                                isDeleteMemberButtonDisabled
                              }
                              openRemoveModal={() => openRemoveModal(member, index)}
                            />
                          ))}
                        </>
                      );
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    justifyContent: 'space-between',
                    py: 5,
                    gap: 3,
                    backgroundColor: 'background.default',
                    position: 'sticky',
                    bottom: -40,
                  }}
                >
                  {tabIndex === 1 && (
                  <Button
                    color="secondary"
                    sx={{ order: { xs: 1, md: 0 } }}
                    onClick={() => {
                      setTabIndex(0);
                      setErrors({});
                    }}
                  >
                    Back
                  </Button>
                  )}

                  <Box sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    ml: { xs: 0, md: 'auto' },
                    gap: { xs: 3, md: 5 },
                  }}
                  >
                    {tabIndex === 1 && (
                    <Button
                      color="secondary"
                      onClick={() => {
                        if (fieldArrayPropsRef.current) {
                          fieldArrayPropsRef.current.push({
                            email: '',
                            role: '',
                            firstName: '',
                            lastName: '',
                            uuid: uuidv4(),
                          });
                        }
                      }}
                    >
                      +1 Member
                    </Button>
                    )}
                    <Button
                      type="submit"
                      color="primary"
                      disabled={isSubmitting || !isValid}
                    >
                      {tabIndex === 0 ? 'Next' : 'Create'}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Form>
          );
        }}
      </Formik>

      <ModalWrapper
        open={openModal === 'remove'}
        maxWidth={600}
        onClose={closeModal}
      >
        <RemoveMemberModal
          onClose={closeModal}
          onRemove={removeTeamMember}
          memberData={memberData}
        />
      </ModalWrapper>
    </MainLayout>
  );
});
