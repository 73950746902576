import { RefObject, useEffect } from 'react';
import { IChartApi } from 'lightweight-charts';

export const useLightweightChartResize = (
  chartContainer: RefObject<HTMLDivElement | null>,
  chart: RefObject<IChartApi | null>,
) => {
  useEffect(() => {
    const currContainer = chartContainer.current;
    const resizeObserver = new ResizeObserver(() => {
      const rect = currContainer?.getBoundingClientRect();
      chart.current?.resize(rect?.width || 500, rect?.height || 500);
      chart.current?.timeScale().fitContent();
    });

    if (currContainer) {
      resizeObserver.observe(currContainer);
    }

    return () => {
      if (currContainer) {
        resizeObserver.unobserve(currContainer);
      }
    };
  }, [chart, chartContainer]);
};
