import {
  Box, Button, CustomMultiReactSelect, OptionType, Typography,
} from '@chaos/ui';
import { useMemo, useState } from 'react';
import { CustomCheckbox } from '@chaos/ui/custom-checkbox';
import { promoteModelResults } from 'src/pages/param-recommendations-tool/data/requests';
import { useCurrentTeam } from 'src/hooks';
import { ModelRunResult, PromoteAssetData, PromoteModelResultsResponse } from '../../../../data/types';

type Props = {
  modelResults: ModelRunResult[];
};

const PromoteForm = ({ modelResults }: Props) => {
  const team = useCurrentTeam();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [promoteResponse, setPromoteResponse] = useState<PromoteModelResultsResponse>();
  const assets = modelResults.map((r) => r.metadata.asset_name);
  const [selectedAssets, setSelectedAssets] = useState<string[]>(assets);
  const [
    keepPromotedParametersInUnderReview,
    setKeepPromotedParametersInUnderReview,
  ] = useState(false);

  const assetsPromoteData = useMemo(() => modelResults.map((r) => {
    const { metadata } = r;
    const data: PromoteAssetData = {
      metadata: {
        assetName: metadata.asset_name,
        market: metadata.market_id.toLowerCase(),
        protocol: metadata.protocol.toLowerCase(),
      },
      parametersToPromote: [
        ...Object.keys(r.current.values),
        ...Object.keys(r.current.derived_values || {}),
      ],
      keepPromotedParametersInUnderReview,
    };

    return data;
  }), [keepPromotedParametersInUnderReview, modelResults]);

  const assetsOptions: OptionType[] = assets.map((asset) => ({
    label: asset,
    value: asset,
    cryptoIcon: asset,
  }));

  const onSubmit = async () => {
    if (team?.authKey) {
      setIsSubmitting(true);
      const promoteData = assetsPromoteData.filter(
        (d) => selectedAssets.includes(d.metadata.assetName),
      );
      const response = await promoteModelResults(promoteData, team?.authKey);
      setPromoteResponse(response);
      setIsSubmitting(false);
    }
  };
  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box>
        <Typography variant="caption" fontSize={12}>Assets to Promote</Typography>
        <CustomMultiReactSelect
          controlShouldRenderValue
          options={assetsOptions}
          onChange={(vals) => setSelectedAssets(vals.map((v) => v.value))}
          value={assetsOptions.filter((opt) => selectedAssets.includes(opt.value))}
          placeholder="Assets"
          menuPortalTarget={document.body}
          menuPosition="fixed"
        />
      </Box>
      <Box display="flex" alignItems="center">
        <CustomCheckbox
          checked={keepPromotedParametersInUnderReview}
          onChange={(e) => setKeepPromotedParametersInUnderReview(e.target.checked)}
        />
        <Typography>Keep Promoted Parameters in Under Review</Typography>
      </Box>

      <Box display="flex" gap={2}>
        <Button
          color="primary"
          onClick={() => {
            void onSubmit();
          }}
          disabled={isSubmitting}
        >
          Promote
        </Button>
        {promoteResponse && (
          <Typography color={promoteResponse?.[0]?.errorMessage ? 'red.main' : 'green.main'}>
            {promoteResponse?.[0]?.errorMessage ?? 'Success'}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default PromoteForm;
