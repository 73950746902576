import {
  FC, useEffect, useRef, useState,
} from 'react';
import {
  useFirebase,
  useFirestore,
  useFirestoreConnect,
} from 'react-redux-firebase';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { Box } from '@chaos/ui/box';
import { Button } from '@chaos/ui/button';
import { Typography } from '@chaos/ui/typography';
import { required } from 'src/utils/validators';
import { useAppSelector } from 'src/store';
import { COLLECTIONS, fetchContractABI, CHAINS } from '@chaos/utils';
import { Contract, ContractStatus, Team } from '@chaos/types';
import { InputField } from '../fields';
import { JSONViewer } from '../json-viewer';
import { ModalWrapper } from './modal-wrapper';
import { ConfirmUploadModal } from './confirm-upload-contract-modal';

interface FetchContractFormValues {
  name: string
  address: string
}

const initialValues: FetchContractFormValues = {
  name: '',
  address: '',
};

const validationSchema = Yup.object().shape({
  name: required,
  address: required,
});

interface UploadContractModalProps {
  team: Team
  onClose?: () => void
  contracts: Contract[]
}

export const FetchChainContractModal: FC<UploadContractModalProps> = ({
  team,
  onClose,
  contracts,
}) => {
  const formikHelpersRef = useRef<
  FormikHelpers<FetchContractFormValues> | undefined
  >(undefined);
  // TODO - use contracts to check if fetched contract already exists.
  const { uid } = useAppSelector((state) => state.firebase.auth);
  const firestore = useFirestore();
  const firebase = useFirebase();

  useFirestoreConnect([
    {
      collection: COLLECTIONS.CONTRACTS,
      where: [
        'team',
        '==',
        firestore.collection(COLLECTIONS.TEAMS).doc(team.id),
      ],
    },
  ]);

  const [isContractFetched, setIsContractFetched] = useState(false);
  const [contractUpdateConfirmed, setContractUpdateConfirmed] = useState(false);
  const [confirmUploadModalOpen, setConfirmUploadModalOpen] = useState(false);
  // TODO - contractNotFoundError to indicate of we didn't find contract
  const [contractNotFoundError, setContractNotFoundError] = useState(false);
  const [contractABI, setContractABI] = useState<any[] | undefined>(undefined);

  const confirmContractUpdate = () => {
    setContractUpdateConfirmed(true);
  };
  const openConfirmUploadModal = () => {
    setConfirmUploadModalOpen(true);
  };
  const closeConfirmUploadModal = () => {
    setConfirmUploadModalOpen(false);
    if (formikHelpersRef.current) {
      formikHelpersRef.current.setSubmitting(false);
    }
  };

  const fetchContractAction = (address: string) => {
    setContractNotFoundError(false);
    // TODO - use blockcain from state instead of CHAINS.ETH_MAINNET
    fetchContractABI(address, CHAINS.ETH_MAINNET)
      .then((abi) => {
        setContractABI(abi);
        setIsContractFetched(true);
      })
      .catch((e) => {
        setContractNotFoundError(true);
        setIsContractFetched(false);
        openConfirmUploadModal();
        console.log('fetch contract abi error ', e);
      });
  };

  useEffect(() => {
    if (contractUpdateConfirmed && formikHelpersRef.current) {
      void formikHelpersRef.current.submitForm();
    }
  }, [contractUpdateConfirmed]);

  const onSubmit = (
    values: FetchContractFormValues,
    helpers: FormikHelpers<FetchContractFormValues>,
  ) => {
    const contract = contracts.find((c) => c.contractName === values.name);

    if (contract) {
      if (!contractUpdateConfirmed) {
        formikHelpersRef.current = helpers;
        openConfirmUploadModal();
        return;
      }
      setConfirmUploadModalOpen(false);
      firestore
        .collection(COLLECTIONS.CONTRACTS)
        .doc(contract.id)
        .update({
          contractName: values.name,
          abi: contractABI,
          address: values.address,
          updated: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          onClose?.();
        })
        .catch(console.log);

      return;
    }

    firestore
      .collection(COLLECTIONS.CONTRACTS)
      .add({
        status: ContractStatus.deployed,
        contractName: values.name,
        abi: contractABI,
        address: values.address,
        team: firestore.collection(COLLECTIONS.TEAMS).doc(team.id),
        createdBy: firestore.collection(COLLECTIONS.USERS).doc(uid),
        updated: firebase.firestore.FieldValue.serverTimestamp(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        onClose?.();
      })
      .catch(console.log);
  };

  return (
    <>
      <Formik
        validateOnMount
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values, submitForm, isSubmitting, isValid,
        }) => (
          <>
            <Box component="header">
              <Box className="modal-header" sx={{ py: '36px' }}>
                <Typography variant="h2" component="h2" sx={{ pr: 5 }}>
                  {isContractFetched
                    ? `Fetch ${values.name} contract`
                    : 'Fetch Contract From Chain'}
                </Typography>
              </Box>
            </Box>

            <Box className="modal-body">
              <Form autoComplete="off">
                {isContractFetched ? (
                  <>
                    <Box>
                      <Typography variant="caption">Contract Name</Typography>
                      <Typography sx={{ fontWeight: 'fontWeightLight' }}>
                        {values.name}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption">Address</Typography>
                      <Typography sx={{ fontWeight: 'fontWeightLight' }}>
                        {values.address}
                      </Typography>
                    </Box>

                    <Box>
                      <Typography variant="caption">Preview</Typography>
                      <JSONViewer json={contractABI || []} />
                    </Box>
                  </>
                ) : (
                  <>
                    <Box>
                      <InputField
                        fullWidth
                        name="name"
                        label="Contract Name"
                        placeholder="Enter contract name"
                      />
                    </Box>
                    <Box sx={{ mt: 5 }}>
                      <InputField
                        fullWidth
                        name="address"
                        label="Contract Address"
                        placeholder="Enter contract address"
                      />
                    </Box>
                  </>
                )}
              </Form>
            </Box>
            <Box component="footer">
              <Box className="modal-footer" sx={{ py: 5 }}>
                <Button
                  color="secondary"
                  sx={{ mr: '24px', ml: 'auto' }}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting || !isValid}
                  color="primary"
                  onClick={
                    isContractFetched
                      ? () => submitForm()
                      : () => fetchContractAction(values.address)
                  }
                >
                  {isContractFetched ? 'Save' : 'Fetch'}
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Formik>

      <ModalWrapper
        open={confirmUploadModalOpen}
        onClose={closeConfirmUploadModal}
        maxWidth={600}
      >
        <ConfirmUploadModal
          contractNotFoundError={contractNotFoundError}
          onClose={closeConfirmUploadModal}
          confirmContractUpdate={confirmContractUpdate}
        />
      </ModalWrapper>
    </>
  );
};
