import { createContext } from 'react';
import { Asset } from '@chaos/types';

export interface AssetsContextValue {
  assets: Asset[] | undefined
}

const defaultValue: AssetsContextValue = {
  assets: undefined,
};

export const AssetsContext = createContext<AssetsContextValue>(defaultValue);
