import { FC } from 'react';
import { FormControlLabel, FormGroup } from '@mui/material';
import { Box } from '../box';
import { Checkbox } from '../checkbox';
import { ReactComponent as UncheckedSvg } from '../assets/img/icons/checkbox-off.svg';
import { ReactComponent as CheckedSvg } from '../assets/img/icons/check-square.svg';
import { CryptoIcon } from '../crypto-icon';
import { CustomIcon } from '../custom-icon';

export type TokensFilterProps = {
  tokens: { name: string, customIcon?: string, cryptoIcon?: string, isHidden: boolean }[],
  onChange: (i: number, value: boolean) => void,
};

export const TokensFilter: FC<TokensFilterProps> = ({ tokens, onChange }) => (
  <FormGroup row={tokens.length > 2} sx={{ gap: 3 }}>
    {tokens.map((token, i) => {
      const { isHidden } = tokens[i];

      return (
        <FormControlLabel
          sx={{ flex: '1 0 calc(50% - 24px)', marginRight: 0 }}
          key={token.name}
          control={(
            <Checkbox
              checked={!isHidden}
              size="small"
              icon={<UncheckedSvg />}
              checkedIcon={<CheckedSvg />}
              sx={{ userSelect: 'none' }}
              onChange={(e) => onChange(i, e.target.checked)}
              name={token.name}
            />
          )}
          label={(
            <Box sx={{
              display: 'flex',
              minWidth: '24px',
              pl: 1,
              '& svg, & img': { width: 24, height: 24, marginRight: 1 },
            }}
            >
              {token.customIcon && <CustomIcon icon={token.customIcon} />}
              {token.cryptoIcon && <CryptoIcon icon={token.cryptoIcon} />}
              {token.name}
            </Box>
              )}
        />
      );
    })}
  </FormGroup>
);
