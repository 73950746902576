export const Gradient = () => (
  <svg style={{ height: 0, width: 0 }}>
    <defs>
      {/* https://uigradients.com/#EndlessRiver */}
      <linearGradient
        id="blue"
        x1="0"
        y1="0"
        gradientTransform="rotate(90)"
      >
        <stop offset="0" stopColor="rgba(49, 216, 191, 1)" />
        <stop offset="1" stopColor="#003A75" />
      </linearGradient>
      <linearGradient id="red" x1="0" y1="0" gradientTransform="rotate(90)">
        <stop offset="0" stopColor="rgba(213, 15, 61, 0.6)" />
        <stop offset="1" stopColor="rgba(9, 101, 241, 0)" />
      </linearGradient>
      <linearGradient
        id="kyoopal"
        x1="0"
        y1="0"
        gradientTransform="rotate(90)"
      >
        <stop offset="0" stopColor="#dd3e54" />
        <stop offset="1" stopColor="#6be585" />
      </linearGradient>
      <linearGradient
        id="rose"
        x1="0"
        y1="0"
        gradientTransform="rotate(90)"
      >
        <stop offset="0" stopColor="#E8CBC0" />
        <stop offset="1" stopColor="#636FA4" />
      </linearGradient>
      <linearGradient
        id="green"
        x1="0"
        y1="0"
        gradientTransform="rotate(90)"
      >
        <stop offset="0" stopColor="#2DB574" />
        <stop offset="1" stopColor="#22252C" />
      </linearGradient>
      <linearGradient
        id="pink"
        x1="0"
        y1="0"
        gradientTransform="rotate(90)"
      >
        <stop offset="0" stopColor="#E85DFF" />
        <stop offset="1" stopColor="#22252C" />
      </linearGradient>
      <linearGradient
        id="orange"
        x1="0"
        y1="0"
        gradientTransform="rotate(90)"
      >
        <stop offset="0" stopColor="#DDA52B" />
        <stop offset="1" stopColor="#22252C" />
      </linearGradient>
    </defs>
  </svg>
);
