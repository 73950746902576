import { FC } from 'react';
import { VictoryPie } from 'victory';
import { Box } from '../box';
import { Typography } from '../typography';
import { colorScaleCold, colorScaleWarm } from '../utils/color-scale';
import { chartTooltip } from './chart-tooltip';
import { formatAmount } from '../utils/formatters';
import { Tooltip } from '../tooltip';
import type { DataItem } from './types';
import { CryptoIcon } from '../crypto-icon';

export interface PieChartProps {
  data?: DataItem[]
  isWarm?: boolean
  size?: number
  labelPrefix?: string
  labelSuffix?: string
  hideChartLegends?: boolean
  legendPrefix?: string
  showLegendIcon?: boolean
  format?: 'currency'
  emptyState?: boolean
}

function dataToLabels(data: DataItem[], prefix?: string, suffix?: string) {
  return data.map(
    (item) => `${item.x || ''} - ${prefix || ''}${item.y || ''}${suffix || ''}`,
  );
}

export const PieChart: FC<PieChartProps> = ({
  data,
  isWarm,
  size = 272,
  labelPrefix,
  labelSuffix,
  hideChartLegends,
  showLegendIcon,
  legendPrefix,
  format,
  emptyState,
}) => {
  const colorScale = isWarm ? colorScaleWarm : colorScaleCold;
  const formattedData = data?.map((d) => {
    if (format === 'currency') {
      const xLabelPrefix = d.x ? `${d.x} - ` : '';
      return {
        ...d,
        label: `${xLabelPrefix}${formatAmount(Number(d.y), { currency: 'USD', notation: 'standard' })}`,
      };
    }
    return d;
  });

  if (emptyState) {
    return (
      <Box height={376}>
        <Box display="flex" alignItems="center" justifyContent="center" height="80%">
          <Typography
            variant="h2"
            color="light.main"
          >
            No Data to Show
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: {
          xs: '1fr',
          md: 'max-content 1fr',
        },
        position: 'relative',
        margin: 'auto',
        width: {
          md: '100%',
        },
        svg: {
          display: 'block',
        },
        gap: [2, 4, 6],
      }}
    >
      <Box flex={1} maxWidth={size} mx="auto">
        <VictoryPie
          padding={0}
          data={formattedData}
          width={size}
          height={size}
          innerRadius={size / 4}
          labels={dataToLabels(formattedData || [], labelPrefix, labelSuffix)}
          colorScale={colorScale}
          labelComponent={chartTooltip}
        />
      </Box>

      {data && !hideChartLegends && (
        <Box
          sx={{
            flexGrow: 1,
            gap: [1, 1.5],
            display: 'grid',
            gridTemplateColumns: {
              xs: '1fr',
              md: '1fr 1fr',
              lg: '1fr 1fr 1fr',
            },
          }}
        >
          {data.map((d, i) => {
            const labels = String(d.x).split('\n');

            return (
              <Typography
                component="div"
                display="flex"
                alignItems="center"
                gap={1}
                key={d.x}
                mb={3}
                maxWidth={300}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    width: 8,
                    height: 8,
                    borderRadius: 4,
                    background: colorScaleCold[i % colorScaleCold.length],
                    cursor: 'pointer',
                  }}
                />
                <Box display="flex" gap={1} onClick={d.onClick} sx={{ cursor: d.onClick ? 'pointer' : undefined }}>
                  {showLegendIcon && <CryptoIcon sx={{ ml: 1 }} icon={labels[0].toLowerCase()} />}
                  <Box display="flex" flexDirection="column">
                    {labels.map((l, j) => (
                      <Typography key={l} color={j === 0 ? 'white.main' : undefined} variant={j !== 0 ? 'caption' : undefined} fontSize={j !== 0 ? 12 : undefined}>
                        {l}
                      </Typography>
                    ))}
                  </Box>
                </Box>
                {legendPrefix && <Box sx={{ color: 'almostWhite.main' }}>{legendPrefix}</Box>}
                {format === 'currency' ? (
                  <Tooltip
                    title={formatAmount(Number(d.y), { currency: 'USD', notation: 'standard' })}
                  >
                    <Box sx={{ color: 'white.main' }}>
                      {formatAmount(Number(d.y), { currency: 'USD', notation: 'compact' })}
                    </Box>
                  </Tooltip>
                ) : (
                  <Box sx={{ color: 'white.main' }}>{d.y}</Box>
                )}
              </Typography>
            );
          })}
        </Box>
      )}
    </Box>
  );
};
