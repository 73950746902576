import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMonaco } from '@monaco-editor/react';
import { Box } from '@chaos/ui';
import { MainLayout } from 'src/components/layouts';
import { PageTracker } from 'src/components/page-tracker';
import { RouteParams, RoutePath } from 'src/config/routes';
import { Loader } from 'src/components/loader';
import { Tabs } from '@chaos/ui/tabs';
import { useSimulationContract } from 'src/hooks/useSimulationContract';
import { useBlockchainSimulationResult } from 'src/hooks/useBlockchainSimulationResult';
import { BlockExplorerTransactions } from 'src/components/block-explorer-transactions';
import { MONACO_THEME } from 'src/components/code-editor/config';
import { BlockExplorerAddressContractRead } from './block-explorer-address-contract-read';
import { BlockExplorerAddressContractCode } from './block-explorer-address-contract-code';
import { BlockExplorerAddressContractEvents } from './block-explorer-address-contract-events';

export const BlockExplorerAddressPage = PageTracker(() => {
  const { simulationResultId, address } = useParams<{
    simulationResultId: string, address: string,
  }>();
  const [currentTab, setCurrentTab] = useState(0);
  const monaco = useMonaco();
  const { simulation, result } = useBlockchainSimulationResult(simulationResultId!, true);
  const { contract, isLoading } = useSimulationContract(
    simulationResultId!,
    address!,
    result?.meta?.connectionKey,
  );

  useEffect(() => {
    if (monaco) {
      monaco.editor.defineTheme('custom-theme', {
        ...MONACO_THEME,
        colors: {
          ...MONACO_THEME.colors,
          'editor.background': '#17191E',
        },
      });
    }
  }, [monaco]);

  const breadcrumbsLinks = [{
    title: 'Simulation Results',
    href: RoutePath.Simulations.Results,
  }, {
    title: simulation?.name || '',
    href: RoutePath.Simulations.ResultDetails
      .replace(RouteParams.SimulationResultId, simulationResultId!),
  }, {
    title: 'Block Explorer',
    href: RoutePath.Simulations.BlockExplorer.Home
      .replace(RouteParams.SimulationResultId, simulationResultId!),
  }];

  return (
    <MainLayout headerProps={{
      pageTitle: contract ? `Contract (${contract.contractName})` : 'Address',
      breadcrumbsLinks,
      extraData: { text: address!, allowCopy: true },
    }}
    >
      {!isLoading && (
        <Box>
          {contract && (
            <Box mb={5}>
              <Tabs
                value={currentTab}
                tabs={[
                  { label: 'Transactions' },
                  { label: 'Code' },
                  { label: 'Read Contract' },
                  { label: 'Events' },
                ]}
                onChange={(_, val) => setCurrentTab(val)}
              />
            </Box>
          )}
          {(!contract || currentTab === 0) && (
            <BlockExplorerTransactions
              simulationResultId={simulationResultId!}
              connectionKey={result?.meta?.connectionKey}
              address={address}
            />
          )}
          {contract && currentTab === 1 && <BlockExplorerAddressContractCode contract={contract} />}
          {contract && currentTab === 2 && (
            <BlockExplorerAddressContractRead
              contract={contract}
              externalRemotePoolURL={result?.meta?.externalRemotePoolURL}
            />
          )}
          {contract && currentTab === 3 && (
            <BlockExplorerAddressContractEvents contract={contract} />
          )}
        </Box>
      )}
      {isLoading && <Loader />}
    </MainLayout>
  );
});
