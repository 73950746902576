import {
  InputLabel, Grid, Link, Typography, Box, CustomReactSelect,
} from '@chaos/ui';
import { ScriptResource } from '@chaos/types';
import { FC } from 'react';
import { spells } from './spells';

type SpellsConfigProps = {
  setSelectedInput: (value: React.SetStateAction<Partial<ScriptResource>>) => void,
  selectedInput: Partial<ScriptResource>,
};

type SpellData = {
  address: string,
  label: string,
  value: string,
  description: string
  url: string
};

const spellOptions : SpellData[] = spells.reverse().map((spell) => ({
  address: spell.address,
  label: spell.title,
  value: spell.address,
  description: spell.about,
  url: spell.url,
}));

export const SpellsConfig: FC<SpellsConfigProps> = ({
  setSelectedInput, selectedInput,
}) => {
  const selectedSpell = spellOptions.find(
    ({ value }) => value === selectedInput?.args?.spellAddress?.value,
  );
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <InputLabel>Choose Spell</InputLabel>
        <CustomReactSelect
          name="spell"
          options={spellOptions}
          value={selectedSpell}
          onChange={(selected) => {
            setSelectedInput({
              ...selectedInput,
              args: {
                ...selectedInput.args,
                spellAddress: {
                  description: 'spellAddress',
                  type: 'string',
                  value: selected?.value.toString() || '',
                },
              },
            });
          }}
          placeholder="Select Spell"
          isSearchable
        />
      </Grid>

      {selectedSpell !== undefined
      && (
        <Grid item xs={12}>
          <InputLabel>Details</InputLabel>
          <Box bgcolor="background.default" borderRadius={3} flex={1} marginTop="8px" padding="8px 16px">
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Typography variant="caption">Spell Address</Typography>
                <Typography variant="body1" noWrap>
                  <Link
                    className="gradient-link"
                    href={`https://etherscan.io/address/${selectedSpell.address}`}
                    onClick={(event: React.SyntheticEvent) => event.stopPropagation()}
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {selectedSpell.address}
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption">Vote Link</Typography>
                <Typography
                  variant="body1"
                  noWrap
                >
                  <Link
                    className="gradient-link"
                    href={selectedSpell.url}
                    onClick={(event: React.SyntheticEvent) => event.stopPropagation()}
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {selectedSpell.url}
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption">Details</Typography>
                <Typography
                  variant="body2"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '15',
                    whiteSpace: 'pre-wrap',
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {selectedSpell?.description}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
