import { ChaosApolloProvider } from '../utils/graphql';
import { BorrowSupplyCapTool as BorrowSupplyCapToolComp } from './BorrowSupplyCapTool';

export type BorrowSupplyCapProtocol = 'aave' | 'venus' | 'tashi' | 'seamless' | 'benqi';

type Props = {
  protocol: BorrowSupplyCapProtocol;
  isChainMarket?: boolean;
};

export const BorrowSupplyCapTool = ({ protocol, isChainMarket }: Props) => (
  <ChaosApolloProvider protocol={protocol}>
    <BorrowSupplyCapToolComp isChainMarket={isChainMarket} />
  </ChaosApolloProvider>
);
