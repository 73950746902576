const chainToBlockExplorerUrlMapping: { [chain: string]: string } = {
  Arbitrum_Goerli: 'https://goerli.arbiscan.io/',
  Arbitrum: 'https://arbiscan.io/',
  Avalanche: 'https://snowtrace.io/',
  Binance: 'https://bscscan.com/',
  Fantom: 'https://ftmscan.com/',
  Harmony: 'https://explorer.harmony.one/',
  Optimism: 'https://optimistic.etherscan.io/',
  Polygon: 'https://polygonscan.com/',
  Ethereum: 'https://etherscan.io/',
  Goerli: 'https://goerli.etherscan.io/',
  Metis: 'https://andromeda-explorer.metis.io/',
  Base: 'https://base.blockscout.com/',
  Evmos: 'https://escan.live/',
  Gnosis: 'https://gnosisscan.io/',
};

export const getBlockDetailsUrl = (chain: string, block: string) => `${chainToBlockExplorerUrlMapping[chain]}block/${block}`;
export const getTxDetailsUrl = (chain: string, txHash: string) => `${chainToBlockExplorerUrlMapping[chain]}tx/${txHash}`;
export const getAccountDetailsUrl = (chain: string, accountAddress: string) => `${chainToBlockExplorerUrlMapping[chain]}address/${accountAddress}`;
