import { DashboardPayload } from '@chaos/types';

export interface DashboardState {
  currentDashboard?: DashboardPayload
}

const initialState: DashboardState = {
  currentDashboard: undefined,
};

interface Action {
  type: string
  payload?: DashboardPayload
}

const actionTypes = {
  SET_CURRENT_DASHBOARD: 'dashboard/SET_CURRENT_DASHBOARD',
};

export const setCurrentDashboardAction = (
  dashboard?: DashboardPayload,
) => ({
  type: actionTypes.SET_CURRENT_DASHBOARD,
  payload: dashboard,
});

export function dashboardReducer(
  // eslint-disable-next-line
  state = initialState,
  action: Action,
): DashboardState {
  switch (action.type) {
    case actionTypes.SET_CURRENT_DASHBOARD:
      return {
        ...state,
        currentDashboard: action.payload,
      };
    default:
      return state;
  }
}
