export enum TokenTickerEnum {
  AAVE = 'AAVE',
  ADA = 'ADA',
  ALGO = 'ALGO',
  ATOM = 'ATOM',
  AVAX = 'AVAX',
  AUD = 'AUD',
  BAL = 'BAL',
  BAT = 'BAT',
  BCH = 'BCH',
  BNB = 'BNB',
  BNBBSC = 'BNB.BSC',
  BUSD = 'BUSD',
  ENJ = 'ENJ',
  EOS = 'EOS',
  WBNB = 'WBNB',
  BTC = 'BTC',
  CAD = 'CAD',
  CELO = 'CELO',
  COMP = 'COMP',
  CRV = 'CRV',
  DAI = 'DAI',
  DOT = 'DOT',
  DAIE = 'DAIE',
  DOGE = 'DOGE',
  ETC = 'ETC',
  ETH = 'ETH',
  EUR = 'EUR',
  FRAX = 'FRAX',
  FTM = 'FTM',
  FIL = 'FIL',
  GBP = 'GBP',
  GRT = 'GRT',
  GUSD = 'GUSD',
  HND = 'HND',
  ICP = 'ICP',
  KNC = 'KNC',
  KSM = 'KSM',
  LINK = 'LINK',
  LINKE = 'LINK.E',
  LUNA = 'LUNA',
  LRC = 'LRC',
  LTC = 'LTC',
  MANA = 'MANA',
  MATIC = 'MATIC',
  MOONWELL = 'MOONWELL',
  MFAM = 'MFAM',
  MIM = 'MIM',
  MKR = 'MKR',
  MOVR = 'MOVR',
  MOVR_BW = 'MOVR_BW',
  NEAR = 'NEAR',
  ONE_INCH = '1INCH',
  OP = 'OP',
  PINK = 'PINK',
  QIDAI = 'QIDAI',
  QIETH = 'QIETH ',
  QI = 'QI',
  QIQI = 'QIQI',
  QIAVAX = 'QIAVAX',
  QIUSDC = 'QIUSDC',
  QIUSDT = 'QIUSDT',
  RAND = 'RAND',
  REN = 'REN',
  RENBTC = 'RENBTC',
  ROME = 'ROME',
  RUNE = 'RUNE',
  SAVAX = 'SAVAX',
  SNX = 'SNX',
  SOLAR = 'SOLAR',
  SOL = 'SOL',
  SUSHI = 'SUSHI',
  TUSD = 'TUSD',
  USDT = 'USDT',
  UMA = 'UMA',
  UNI = 'UNI',
  USDTE = 'USDT.E',
  USD = 'USD',
  USDC = 'USDC',
  USDP = 'USDP',
  USDCE = 'USDC.E',
  VETH = 'VETH',
  WBTC = 'WBTC',
  WETH = 'WETH',
  WMOVR = 'WMOVR',
  XLM = 'XLM',
  XMR = 'XMR',
  XRP = 'XRP',
  YFI = 'YFI',
  ZEC = 'ZEC',
  ZRX = 'ZRX',
  PNG = 'PNG',
  UST = 'UST',
  SHIB = 'SHIB',
  AXS = 'AXS',
  APE = 'APE',
  GMT = 'GMT',
  KAVA = 'KAVA',
  ENS = 'ENS',
  THETA = 'THETA',
  CHZ = 'CHZ',
  CRO = 'CRO',
  VET = 'VET',
  CHR = 'CHR',
  WAVES = 'WAVES',
  SAND = 'SAND',
  GALA = 'GALA',
  ZIL = 'ZIL',
  RSR = 'RSR',
}

export enum ChartType {
  AreaChartRow = 'AreaChartRow',
  HistogramChartRow = 'HistogramChartRow',
  OverviewCardRow = 'OverviewCardRow',
  PieChartRow = 'PieChartRow',
  PieChart = 'PieChart',
  PairsRow = 'PairsRow',
  AddressRow = 'AddressRow',
  OverviewWithIconChart = 'OverviewWithIconChart',
  BalanceRow = 'BalanceRow',
  VictoryLineChart = 'VictoryLineChart',
  JSONWidget = 'JSONWidget',
  Table = 'Table',
  DyDxPerpetualFundingRate = 'DyDxPerpetualFundingRate',
  DyDxCalculator = 'DyDxCalculator',
  ScatterChart = 'ScatterChart',
  TokenCards = 'TokenCards',
  RiskScores = 'RiskScores',
  TokenTreeMaps = 'TokenTreeMaps',
}

export enum ChartDataSource {
  ONCHAIN = 'onchain',
  OFFCHAIN = 'offchain',
}
