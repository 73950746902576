import { FC, useMemo } from 'react';
import dayjs from 'dayjs';
import { Header, RenderData } from '@chaos/types';
import { ChaosTable } from '@chaos/ui/chaos-table';
import { RouteParams, RoutePath } from 'src/config/routes';
import { useSimulationTransactions } from 'src/hooks/useSimulationTransactions';
import { Loader } from 'src/components/loader';
import { formatAmount } from '../charts/utils';

type BlockExplorerTransactionsType = {
  simulationResultId: string,
  connectionKey?: string,
  address?: string,
  block?: number,
};

export const BlockExplorerTransactions: FC<BlockExplorerTransactionsType> = ({
  simulationResultId, connectionKey, address, block,
}) => {
  const { isLoading, simulationTransactions } = useSimulationTransactions(
    simulationResultId,
    connectionKey,
  );
  const headers: Header[] = useMemo(() => [{
    renderType: 'STATUS',
    text: 'Status',
  }, {
    renderType: 'TEXT',
    text: 'Txn Hash',
  }, {
    renderType: 'TEXT',
    text: 'Method',
  }, {
    renderType: 'TEXT',
    text: 'Block',
  }, {
    renderType: 'TEXT',
    text: 'Date',
  }, {
    renderType: 'TEXT',
    text: 'From',
  }, {
    renderType: 'TEXT',
    text: 'To',
  }, {
    renderType: 'TEXT',
    text: 'Value',
  }, {
    renderType: 'TEXT',
    text: 'Txn Fee',
  }], []);
  const data = useMemo(() => simulationTransactions
    ?.filter((transaction) => {
      let isShown = true;

      if (address) {
        isShown = transaction.to?.address.toLowerCase() === address
          || transaction.from.address.toLowerCase() === address;
      }

      if (block) {
        isShown = isShown && transaction.blockNumber === block;
      }

      return isShown;
    })
    .map<RenderData[]>((transaction) => [{
    renderType: 'LABELS',
    text: transaction.status === 0 ? 'Reverted' : 'Success',
    status: transaction.status === 0 ? 'ERROR' : 'OK',
  }, {
    renderType: 'LINK',
    text: transaction.hash,
    href: RoutePath.Simulations.BlockExplorer.TransactionDetails
      .replace(RouteParams.SimulationResultId, simulationResultId)
      .replace(RouteParams.TransactionHash, transaction.hash),
  }, {
    renderType: 'TEXT',
    text: transaction.methodName || '',
  }, {
    renderType: 'TEXT',
    text: transaction.blockNumber.toString(),
  }, {
    renderType: 'TEXT',
    text: dayjs(transaction.timestamp * 1000).format('MM/DD/YYYY hh:mm'),
  }, {
    renderType: 'LINK',
    text: transaction.from.name || transaction.from.address,
    copyText: transaction.from.address,
    href: RoutePath.Simulations.BlockExplorer.Address
      .replace(RouteParams.SimulationResultId, simulationResultId)
      .replace(RouteParams.Address, transaction.from.address),
  }, {
    renderType: 'LINK',
    text: transaction.to?.name || transaction.to?.address || '',
    copyText: transaction.to?.address,
    href: transaction.to?.address && RoutePath.Simulations.BlockExplorer.Address
      .replace(RouteParams.SimulationResultId, simulationResultId)
      .replace(RouteParams.Address, transaction.to?.address),
  }, {
    renderType: 'TEXT',
    text: `${transaction.value} Ether`,
  }, {
    renderType: 'TEXT',
    text: transaction.gasUsed ? formatAmount(
      Number(transaction.gasPrice) * transaction.gasUsed,
      { maximumFractionDigits: 10 },
    ) : '0',
    value: transaction.gasUsed ? Number(transaction.gasPrice) * transaction.gasUsed : 0,
  }]), [simulationTransactions, address, simulationResultId, block]);

  return !isLoading ? <ChaosTable data={data} headers={headers} isFullHeight /> : <Loader />;
};
