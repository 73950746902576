import {
  FC, useEffect, useRef, useState,
} from 'react';
import {
  createChart, IChartApi, Point, UTCTimestamp,
} from 'lightweight-charts';
import type { ResponsiveStyleValue } from '@mui/system';
import { Box } from '@chaos/ui/box';
import { Paper } from '@chaos/ui/paper';
import { Typography } from '@chaos/ui/typography';
import { useLightweightChartResize } from 'src/hooks/useLightweightChartResize';

type LightweightLineChartProps = {
  data: {
    time: UTCTimestamp,
    value: number,
    label?: string
  }[],
  valueSuffix?: string,
  hoverBoxLabel?: string,
  height: ResponsiveStyleValue<number | string>,
};

export const LightweightLineChart: FC<LightweightLineChartProps> = ({
  data,
  valueSuffix,
  hoverBoxLabel,
  height,
}) => {
  const chartContainer = useRef<HTMLDivElement>(null);
  const chart = useRef<IChartApi | null>(null);
  const [hoverData, setHoverData] = useState<{ value: string, time: string } & Point>();

  useEffect(() => {
    if (chartContainer.current) {
      const rect = chartContainer.current?.getBoundingClientRect();
      chart.current = createChart(chartContainer.current, {
        width: rect.width || 500,
        height: rect.height || 500,
        layout: {
          backgroundColor: '#000',
          textColor: '#fff',
        },
        timeScale: {
          timeVisible: true,
          secondsVisible: true,
        },
      });
      chart.current.subscribeCrosshairMove((param) => {
        const value = param.seriesPrices.values().next();

        if (value.done) {
          setHoverData(undefined);
        } else {
          setHoverData({
            value: (value.value as number).toFixed(6),
            time: new Date(param.time as number * 1000).toLocaleString('en-US'),
            ...param.point!,
          });
        }
      });
      const lineSeries = chart.current.addLineSeries({
        priceFormat: {
          precision: 5,
          minMove: 0.00001,
        },
      });
      lineSeries.setData(data);
      chart.current.timeScale().fitContent();
    }

    return () => {
      if (chart.current) {
        chart.current.remove();
      }
    };
  }, [data]);

  useLightweightChartResize(chartContainer, chart);
  const extraData = hoverData?.value || (hoverBoxLabel && data[data.length - 1].value.toFixed(6));

  return (
    <Box position="relative" height={height}>
      {extraData && (
        <Paper
          variant="card"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute',
            zIndex: 10,
            p: 1,
            m: 1,
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            top: hoverData?.y || '10%',
            left: hoverData ? hoverData.x - 90 : '10%',
          }}
        >
          <Box>
            <Typography variant="caption">{hoverData?.time || hoverBoxLabel}</Typography>
            <Typography
              variant="body2"
              color={Number(extraData) < 0 ? 'error.main' : 'success.main'}
            >
              {extraData}
              {valueSuffix}
            </Typography>
          </Box>
        </Paper>
      )}
      <Box height={height} ref={chartContainer} />
    </Box>
  );
};

export default LightweightLineChart;
