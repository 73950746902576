import { FC } from 'react';
import { CodeEntity, ScriptResource } from '@chaos/types';
import { useNavigate, useParams } from 'react-router-dom';
import { PageTracker } from 'src/components/page-tracker';
import { EditPageLayout } from 'src/components/layouts';
import { ScriptForm } from 'src/components/script-form';
import { RoutePath } from 'src/config/routes';
import { useBlockchainAgents } from 'src/hooks/useBlockchainAgents';
import { useBlockchainScenarios } from 'src/hooks/useBlockchainScenarios';
import { useBlockchainObservers } from 'src/hooks/useBlockchainObservers';
import { useBlockchainAssertions } from 'src/hooks/useBlockchainAssertions';
import { createBlockchainScriptFn } from 'src/components/dashboard/network';
import { Loader } from 'src/components/loader';
import { useCurrentTeam } from 'src/hooks';
import { useScriptCode } from 'src/hooks/useScriptCode';

type ScriptEditPageProps = {
  type: CodeEntity,
  script?: ScriptResource,
  rootRoute: string,
  allEntities: ScriptResource[]
};

const ScriptEditPage: FC<ScriptEditPageProps> = ({
  type, script, rootRoute, allEntities,
}) => {
  const navigate = useNavigate();
  const team = useCurrentTeam();
  const code = useScriptCode(script?.fullPath);

  return (
    <EditPageLayout
      rootRoute={rootRoute}
      rootTitle={`${type}s`}
      pageTitle={`Edit ${type}`}
      metaTitle={`Edit ${type}`}
    >
      {(code && script) ? (
        <ScriptForm
          type={type}
          allEntities={allEntities}
          initialScript={{
            ...script,
            code,
            args: Object.entries(script.args || {}).reduce((res, [key, value]) => ({
              ...res,
              [key]: typeof value === 'string' ? { type: value } : value,
            }), {}),
          }}
          onSubmit={async (newAgent) => {
            await createBlockchainScriptFn(newAgent, type, team?.authKey);
            navigate(rootRoute);
          }}
          isEditPage
        />
      ) : <Loader />}
    </EditPageLayout>
  );
};

export const AgentEditPage = PageTracker(() => {
  const { blockchainAgents } = useBlockchainAgents();
  const { id } = useParams<{ id: string }>();
  const agent = blockchainAgents.find((curr) => curr.id === id);

  return <ScriptEditPage type="Agent" script={agent} allEntities={blockchainAgents} rootRoute={RoutePath.AgentPlayground.Home} />;
});

export const ScenarioEditPage = PageTracker(() => {
  const { blockchainScenarios } = useBlockchainScenarios();
  const { id } = useParams<{ id: string }>();
  const scenario = blockchainScenarios.find((curr) => curr.id === id);

  return <ScriptEditPage type="Scenario" script={scenario} allEntities={blockchainScenarios} rootRoute={RoutePath.ScenarioCatalogue.Home} />;
});

export const ObserverEditPage = PageTracker(() => {
  const { blockchainObservers } = useBlockchainObservers();
  const { id } = useParams<{ id: string }>();
  const observer = blockchainObservers.find((curr) => curr.id === id);

  return <ScriptEditPage type="Observer" script={observer} allEntities={blockchainObservers} rootRoute={RoutePath.ObserverConfiguration.Home} />;
});

export const AssertionEditPage = PageTracker(() => {
  const { blockchainAssertions } = useBlockchainAssertions();
  const { id } = useParams<{ id: string }>();
  const assertion = blockchainAssertions.find((curr) => curr.id === id);

  return <ScriptEditPage type="Assertion" script={assertion} allEntities={blockchainAssertions} rootRoute={RoutePath.AssertionWizard.Home} />;
});
