import { useEffect, useMemo, useState } from 'react';
import { EconnomicRecommendationTrigger } from '@chaos/types';
import { getSimulationTriggers } from 'src/services/engine';
import { useCurrentTeam } from './useCurrentTeam';

export const useEconnomicRecommendationTriggers = (lastId?: string, pageSize = 30): {
  isLoading: boolean
  econnomicRecommendationTriggers: EconnomicRecommendationTrigger[]
} => {
  const team = useCurrentTeam();
  const [triggers, setTriggers] = useState<EconnomicRecommendationTrigger[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [areAllLoaded, setAreAllLoaded] = useState(false);

  useEffect(() => {
    if (!areAllLoaded && team?.authKey) {
      setIsLoading(true);
      void getSimulationTriggers(team.authKey, pageSize, lastId).then(({
        last_id: hasLastId,
        recommendations: newTriggers,
      }) => {
        if (!hasLastId) {
          setAreAllLoaded(true);
        }

        setTriggers((oldTriggers) => (oldTriggers
          ? [...oldTriggers, ...newTriggers] : newTriggers));
        setIsLoading(false);
      });
    }
  }, [team?.authKey, pageSize, lastId, areAllLoaded]);

  const result = useMemo(
    () => ({ isLoading, econnomicRecommendationTriggers: triggers || [] }),
    [isLoading, triggers],
  );
  return result;
};
