import { useEffect, useMemo, useState } from 'react';
import {
  combineLatest, map, Observable, Subscription,
} from 'rxjs';
import {
  collection, doc, limit, query, where,
} from 'firebase/firestore';
import { collectionData } from 'rxfire/firestore';
import { SimulationContract, SimulationTransaction } from '@chaos/types';
import { COLLECTIONS } from '@chaos/utils';
import { firestore } from 'src/services/firebase';
import { useCurrentTeam } from './useCurrentTeam';

type BlockExplorerSearchResult = { label: string, value: string, type: 'address' | 'transaction' };

export const useBlockExplorerSearch = (text: string, simulationResultId: string): {
  isLoading: boolean, results: BlockExplorerSearchResult[]
} => {
  const team = useCurrentTeam();
  const [results, setResults] = useState<BlockExplorerSearchResult[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const subs: Subscription[] = [];

    if (text) {
      setIsLoading(true);
      const transactionsCol = collection(firestore, COLLECTIONS.ETH_TRANSACTIONS);
      const contractsCol = collection(firestore, COLLECTIONS.SIMULATION_CONTRACTS);
      const transactionsFromAddressObs = collectionData(query(
        transactionsCol,
        where('team', '==', doc(collection(firestore, COLLECTIONS.TEAMS), team?.id)),
        where('simulationResultId', '==', simulationResultId),
        where('from.address', '>=', text),
        where('from.address', '<=', `${text}\uf8ff`),
        limit(100),
      ), { idField: 'id' }) as Observable<SimulationTransaction[]>;
      const transactionsToAddressObs = collectionData(query(
        transactionsCol,
        where('team', '==', doc(collection(firestore, COLLECTIONS.TEAMS), team?.id)),
        where('simulationResultId', '==', simulationResultId),
        where('to.address', '>=', text),
        where('to.address', '<=', `${text}\uf8ff`),
        limit(100),
      ), { idField: 'id' }) as Observable<SimulationTransaction[]>;
      const transactionsHashObs = collectionData(query(
        transactionsCol,
        where('team', '==', doc(collection(firestore, COLLECTIONS.TEAMS), team?.id)),
        where('simulationResultId', '==', simulationResultId),
        where('hash', '>=', text),
        where('hash', '<=', `${text}\uf8ff`),
        limit(100),
      ), { idField: 'id' }) as Observable<SimulationTransaction[]>;
      const contractsObs = collectionData(query(
        contractsCol,
        where('team', '==', doc(collection(firestore, COLLECTIONS.TEAMS), team?.id)),
        where('simulationResultId', '==', simulationResultId),
      ), { idField: 'id' }) as Observable<SimulationContract[]>;
      subs.push(
        combineLatest([
          transactionsFromAddressObs.pipe(
            map((txs) => txs.map<BlockExplorerSearchResult>((t) => ({
              label: t.from.name ? `${t.from.name}  (${t.from.address})` : t.from.address,
              value: t.from.address,
              type: 'address',
            }))),
          ),
          transactionsToAddressObs.pipe(
            map((txs) => txs.map<BlockExplorerSearchResult>((t) => ({
              label: t.to!.name ? `${t.to!.name}  (${t.to!.address})` : t.to!.address,
              value: t.to!.address,
              type: 'address',
            }))),
          ),
          transactionsHashObs.pipe(
            map((txs) => txs.map<BlockExplorerSearchResult>((t) => ({
              label: t.hash,
              value: t.hash,
              type: 'transaction',
            }))),
          ),
          contractsObs.pipe(
            map((cs) => cs.map<BlockExplorerSearchResult>((c) => ({
              label: `${c.contractName} (${c.address})`,
              value: c.address,
              type: 'address',
            }))),
          ),
        ]).subscribe((data) => {
          setResults([...data[0], ...data[1], ...data[2]]
            .reduce<BlockExplorerSearchResult[]>((acc, curr) => {
            if (
              !acc.find((r) => r.value === curr.value)
              && curr.value.toLowerCase().startsWith(text)
              && acc.length < 10
            ) {
              acc.push(curr);
            }

            return acc;
          }, []));
          setIsLoading(false);
        }),
      );
    } else {
      setResults([]);
      setIsLoading(false);
    }

    return () => subs.forEach((s) => s.unsubscribe());
  }, [team?.id, text, simulationResultId]);
  const result = useMemo(() => ({ isLoading, results }), [isLoading, results]);

  return result;
};
