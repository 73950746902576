import { FC, useEffect, useState } from 'react';
import { Slider } from '@chaos/ui/slider';
import { Box } from '@chaos/ui/box';
import { Grid } from '@chaos/ui/grid';
import { TextField } from '@chaos/ui/text-field';
import { InputLabel } from '@chaos/ui/input-label';
import { IconButton } from '@chaos/ui/icon-button';
import { InputAdornment } from '@chaos/ui/input-adornment';
import { TokensFilter } from 'src/components/tokens-filter';
import { formatAmount } from 'src/components/charts/utils';
import { CustomIcon } from 'src/components/custom-icon';
import { CustomMultiReactSelect } from '@chaos/ui/custom-select';
import type {
  Filter, FilterPicker, FilterRange, FilterText,
} from '../table-filter';

interface TableFilterProps {
  filter: Filter
  setFilterValues: React.Dispatch<React.SetStateAction<Record<number, Filter>>>
}

interface TableFilterSliderProps extends TableFilterProps {
  filter: FilterRange
  range: FilterRange['range']
}

interface TableFilterOptionsProps extends TableFilterProps {
  filter: FilterPicker
  options: FilterPicker['options']
}

interface TableFilterTextProps extends TableFilterProps {
  filter: FilterText
}

const SliderTextField: FC<TableFilterSliderProps & { isRight?: boolean }> = ({
  filter, range, setFilterValues, isRight,
}) => (
  <TextField
    sx={{
      input: { background: '#17191e', width: 120 },
      '.MuiOutlinedInput-root': { px: 0 },
      '.MuiOutlinedInput-root:hover': { backgroundColor: 'initial !important' },
      ml: isRight ? 'auto' : undefined,
    }}
    value={formatAmount(range[isRight ? 1 : 0], { notation: 'standard' })}
    InputProps={{
      startAdornment:
        filter.prefix && <InputAdornment position="start">{filter.prefix}</InputAdornment>,
      endAdornment:
        filter.suffix && <InputAdornment position="end">{filter.suffix}</InputAdornment>,
    }}
    onChange={(e) => {
      const value = (e.target.value && Number.isInteger(Number(e.target.value)))
        ? Number(e.target.value) : 0;
      setFilterValues((filterValues) => ({
        ...filterValues,
        [filter.fieldIndex]: {
          ...filter,
          type: 'range',
          range: isRight ? [range[0], value] : [value, range[1]],
          isApplied: filter.range[isRight ? 1 : 0] !== value,
        },
      }));
    }}
  />
);

export const TableFilterSlider: FC<TableFilterSliderProps> = (
  { range, filter, setFilterValues },
) => {
  const minRangeValue = filter.range[0];
  const maxRangeValue = filter.range[1];

  const isRangeValuesChanged = (
    newMinValue: number,
    newMaxValue: number,
  ): boolean => newMinValue !== minRangeValue || newMaxValue !== maxRangeValue;

  return (
    <Box>
      <InputLabel>{filter.fieldName}</InputLabel>
      <Slider
        value={range}
        min={minRangeValue}
        max={maxRangeValue}
        step={filter.stepSize || (maxRangeValue - minRangeValue <= 10 ? 0.01 : 0.1)}
        onChange={(_, val) => {
          const isApplied = Array.isArray(val)
            ? isRangeValuesChanged(val[0], val[1])
            : isRangeValuesChanged(val, val);
          setFilterValues((filterValues) => ({
            ...filterValues,
            [filter.fieldIndex]: {
              ...filter,
              range: Array.isArray(val)
                ? [Math.min(val[0], val[1]), Math.max(val[0], val[1])] : [val, val],
              isApplied,
            },
          }));
        }}
        sx={{
          '.MuiSlider-rail': { backgroundColor: '#777', height: '2px' },
          '.MuiSlider-track': { background: 'linear-gradient(154.16deg, #31D8BF -3.9%, #0965F1 126.56%)', height: '4px', border: 'none' },
          '.MuiSlider-thumb': {
            background: 'linear-gradient(154.16deg, #31D8BF -3.9%, #0965F1 126.56%)',
            height: 24,
            width: 24,
            '&:after': {
              content: '""',
              width: 8,
              height: 8,
              borderRadius: 8,
              background: 'white',
            },
          },
        }}
      />
      <Box display="flex">
        <SliderTextField filter={filter} range={range} setFilterValues={setFilterValues} />
        <SliderTextField filter={filter} range={range} setFilterValues={setFilterValues} isRight />
      </Box>
    </Box>
  );
};

export const TableFilterOptions: FC<TableFilterOptionsProps> = (
  { filter, options, setFilterValues },
) => (
  <Grid item xs={12}>
    <TokensFilter
      tokens={options}
      onChange={(index, isVisible) => setFilterValues((filterValues) => {
        const newOptions = options.map(
          (currOption, i) => ({
            ...currOption,
            isHidden: index === i ? !isVisible : currOption.isHidden,
          }),
        );
        return {
          ...filterValues,
          [filter.fieldIndex]: {
            ...filter,
            options: newOptions,
            isApplied: newOptions.some((option) => option.isHidden),
          },
        };
      })}
    />
  </Grid>
);

export const TableFilterAutocomplete: FC<TableFilterOptionsProps> = (
  { filter, options, setFilterValues },
) => {
  const [currentOptions, setCurrentOptions] = useState(options);
  useEffect(() => setCurrentOptions(options), [options]);

  return (
    <Grid item xs={12}>
      <Box minWidth={200}>
        <CustomMultiReactSelect
          options={options.filter((o) => !o.isFixed).map((o) => ({
            label: o.name, value: o.name, icon: o.icon,
          }))}
          onChange={(vals) => setFilterValues((filterValues) => {
            const newOptions = options.map((o) => ({
              ...o,
              isHidden: !vals.find((v) => v.value === o.name),
            }));
            setCurrentOptions(newOptions);
            return {
              ...filterValues,
              [filter.fieldIndex]: {
                ...filter,
                options: newOptions,
                isApplied: newOptions.some((o) => !o.isHidden),
              },
            };
          })}
          value={currentOptions.filter((o) => !o.isHidden).map((o) => ({
            label: o.name, value: o.name, isFixed: o.isFixed,
          }))}
          placeholder={filter.fieldName}
          closeMenuOnSelect
          menuPortalTarget={document.body}
        />
        <Box display="flex" flexDirection="row" flexWrap="wrap" gap={1} mt={2}>
          {currentOptions.filter((o) => !o.isHidden).map((o) => (
            <Box
              key={o.name}
              bgcolor="background.light"
              display="flex"
              flexDirection="row"
              gap={1}
              px={1}
              borderRadius={2}
            >
              {o.icon && <CustomIcon icon={o.icon} sx={{ svg: { width: 16, height: 16 } }} />}
              {o.name}
              {!o.isFixed && (
                <IconButton
                  sx={{ p: 0 }}
                  onClick={() => setFilterValues((filterValues) => {
                    const newOptions = currentOptions.map(((co) => (o.name === co.name
                      ? { ...co, isHidden: true } : co)));
                    setCurrentOptions(newOptions);
                    return {
                      ...filterValues,
                      [filter.fieldIndex]: {
                        ...filter,
                        options: newOptions,
                        isApplied: newOptions.some((co) => !co.isHidden),
                      },
                    };
                  })}
                >
                  <CustomIcon icon="close" sx={{ svg: { width: 8, height: 8 } }} />
                </IconButton>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </Grid>
  );
};

export const TableFilterText: FC<TableFilterTextProps> = ({ filter, setFilterValues }) => (
  <Box>
    <Box>
      <InputLabel>{filter.fieldName}</InputLabel>
    </Box>
    <TextField
      sx={{ width: '100%', input: { background: '#17191e' } }}
      value={filter.value || ''}
      placeholder={`Enter ${filter.fieldName}`}
      onChange={(e) => setFilterValues((filterValues) => ({
        ...filterValues,
        [filter.fieldIndex]: {
          ...filter, type: 'text', value: e.target.value, isApplied: e.target.value.length > 0,
        },
      }))}
    />
  </Box>
);
