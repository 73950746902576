import {
  DatumValue, Point, Serie, SliceTooltipProps,
} from '@nivo/line';
import { Box } from '@chaos/ui/box';
import { Typography } from '@chaos/ui';
import { colorScale } from 'src/components/charts/utils';

function filterDuplicatesBy<T>(arr: T[], keys: (keyof T)[]) {
  return arr.filter((item, idx) => arr.findIndex((
    item2,
  ) => keys.every((key) => item2[key] === item[key])) === idx);
}
export type LineChartSerie = Serie & { title: string };

type ChartTooltipProps = {
  data: LineChartSerie[];
  tooltipProps: SliceTooltipProps;
  headerLabel?: (xValue: DatumValue) => string;
  hiddenData?: boolean[];
};

type PointMetadata = Record<string, string>;
type PointDataType = Point['data'] & { metadata?: PointMetadata };
export const ChartTooltip = ({
  data, tooltipProps: { slice }, headerLabel, hiddenData,
}: ChartTooltipProps) => (
  <Box
    padding={2}
    bgcolor="background.modal"
    borderRadius={2}
  >
    {slice.points?.[0] && headerLabel && (
      <Box marginBottom={2} marginLeft={2}>{headerLabel(slice.points[0].data.x)}</Box>
    )}
    <Box display="flex" maxWidth={700} gap={2} flexWrap="wrap">
      {filterDuplicatesBy(slice.points, ['serieId']).reduce((elements: React.ReactElement[], point) => {
        const datum = data.find((d) => d.id === point.serieId);
        let title = datum?.title || '';
        const { metadata } = (point.data as PointDataType);

        if (title.includes('0x')) {
          title = `${title.substring(0, title.indexOf('0x') + 3)}...${title.substring(title.length - 3)}`;
        }

        if (datum && hiddenData?.[data.indexOf(datum)]) return elements;
        return [(
          <Box
            key={point.id}
            display="flex"
            gap={1}
            alignItems={metadata ? 'baseline' : 'center'}
          >
            <Box
              bgcolor={datum && colorScale[data.indexOf(datum) % colorScale.length]}
              width={8}
              height={8}
              borderRadius="50%"
            />
            {metadata ? (
              <Box display="flex" flexDirection="column">
                <Box display="flex" gap={0.5}>
                  <Typography variant="caption">
                    {title}
                    :
                  </Typography>
                  <Typography>{point.data.yFormatted}</Typography>
                </Box>
                {Object.entries(metadata)
                  .map(([key, value]: [string, string], idx: number) => (
                    <Box display="flex" gap={0.5} key={`${idx.toString()}-${key}-${value}`}>
                      <Typography variant="caption">
                        {key}
                        :
                      </Typography>
                      <Typography>{value}</Typography>
                    </Box>
                  ))}
              </Box>
            ) : (
              <>
                <Typography variant="caption">
                  {title}
                  :
                </Typography>
                <Typography>{point.data.yFormatted}</Typography>
              </>
            )}

          </Box>
        ), ...elements];
      }, [])}
    </Box>
  </Box>
);
