import { useMemo, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  Autocomplete, Box, Link, Typography,
} from '@chaos/ui';
import { MainLayout } from 'src/components/layouts';
import { PageTracker } from 'src/components/page-tracker';
import { useBlockchainSimulationResult } from 'src/hooks/useBlockchainSimulationResult';
import { useSimulationBlocks } from 'src/hooks/useSimulationBlocks';
import { useSimulationTransactions } from 'src/hooks/useSimulationTransactions';
import { useBlockExplorerSearch } from 'src/hooks/useBlockExplorerSearch';
import { RouteParams, RoutePath } from 'src/config/routes';
import { SearchBar } from 'src/components/search-bar';
import { StatusChip } from 'src/components/status-chip';
import { Loader } from 'src/components/loader';
import { debounce } from 'src/utils/debounce';

export const BlockExplorerPage = PageTracker(() => {
  const navigate = useNavigate();
  const { simulationResultId } = useParams<{ simulationResultId: string }>();
  const {
    simulation, result, isLoading,
  } = useBlockchainSimulationResult(simulationResultId!, true);
  const [inputValue, setInputValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const { results, isLoading: isSearching } = useBlockExplorerSearch(
    searchValue,
    simulationResultId!,
  );
  const { isLoading: isLoadingBlocks, simulationBlocks } = useSimulationBlocks(
    simulationResultId!,
    result?.meta?.connectionKey,
  );
  const { isLoading: isLoadingTransactions, simulationTransactions } = useSimulationTransactions(
    simulationResultId!,
    result?.meta?.connectionKey,
  );
  const blocks = useMemo(() => simulationBlocks?.slice(0, 7), [simulationBlocks]);
  const transactions = useMemo(
    () => simulationTransactions?.slice(0, 7),
    [simulationTransactions],
  );
  const debouncedSearch = useMemo(() => debounce((val: string) => setSearchValue(val), 200), []);

  const breadcrumbsLinks = [{
    title: 'Simulation Results',
    href: RoutePath.Simulations.Results,
  }, {
    title: simulation?.name || '',
    href: RoutePath.Simulations.ResultDetails
      .replace(RouteParams.SimulationResultId, simulationResultId!),
  }];

  return (
    <MainLayout headerProps={{ pageTitle: 'Block Explorer', breadcrumbsLinks }}>
      {(!isLoading && !isLoadingTransactions && !isLoadingBlocks) ? (
        <>
          <Box width={{ xs: '100%', lg: 'calc(50% - 40px)' }} mb={5}>
            <Autocomplete
              inputValue={inputValue}
              renderInput={(params) => (
                <SearchBar
                  {...params}
                  onChange={(e) => {
                    setInputValue(e);
                    debouncedSearch(e.toLowerCase());
                  }}
                  placeholder="Search by address / transaction"
                  loading={isSearching}
                />
              )}
              loading={isSearching}
              options={results}
              getOptionLabel={(option) => option.label}
              onChange={(_, val) => {
                if (val?.type === 'address') {
                  navigate(RoutePath.Simulations.BlockExplorer.Address
                    .replace(RouteParams.SimulationResultId, simulationResultId!)
                    .replace(RouteParams.Address, val.value));
                } else if (val?.type === 'transaction') {
                  navigate(RoutePath.Simulations.BlockExplorer.TransactionDetails
                    .replace(RouteParams.SimulationResultId, simulationResultId!)
                    .replace(RouteParams.TransactionHash, val.value));
                }
              }}
            />
          </Box>
          <Box display="flex" gap={5} flexDirection={{ xs: 'column', lg: 'row' }}>
            <Box width={{ xs: '100%', lg: '50%' }}>
              <Box mb={0.5} borderRadius="16px 16px 0px 0px" bgcolor="#30343B" px={3} py={2}>
                <Typography fontWeight={600}>Latest Blocks</Typography>
              </Box>
              {blocks?.map((block) => (
                <Box
                  key={block.number}
                  bgcolor="#30343B"
                  px={3}
                  py={1.5}
                  borderBottom="1px solid #22252C"
                  display="flex"
                >
                  <Box
                    bgcolor="light.main"
                    width={48}
                    height={48}
                    borderRadius={30}
                    color="background.modal"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexShrink={0}
                  >
                    <Typography fontWeight={600}>BK</Typography>
                  </Box>
                  <Box ml={2} flexGrow={2} flexShrink={0}>
                    <Link
                      component={RouterLink}
                      to={RoutePath.Simulations.BlockExplorer.BlockDetails
                        .replace(RouteParams.SimulationResultId, simulationResultId!)
                        .replace(RouteParams.BlockNumber, block.number.toString())}
                      underline="hover"
                    >
                      <Typography className="gradient-link" fontWeight={400}>{block.number}</Typography>
                    </Link>
                    <Typography variant="caption" color="text.secondary">
                      {dayjs(block.timestamp * 1000).format('MM/DD/YYYY hh:mm')}
                    </Typography>
                  </Box>
                  <Box flexGrow={1} flexShrink={0} ml={2} display="flex" alignItems="center">
                    {block.transactionsCount ? (
                      <Link
                        component={RouterLink}
                        to={RoutePath.Simulations.BlockExplorer.BlockTransactions
                          .replace(RouteParams.SimulationResultId, simulationResultId!)
                          .replace(RouteParams.BlockNumber, block.number.toString())}
                        underline="hover"
                      >
                        <Typography className="gradient-link" fontWeight={400}>
                          {block.transactionsCount}
                          {' '}
                          txns
                        </Typography>
                      </Link>
                    ) : (
                      <Typography fontWeight={400}>
                        {block.transactionsCount}
                        {' '}
                        txns
                      </Typography>
                    )}
                  </Box>
                </Box>
              ))}
              <Box
                bgcolor="background.modal"
                p={3}
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="0px 0px 16px 16px"
              >
                <Link
                  component={RouterLink}
                  to={RoutePath.Simulations.BlockExplorer.Blocks
                    .replace(RouteParams.SimulationResultId, simulationResultId!)}
                  underline="hover"
                >
                  <Typography className="gradient-link" fontWeight={600}>View All Blocks</Typography>
                </Link>
              </Box>
            </Box>
            <Box width={{ xs: '100%', lg: '50%' }}>
              <Box mb={0.5} borderRadius="16px 16px 0px 0px" bgcolor="#30343B" px={3} py={2}>
                <Typography fontWeight={600}>Latest Transactions</Typography>
              </Box>
              {transactions?.map((transaction) => (
                <Box
                  key={transaction.id}
                  bgcolor="#30343B"
                  px={3}
                  py={1.5}
                  borderBottom="1px solid #22252C"
                  display="flex"
                >
                  <Box
                    bgcolor="light.main"
                    width={48}
                    height={48}
                    borderRadius={30}
                    color="background.modal"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexShrink={0}
                  >
                    <Typography fontWeight={600}>TX</Typography>
                  </Box>
                  <Box ml={2} flexShrink={0} maxWidth={150}>
                    <Link
                      component={RouterLink}
                      to={RoutePath.Simulations.BlockExplorer.TransactionDetails
                        .replace(RouteParams.SimulationResultId, simulationResultId!)
                        .replace(RouteParams.TransactionHash, transaction.hash.toLowerCase())}
                      underline="hover"
                    >
                      <Typography className="gradient-link" fontWeight={400} overflow="hidden" textOverflow="ellipsis">
                        {transaction.hash}
                      </Typography>
                    </Link>
                    <Typography variant="caption" color="text.secondary">
                      {dayjs(transaction.timestamp * 1000).format('MM/DD/YYYY hh:mm')}
                    </Typography>
                  </Box>
                  <Box overflow="hidden" flexShrink={1} mx={2}>
                    <Box display="flex" gap={0.5}>
                      <Typography>From</Typography>
                      <Link
                        component={RouterLink}
                        to={RoutePath.Simulations.BlockExplorer.Address
                          .replace(RouteParams.SimulationResultId, simulationResultId!)
                          .replace(RouteParams.Address, transaction.from.address.toLowerCase())}
                        underline="hover"
                        overflow="hidden"
                      >
                        <Typography className="gradient-link" fontWeight={400} overflow="hidden" textOverflow="ellipsis">
                          {transaction.from.address}
                        </Typography>
                      </Link>
                    </Box>
                    {transaction.to && (
                      <Box display="flex" gap={0.5}>
                        <Typography>To</Typography>
                        <Link
                          component={RouterLink}
                          to={RoutePath.Simulations.BlockExplorer.Address
                            .replace(RouteParams.SimulationResultId, simulationResultId!)
                            .replace(RouteParams.Address, transaction.to.address.toLowerCase())}
                          underline="hover"
                          overflow="hidden"
                        >
                          <Typography className="gradient-link" fontWeight={400} overflow="hidden" textOverflow="ellipsis">
                            {transaction.to.name || transaction.to.address}
                          </Typography>
                        </Link>
                      </Box>
                    )}
                  </Box>
                  <Box flex={1} display="flex" alignItems="center" justifyContent="right">
                    <StatusChip label={`${transaction.value} ETH`} status="success" />
                  </Box>
                </Box>
              ))}
              <Box
                bgcolor="background.modal"
                p={3}
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="0px 0px 16px 16px"
              >
                <Link
                  component={RouterLink}
                  to={RoutePath.Simulations.BlockExplorer.Transactions
                    .replace(RouteParams.SimulationResultId, simulationResultId!)}
                  underline="hover"
                >
                  <Typography className="gradient-link" fontWeight={600}>View All Transactions</Typography>
                </Link>
              </Box>
            </Box>
          </Box>
        </>
      ) : <Loader />}
    </MainLayout>
  );
});
