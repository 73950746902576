import { EthABI } from './ethe';

export enum CHAINS {
  ETH_MAINNET = 'eth_mainnet',
}

export async function fetchContractABI(
  address: string,
  chain: CHAINS,
): Promise<Array<any>> {
  switch (chain) {
    case CHAINS.ETH_MAINNET:
      return EthABI(address);
    default:
      throw new Error('unsupported chain');
  }
}
