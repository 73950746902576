import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MainLayout } from 'src/components/layouts';
import { Box } from '@chaos/ui';
import { CustomReactSelect } from '@chaos/ui/custom-select';
import { Loader } from 'src/components/loader';
import { AggregationName, RepetitionsDataType } from '@chaos/types';
import { FetchStorageBlobs } from 'src/hooks';
import { useFirebase } from 'react-redux-firebase';
import { RouteParams, RoutePath } from 'src/config/routes';
import { useSimulationPermutationResults } from 'src/hooks/useSimulationPermuationResults';
import { useBlockchainSimulationRepetitions } from 'src/hooks/useBlockchainSimulationRepetitions';
import {
  Overview, AggregationTable, ObservationBreakdownTable, ObservationsDistribution,
} from './components';
import { useExperimentResult } from './use-experiment-result';
import { aggregateDataOptions } from './utils';

const SimulationExperimentResultsPage = () => {
  const firebase = useFirebase();
  const [
    selectedAggregation,
    setSelectedAggregation,
  ] = useState<AggregationName>('last');
  const {
    simulationResultId, permutationId,
  } = useParams<{ simulationResultId: string, permutationId: string }>();

  const [repetitionsDataByPath, setRepetitionsDataByPath] = useState<
  Record<string, RepetitionsDataType>
  >();
  const [confidenceIntervalOverTimeByPath, setConfidenceIntervalOverTimeByPath] = useState<
  Record<string, string[]>
  >();
  const [isLoadingStorageBlobs, setIsLoadingStorageBlobs] = useState<boolean>();
  const {
    isLoading,
    simulation,
    metricStatistics: oldMetricStatistics,
    permutationInfo: oldPermutationInfo,
  } = useExperimentResult(simulationResultId!, permutationId);
  const { permuationResults } = useSimulationPermutationResults(simulationResultId!);
  const { results: repetitionsResults } = useBlockchainSimulationRepetitions(
    simulationResultId,
    permutationId,
  );

  const { permutationInfo, metricStatistics } = useMemo(
    () => ({
      permutationInfo: permuationResults?.length
        ? permuationResults.find((pr) => pr.id === permutationId) : oldPermutationInfo,
      metricStatistics: permuationResults?.length
        ? permuationResults.find((pr) => pr.id === permutationId)?.metricStatistics || []
        : oldMetricStatistics,
    }),
    [permuationResults, oldPermutationInfo, oldMetricStatistics, permutationId],
  );

  const repetitionsPaths = useMemo(() => (!permuationResults ? metricStatistics?.map((
    statistics,
  ) => statistics.repetitionsDataPath) : undefined), [permuationResults, metricStatistics]);

  const confidenceIntervalOverTimePaths = useMemo(() => metricStatistics?.map((
    statistics,
  ) => statistics.confidenceIntervalOverTimePath), [metricStatistics]);

  useEffect(() => {
    if (!confidenceIntervalOverTimePaths?.length) return;

    void (async () => {
      try {
        setIsLoadingStorageBlobs((prevLoading) => prevLoading === undefined);

        if (repetitionsPaths?.length) {
          const repetitionsRes = await FetchStorageBlobs(firebase, repetitionsPaths);
          setRepetitionsDataByPath(repetitionsRes as Record<string, RepetitionsDataType>);
        }

        const confidenceIntervalOverTimeRes = await FetchStorageBlobs(
          firebase,
          confidenceIntervalOverTimePaths,
        );

        const mappedConfidenceIntervalOverTimeRes = Object.entries(confidenceIntervalOverTimeRes)
          .reduce((acc: Record<string, string[]>, [path, valuesString]: [string, string]) => {
            acc[path] = (valuesString).split(',');
            return acc;
          }, {});

        setConfidenceIntervalOverTimeByPath(mappedConfidenceIntervalOverTimeRes);
        setIsLoadingStorageBlobs(false);
      } catch (error) {
        console.log(error);
        setIsLoadingStorageBlobs(false);
      }
    })();
  }, [repetitionsPaths, confidenceIntervalOverTimePaths, firebase]);

  if (isLoading || isLoadingStorageBlobs) return <Loader />;

  return (
    <MainLayout
      headerProps={{
        pageTitle: 'Experiment Results',
        breadcrumbsLinks: [
          {
            title: 'Simulations Results',
            href: RoutePath.Simulations.Results,
          },
          {
            title: simulation?.name || simulationResultId!,
            href: RoutePath.Simulations.MultiSimulationReport
              .replace(RouteParams.SimulationResultId, simulationResultId!),
          },
        ],
        suffixComponent: <CustomReactSelect
          defaultValue={aggregateDataOptions[0]}
          options={aggregateDataOptions}
          onChange={(val) => {
            if (val?.value) {
              setSelectedAggregation(val.value as AggregationName);
            }
          }}
        />,
      }}
    >
      <Box display="flex" flexDirection="column" gap={7.5}>
        {metricStatistics
        && simulation
        && permutationInfo
        && (permuationResults || repetitionsDataByPath)
        && confidenceIntervalOverTimeByPath && (
          <>
            <Overview
              simulation={simulation}
              permutationInfo={permutationInfo}
              repetitionsCount={permuationResults ? permuationResults.length
                : Object.keys(metricStatistics[0]
                  ? repetitionsDataByPath![metricStatistics[0].repetitionsDataPath]
                  : {}).length}
            />
            <AggregationTable
              aggregationMethod={selectedAggregation}
              simulation={simulation}
              metricStatistics={metricStatistics}
              observationsMetaData={repetitionsResults.map((r) => r.result.observationsMetaData)}
            />
            <ObservationBreakdownTable
              aggregationMethod={selectedAggregation}
              simulation={simulation}
              metricStatistics={metricStatistics}
              repetitionsResults={repetitionsResults}
            />
            <ObservationsDistribution
              aggregationMethod={selectedAggregation}
              simulation={simulation}
              metricStatistics={metricStatistics}
              confidenceIntervalOverTimeByPath={confidenceIntervalOverTimeByPath}
              observationsMetaData={repetitionsResults.map((r) => r.result.observationsMetaData)}
              repetitionsResults={repetitionsResults}
            />
          </>
        )}
      </Box>
    </MainLayout>
  );
};
export default SimulationExperimentResultsPage;
