import {
  useEffect, useState, useRef,
} from 'react';

export const useFallbackUserAvatar = (): string => {
  const isMounted = useRef(false);
  const [loadedImage, setLoadedImage] = useState<string>('');

  useEffect(() => {
    import('../assets/img/agents/5.png')
      .then((data: { default: string }) => {
        setLoadedImage((state) => (!isMounted.current ? data.default : state));
      })
      .catch((error) => console.error('Failed to load avatar', error));

    return () => {
      isMounted.current = true;
    };
  }, []);

  return loadedImage;
};
