import {
  useMemo, useRef, useState, useEffect, useCallback, memo, ReactChild,
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Header, RenderData, RowData } from '@chaos/types';
import { Box } from '@chaos/ui/box';
import { Tooltip } from '@chaos/ui/tooltip';
import { Typography } from '@chaos/ui/typography';
import { IconButton } from '@chaos/ui/icon-button';
import { Link } from '@chaos/ui/link';
import { Menu } from '@chaos/ui/menu';
import { MenuItem } from '@chaos/ui/menu-item';
import { TableSortLabel } from '@chaos/ui/table-sort-label';
import SaveIcon from '@mui/icons-material/Save';
import { usePagination, SortChangeHandler } from 'src/hooks/usePagination';
import { downloadCsv } from 'src/utils/csv';
import { debounce } from 'src/utils/debounce';
import { CustomIcon } from '../custom-icon';
import { KeyValueModal, ModalWrapper } from '../modals';
import { TableFilterButton } from './table-filter-button';
import TableRow from './table-row';
import { Pagination } from '../pagination';
import { SearchBar } from '../search-bar';
import { Loader } from '../loader';
import { ShimmerLoader } from '../shimmer-loader';

interface TableProps {
  titleIcon?: string
  title?: string
  headers: Array<Header>
  data: Array<RenderData[]>
  rowHeight?: number
  description?: string
  tableHeight?: number
  isFilterHidden?: boolean
  isSettingsHidden?: boolean
  pageSize?: number
  initialSortBy?: number
  isInitialSortDesc?: boolean
  isInitialSortEnable?: boolean
  showSearch?: boolean
  serchbarPlaceholder?: string
  showRowChevron?: boolean
  isFullHeight?: boolean
  getRowImageUrl?: (rowId: number) => string | Promise<string>
  rowHref?: (rowId: number) => string | { pathname: string, state?: any }
  rowHrefTarget?: '_blank' | '_self',
  onSortChange?: SortChangeHandler,
  onPageChange?: (pageNumber: number) => void,
  isLoading?: boolean,
  onSearch?: (text: string) => void,
  customFilter?: ReactChild,
  headerSuffixComponent?: React.ReactNode;
}

const TableHeaderWithIcon = (title: string, titleIcon: string) => (
  <Box display="flex" alignItems="center" flexDirection="row">
    <Typography variant="h2">{title}</Typography>
    <Box ml={1} mt={0.5}>
      <CustomIcon sx={{ height: 30, width: 30 }} icon={titleIcon} />
    </Box>
  </Box>
);

const Table = ({
  title,
  headers,
  data,
  titleIcon,
  rowHeight = 72,
  description,
  tableHeight = 600,
  isFilterHidden,
  isSettingsHidden,
  pageSize = 50,
  initialSortBy = -1,
  isInitialSortEnable = true,
  isInitialSortDesc = false,
  showSearch = false,
  serchbarPlaceholder,
  showRowChevron = false,
  isFullHeight,
  getRowImageUrl,
  rowHref,
  rowHrefTarget,
  onSortChange,
  onPageChange,
  isLoading,
  onSearch,
  customFilter,
  headerSuffixComponent,
}: TableProps) => {
  const rowsData: RowData[] = useMemo(() => data.map((
    renderData: RenderData[],
    idx: number,
  ) => ({ renderData, id: idx })), [data]);

  const [searchInputValue, setSearchInputValue] = useState<string>();
  const debouncedSearch = useMemo(
    () => debounce((val?: string) => val !== undefined && onSearch?.(val), 200),
    [onSearch],
  );
  const minWidth = headers.length * 125;
  const [extraData, setExtraData] = useState<{ data?: Record<string, string>[], title?: string }>();
  const [sortBy, setSortBy] = useState<number>(initialSortBy);
  const [isDesc, setIsDesc] = useState(isInitialSortDesc);
  const [isSortEnable, setIsSortEnable] = useState(isInitialSortEnable);
  const [filteredData, setFilteredData] = useState(rowsData);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuEl = useRef<HTMLButtonElement>(null);
  const node = useRef<HTMLDivElement>();
  useEffect(() => setFilteredData(rowsData), [rowsData]);
  const sortedData = useMemo(() => {
    if (isSortEnable === false || sortBy < 0) {
      return filteredData;
    }
    return filteredData
      .filter(({ renderData: row }) => row.map((cell) => cell.text).join('')
        .toLowerCase()
        .includes(onSearch ? '' : searchInputValue?.toLowerCase() || ''))
      .slice().sort(({ renderData: a }, { renderData: b }) => {
        if (!onSortChange) {
          let aValue;
          let bValue;

          if (a[sortBy].extraData?.data) {
            aValue = a[sortBy].extraData?.data?.length || 0;
            bValue = b[sortBy].extraData?.data?.length || 0;
          } else {
            aValue = a[sortBy].value !== undefined ? a[sortBy].value! : a[sortBy].text.toString();
            bValue = b[sortBy].value !== undefined ? b[sortBy].value! : b[sortBy].text.toString();
          }

          if (aValue > bValue) {
            return isDesc ? -1 : 1;
          }

          return isDesc ? 1 : -1;
        }

        return 1;
      });
  }, [filteredData, isDesc, sortBy, isSortEnable, searchInputValue, onSortChange, onSearch]);

  const {
    currentPage, dataForPage, pageCount, setCurrentPage,
  } = usePagination<RowData>(
    sortedData,
    sortedData.length,
    pageSize,
  );

  useEffect(() => {
    const sortField = headers[sortBy]?.field;

    if (onSortChange && sortField) {
      setCurrentPage(1);
      onSortChange(sortField, isDesc ? -1 : 1);
    }
  }, [onSortChange, sortBy, headers, isDesc, setCurrentPage]);
  useEffect(() => onPageChange?.(currentPage), [onPageChange, currentPage]);

  const hasIcon = titleIcon && titleIcon.length > 0;
  const exportToCsv = () => {
    downloadCsv(
      headers.map((header) => header.text.toString()),
      sortedData
        .map(({ renderData: row }) => row.map((cell) => (cell.value || cell.text).toString())),
      title,
    );
  };

  const defaultSortIcon = useCallback(() => <CustomIcon icon="sort-none" />, []);

  const activeSortIcon = useCallback(() => <CustomIcon icon="sort-down" sx={{ transform: !isDesc ? 'rotateX(180deg)' : '  ' }} />, [isDesc]);

  const getSortedIcon = (index: number) => {
    if (sortBy !== index) {
      return defaultSortIcon;
    }

    return activeSortIcon;
  };

  useEffect(() => {
    setCurrentPage(1);
    debouncedSearch(searchInputValue);
  }, [searchInputValue, setCurrentPage, debouncedSearch]);

  const headersForRender = headers.filter((header) => header.renderType !== 'FILTER');

  return (
    <Box
      sx={isFullHeight ? {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      } : undefined}
    >
      {title && (
        <Box display="flex" alignItems="center" mb={2} gap={2}>
          <Box display="flex" flex="1">
            {hasIcon ? TableHeaderWithIcon(title, titleIcon) : (
              <Typography variant="h2">{title}</Typography>
            )}
          </Box>
          {headerSuffixComponent}
          {!isSettingsHidden && (
            <IconButton
              ref={menuEl}
              onClick={() => setIsMenuOpen(true)}
            >
              <CustomIcon icon="more" />
            </IconButton>
          )}
        </Box>
      )}
      {description && <Typography sx={{ mt: 1, mb: 3 }}>{description}</Typography>}
      <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="space-between" mb={3}>
        {customFilter}
        {!(isFilterHidden || rowsData.length <= 10) && (
          <TableFilterButton
            headers={headers}
            data={rowsData}
            onChange={setFilteredData}
            setTablePage={setCurrentPage}
          />
        )}

        {showSearch && (
          <SearchBar
            sx={{
              flexBasis: 440,
              flexSrink: 1,
              ml: 'auto',
            }}
            value={searchInputValue || ''}
            onChange={setSearchInputValue}
            placeholder={serchbarPlaceholder || 'Search'}
          />
        )}
      </Box>
      {dataForPage ? (
        <Box sx={isFullHeight ? {
          flex: 1,
          height: 0,
          display: 'flex',
          flexDirection: 'column',
          overflowX: 'auto',
        } : { overflow: 'auto' }}
        >
          <Box
            display="flex"
            flexDirection="row"
            minWidth={minWidth}
            sx={{
              py: 3,
              mb: 0.5,
              borderTopLeftRadius: '16px',
              borderTopRightRadius: '16px',
              background: '#30343B',
              '.table-header:last-child': {
                paddingRight: '24px',
              },
            }}
          >
            {getRowImageUrl && (
            <Box
              width={0}
              flexBasis={0}
              flexGrow={1}
              flexShrink={1}
            />
            )}
            {headersForRender.map((item) => {
              const i = headers.indexOf(item);

              return (
                <Box
                  key={i}
                  overflow="hidden"
                  minWidth={0}
                  flexBasis={(item.width as string) || `${100 / headersForRender.length}%`}
                  flexGrow={1}
                  flexShrink={1}
                  className="table-header"
                  marginLeft={3}
                >
                  {item.renderType !== 'EMPTY' && (
                  <TableSortLabel
                    IconComponent={getSortedIcon(i)}
                    onClick={() => {
                      if (!item.nonSortable) {
                        setIsSortEnable(() => true);
                        if (sortBy === i) {
                          setIsDesc(!isDesc);
                        } else {
                          setSortBy(headers.indexOf(item));
                          setIsDesc(true);
                        }
                      }
                    }}
                    sx={{
                      svg: {
                        margin: 0, flex: '0 0 24px', display: item.nonSortable ? 'none' : undefined, width: '24px',
                      },
                      width: '100%',
                      cursor: item.nonSortable ? 'default' : 'pointer',
                    }}
                  >
                    {item.tooltip ? (
                      <Tooltip title={item.tooltip} arrow>
                        <Typography color="light.main" fontSize={14}>
                          {item?.text}
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography color="light.main" fontSize={14}>
                        {item?.text}
                      </Typography>
                    )}
                  </TableSortLabel>
                  )}
                </Box>
              );
            })}
            {showRowChevron && (
            <Box
              width={0}
              flexBasis={0}
              flexGrow={1}
              flexShrink={1}
            />
            )}
          </Box>
          <Box
            minWidth={minWidth}
            ref={node}

          >
            <Box
              flex={isFullHeight ? 1 : undefined}
              height={isFullHeight ? '100%' : Math.min(dataForPage.length * rowHeight, tableHeight)
               /* dataForPage.length - 1 is the total rows margin bottom */
               + (dataForPage.length - 1)}
              overflow="scroll"
              position="relative"
            >
              {isLoading && (
                <Box
                  width="100%"
                  display="flex"
                  position="absolute"
                  flexDirection="column"
                  sx={{ opacity: 0.4 }}
                >
                  {dataForPage.map((_, i) => (
                    <ShimmerLoader
                      key={i}
                      sx={{ height: rowHeight }}
                    />
                  ))}
                </Box>
              )}
              {dataForPage.map(({ renderData: row, id }) => {
                const tableRow = (
                  <TableRow
                    key={id}
                    data-test-id="table-row"
                    rowIndex={id}
                    data={row}
                    headers={headersForRender}
                    setExtraData={setExtraData}
                    fixedRowHeight={rowHeight}
                    getRowImageUrl={getRowImageUrl}
                    showRowChevron={showRowChevron}
                  />
                );

                return rowHref ? (
                  <Link
                    key={id}
                    data-test-id="table-row-link"
                    component={RouterLink}
                    to={rowHref(id)}
                    target={rowHrefTarget}
                    color="inherit"
                    underline="none"
                    display="block"
                    sx={{
                      mb: '1px',
                      '&:last-of-type': {
                        mb: 0,
                      },
                      cursor: 'pointer',
                    }}
                  >
                    {tableRow}
                  </Link>
                ) : tableRow;
              })}
            </Box>
          </Box>
          {pageCount > 1 && (
          <Box p="12px 24px" bgcolor="background.modal" borderRadius="0 0 8px 8px" position="sticky" bottom={0} left={0} zIndex={10}>
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={(_, page) => setCurrentPage(page)}
            />
          </Box>
          )}
        </Box>
      ) : <Loader />}
      <ModalWrapper open={!!extraData} onClose={() => setExtraData(undefined)} maxWidth={800}>
        <KeyValueModal
          title={extraData?.title || 'Data'}
          onClose={() => setExtraData(undefined)}
          json={extraData?.data || []}
        />
      </ModalWrapper>
      <Menu
        anchorEl={menuEl.current}
        open={isMenuOpen}
        onClose={() => {
          setIsMenuOpen(false);
        }}
        onClick={() => {
          setIsMenuOpen(false);
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={exportToCsv}>
          <SaveIcon />
          <Typography ml={1} component="span">Export to CSV</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export const ChaosTable = memo(Table);
