import { BlockchainSimulationResult } from '@chaos/types';

export const blockchaiSimulationResultCounts = (
  simulationResult?: BlockchainSimulationResult,
): { success: number; failed: number } => {
  if (
    !simulationResult
    || !simulationResult.result
    || !simulationResult.result.assertions
    || !Array.isArray(simulationResult.result.assertions)
  ) {
    return {
      success: 0,
      failed: 0,
    };
  }
  let success = 0;
  let failed = 0;

  simulationResult.result.assertions.forEach((res) => {
    if (res.success) {
      success += 1;
    } else {
      failed += 1;
    }
  });
  return {
    success,
    failed,
  };
};
