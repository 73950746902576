import BitcoinLogoImage from '../assets/img/bitcoin-logo.svg';
import EthereumImage from '../assets/img/icons/ethereum-logo.svg';
import SolImage from '../assets/img/crypto/sol.svg';
import AaveImage from '../assets/img/crypto/aave.svg';
import AvaxImage from '../assets/img/crypto/avax.svg';
import CeloImage from '../assets/img/crypto/celo.svg';
import CompoundImage from '../assets/img/crypto/compound.svg';
import DaiImage from '../assets/img/crypto/dai.svg';
import sDaiImage from '../assets/img/crypto/sdai.svg';
import ShibImage from '../assets/img/crypto/shib.svg';
import AxsImage from '../assets/img/crypto/axs.svg';
import ApeImage from '../assets/img/crypto/ape.svg';
import GmtImage from '../assets/img/crypto/gmt.svg';
import KavaImage from '../assets/img/crypto/kava.svg';
import EnsImage from '../assets/img/crypto/ens.svg';
import ThetaImage from '../assets/img/crypto/theta.svg';
import ChzImage from '../assets/img/crypto/chz.svg';
import CroImage from '../assets/img/crypto/cro.svg';
import VetImage from '../assets/img/crypto/vet.svg';
import ChrImage from '../assets/img/crypto/chr.svg';
import LinkImage from '../assets/img/crypto/link.svg';
import LunaImage from '../assets/img/crypto/luna.svg';
import MaticImage from '../assets/img/crypto/matic.svg';
import MaticXImage from '../assets/img/crypto/maticx.svg';
import StMaticImage from '../assets/img/crypto/stmatic.svg';
import MakerImage from '../assets/img/crypto/maker.svg';
import QntImage from '../assets/img/crypto/qnt.svg';
import BoneImage from '../assets/img/crypto/bone.svg';
import EurocImage from '../assets/img/crypto/euroc.svg';
import LdoImage from '../assets/img/crypto/ldo.svg';
import LooksImage from '../assets/img/crypto/looks.svg';
import RplImage from '../assets/img/crypto/rpl.svg';
import GhoImage from '../assets/img/crypto/gho.svg';
import MakerDaoImage from '../assets/img/crypto/makerdao.svg';
import TheGraphImage from '../assets/img/crypto/the-graph.svg';
import UniImage from '../assets/img/crypto/uni.svg';
import UniswapImage from '../assets/img/crypto/uniswap.svg';
import HathorImage from '../assets/img/crypto/hathor-logo.svg';
import UsdtImage from '../assets/img/crypto/usdt.svg';
import StUsdtImage from '../assets/img/crypto/stusdt.svg';
import UsdcImage from '../assets/img/crypto/usdc.svg';
import SusdImage from '../assets/img/crypto/susd.svg';
import AlgorandImage from '../assets/img/crypto/algorand.svg';
import ZrxImage from '../assets/img/crypto/zrx.svg';
import BatImage from '../assets/img/crypto/bat.svg';
import DotImage from '../assets/img/crypto/dot.svg';
import CrvImage from '../assets/img/crypto/crv.svg';
import CrvUSDImage from '../assets/img/crypto/crvusd.svg';
import GhstImage from '../assets/img/crypto/ghst.svg';
import JeurImage from '../assets/img/crypto/jeur.svg';
import DpiImage from '../assets/img/crypto/dpi.svg';
import BalImage from '../assets/img/crypto/bal.svg';
import AgeurImage from '../assets/img/crypto/ageur.svg';
import EursImage from '../assets/img/crypto/eurs.svg';
import WoneImage from '../assets/img/crypto/wone.svg';
import BnbImage from '../assets/img/crypto/bnb.svg';
import WbnbImage from '../assets/img/crypto/wbnb.svg';
import MoonriverImage from '../assets/img/crypto/movr.svg';
import KsmImage from '../assets/img/crypto/ksm.svg';
import BUSDImage from '../assets/img/crypto/busd.svg';
import FraxImage from '../assets/img/crypto/frax.svg';
import MIMImage from '../assets/img/crypto/mim.svg';
import SolarImage from '../assets/img/crypto/solar.svg';
import RomeImage from '../assets/img/crypto/rome.svg';
import HundredFinanceImage from '../assets/img/crypto/hnd.svg';
import FantomImage from '../assets/img/crypto/fantom.svg';
import HarmonyImage from '../assets/img/crypto/harmony.svg';
import PinkImage from '../assets/img/crypto/pink.svg';
import MovrBWImage from '../assets/img/crypto/movr_bw.svg';
import DogeImage from '../assets/img/crypto/doge.svg';
import LtcImage from '../assets/img/crypto/ltc.svg';
import BchImage from '../assets/img/crypto/bch.svg';
import AtomImage from '../assets/img/crypto/atom.svg';
import XlmImage from '../assets/img/crypto/xlm.svg';
import XmrImage from '../assets/img/crypto/xmr.svg';
import EtcImage from '../assets/img/crypto/etc.svg';
import IcpImage from '../assets/img/crypto/icp.svg';
import FilImage from '../assets/img/crypto/fil.svg';
import EosImage from '../assets/img/crypto/eos.svg';
import RuneImage from '../assets/img/crypto/rune.svg';
import ZecImage from '../assets/img/crypto/zec.svg';
import SnxImage from '../assets/img/crypto/snx.svg';
import EnjImage from '../assets/img/crypto/enj.svg';
import YfiImage from '../assets/img/crypto/yfi.svg';
import StethImage from '../assets/img/crypto/steth.svg';
import OneInchImage from '../assets/img/crypto/1inch.svg';
import UmaImage from '../assets/img/crypto/uma.svg';
import SavaxImage from '../assets/img/crypto/savax.svg';
import PolygonImage from '../assets/img/crypto/polygon.svg';
import MetisImage from '../assets/img/crypto/metis.svg';
import WbtcImage from '../assets/img/crypto/wbtc.svg';
import SushiImage from '../assets/img/icons/sushi.svg';
import NearImage from '../assets/img/icons/near.svg';
import ArbitrumImage from '../assets/img/crypto/arbitrum.svg';
import SolanaImage from '../assets/img/icons/solana.svg';
import AvalancheImage from '../assets/img/icons/avalanche.svg';
import BitcoinImage from '../assets/img/crypto/bitcoin.svg';
import MoonwellImage from '../assets/img/crypto/moonwell.svg';
import QIImage from '../assets/img/crypto/qi.svg';
import BenqiImage from '../assets/img/crypto/benqi.svg';
import OptimismImage from '../assets/img/crypto/op.svg';
import WETHImage from '../assets/img/icons/weth_logo.svg';
import EthereumTokenImage from '../assets/img/crypto/ethereum.svg';
import CardanoImage from '../assets/img/icons/cardano.svg';
import TerraImage from '../assets/img/crypto/terra.svg';
import DyDxImage from '../assets/img/crypto/dydx.svg';
import DefiKingdomsImage from '../assets/img/crypto/jewel.svg';
import PangolinImage from '../assets/img/crypto/pangolin.svg';
import WavesImage from '../assets/img/crypto/waves.svg';
import GusdImage from '../assets/img/crypto/gusd.svg';
import SandboxImage from '../assets/img/crypto/sandbox.svg';
import ManaImage from '../assets/img/crypto/mana.svg';
import GalaImage from '../assets/img/crypto/gala.svg';
import ZilImage from '../assets/img/crypto/zil.svg';
import RsrImage from '../assets/img/crypto/rsr.svg';
import LrcImage from '../assets/img/crypto/lrc.svg';
import MaiImage from '../assets/img/crypto/mimatic.svg';
import OsmoImage from '../assets/img/crypto/osmo.svg';
import HuahuaImage from '../assets/img/crypto/huahua.svg';
import BldImage from '../assets/img/crypto/bld.svg';
import TgdImage from '../assets/img/crypto/tgd.svg';
import DigImage from '../assets/img/crypto/dig.svg';
import UmeeImage from '../assets/img/crypto/umee.svg';
import MntlImage from '../assets/img/crypto/mntl.svg';
import SwthImage from '../assets/img/crypto/swth.svg';
import StAtomImage from '../assets/img/crypto/statom.svg';
import StrdImage from '../assets/img/crypto/strd.svg';
import AxlImage from '../assets/img/crypto/axl.svg';
import DevImage from '../assets/img/crypto/dev.svg';
import SommImage from '../assets/img/crypto/somm.svg';
import DarcImage from '../assets/img/crypto/darc.svg';
import DesmosImage from '../assets/img/crypto/desmos.svg';
import BandImage from '../assets/img/crypto/band.svg';
import VdlImage from '../assets/img/crypto/vdl.svg';
import LumImage from '../assets/img/crypto/lum.svg';
import CheqImage from '../assets/img/crypto/cheq.svg';
import CmdxImage from '../assets/img/crypto/cmdx.svg';
import MedImage from '../assets/img/crypto/med.svg';
import XkiImage from '../assets/img/crypto/xki.svg';
import BtsgImage from '../assets/img/crypto/btsg.svg';
import BcnaImage from '../assets/img/crypto/bcna.svg';
import EeurImage from '../assets/img/crypto/eeur.svg';
import NgmImage from '../assets/img/crypto/ngm.svg';
import IovImage from '../assets/img/crypto/iov.svg';
import DvpnImage from '../assets/img/crypto/dvpn.svg';
import RegenImage from '../assets/img/crypto/regen.svg';
import PstakeImage from '../assets/img/crypto/pstake.svg';
import XprtImage from '../assets/img/crypto/xprt.svg';
import StarsImage from '../assets/img/crypto/stars.svg';
import EvmosImage from '../assets/img/crypto/evmos.svg';
import IosImage from '../assets/img/crypto/ios.svg';
import JunoImage from '../assets/img/crypto/juno.svg';
import ScrtImage from '../assets/img/crypto/scrt.svg';
import AktImage from '../assets/img/crypto/akt.svg';
import LikeImage from '../assets/img/crypto/like.svg';
import InjImage from '../assets/img/crypto/inj.svg';
import FetImage from '../assets/img/crypto/fet.svg';
import IrisImage from '../assets/img/crypto/iris.svg';
import GravImage from '../assets/img/crypto/grav.svg';
import KujiImage from '../assets/img/crypto/kuji.svg';
import IonImage from '../assets/img/crypto/ion.svg';
import DecImage from '../assets/img/crypto/dec.svg';
import JklImage from '../assets/img/crypto/jkl.svg';
import ToriImage from '../assets/img/crypto/tori.svg';
import IstImage from '../assets/img/crypto/ist.svg';
import RebusImage from '../assets/img/crypto/rebus.svg';
import CudosImage from '../assets/img/crypto/cudos.svg';
import UstcImage from '../assets/img/crypto/ustc.svg';
import StStarsImage from '../assets/img/crypto/stSTARS.svg';
import StJunoImage from '../assets/img/crypto/stJUNO.svg';
import StOsmoImage from '../assets/img/crypto/stOSMO.svg';
import UsdtGrvImage from '../assets/img/crypto/USDTgrv.svg';
import UsdcGrvImage from '../assets/img/crypto/USDCgrv.svg';
import RowanImage from '../assets/img/crypto/rowan.svg';
import NetaImage from '../assets/img/crypto/neta.svg';
import GltoImage from '../assets/img/crypto/glto.svg';
import OdinImage from '../assets/img/crypto/odin.svg';
import LvnImage from '../assets/img/crypto/lvn.svg';
import LambImage from '../assets/img/crypto/lamb.svg';
import SeJunoImage from '../assets/img/crypto/seJUNO.svg';
import GkeyImage from '../assets/img/crypto/gkey.svg';
import WstEthImage from '../assets/img/crypto/wsteth.svg';
import CbEthImage from '../assets/img/crypto/cbeth.svg';
import GmxImage from '../assets/img/crypto/gmx.svg';
import CvxImage from '../assets/img/crypto/cvx.svg';
import FeiImage from '../assets/img/crypto/fei.svg';
import KncImage from '../assets/img/crypto/knc.svg';
import LusdImage from '../assets/img/crypto/lusd.svg';
import PaxImage from '../assets/img/crypto/pax.svg';
import RaiImage from '../assets/img/crypto/rai.svg';
import RenImage from '../assets/img/crypto/ren.svg';
import RenfilImage from '../assets/img/crypto/renfil.svg';
import TusdImage from '../assets/img/crypto/tusd.svg';
import UsdpImage from '../assets/img/crypto/usdp.svg';
import XsushiImage from '../assets/img/crypto/xsushi.svg';
import AmplImage from '../assets/img/crypto/ampl.svg';
import RethImage from '../assets/img/crypto/reth.svg';
import BobImage from '../assets/img/crypto/bob.svg';
import XrpImage from '../assets/img/crypto/xrp.svg';
import XvsImage from '../assets/img/crypto/xvs.svg';
import TrxImage from '../assets/img/crypto/trx.svg';
import WbethImage from '../assets/img/crypto/wbeth.svg';
import SfrxEthImage from '../assets/img/crypto/sfrxeth.svg';
import SxpImage from '../assets/img/crypto/sxp.svg';
import CakeImage from '../assets/img/crypto/cake.svg';
import BethImage from '../assets/img/crypto/beth.svg';
import UsdoImage from '../assets/img/crypto/usdo.svg';
import ConsideredFinanceImage from '../assets/img/considered-finance.svg';
import RadiantImage from '../assets/img/crypto/radiant.svg';
import RdntImage from '../assets/img/crypto/rdnt.svg';
import FtmLPMImage from '../assets/img/crypto/ftmlpm.svg';
import MaticLPMImage from '../assets/img/crypto/maticlpm.svg';
import GLPMImage from '../assets/img/crypto/glpm.svg';
import AvaxLPMImage from '../assets/img/crypto/avaxlpm.svg';
import VenusImage from '../assets/img/crypto/venus.svg';
import TapiocaImage from '../assets/img/crypto/tapioca.svg';
import BttImage from '../assets/img/crypto/btt.svg';
import UsddImage from '../assets/img/crypto/usdd.svg';
import WinImage from '../assets/img/crypto/win.svg';
import AnkrbnbImage from '../assets/img/crypto/ankrbnb.svg';
import StkbnbImage from '../assets/img/crypto/stkbnb.svg';
import FlokiImage from '../assets/img/crypto/floki.svg';
import AnkrImage from '../assets/img/crypto/ankr.svg';
import BswImage from '../assets/img/crypto/bsw.svg';
import AlpacaImage from '../assets/img/crypto/alpaca.svg';
import HayImage from '../assets/img/crypto/HAY.svg';
import RacaImage from '../assets/img/crypto/RACA.svg';
import BnbxImage from '../assets/img/crypto/BNBx.svg';
import XaiImage from '../assets/img/crypto/xai.svg';
import UzdImage from '../assets/img/crypto/uzd.svg';
import DolaImage from '../assets/img/crypto/dola.svg';
import QuintaImage from '../assets/img/crypto/quinta.svg';
import ThreeCrvImage from '../assets/img/crypto/3crv.svg';
import KvtImage from '../assets/img/crypto/kvt.svg';
import TBtcImage from '../assets/img/crypto/tbtc.svg';
import UsdbcImage from '../assets/img/crypto/usdbc.svg';
import BaseImage from '../assets/img/crypto/base.svg';
import StgImage from '../assets/img/crypto/stg.svg';
import FxsImage from '../assets/img/crypto/fxs.svg';
import SnbnbImage from '../assets/img/crypto/snbnb.svg';
import TwtImage from '../assets/img/crypto/twt.svg';
import TashiImage from '../assets/img/crypto/tashi.svg';
import SeamlessImage from '../assets/img/crypto/seamless.svg';
import EureImage from '../assets/img/crypto/eure.svg';
import GnoImage from '../assets/img/crypto/gno.svg';
import GnosisImage from '../assets/img/crypto/gnosis.svg';
import XdaiImage from '../assets/img/crypto/xdai.svg';
import PlanetImage from '../assets/img/crypto/planet.svg';

export const getIconSrc: (iconName: string) => string | null = (iconName) => {
  const loweCaseIconName = iconName.toLowerCase();
  switch (loweCaseIconName) {
    case 'btc':
    case 'btc.b':
    case 'btcb':
      return BitcoinImage;
    case 'btc-logo':
      return BitcoinLogoImage;
    case 'dydx':
      return DyDxImage;
    case 'polygon':
    case 'polygon_mumbai':
      return PolygonImage;
    case 'metis':
      return MetisImage;
    case 'mfam':
      return MoonwellImage;
    case 'moonwell':
      return MoonwellImage;
    case 'sushi':
    case 'sushiswap':
      return SushiImage;
    case 'wbtc':
    case 'wbtc.e':
      return WbtcImage;
    case 'qibtc':
      return BitcoinImage;
    case 'weth.e':
    case 'qieth':
    case 'twethm':
    case 'wethm':
    case 'weth':
      return WETHImage;
    case 'wsteth':
      return WstEthImage;
    case 'cbeth':
      return CbEthImage;
    case 'xrp':
      return XrpImage;
    case 'xvs':
      return XvsImage;
    case 'trx':
    case 'trxold':
      return TrxImage;
    case 'wbeth':
      return WbethImage;
    case 'sxp':
      return SxpImage;
    case 'cake':
      return CakeImage;
    case 'beth':
      return BethImage;
    case 'benqi-logo':
      return BenqiImage;
    case 'benqi':
      return QIImage;
    case 'qiqi':
      return QIImage;
    case 'qi':
      return QIImage;
    case 'pink':
      return PinkImage;
    case 'solar':
      return SolarImage;
    case 'hnd':
      return HundredFinanceImage;
    case 'mim':
      return MIMImage;
    case 'pangolin':
      return PangolinImage;
    case 'png':
      return PangolinImage;
    case 'rome':
      return RomeImage;
    case 'frax':
    case 'fraxlendv1 - crv/frax':
      return FraxImage;
    case 'sfrxeth':
    case 'sfrxeth-v1':
    case 'sfrxeth-v2':
      return SfrxEthImage;
    case 'wftm':
    case 'tftm':
    case 'tftmm':
    case 'ftm':
    case 'ftmm':
    case 'fantom':
    case 'fantom_testnet':
      return FantomImage;
    case 'harmony':
      return HarmonyImage;
    case 'ksm':
      return KsmImage;
    case 'wbnb':
      return WbnbImage;
    case 'bnb':
    case 'binance':
      return BnbImage;
    case 'bnb.bsc':
      return BnbImage;
    case 'busd':
      return BUSDImage;
    case 'movr_bw':
      return MovrBWImage;
    case 'wmovr':
    case 'movr':
    case 'moonriver':
      return MoonriverImage;
    case 'near':
      return NearImage;
    case 'arbitrum':
    case 'arb':
    case 'arbitrum_goerli':
      return ArbitrumImage;
    case 'solana':
      return SolanaImage;
    case 'avalanche':
    case 'avalanche_fuji':
      return AvalancheImage;
    case 'huahua':
      return HuahuaImage;
    case 'bld':
      return BldImage;
    case 'tgd':
      return TgdImage;
    case 'dig':
      return DigImage;
    case 'umee':
      return UmeeImage;
    case 'mntl':
      return MntlImage;
    case 'swth':
      return SwthImage;
    case 'statom':
      return StAtomImage;
    case 'strd':
      return StrdImage;
    case 'axl':
      return AxlImage;
    case 'dev':
      return DevImage;
    case 'somm':
      return SommImage;
    case 'darc':
      return DarcImage;
    case 'desmos':
    case 'dsm':
      return DesmosImage;
    case 'band':
      return BandImage;
    case 'vdl':
      return VdlImage;
    case 'lum':
      return LumImage;
    case 'cheq':
      return CheqImage;
    case 'cmdx':
      return CmdxImage;
    case 'med':
      return MedImage;
    case 'xki':
      return XkiImage;
    case 'btsg':
      return BtsgImage;
    case 'bcna':
      return BcnaImage;
    case 'eeur':
      return EeurImage;
    case 'ngm':
      return NgmImage;
    case 'iov':
      return IovImage;
    case 'dvpn':
      return DvpnImage;
    case 'regen':
      return RegenImage;
    case 'pstake':
      return PstakeImage;
    case 'xprt':
      return XprtImage;
    case 'stars':
      return StarsImage;
    case 'evmos':
      return EvmosImage;
    case 'ios':
      return IosImage;
    case 'juno':
      return JunoImage;
    case 'scrt':
      return ScrtImage;
    case 'akt':
      return AktImage;
    case 'like':
      return LikeImage;
    case 'inj':
      return InjImage;
    case 'fet':
      return FetImage;
    case 'iris':
      return IrisImage;
    case 'grav':
      return GravImage;
    case 'kuji':
      return KujiImage;
    case 'ion':
      return IonImage;
    case 'dec':
      return DecImage;
    case 'stosmo':
      return StOsmoImage;
    case 'jkl':
      return JklImage;
    case 'tori':
      return ToriImage;
    case 'ist':
      return IstImage;
    case 'rebus':
      return RebusImage;
    case 'cudos':
      return CudosImage;
    case 'ustc':
      return UstcImage;
    case 'ststars':
      return StStarsImage;
    case 'stjuno':
      return StJunoImage;
    case 'usdt.grv':
      return UsdtGrvImage;
    case 'usdc.grv':
      return UsdcGrvImage;
    case 'rowan':
      return RowanImage;
    case 'neta':
      return NetaImage;
    case 'glto':
      return GltoImage;
    case 'odin':
      return OdinImage;
    case 'lvn':
      return LvnImage;
    case 'lamb':
      return LambImage;
    case 'sejuno':
      return SeJunoImage;
    case 'gkey':
      return GkeyImage;
    case 'veth':
      return EthereumImage;
    case 'eth':
      return EthereumImage;
    case 'ethereum':
    case 'goerli':
      return EthereumImage;
    case 'bitcoin':
      return BitcoinImage;
    case 'ethereum-token':
      return EthereumTokenImage;
    case 'cardano':
      return CardanoImage;
    case 'ada':
      return CardanoImage;
    case 'sol':
      return SolImage;
    case 'aave':
      return AaveImage;
    case 'qiavax':
      return AvaxImage;
    case 'tavax':
    case 'wavax':
    case 'avax':
    case 'tavaxm':
    case 'avaxm':
      return AvaxImage;
    case 'savax':
      return SavaxImage;
    case 'celo':
      return CeloImage;
    case 'compound':
      return CompoundImage;
    case 'comp':
      return CompoundImage;
    case 'qidai':
    case 'dai':
    case 'dai.e':
    case 'm.dai':
      return DaiImage;
    case 'sdai':
      return sDaiImage;
    case 'link':
    case 'link.e':
      return LinkImage;
    case 'luna':
      return LunaImage;
    case 'lunc':
      return LunaImage;
    case 'tmatic':
    case 'tmaticm':
    case 'maticm':
    case 'imatic':
    case 'wmatic':
    case 'matic':
      return MaticImage;
    case 'maticx':
      return MaticXImage;
    case 'stmatic':
      return StMaticImage;
    case 'mkr':
    case 'maker':
      return MakerImage;
    case 'qnt':
      return QntImage;
    case 'bone':
      return BoneImage;
    case 'euroc':
      return EurocImage;
    case 'ldo':
      return LdoImage;
    case 'looks':
      return LooksImage;
    case 'rpl':
      return RplImage;
    case 'gho':
      return GhoImage;
    case 'grt':
      return TheGraphImage;
    case 'uniswap':
    case 'uniswapv3':
      return UniswapImage;
    case 'hathor':
      return HathorImage;
    case 'uni':
      return UniImage;
    case 'optimism':
    case 'op':
      return OptimismImage;
    case 'qiusdt':
    case 'fusdt':
    case 'usdt':
    case 'usdt.e':
    case 'm.usdt':
      return UsdtImage;
    case 'stusdt':
      return StUsdtImage;
    case 'qiusdc':
    case 'usd':
    case 'usdc':
    case 'usdc.e':
    case 'm.usdc':
      return UsdcImage;
    case 'susd':
      return SusdImage;
    case 'algorand':
      return AlgorandImage;
    case 'algo':
      return AlgorandImage;
    case 'zrx':
      return ZrxImage;
    case 'bat':
      return BatImage;
    case 'dot':
      return DotImage;
    case 'crv':
    case 'curve':
      return CrvImage;
    case 'crvusd':
      return CrvUSDImage;
    case 'ust':
      return TerraImage;
    case 'jewel':
      return DefiKingdomsImage;
    case 'doge':
      return DogeImage;
    case 'dogecoin':
      return DogeImage;
    case 'ltc':
      return LtcImage;
    case 'litecoin':
      return LtcImage;
    case 'bch':
      return BchImage;
    case 'bitcoin-cash':
      return BchImage;
    case 'atom':
      return AtomImage;
    case 'cosmos':
      return AtomImage;
    case 'stellar':
      return XlmImage;
    case 'xlm':
      return XlmImage;
    case 'xmr':
      return XmrImage;
    case 'monero':
      return XmrImage;
    case 'etc':
      return EtcImage;
    case 'ethereum-classic':
      return EtcImage;
    case 'icp':
      return IcpImage;
    case 'internet-computer':
      return IcpImage;
    case 'fil':
      return FilImage;
    case 'filecoin':
      return FilImage;
    case 'eos':
      return EosImage;
    case 'rune':
      return RuneImage;
    case 'thorchain':
      return RuneImage;
    case 'zec':
      return ZecImage;
    case 'zcash':
      return ZecImage;
    case 'snx':
      return SnxImage;
    case 'synthetix':
    case 'synthetix network':
      return SnxImage;
    case 'enj':
      return EnjImage;
    case 'enjin':
      return EnjImage;
    case 'yfi':
      return YfiImage;
    case 'yearn':
      return YfiImage;
    case 'inch':
    case '1inch':
      return OneInchImage;
    case 'uma':
      return UmaImage;
    case 'shib':
      return ShibImage;
    case 'axs':
      return AxsImage;
    case 'ape':
      return ApeImage;
    case 'gmt':
      return GmtImage;
    case 'waves':
      return WavesImage;
    case 'gusd':
      return GusdImage;
    case 'steth':
      return StethImage;
    case 'sand':
      return SandboxImage;
    case 'mana':
      return ManaImage;
    case 'gala':
      return GalaImage;
    case 'zil':
      return ZilImage;
    case 'rsr':
      return RsrImage;
    case 'lrc':
      return LrcImage;
    case 'kava':
      return KavaImage;
    case 'ens':
      return EnsImage;
    case 'theta':
      return ThetaImage;
    case 'chz':
      return ChzImage;
    case 'cro':
      return CroImage;
    case 'vet':
      return VetImage;
    case 'chr':
      return ChrImage;
    case 'ghst':
      return GhstImage;
    case 'jeur':
      return JeurImage;
    case 'dpi':
      return DpiImage;
    case 'bal':
    case 'balancer':
      return BalImage;
    case 'ageur':
      return AgeurImage;
    case 'eurs':
      return EursImage;
    case 'wone':
    case 'one':
      return WoneImage;
    case 'mimatic':
    case 'mai':
      return MaiImage;
    case 'makerdao':
      return MakerDaoImage;
    case 'osmo':
      return OsmoImage;
    case 'gmx':
      return GmxImage;
    case 'cvx':
      return CvxImage;
    case 'fei':
      return FeiImage;
    case 'knc':
      return KncImage;
    case 'lusd':
      return LusdImage;
    case 'pax':
      return PaxImage;
    case 'rai':
      return RaiImage;
    case 'ren':
      return RenImage;
    case 'renfil':
      return RenfilImage;
    case 'tusd':
    case 'tusdold':
      return TusdImage;
    case 'usdp':
      return UsdpImage;
    case 'xsushi':
      return XsushiImage;
    case 'ampl':
      return AmplImage;
    case 'reth':
      return RethImage;
    case 'bob':
      return BobImage;
    case 'usdo':
      return UsdoImage;
    case 'considered-finance':
      return ConsideredFinanceImage;
    case 'rdnt':
      return RdntImage;
    case 'radiant':
      return RadiantImage;
    case 'tftmlpm':
    case 'ftmlpm':
      return FtmLPMImage;
    case 'tmaticlpm':
    case 'maticlpm':
      return MaticLPMImage;
    case 'tglpm':
    case 'glpm':
      return GLPMImage;
    case 'tavaxlpm':
    case 'avaxlpm':
      return AvaxLPMImage;
    case 'venus':
      return VenusImage;
    case 'tapioca':
      return TapiocaImage;
    case 'btt':
      return BttImage;
    case 'usdd':
      return UsddImage;
    case 'win':
      return WinImage;
    case 'ankrbnb':
      return AnkrbnbImage;
    case 'stkbnb':
      return StkbnbImage;
    case 'floki':
      return FlokiImage;
    case 'ankr':
      return AnkrImage;
    case 'bsw':
      return BswImage;
    case 'alpaca':
      return AlpacaImage;
    case 'hay':
      return HayImage;
    case 'raca':
      return RacaImage;
    case 'bnbx':
      return BnbxImage;
    case 'xai':
      return XaiImage;
    case 'uzd':
      return UzdImage;
    case 'dola':
      return DolaImage;
    case 'quinta':
      return QuintaImage;
    case '3crv':
      return ThreeCrvImage;
    case 'kvt':
      return KvtImage;
    case 'tbtc':
      return TBtcImage;
    case 'usdbc':
      return UsdbcImage;
    case 'base':
      return BaseImage;
    case 'stg':
      return StgImage;
    case 'fxs':
      return FxsImage;
    case 'snbnb':
      return SnbnbImage;
    case 'twt':
      return TwtImage;
    case 'tashi':
      return TashiImage;
    case 'seamless':
      return SeamlessImage;
    case 'gnosis':
      return GnosisImage;
    case 'gno':
      return GnoImage;
    case 'xdai':
    case 'wxdai':
      return XdaiImage;
    case 'eure':
      return EureImage;
    case 'planet':
      return PlanetImage;
    default:
      return null;
  }
};
