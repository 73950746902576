import { useBlockchainSimulationResult } from 'src/hooks';
import {
  BlockchainSimulation, MetricStatistics, PermutationInfo,
} from '@chaos/types';
import { useMemo } from 'react';

type ReturnType = {
  isLoading: boolean;
  simulation?: BlockchainSimulation;
  metricStatistics?: MetricStatistics[];
  permutationInfo?: PermutationInfo
};

export const useExperimentResult = (
  simulationResultId: string,
  permutationId?: string,
): ReturnType => {
  const {
    isLoading, simulationStatistics, simulation,
  } = useBlockchainSimulationResult(
    simulationResultId,
    true,
  );

  const { metricStatistics, permutationInfo } = simulationStatistics
    ?.data?.find((datum) => datum.permutationInfo?.id === permutationId) || {};

  const result = useMemo(() => ({
    isLoading,
    simulation,
    metricStatistics,
    permutationInfo,
  }), [isLoading, metricStatistics, permutationInfo, simulation]);
  return result;
};
