export const dataQueryURL = () => {
  const hostName = window.location.hostname;
  if (hostName.includes('staging') || ['localhost', '127.0.0.1'].includes(hostName)) {
    return 'https://staging.chaoslabs.co/';
  }
  if (hostName.startsWith('dogfood')) {
    return 'https://dogfood.chaoslabs.co/';
  }
  return 'https://cloud.chaoslabs.co/';
};
