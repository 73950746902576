import styled from '@mui/material/styles/styled';
import { Tabs, Tab } from '@mui/material';
import { TabsProps } from './tabs';
import { Chip } from '../chip';

const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiButtonBase-root': {
    flexDirection: 'row',
  },
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    width: '100%',
    height: 2,
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 86,
    width: '100%',
    height: 2,
    borderRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
}));

const StyledTab = styled(Tab)({
  fontWeight: 500,
  fontSize: 16,
  textTransform: 'none',
  color: 'rgba(255, 255, 255, 0.5)',
  '&.Mui-selected': {
    color: 'rgba(255, 255, 255, 1)',
  },
});

export const TitleTabs = ({ tabs, value, onChange }: TabsProps) => (
  <StyledTabs
    value={value}
    onChange={onChange}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    variant="scrollable"
    scrollButtons="auto"
  >
    {tabs.map((tab) => (
      <StyledTab
        key={tab.label?.toString()}
        data-testid={tab.testId}
        label={(
          <>
            {tab.label}
            {tab.badge && (
              <Chip
                label={tab.badge}
                color="primary"
                size="small"
                sx={{ marginLeft: 1.5 }}
              />
            )}
          </>
        )}
      />
    ))}
  </StyledTabs>
);
