import { FC, useEffect, useState } from 'react';
import type { ContractInterface } from 'ethers';
import * as ethers from 'ethers';
import { SimulationContract } from '@chaos/types';
import {
  Box, Button, InputLabel, TextField, Typography,
} from '@chaos/ui';
import { CircularProgress } from '@chaos/ui/circular-progress';
import { CustomIcon } from 'src/components/custom-icon';
import { Accordion } from 'src/components/accordion';
import { Loader } from 'src/components/loader';

type RpcResult = { message: string, isError?: boolean };
type AbiValues = Record<string, {
  values: string[],
  returnType?: string,
  result?: RpcResult,
  isLoading?: boolean
}>;

const callRpc = async (
  rpcUrl: string,
  address: string,
  abi: ContractInterface,
  endpoint: string,
  args: string[],
): Promise<RpcResult> => {
  try {
    const provider = new ethers.providers.JsonRpcBatchProvider(rpcUrl);
    const contract = new ethers.Contract(address, abi, provider);

    // eslint-disable-next-line
    const result = await contract[endpoint](...args.map(s => s.trim()));
    // eslint-disable-next-line
    return { message: result.toString() };
  } catch (e) {
    return { message: (e as { message: string }).message, isError: true };
  }
};

const RpcInput: FC<{ name: string, value: string, onChange: (val: string) => void }> = ({
  name, value, onChange,
}) => (
  <Box>
    <InputLabel>{name}</InputLabel>
    <TextField
      fullWidth
      placeholder={name}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  </Box>
);

type BlockExplorerAddressContractReadProps = {
  contract: SimulationContract,
  externalRemotePoolURL?: string,
};

export const BlockExplorerAddressContractRead: FC<BlockExplorerAddressContractReadProps> = ({
  contract, externalRemotePoolURL,
}) => {
  const [abiValues, setAbiValues] = useState<AbiValues>();

  useEffect(() => {
    if (contract) {
      setAbiValues(contract.abi.reduce<AbiValues>((acc, func) => {
        if (func.stateMutability === 'view' || func.stateMutability === 'pure') {
          acc[func.name] = { values: func.inputs.map(() => ''), returnType: func.outputs[0]?.type };
        }

        return acc;
      }, {}));
    }
  }, [contract]);

  return abiValues ? (
    <Box>
      <Box mb={5} display="flex" alignItems="center" gap={1.5}>
        <CustomIcon icon="file-list" />
        <Typography variant="h2">Read Contract Information</Typography>
      </Box>
      {contract.abi
        .filter(({ stateMutability }) => stateMutability === 'view' || stateMutability === 'pure')
        .map((func) => {
          const values = abiValues[func.name];

          return (
            <Accordion
              key={func.name}
              header={func.name}
              content={(
                <Box display="flex" flexDirection="column" gap={2}>
                  {func.inputs.map((input, i) => (
                    <RpcInput
                      key={`${input.name}-${input.type}-${i}`}
                      name={`${input.name || '<input>'} (${input.type})`}
                      value={values.values[i]}
                      onChange={(val) => setAbiValues({
                        ...abiValues,
                        [func.name]: {
                          ...values,
                          values: values.values.map((_, j) => (j === i
                            ? val : values.values[j])),
                        },
                      })}
                    />
                  ))}
                  <Box display="flex" alignItems="center" gap={2}>
                    <Button
                      color="primary"
                      sx={{ maxWidth: 70 }}
                      onClick={() => {
                        if (externalRemotePoolURL) {
                          setAbiValues({
                            ...abiValues,
                            [func.name]: { ...values, isLoading: true },
                          });
                          void callRpc(
                            externalRemotePoolURL,
                            contract.address,
                            contract.abi,
                            func.name,
                            Object.values(values.values),
                          ).then((res) => setAbiValues({
                            ...abiValues,
                            [func.name]: { ...values, result: res, isLoading: false },
                          }));
                        }
                      }}
                      disabled={values.isLoading}
                    >
                      Query
                    </Button>
                    {values.isLoading && <CircularProgress />}
                  </Box>
                  {values.result && (
                    <Box>
                      <Typography
                        className={!values.result.isError ? 'gradient-link' : undefined}
                        color={values.result.isError ? 'error.main' : undefined}
                      >
                        {values.result.message}
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            />
          );
        })}
    </Box>
  ) : <Loader />;
};
