import { useParams } from 'react-router-dom';
import { MainLayout } from 'src/components/layouts';
import { PageTracker } from 'src/components/page-tracker';
import { useBlockchainSimulationResult } from 'src/hooks';
import { RouteParams, RoutePath } from 'src/config/routes';
import { BlockExplorerTransactions } from 'src/components/block-explorer-transactions';

export const BlockExplorerTransactionsPage = PageTracker(() => {
  const { simulationResultId } = useParams<{ simulationResultId: string }>();
  const { simulation, result } = useBlockchainSimulationResult(simulationResultId!, true);

  const breadcrumbsLinks = [{
    title: 'Simulation Results',
    href: RoutePath.Simulations.Results,
  }, {
    title: simulation?.name || '',
    href: RoutePath.Simulations.ResultDetails
      .replace(RouteParams.SimulationResultId, simulationResultId!),
  }, {
    title: 'Block Explorer',
    href: RoutePath.Simulations.BlockExplorer.Home
      .replace(RouteParams.SimulationResultId, simulationResultId!),
  }];
  return (
    <MainLayout height={0} headerProps={{ pageTitle: 'Latest Transactions', breadcrumbsLinks }}>
      <BlockExplorerTransactions
        simulationResultId={simulationResultId!}
        connectionKey={result?.meta?.connectionKey}
      />
    </MainLayout>
  );
});
