import React from 'react';
import { Typography } from '@chaos/ui/typography';
import { Box } from '@chaos/ui/box';
import { Button } from '@chaos/ui/button';
import { IconButton } from '@chaos/ui/icon-button';
import { InputAdornment } from '@chaos/ui/input-adornment';
import { TextField } from '@chaos/ui/text-field';
import { MainLayout } from 'src/components/layouts';
import { InputLabel } from '@chaos/ui/input-label';
import { CustomIcon } from 'src/components/custom-icon';
import { usePageTitle } from 'src/hooks';
import { PageTracker } from 'src/components/page-tracker';

export const AccountSettingsPage = PageTracker((): JSX.Element => {
  usePageTitle('Account Settings');
  const [email, setEmail] = React.useState('michael.mitc@example.com');
  const [currentPassword, setCurrentPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] = React.useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = React.useState(false);

  const toggleCurrentPasswordVisibility = () => setIsCurrentPasswordVisible(
    !isCurrentPasswordVisible,
  );
  const toggleNewPasswordVisibility = () => setIsNewPasswordVisible(!isNewPasswordVisible);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  };

  return (
    <MainLayout
      topbar={(
        <Typography variant="h1" component="h1" sx={{ p: '44px 0', flexGrow: 1 }}>
          Account Settings
        </Typography>
      )}
    >
      <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
        <Box
          sx={{
            display: 'flex',
            mb: 5,
          }}
        >
          <Box sx={{ flexGrow: 1, mr: 5 }}>
            <InputLabel>Email</InputLabel>
            <TextField
              type="email"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              placeholder="Enter your email"
              fullWidth
            />
          </Box>
          <Box sx={{ flexGrow: 1, mr: 5 }}>
            <InputLabel>Current Password</InputLabel>

            <TextField
              type={isCurrentPasswordVisible ? 'text' : 'password'}
              value={currentPassword}
              placeholder="Enter your current password"
              fullWidth
              onChange={(event) => {
                setCurrentPassword(event.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle current password visibility"
                      edge="end"
                      onClick={toggleCurrentPasswordVisibility}
                      size="large"
                    >
                      <CustomIcon icon={isCurrentPasswordVisible ? 'eye-off' : 'eye'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <InputLabel>New Password</InputLabel>
            <TextField
              type={isNewPasswordVisible ? 'text' : 'password'}
              value={newPassword}
              placeholder="Enter your new password"
              fullWidth
              onChange={(event) => {
                setNewPassword(event.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle new password visibility"
                      edge="end"
                      onClick={toggleNewPasswordVisibility}
                      size="large"
                    >
                      <CustomIcon icon={isNewPasswordVisible ? 'eye-off' : 'eye'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            type="button"
            sx={{
              mr: 5,
              px: 0,
              backgroundImage: 'linear-gradient(154.16deg, #31D8BF -3.9%, #0965F1 126.56%)',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            Reset Password
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </Box>
      </Box>
    </MainLayout>
  );
});
