import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/config/routes';
import { PageTracker } from 'src/components/page-tracker';
import { ScriptForm } from 'src/components/script-form';
import { EditPageLayout } from 'src/components/layouts';
import { createBlockchainScriptFn } from 'src/components/dashboard/network';
import { useCurrentTeam } from 'src/hooks';
import { useBlockchainScenarios } from 'src/hooks/useBlockchainScenarios';
import { ConfirmationLeavingDialog, getDisablePromptPushState } from '../../components/confirmation-leaving-dialog';

export const ScenarioCreatePage = PageTracker((): JSX.Element => {
  const team = useCurrentTeam();
  const navigate = useNavigate();
  const { blockchainScenarios } = useBlockchainScenarios();

  return (
    <EditPageLayout
      rootRoute={RoutePath.ScenarioCatalogue.Home}
      rootTitle="Scenarios"
      pageTitle="Create New Scenario"
      metaTitle="Create Scenario"
    >
      <ConfirmationLeavingDialog message="Changes you made may not be saved." />
      <ScriptForm
        type="Scenario"
        allEntities={blockchainScenarios}
        onSubmit={async (scenario) => {
          await createBlockchainScriptFn(scenario, 'Scenario', team?.authKey);
          navigate(RoutePath.ScenarioCatalogue.Home, { state: getDisablePromptPushState() });
        }}
      />
    </EditPageLayout>
  );
});
