import { useCurrentTeam } from 'src/hooks';
import { useEffect, useMemo, useState } from 'react';
import { ModelRun } from '../types';
import { getModelRunsPage } from '../requests';

type ReturnType = {
  isLoading: boolean
  modelRuns: ModelRun[]
};

export const useModelRuns = (lastId?: string, pageSize = 30): ReturnType => {
  const team = useCurrentTeam();
  const [modelRuns, setModelRuns] = useState<ModelRun[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [areAllLoaded, setAreAllLoaded] = useState(false);

  useEffect(() => {
    void (async () => {
      if (!areAllLoaded && team?.authKey) {
        setIsLoading(true);
        const modelRunsPage = await getModelRunsPage(team.authKey, pageSize, lastId);

        setAreAllLoaded(modelRunsPage.length !== pageSize);
        setModelRuns((prevRuns) => [...(prevRuns || []), ...modelRunsPage]);

        setIsLoading(false);
      }
    })();
  }, [team?.authKey, pageSize, lastId, areAllLoaded]);

  const result = useMemo(() => ({ isLoading, modelRuns }), [isLoading, modelRuns]);

  return result;
};
