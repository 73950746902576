import { FC } from 'react';
import { Modal } from '@chaos/ui/modal';
import { Paper } from '@chaos/ui/paper';
import { IconButton } from '@chaos/ui/icon-button';
import { CustomIcon } from 'src/components/custom-icon';

interface ModalWrapperProps {
  open: boolean
  onClose?: () => void
  maxWidth?: number
}

export const ModalWrapper: FC<ModalWrapperProps> = (props) => {
  const {
    children, open, onClose, maxWidth,
  } = props;
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        p: 5,
        display: 'flex',
        '& > .MuiBox-root': {
          maxWidth,
        },
        '.modal': {
          display: 'flex',
          alignItems: 'center',
          width: 'auto',
          margin: 'auto',
        },
        '.modal-header, .modal-footer': {
          display: 'flex',
          alignItems: 'center',
          px: { xs: 2, md: 5 },
        },
        '.modal-body': {
          display: 'flex',
          flexDirection: 'column',
          rowGap: '40px',
          overflow: 'auto',
          px: { xs: 2, md: 5 },
        },
        '.modal-footer': {
          justifyContent: 'flex-end',
        },
      }}
    >
      <Paper
        variant="card"
        sx={{
          margin: 'auto',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '100%',
          flex: 1,
          maxWidth,
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute', right: { xs: '12px', md: '28px' }, top: { xs: '28px' },
          }}
          size="large"
        >
          <CustomIcon icon="close" />
        </IconButton>
        {children}
      </Paper>
    </Modal>
  );
};
