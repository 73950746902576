import {
  Box, Link, Paper, Typography,
} from '@chaos/ui';
import { publicDashboards } from '@chaos/ui/utils/dashboards';
import { PageTracker } from 'src/components/page-tracker';
import { useCurrentTeam, useDashboards } from 'src/hooks';
import { DashboardItem } from './dashboard-item';

export const DashboardsPage = PageTracker((): JSX.Element => {
  const team = useCurrentTeam();
  const { dashboards, privateDashboards } = useDashboards();
  const teamKey = team?.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() || '';
  const teamPublicDashboards = publicDashboards[teamKey];

  return (
    <Box mt={5.5}>
      <Typography variant="h1">Risk Tools</Typography>
      {!!teamPublicDashboards?.length && (
        <Box mt={10}>
          <Typography variant="h2">Community Analytics</Typography>
          <Typography>Community Analytics dashboards and tools are publicly accessible</Typography>
          <Box mt={3}>
            {teamPublicDashboards.map((d) => (
              <Link
                key={d.id}
                href={d.externalLink ? d.link : `https://community.chaoslabs.xyz${d.link}`}
                target="_blank"
                sx={{
                  color: 'inherit',
                  textDecoration: 'inherit',
                  width: 592,
                  maxWidth: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  overflow: 'hidden',
                  mb: 3,
                }}
              >
                <Paper
                  variant="card"
                  sx={{
                    display: 'flex', width: '100%', overflow: 'hidden', p: 0,
                  }}
                >
                  <Box display="flex" borderRight="1px solid #22252C">
                    <img
                      width={120}
                      height={120}
                      src={d.icon}
                      alt="tool-icon"
                    />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    flex="1"
                    padding={2}
                  >
                    <Typography variant="h2">{d.title}</Typography>
                    <Typography fontWeight={400}>{d.subtitle}</Typography>
                  </Box>
                </Paper>
              </Link>
            ))}
          </Box>
        </Box>
      )}
      {(!!dashboards?.length || !!privateDashboards?.length) && (
        <Box mt={10}>
          <Typography variant="h2">Private Dashboards</Typography>
          <Typography>
            Private dashboards and tools are only accessible to logged-in users
          </Typography>
          <Box mt={3}>
            {privateDashboards?.map(({
              id, title, subtitle,
            }) => (
              <DashboardItem key={id} id={id} name={title} description={subtitle} />
            ))}
            {dashboards?.map(({
              id, name, description, image,
            }) => (
              <DashboardItem key={id} id={id} name={name} description={description} image={image} />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
});
