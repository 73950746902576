import { useEffect, useMemo, useState } from 'react';
import { Subscription } from 'rxjs';
import { collection, doc } from 'firebase/firestore';
import { docData } from 'rxfire/firestore';
import { COLLECTIONS } from '@chaos/utils';
import { PythonSimulationResult } from '@chaos/types';
import { firestore } from 'src/services/firebase';

export const usePythonSimulationResult = (id?: string): {
  isLoading: boolean, result?: PythonSimulationResult,
} => {
  const [simResult, setSimResult] = useState<PythonSimulationResult>();

  useEffect(() => {
    const subs: Subscription[] = [];
    const simResultsCol = collection(firestore, COLLECTIONS.PYTHON_SIMULATION_RESULTS);

    if (id) {
      const resultObs = docData(doc(simResultsCol, id), { idField: 'id' });
      subs.push(resultObs.subscribe((r) => setSimResult(r as PythonSimulationResult)));
    }

    return () => subs.forEach((sub) => sub.unsubscribe());
  }, [id]);

  const result = useMemo(
    () => ({ isLoading: !!id && !simResult, result: simResult }),
    [simResult, id],
  );

  return result;
};
