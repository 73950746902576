import {
  FC, useEffect, useMemo, useState,
} from 'react';
import { mergeMap, Subscription, zip } from 'rxjs';
import { collectionData, docData } from 'rxfire/firestore';
import { Team, TeamMembership } from '@chaos/types';
import { COLLECTIONS } from '@chaos/utils';
import {
  collection, doc, query, where,
} from 'firebase/firestore';
import { useAppSelector } from 'src/store';
import { firestore } from 'src/services/firebase';
import { LoadingOverlay } from 'src/components/loading-overlay';
import { TeamContext } from './team-context';

export const TeamProvider: FC = ({ children }) => {
  const { teamId } = useAppSelector((state) => state.team);
  const [team, setTeam] = useState<string | undefined>();
  const [teams, setTeams] = useState<Team[]>();
  const [isLoading, setIsLoading] = useState(true);
  const { uid } = useAppSelector((state) => state.firebase.auth);

  useEffect(() => {
    setIsLoading(true);
    const subs: Subscription[] = [];

    if (uid) {
      const teamMembershipCol = collection(firestore, COLLECTIONS.TEAM_MEMBERSHIP);
      const teamsCol = collection(firestore, COLLECTIONS.TEAMS);

      const teamMembershipObs = collectionData(query(
        teamMembershipCol,
        where('member', '==', doc(collection(firestore, COLLECTIONS.USERS), uid)),
      ), { idField: 'id' }).pipe(mergeMap((data) => (data.length > 0 ? zip(Array.from(
        new Set((data as TeamMembership[]).map((m) => m.team.id)),
      ).map((s) => docData(doc(teamsCol, s), { idField: 'id' }))) : Promise.resolve(data))));
      subs.push(teamMembershipObs.subscribe((data) => {
        setIsLoading(false);
        setTeams((data as Team[]).filter((t) => !!t));
      }));
    } else {
      setIsLoading(false);
    }

    return () => subs.forEach((s) => s.unsubscribe());
  }, [uid]);

  useEffect(() => {
    const currTeam = teams && (teams.find(({ id }) => id === teamId) || teams[0]);
    setTeam(currTeam?.id);
  }, [teamId, teams]);

  const value = useMemo(() => ({
    teams: teams || [],
    loading: isLoading,
    team: teams?.find(({ id }) => team === id),
    setTeam,
  }), [teams, team, isLoading]);

  return (
    <TeamContext.Provider value={value}>
      {value.loading ? <LoadingOverlay hideNav /> : children}
    </TeamContext.Provider>
  );
};
