import {
  FC, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { IconButton, CustomIcon } from '@chaos/ui';
import { Box } from '@chaos/ui/box';
import type { Header, RowData } from '@chaos/types';
import { wrappedSymbolToIconSymbol } from 'src/utils/icon-helper';
import {
  TableFilter, Filter, FilterPicker, FilterRange, FilterText,
} from './table-filter';
import { TableAppliedFilters } from './table-applied-filters';

const getFilters = (
  data: RowData[],
  headers: Header[],
  currFilters: Record<number, Filter>,
) => {
  const filters: Filter[] = headers
    .filter((header, i) => header.renderType !== 'EMPTY' && data.find(({ renderData: row }) => row[i].text && row[i].text.length > 0))
    .map((header, i) => ({
      fieldName: header.text.toString(),
      fieldIndex: i,
      type: 'text',
      isApplied: currFilters[i]?.isApplied || false,
      suffix: header.suffix,
      prefix: header.prefix,
      isAutocomplete: header.isAutocomplete,
    }));

  data.forEach(({ renderData: row }) => {
    row.forEach(({
      renderType, value, extraData, text, token1,
    }, i) => {
      if (i >= filters.length) {
        return;
      }

      if (extraData?.symbol) {
        const { symbol } = extraData;
        const options = (filters[i] as FilterPicker).options || [];
        const isOptionExist = options.find(({ name }) => name === symbol);
        const newOption = {
          name: symbol,
          icon: wrappedSymbolToIconSymbol(symbol),
          isHidden: true,
        };
        const newOptions = isOptionExist ? options : [...options, newOption];

        filters[i] = {
          ...filters[i],
          type: 'options',
          options: newOptions,
        };
      } else if (renderType === 'TEXT' && value) {
        const range = (filters[i] as FilterRange).range || [Infinity, 0];
        filters[i] = {
          ...filters[i],
          type: 'range',
          range: [
            value < range[0] ? Math.floor(value) : range[0],
            value > range[1] ? Math.ceil(value) : range[1],
          ],
        };
      } else if (renderType === 'CHIP' || renderType === 'LABELS' || (filters[i] as FilterPicker).isAutocomplete) {
        const options = (filters[i] as FilterPicker).options || [];
        filters[i] = {
          ...filters[i],
          type: 'options',
          options: text && !options.find(({ name }) => name === text) ? [
            ...options,
            {
              name: text,
              icon: token1 && wrappedSymbolToIconSymbol(token1),
              isHidden: (filters[i] as FilterPicker).isAutocomplete || false,
            },
          ] : options,
        };
      }
    });
  });

  return filters.filter(
    (filter, i) => filter.type !== 'range' || filter.range[0] !== filter.range[1]
      || !data.find(({ renderData: row }) => row[i].text),
  );
};

const filterData = (data: RowData[], filterValues: Record<number, Filter>) => data.filter(
  ({ renderData: row }) => row.every(({ text, value, extraData }, i) => {
    if (!filterValues[i] || !filterValues[i].isApplied) {
      return true;
    }

    if (filterValues[i].type === 'text') {
      return text.toString().toLowerCase().includes(
        (filterValues[i] as FilterText).value?.toLowerCase() || '',
      );
    }

    if (filterValues[i].type === 'range' && value) {
      const { range } = filterValues[i] as FilterRange;
      return value >= range[0] && value <= range[1];
    }

    if (filterValues[i].type === 'options') {
      const symbols = Array.from(
        new Set((extraData?.data as { symbol: string }[])?.map(({ symbol }) => symbol)),
      );
      return symbols?.length
        ? (filterValues[i] as FilterPicker).options
          .filter(({ isHidden }) => !isHidden)
          .every(({ name }) => symbols.includes(name))
        : (filterValues[i] as FilterPicker).options.find(
          ({ isHidden, name }) => !isHidden && name === text,
        );
    }

    return false;
  }),
);

interface TableFilterButtonInterface {
  headers: Header[],
  data: RowData[],
  onChange: (rows: RowData[]) => void
  setTablePage: (page: number) => void
}

export const TableFilterButton: FC<TableFilterButtonInterface> = ({
  headers, data, onChange, setTablePage,
}) => {
  const filterButtonRef = useRef<null | HTMLButtonElement>(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filters, setFilters] = useState<Record<number, Filter>>({});
  const onFilter = useCallback((filterValues: Record<number, Filter>) => {
    setFilters(filterValues);
    onChange(filterData(data, filterValues));
  }, [data, onChange]);

  useEffect(() => {
    setFilters({});
  }, [data]);

  useEffect(() => {
    onChange(filterData(data, filters));
  }, [data, onChange, filters]);

  const tableFilters = useMemo(
    () => getFilters(data, headers, filters),
    // TODO: https://chaoslabs.atlassian.net/browse/DEV-189
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters],
  );

  return (
    <Box display="flex" alignItems="center">
      <IconButton
        color="secondary"
        ref={filterButtonRef}
        onClick={(e) => {
          if (e.detail === 0) {
            return;
          }
          setIsFilterOpen(!isFilterOpen);
        }}
        aria-label="Add Filter"
        data-testid="toggle-fitler"
      >
        <CustomIcon icon="filter" />
      </IconButton>
      <TableAppliedFilters
        filters={filters}
        headers={headers}
        removeFilter={(index) => {
          setTablePage(1);
          onFilter({
            ...filters,
            [index]: {
              ...filters[index],
              isApplied: false,
            },
          });
        }}
      />
      <TableFilter
        isOpen={isFilterOpen}
        anchorEl={filterButtonRef.current}
        filters={tableFilters}
        onChange={(filterValues) => {
          setTablePage(1);
          onFilter(filterValues);
        }}
        close={() => setIsFilterOpen(false)}
      />
    </Box>
  );
};
