import { FC, useMemo } from 'react';
import {
  useFirestoreConnect, useFirestore, ReduxFirestoreQuerySetting, isLoaded,
} from 'react-redux-firebase';
import { COLLECTIONS } from '@chaos/utils';
import { useAppSelector } from 'src/store';
import { useCurrentTeam } from 'src/hooks';
import { AssetsContext } from './assets-context';

export const AssetsProvider: FC = ({ children }) => {
  const team = useCurrentTeam();
  const teamName = window.location.host.split('.')[0].replace('-staging', '');
  const firestore = useFirestore();
  const query = useMemo<ReduxFirestoreQuerySetting | undefined>(() => ((team || teamName) ? {
    collection: COLLECTIONS.ASSETS,
    where: [
      team ? ['team', '==', firestore.collection(COLLECTIONS.TEAMS).doc(team.id)]
        : ['teamName', '==', teamName],
    ],
  } : undefined), [team, teamName, firestore]);
  useFirestoreConnect(query);
  const assets = useAppSelector((state) => state.firestore.data.assets);
  const contextValue = useMemo(() => ({
    assets: assets ? Object.values(assets) : isLoaded(assets) ? [] : undefined,
  }), [assets]);

  return (
    <AssetsContext.Provider value={contextValue}>
      {children}
    </AssetsContext.Provider>
  );
};
