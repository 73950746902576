import { PropsWithChildren } from 'react';
import {
  ArgType,
  CodeEntity,
  ObserverChartType,
  ObserverDataType,
  ObserverScript,
  ObserverValueType,
  ScriptArgs,
  ScriptResource,
} from '@chaos/types';
import { Box } from '@chaos/ui/box';
import { Paper } from '@chaos/ui/paper';
import { Typography } from '@chaos/ui/typography';
import { TextField } from '@chaos/ui/text-field';
import { CustomReactSelect } from '@chaos/ui/custom-select';
import { useRandomAvatar } from 'src/hooks';
import { useScriptCode } from 'src/hooks/useScriptCode';
import { CodeEditor } from '../code-editor';
import { Loader } from '../loader';

interface ScriptSummaryProps {
  type: CodeEntity,
  script: Partial<ScriptResource>,
  onChangeObserver? :(observerValues: Partial<ObserverScript>) => void
  onChange?: (args: ScriptArgs) => void,
}

type InputContainerProps = { title: string };
const InputContainer = ({ title, children }: PropsWithChildren<InputContainerProps>) => (
  <Box display="flex" flexDirection="column" flex={1} minWidth={250} maxWidth={250}>
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography variant="body1">{title}</Typography>
      {children}
    </Box>
  </Box>
);

const observerDataTypeOptions = [
  {
    label: 'Per Block',
    value: 'event',
  },
  {
    label: 'Aggregated',
    value: 'aggregated',
  },
];

const observerChartTypeOptions = [
  {
    label: 'Area',
    value: 'AREA',
  },
  {
    label: 'Line',
    value: 'LINE',
  },
  {
    label: 'Histogram',
    value: 'HISTOGRAM',
  },
];

const observerValueTypeOptions = [
  {
    label: 'Number',
    value: 'number',
  },
  {
    label: 'Price',
    value: 'price',
  },
  {
    label: 'Token',
    value: 'token',
  },
];

export const ScriptSummary = ({
  type,
  script,
  onChangeObserver,
  onChange,
}: ScriptSummaryProps): JSX.Element => {
  const { randomAvatar } = useRandomAvatar(script.id);
  const code = useScriptCode(script.fullPath);
  const args = Object.entries(script.args || {}).map(([key, value]) => ({
    name: key,
    type: value.type,
    description: value.description,
  })).filter(({ name }) => name !== 'chainUrl');
  const isObserver = type === 'Observer';

  const inputOptions: { label: ArgType, value: ArgType }[] = [{
    label: 'number',
    value: 'number',
  }, {
    label: 'string',
    value: 'string',
  }, {
    label: 'number[]',
    value: 'number[]',
  }, {
    label: 'string[]',
    value: 'string[]',
  }];

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Paper variant="card">
        <Box display="flex">
          <Box
            component="img"
            src={script.thumbnail?.url || randomAvatar}
            alt={script.name}
            height={48}
            width={48}
            borderRadius={48}
          />
          <Box ml={2}>
            <Typography variant="caption">Name</Typography>
            <Typography>{script.name}</Typography>
          </Box>
        </Box>
        <Box mt={3}>
          <Typography variant="caption">Description</Typography>
          <Typography textOverflow="ellipsis" overflow="hidden" sx={{ hyphens: 'auto' }}>{script.description || '-'}</Typography>
        </Box>
      </Paper>

      {isObserver && onChangeObserver && (
        <Paper
          variant="card"
          sx={{
            display: 'flex', flexWrap: 'wrap', gap: 3,
          }}
        >
          <InputContainer title="Data Type">
            <CustomReactSelect
              placeholder="Select Type"
              value={observerDataTypeOptions.find(
                (option) => (script as ObserverScript).dataType === option.value,
              )}
              options={observerDataTypeOptions}
              onChange={(selected) => {
                onChangeObserver({ dataType: selected?.value as ObserverDataType });
              }}
              isSearchable
            />
          </InputContainer>
          <InputContainer title="Chart Type">
            <CustomReactSelect
              placeholder="Select Type"
              value={observerChartTypeOptions.find(
                (option) => (script as ObserverScript).chartType === option.value,
              )}
              options={observerChartTypeOptions}
              onChange={(selected) => {
                onChangeObserver({ chartType: selected?.value as ObserverChartType });
              }}
              isSearchable
            />
          </InputContainer>
          <InputContainer title="Value Type">
            <CustomReactSelect
              placeholder="Select Type"
              value={observerValueTypeOptions.find(
                (option) => (script as ObserverScript).valueType === option.value,
              )}
              options={observerValueTypeOptions}
              onChange={(selected) => {
                onChangeObserver({ valueType: selected?.value as ObserverValueType });
              }}
              isSearchable
            />
          </InputContainer>
          <InputContainer title="Value Label">
            <TextField
              placeholder="Label"
              value={(script as ObserverScript).valueLabel}
              onChange={(e) => onChangeObserver({ valueLabel: e.target.value })}
            />
          </InputContainer>
        </Paper>
      )}

      {!!args.length && (
        <Paper
          variant="card"
          sx={{
            display: 'flex', flexWrap: 'wrap', columnGap: '110px', rowGap: '32px',
          }}
        >
          {args.map((arg) => (
            <Box display="flex" flexDirection="column" width={onChange ? '100%' : '250px'}>
              <Box display="flex" flexDirection="column" gap={onChange && 1}>
                <Typography variant="body1">{arg.name}</Typography>
                {onChange ? (
                  <Box display="flex">
                    <CustomReactSelect
                      placeholder="Select Configuration"
                      options={inputOptions}
                      value={inputOptions.find(({ value }) => value === arg.type)}
                      onChange={(selected) => {
                        if (selected) {
                          onChange(args.reduce<ScriptArgs>((res, curr) => ({
                            ...res,
                            [curr.name]: curr.name === arg.name ? {
                              ...arg,
                              type: selected.value as ArgType,
                            } : curr,
                          }), {}));
                        }
                      }}
                      isSearchable
                    />
                    <TextField
                      sx={{ ml: '1px' }}
                      placeholder="Description"
                      fullWidth
                      InputProps={{ style: { borderRadius: '0 16px 16px 0' } }}
                      onChange={(e) => {
                        onChange(args.reduce<ScriptArgs>((res, curr) => ({
                          ...res,
                          [curr.name]: curr.name === arg.name ? {
                            ...arg,
                            description: e.target.value,
                          } : curr,
                        }), {}));
                      }}
                    />
                  </Box>
                ) : (
                  <>
                    <Typography variant="caption">{arg.type}</Typography>
                    <Typography variant="caption">{arg.description}</Typography>
                  </>
                )}
              </Box>
            </Box>
          ))}
        </Paper>
      )}
      {!onChange && (
        !code ? (
          <Paper variant="card">
            <Loader />
          </Paper>
        ) : <CodeEditor entity={type} initialCode={code} />
      )}
    </Box>
  );
};
