import { BlockchainSimulationResult, SimulationType } from '@chaos/types';
import { ProgressStatus } from 'src/store/progress-bar/reducer';

export function isSimulationStateOnScriptExecutionStatus(state: string): boolean {
  return state === ProgressStatus.runSetupScripts
    || state === ProgressStatus.runRuntimeScripts
    || state === ProgressStatus.runConfigSimulations;
}

export function isSimulationOnFinishedStatus(state: string): boolean {
  return state === ProgressStatus.simulationError || state === ProgressStatus.simulationFinished
    || state === ProgressStatus.simulationCancelled;
}

export function isParamConfigHistoryResult(
  result?: BlockchainSimulationResult,
  simulationType?: SimulationType,
): boolean {
  return simulationType === SimulationType.ParameterConfiguration
    && result?.parentResultId === null;
}
