import { useNavigate } from 'react-router-dom';
import { EditPageLayout } from 'src/components/layouts';
import { RoutePath } from 'src/config/routes';
import { useCurrentTeam } from 'src/hooks';
import { PageTracker } from 'src/components/page-tracker';
import { ScriptForm } from 'src/components/script-form';
import { createBlockchainScriptFn } from 'src/components/dashboard/network';
import { useBlockchainAgents } from 'src/hooks/useBlockchainAgents';
import { ConfirmationLeavingDialog, getDisablePromptPushState } from '../../components/confirmation-leaving-dialog';

export const AgentCreatePage = PageTracker((): JSX.Element => {
  const navigate = useNavigate();
  const team = useCurrentTeam();
  const { blockchainAgents } = useBlockchainAgents();

  return (
    <EditPageLayout
      rootRoute={RoutePath.AgentPlayground.Home}
      rootTitle="Agents"
      pageTitle="Create New Agent"
      metaTitle="Create Agent"
    >
      <ConfirmationLeavingDialog message="Changes you made may not be saved." />
      <ScriptForm
        type="Agent"
        allEntities={blockchainAgents}
        onSubmit={async (agent) => {
          await createBlockchainScriptFn(agent, 'Agent', team?.authKey);
          navigate(RoutePath.AgentPlayground.Home, { state: getDisablePromptPushState() });
        }}
      />
    </EditPageLayout>
  );
});
