import { FC, useRef } from 'react';
import { VictoryPie, VictoryChart, VictoryAxis } from 'victory';
import { Box } from '@chaos/ui/box';
import { Typography } from '@chaos/ui/typography';
import { useElementSize } from '@chaos/ui/hooks/use-element-size';
import { colorScaleCold, colorScaleWarm } from './utils';
import { chartTooltip } from './chart-tooltip';
import type { DataItem } from '.';

export interface PieChartProps {
  data?: DataItem[]
  isWarm?: boolean
  labelPrefix?: string
  labelSuffix?: string
  hideChartLegends?: boolean
}

const formatAmount = (amount: number) => new Intl.NumberFormat('en-US').format(amount);

function dataToLabels(data: DataItem[], prefix?: string, suffix?: string) {
  return data.map((item) => `${item?.x || ''} - ${
    prefix || ''}${item.y ? formatAmount(Number(item.y)) : ''}${suffix || ''
  }`);
}

export const PieChart: FC<PieChartProps> = ({
  data, isWarm, labelPrefix, labelSuffix, hideChartLegends,
}) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const size = useElementSize(chartRef);
  const colorScale = isWarm ? colorScaleWarm : colorScaleCold;

  return (
    <Box
      ref={chartRef}
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        flex: 1,
      }}
    >
      <VictoryChart {...size} height={size?.height ? size.height - 48 : 0}>
        <VictoryAxis
          style={{
            axis: { stroke: 'transparent' },
            ticks: { stroke: 'transparent' },
            tickLabels: { fill: 'transparent' },
          }}
        />
        <VictoryPie
          data={data}
          width={size?.width || 272}
          height={size?.height || 272}
          innerRadius={size?.height ? size.height / 6 : 80}
          radius={size?.height ? size.height / 3 : 136}
          labels={dataToLabels(data || [], labelPrefix, labelSuffix)}
          colorScale={colorScale}
          labelComponent={chartTooltip}
        />
      </VictoryChart>

      {(data && !hideChartLegends) && (
        <Box
          sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}
        >
          {data.map((item, i) => (
            <Box
              key={i}
              sx={{ padding: '0 12px', display: 'flex', alignItems: 'center' }}
            >
              <Box
                sx={{
                  width: 8,
                  height: 8,
                  borderRadius: '50%',
                  marginRight: 1,
                  backgroundColor: colorScale[i % colorScale.length],
                }}
              />
              <Typography
                variant="body1"
                sx={{ color: '#9B9DA1', fontSize: { xs: 12, sm: 16 } }}
              >
                {item.x}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
