import { useMemo } from 'react';
import { Box } from '@chaos/ui';

export type AvatarAbrType = 'short' | 'long';

export interface CircleAvatarProps {
  name: string;
  colorFromName?: boolean;
  type?: AvatarAbrType;
}

const colors = ['#5E3D4F', '#5E4C37', '#2F514A', '#2F5260', '#504270', '#2E4366', '#46484E'];

const getRandomColor = () => colors[Math.floor(Math.random() * (colors.length))];

const upperFirstLetter = (word: string): string => word.charAt(0).toUpperCase();

const getNameAbr = (name: string, type: AvatarAbrType): string => {
  const words = name.split(' ');
  if (words.length === 0) {
    return name;
  }

  const firstWord = words[0];

  if (words.length === 1 || type === 'short') {
    return upperFirstLetter(firstWord);
  }

  if (words.length >= 2) {
    return `${upperFirstLetter(firstWord)} ${upperFirstLetter(words[1])}`;
  }

  return name;
};

const calcNumber = (
  number: number,
  char: string,
  index: number,
  array: string[],
) => (number + char.charCodeAt(0) + array.length - index) % colors.length;

const getRandomColorFromStr = (string: string) => {
  const colorPosition = string
    .split('')
    .reduce(calcNumber, 0);
  return colors[colorPosition];
};

export const CircleAvatar = ({ name, type = 'short', colorFromName = false }: CircleAvatarProps) => {
  const color = useMemo(
    () => (colorFromName ? getRandomColorFromStr(name) : getRandomColor()),
    [colorFromName, name],
  );

  return (
    <Box sx={{
      minWidth: '48px',
      height: '48px',
      borderRadius: '24px',
      backgroundColor: color,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    >
      {getNameAbr(name, type)}
    </Box>
  );
};
