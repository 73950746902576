import { palette } from './palette';

export const colors = {
  primary: {
    50: '#F0F7FF',
    100: '#C2E0FF',
    200: '#80BFFF',
    300: '#66B2FF',
    400: '#3399FF',
    main: palette.blue.main,
    500: '#007FFF',
    600: '#0072E5',
    700: '#0059B2',
    800: '#004C99',
    900: '#003A75',
  },
  background: {
    default: palette.black.main,
    modal: '#17191E',
    light: '#3B4046',
    tooltip: '#17191E',
  },
  error: {
    main: palette.red.main,
    opacity50: palette.red.opacity50,
    opacity20: palette.red.opacity20,
  },
  chartText: palette.white.main,
  light: {
    main: palette.almostWhite.main,
  },
  success: {
    main: palette.green.main,
    opacity50: palette.green.opacity50,
    opacity20: palette.green.opacity20,
  },
  verified: {
    main: palette.green.main,
    opacity50: palette.green.opacity50,
    opacity20: palette.green.opacity20,
  },
  deployed: {
    main: palette.violette.main,
    opacity50: palette.violette.opacity50,
    opacity20: palette.violette.opacity20,
  },
  progress: {
    main: palette.turqouise.opacity80,
    opacity50: palette.turqouise.opacity50,
    opacity20: palette.turqouise.opacity20,
  },
  uploaded: {
    main: palette.orange.main,
    opacity50: palette.orange.opacity50,
    opacity20: palette.orange.opacity20,
  },
  recommended: {
    main: palette.blue.main,
    opacity50: palette.blue.opacity50,
    opacity20: palette.blue.opacity20,
  },
  disabled: {
    main: '#9B9DA180',
    opacity50: 'rgba(155, 157, 161, 0.5)',
  },
};

export type Color = keyof typeof colors;
