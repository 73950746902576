import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, Paper } from '@chaos/ui';
import { PageTracker } from 'src/components/page-tracker';
import { RouteParams, RoutePath } from 'src/config/routes';
import {
  useBlockchainSimulationResult,
  useCurrentTeam,
  usePageTitle,
} from 'src/hooks';
import { blockchaiSimulationResultCounts } from 'src/utils/blockchain-simulation-result-counts';
import { SimulationError } from 'src/components/simulatiion-error';
import { SimulationResultGeneralInfo } from 'src/components/simulation-result-general-info';
import { isParamConfigHistoryResult, isSimulationOnFinishedStatus, isSimulationStateOnScriptExecutionStatus } from 'src/utils/simulation-helper';
import { ParamConfigScatterChartRow } from 'src/components/charts/param-config-chart-row';
import { ParamConfigScatterChartGroup } from 'src/components/charts/param-config-chart-group';
import { ParamConfigHeatmap } from 'src/components/charts/param-config-heatmap';
import { ParamConfigPermutationTable } from 'src/components/charts/param-config-permutaion-table';
import { MainLayout } from 'src/components/layouts';
import { cancelBlockchainSimulationFn } from 'src/components/dashboard/network';
import { usePermission } from 'src/hooks/usePermissions';
import { useAppSelector } from 'src/store';
import { chaosSnackbar } from 'src/utils/chaos-snackbar-utils';
import { PaginatedSimulationResults } from 'src/components/paginated-simulation-results';
import { useSimulationPermutationResults } from 'src/hooks/useSimulationPermuationResults';
import { blockchainSimulationTitle } from './helpers';

export const MultiSimulationReport = PageTracker((): JSX.Element => {
  const { simulationResultId } = useParams<{ simulationResultId: string }>();
  const {
    result,
    simulation,
    observers,
    simulationStatistics,
  } = useBlockchainSimulationResult(simulationResultId!);
  const { permuationResults } = useSimulationPermutationResults(simulationResultId!);

  const { errorMessage, data } = (simulationStatistics || {});

  const counts = blockchaiSimulationResultCounts(result);
  const pageTitle = simulation?.name || blockchainSimulationTitle(result);
  usePageTitle(pageTitle);
  const simulationUrl = RoutePath.Simulations.Details.replace(RouteParams.SimulationId, simulation?.id || '');

  const [observationIds, setObservationIds] = useState<string[]>();

  const { permuatationsInfo, observationsInfo, simulationStats } = useMemo(() => ({
    permuatationsInfo: permuationResults?.length ? permuationResults.flatMap((pr) => ({
      args: pr.args, id: pr.id, resultIds: [simulationResultId!],
    })) : result?.result?.permutations,
    observationsInfo: permuationResults?.length
      ? permuationResults.flatMap((pr) => pr.observationInfos) : result?.result?.observationsInfo,
    simulationStats: permuationResults?.length ? {
      ...simulationStatistics,
      simulationId: simulation?.id || '',
      simulationResultId: simulationResultId || '',
      data: permuationResults.flatMap((pr) => ({
        metricStatistics: pr.metricStatistics || [],
        permutationInfo: pr,
      })),
    } : simulationStatistics,
  }), [permuationResults, result, simulationResultId, simulationStatistics, simulation?.id]);
  const validSimulationStatistics = (!errorMessage && data?.length) ? simulationStats : undefined;

  useEffect(() => {
    if (observationsInfo && permuatationsInfo) {
      const simulationsObserversIds = observers?.map((obs) => obs.id) || [];
      const observationsIds = observationsInfo.map(
        (info) => info.id,
      ).filter((id, i, a) => a.indexOf(id) === i && simulationsObserversIds.includes(id));
      setObservationIds(observationsIds);
    }
  }, [observers, permuatationsInfo, observationsInfo]);

  const isParamConfigSimulation = isParamConfigHistoryResult(result, simulation?.simulationType);
  let durationValue = `0 ${isParamConfigSimulation ? 'simulations' : 'blocks'}`;
  const totalIterations = (isParamConfigSimulation
    ? result?.meta?.iterations
    : simulation?.iterations) ?? 0;
  let durationHeaderName = 'Current state progress';
  if (result?.state === undefined || isSimulationOnFinishedStatus(result?.state)) {
    durationHeaderName = 'Duration';
    durationValue = isParamConfigSimulation
      ? `${result?.meta?.iterations ?? '0'} simulations`
      : `${(result?.meta?.endBlock || 0) - (result?.meta?.startBlock || 0)} blocks`;
  } else if (isSimulationStateOnScriptExecutionStatus(result?.state)) {
    durationValue = `${
      result?.meta?.currentIteration ?? '0'}/${totalIterations
    } ${isParamConfigSimulation ? 'simulations' : 'blocks'}`;
  }

  const scatterGroups = simulation?.observerGroupsMetadata?.map((observerGroup) => ({
    groupName: observerGroup.groupName,
    observations: (observerGroup.observerIds || []).map(
      (observerId) => (observers ?? []).filter((info) => info.id === observerId)[0],
    ),
  }));

  const groupped = scatterGroups?.flatMap((x) => x.observations)?.flatMap((x) => x?.id) || [];

  const relevantObservers = observers?.sort((a, b) => (a.displayOrder || 0) - (b.displayOrder || 0))
    .slice(0, 3);

  const simulationDate = useMemo(() => {
    const dateToParse = result?.created.seconds;
    return dateToParse
      ? dayjs(dateToParse * 1000).format('D/M/YYYY [at] h:mm a')
      : undefined;
  }, [result?.created.seconds]);

  const runtimeMillis = 1000 * Math.max(
    0,
    (result?.updated.seconds ?? 0) - (result?.created.seconds ?? 0),
  );

  const team = useCurrentTeam();
  const profile = useAppSelector((state) => state.firebase.auth);
  const triggerPermission = usePermission('trigger_simualation');
  const [isCanceled, setIsCanceled] = useState<boolean>(!triggerPermission);

  const cancelSimulation = async () => {
    if (!triggerPermission) {
      return;
    }

    setIsCanceled(true);
    const resultId = await cancelBlockchainSimulationFn(
      simulationResultId,
      profile.uid,
      team?.authKey,
    );

    if (!resultId) return;

    chaosSnackbar.toast(
      'Simulation execution cancled',
      {
        icon: 'check-circle',
      },
    );
  };

  const optionsMenuItems = (
    isCanceled || (result?.state && isSimulationOnFinishedStatus(result?.state)))
    ? undefined
    : [
      {
        title: 'Cancel',
        icon: 'close',
        onClick: async () => {
          await cancelSimulation();
        },
      },
    ];

  return (
    <MainLayout
      headerProps={{
        pageTitle,
        pageSubtitle: simulationDate,
        breadcrumbsLinks: [{
          title: 'Simulation Results',
          href: RoutePath.Simulations.Results,
        }],
        breadcrumbTitle: pageTitle,
        breadcrumbTitleLink: simulationUrl,
        menuItems: optionsMenuItems,
      }}
    >
      {result?.error && (
      <Box sx={{ pb: '40px' }}>
        <SimulationError errorText={result?.error} />
      </Box>
      )}
      <Box display="flex" flexDirection="column" gap={5}>
        <SimulationResultGeneralInfo
          id={simulationResultId!}
          state={result?.state}
          startBlock={result?.meta?.startBlock}
          currentIteration={result?.meta?.currentIteration}
          totalIteration={totalIterations}
          passed={counts.success}
          failed={counts.failed}
          durationHeaderName={durationHeaderName}
          durationValue={durationValue}
          runtimeMillis={runtimeMillis}
          description={simulation?.description}
          rpcUrl={result?.meta?.externalRemotePoolURL}
          isParamConfigSimulation={isParamConfigSimulation}
        />

        {(!!observationIds
        && observationIds.length > 0
        && !!observers
        && observers.length > 0) && (
          <>
            {!!permuatationsInfo?.length && !!observationsInfo?.length && (
              <ParamConfigHeatmap
                simulationResultId={simulationResultId!}
                permutationInfo={permuatationsInfo}
                observationsInfo={observationsInfo}
                observations={relevantObservers!}
                simulationStatistics={simulationStats}
              />
            )}

            <Box marginTop="24px">
              <ParamConfigPermutationTable
                simulationResultId={simulationResultId!}
                permutationInfo={permuatationsInfo ?? []}
                observationsPermutationInfo={observationsInfo ?? []}
                observers={relevantObservers!}
                simulationStatistics={simulationStats}
              />
            </Box>

            {scatterGroups && scatterGroups.map((obsrvGroup) => (
              <ParamConfigScatterChartGroup
                key={`scatter-group-${obsrvGroup.groupName}`}
                permutationInfo={permuatationsInfo ?? []}
                observationsInfo={observationsInfo ?? []}
                {...obsrvGroup}
                simulationStatistics={validSimulationStatistics}
              />
            ))}

            <Paper
              variant="card"
              sx={{
                padding: '24px 24px 32px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
              }}
            >
              <Grid container spacing={2}>
                {observationIds.filter((x) => !groupped.includes(x)).map((observationId) => (
                  <Grid item xs={6}>
                    <ParamConfigScatterChartRow
                      key={`scatter-${observationId}`}
                      simulationResultId={simulationResultId!}
                      permutationInfo={permuatationsInfo ?? []}
                      observationsInfo={observationsInfo ?? []}
                      observation={observers.filter((info) => info.id === observationId)[0]}
                      simulationStatistics={validSimulationStatistics}
                    />
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </>
        )}
        <Box marginTop="24px">
          <PaginatedSimulationResults
            title="Simulations"
            parentResultId={simulationResultId}
            omitNameColumn
            showCusomParameterConfigurationHeaders
          />
        </Box>
      </Box>
    </MainLayout>
  );
});
