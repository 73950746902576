import { useCallback, useMemo, useState } from 'react';
import {
  ScriptResource,
  CodeEntity,
  ObserverScript,
  Script,
} from '@chaos/types';
import { Box } from '@chaos/ui/box';
import { Button } from '@chaos/ui/button';
import { Typography } from '@chaos/ui/typography';
import { InputLabel } from '@chaos/ui/input-label';
import { TextField } from '@chaos/ui/text-field';
import { Tabs } from '@chaos/ui/tabs';
import imgUploadPreview from 'src/assets/img/img-upload-preview-dark.png';
import * as Yup from 'yup';
import { useValidation } from 'src/hooks/useValidation';
import { FirebaseUploader } from '../firebase-uploader';
import { CodeEditor } from '../code-editor';
import { ScriptSummary } from '../script-summary';

interface ScriptFormProps {
  type: CodeEntity,
  initialScript?: Script,
  onSubmit: (script: Script | ObserverScript) => Promise<void>,
  isEditPage?: boolean,
  allEntities: ScriptResource[]
}

const nonImgUpload: CodeEntity[] = ['Scenario'];

export const ScriptForm = ({
  type,
  onSubmit,
  initialScript,
  isEditPage,
  allEntities,
}: ScriptFormProps): JSX.Element => {
  const [tabIndex, setTabIndex] = useState(0);
  const [script, setScript] = useState<Partial<Script | ObserverScript>>(initialScript || {});
  const onChange = useCallback((code?: string, args?: string[], isClass?: boolean) => {
    setScript((currScript) => ({
      ...currScript,
      code: code || '',
      args: args?.reduce((res, arg) => ({
        ...res,
        [arg]: currScript.args?.[arg] || { type: 'string' },
      }), {}),
      isClass,
    }));
  }, []);

  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const handleSubmit = async () => {
    setIsSubmit(true);
    await onSubmit(script as Script);
    setIsSubmit(false);
  };

  const isImageUploadAvailable = !nonImgUpload.includes(type);

  const scriptFormValidationSchema = useMemo(() => Yup.object({
    name: Yup.string().uniqueName(allEntities),
  }), [allEntities]);

  const [validate, isFormValid, errors] = useValidation(scriptFormValidationSchema, initialScript);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ mb: 5 }}>
        <Tabs
          value={tabIndex}
          tabs={[{ label: 'Details' }, { label: 'Code' }, { label: 'Configuration' }]}
          fullWidth
        />
      </Box>
      {tabIndex === 0 && (
        <>
          <Typography component="h2" variant="h2" sx={{ py: '36px' }}>
            General
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              gap: { xs: 5, md: 0 },
              alignItems: 'center',
              width: '100%',
            }}
          >
            <FirebaseUploader
              category="img-agents"
              fallbackPreviewPath={imgUploadPreview}
              onUploadSuccess={(thumbnail) => setScript({
                ...script,
                thumbnail,
              })}
              value={script.thumbnail}
              disabled={!isImageUploadAvailable}
              isInitialHoverDisabled={!isImageUploadAvailable}
              isDark
            />
            <Box display="flex" flexDirection="column" gap={1} flex={1} width="100%">
              <Box>
                <InputLabel>{`${type} Name`}</InputLabel>
                <TextField
                  error={Boolean(errors?.name)}
                  helperText={errors?.name}
                  value={script.name}
                  placeholder={`${type} name`}
                  onChange={(e) => {
                    validate({ name: e.target.value.trim() });
                    setScript({
                      ...script,
                      name: e.target.value,
                    });
                  }}
                />
              </Box>
              <Box>
                <InputLabel>Description</InputLabel>
                <TextField
                  fullWidth
                  value={script.description}
                  multiline
                  minRows={3}
                  placeholder={`${type} description`}
                  onChange={(e) => setScript({
                    ...script,
                    description: e.target.value,
                  })}
                />
              </Box>
            </Box>
          </Box>
        </>
      )}
      {tabIndex === 1 && <CodeEditor entity={type} onChange={onChange} initialCode={script.code} />}
      {tabIndex === 2 && (
        <ScriptSummary
          type={type}
          script={script}
          onChangeObserver={(observerValues: Partial<ObserverScript>) => setScript({
            ...script,
            ...(observerValues || {}),
          })}
          onChange={(args) => setScript({
            ...script,
            args,
            argsDefinition: args,
          })}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '40px 0',
          backgroundColor: 'background.default',
          position: 'sticky',
          bottom: -40,
        }}
      >
        {tabIndex > 0 && (
          <Button color="secondary" onClick={() => setTabIndex(tabIndex - 1)}>
            Back
          </Button>
        )}
        <Button
          color="primary"
          disabled={(tabIndex === 0 ? !script.name : !script.code) || isSubmit || !isFormValid}
          sx={{
            ml: 'auto',
          }}
          onClick={() => {
            void (tabIndex < 2 ? setTabIndex(tabIndex + 1) : handleSubmit());
          }}
        >
          {tabIndex < 2 ? 'Next' : isEditPage ? 'Update' : 'Create'}
        </Button>
      </Box>
    </Box>
  );
};
