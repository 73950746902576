import { FC } from 'react';
import { Field, FieldProps } from 'formik';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { StandardTextFieldProps } from '@mui/material/TextField';

import { InputLabel } from '@chaos/ui/input-label';
import { MenuItem } from '@chaos/ui/menu-item';
import { TextField } from '@chaos/ui/text-field';
import { CustomIcon } from '../custom-icon';

interface Option {
  label: string
  value: string
}

interface SelectFieldProps extends StandardTextFieldProps {
  name: string
  label?: string
  placeholder: string
  options: Option[]
  onChange?: (value: any) => void
  textForSelected?: (selected: any) => any
}

export const SelectField: FC<SelectFieldProps> = ({
  name,
  label,
  placeholder,
  options,
  onChange,
  textForSelected,
  ...props
}): JSX.Element => (
  <>
    {label && <InputLabel>{label}</InputLabel>}
    <Field name={name}>
      {({
        field: { value, onChange: onChangeField, onBlur },
        meta,
      }: FieldProps) => {
        let error: string | undefined;
        if (meta && meta.touched && meta.error) {
          error = meta.error.replace(name, label || 'Value');
        }
        return (
          <FormControl fullWidth error={error !== undefined}>
            <TextField
              {...props}
              select
              id={name}
              name={name}
              fullWidth
              placeholder={placeholder}
              value={value as string || ''}
              onBlur={onBlur}
              onChange={(e) => {
                onChangeField(e);
                if (onChange) {
                  onChange(e.target.value);
                }
              }}
              SelectProps={{
                IconComponent: (iconProps) => (
                  <CustomIcon icon="chevron-down" {...iconProps} />
                ),
              }}
              InputProps={{
                placeholder,
              }}
            >
              {options.map(({ label: currLabel, value: currValue }) => (
                <MenuItem value={currValue} key={currLabel}>
                  {currLabel}
                </MenuItem>
              ))}
            </TextField>
            {error && <FormHelperText>{error}</FormHelperText>}
          </FormControl>
        );
      }}
    </Field>
  </>
);
