import {
  Box, Typography,
} from '@chaos/ui';
import { HeatmapChart } from '@chaos/ui/charts';
import { CustomReactSelect } from '@chaos/ui/custom-select';
import { ShimmerLoader } from '@chaos/ui/shimmer-loader';
import { formatAmount } from '@chaos/ui/utils/formatters';
import { useState } from 'react';
import { PriceManipulationResultType } from '../types';
import { parseExploitResultToHeatmapData } from '../utils';

type ParamOption = 'NetProfit' | 'CapitalEfficiencyScore';
export const dropdownOptions: Array<{ label: string, value: ParamOption }> = [
  {
    value: 'NetProfit',
    label: 'Attack Profit',
  },
  {
    value: 'CapitalEfficiencyScore',
    label: 'Capital Efficiency Score',
  },
];

type Props = {
  exploitResult?: PriceManipulationResultType;
  isCalculating: boolean;
};
const AssetProtectionHeatmap = ({ exploitResult, isCalculating }: Props) => {
  const [selectedParam, setSelectedParam] = useState<ParamOption>('NetProfit');
  return (
    <Box mt={5} display="flex" flexDirection="column" gap={3}>
      <Box
        height={624}
        p={3}
        width="100%"
        bgcolor="#181C1F"
        display="flex"
        flexDirection="column"
        gap={3}
        borderRadius={2}
      >
        {isCalculating ? <ShimmerLoader />
          : exploitResult && (
            <>
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <Typography fontSize={24} fontWeight={600}>
                    Calculation Results
                  </Typography>
                </Box>
                <Box display="flex" gap={3} alignItems="flex-end">
                  <CustomReactSelect
                    options={dropdownOptions}
                    value={dropdownOptions.find((opt) => opt.value === selectedParam)}
                    onChange={(option) => {
                      if (!option) return;
                      setSelectedParam(option.value as ParamOption);
                    }}
                  />
                </Box>
              </Box>
              <Box flex={1} height={0}>

                <HeatmapChart
                  data={parseExploitResultToHeatmapData(exploitResult, selectedParam)}
                  axisTop={null}
                  axisLeft={{
                    tickSize: 0,
                    tickPadding: 10,
                    tickRotation: 0,
                    legend: 'Capital Deployed',
                    legendPosition: 'middle',
                    legendOffset: -87,
                  }}
                  axisBottom={{
                    tickSize: 0,
                    tickPadding: 10,
                    legend: 'Price Pump Factor',
                    legendPosition: 'middle',
                    legendOffset: 62,
                  }}
                  height="100%"
                  margin={{
                    top: 0, right: 0, bottom: 70, left: 95,
                  }}
                  valueFormat={(value: number) => (
                    formatAmount(
                      value,
                      {
                        notation: 'compact',
                        maximumFractionDigits: 2,
                        currency: selectedParam === 'NetProfit' ? 'USD' : undefined,
                      },
                    )
                  )}
                  annotations={[
                    {
                      type: 'rect',
                      match: {
                        id: '15.5',
                      },
                      note: 'Best',
                      noteX: 85,
                      noteY: -85,
                      offset: 0,
                      noteTextOffset: 12,
                      borderRadius: 5,
                    },
                  ]}
                />
              </Box>
            </>
          )}
      </Box>
    </Box>
  );
};

export default AssetProtectionHeatmap;
