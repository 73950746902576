import { Route, Routes, Navigate } from 'react-router-dom';
import { RoutePath } from 'src/config/routes';
import { ModelRunsPage, ModelRunDetailsPage, ModelTriggerPage } from './pages';

export const replaceHomePath = (path: string) => path.replace(RoutePath.ParamRecommendationsTool.Home, '');

const ParamRecommendationsToolRoutes = () => (
  <Routes>
    <Route
      index
      element={(
        <Navigate
          to={RoutePath.ParamRecommendationsTool.ModelRuns}
          replace
        />
    )}
    />
    <Route
      path={replaceHomePath(RoutePath.ParamRecommendationsTool.ModelRuns)}
      element={<ModelRunsPage />}
    />
    <Route
      path={replaceHomePath(RoutePath.ParamRecommendationsTool.ModelTrigger)}
      element={<ModelTriggerPage />}
    />
    <Route
      path={replaceHomePath(RoutePath.ParamRecommendationsTool.ModelRunDetails)}
      element={<ModelRunDetailsPage />}
    />
  </Routes>
);

export default ParamRecommendationsToolRoutes;
