import { useEffect, useMemo, useState } from 'react';
import { Observable, Subscription } from 'rxjs';
import {
  collection, doc, limit, orderBy, query, startAfter, where,
} from 'firebase/firestore';
import { collectionData } from 'rxfire/firestore';
import { COLLECTIONS } from '@chaos/utils';
import { BlockchainSimulation } from '@chaos/types';
import { firestore } from 'src/services/firebase';
import { useCurrentTeam } from './useCurrentTeam';

export const useBlockchainSimulations = (
  sortBy: keyof BlockchainSimulation = 'createdAt',
  sortDirection: 'asc' | 'desc' = 'desc',
  last?: BlockchainSimulation,
  limitNumber?: number,
  idFilter?: string,
  createdAtFilter?: [number, number],
  nameFilter?: string,
): {
  isLoading: boolean
  blockchainSimulations: BlockchainSimulation[]
} => {
  const team = useCurrentTeam();
  const [simulations, setSimulations] = useState<BlockchainSimulation[]>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const subs: Subscription[] = [];
    const simsCol = collection(firestore, COLLECTIONS.BLOCKCHAIN_SIMULATIONS);
    const simulationsObs = collectionData(query(
      simsCol,
      where('team', '==', doc(collection(firestore, COLLECTIONS.TEAMS), team?.id)),
      ...(!idFilter ? [orderBy(sortBy, sortDirection)] : []),
      ...((last && !idFilter) ? [startAfter(last[sortBy])] : []),
      ...((limitNumber && !idFilter) ? [limit(limitNumber)] : []),
      ...(idFilter ? [where('id', '==', idFilter)] : []),
      ...((createdAtFilter && !idFilter && !nameFilter) ? [
        where('createdAt', '>', new Date(createdAtFilter[0])),
        where('createdAt', '<', new Date(createdAtFilter[1])),
      ] : []),
      ...((nameFilter && !idFilter) ? [
        where('lowercaseName', '>=', nameFilter.toLowerCase()),
        where('lowercaseName', '<=', `${nameFilter.toLowerCase()}\uf8ff}`),
      ] : []),
    ), { idField: 'id' }) as Observable<BlockchainSimulation[]>;
    subs.push(simulationsObs.subscribe((data) => {
      setIsLoading(false);
      setSimulations((s) => (last ? [...s || [], ...data] : data));
    }));

    return () => subs.forEach((s) => s.unsubscribe());
  }, [team?.id, sortBy, sortDirection, last, limitNumber, idFilter, createdAtFilter, nameFilter]);
  const reuslt = useMemo(
    () => ({ isLoading, blockchainSimulations: simulations || [] }),
    [isLoading, simulations],
  );

  return reuslt;
};
