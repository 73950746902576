import { FC, ReactNode } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CodeEntity } from '@chaos/types';
import { RoutePath } from 'src/config/routes';
import { useBlockchainAgents } from 'src/hooks/useBlockchainAgents';
import { useBlockchainAssertions } from 'src/hooks/useBlockchainAssertions';
import { useBlockchainObservers } from 'src/hooks/useBlockchainObservers';
import { useBlockchainScenarios } from 'src/hooks/useBlockchainScenarios';
import { PageTracker } from 'src/components/page-tracker';
import { ScriptSummary } from 'src/components/script-summary';
import { MainLayout } from 'src/components/layouts';
import { Loader } from 'src/components/loader';
import { Page404 } from '@chaos/ui';

type ScriptDetailsPageProps = {
  children: ReactNode,
  type: CodeEntity,
  title: string,
  breadcrumb: { link: string, title: string },
  editLink: string,
};

const ScriptDetailsPage: FC<ScriptDetailsPageProps> = ({
  children, type, title, breadcrumb, editLink,
}) => {
  const navigate = useNavigate();

  const optionsMenuItems = [
    {
      title: `Edit ${type}`,
      icon: 'pencil',
      onClick: () => navigate(editLink),
    },
  ];

  return (
    <MainLayout
      headerProps={{
        pageTitle: title,
        menuItems: optionsMenuItems,
        breadcrumbsLinks: [{
          title: breadcrumb.title,
          href: breadcrumb.link,
        }],
      }}
    >
      {children}
    </MainLayout>
  );
};

export const AgentDetailsPage = PageTracker((): JSX.Element => {
  const { isLoading, blockchainAgents } = useBlockchainAgents();
  const { id } = useParams<{ id: string }>();
  const agent = blockchainAgents.find((curr) => curr.id === id);

  if (isLoading) {
    return <Loader />;
  }

  return agent ? (
    <ScriptDetailsPage
      type="Agent"
      title={agent.name}
      breadcrumb={{ title: 'Agents', link: RoutePath.AgentPlayground.Home }}
      editLink={RoutePath.AgentPlayground.Edit.replace(':id', id!)}
    >
      <ScriptSummary script={agent} type="Agent" />
    </ScriptDetailsPage>
  ) : <Page404 />;
});

export const AssertionDetailsPage = PageTracker((): JSX.Element => {
  const { isLoading, blockchainAssertions } = useBlockchainAssertions();
  const { id } = useParams<{ id: string }>();
  const assertion = blockchainAssertions.find((curr) => curr.id === id);

  if (isLoading) {
    return <Loader />;
  }

  return assertion ? (
    <ScriptDetailsPage
      type="Assertion"
      title={assertion.name}
      breadcrumb={{ title: 'Assertions', link: RoutePath.AssertionWizard.Home }}
      editLink={RoutePath.AssertionWizard.Edit.replace(':id', id!)}
    >
      <ScriptSummary script={assertion} type="Assertion" />
    </ScriptDetailsPage>
  ) : <Page404 />;
});

export const ObserverDetailsPage = PageTracker((): JSX.Element => {
  const { isLoading, blockchainObservers } = useBlockchainObservers();
  const { id } = useParams<{ id: string }>();
  const observer = blockchainObservers.find((curr) => curr.id === id);

  if (isLoading) {
    return <Loader />;
  }

  return observer ? (
    <ScriptDetailsPage
      type="Observer"
      title={observer.name}
      breadcrumb={{ title: 'Observer Configuration', link: RoutePath.ObserverConfiguration.Home }}
      editLink={RoutePath.ObserverConfiguration.Edit.replace(':id', id!)}
    >
      <ScriptSummary script={observer} type="Observer" />
    </ScriptDetailsPage>
  ) : <Page404 />;
});

export const ScenarioDetailsPage = PageTracker((): JSX.Element => {
  const { isLoading, blockchainScenarios } = useBlockchainScenarios();
  const { id } = useParams<{ id: string }>();
  const scenario = blockchainScenarios.find((curr) => curr.id === id);

  if (isLoading) {
    return <Loader />;
  }

  return scenario ? (
    <ScriptDetailsPage
      type="Scenario"
      title={scenario.name}
      breadcrumb={{ title: 'Scenarios', link: RoutePath.ScenarioCatalogue.Home }}
      editLink={RoutePath.ScenarioCatalogue.Edit.replace(':id', id!)}
    >
      <ScriptSummary script={scenario} type="Scenario" />
    </ScriptDetailsPage>
  ) : <Page404 />;
});
