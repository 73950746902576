import { FC } from 'react';
import { SxProps } from '@mui/material';
import { Chip } from '@chaos/ui/chip';

enum Status {
  error,
  success,
  verified,
  deployed,
  uploaded,
  disabled,
}

interface StatusChipProps {
  label: string
  status: keyof typeof Status
  sx?: SxProps
}

export const StatusChip: FC<StatusChipProps> = ({ label, status, sx }) => (
  <Chip
    label={label}
    sx={{
      ...(sx || {}),
      padding: 0,
      height: 24,
      borderRadius: '8px',
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 'fontWeightBold',
      backgroundColor: `${status}.opacity50`,
    }}
  />
);
