import {
  Grid, TextField, InputLabel, InputAdornment, CustomReactSelect,
} from '@chaos/ui';
import { ScriptResource } from '@chaos/types';
import { FC } from 'react';
import { CustomIcon } from '../custom-icon';
import { HintWithIcon } from '../hint-with-icon';

type OracleConfigProps = {
  setSelectedInput: (value: React.SetStateAction<Partial<ScriptResource>>) => void,
  selectedInput: Partial<ScriptResource>,
};

const tokenOptions = [
  { label: 'CRVV1ETHSTETH', value: 'CRVV1ETHSTETH', icon: 'unknown' },
  { label: 'ETH', value: 'ETH', icon: 'ethereum-token' },
  { label: 'WBTC', value: 'WBTC', icon: 'wbtc' },
  { label: 'STETH', value: 'STETH', icon: 'steth' },
  { label: 'MANA', value: 'MANA', icon: 'mana' },
  { label: 'LINK', value: 'LINK', icon: 'link' },
  { label: 'YFI', value: 'YFI', icon: 'yfi' },
  { label: 'MATIC', value: 'MATIC', icon: 'matic' },
  { label: 'UNIV2DAIETH', value: 'UNIV2DAIETH', icon: 'unknown' },
  { label: 'UNIV2WBTCETH', value: 'UNIV2WBTCETH', icon: 'unknown' },
  { label: 'UNIV2USDCETH', value: 'UNIV2USDCETH', icon: 'unknown' },
  { label: 'UNIV2UNIETH', value: 'UNIV2UNIETH', icon: 'unknown' },
  { label: 'UNIV2WBTCDAI', value: 'UNIV2WBTCDAI', icon: 'unknown' },
  { label: 'GUSD', value: 'GUSD', icon: 'gusd' },
];

export const OracleConfig: FC<OracleConfigProps> = ({
  setSelectedInput, selectedInput,
}) => (
  <Grid container spacing={3}>
    <Grid item xs={6}>
      <InputLabel>Select Token</InputLabel>
      <CustomReactSelect
        name="token"
        placeholder="Token"
        options={tokenOptions}
        value={tokenOptions.find(
          ({ value }) => value === selectedInput?.args?.collateralType.value,
        )}
        onChange={(selected) => setSelectedInput({
          ...selectedInput,
          args: {
            ...selectedInput.args,
            collateralType: {
              type: 'string',
              value: selected?.value.toString() || '',
            },
          },
        })}
        isSearchable
      />
    </Grid>
    <Grid item sx={{ alignSelf: 'end' }} xs={6}>
      <InputLabel>Select Target</InputLabel>
      <TextField
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ ml: 2 }}>
              <CustomIcon icon="coin-dollar" />
            </InputAdornment>
          ),
          /* eslint-disable-next-line react/jsx-no-duplicate-props */ }}
        inputProps={{ min: 0 }}
        disabled
        placeholder="USD"
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <InputLabel>Select Initial Mock Price ($)</InputLabel>
      <TextField
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ ml: 2 }}>$</InputAdornment>
          ),
          /* eslint-disable-next-line react/jsx-no-duplicate-props */ }}
        inputProps={{ min: 0 }}
        value={selectedInput?.args?.initialPrice.value}
        onChange={(e) => setSelectedInput({
          ...selectedInput,
          args: {
            ...selectedInput.args,
            initialPrice: {
              type: 'number',
              value: e.target.value,
            },
          },
        })}
        placeholder="Value"
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <InputLabel sx={{ justifyContent: 'start' }}>
        Price Change
        <HintWithIcon sx={{ ml: 2 }} iconProps={{ icon: 'question-mark' }}>
          Delta in price change as a percentage of the initial price
        </HintWithIcon>
      </InputLabel>
      <TextField
        InputProps={{
          endAdornment: (<InputAdornment sx={{ mr: '16px !important' }} position="end">%</InputAdornment>),
          /* eslint-disable-next-line react/jsx-no-duplicate-props */ }}
        inputProps={{ min: 0, max: 100 }}
        fullWidth
        value={selectedInput?.args?.pricePercentageDelta.value}
        onChange={(e) => setSelectedInput({
          ...selectedInput,
          args: {
            ...selectedInput.args,
            pricePercentageDelta: {
              type: 'number',
              value: e.target.value,
            },
          },
        })}
        placeholder="Value"
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <InputLabel>Frequency of Price Change</InputLabel>
      <TextField
        InputProps={{
          endAdornment: (<InputAdornment sx={{ mr: '16px !important' }} position="end">blocks</InputAdornment>),
        }}
        fullWidth
        value={selectedInput?.args?.blockUpdateInterval.value}
        onChange={(e) => setSelectedInput({
          ...selectedInput,
          args: {
            ...selectedInput.args,
            blockUpdateInterval: {
              type: 'number',
              value: e.target.value,
            },
          },
        })}
        placeholder="Enter Value"
      />
    </Grid>
  </Grid>
);
