import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Header, RenderData } from '@chaos/types';
import { MainLayout } from 'src/components/layouts';
import { PageTracker } from 'src/components/page-tracker';
import { useBlockchainSimulationResult } from 'src/hooks/useBlockchainSimulationResult';
import { useSimulationBlocks } from 'src/hooks/useSimulationBlocks';
import { RouteParams, RoutePath } from 'src/config/routes';
import { ChaosTable } from 'src/components/widgets';
import { Loader } from 'src/components/loader';
import { formatAmount } from 'src/components/charts/utils';

export const BlockExplorerBlocksPage = PageTracker(() => {
  const { simulationResultId } = useParams<{ simulationResultId: string }>();
  const { simulation, result } = useBlockchainSimulationResult(simulationResultId!, true);
  const { isLoading, simulationBlocks } = useSimulationBlocks(
    simulationResultId!,
    result?.meta?.connectionKey,
  );
  const headers: Header[] = useMemo(() => [{
    renderType: 'TEXT',
    text: 'Block',
  }, {
    renderType: 'TEXT',
    text: 'Date',
  }, {
    renderType: 'TEXT',
    text: 'Txn',
  }, {
    renderType: 'TEXT',
    text: 'Gas Used',
  }, {
    renderType: 'TEXT',
    text: 'Gas Limit',
  }], []);
  const data = useMemo(() => simulationBlocks.map<RenderData[]>((block) => [{
    renderType: 'LINK',
    text: block.number.toString(),
    value: block.number,
    href: RoutePath.Simulations.BlockExplorer.BlockDetails
      .replace(RouteParams.SimulationResultId, simulationResultId!)
      .replace(RouteParams.BlockNumber, block.number.toString()),
  }, {
    renderType: 'TEXT',
    text: dayjs(block.timestamp * 1000).format('MM/DD/YYYY hh:mm'),
  }, {
    renderType: block.transactionsCount ? 'LINK' : 'TEXT',
    text: formatAmount(block.transactionsCount),
    value: block.transactionsCount,
    href: RoutePath.Simulations.BlockExplorer.BlockTransactions
      .replace(RouteParams.SimulationResultId, simulationResultId!)
      .replace(RouteParams.BlockNumber, block.number.toString()),
  }, {
    renderType: 'TEXT',
    text: `${formatAmount(block.gasUsed)} (${formatAmount((block.gasUsed / block.gasLimit) * 100)}%)`,
  }, {
    renderType: 'TEXT',
    text: formatAmount(block.gasLimit),
    value: block.gasLimit,
  }]), [simulationBlocks, simulationResultId]);

  const breadcrumbsLinks = [{
    title: 'Simulation Results',
    href: RoutePath.Simulations.Results,
  }, {
    title: simulation?.name || '',
    href: RoutePath.Simulations.ResultDetails
      .replace(RouteParams.SimulationResultId, simulationResultId!),
  }, {
    title: 'Block Explorer',
    href: RoutePath.Simulations.BlockExplorer.Home
      .replace(RouteParams.SimulationResultId, simulationResultId!),
  }];
  return (
    <MainLayout height={0} headerProps={{ pageTitle: 'Latest Blocks', breadcrumbsLinks }}>
      {!isLoading ? <ChaosTable data={data} headers={headers} isFullHeight /> : <Loader />}
    </MainLayout>
  );
});
