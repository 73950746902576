import { renderToString } from 'react-dom/server';
import { TooltipComponentFormatterCallbackParams } from 'echarts';
import { Box } from '../box';
import { Typography } from '../typography';
import { formatAxis } from '../utils/formatters';

type ParamType = { axisValue: string | number };

interface EchartsTooltipFormatterProps {
  headerFormatter?: (value: string | number) => string
  valueFormatter?: (val: string | number) => string
  currency?: string
  showTotal?: boolean
  showXAxisInline?: boolean
  hideZeroValues?: boolean
}

export const echartsTooltipFormatter = ({
  headerFormatter,
  currency,
  valueFormatter = (val: string | number) => formatAxis(val, 4, currency),
  showXAxisInline = false,
  hideZeroValues = false,
  showTotal = false,
}: EchartsTooltipFormatterProps = {}) => (params: TooltipComponentFormatterCallbackParams) => {
  const paramsArr = Array.isArray(params) ? params : [params];
  const paramValue = (paramsArr[0] as unknown as ParamType)?.axisValue;
  const xAxisCategory = paramsArr[0]?.name;
  const xAxisValue = Array.isArray(paramValue)
    ? Number(paramValue[0])
    : Number(paramValue);
  const tooltipHeader = headerFormatter?.(xAxisValue || xAxisCategory)
      || (!Number.isNaN(xAxisValue) ? xAxisValue : xAxisCategory);

  const total: number | undefined = showTotal && paramsArr.length > 1
    ? paramsArr.reduce((sum, { value }) => {
      const datapointValue = Array.isArray(value) ? Number(value[1]) : Number(value);
      return sum + datapointValue;
    }, 0)
    : undefined;

  return renderToString(
    <Box padding={2} bgcolor="background.modal" borderRadius={2} data-testid="tooltip">
      {!!tooltipHeader?.toString() && (
      <Typography marginBottom={1} variant="body2">{tooltipHeader}</Typography>
      )}
      {total && (
      <Typography marginBottom={1} variant="body2">{`Total: ${valueFormatter(total)}`}</Typography>
      )}
      <Box
        display="flex"
        maxWidth={700}
        gap={0.5}
        flexWrap="wrap"
        flexDirection="column"
      >
        {paramsArr
          .filter((param) => {
            if (hideZeroValues && Array.isArray(param.value) && param.value[1] === 0) {
              return false;
            }
            return param;
          })
          .map((param) => {
            const {
              seriesId,
              seriesName = '',
              value,
              color,
              dimensionNames,
              componentSubType,
            } = param;

            if (Array.isArray(value) && componentSubType === 'scatter') {
              return (
                <Box>
                  {value.map((val, idx) => (
                    <Box
                      key={seriesId}
                      display="flex"
                      gap={1}
                      alignItems="baseline"
                    >
                      <Typography variant="caption" color="#9B9DA1">
                        {dimensionNames?.[idx]}
                        :
                      </Typography>
                      <Typography variant="caption">
                        {valueFormatter(Number(val))}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              );
            }

            return (
              <Box key={seriesId} display="flex" gap={1} alignItems="baseline">
                <Box
                  bgcolor={color?.toString()}
                  width={8}
                  height={8}
                  borderRadius="50%"
                />
                <Typography variant="caption" color="#9B9DA1">
                  {`${seriesName}${
                    showXAxisInline ? ` ${xAxisCategory}` : ''
                  }:`}
                </Typography>
                <Typography variant="caption">
                  {valueFormatter(Array.isArray(value) ? Number(value[1]) : Number(value))}
                </Typography>
              </Box>
            );
          })}
      </Box>
    </Box>,
  );
};
