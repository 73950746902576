import {
  FC, ReactNode,
} from 'react';
import { Box } from '@chaos/ui/box';
import { useMediaQuery, Theme } from '@chaos/ui/theme';
import { ErrorBoundaryComponent } from '@chaos/ui/error-boundary';
import { AppNav } from '../app-nav';
import { AppMenuMobile } from '../app-menu-mobile';

interface PageLayoutProps {
  children?: ReactNode
}

export const PageLayout: FC<PageLayoutProps> = ({ children }) => {
  const mdAndUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));

  return (
    <Box display="flex" width="100%" minHeight="100%">
      {mdAndUp && (
        <Box component="aside" maxHeight="100vh" position="sticky" top={0}>
          <AppNav />
        </Box>
      )}
      <Box
        component="main"
        display="flex"
        flexDirection="column"
        flexGrow={1}
        minWidth={0}
        position="relative"
        height="100vh"
        overflow="auto"
        ml={mdAndUp ? 5 : 0}
      >
        {!mdAndUp && <AppMenuMobile />}
        <ErrorBoundaryComponent>{children}</ErrorBoundaryComponent>
      </Box>
    </Box>
  );
};
