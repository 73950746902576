import React, { useCallback, useRef, useState } from 'react';
import { ConfirmationPromptDialog } from '../confirmation-prompt-dialog';

const DISABLE_KEY = 'DisableConfirmationLeavingDialog';
export const getDisablePromptPushState: () => { [DISABLE_KEY]: boolean } = () => ({
  [DISABLE_KEY]: true,
});

interface ConfirmationLeavingDialogProps {
  title?: string
  message: string
}
export const ConfirmationLeavingDialog: React.FC<ConfirmationLeavingDialogProps> = ({
  children,
  message,
  title = 'Leaving Page',
}) => {
  const [isShowModal, setIsShow] = useState(false);
  const historyBlocker = useRef(() => {});
  // const historyLocation: React.MutableRefObject<typeof history['location'] | undefined> =
  // useRef();
  // useEffect(() => {
  //   if (historyBlocker.current) {
  //     historyBlocker.current();
  //   }
  //   console.log('ConfirmationLeavingDialog effect');
  //   historyBlocker.current = history.block((location) => {
  //     if (!location.state || location.state[DISABLE_KEY] !== true) {
  //       setIsShow(true);
  //       historyLocation.current = location;
  //       return false;
  //     }
  //     return historyBlocker?.current();
  //   });

  //   return () => {
  //     historyBlocker?.current();
  //   };
  // }, [history]);

  const onConfirmed = useCallback(() => {
    historyBlocker?.current();

    // if (historyLocation.current) {
    //   history.push(historyLocation.current);
    // }
  }, []);
  const onReject = useCallback(() => {
    setIsShow(false);
  }, []);

  return (
    <ConfirmationPromptDialog
      confirmButtonText="Leave"
      cancelButtonText="Stay here"
      isShow={isShowModal}
      title={title}
      onConfirm={onConfirmed}
      onClose={onReject}
    >
      {message || children}
    </ConfirmationPromptDialog>
  );
};
