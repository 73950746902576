/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import axios from 'axios';
import { ExtendedFirebaseInstance } from 'react-redux-firebase';

export async function FetchStorageBlobs(firebase: ExtendedFirebaseInstance, paths: string[]):
Promise<Record<string, any>> {
  const storageRef = firebase.storage();
  const refs = paths.map((path) => storageRef.ref(path));
  const urlPromises = refs.map((ref) => ref.getDownloadURL());
  const urls = await Promise.all(urlPromises);
  const downloadPromises = urls.map((url) => axios.get<any>(url));
  const downloads = await Promise.all(downloadPromises);
  const blobs = downloads.map((download) => download.data);
  const results: Record<string, any> = {};
  paths.forEach((path, index) => {
    results[path] = blobs[index];
  });

  return results;
}
