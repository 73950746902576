import {
  useState, useEffect, Dispatch, SetStateAction,
} from 'react';
import { fillDashboardData } from 'src/data-fetchers';
import { DashboardPayload } from '@chaos/types';
import { useCurrentTeam } from 'src/hooks';
import { useAppDispatch, useAppSelector } from 'src/store';
import { setCurrentDashboardAction } from 'src/store/dashboard/reducer';
import { ModalId } from 'src/pages/overview-page/types';
import { EmptyState } from 'src/components/empty-state';
import { useParams } from 'react-router-dom';
import { Loader } from '../loader';
import { Error } from '../error';
import { DashboardBuilder } from './DashboardBuilder';

interface DashboardProps {
  dashboards: DashboardPayload[]
  isLoading: boolean
  error: boolean
  setError: Dispatch<SetStateAction<boolean>>
  openModal?: (type: ModalId) => void;
}

export const Dashboard = ({
  dashboards,
  isLoading,
  error,
  setError,
  openModal,
}: DashboardProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const currentDashboard = useAppSelector(
    (state) => state.dashboard.currentDashboard,
  );
  const [filledDashboard, setFilledDashboard] = useState<
  DashboardPayload | undefined
  >(undefined);
  const team = useCurrentTeam();
  const [isReloading, setIsReloading] = useState(isLoading);

  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    const newDashboard = dashboards.find((dash) => dash.id === id);
    dispatch(setCurrentDashboardAction(newDashboard));
  }, [id, dashboards, dispatch]);

  useEffect(() => {
    let isCanceled = false;

    if (currentDashboard) {
      setError(false);
      setIsReloading(true);
      fillDashboardData(currentDashboard)
        .then((res) => {
          if (!isCanceled) {
            setFilledDashboard(res);
          }
        })
        .catch((e) => {
          console.log('Filled Dashboards failed...', e);
          setError(true);
        })
        .finally(() => {
          setIsReloading(false);
        });
    }

    return () => {
      isCanceled = true;
    };
  }, [currentDashboard, setError]);

  useEffect(() => {
    if ((!currentDashboard || !dashboards.find(
      (dashboard) => dashboard.id === currentDashboard?.id,
    )) && dashboards.length > 0) {
      dispatch(setCurrentDashboardAction(dashboards[0]));
    } else if (team === undefined && !isReloading) {
      dispatch(setCurrentDashboardAction(dashboards[0]));
      // public dashboard has no team, this is an edge
    }
  }, [isReloading, currentDashboard, dashboards, team, dispatch]);

  try {
    return error ? (
      <Error errorMessage="The API service is unavailable." />
    ) : isReloading || isLoading ? (
      <Loader />
    ) : (!isReloading && dashboards.length === 0) || !filledDashboard ? (
      <EmptyState type="visual data" />
    ) : (
      <>
        {DashboardBuilder(
          filledDashboard,
          currentDashboard?.name,
          openModal && (() => openModal('add-widget')),
        )}
      </>
    );
  } catch (e) {
    console.error('Dashboard err:', e);
    return <Error />;
  }
};
