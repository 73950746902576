import { CodeEntity } from '@chaos/types';
import * as monaco from 'monaco-editor';

export const SUPPORTED_FILE_TYPES = 'text/javascript';

export const getDefaultPreset = (entity: CodeEntity) => `import { ${entity}Chaos } from "@chaos-labs/simulation-ts-modules";
const BigNumber = CM.BigNumber;

export async function main(chainUrl)  {
  log("${entity} logic goes here");
}
`;

export const MONACO_THEME: monaco.editor.IStandaloneThemeData = {
  base: 'vs-dark',
  inherit: true,
  rules: [
    { token: '', foreground: '6D9AB4', background: '1E1E1E' },
    { token: 'invalid', foreground: 'f44747' },
    { token: 'emphasis', fontStyle: 'italic' },
    { token: 'strong', fontStyle: 'bold' },

    { token: 'variable', foreground: '74B0DF' },
    { token: 'variable.predefined', foreground: '4864AA' },
    { token: 'variable.parameter', foreground: '9CDCFE' },
    { token: 'constant', foreground: '569CD6' },
    { token: 'comment', foreground: '9B9DA1' },
    { token: 'number', foreground: 'B5CEA8' },
    { token: 'number.hex', foreground: '5BB498' },
    { token: 'regexp', foreground: 'B46695' },
    { token: 'annotation', foreground: 'cc6666' },
    { token: 'type', foreground: '5D94FF' },

    { token: 'delimiter', foreground: 'DCDCDC' },

    { token: 'tag', foreground: '569CD6' },
    { token: 'metatag', foreground: 'DD6A6F' },

    { token: 'key', foreground: '9CDCFE' },

    { token: 'string', foreground: 'FFA318' },

    { token: 'keyword', foreground: 'B85DFF' },

  ],
  colors: {
    'editor.background': '#22252C',
    'editorLineNumber.foreground': '#9B9DA1',
    'editor.lineHighlightBackground': '#5A2D3E',
  },
};

export const MONACO_OPTIONS: monaco.editor.IEditorConstructionOptions = {
  autoIndent: 'full',
  automaticLayout: true,
  contextmenu: true,
  fontFamily: 'Armata',
  fontSize: 12,
  lineHeight: 24,
  hideCursorInOverviewRuler: true,
  matchBrackets: 'always',
  minimap: {
    enabled: false,
  },
  folding: false,
  readOnly: false,
  suggestOnTriggerCharacters: false,
  acceptSuggestionOnEnter: 'off',
  quickSuggestions: {
    other: false,
    comments: false,
    strings: false,
  },
  parameterHints: {
    enabled: false,
  },
};
