import { firestore } from 'src/services/firebase';
import { BlockchainSimulation, Script, CodeEntity } from '@chaos/types';
import {
  RunBlockchainSimulation,
  CreateBlockchainSimulation,
  CreateBlockchainAgent,
  CreateBlockchainScenario,
  CreateBlockchainAssertion,
  CreateBlockchainObserver,
  CancelBlockchainSimulation,
} from '../../services/engine';

export const createBlockchainSimulationFn = async function createBlockchainSimulationFn(
  simulation: Partial<BlockchainSimulation>,
  authKey: string | undefined,
  onSimulationCreate: (par: any) => void,
): Promise<void> {
  if (simulation && authKey) {
    // eslint-disable-next-line
    const data = await CreateBlockchainSimulation(simulation, authKey);
    onSimulationCreate(data);
  }
};

export const createBlockchainScriptFn = async (
  script: Script,
  type: CodeEntity,
  authKey: string | undefined,
) => {
  if (authKey) {
    if (type === 'Agent') {
      await CreateBlockchainAgent(script, authKey);
    } if (type === 'Scenario') {
      await CreateBlockchainScenario(script, authKey);
    } if (type === 'Assertion') {
      await CreateBlockchainAssertion(script, authKey);
    } if (type === 'Observer') {
      await CreateBlockchainObserver(script, authKey);
    }
  }
};

export const runBlockchainSimulationFn = async function runBlockchainSimulationFn(
  simulationID: string | undefined,
  userID: string | undefined,
  authKey: string | undefined,
  onSimulationUpdate?: (par: any) => void,
  setResultId?: (par: string) => void,
): Promise<string | undefined> {
  if (simulationID && userID && authKey) {
    // eslint-disable-next-line
    const data: string = await RunBlockchainSimulation(simulationID, userID, authKey);
    onSimulationUpdate?.(data);
    if (setResultId) {
      setResultId(data);
    }

    return data;
  }
  return undefined;
};

export const cancelBlockchainSimulationFn = async function cancelBlockchainSimulationFn(
  simulationID: string | undefined,
  userID: string | undefined,
  authKey: string | undefined,
): Promise<string | undefined> {
  if (simulationID && userID && authKey) {
    // eslint-disable-next-line
    const data: string = await CancelBlockchainSimulation(simulationID, userID, authKey);
    return data;
  }
  return undefined;
};

export function fetchPageContent(updateCallback: (data: any) => void): void {
  const defaultDashId = 'D4kxrlSu8yOfHocJU1pO';
  firestore
    .collection('dashboards')
    .doc(defaultDashId)
    .onSnapshot((querySnapshot: any) => {
      if (!querySnapshot) {
        return;
      }
      try {
        // eslint-disable-next-line
        updateCallback(querySnapshot.data());
      } catch (e) {
        console.log('network - fetchPageContent failed...', e);
      }
    });
}
