import { FC } from 'react';
import { SimulationContract } from '@chaos/types';
import { Box } from '@chaos/ui';
import { Loader } from 'src/components/loader';
import { useSimulationContractEvents } from 'src/hooks/useSimulationContractEvents';
import { EventList } from 'src/components/transaction-details/event-list';

type BlockExplorerAddressContractEventsProps = {
  contract: SimulationContract,
};

export const BlockExplorerAddressContractEvents: FC<BlockExplorerAddressContractEventsProps> = ({
  contract,
}) => {
  const { simulationContractEvents, isLoading } = useSimulationContractEvents(
    contract.simulationResultId,
    contract.address,
  );

  return !isLoading ? (
    <Box display="flex" flexDirection="column">
      <EventList events={simulationContractEvents} />
    </Box>
  ) : <Loader />;
};
