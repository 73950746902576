import {
  Box, Button, CustomReactSelect, Page404,
} from '@chaos/ui';
import { useParams } from 'react-router-dom';
import { Loader } from '@chaos/ui/loader';
import { MainLayout } from 'src/components/layouts';
import { useMemo, useState } from 'react';
import {
  DetailsCard, PromoteModal, PublishModal, RecommendationsTable, RulesTable,
} from './components';
import { useModelRunResults } from '../../data/hooks/use-model-run-results';
import { useSelectAssetResult } from './use-select-asset-result';

const ModelRunDetailsPage = () => {
  const { id } = useParams();
  const { isLoading, modelRunResults } = useModelRunResults(id!);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [isPromoteModalOpen, setIsPromoteModalOpen] = useState(false);
  const results = useMemo(() => modelRunResults?.result?.results || [], [modelRunResults]);

  const {
    assetsOptions,
    selectedAsset,
    setSelectedAsset,
    selectedAssetResult,
  } = useSelectAssetResult(results || []);

  if (isLoading) return <Loader />;

  if (!results?.length) return <Page404 />;

  const assetDropdownOptions = assetsOptions.map((asset) => ({
    value: asset,
    label: asset,
    cryptoIcon: asset,
  }));

  return (
    <MainLayout headerProps={{
      pageTitle: 'Model Results',
      suffixComponent: (
        <Box display="flex" gap={2}>
          <Button color="primary" onClick={() => setIsPromoteModalOpen(true)}>Promote</Button>
          <Button color="secondary" onClick={() => setIsPublishModalOpen(true)}>Publish to Under Review</Button>
        </Box>
      ),
    }}
    >
      <Box display="flex" flexDirection="column" gap={3}>
        <DetailsCard
          startDate={modelRunResults!.created}
          results={results}
        />
        <CustomReactSelect
          options={assetDropdownOptions}
          value={assetDropdownOptions.find((opt) => opt.value === selectedAsset)}
          onChange={(option) => {
            if (!option) return;
            setSelectedAsset(option.value);
          }}
        />
        {selectedAssetResult && (
          <>
            <RecommendationsTable
              current={selectedAssetResult.current}
              recommended={selectedAssetResult.recommended}
            />
            <RulesTable
              modelRunResults={selectedAssetResult}
            />
          </>
        )}
      </Box>
      <PublishModal
        isOpen={isPublishModalOpen}
        setIsOpen={setIsPublishModalOpen}
        modelResults={results}
      />
      <PromoteModal
        isOpen={isPromoteModalOpen}
        setIsOpen={setIsPromoteModalOpen}
        modelResults={results}
      />
    </MainLayout>
  );
};

export default ModelRunDetailsPage;
