import { FC } from 'react';
import { BlockchainSimulation } from '@chaos/types';
import { Grid, Typography, Box } from '@chaos/ui';
import { Accordion } from 'src/components/accordion';
import { SimulationResultInfoCard } from 'src/components/simulation-result-info-card';

interface SimulationGeneralInfoProps {
  simulation: BlockchainSimulation
}

const checkValue = (value: string | undefined): string => ((value && value.length) ? value : '-');

export const SimulationGeneralInfo: FC<SimulationGeneralInfoProps> = ({ simulation }) => (
  <Accordion
    defaultExpanded
    header={(
      <Box sx={{
        display: 'flex', alignItems: 'center',
      }}
      >
        <Typography variant="h2">
          General
        </Typography>
      </Box>
      )}
  >
    <Grid container spacing={3}>
      <Grid item xs={12} md={4} key="Name-InfoCard">
        <SimulationResultInfoCard titleType="small" title="Name" value={checkValue(simulation.name)} />
      </Grid>
      <Grid item xs={12} key="Description-InfoCard">
        <SimulationResultInfoCard
          titleType="small"
          title="Description"
          value={checkValue(simulation.description)}
        />
      </Grid>
    </Grid>
  </Accordion>
);
