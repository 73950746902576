import { ChartType } from '@chaos/types';

// TODO: export the sources as a type from data-fetchers or a common library
export const widgetsPerSource: Record<string, ChartType[]> = {
  'ardana.api_health': [ChartType.OverviewWithIconChart],
  'ardana.combined': [ChartType.JSONWidget],
  'ardana.aggregate_volume': [ChartType.JSONWidget],
  'ardana.aggregate_liquidity': [ChartType.JSONWidget],
  'benqi.api_health': [ChartType.OverviewWithIconChart],
  'benqi.markets_borrow_assets': [ChartType.OverviewWithIconChart],
  'benqi.markets_supply_assets': [ChartType.OverviewWithIconChart],
  'benqi.reserves': [ChartType.OverviewWithIconChart],
  'benqi.qi_stats': [ChartType.JSONWidget],
  'benqi.top_suppliers_avax': [ChartType.Table],
  'benqi.top_borrowers_avax': [ChartType.Table],
  'benqi.top_suppliers_qi': [ChartType.Table],
  'benqi.top_borrowers_qi': [ChartType.Table],
  'benqi.top_suppliers_wbtc': [ChartType.Table],
  'benqi.top_borrowers_wbtc': [ChartType.Table],
  'benqi.pangolin_pairs_price': [ChartType.PairsRow],
  'defilama.solarbeam': [ChartType.JSONWidget],
  'defilama.sushiswap': [ChartType.JSONWidget],
  'defilama.zenlink': [ChartType.JSONWidget],
  'defilama.moonwell': [ChartType.JSONWidget],
  'generic.balance': [ChartType.JSONWidget],
  'generic.transactions': [ChartType.JSONWidget],
  'generic.erc20Balances': [ChartType.JSONWidget],
  'maker.dai_collateralization': [ChartType.PieChartRow],
  'maker.dai_generated_by_collateral': [ChartType.PieChartRow, ChartType.PairsRow],
  'maker.total_locked': [ChartType.AreaChartRow, ChartType.HistogramChartRow, ChartType.OverviewCardRow],
  'maker.collateral_ratio': [ChartType.OverviewCardRow],
  'maker.oracles': [ChartType.OverviewWithIconChart],
  'maker.ecosystem': [ChartType.OverviewWithIconChart],
  'maker.addresses': [ChartType.AddressRow],
  'maker.total_dai': [ChartType.AreaChartRow, ChartType.HistogramChartRow],
  'moonriver.moonwell_price_data': [ChartType.JSONWidget],
  'moonriver.moonwell_market_data': [ChartType.JSONWidget],
  'moonriver.sushi_tokens': [ChartType.JSONWidget],
  'moonriver.sushi_pairs': [ChartType.PairsRow],
  'moonriver.solarbeam_tokens': [ChartType.JSONWidget],
  'moonriver.solarbeam_pairs': [ChartType.PairsRow],
  'moonriver.aggregated_tokens_liquidity': [ChartType.Table],
  'moonriver.aggregated_tokens_volume': [ChartType.Table],
  'moonriver.daily_utilizations_btc': [ChartType.Table],
  'moonriver.daily_utilizations_frax': [ChartType.Table],
  'moonriver.daily_utilizations_eth': [ChartType.Table],
  'moonriver.daily_utilizations_movr': [ChartType.Table],
  'moonriver.daily_utilizations_usdc': [ChartType.Table],
  'moonriver.daily_utilizations_usdt': [ChartType.Table],
  'moonriver.top_borrowers_btc': [ChartType.Table],
  'moonriver.top_borrowers_movr': [ChartType.Table],
  'moonriver.top_borrowers_usdc': [ChartType.Table],
  'moonriver.top_borrowers_usdt': [ChartType.Table],
  'moonriver.top_borrowers_frax': [ChartType.Table],
  'moonriver.top_borrowers_eth': [ChartType.Table],
  'moonriver.moonwell_top_cdp_accounts': [ChartType.Table],
};
