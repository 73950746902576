import { GetPageData } from 'src/services/engine';
import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import {
  FormValues, ExplorerParamChangeResponse, ExplorerLtScanningResponse, Wallet,
} from './types';
import { DATA_QUERY_URL } from '../../utils/graphql';
import { RISK_EXPLORER_LIQUIDATIONS } from '../graphql-queries';

type WalletsQuery = {
  chains: string[];
  liquidationThreshold: number;
  tokenSymbol: string;
  priceChangePercent: number;
}[];

export const getChangeResult = async (
  values: FormValues,
  defaultLts: Record<string, number>,
): Promise<ExplorerParamChangeResponse | undefined> => {
  const {
    assetsValues,
  } = values;

  const pageQuery = {
    assets: assetsValues.map(({ asset }) => asset).join(','),
    liquidationThresholds: assetsValues.map(({ liquidationThreshold }) => liquidationThreshold).join(','),
  };
  const defaultWalletsQuery = assetsValues.map((asset) => ({
    chains: ['Ethereum'], liquidationThreshold: defaultLts[asset.asset], tokenSymbol: asset.asset, priceChangePercent: 0,
  }));
  const walletsQuery = assetsValues.map((asset) => ({
    chains: ['Ethereum'], liquidationThreshold: asset.liquidationThreshold, tokenSymbol: asset.asset, priceChangePercent: 0,
  }));
  const apolloClient = new ApolloClient<NormalizedCacheObject>({
    uri: `${DATA_QUERY_URL}query/ccar-lending`,
    cache: new InMemoryCache(),
    headers: {
      protocol: 'aave-v2',
    },
  });

  const liquidationsQuery = (query: WalletsQuery) => apolloClient
    .query<{ riskExplorerLiquidatedWallets: Wallet[] }>({
    query: RISK_EXPLORER_LIQUIDATIONS,
    variables: {
      query,
      becameEligibleOnly: false,
    },
  });

  const [pageResult, walletsResult, defaultWalletsResult] = await Promise.all([
    GetPageData<ExplorerParamChangeResponse>(
      'aave_param_explorer',
      pageQuery,
    ),
    liquidationsQuery(walletsQuery),
    liquidationsQuery(defaultWalletsQuery),
  ]);

  const liquidatedWallets = walletsResult
    .data.riskExplorerLiquidatedWallets
    .filter((w) => w.health < 1);
  const defaultLiquidatedWallets = defaultWalletsResult
    .data.riskExplorerLiquidatedWallets
    .filter((w) => w.health < 1);

  return pageResult && {
    liquidatedWallets,
    defaultLiquidatedWallets,
    healthHistogram: pageResult.healthHistogram,
  };
};

export const getScanningResult = async (
  values: FormValues,
): Promise<ExplorerLtScanningResponse | undefined> => {
  const {
    assetsValues,
  } = values;

  const apolloClient = new ApolloClient<NormalizedCacheObject>({
    uri: `${DATA_QUERY_URL}query/ccar-lending`,
    cache: new InMemoryCache(),
    headers: {
      protocol: 'aave-v2',
    },
  });
  const range: number[] = [];

  for (let i = -10; i < 0; i += 1) {
    range.push(i);
  }

  const queries = range.map((n) => assetsValues.map((asset) => ({
    chains: ['Ethereum'], liquidationThreshold: asset.liquidationThreshold + n, tokenSymbol: asset.asset, priceChangePercent: 0,
  })));
  const results = await Promise.all(queries.map((query) => apolloClient
    .query<{ riskExplorerLiquidatedWallets: Wallet[] }>({
    query: RISK_EXPLORER_LIQUIDATIONS,
    variables: {
      query,
      becameEligibleOnly: false,
    },
  })));
  return range.reduce((acc, n, i) => ({
    ...acc,
    [n.toString()]: results[i].data.riskExplorerLiquidatedWallets.filter((w) => w.health < 1),
  }), {});
};

export const deBankWalletLink = (address: string) => `https://debank.com/profile/${address}`;
